import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL, STRIPE_KEY } from '../config/constants.js';
import {Tabs, Tab} from "react-bootstrap";
import InputMask from 'react-input-mask';
import { Redirect, NavLink, Link } from 'react-router-dom';
import ls from 'local-storage';
import $ from 'jquery';
import defaultimg from '../components/default.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Spinner from '../includes/Spinner.component';
import CropAndPreview from '../includes/CropAndPreviewModal.component';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
const base_api_url = API_URL;
const stripePromise = loadStripe(STRIPE_KEY);
const errors = {};
toast.configure();
export class EditListenerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onChangeFirstNameEdit = this.onChangeFirstNameEdit.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeDob = this.onChangeDob.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeZip = this.onChangeZip.bind(this);
    this.onChangeSSN = this.onChangeSSN.bind(this);
    this.onChangeFaceBook = this.onChangeFaceBook.bind(this);
    this.onChangeLogindevice = this.onChangeLogindevice.bind(this);
    this.onChangeTwitter 	= this.onChangeTwitter.bind(this);
    this.onChangeStage 		= this.onChangeStage.bind(this);
    this.onChangeBio 		= this.onChangeBio.bind(this);
    this.onClicktab 		= this.onClicktab.bind(this);
    this.onSubmit 			= this.onSubmit.bind(this);
    this.state = {
      user: [],
      errors: [],
      error: null,
      msg: null,
      successclass: null,
      redirect: false,
      loggedintoken: ls.get('token'),
      selectedprofile: 'Choose File',
      usersubscription: '',
      formStep: '1',
      loader: true,
      btnDisable: false,
      btnText: 'Update',
      showCropAndPreview: false,
      countrylist:[],
      is_free:'1',
      planlist:[],
    };
  }
  handleShow = () => {
    this.setState({ showCropAndPreview: !this.state.showCropAndPreview });
  };
  handleChange(date) {
    /*if(this.state.errors['dob_error']){delete this.state.errors['dob_error'];}*/
    this.setState({
      dob: date
    });
  }
  componentDidMount() {
    document.title = 'CONQER Music - My Profile';
    //console.log(this.props.match.params.id);
    this.fetchUserRecord();
    this.fetchUserSubscriptionRecord();
    this.getcountrylist();
    this.getplandetails('listener');
  }
  getplandetails = (utype) => {    
    const usertype = utype;
    const obj = {
      usertype: usertype
    }
    Axios.post(base_api_url + 'getplandetails.php', obj)
      .then(
        (res) => {
          if (res.data.res_status == 200) {
            this.setState({
              planlist: res.data.res_data
            });
          } else {
            this.setState({
              planlist: []
            });
          }
        }
      );
  }
  handleValidationUpgrad = () => {
    let formIsValid = true;
    if (!this.state.sub_plan) {
      formIsValid = false;
      errors['plan_error'] = 'Please select subscription plan.';
    }
    if(!this.state.formValidation){
      formIsValid = false;
    }
    if(!this.state.fillStatus){
      this.setState({
        cardError:'Please enter card detail.'
      });
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  stripeElementChange = (element, name) => {
    this.setState({
      fillStatus: true,
      cardError:'',
      formValidation: true
    });
    if(element.error){
      if(element.error.code){
        this.setState({
          formValidation: false,
          cardError: element.error.message
        });
      }
    }else if(!element.complete){
      this.setState({
        fillStatus: false
      });
    }
  }
  async onUpgradeProfile(e) {
    e.preventDefault();
    if (this.handleValidationUpgrad()) {
      this.setState({
        btnText:'Please Wait..',
        btnDisable:true
      });
      await this.onToken();      
    }
  }
  async onToken() {
    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if(result.error){
      this.setState({
        btnText:'Update',
        btnDisable:false
      });
    }else{
      let formData = {
        "token":result.token.id,
        "membership": this.state.sub_plan,
        "email": this.state.email
      };
      const axiosConfig = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      };
      Axios.post(base_api_url + 'stripepay.php', formData, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          let formUpdateData = {
            "subscription_id": res.data.res_data,
            "plan_id": this.state.sub_plan,            
            "user_id": this.state.user_id
          };
          Axios.post(base_api_url + 'upgrade-plan.php', formUpdateData, { axiosConfig }).then((response) => {
            if (response.data.res_status == 200) {
              this.fetchUserSubscriptionRecord();
              toast.success(response.data.res_message);
              this.setState({
                is_free: '0',
                formStep:'1'
              });
              toast.success(res.data.res_message);
            }else{
              toast.error(response.data.res_message);
            }
          });          
        }else {
          toast.error(res.data.res_message);
        }
        this.setState({
          btnText:'Update',
          btnDisable:false
        });
      }
      )
    }
  }
  getcountrylist = () => {
    Axios.post(base_api_url + 'country.php')
      .then(
        (res) => {
          if (res.data.res_status == 200) {
            this.setState({
              countrylist: res.data.res_data
            });
          } else {
            this.setState({
              countrylist: []
            });
          }
        }
      );
  }
  croppedImage = async (img) => {
    this.setState({ profile_pic_path: img });
  };
  handleValidation = () => {
    let formIsValid = true;
    if (!this.state.firstname) {
      formIsValid = false;
      errors['firstname_error'] = 'Please enter first name.';
    }
    if (this.state.selectedFile != null) {
      const fsize = this.state.selectedFile.size;
      const file = Math.round(fsize / 1024);
      if (file >= 5120) {
        formIsValid = false;
        errors['image_error'] = 'File too Big, please select a file less than 5mb.';
      }
    }
    if(!this.state.email){
      formIsValid = false;
      errors['email_error'] = 'Please enter email.';
    }else if(!this.validateEmail(this.state.email)){
      formIsValid = false;      
      errors["email_error"] = "Please enter valid email.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  fetchUserRecord = () => {
    const usertoken = this.props.match.params.id;

    const obj = {
      token: usertoken,
      userid: ls.get('userid')
    };

    const axiosConfig = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    };

    Axios.post(base_api_url + 'userbytoken.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        this.setState({
          firstname: res.data.listenerdata.first_name,
          lastname: res.data.listenerdata.last_name,
          account_number: res.data.listenerdata.account_number,
          address: res.data.listenerdata.address,
          city: res.data.listenerdata.city,
          dob: (res.data.listenerdata.dob)? new Date(res.data.listenerdata.dob):"",
          email: res.data.listenerdata.email,
          facebook: res.data.listenerdata.facebook,
          gender: res.data.listenerdata.gender,
          linkedin: res.data.listenerdata.linkedin,
          phone: res.data.listenerdata.phone,
          profile_pic: res.data.listenerdata.profile_pic,
          profile_pic_path: res.data.listenerdata.profile_pic_path,
          ssn: res.data.listenerdata.ssn,
          state: res.data.listenerdata.state,
          zipcode: res.data.listenerdata.zipcode,
          country: res.data.listenerdata.country,
          login_device: res.data.listenerdata.login_device,
          about: res.data.listenerdata.about,
          user_id: res.data.listenerdata.user_id,
          twitter: res.data.listenerdata.twitter,
          stage: res.data.listenerdata.stage,
          is_free: res.data.listenerdata.is_free,
          loader: false
        });
      } else {
        this.setState({
          redirect: false,
          msg: null,
          successclass: null,
          loader: false
        });
      }
    });
  };

  fetchUserSubscriptionRecord = () => {
    const usertoken = this.props.match.params.id;

    const obj = {
      token: usertoken,
      userid: ls.get('userid')
    };

    const axiosConfig = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    };

    Axios.post(base_api_url + 'getsubscription.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        this.setState({
          usersubscription: res.data.user_data
        });
      } else {
        this.setState({
          redirect: false,
          msg: null,
          successclass: null
        });
      }
    });
  };

  state = {
    selectedFile: null
  };

  onFileChange(e) {
    if (this.state.errors['image_error']) {
      delete this.state.errors['image_error'];
    }
	
	if(e.target.files.length > 0){
		let url = URL.createObjectURL(e.target.files[0]);
		this.setState({ selectedprofile: e.target.files[0].name, selectedFile: url, showCropAndPreview: true });
	}
  }

  onChangeFirstNameEdit(e) {
    if (this.state.errors['firstname_error']) {
      delete this.state.errors['firstname_error'];
    }
    this.setState({
      firstname: e.target.value
    });
  }

  onChangeLastName(e) {
    this.setState({
      lastname: e.target.value
    });
  }

  onChangeDob(e) {
    this.setState({
      dob: e.target.value
    });
  }

  onChangeGender(e) {
    this.setState({
      gender: e.target.value
    });
  }

  onChangeAddress(e) {
    this.setState({
      address: e.target.value
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value
    });
  }

  onChangeCity(e) {
    this.setState({
      city: e.target.value
    });
  }

  onChangeState(e) {
    this.setState({
      state: e.target.value
    });
  }

  onChangeZip(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        zipcode: e.target.value
      });
    }
  }
  onChangeCountry(e){
    this.setState({
      country: e.target.value
    })
  }
  onChangeSSN(e) {
    this.setState({
      ssn: e.target.value
    });
  }

  onChangeFaceBook(e) {
    this.setState({
      facebook: e.target.value
    });
  }
  onChangeLogindevice(e) {
    this.setState({
      login_device: e.target.value
    });
  }
  validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }
  onChangeEmail(e){
    if (this.state.errors['email_error']) {
      delete this.state.errors['email_error'];
    }
    this.setState({
      email: e.target.value
    });
  }
  onChangeTwitter(e) {
    this.setState({
      twitter: e.target.value
    });
  }

  onChangeStage(e) {
    this.setState({
      stage: e.target.value
    });
  }

  onChangeBio(e) {
    this.setState({
      about: e.target.value
    });
  }
  onClicktab(tab) {
    ls.set('selectedtab',tab);
    this.setState({
      formStep:tab
    });
  }
  onChangePlan(e){
    if (this.state.errors['plan_error']) {
      delete this.state.errors['plan_error'];
    }
    this.setState({
      sub_plan: e.target.value
    })
  }
	closeAccount = () => {
		if(window.confirm("Are you sure you want to close account? You will not be able to login again and all data will be lost!")){
		const obj = {
		  userid: ls.get('userid')
		};

		const axiosConfig = {
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*'
		};

		Axios.post(base_api_url + 'closeaccount.php', obj, { axiosConfig }).then((res) => {
		  if (res.data.res_status == 200) {
				this.setState({
				  redirect: false,
				  msg: res.data.res_message,
				  successclass: 'successmsg'
				});
				setTimeout(() => {
					ls.set('email', '');
					ls.set('role', '');
					ls.set('name', '');
					ls.set('loginstatus', '');
					ls.set('token', '');
					ls.set('userid', '');
					ls.set('profile_pic', '');
					ls.set('membership', '');
					ls.set('membership_status', '');
					ls.set('profile', '');
					ls.set('membership_date', '');
					this.setState({
						redirect: true,
						successclass: null,
						msg: null
					  });
					}, 10000);
		  } else {
			this.setState({
			  redirect: false,
			  msg: res.data.res_message,
			  successclass: 'errormsg'
			});
		  }
		});
  }
  };
  async onSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({
        //loader: true
        btnDisable: true,
        btnText: 'Please Wait...'
      });
	  
      const formData = new FormData();

      const axiosConfig = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      };

      if (this.state.selectedFile != null) {
        let blob = await fetch(this.state.profile_pic_path).then((r) => r.blob());
        formData.append('image', blob, this.state.selectedprofile);
      }
      const dob = moment(this.state.dob);
      formData.append('first_name', this.state.firstname);
      formData.append('last_name', this.state.lastname);
      formData.append('dob', dob.format('yyyy-MM-DD'));
      formData.append('gender', this.state.gender);
      formData.append('address', this.state.address);
      formData.append('phone', this.state.phone);
      formData.append('email', this.state.email);
      formData.append('ssn', this.state.ssn);
      formData.append('city', this.state.city);
      formData.append('state', this.state.state);
      formData.append('zipcode', this.state.zipcode);
      formData.append('country', this.state.country);
      formData.append('login_device', this.state.login_device);
      formData.append('facebook', this.state.facebook);
      formData.append('twitter', this.state.twitter);
      formData.append('stage', this.state.stage);
      formData.append('bank_name', '');
      formData.append('account_number', '');
      formData.append('routing_number', '');
      formData.append('userid', this.state.user_id);
      formData.append('oldprofilepic', this.state.profile_pic);
      formData.append('oldroutingpic', '');
      formData.append('oldidentitypic', '');
      formData.append('about', this.state.about);
      Axios.post(base_api_url + 'update-user.php', formData, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          window.scrollTo(0, 0);
          this.setState({
            successclass: 'successmsg',
            msg: res.data.res_message,
            selectedprofile: 'Choose File',
            selectedidentity: 'Choose File',
            selectedrouting: 'Choose File',
			       selectedFile: null
          });
          this.refs.uploadImageProfile.value = "";
        } else {
          this.setState({
            successclass: 'errormsg',
            msg: 'Profile not updated, please try again!',
            selectedprofile: 'Choose File',
            selectedidentity: 'Choose File',
            selectedrouting: 'Choose File'
          });
        }
		this.fetchUserRecord();
		this.fetchUserSubscriptionRecord();
		let getseltab = ls.get('selectedtab');
		if(getseltab === '2'){
		  this.setState({formStep:'2'});
		}else if(getseltab === '3'){
		  this.setState({formStep:'3'});
		}else{
		  this.setState({formStep:'1'});
		}
        this.setState({
          //loader: false
          btnDisable: false,
          btnText: 'Update'
        });
        setTimeout(() => {
          this.setState({
            successclass: null,
            msg: null
          });
        }, 5000);
      });
    }
  }
  onclickseturl = (urlval) => {
    this.setState({
      urlvalinitial: urlval
    });
    $('#myModal').modal('show');
  };
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };

  render() {
    console.log('formStep', this.state.formStep);
	if(this.state.redirect){
	  return <Redirect to="/" />;
	}
    return (
      <React.Fragment>
        <div className={this.state.successclass}>{this.state.msg}</div>
        {(this.state.is_free == '0')?
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">Subscription</h3>
                <div className="box-tools">
                  <NavLink to={'/listener-profile/' + this.state.loggedintoken} className="btn btn-primary">
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
                  </NavLink>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-4 subscriptionbtnactive">
                    <p>
                      <span className="subsdetail"><strong>Subscription Started On </strong></span>: {(ls.get('membership_status'))?this.state.usersubscription.start_date:"NA"}
                      <br />
                      <span className="subsdetail"><strong>Subscription Expiry On </strong></span>: {(ls.get('membership_status'))?this.state.usersubscription.end_date:"NA"}
                      <br />
                      <span className="subsdetail"><strong>Membership Status </strong></span>: {(ls.get('membership_status'))?this.state.usersubscription.membership_status:"NA"}
                    </p>
                  </div>
                  <div className="col-md-8 buttonset subs">
                  {(ls.get('membership_status'))?
                  <>
                    <Link to="/listener-change-plan/" className="btn btn-primary pull-right text-white">
                      <i className="fa fa-ticket" aria-hidden="true"></i>&nbsp;Change Plan
                    </Link>
                    <Link to="/listenerchange-card-details/" className="btn btn-success pull-right text-white">
                      <i className="fa fa-cc-mastercard" aria-hidden="true"></i>&nbsp;Change Card Details
                    </Link>
                    </>
                    :<Link to="/listener-change-plan/" className="btn btn-primary pull-right text-white">
                      <i className="fa fa-ticket" aria-hidden="true"></i>&nbsp;Purchase Plan
                    </Link>}
                    <button type="button" className="btn btn-danger pull-right" onClick={ this.closeAccount.bind(this) }>
                      <i className="fa fa-times" aria-hidden="true"></i>&nbsp;Close Account
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>:""}
		{(this.state.loader)?<Spinner />:
			<form className="artist-form" onSubmit={this.onSubmit}>
			  <div className="row">
				<div className="col-md-12">
				  <div className="box box-success distributions-profileform">
                <div className="box-header with-border addprofilenew">
					  <h3 className="box-title">Listener Profile</h3>
					</div>
					<div className="box-body no-padding havetabs eventadds">
            <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
              <li className="nav-item"><a href="#tab_1" data-toggle="tab" aria-expanded="true" className={(this.state.formStep==='1')?'nav-link active':'nav-link'} onClick={()=>this.onClicktab('1')}>Personal Information</a></li>
              <li className="nav-item"><a href="#tab_2" data-toggle="tab" aria-expanded="false" className={(this.state.formStep==='2')?'nav-link active':'nav-link'} onClick={()=>this.onClicktab('2')}>Social Media Information</a></li> 
              <li className="nav-item"><a href="#tab_3" data-toggle="tab" aria-expanded="false" className={(this.state.formStep==='3')?'nav-link active':'nav-link'} onClick={()=>this.onClicktab('3')}>Upload</a></li>
              {(this.state.is_free == '1')?<li className="nav-item"><a href="#tab_upgrad" onClick={()=>this.onClicktab('4')} data-toggle="tab" aria-expanded="false" className={(this.state.formStep==='4')?'nav-link active':'nav-link'}>Upgrade Profile</a></li>:""
              }
            </ul>
            <div className="tab-content">
              <div className={(this.state.formStep==='1')?'tab-pane active':'tab-pane'} id="tab_1">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                      <label htmlFor="firstname">First Name<span className="requiredastrik">*</span></label>
                      <input type="text" className="form-control" value={this.state.firstname} onChange={this.onChangeFirstNameEdit} />
                       <font className="errorMsg">{this.state.errors['firstname_error']}</font>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                      <label htmlFor="lastname">Last Name</label>
                      <input type="text" className="form-control" value={this.state.lastname} onChange={this.onChangeLastName} />
                      </div>
                    </div>
                  </div>

            <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="dateofbirth">Date of Birth</label>
              <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                          </div>
                          <DatePicker
                selected={this.state.dob}
                onChange={this.handleChange}
                name="dob"
                dateFormat="MM/dd/yyyy"
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                maxDate={new Date()}
                className="form-control"
                dataInputmaskAlias="datetime" 
              />
                          
                        </div>

              
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <br />
              <div className="custom-control custom-radio custom-control-inline">
                <input
                type="radio"
                id="malegender"
                value="male"
                name="gender"
                onChange={this.onChangeGender}
                checked={this.state.gender === 'male'}
                className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="malegender">
                Male
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                type="radio"
                id="femalegender"
                value="female"
                name="gender"
                checked={this.state.gender === 'female'}
                onChange={this.onChangeGender}
                className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="femalegender">
                Female
                </label>
              </div>
              </div>
            </div>
            </div>

            <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="phone">Phone No.</label>
              <InputMask
                {...this.props}
                mask="(999) 999-9999"
                maskChar=" "
                className="form-control"
                value={this.state.phone}
                onChange={this.onChangePhone}
              />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="text" className="form-control" value={this.state.email} onChange={this.onChangeEmail.bind(this)} />
              <font className="errorMsg">{this.state.errors['email_error']}</font>
              </div>
            </div>            
            </div>

            <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="address">Address</label>
              <input type="text" className="form-control" value={this.state.address} onChange={this.onChangeAddress} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="city">City</label>
              <input type="text" className="form-control" value={this.state.city} onChange={this.onChangeCity} />
              </div>
            </div>
            
            </div>
            <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="state">State</label>
              <input type="text" className="form-control" value={this.state.state} onChange={this.onChangeState} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="country">Country</label>
              <select name="country" id="country" className="form-control" value={this.state.country} onChange={this.onChangeCountry.bind(this)}>
                <option>--Select Country--</option>
                {this.state.countrylist.map((result) => {
                  return (<option value={result.key}>{result.value}</option>)
                  })}
              </select>
              </div>
            </div>            
            </div>
            <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="zip">Zip Code</label>
                  <input type="text" className="form-control" value={this.state.zipcode} onChange={this.onChangeZip} maxlength="6" />
                 </div>
            </div>
            </div>
            <div className="row">
              <div className="col-md-12 notmainbtn customtabsbtns">
                <button type="submit" className="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
              </div>
            </div>
              


              </div>
              <div className={(this.state.formStep==='2')?'tab-pane active':'tab-pane'} id="tab_2">
                <div className="row">
            {/*<div className="col-md-6">
              <div className="form-group">
              <label htmlFor="ssn">SSN</label>
              <InputMask
                {...this.props}
                className="form-control"
                placeholder="SSN"
                mask="999-99-9999"
                maskChar=" "
                value={this.state.ssn}
                onChange={this.onChangeSSN}
                id="ssn"
              />
              </div>
            </div>*/}
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="facebook">Facebook</label>
              <input
                type="text"
                className="form-control"
                value={this.state.facebook !== 'null' ? this.state.facebook : ''}
                onChange={this.onChangeFaceBook}
              />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="twitter">Twitter</label>
              <input
                type="text"
                className="form-control"
                value={this.state.twitter !== 'null' ? this.state.twitter : ''}
                onChange={this.onChangeTwitter}
              />
              </div>
            </div>
            </div>

            <div className="row">
            
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="stagename">Stage Name</label>
              <input
                type="text"
                className="form-control"
                value={this.state.stage !== 'null' ? this.state.stage : ''}
                onChange={this.onChangeStage}
              />
              </div>
            </div>
            </div>
            <div className="row">
              <div className="col-md-12 notmainbtn customtabsbtns">
                <button type="submit" className="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
              </div>
            </div>

              </div>
              <div className={(this.state.formStep==='3')?'tab-pane active':'tab-pane'} id="tab_3">

              <CropAndPreview
            show={this.state.showCropAndPreview}
            data={{ src: this.state.selectedFile }}
            croppedImages={this.croppedImage}
            handleShow={this.handleShow}
            />
            <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="customFile">Profile Picture</label>
              <input type="file" className="form-control" id="customFile" ref="uploadImageProfile" onChange={this.onFileChange} accept="image/*" />
              <font className="errorMsg">{this.state.errors['image_error']}</font>
                <img
                onError={this.addDefaultSrc}
                src={this.state.profile_pic_path}
                className="img-responsive img-circle"
                style={{ width: '100px', height: '100px', marginTop:'5px' }}
                onClick={() => this.onclickseturl(this.state.profile_pic_path)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
              <label htmlFor="routingdocuments">User Bio</label>
              <textarea className="form-control rounded-0" onChange={this.onChangeBio} value={this.state.about} rows="3"></textarea>
              </div>
            </div>
            </div>            

            <div className="row">
              <div className="col-md-12 notmainbtn customtabsbtns">
                <button type="submit" className="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
              </div>
            </div>
              </div>
              {(this.state.is_free == '1')?
              <div className={(this.state.formStep==='4')?'tab-pane active':'tab-pane'} id="tab_upgrad">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Subscription Plan<span className="requiredastrik">*</span></label>
                      <select className="form-control" onChange={this.onChangePlan.bind(this)}>
                        <option value="">--Select Subscription Plan--</option>
                          {this.state.planlist.map((result) => {
                            return (<option value={result.id}>{result.plan} [ ${result.amount}]</option>)
                          })}
                      </select>
                      <font className="errorMsg">{this.state.errors['plan_error']}</font>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Credit Card<span className="requiredastrik">*</span></label>
                      <div className="creditcardsection">
                        <CardElement options={{ hidePostalCode: true }}  onChange={(element) => this.stripeElementChange(element, 'card_number')} />
                      </div>
                      <font className="errorMsg">{this.state.cardError}</font>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 notmainbtn customtabsbtns">
                    <button type="button" onClick={ this.onUpgradeProfile.bind(this)} className="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
                  </div>
                </div>
              </div>:""}
            </div>            
            </div>
			</div>
          <div className="box-footer clearfix"></div>
				  </div>
				</div>
			  </div>
			</form>
		}
        <div id="myModal" className="modal fade imgmodal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <button type="button" className="btn btn-default closeicon" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-body">
                <img className="showimage img-responsive" src={this.state.urlvalinitial} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default function InjectedEventBooking({ match }) {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <EditListenerProfile match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}
