import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card } from "react-bootstrap";
export default class helpComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classData0: true,
			classData1: false,
			classData2: false
		}
	}
	componentDidMount(){
		document.title = "CONQER Music - Help";
	}
	handleOnClick1 = (e, act) => {
		this.setState({
			classData0: !act,
			classData1: false,
			classData2: false
		});	
  	}
  	handleOnClick2 = (e, act) => {
		this.setState({
			classData1: !act,
			classData0: false,
			classData2: false
		});	
  	}
  	handleOnClick3 = (e, act) => {
		this.setState({
			classData2: !act,
			classData1: false,
			classData0: false
		});	
  	}
	render() {
		return (
			<React.Fragment>
				<section className="ques-ans-section">
				  	<div className="container">
				  	<h3 className="team-title-heading">Help</h3>
				  		<Accordion defaultActiveKey="0">
	                        <Card>
	                            <Accordion.Toggle onClick={() => this.handleOnClick1('0', this.state.classData0)} className={(this.state.classData0)?"":"collapsed"} as={Card.Header} eventKey="0">
	                               Artist Registration
	                            </Accordion.Toggle>

	                            <Accordion.Collapse eventKey="0">
	                                <Card.Body>
	                                <p>1. Click on the URL (<Link to="/">https://conqermusic.com</Link>) to navigate CONQER MUSIC Platform.</p>
	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help1.png'} />
	                                <p>2. Navigate to Artist Menu from top.</p>
	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help2.png'} />
	                                <p>3. Go to bottom of the page by scrolling vertically.</p>
	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help3.png'} />
	                                <p>Navigate “CREATE A PROFILE TODAY” to open Artist Registration form.</p>
	                                <p>4. Registration - Following fields populates which are mandatory to fill.</p>
	                                <ul>
				                        <li>First Name* For user’s Name</li>
				                        <li>Email* Email will be used for communication.</li>
				                        <li>Password* To secure their account user need to set a password according to themselves to prevent their account.</li>
				                        <li>Date of Birth* Here user need to fill their date of birth to have a record in this platform.</li>
				                        <li>Subscription* To Continue with site user need to select a plan either monthly or yearly and make a payment via card by entering card number, expiry month/year and CVC.</li>
				                        <li>There are two plans available.<br />Mothly-$9.77<br />Yearly- $108</li>
				                        <li>Terms & Condition* After filling all the details user need to agree to the terms and condition to proceed further with registration and press the submit button.</li>
				                    </ul>   
				                     <p><small>Note: Fields denoted by * are mandatory and need to be filled.</small></p>

	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help4.png'} />
	                                <p>5. After Successful registration process user then need to activate their account using activation link in email id used during registration.</p>
	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help5_prod.png'} />
	                                <p>6. After successful account activation user can log in to their account using email id and password used during registration.</p>
	                                </Card.Body>
	                            </Accordion.Collapse>
	                        </Card>
	                        <Card>
	                            <Accordion.Toggle onClick={() => this.handleOnClick2('1',this.state.classData1)} className={(this.state.classData1)?"":"collapsed"} as={Card.Header} eventKey="1">
	                               Listener Registration
	                            </Accordion.Toggle>

	                            <Accordion.Collapse eventKey="1">
	                                <Card.Body>
	                                <p>1. Click on the URL (<Link to="/">https://conqermusic.com</Link>) to navigate CONQER MUSIC Platform.</p>
	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help1.png'} />
	                                <p>2. Navigate to Listener Menu from top.</p>
	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help2-li.png'} />
	                                <p>3. Go to bottom of the page by scrolling vertically.</p>
	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help3.png'} />
	                                <p>Navigate “CREATE A PROFILE TODAY” to open Listener Registration form.</p>
	                                <p>4. Registration - Following fields populates which are mandatory to fill.</p>
	                                <ul>
				                        <li>First Name* For user’s Name</li>
				                        <li>Email* Email will be used for communication.</li>
				                        <li>Password* To secure their account user need to set a password according to themselves to prevent their account.</li>
				                        <li>Date of Birth* Here user need to fill their date of birth to have a record in this platform.</li>
				                        <li>Subscription* To Continue with site user need to select a plan either monthly or yearly and make a payment via card by entering card number, expiry month/year and CVC.</li>
				                        <li>There are two plans available.<br />Mothly-$7.77<br />Yearly- $84.00</li>
				                        <li>Terms & Condition* After filling all the details user need to agree to the terms and condition to proceed further with registration and press the submit button.</li>
				                    </ul>   
				                     <p><small>Note: Fields denoted by * are mandatory and need to be filled.</small></p>

	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help4.png'} />
	                                <p>5. After Successful registration process user then need to activate their account using activation link in email id used during registration.</p>
	                                <img className="makeimg-responsive" src={process.env.PUBLIC_URL + 'assets/help/help5_prod.png'} />
	                                <p>6. After successful account activation user can log in to their account using email id and password used during registration.</p>
	                                </Card.Body>
	                            </Accordion.Collapse>
	                        </Card>
	                        <Card>
	                            <Accordion.Toggle onClick={() => this.handleOnClick3('2',this.state.classData2)} className={(this.state.classData2)?"":"collapsed"} as={Card.Header} eventKey="2">
	                               Subscription Pricing
	                            </Accordion.Toggle>
	                            <Accordion.Collapse eventKey="2">
	                                <Card.Body>
	                                	<ul>
	                                		<li>Listener</li>
	                                		<ul>
	                                			<li>Monthly Subscription - $7.77</li>
	                                			<li>Yearly Subscription - $84.00</li>
	                                		</ul>
	                                		<li>Artist </li>
	                                		<ul>
	                                			<li>Monthly Subscription - $9.77</li>
	                                			<li>Yearly Subscription - $108.00</li>
	                                		</ul>
	                                	</ul>
	                                </Card.Body>
	                            </Accordion.Collapse>
	                        </Card>
	                    </Accordion>
				  	</div>
				</section>
			</React.Fragment>
		)
	}
}