import React, { Component } from 'react';
import  { Link  } from 'react-router-dom';
export default class About extends Component {
    componentDidMount(){
		document.title = "CONQER Music - About Us";
	}
	render() {
        return (
		<React.Fragment>
			<div id="slider-home">
				<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
				  <div className="carousel-inner">
					<div className="carousel-item active">
					  <img src={process.env.PUBLIC_URL + 'assets/slider/slide6.jpg'} alt="banner" />
					  <div className="title">
						<h1 className="animated slideInDown">CONQER&nbsp;<span>MUSIC</span></h1>
						<h2 className="animated slideInLeft">Founded on biblical principles.</h2>
					  </div>
					  
					</div>
					<div className="carousel-item">
					  <img src={process.env.PUBLIC_URL + 'assets/slider/slide6.jpg'} alt="banner"  />
					  <div className="title">
						<h1 className="animated slideInDown">CONQER <span>MUSIC</span></h1>
						<h2 className="animated slideInLeft">Founded on biblical principles.</h2>
					  </div>
					  
					</div>
				  </div>
				</div>
			</div>
			<section className="can-vision">
				<div className="container">
					<div className="row">
						<div className="col-md-5">
							<div className="vision-part">
								<p>The vision was birthed and incorporated in 2019 with a passion to create a music and video platform that are founded on biblical principles and supports the kingdom of God.</p>

								<div className="mt-4"></div>

								<p>You will find exclusive songs and albums from new artists, sermons, post or buy tickets for events, request prayer, and more </p>

								<p>We are a minority-owned company that seeks not only to create an empowering musical experience but promote artists, develop entrepreneurs, preach the gospel of Christ and build our communities.</p>
								
								<p>Check out our <Link to={'/philanthropy'} className="vision-p-colour">philanthropy</Link> page for more information on our community efforts.</p>
								
								<p>And learn more about what is going on in the Christ-centered community in our <Link to={'/news-media'} className="news-media">News & Media</Link> page.</p>
								
							</div>
						</div>
						<div className="col-md-7">
						  <div className="row vision-people">
							<div className="col-md-4">
							  <img src={process.env.PUBLIC_URL + 'assets/about/music1.jpg'} alt="music"/>
							</div>
							<div className="col-md-4">
							  <img src={process.env.PUBLIC_URL + 'assets/about/music2.jpg'} alt="music" />
							</div>
							<div className="col-md-4">
							  <img src={process.env.PUBLIC_URL + 'assets/about/music3.jpg'} alt="music" />
							</div>
							<div className="col-md-4">
							  <img src={process.env.PUBLIC_URL + 'assets/about/music4.jpg'} alt="music" />
							</div>
							<div className="col-md-4">
							  <img src={process.env.PUBLIC_URL + 'assets/about/music5.jpg'} alt="music" />
							</div>
							<div className="col-md-4">
							  <img src={process.env.PUBLIC_URL + 'assets/about/music6.jpg'} alt="music" />
							</div>
						  </div>
						</div>
					</div>
				</div>
			</section>
			<section className="addon_m_conqer">
			  <div className="container m_image_adds">
				<img src={process.env.PUBLIC_URL + 'assets/about/appbottom.png'} />
			  </div>
			</section>
			<section className="teamarea">
			    <div className="container team-content">
			        <h3 className="team-title-heading">Executive Team</h3>        
			    	<div className="row vision-people">			            
			            <div className="col-md-3 team-pic">
			                <img src={process.env.PUBLIC_URL + 'assets/team/terry-davis.jpg'} alt="music" />
			                <h4 className="titlename">Terry Davis</h4>
			                <p className="title-designation">Chief Executive Officer</p>
			            </div>
			            <div className="col-md-3 team-pic">
			                <img src={process.env.PUBLIC_URL + 'assets/team/camina-davis-cheif-operation-officer.jpg'} alt="music" />
			                <h4 className="titlename">Camina Davis</h4>
			                <p className="title-designation">Chief Operations Officer</p>
			            </div>
			            <div className="col-md-3 team-pic">
			                <img src={process.env.PUBLIC_URL + 'assets/team/michael-anderson-jr.jpg'} alt="music" />
			                <h4 className="titlename">Michael Anderson Jr.</h4>
			                <p className="title-designation">Chief Development Officer</p>
			                
			            </div>
			            <div className="col-md-3 team-pic">
			                <img src={process.env.PUBLIC_URL + 'assets/team/m-dianne.jpg'} alt="music" />
			                <h4 className="titlename">Dianne Thompson</h4>  
			                <p className="title-designation">Chief Financial Officer</p>              
			            </div>
			            <div className="col-md-3 team-pic">
			                <img src={process.env.PUBLIC_URL + 'assets/team/praveen-sharma.jpg'} alt="music" />
			                <h4 className="titlename">Praveen Sharma</h4>  
			                <p className="title-designation">Chief Information Officer</p>              
			            </div>
			        </div>
			    </div>
			</section>
		</React.Fragment>
		)
	}
}