import React, { Component } from 'react';
export default class Philanthropy extends Component {
    componentDidMount(){
		document.title = "CONQER Music - Philanthropy";
	}
	render() {
        return (
			<React.Fragment>
				<div id="slider-home" className="philanthropy-sliders">
					<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
					  <div className="carousel-inner ">
						<div className="carousel-item active">
						  <img src={process.env.PUBLIC_URL + 'assets/slider/slideshow5.jpg'} alt="..."/>
						  <div className="title">
							<h1 className="animated slideInDown artist-sl"><span> Isaiah 58:12</span></h1>
							<h2 className="animated slideInLeft philantho-slid">
							  Those from among you shall build the old waste places;<br/>
							  You shall raise up the foundations of many generations;<br/>
							  And you shall be called the Repairer of the Breach,<br/>
							  The Restorer of Paths to Dwell In.<br/>
							</h2>
						  </div>
						  <div className="phil-slid-m-img">
							  <div className="phil-slid-img"><img src={process.env.PUBLIC_URL + 'assets/slider/imga1.png'} /></div>
						  </div> 
						</div>
						<div className="carousel-item">
						  <img src={process.env.PUBLIC_URL + 'assets/slider/slideshow5.jpg'} alt="..."/>
						  <div className="title">
						    <h1 className="animated slideInDown artist-sl"><span> Isaiah 58:12</span></h1>
							<h2 className="animated slideInLeft">
							  Those from among younShall build the old waste places;<br/>
							  You shall raise up the foundations of many generations;<br/>
							  And you shall be called the Repairer of the Breach,<br/>
							  The Restorer of Paths to Dwell In.<br/>
							</h2>
						  </div>
						   <div className="phil-slid-m-img">
							  <div className="phil-slid-img"><img src={process.env.PUBLIC_URL + 'assets/slider/imga1.png'} /></div>
						  </div>
						</div>
					  </div>     
					</div>
				</div>
				<section className="collaborate-canqer">
				  <div className="container">
					<div className="row">
					  <div className="col-md-4 can-colli">
						<a href="https://www.zoeblueprint.com/" target="_blank"><img src={process.env.PUBLIC_URL + 'assets/philanthropy/zbp-logo.jpg'} /></a>
					  </div>
					  <div className="col-md-8">
						<p>Zoe Blueprint is a platform that helps educators, community service organizations, students, parents and other users plan and track community service hours. The platform is designed to bring charities together under one umbrella and help educators, students, parents, and anyone else who’s interested in lending a generous hand, plan and track their community service. With Zoe Blueprint, you have the power to find charities in your area and join in on the fun.</p>
					  </div>
					</div>
				  </div>
				</section>
				<section className="collaborate-canqer">
				  <div className="container">
					<div className="row">
					  <div className="col-md-4 can-colli">
						<a href="https://www.simusa.org/sports-ministry/" target="_blank"><img src={process.env.PUBLIC_URL + 'assets/philanthropy/cam_logo.jpg'} /></a>
					  </div>
					  <div className="col-md-8">
						<p>Sports Ministry - Introducing students to the gospel through free sports activities include but not limited to football, basketball, and more.</p>
					  </div>
					</div>
				  </div>
				</section>
				<section className="collaborate-canqer">
				  <div className="container">
					<div className="row">
					  <div className="col-md-4 can-colli">
						<a href="https://therosesministry.org" target="_blank"><img src={process.env.PUBLIC_URL + 'assets/philanthropy/roselogo.png'} /></a>
					  </div>
					  <div className="col-md-8">
						<p>The R.O.S.E Ministry (Restoring Our Seniors Environment) - This program seeks to clean, organize, and fix the homes of elderly men and women who have little or no income.</p>
					  </div>
					</div>
				  </div>
				</section>
				<section className="collaborate-canqer">
				  <div className="container">
					<div className="row">
					  <div className="col-md-4 can-colli">
						<a href="https://swim-go.org" target="_blank"><img src={process.env.PUBLIC_URL + 'assets/philanthropy/sw_logo.png'} /></a>
					  </div>
					  <div className="col-md-8">
						<p>SOLANO WOMEN IN MEDICINE (SWIM) implements therapeutic programs that addresses Adverse Childhood Experiences (ACEs) and Trauma-Informed Care. The programs include field trips across the U.S. such as the Grand Canyon, Yosemite Park, etc., hydroponics gardens at home, special events and a host of other activities to heal the family.</p>
					  </div>
					</div>
				  </div>
				</section>

				<section className="philan_section">
				  <div className="container philan_future">
					<p>Train future leaders in different skill sets, nurture their natural born talents and develop them to be legitimate tax-paying business owners.</p>
				  </div>
				</section>
			</React.Fragment>
		)
	}
}