import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink, Redirect, Link, withRouter } from 'react-router-dom';
import ls from 'local-storage';
import Axios from 'axios';
import { Col } from 'react-bootstrap';
import { API_URL } from '../../config/constants.js';
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
class HeaderTop extends Component {
	constructor(props) {
        super(props);
        this.state = {
		  email:'',
		  role:'',
		  name:'',
		  login_time:'',
		  unreadCounter:'0',
		  notifications:[]
		}
    }
    componentDidMount() {
		const localData = JSON.parse(ls.get("storeData"));
		if(localData !== null){
			const obj = {
				id: localData.id
			}
			
			Axios.post(API_URL + 'admin-detail.php', obj, { axiosConfig })
			.then(
				(res) => {
					if(res.data.res_status === "200"){
						this.setState({ email:res.data.res_data.name, role:res.data.res_data.type, name:res.data.res_data.name,
							login_time: res.data.res_data.login_time
						});						
					}else{
						return <Redirect to="/admin/logout" />;
					}					
				}
			)
		}
		this.getNotificationData();
		setInterval(this.getNotificationData, 50000);
	}
	getNotificationData = async () => {
		const response = await Axios.post(API_URL + 'admin-notification.php', axiosConfig);
	    if (response.data.res_status === '200') {
	      this.setState({
	        notifications: response.data.res_data,
	        unreadCounter: response.data.total_unread,
	      });
	    }
	};
	readNotification = async (uid) => {
	    const obj = {
	      id: uid,
	    };
	    await Axios.post(API_URL + 'read-notification.php', obj, axiosConfig);
	    this.getNotificationData();
	};
	handleShowModal = (con, index) => {
    const selectedItemData = this.state.notifications[index] ? this.state.notifications[index] : {};
    this.readNotification(selectedItemData.id);
    if(selectedItemData.notification_type == "song_added"){
      let path = '/admin/song/'+selectedItemData.data_id;
      this.props.history.push(path);
    }    
  };
    render() {
    	
    	return (
    		<div> 
			    <div className="topbararea">
			      <div className="profile-infor">
			        <nav className="navbar navbar-static-top">
						<button type="button" id="sidebarCollapse" className="sidebar-toggle">
							<span className="sr-only">Toggle navigation</span>
						</button>
			            <div className="articwelcome">
							<h4>{this.state.name}</h4>
						</div>     
			          <div className="navbar-custom-menu">
			            <ul className="nav navbar-nav">
			              
			              <li className="dropdown notifications-menu">
			              	<Link className="dropdown-toggle" data-toggle="dropdown">
			                <i className="fa fa-bell-o"></i>
			                {this.state.unreadCounter > 0 ? <span className="label label-warning">{this.state.unreadCounter}</span> : ''}
			                </Link>
			                <ul className="dropdown-menu">
			                  <li className="header">You have {this.state.unreadCounter} notifications</li>
			                  <li>
			                    <ul className="menu">
			                      {this.state.notifications.map((item, index) => (
			                          (item.read == 0)?
			                          <Link onClick={() => this.handleShowModal(false, index)}>
			                          <li style={{ cursor: 'pointer', borderBottom:'1px solid #dcd9d9' }}>
			                            <Col className="py-2">
			                            <span style={{ fontSize: '0.9rem' }} className={item.read === '0' ? 'font-weight-bold text-dark' : 'text-secondary'}>
			                                {item.title}
			                              </span>
			                            </Col>
			                          </li>
			                          </Link>:""
			                        ))}
			                    </ul>
			                  </li>
			                  <li className="footer"><Link to="/admin/notification-list">View all</Link></li>
			                </ul>
			              </li>  
			              <li className="dropdown user user-menu">
			                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
			                  <img src="https://adminlte.io/themes/AdminLTE/dist/img/user2-160x160.jpg" className="user-image" alt="User Image" />
			                 
			                </a>
			                <ul className="dropdown-menu">
			                  <li className="user-header">
			                    <img src="https://adminlte.io/themes/AdminLTE/dist/img/user2-160x160.jpg" className="img-circle" alt="User Image" />

			                    <p>
			                      {this.state.name} - {this.state.role}<br />
			                      <span>Login Time: {this.state.login_time}</span>
			                    </p>
			                  </li>
			                  <li className="user-footer">
			                    <div className="pull-left">
			                    </div>
			                    <div className="pull-right">
			                      <NavLink to="/admin/logout" className="btn btn-default btn-flat">Log out</NavLink>
			                    </div>
			                  </li>
			                </ul>
			                
			              </li>
			              </ul>
			          </div>

			        </nav>
			      </div>
			    </div>
			</div>
    	)
    }
}
export default withRouter(HeaderTop);