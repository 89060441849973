import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Loader from '../components/loader.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import DOMPurify from "dompurify";

const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
export default class ListenerSongPlays extends Component {
	constructor(props){
		super(props);
		this.state = {
			error: null,
			msg: null,
			loading:true,
			successclass:null,
			redirect:false,
			index: 0,
			currentTime: '0:00',
			musicList: this.props.musiclist,
			pause: false,
			currentalbum:'',
		};
		
	}


 componentDidMount() {
   this.playerRef.addEventListener("timeupdate", this.timeUpdate, false);
   this.playerRef.addEventListener("ended", this.nextSong, false);
   this.timelineRef.addEventListener("click", this.changeCurrentTime, false);
   this.timelineRef.addEventListener("mousemove", this.hoverTimeLine, false);
   this.timelineRef.addEventListener("mouseout", this.resetTimeLine, false);
   this.getalbumdata();
 }

 /* componentWillUnmount() {
    this.playerRef.removeEventListener("timeupdate", this.timeUpdate);
    this.playerRef.removeEventListener("ended", this.nextSong);
    this.timelineRef.removeEventListener("click", this.changeCurrentTime);
    this.timelineRef.removeEventListener("mousemove", this.hoverTimeLine);
    this.timelineRef.removeEventListener("mouseout", this.resetTimeLine);
  }*/
  getalbumdata = () => {
		const objs = {
			id:this.props.alid
		}
		Axios.post(base_api_url+'getalbum.php',objs,{axiosConfig})
		.then(
			(result) => {
				if(result.data.res_status==200){
					let returndatas = result.data.res_data;
					// console.log('returndatas',returndatas);
					if(returndatas !== 'null'){
						this.setState({
							currentalbum:returndatas,
							loading:false
						});
					}
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}

changeCurrentTime = (e) => {
  const duration = this.playerRef.duration;
  
  const playheadWidth = this.timelineRef.offsetWidth;
  const offsetWidht = this.timelineRef.offsetLeft;
  const userClickWidht = e.clientX - offsetWidht;
 
  const userClickWidhtInPercent = (userClickWidht*100)/playheadWidth;

  this.playheadRef.style.width = userClickWidhtInPercent + "%";
  this.playerRef.currentTime = (duration * userClickWidhtInPercent)/100;
}

hoverTimeLine = (e) => {
  const duration = this.playerRef.duration;
  
  const playheadWidth = this.timelineRef.offsetWidth
  
  const offsetWidht = this.timelineRef.offsetLeft;
  const userClickWidht = e.clientX - offsetWidht;
  const userClickWidhtInPercent = (userClickWidht*100)/playheadWidth;

  if(userClickWidhtInPercent <= 100){
    this.hoverPlayheadRef.style.width = userClickWidhtInPercent + "%";
  }
  
  const time = (duration * userClickWidhtInPercent)/100;
  
  if( (time >=0) && (time <= duration)){
    this.hoverPlayheadRef.dataset.content = this.formatTime(time);
  }
}

resetTimeLine = () => {
  this.hoverPlayheadRef.style.width = 0;
}

timeUpdate = () => {
  const duration = this.playerRef.duration;
  const timelineWidth = this.timelineRef.offsetWidth - this.playheadRef.offsetWidth;
  const playPercent = 100 * (this.playerRef.currentTime / duration);
	this.playheadRef.style.width = playPercent + "%";
  const currentTime = this.formatTime(parseInt(this.playerRef.currentTime));  
  this.setState({ 
    currentTime 
  });
}

formatTime = (currentTime) =>{
  const minutes = Math.floor(currentTime / 60);
  let seconds = Math.floor(currentTime % 60);

  seconds = (seconds >= 10) ? seconds : "0" + seconds % 60;
  
  const formatTime = minutes + ":" +  seconds
 
  return formatTime;
  }

  updatePlayer = () =>{
    const { musicList, index } = this.state;
    const currentSong = musicList[index];
    const audio = new Audio(currentSong.audio);
    this.playerRef.load();
  }
  
  nextSong = () => {
    const { musicList, index, pause } = this.state;
  
    this.setState({ 
      index: (index + 1) % musicList.length
    });
    this.updatePlayer();
    if(pause){
      this.playerRef.play();
    }
  };

  prevSong = () => {
    const { musicList, index, pause } = this.state;  
    
    this.setState({ 
      index: (index + musicList.length - 1) % musicList.length
    });
    this.updatePlayer();
    if(pause){
      this.playerRef.play();
    }
  };
   

  playOrPause = () =>{
    const { musicList, index, pause } = this.state;
    const currentSong = musicList[index];
    const audio = new Audio(currentSong.audio);
    if( !this.state.pause ){
      this.playerRef.play();
    }else{
      this.playerRef.pause();
    }
    this.setState({
      pause: !pause
    })
  }
  
  clickAudio = (key,songid) =>{  
    const { pause } = this.state;
    this.setState({
      index: key
    });
    
    this.updatePlayer();
	
	const objs = {
		user_id:ls.get('userid'),
		song_id:songid
	}
	Axios.post(base_api_url+'songplayed.php',objs,{axiosConfig})
	.then(
		(result) => {
			if(result.data.res_status==200){
				this.setState({
					loading:false
				});
			}else{
				this.setState({
					loading:false
				});
			}
		}
	);
	
    if(pause){
      this.playerRef.play();
    }else{
		this.playOrPause();
	}
  }

  
	render() {
		const { musicList, index, currentTime, pause } = this.state;
		const currentSong = musicList[index];
		const {currentalbum} = this.state;
		let albumname = currentalbum.name;
		let albumsabout = currentalbum.about;
		let albumsuserid = currentalbum.user_id;
		let backurl = '/listener-assong/' + albumsuserid;
		return (
		<React.Fragment>
			<div className="row artisitblock allartist">
				<div className="col-md-3">
					<div className="box box-info">            
						<div className="box-body">
							<a href="#">
								<div className="profile-art"><img onError={this.addDefaultSrc} src={currentalbum.banner_image} onClick={() => this.onclickseturl(currentalbum.banner_image)} /></div>
								<div className="profileartinfo">
									<span className="artname">{albumname}</span>                
								</div>
							</a>
						</div>
					</div>
				</div>  
				<div className="col-md-9">
					<div className="artist-profileinfo">
						<div className="mutipleicns songpageback">
							<Link to={backurl}><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</Link>
						</div>
						<div className="bestartistname">         
							<p>{(albumsabout !== 'null')?albumsabout:''}</p>
						</div>
					</div>
				</div>              
			</div>  
			<div className="row songlidtdata">
				<div className="col-md-12">
					<div className="box box-info">
						<div className="box-header with-border">
							<h3 className="box-title">Top songs of {albumname}</h3>
						</div>
						<div className="box-body">
							<div className="songlists">
								<div className="play-list" >
									{musicList.map( (music, key=0) =>
										<div key={key} 
										onClick={()=>this.clickAudio(key,music.song_id)}
										className={"track " + 
										(index === key && !pause ?'current-audio':'') + 
										(index === key && pause ?'play-now':'')} >
											<span className="markfavourites"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
											<img className="track-img" src={music.img}/>
											<div className="track-discr" >
												<div className="track-name" >{music.name}</div>
												<div className="track-author" >{music.author}</div>
											</div>
											<div className="track-duration" >
												{(index === key)
												?currentTime
												:music.duration
												}
											</div>
										</div>
									)}
								</div>
								
								<div id="playerimplement" className="current-song">
									<div className="row">
										<div className="col-md-4">
											<div className="songdetials">
												<audio ref={ref => this.playerRef = ref}>
													<source src={ currentSong.audio } type="audio/ogg"/>
													Your browser does not support the audio element.
												</audio>
												<div className="song-imge">
													<img src={ currentSong.img }/>
												</div>
												<div className="song-titlens">
													<h4>{ currentSong.name }</h4>
													<span className="moviename">{ currentSong.author }</span>
												</div>
											</div>
										</div>
										<div className="col-md-8">
											<div className="row">
												<div className="col-md-4">
													<div className="playersection">
														<span className="next-farward" onClick={this.prevSong}><i className="fa fa-step-backward"></i></span>
														<span className="playsongicon" onClick={this.playOrPause}>
															{
															(!pause) ? <i className="fa fa-play"></i>
															:<i className="fa fa-pause"></i>
															}
														</span>
														<span className="next-farward" onClick={this.nextSong}><i className="fa fa-step-forward"></i></span>
													</div>	
												</div>	
												<div className="col-md-6">
													<div className="time">
														<div className="current-time">{ currentTime }</div>
														<div className="end-time">{ currentSong.duration }</div>
													</div>
													<div ref={ref => this.timelineRef = ref} id="timeline">
														<div ref={ref => this.playheadRef = ref} id="playhead"></div>
														<div ref={ref => this.hoverPlayheadRef = ref} className="hover-playhead" data-content="0:00"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</React.Fragment>
		)
	}
}