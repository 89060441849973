import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import  { Link, NavLink, withRouter, Redirect  } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import XMLData1 from '../assets/xml/bible1.xml';
import XMLData2 from '../assets/xml/bible2.xml';
import XMLData3 from '../assets/xml/bible3.xml';
import XMLData4 from '../assets/xml/bible4.xml';
import XMLData5 from '../assets/xml/bible5.xml';
import DOMPurify from "dompurify";
import {parseString} from 'xml2js';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
		
export default class ReadingBible extends Component {
	constructor(props){
		super(props);
		this.state = {		  
			bname:'',
			showloader:false,		    
			showEnd:true,		    
			chapters:[],
			biblelist:[],			
		}
		this.onChangeBible 		= this.onChangeBible.bind(this);
		this.onChangeGenesis 	= this.onChangeGenesis.bind(this);
		this.onEndReading 		= this.onEndReading.bind(this);
	}
	componentDidMount(){
		document.title = "CONQER Music - Bible reading plan";
		let loggedinemail = this.props.match.params.email;
		let bibleid = this.props.match.params.bibleid;
		if((typeof loggedinemail != 'undefined') && (typeof loggedinemail != 'undefined')){
			ls.set('useremail', loggedinemail);
			ls.set('bibleid', bibleid);
		}		
		this.getOtherData();		
	}
	getOtherData = () => {
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		}
		const formData = new FormData();
		formData.append("bibleid", ls.get('bibleid'));
		formData.append("email", ls.get('useremail'));
		Axios.post(base_api_url+'readingbible.php', formData, {axiosConfig})
		.then(
		(res) => {
			if(res.data.res_status==200){
				ls.set('bibletype', res.data.res_data.bibletype);
				ls.set('fileshortname', res.data.res_data.fileshortname);
			}
			this.getxmldata();
			this.getbiblelist();
		});
	}
	getbiblelist = () => {		
		const obj = {}
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		}
		Axios.post(base_api_url+'getbiblelist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						biblelist:res.data.res_data,
					});
				}
			}
		);
	}
	getxmldata = () => {
		let bibleid = ls.get('bibleid');
		let XMLData = '';
		if(bibleid == '1'){
			XMLData = XMLData1;
		}else if(bibleid == '2'){
			XMLData = XMLData2;
		}else if(bibleid == '3'){
			XMLData = XMLData3;
		}else if(bibleid == '4'){
			XMLData = XMLData4;
		}else if(bibleid == '5'){
			XMLData = XMLData5;
		}else{
			XMLData = XMLData1;
		}
		Axios.get(XMLData, {
		   "Content-Type": "application/xml; charset=utf-8"
		})
		.then((response) => {
			let chapterdata,bnamedata;
			parseString(
				response.data,
				function(err, result) {
					let bibleData 		= result.XMLBIBLE.BIBLEBOOK[0];
					chapterdata 		= bibleData.CHAPTER;
					bnamedata			= bibleData.$.bname;
				}
			);
			this.setState({
				chapters : chapterdata,
				bname : bnamedata,
				showloader:true,
			});
		});
	}
	onStopReadLater = (e,useremail,bibletype,lastElement) => {
		const obj = {
			useremail: useremail,
			bibletype: bibletype,
			currentpage: lastElement,
		}
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		};
		Axios.post(base_api_url+'stopandreadlater.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						successclass:'successmsg',
						msg:res.data.res_message,
					})
				}else{
					this.setState({
						successclass:'errormsg',
						msg:res.data.res_message,
					});
				}
				setTimeout(() => {
					let redirectUrl =  '/mobile-bible-reading/'+ls.get('useremail');
					this.props.history.push(redirectUrl);
				}, 5000)
			}
		);
	}
	onChangeBible = (e,currentpage)=>{
		const obj = {
			bibleid:e.target.value
		}
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		}
		Axios.post(base_api_url+'getbiblelist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					let bibleTdata = res.data.res_data[0];
					let bibleTname = bibleTdata.label;
					ls.set('bibleid', e.target.value);
					ls.set('fileshortname', bibleTname);
					let currentpageUrl = window.location.href;
					window.open(currentpageUrl,'_blank');
				}
			}
		);
		
	}
	onChangeGenesis = (e)=>{
		let bounceUrl = '/mobile-reading-bible/page/'+ e.target.value;
		this.props.history.push(bounceUrl);
	}
	handleKeyUp = (e) => {
    	var searchedPara = document.getElementById("newData").querySelectorAll(".data__1");
    	for (let item of searchedPara) {
    		var words = e.target.value;
	    	var regex = RegExp(words);
	    	var replacement = '<span class="searched">'+ words +'</span>';
	    	var newHTML = item.textContent.replace(regex, replacement);
	    	item.innerHTML = newHTML;
	    }
    }
	onEndReading(e, useremail, bibletype,lastElement){
		let isChecked = e.target.checked;
		if(isChecked){
			this.setState({showEnd:false});
			const obj = {
				useremail: useremail,
				bibletype: bibletype,
				currentpage: lastElement,
			}
			const axiosConfig = {
				'Content-Type': 'multipart/form-data',
				"Access-Control-Allow-Origin": "*",
			};
			Axios.post(base_api_url+'endbiblereading.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
						})
					}else{
						this.setState({
							successclass:'errormsg',
							msg:res.data.res_message,
						});
					}
					setTimeout(() => {
						let redirectUrl =  '/mobile-bible-reading/'+ls.get('useremail')
						this.props.history.push(redirectUrl);
					}, 5000)
				}
			);
		}
	}
    render() {
		let useremail 	= ls.get('useremail');
		let bibleid 	= ls.get('bibleid');
		let bibletype 	= ls.get('bibletype');
		let fileshortname = ls.get('fileshortname');
		let { chapters, bname} 	= this.state;
		let allchapters 		= chapters.length; 
		let currentLocation 	= window.location.pathname;
		let location_array 		= currentLocation.split('/');
		let chapter 			= 0;
		let prevlastElement 	= 0;
		let nextlastElement 	= 0;
		let prevlinkclass 		= 'btn btn-primary';
		let nextlinkclass 		= 'btn btn-primary';
		let prevlink 			= '';
		let nextlink 			= '';
		let lastElement 		= location_array[3];
		let currentpage			= lastElement;
		if(lastElement !== undefined){
			if(lastElement == '' || lastElement == 0 || parseInt(lastElement) < 0 ){
				return <Redirect to={'/mobile-reading-bible/page/1'} />
			}else{
				
				prevlastElement 	= parseInt(lastElement)-1;
				if(prevlastElement == 0){
					prevlink 			= '#';
					prevlinkclass		= prevlinkclass + ' prevlinkdisable';
				}else{
					prevlink 			= '/mobile-reading-bible/page/'+prevlastElement;
				}
				nextlastElement 	= parseInt(lastElement)+1;
				if(nextlastElement <= allchapters){
					nextlink 			= '/mobile-reading-bible/page/'+ nextlastElement;
				}else{
					nextlink 			= '#';
					nextlinkclass		= nextlinkclass + ' nextlinkdisable';
				}
				lastElement 		= parseInt(lastElement) -1;	 
				chapter 			= chapters[lastElement];
				/* if(chapter === undefined){
					return <Redirect to={'/reading-bible/page/1'} />
				} */
			}
			
		}else{
			prevlink 			= '#';
			prevlinkclass		= prevlinkclass + ' prevlinkdisable';
			nextlastElement 	= 2;
			nextlink 			= '/mobile-reading-bible/page/'+ nextlastElement;
			chapter 			= chapters[0];
		}
		
		if(this.state.showloader==false){
			return <Spinner />;
		}else{
			return (
				<React.Fragment>
					<section className="bible-chapter-section my-5">
						<div className="container btnsetpart">
							<div className={this.state.successclass}>{this.state.msg}</div>
							<div className="biblechapters">
								<div className="row">
									<div className="col-md-12">
										<h3 className="bname bibllename">{bname} ({fileshortname})</h3>
									</div>
								</div>
								<div className="row">
									<div className="col-md-4" style={{'marginBottom':'5px'}}>
										<input type="text" className="form-control" placeholder="Search" onKeyUp={this.handleKeyUp.bind(this)}/>
									</div>
									<div className="col-md-4" style={{'marginBottom':'5px'}}>
										<select className="custom-select" name="bibleselection" id="bibleselection" onChange={(e)=>this.onChangeGenesis(e)}>
											<option value="">--Select Genesis--</option>
											{this.state.chapters.map((item,index)=> { 
												  return (<option key={index} value={item.$.cnumber} selected={(item.$.cnumber ===  currentpage)?'selected':''}>Genesis {item.$.cnumber} </option>)
											})}
										</select>
									</div>									
									<div className="col-md-4" style={{'marginBottom':'5px'}}>
										<select className="custom-select" name="bibleselection" id="bibleselection" onChange={(e)=>this.onChangeBible(e,currentpage)}>
											<option value="">--Select Bible--</option>
											{this.state.biblelist.map((item,index)=> { 
												  return (<option key={index} value={item.value} selected={(item.value ===  bibleid)?'selected':''}>{item.name} ({item.label})</option>)
											})}
										</select>
										<div className="bibbelbuttons d-flex justify-content-center" style={{'marginTop':'5px'}}>
											<Link to={prevlink} className={prevlinkclass} title="Previous Chapter"><i className="fa fa-fast-backward" aria-hidden="true"></i></Link>						
										
											<div title="Pause, for read later" style={{'height':'39px','marginTop':'2px'}} className="btn btn-primary" onClick={(e) => this.onStopReadLater(e, useremail, bibletype,currentpage)}><i className="fa fa-pause" aria-hidden="true"></i></div>
											
											<Link to={nextlink} className={nextlinkclass} title="Next Chapter"><i className="fa fa-fast-forward" aria-hidden="true"></i></Link>
										</div>
									</div>
								</div>
								<Row>
									{	
										( chapter)?
											<div className="">
												<div className="col-md-12">
													<div className="biblepagecontent" id="newData">
														<h5 className="vubchaptername"><b>Genesis {chapter.$.cnumber}</b></h5>
														{
															chapter.VERS.map(versitem => {
																return ( <p className="data__1">{versitem._}</p> );
															})
														}
													</div>
												</div>
											</div>
										:<div></div>
									}
									<Col>
									{(this.state.chapters.length == currentpage)?(this.state.showEnd)?
										<label htmlFor="endreading" className="mt-2 text-white"><input type="checkbox" id="endreading" className="" name="endreading" value="1" onChange={(e) => this.onEndReading(e, useremail, bibletype,currentpage)} /> Click here to end reading bible!</label>
									:'':''}
									</Col>
								</Row>
							</div>
							<div className="row mt-5 bibbelbuttons d-flex justify-content-center">
								<Link to={prevlink} className={prevlinkclass} title="Previous Chapter"><i className="fa fa-fast-backward" aria-hidden="true"></i></Link>
								<div title="Pause, for read later" className="btn btn-primary" style={{'height':'39px','marginTop':'2px'}} onClick={(e) => this.onStopReadLater(e, useremail, bibletype,currentpage)}><i className="fa fa-pause" aria-hidden="true"></i></div>
								<Link to={nextlink} className={nextlinkclass} title="Next Chapter"><i className="fa fa-fast-forward" aria-hidden="true"></i></Link>							
							</div>
						</div>
					</section>
				</React.Fragment>
			)
		}
	}
}