import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link, withRouter } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import ListenerReport from './listenerReport';
import AttendeeReport from './attendeeReport';
import DatePicker from 'react-datepicker';
import Axios from 'axios';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery'; 
import Spinner from '../../../includes/Spinner.component';

export default class songRevenue extends Component {
	constructor(props) {
    super(props);
	this.state = {
      userid: null,
      token: '',
      error: null,
      msg: null,
      reportlist: [],
      loading: true,
      loader: null,
      redirect: false,
      hideCustom: true,
      dataType: 'all',
      startdate: '',
      endDate: '',
      filterStatus:'listener'
    };
  }
  changeDataStatus = (e) => {
  	$('#myevents').DataTable().destroy();
  	$('#songreports').DataTable().destroy();
  	this.setState({ filterStatus: e.target.value });
  }
  
    
    render() {
		const {userData} = this.state;
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
				{(this.state.loader === true)?<Spinner />:
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title" style={{"text-transform": "capitalize"}}>Event {(this.state.filterStatus == 'listener')?'Booking':'Attendee'} Report</h3>
								 	<div className="box-tools ">
									  <select className="filteroptionselect" as="select" value={this.state.filterStatus} onChange={(e) => this.changeDataStatus(e)} style={{ width: '10vw' }}>
											<option value="listener">Listener Report</option>
											<option value="attendee">Attendee Report</option>
										  </select>
									<Link to={'/admin/events/'} className="btn btn-primary pull-right">
										<i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
									</Link>
								</div>
								<div className="box-tools saearchicons">
								  <div className="input-group input-group-sm hidden-xs"></div>
								</div>
								</div>
								<div className="box-body no-padding">
								  <div className="table table-responsive no-margin table-striped table-hover">
								  		{(this.state.filterStatus === 'listener')?
								  			<ListenerReport eventId={this.props.match.params.id}/>:<AttendeeReport eventId={this.props.match.params.id}/>
								  		}
									</div>            
								</div>
							</div>
						</div>        
					</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}