import React, { Component } from 'react';
import  { NavLink  } from 'react-router-dom';
export default class Conqer extends Component {
    componentDidMount(){
		document.title = "CONQER Music - Conquer";
	}
	render() {
        return (
			<React.Fragment>
				<section className="foldericonarea">
				  <div className="container">
					<div className="row">
					  <div className="col-md-12">
						<div className="folersection">
						  <div className="newctgarea">
							<div className="newctimg"><i className="fa fa-folder-open" aria-hidden="true"></i></div>
							<div className="newsctgname">CONQER (1)</div>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</section>
				<section className="news-sectioncmg">
				  <div className="container" >
					<div className="row">
					  <div className="col-md-12">
						<div className="comingimgleft"><img src={process.env.PUBLIC_URL + 'assets/news/867519228d1d5325856fc61d710ded0e_XL.jpg'} alt="Conquer Everything Coming Soon" /></div>
					  </div>
					</div>
					
				  </div>
				</section>
				<section className="news-comingsooncontent fiveconqer">
				  <div className="container">
					<div className="row">
					  <div className="col-md-12">
						<h2><a href="#">Conquer Everything Coming Soon</a></h2>
						<div className="dtetime fives">09:14 AM, 22 Oct 2019</div>
						<p>Gospel artist Michael Anderson Jr is set to release his seventh studio album titled “Conquer Everything” Jan 25th exclusively on Conqer Music. Make sure to check out his body of work with such titles as Revival, Who Am I, wAKE uP, The Clarion Call, 12.10.79, and Don’t Say Much Let God Speak.</p>
						<p><a href="https://open.spotify.com/artist/1xQ6GA0A4RnrE6ZrIRyqi4?si=_hoI75tfS5GDdetmWCzF0Q" target="_blank" rel="noopener noreferrer">Spotify</a> - <a href="https://www.youtube.com/channel/UCvuJKYqvTFPU_jxNGU8iHQQ" target="_blank" rel="noopener noreferrer">YouTube</a> - <a href="https://www.facebook.com/WhoIsMichaelAndersonJr" target="_blank" rel="noopener noreferrer">Facebook Whois Michael</a> - <a href="https://www.instagram.com/michaelandersonjr" target="_blank" rel="noopener noreferrer">Instagram Michael</a> - <a href="https://www.instagram.com/RevivalDaAlbum" target="_blank" rel="noopener noreferrer">Instragram Revival Album</a> - <a href="https://twitter.com/prolificmikejr" target="_blank" rel="noopener noreferrer">Twitter</a></p>
						<p>Mr. Anderson is a sought-after prolific, gifted performer, writer and producer with over 20+ years professional experience in the entertainment industry with emphasis on music production. He is trusted by artists as well as other entertainment professionals to produce the best overall project.</p>
						<p>&nbsp;</p>
						<div className="publishednub">
						  <div className="publiline"><span>Published in</span> CONQER</div>
						  <p>&nbsp;</p>
						  <p><NavLink to={'/32-conquer-everything-coming-soon'}>Read more...</NavLink></p>
						</div>
					  </div>
					</div>
				  </div>
				</section>
			</React.Fragment>
		)
	}
}