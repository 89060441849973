import React, { Component } from 'react';
import Axios from 'axios';
import  { NavLink } from 'react-router-dom';
import Spinner from '../includes/Spinner.component'
import ls from 'local-storage';
import defaultimg from '../components/default.png';
import $ from 'jquery';
import { API_URL } from '../config/constants.js';
const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};

export default class ListenerProfile extends Component {
	
	constructor(props){
		super(props);
		this.state = {
		  userdata:'',
		  error: null,
		  msg: null,
		  token:ls.get('token'),
		  successclass:null,
		  redirect:false,
		  loading:true		  
		}
	}
	
	componentDidMount(){
		document.title = "CONQER Music - My Profile";
		this.getUserData();
	}
	
	getUserData = () =>{
		const obj = {
			userid: ls.get('userid')
		}
				
		Axios.post(base_api_url+'userbytoken.php',obj,{axiosConfig})
		.then(
			(res) => {
				
				if(res.data.res_status==200){
					this.setState({
						userdata:res.data.listenerdata,						
						loading:false
					});
				}
			}
		);
	}
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	render() {
		if (this.state.loading == true) {
			return <Spinner />;
		}
		const userinfo = this.state.userdata;
		var profile_image = '';
		var image = new Image();
		var url_image = userinfo.profile_pic_path;
		var profile_image = userinfo.profile_pic_path;
		/*image.src = url_image;
		if (image.width !== 0) {
			profile_image = userinfo.profile_pic_path;								
		} else {
			profile_image = defaultimg;
		}*/
        return (        	
			<React.Fragment>
									 
						<div className="row">
							<div className="col-md-12">
								<div className="box box-success distributions-profileform">
									<div className="box-header with-border addprofilenew">
									  <h3 className="box-title">Profile</h3>
									</div>
									<div className="box-body no-padding">
									  <div className="row">
										<div className="col-md-12">
										  <div className="sectionhead">
											<div className="headsec">
											  <h3>Personal Information</h3>
											</div>
										  </div>
										</div>
									  </div>
									  <div className="row">
										<div className="col-md-6">  
										  <div className="form-group">
											<label for="firstname">Name</label>
											<div className="decentvalue">{userinfo.first_name + ' ' +userinfo.last_name}</div>
										  </div>
										</div>
										<div className="col-md-6">  
										  <div className="form-group">
											<label for="firstname">Email</label>
											<div className="decentvalue">{userinfo.email}</div>
										  </div>
										</div>
									  </div>
										<div className="row">											
											<div className="col-md-6">  
											  <div className="form-group">
												<label for="firstname">Phone No.</label>
												<div className="decentvalue">{(userinfo.phone)?`(${(userinfo.phone).substring(0, 3)}) ${(userinfo.phone).substring(3, 6)}-${(userinfo.phone).substring(6, (userinfo.phone).length)}`:''}</div>
											  </div>
											</div>
											<div className="col-md-6">  
											  <div className="form-group">
												<label for="firstname">DOB</label>
												<div className="decentvalue">{userinfo.dob}</div>
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
											  <div className="form-group">
											   <label for="customFile">Registered Date</label>
											   <div className="decentvalue">{userinfo.created}</div>
											  </div>
											</div>
											<div className="col-md-6">
											  <div className="form-group">
												<label for="lastname">Address</label>
												<div className="decentvalue">{userinfo.address}</div>
											  </div>
											</div>										
										</div>
										<div className="row">
											<div className="col-md-6">
											  <div className="form-group">
												<label for="lastname">Zipcode</label>
												<div className="decentvalue">{userinfo.zipcode}</div>
											  </div>
											</div>
											<div className="col-md-6">
											  <div className="form-group">
											   <label for="customFile">Last Visited Date</label>
											   <div className="decentvalue">{userinfo.last_login}</div> 
											  </div>
											</div>
										</div>                   
										<div className="row">
											<div className="col-md-12 listenerprofilepic">
												<img src={profile_image} width="100" height="100" className="img-responsive img-circle" onClick={() => this.onclickseturl(profile_image)} />
											</div>
										</div>
									</div>
									
									<div className="box-footer clearfix">
										<NavLink className="btn btn-primary" to={'/edit-listener-profile/'+this.state.token}>Edit Profile</NavLink>
										
									</div>
								</div>
							</div>        
						  </div>
					<div id="myModal" className="modal fade imgmodal" role="dialog">
					<div className="modal-dialog">
						<div className="modal-content">
							<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
							<div className="modal-body">
								<img className="showimage img-responsive" src={this.state.urlvalinitial} />
							</div>            
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}