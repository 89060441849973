import React, { Component } from 'react';
import Axios from 'axios';
import ArtistTopMenu from '../includes/artisttopmenu.component';
import  { NavLink, Link  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import ConversationList from './conversationlist.component';
import ls from 'local-storage';
import validator from 'validator';
import { Spinner } from 'react-bootstrap';

const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
export default class ViewPrayer extends Component {
	
	constructor(props){
		super(props);
		this.onChangeComment 	= this.onChangeComment.bind(this);
		this.onChangeAnonymus 	= this.onChangeAnonymus.bind(this);
		this.onSubmit 			= this.onSubmit.bind(this);
		
		this.state = {
			prayerdata:'',
			conversationdata:[],
			comment:'',
			anonymus:'',
			error: null,
			role: null,
			msg: null,
			userid:null,
			token:ls.get('token'),
			successclass:null,
			redirect:false,
			btndisable:false,
			buttontext:'Response',		  
		}
	}
	
	componentDidMount(){
		document.title = "CONQER Music - View Prayer";
		this.getPrayerData();
		const userid = ls.get('userid');
		this.setState({
			role: ls.get('role'),
			userid:userid
		});
		this.getPrayerConversation();
	}
	componentDidUpdate(){
		 // this.interval = setInterval(() => this.getPrayerConversation(), 5000);
	}
	
	scrollToBottom = () => {
		const divToScrollTo = document.getElementById(`mssgbox1`);
		if (divToScrollTo) {
			divToScrollTo.scrollIntoView({block: "end", inline: "end", behavior: "smooth" });
		}
		
	}
	
	getPrayerConversation = async() => {
		
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ userid: ls.get('userid'),role: ls.get('role'),prid: this.props.match.params.id, })
		};
		
		await fetch(base_api_url+'getprayerconversation.php',requestOptions)
        .then(response => response.json())
        .then(data => {
			if(data.res_status==200){	
				this.setState({
					conversationdata:data.res_data,
					loading:false 
				});
			}else{
				this.setState({
					error:data.res_message,												
					loading:false
				});
			}
		});
		
		this.scrollToBottom();
	}
	
	getPrayerData = () =>{
		const obj = {
			userid: ls.get('userid'),
			role: ls.get('role'),
			prid: this.props.match.params.id,
		}
		
		Axios.post(base_api_url+'viewprayerline.php',obj,{axiosConfig})
		.then(
			(res) => {
				
				if(res.data.res_status==200){			
					this.setState({
						prayerdata:res.data.res_data,						
						loading:false
					});
				}
			}
		);
	}
	
	onChangeComment(e){
		let checkcomment = validator.isEmpty(e.target.value,{ignore_whitespace:true}); 
		if(!checkcomment){
			this.setState({
				comment: e.target.value,
				btndisable:true
			});
		}else{
			this.setState({
				comment: e.target.value,
				btndisable:false
			});
		}
	}
	onChangeAnonymus(e){
		let isChecked = e.target.checked;
		let anonymusvalue = '';
		if(isChecked){
			anonymusvalue = e.target.value;
		}
		
		this.setState({
		  anonymus: anonymusvalue
		});
	}
	
	onSubmit(e) {
		e.preventDefault();
		this.setState({buttontext:<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '3vh',float:'right' }}><Spinner style={{ width: '15px', height: '15px', display: 'block'}} variant="light" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div>});
		const formData = new FormData();
		const usertoken = this.props.match.params.id;
		const axiosConfig = {
			  'Content-Type': 'multipart/form-data',
			  "Access-Control-Allow-Origin": "*",
		};
		const role = ls.get('role');
		const userid = ls.get('userid');
		formData.append("role", role);
		formData.append("userid", userid);
		formData.append("comment", this.state.comment);
		formData.append("anonymus", this.state.anonymus);
		formData.append("user_token", usertoken);
		
		
		Axios.post(base_api_url+'prcomment.php', formData, {axiosConfig})
		.then(
			(res) => { 
				if(res.data.res_status==200){
					this.setState({
						successclass:'successmsg',
						msg:'Reply added successfully!',
						buttontext:'Response',
					});
					this.getPrayerConversation();					
					this.scrollToBottom();
					
				}else{
					window.scrollTo(0, 0);
					this.setState({
						successclass:'errormsg',						
						msg:'Reply not added, please try again!',
						buttontext:'Response',
					})
				}
				setTimeout(() => {
				  this.setState({
					successclass:'hideclass',
					msg:null
				  });
				}, 5000)
			}
		);
		this.refs.usertype.checked = false;
		this.setState({
			comment:'',
			btndisable:false,
			anonymus:'',
		});		
	}
	
	render() {
		const prayerinfo  		= this.state.prayerdata;
		const conversationsdata	= this.state.conversationdata;
        return (
			<React.Fragment>
			<div className="row">
				<div className="col-md-12">
					<div className="box box-success distributions-profileform">
						<div className="box-header with-border addprofilenew">
						  <h3 className="box-title">Prayer Request</h3>
						  <div className="box-tools">
							<Link className="btn btn-primary" to={"/listner-prayer-line/"+this.state.token}>
								<i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
							</Link>
						  </div>
						</div>
						<div className="box-body no-padding">
							<div className="row">
								<div className="col-md-12">
									<div className="sectionhead">
										<div className="headsec">
										  <h3>Prayer Information</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<div className="form-group">
										<label for="lastname">Request Date</label>
										<div className="decentvalue">{prayerinfo.date}</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="form-group">
										<label for="lastname">Sender Name</label>
										{(prayerinfo.anonymus === '1')?<div className="decentvalue">Anonymous</div>:<div className="decentvalue">{prayerinfo.name}</div>}
									</div>
								</div>
								<div className="col-md-6">  
									<div className="form-group">
										<label for="lastname">Prayer Description</label>
										<div className="decentvalue">{prayerinfo.prayer}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="box-body no-padding newlistener-replyform">
							<div className="row">
								<div className="col-md-12">
								  <div className="sectionhead">
									<div className="headsec">
									  <h3>Responses</h3>
									</div>
								  </div>
								</div>
							</div>
							{(conversationsdata.length > 0 )?<div className="direct-chat-messages conversationchatbox">{conversationsdata.map((result,index) => {return (<ConversationList coversationdata={result} />)})}<div id="mssgbox3"></div><div id="mssgbox1"></div></div>:<div className="noreplyfound">No response found!</div>}
							<div className="box-body no-padding sendmsgftr listener-reply-form">
								<form className="artist-form" onSubmit={this.onSubmit}>
									<div className="row">
										<div className="col-md-12 col-xs-12">
											<div className="input-group sendmesagesection">
												<input type="text" name="message" placeholder="Prayer Response..." className="form-control" onChange={this.onChangeComment} value={this.state.comment} required="required"/>
												<span className="input-group-append">
												  <button type="submit" className="btn btn-primary" disabled={(!this.state.btndisable)?'disabled':'' }><i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;{this.state.buttontext}</button>
												</span>
											</div>
											<div className="form-group">
												<label htmlFor="anonymus"><input type="checkbox" ref="usertype" id="anonymus" className="htlanonymus" name="anonymus" value="1" onChange={this.onChangeAnonymus} /> Do you want to send a response as anonymous?</label>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>		
			</React.Fragment>
		)
	}
}