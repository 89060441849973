import React, { Component } from 'react';
import Axios from 'axios';
import  { NavLink  } from 'react-router-dom';
import ls from 'local-storage';
import { API_URL } from '../../../config/constants.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery'; 
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
export default class listenerReport extends Component {
	constructor(props){
		super(props);
		this.state = {
	      participantsList: []
	    };
	}
	componentDidMount() {
	    document.title = 'CONQER Music - Event Attendee Report';
	    this.getallreportlist();
	}
	getallreportlist = () => {
    let obj = {
    	event_id: this.props.eventId
    };
    Axios.post(API_URL + 'get-attendee-by-event.php', obj, { axiosConfig }).then((res) => {
      $('#myevents').DataTable().destroy();
      setTimeout(() => {
        $('#myevents').DataTable({
          destroy: true,
		  stateSave: true,
          processing: true,
          pageLength: 10,
		  language: {
			search: "Search"
		  },
          lengthMenu: [
            [5, 10, 15, -1],
            [5, 10, 15, 'All'],
          ],
          dom: 'Bfrtip',
          buttons: ['csv', 'print'],
		  "columnDefs": [
				{ "type": "num-fmt", "targets": 2, "className": "", "appliesTo": "print" },
				{ "type": "num-fmt", "targets": 3, "className": "", "appliesTo": "print" },
				{ "type": "num-fmt", "targets": 4, "className": "text-center", "appliesTo": "print" },
				{ "type": "num-fmt", "targets": 5, "className": "text-center", "appliesTo": "print" },
				{ orderable: false, targets: -1 }
			],
        });
      }, 100);
      if (res.data.res_status == 200) {
        const returndata = res.data.res_data;
        this.setState({
          participantsList: returndata,
          loading: false,
        });
      } else {
        this.setState({
          participantsList: [],
          loading: false,
        });
      }
    });
  }
	render() {
		return(
			<>
				<table id="myevents" className="table no-margin table-striped table-hover">
					<thead>
					  <tr>
						<th>Event Name</th>                    
						<th>Ticket</th>
						<th>Name</th>
						<th>Phone Number</th>
						<th>Email</th>
						<th style={{textAlign:'center'}}>Booked Tickets</th>
						<th style={{textAlign:'center'}}>Artist Amount</th>
						<th style={{textAlign:'center'}}>Admin Commission</th>
						<th style={{textAlign:'center'}}>Amount Received</th>
						<th>Reference ID</th>
						<th>Status</th>
					  </tr>
					</thead>
					<tbody>
					  {this.state.participantsList.map((result) => {
						return (
						<>										
						  <tr>                  
							<td><NavLink to={'/admin/events/view/'+result.event_id}>{result.event_name}</NavLink> </td>
							<td>{result.ticket_name}</td>
							<td>{result.participant_name}</td>
							<td>
								{result.phone
								? `(${result.phone.substring(0, 3)}) ${result.phone.substring(3, 6)}-${result.phone.substring(
									6,
									result.phone.length
								  )}`
								: ''}
							</td>
							<td>{result.email}</td>
							<td style={{textAlign:'center'}}>{result.no_of_tickets}</td>
							<td style={{textAlign:'center'}}>{result.total_price}</td>
							<td style={{textAlign:'center'}}>{result.admin_commission}</td>
							<td style={{textAlign:'center'}}>{result.ticket_price}</td>
							<td>{result.reference_id}</td>
							<td>{(result.status=='1') ? <span className="label label-success">Booked</span> : <span className="label label-danger">Failed</span>}</td>
						  </tr>
						  </>	
						)
						})}
					</tbody>
				</table>
			</>
		);
	}
}