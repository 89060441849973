import React, { Component } from 'react';
import $ from 'jquery';
export default class Merchendise extends Component {
	
	constructor(props){
		super(props);		
		this.state = {
		  urlvalinitial:null,
		}
	}
	
	componentDidMount(){
		
		document.title = "CONQER Music - Merchandise";
	}
	
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	
	closemodal = () => {
		this.setState({
			urlvalinitial: ''
		});
		$('#myModal').modal('hide');
	}
	
    render() {
        return (
			<React.Fragment>
				<div id="slider-home" className="merchandiseslider">
					<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
					  <div className="carousel-inner">
						<div className="carousel-item active">
						  <img src={process.env.PUBLIC_URL +'assets/slider/slide-mechandise.jpg'} />
						  <div className="title">
							<h1 className="animated slideInDown">CONQER <span>MUSIC</span></h1>
							<h2 className="animated slideInLeft">Shop Products Now</h2>
							<h2 className="animated slideInLeft"><a href="https://store.conqermusic.com/" target="_blank">Go To Store</a></h2>
						  </div>         
						</div>
						<div className="carousel-item">
						  <img src={process.env.PUBLIC_URL +'assets/slider/slide-mechandise.jpg'} />
						  <div className="title">
							<h1 className="animated slideInDown">CONQER <span>MUSIC</span></h1>
							<h2 className="animated slideInLeft">Shop Products Now</h2>
							<h2 className="animated slideInLeft"><a href="https://store.conqermusic.com/" target="_blank">Go To Store</a></h2>
						  </div>          
						</div>
					  </div>      
					</div>
				</div>

				<section className="featured-products">    
					<h2>Featured Products</h2>
					<p>Some highest-rated and purchased products below</p>
					<div className="container feature_pro">
						<div className="row">
							<div className="col-md-4">
								<img className="openthis" onClick={() => this.onclickseturl(process.env.PUBLIC_URL +'assets/merchandise/conqer-mask-3.jpg')} src={process.env.PUBLIC_URL +'assets/merchandise/conqer-mask-3.jpg'} />              
							</div>
							<div className="col-md-4">
								<img className="openthis" onClick={() => this.onclickseturl(process.env.PUBLIC_URL +'assets/merchandise/conqer-mask-2.jpg')} src={process.env.PUBLIC_URL +'assets/merchandise/conqer-mask-2.jpg'} />               
							</div>
							<div className="col-md-4">
							   <img className="openthis" onClick={() => this.onclickseturl(process.env.PUBLIC_URL +'assets/merchandise/conqer-mask-1.jpg')} src={process.env.PUBLIC_URL +'assets/merchandise/conqer-mask-1.jpg'} />           
							</div>
						</div>        
					</div>
				</section>

				<section className="addon-product">
					<h2>Add On Products</h2>
					<div className="container">
						<div className="row image-sequance">
							<div className="col-md-6">
								<img className="openthis" onClick={() => this.onclickseturl(process.env.PUBLIC_URL +'assets/merchandise/screen-shot-2020-01-05-at-6.27.03-pm.png')} src={process.env.PUBLIC_URL +'assets/merchandise/screen-shot-2020-01-05-at-6.27.03-pm.png'} />
							</div>
							<div className="col-md-6">
								<img className="openthis" onClick={() => this.onclickseturl(process.env.PUBLIC_URL +'assets/merchandise/20200613_155218.jpg')} src={process.env.PUBLIC_URL +'assets/merchandise/20200613_155218.jpg'} />
							</div>
						</div>
					</div>
				</section>

				<section className="comingsoon-products">    
					<h2>Products Coming Soon</h2>
					{/*<p>Products are coming soon below</p>*/}
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<img className="coming-p openthis" onClick={() => this.onclickseturl(process.env.PUBLIC_URL +'assets/merchandise/dsc_5691.jpg')} src={process.env.PUBLIC_URL + 'assets/merchandise/dsc_5691.jpg'} />
							</div>
							<div className="col-md-6">
								<img className="openthis" onClick={() => this.onclickseturl(process.env.PUBLIC_URL +'assets/merchandise/20200531_165445.jpg')} src={process.env.PUBLIC_URL + 'assets/merchandise/20200531_165445.jpg'} />
							</div>
						</div>
					</div>
				</section>
				
				<div id="myModal" className="modal fade imgmodal" role="dialog">
					<div className="modal-dialog">
						<div className="modal-content">
							<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
							<div className="modal-body">
								<img className="showimage img-responsive" src={this.state.urlvalinitial} />
							</div>            
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}