import React, { Component } from 'react';
import Axios from 'axios';
import  { Link  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
const base_api_url = API_URL;
const axiosConfig = {
	  'Content-Type': 'multipart/form-data',
	  "Access-Control-Allow-Origin": "*",
}
export default class Verification extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  resultstate: null,
		  msgtype: null,
		  activate:null,
		  message:null,
		  msg: null,
		  loading:true,
		  redirect: false
		}
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Verification Page";
		this.activationfunc();
	}
	
	activationfunc = () =>{
		const verificationtoken = this.props.match.params.code;		
		
		const obj = {
			activation_code:verificationtoken
		}
		
		Axios.post(base_api_url+'activation.php', obj, {axiosConfig})
		.then(
			(res) => {console.log(res.data);
				if(res.data.res_status==200){
					
					this.setState({
						resultstate : 'ativated',
						redirect: true,
						loading:false,
						msgtype: 'Congratulations!',
						
						message: 'Your Account has been successfully activated. You can now log in using the email id and password you choose during the registration.'
					});
					
				}else if(res.data.res_status==402){
					this.setState({
						resultstate : 'already-ativated',
						redirect: true,
						loading:false,
						msgtype: 'Error!',
						
						message: 'Account is already activated.'
					});
				}else{
					//console.log(res.data);
					this.setState({
						resultstate : false,
						msgtype: 'Error!',
						
						redirect: false,
						loading:false,
						message: 'Account activation is failed, please try again later!'
					})
				}
			}
		);
	}
	
	render() {
		if(this.state.loading==true){
			return <Spinner />;
		}
		return (
			<React.Fragment>
				<section className="become-artist-form">
					<div className="container">
						<div className="become-artist-part">
							<div className="row">
								<div className="contents_right col-md-12">
									<h1 className="white-heading">{this.state.msgtype}</h1>
									<div className="row">
										<div className="col-md-12">
											<p className="big-white-text">
											{this.state.message}</p>
											<p className="big-white-text"><Link to={'/login'}>Click here to Login</Link></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}