import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../config/constants.js';
import  { Link, NavLink } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import $ from 'jquery'; 
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
export default class SuggestIndex extends Component {
	constructor(props) {
    	super(props);
    	this.state = {
    		listData:[]
    	}
    }
    componentDidMount() {
	  	document.title = "CONQER Music - Suggest Artist";
		this.getSuggestArtistList();
	}
	getSuggestArtistList = () => {
		$('#_suggest').DataTable().destroy();
		Axios.post(base_api_url + 'suggested-artist.php',{axiosConfig}).then(
	        (res) => {
	          	if (res.data.res_status == 200) {
	          		const returndata = res.data.res_data;	
	            	this.setState({
	              		listData: returndata
	            	});
	          	} else {
	            	this.setState({
	              		listData: []
	            	});
	          	}	          	
	         	$('#_suggest').DataTable({
	          		"destroy": true,	
					language: {
						search: "Search"
					},"order": [[ 2, "desc" ]],
					"columnDefs" : [{"targets":2, "sType": "date-uk"}],
					dom: 'Bfrtip',
					buttons: [
		            	{
		                	extend: 'csv',
		                	exportOptions: {
			                    columns: [ 0,1,2]
			                }
		            	},
		            	{
		                extend: 'print',
		                exportOptions: {
		                    columns: [ 0,1,2]
		                }
		            	}
		        	]
	          	});
	        }
	    );
	}
	render() {
		return(
		<React.Fragment>
    		<div className="dashboard-wrapper">
    			<AdminHeader />
    			<div className="maincontent-area">
    				<AdminTopHeader />
    				<div className="main-dashsection">
    					<section className="contentpard">
    						<div className="row">
								<div className="col-md-12">
									<div className="box box-success distributions-profileform">
										<div className="box-header with-border addprofilenew">
									  		<h3 className="box-title">Suggested Artist</h3>         
										</div>
										<div className="box-body no-padding">
									  		<div className="table-responsive">
									  			<table id="_suggest" className="table no-margin table-striped table-hover">
													<thead>
													  	<tr>
															<th>Artist Name</th>
															<th>Suggested By</th>
															<th>Date</th>
													  	</tr>
													</thead>
													<tbody>
													{this.state.listData.map(result => {
														return (
															<tr>
																<td>{result.artist_name}</td>
																<td>{result.suggest_by}</td>
																<td>{result.date}</td>
															</tr>
														)}
														)
													}
													</tbody>
												</table>
									  		</div>
									  	</div>
									</div>
								</div>
							</div>
    					</section>
    				</div>
    			</div>
    		</div>
		</React.Fragment>);
	}
}