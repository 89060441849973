import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import mp3_file from '../assets/song/conqer.mp3';
import { API_URL } from '../config/constants.js';
import $ from 'jquery';
const base_api_url = API_URL;
export default class Index extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {		  
		  linktext:null,
		  linkurl:null,
		  content: null,
		  msg: null,		  
		  loading:true,
		  loader:null,		  
		  redirect:false
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Home";
		
		Axios.get(base_api_url+'verseoftheday.php')
			.then(res => {
				// console.log(res.data);
				const persons = res.data;
				this.setState({ 
					linktext : res.data.res_data.linktext,
					linkurl : res.data.res_data.linkurl,
					content : res.data.res_data.content
				});
		});
		var audio = $("#mysongdefault")[0];
		audio.play();
	}
	
    render() {
        return (
		<React.Fragment>
            <div id="slider-home" className="homepageslider">
				<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
				  <div className="carousel-inner">
					<div className="carousel-item active">
					  <img src={process.env.PUBLIC_URL + 'assets/slider/slide1.jpg'} alt="..." />
					  <div className="title">
						<h1 className="animated slideInDown">CONQER <span>MUSIC</span></h1>
						<p>Streaming the Word, Streaming a difference, Impacting the World</p>
					  </div>
					  <div className="slide-m-img">
						  <div className="first-m-img"><img src={process.env.PUBLIC_URL + 'assets/slider/dv1.png'} /></div>
						  <div className="second-m-img"><img src={process.env.PUBLIC_URL + 'assets/slider/dv2.png'} /></div>
					  </div>
					</div>
					<div className="carousel-item">
					  <img src={process.env.PUBLIC_URL + 'assets/slider/slide1.jpg'} alt="..." />
					  <div className="title">
						<h1 className="animated slideInDown">CONQER <span>MUSIC</span></h1>
						<p>Streaming the Word, Streaming a difference, Impacting the World</p>
					  </div>
					  <div className="slide-m-img">
						  <div className="first-m-img"><img src={process.env.PUBLIC_URL + 'assets/slider/dv1.png'} /></div>
						  <div className="second-m-img"><img src={process.env.PUBLIC_URL + 'assets/slider/dv2.png'} /></div>
					  </div>
					</div>
				  </div>
				</div>
			</div>
			<section>
				<div className="can-audio">
					
					<audio id="mysongdefault" controls>
						<source src={mp3_file} type="audio/mp3" />
					</audio>
				</div>
			</section>
			<section>
				<div className="container verse-section">

					<h3 className="verse-title-heading">VERSE OF THE DAY</h3>
					<div className="verse-discription">
						<p style={{textAlign:'center'}}><a href={this.state.linkurl} target="_blank"><span>{this.state.linktext}</span> {this.state.content}</a></p>
					</div>    
					
				</div>
			</section>
			<section>
				<div className="why-conqer">
					<div className="container why-content">
						<h3>Find out why CONQER</h3>
						<span>is taking the Christian world by storm!</span>						
					</div>
					{/*<div className="empty-space"></div>
					<div>
						<Link to={'/register/listener'} className="why-btn" role="button">CREATE A PROFILE TODAY</Link>
					</div>	*/	}			
				</div>
			</section>
			<section className="Christian-music">
				<div className="container">
					<h3 className="music-title-heading">
						<span> ALL CHRISTIAN MUSIC<br/> Christ  Community  Change </span>
					</h3>
					<div className="music-artist">
						<div className="row">
							<div className="col-md-6">
								<Link to="/register/artist">
								<div className="addon-single reg-0-link">
									<img className="img-responsive" src={process.env.PUBLIC_URL + 'assets/music/img1.jpg'} />
									<p>SIGNUP AS AN ARTIST</p>
								</div>
								</Link>
							</div>
							<div className="col-md-6">
								<Link to="register/listener">
								<div className="addon-single reg-0-link">
									<img className="img-responsive" src={process.env.PUBLIC_URL + 'assets/music/img2.jpg'} />
									<p>SIGNUP AS A LISTENER</p>
								</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="canqer-social">
				<div className="container">
					<div className="row">
					<div className="col-md-3">
						<div className="cansocial">
						<a target="_blank" href="https://www.facebook.com/Conqer-Music-109539370459926/" className="can-social-link">
							<span className="can-social-icon">
							<i className="fa fa-facebook fa-4x"></i>
							</span>
							<h3 className="can-social-title">FACEBOOK</h3>
						</a>
						</div>
					</div>
					<div className="col-md-3">
						<div className="cansocial">
						<a target="_blank" href="https://twitter.com/ConqerM?s=09" className="can-social-link">
							<span className="can-social-icon"><i className="fa fa-twitter fa-4x"></i></span>
							<h3 className="can-social-title">TWITTER</h3>
						</a>
						</div>
					</div>
					<div className="col-md-3">
						<div className="cansocial">
						<a target="_blank" href="https://www.instagram.com/conqermusic/" className="can-social-link">
							<span className="can-social-icon"><i className="fa fa-instagram fa-4x"></i></span>
							<h3 className="can-social-title">INSTAGRAM</h3>
						</a>
						</div>
					</div>
					<div className="col-md-3">
						<div className="cansocial">
						<a target="_blank" href="https://www.linkedin.com/in/conqer-music-794105196" className="can-social-link">
							<span className="can-social-icon"><i className="fa fa-linkedin fa-4x"></i></span>
							<h3 className="can-social-title">LINKEDIN</h3>
						</a>
						</div>
					</div>
				</div>
				</div>   
			</section>
		</React.Fragment>
        )
    }
}