import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Loader from '../components/loader.component';
import defaultimg from '../components/default.png';
import TopSongPlays from '../components/topsongplay.component';
import TopSongPlayer from './topSongPlayer.component';
import ls from 'local-storage';
import Spinner from '../includes/Spinner.component';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import DOMPurify from 'dompurify';

const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
export default class topSingleSong extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      msg: null,
      currentalbum: '',
      songlist: [],
      loading: true,
      successclass: null,
      redirect: false,
      albumsid: this.props.match.params.id,
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Song Page';
    this.getallsonglist();
  }

  getallsonglist = () => {
    const obj = {
      song_id: this.props.match.params.id,
	  user_id: ls.get('userid')
    };

    Axios.post(base_api_url + 'topsimilarsongs.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        const returndata = res.data.res_data;
        ls.remove('localCurrentSong');
        ls.remove('localMusicList');
        ls.remove('localIndex');
        ls.set('localMusicList', returndata);
        this.setState({
          songlist: ls.get('localMusicList'),
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  onclickseturl = (urlval) => {
    this.setState({
      urlvalinitial: urlval,
    });
    $('#myModal').modal('show');
  };
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };
  
  likeSong = (song_id,like_status, musiclist, index) => {
		const objs = {
		  song_id: song_id,
		  like: like_status,
		  user_id: ls.get('userid')
		};
		
		Axios.post(base_api_url + 'songlike.php', objs, { axiosConfig }).then((result) => {
		  let returndatas = result.data.res_message;
		  if (Number(result.data.res_status) === 200) {
			if (returndatas !== 'null') {
			  this.setState({
					successclass:'successmsg',						
					msg:returndatas
			  },()=>{
          let updateMusicList = musiclist[ls.get('localIndex')?ls.get('localIndex'):index];
          updateMusicList.likestatus = like_status;
          musiclist[ls.get('localIndex')] = updateMusicList;
          ls.remove('localMusicList');
          ls.set('localMusicList', musiclist);
				this.getallsonglist();
			});
			}
		  } else {
			this.setState({
				successclass:'errormsg',						
				msg:returndatas
			});
		  }
		  setTimeout(() => {
				this.setState({
					successclass:null,
					msg:null
				});
			}, 10000)
		});
	}
  _favoriteSong = (song_id,is_favorite, musiclist, index) => {
    let favorite = (is_favorite == '0')?'1':'0';
    const objs = {
      song_id: song_id,
      is_favorite: favorite,
      user_id: ls.get('userid')
    };
    
    Axios.post(base_api_url + 'add-to-favorite.php', objs, { axiosConfig }).then((result) => {
      let returndatas = result.data.res_message;
      if (Number(result.data.res_status) === 200) {
      if (returndatas !== 'null') {
        this.setState({
          successclass:'successmsg',            
          msg:returndatas
        },()=>{
          let updateMusicList = musiclist[ls.get('localIndex')?ls.get('localIndex'):index];
          updateMusicList.is_favorite = favorite;
          musiclist[ls.get('localIndex')] = updateMusicList;
          ls.remove('localMusicList');
          ls.set('localMusicList', musiclist);
          this.getallsonglist();
      });
      }
      } else {
      this.setState({
        successclass:'errormsg',            
        msg:returndatas
      });
      }
      setTimeout(() => {
        this.setState({
          successclass:null,
          msg:null
        });
      }, 10000)
    });
  }
  render() {
    if (this.state.loading == true) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
		{(this.state.msg)?<div className={this.state.successclass}>{this.state.msg}</div>:''}
        {/*<TopSongPlays musiclist={this.state.songlist} alid={this.state.albumsid} />*/}
        {this.state.songlist.length !== 0 ? <TopSongPlayer musiclist={this.state.songlist} alid={this.state.albumsid} likeSong={this.likeSong} _favoriteSong={this._favoriteSong} /> : <Spinner />}
      </React.Fragment>
    );
  }
}
