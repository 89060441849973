import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';

//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'jquery/dist/jquery.min.js';
import Spinner from '../../../includes/Spinner.component';
import $ from 'jquery'; 
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const errors = {};
export default class ISRCPackage extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	isrcpacklist:[],
      	errors:[],
		btndisable:'',
		code: '',
		oldpack: '',
		isrcpackid:'',
		exp_date:'',
      	btnSubmitText: 'Submit',
      	disabled:false
      }
    }
    isrcpacklistData(){
		const obj= {isrcpackageid:'0'}
	    Axios.post(API_URL + 'masterisrcpackagelist.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		isrcpacklist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						loader: false,
						isrcpacklist: [],
					});
		        }
		      	$(document).ready(function () {
		    		$('#isrcpacklist').DataTable().destroy();
			        setTimeout(function(){
			        $('#isrcpacklist').DataTable({
						"destroy": true,
						"stateSave": true,
						"lengthMenu": [[10, 15, 20 -1], [10, 15, 20,  "All"]],
						"language": {
							"search": "Search"
						},
						columnDefs: [
							{ type: "num-fmt", "targets": 1, className: "text-center", appliesTo: "print" },
							{ type: "num-fmt", "targets": 2, className: "text-center", appliesTo: "print" }
						],
						dom: 'Bfrtip',
						buttons: [
							{
								extend: 'csv',
								exportOptions: {
									columns: [0,1,2,3,4]
								}
							},
							{
								extend: 'print',
								exportOptions: {
									columns: [0,1,2,3,4]
								},
								customize: function ( win ) {
									$(win.document.body).find('table').addClass('display').css('font-size', '15px');
									$(win.document.body).find('table').css('table-layout','auto');
									$(win.document.body).find('th').css('width','15%');
									$(win.document.body).find('td').css('width','15%');
								}
							}
						]
			        });
			        } ,50);
			    });	        	   
	    	}

	    );
    }
    componentDidMount(){
		document.title = "Master - ISRC Code";
    	this.isrcpacklistData();
    	$(document).ready(function () {
    		$('#isrcpacklist').DataTable().destroy();
	        setTimeout(function(){
	        $('#isrcpacklist').DataTable({
				destroy: true,
				stateSave: true,
				lengthMenu: [[ 10, 15, 20 -1], [10, 15, 20,  "All"]],
				language: {
					search: "Search"
				},
				columnDefs: [
					{ type: "num-fmt", "targets": 1, className: "text-center", appliesTo: "print" },
					{ type: "num-fmt", "targets": 2, className: "text-center", appliesTo: "print" }
				],
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'csv',
						exportOptions: {
							columns: [0,1,2,3,4]
						}
					},
					{
						extend: 'print',
						exportOptions: {
							columns: [0,1,2,3,4]
						}
					}
				]
	        });
	        } ,50);
	    }); 
    }
    editISRCPackage = (isrc_pack_id) => {
		if(this.state.errors['amounterror']){delete this.state.errors['amounterror'];}
		if(this.state.errors['packageerror']){delete this.state.errors['packageerror'];}
		if(this.state.errors['expdateerror']){delete this.state.errors['expdateerror'];}
		if(this.state.errors['noofcodeerror']){delete this.state.errors['noofcodeerror'];}
    	this.setState({
			loader:true,
			isrcpackid:isrc_pack_id,
		})
		const obj = {
			isrcpackageid: isrc_pack_id
		}
		Axios.post(API_URL + 'masterisrcpackagelist.php', obj, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.isrcpacklistData();
					let currentc = res.data.res_data[0];
					this.setState({
						package_name: currentc.package_name,
						oldpack: currentc.package_name,
						amount: currentc.amount,
						noofcode: currentc.no_of_code,
						exp_date: new Date(currentc.expiry_date),
						btnSubmitText: 'Update',
						loader:false,
						method:'update'
					});

				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Internal Server Error, please try again!',
						loader:false
					})
				}
			}
		);
	}
	onBlurPack(e) {
		if(this.state.errors['packageerror']){delete this.state.errors['packageerror'];}
		let packVal = e.target.value;
		if(packVal !== this.state.oldpack){
			const obj = {
				package_name:packVal
			}			
			Axios.post(API_URL+'masterpackage_exist.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						errors["packageerror"] = res.data.res_message;
						this.setState({errors: errors,package_name: ''});
					}else{
						errors["packageerror"] = res.data.res_message;
						this.setState({errors: errors,package_name: packVal});
					}
				}
			);
		}
	}
	
	deleteISRC(id){
    	if(window.confirm("Are you sure you want to delete this package?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deleteisrcpack.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
							package_name: '',
							amount: '',
							exp_date: '',
							noofcode: '',
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message
						})
					}
					this.isrcpacklistData();
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
	onChangeAmount(e) {
		if(this.state.errors['amounterror']){delete this.state.errors['amounterror'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value) || (e.target.value > 0)) {
			this.setState({
				amount: e.target.value
			})
		}else{
			this.setState({
				amount: ''
			})
		}
	}
	onChangeNoofcode(e) {
		if(this.state.errors['noofcodeerror']){delete this.state.errors['noofcodeerror'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value) || (e.target.value > 0)) {
			this.setState({
				noofcode: e.target.value
			})
		}else{
			this.setState({
				noofcode: ''
			})
		}
	}
	onChangePackage(e) {
		if(this.state.errors['packageerror']){delete this.state.errors['packageerror'];}
		this.setState({
			package_name: e.target.value
		})
	}
	handleChange(date) {
		if(this.state.errors['expdateerror']){delete this.state.errors['expdateerror'];}
		this.setState({
		  exp_date: date
		})
	}	
	onCommissionSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
				loader:true,
				disabled:true,
				btnSubmitText:'Please Wait...'
			});
			if(this.state.method === 'update'){
				this.updateTickets();
			}else{
				this.saveTickets();
			}
		}
	}
	saveTickets(){
		let expdate 	= moment(this.state.exp_date);
		let expirydate 	= expdate.format('yyyy-MM-DD');
		let objs = {
			'package_name' : this.state.package_name,
			'amount' : this.state.amount,
			'noofcode' : this.state.noofcode,
			'exp_date' : expirydate,
		}
		Axios.post(API_URL + 'save_isrc_package.php', objs, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						package_name: '',
						amount: '',
						exp_date: '',
						noofcode: '',
						btnSubmitText: 'Submit',
						disabled:false,
						loader:false,
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						btnSubmitText:'Submit',
						disabled:false,
						loader:false,
						msg: res.data.res_message								
					})
				}
				this.isrcpacklistData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			} 
		)
	}
	updateTickets(){
		let expdate 	= moment(this.state.exp_date);
		let expirydate 	= expdate.format('yyyy-MM-DD');
		let objs = {
			'isrcpackid' : this.state.isrcpackid,
			'package_name' : this.state.package_name,
			'amount' : this.state.amount,
			'noofcode' : this.state.noofcode,
			'exp_date' : expirydate,
		}
		Axios.post(API_URL + 'update-isrc-package.php', objs, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						package_name: '',
						amount: '',
						exp_date: '',
						noofcode: '',
						btnSubmitText: 'Submit',
						disabled:false,
						method:'',
						loader:false
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						msg: res.data.res_message,
						btnSubmitText:'Update',
						disabled:false,
						loader:false,
						method:'update'
					})
				}
				this.isrcpacklistData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	handleValidation = () => {
		let formIsValid = true;		
		if(!this.state.amount){
			formIsValid = false;
			errors["amounterror"] = "Please enter amount.";
		}else if(this.state.amount < 1){
			formIsValid = false;
			errors["amounterror"] = "Please enter minimum $1 amount.";
		}
		
		if(!this.state.noofcode){
			formIsValid = false;
			errors["noofcodeerror"] = "Please enter value of allowing code for purchase.";
		}else if(this.state.noofcode < 1){
			formIsValid = false;
			errors["noofcodeerror"] = "Please enter minimum 1.";
		}
		
		if(!this.state.package_name){
			formIsValid = false;
			errors["packageerror"] = "Please enter package name.";
		}
		
		if(!this.state.exp_date){
			formIsValid = false;
			errors["expdateerror"] = "Please select expiry date.";
		}
		
		this.setState({errors: errors});		
        return formIsValid;
	
	}
	changeStatus(id,status){
    	this.setState({
    		loader:true
    	});
    	const obj = {
			id:id,
			status:status
		}			
		Axios.post(API_URL+'updateisrcpackstatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						successclass:'successmsg',
						msg:res.data.res_message,
						package_name: '',
						amount: '',
						exp_date: '',
						noofcode: '',
					});
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:res.data.res_message
					})
				}
				this.isrcpacklistData();
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 10000)
			}
		);
    }
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
    			<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">ISRC Code Package</h3>
							</div>
							<div className="box-body no-padding"> 
								<div className="nav-tabs-custom">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onCommissionSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>ISRC Code Package Information</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>Package Name<span className="requiredastrik">*</span></label>
															<input type="text" className="form-control" name="package_name" value={this.state.package_name} onChange={this.onChangePackage.bind(this)} onBlur={this.onBlurPack.bind(this)} />
															<font className="errorMsg">{this.state.errors["packageerror"]}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label>No. Of Code<span className="requiredastrik">*</span></label>
															<input type="text" className="form-control" name="amount" value={this.state.noofcode} onChange={this.onChangeNoofcode.bind(this)} />
															<font className="errorMsg">{this.state.errors["noofcodeerror"]}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>Amount ($)<span className="requiredastrik">*</span></label>
															<input type="text" className="form-control" name="amount" value={this.state.amount} onChange={this.onChangeAmount.bind(this)} />
															<font className="errorMsg">{this.state.errors["amounterror"]}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group expdatepicker">
															<label>Expiry Date<span className="red">*</span></label>
															<DatePicker
															  selected={ this.state.exp_date }
															  onChange={ this.handleChange.bind(this) }
															  name="startDate"
															  dateFormat="MM/dd/yyyy"
															  dropdownMode="select"
															  showMonthDropdown
															  showYearDropdown
															  adjustDateOnChange
															  minDate={new Date()}
															  className="form-control"
															  id="expdatepicker"
															  onKeyDown={e => e.preventDefault()}
														  />
														  <font className="errorMsg">{this.state.errors["expdateerror"]}</font>
														  <label className="expdatepickercalender" htmlFor="expdatepicker"><i className="fa fa-calendar" aria-hidden="true"></i></label>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6 mt-4">
														<button type="submit" style={{'height':'34px'}} disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>           
							</div>
							<hr />
							<div className="box-body no-padding">
								<div className="table-responsive">
									<table id="isrcpacklist" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Package Name</th>
												<th className="text-center">No. of Code</th>
												<th className="text-center">Amount ($)</th>
												<th>Expiry Date</th>
												<th>Status</th>
												<th className="actionbtnsets" style={{textAlign:'center'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.isrcpacklist.map((result,index) => {
												return (
													<tr key={index}>
														<td>{result.package_name}</td>
														<td className="text-center">{result.no_of_code}</td>
														<td className="text-center">{result.amount}</td>
														<td>{result.expiry_date}</td>
														<td><span className={(result.status==='1')?"label label-success":"label label-danger"}>{(result.status==='1')?"Active":"In Active"}</span></td>
														<td className="actionbtns" style={{textAlign:'center'}}><Link to="#" title="Edit ISRC Package" onClick={() => this.editISRCPackage(result.id)} className="btn btn-info custombtn"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;<Link to="#"  onClick={() => this.changeStatus(result.id,result.status)} title={(result.status === '1' )?'In Active':'Active'} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
														<Link to="#" title="Delete ISRC Package" onClick={() => this.deleteISRC(result.id)} className="btn btn-info custombtn"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>        
				</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}