import React from "react";
import { Redirect, Link } from 'react-router-dom';
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Axios from 'axios';
import { API_URL, TEST_SITE_KEY } from '../config/constants.js';
import DOMPurify from "dompurify";
import ls from 'local-storage';
import $ from 'jquery';
import { Spinner } from 'react-bootstrap';
const errors = {};
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}

class IsrcCodeForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			myalbumid: this.props.currentalid,
			fromcodelist: this.props.fromCodelist,
			isVerified: false,
			purchaseopt: 'individual',
			expiry_date: '',
			reason: '',
			email: '',
			isrcpackage: [],
			errors:[],
			isrcqty: '',
			isrcqtyerror: '',
			isrcamterror: '',
			error: null,
			msg: null,
			emailmsg: null,
			loader: null,
			payment: false,
			disablebtn: true,
			isrccodelist:[],
			totalamountisrc:'',
			amountisrc:'0',
			successclass: 'hideclass',
			redirect: false,
			submitbuttontext: 'Submit',
			enablesubmitbutton: false,
			disablebutton: false,
			stripe_detail:[],
			ccProcess: 0
		}
		this.onChangeisrcqty 	= this.onChangeisrcqty.bind(this);
		this.onBlurisrcqty 		= this.onBlurisrcqty.bind(this);
	}
	componentDidMount(){
		this.getloggedinuserdata();		
		this.getactiveisrcpackage();
		this.getisrccodeamount();
		setTimeout(() => {
			this.getisrccodeamount();
		}, 5000);
	}
	/*componentDidUpdate(){
		setTimeout(() => {
			this.getisrccodeamount();
		}, 3000);
	}*/
	
	getloggedinuserdata = () => {
		const obj = {
			userid:ls.get('userid')
		}
		Axios.post(base_api_url + 'userbytoken.php', obj)
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						email: res.data.listenerdata.email,
						stripe_detail: res.data.listenerdata.stripe_detail
					});
				} else {
					this.setState({
						email: '',
						stripe_detail: []
					});
				}
			}
		);
	}
	getactiveisrcpackage = () => {
		const obj = {}
		Axios.post(base_api_url + 'getactiveisrcpackage.php', obj)
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						isrcpackage: res.data.res_data,
					});
				} else {
					this.setState({
						isrcpackage: res.data.res_data,
					});
				}
			}
		);
	}	
	getisrccodeamount = () => {
		const obj = {setkey:'isrc_amount'}
		Axios.post(base_api_url + 'getsettingvalbykey.php', obj)
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						amountisrc: res.data.res_data.value,
					});
				} else {
					this.setState({
						amountisrc: '0',
					});
				}
			}
		);
	}
	onChangeisrcqty(e) {
		if(e.target.value){
			let qty = e.target.value;
			let calculatedamount = (parseFloat(qty) * parseFloat(this.state.amountisrc)).toFixed(2);
			if(this.state.stripe_detail.symbol == '%'){
				let processFee = parseFloat((calculatedamount*this.state.stripe_detail.amount)/100).toFixed(2);
				this.setState({
					ccProcess: processFee
				});
			}else{
				let processFee = parseFloat(calculatedamount+this.state.stripe_detail.amount).toFixed(2);
				this.setState({
					ccProcess: processFee
				});
			}
			this.setState({
				isrcqty: e.target.value,
				totalamountisrc: calculatedamount,
				disablebutton:true,
				isrcqtyerror:''
			})	
		}else{
			this.setState({
				isrcqty: '',
				totalamountisrc: 0,
				disablebutton:false,
				isrcqtyerror:'',
				ccProcess: 0
			})
		}
		const { stripe, elements } = this.props;
		if (!stripe || !elements) {return;}
		const card = elements.getElement(CardElement);
		card.clear();
	}
	onChangeOption(e) {
		this.setState({
			purchaseopt: e.target.value,
			isrcqty:'',
			isrcqtyerror:'',
			isrcamterror:'',
			payment: false,
		});
		const { stripe, elements } = this.props;
		if (!stripe || !elements) {return;}
		const card = elements.getElement(CardElement);
		card.clear();
	}
	onChangePackage(e) {
		this.setState({
			package: e.target.value
		})
		const objs = {
			isrcpackageid:e.target.value
		}
		Axios.post(base_api_url + 'getpackagedetails.php', objs)
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					let currentPackage = res.data.res_data;
					let calculatedamount = currentPackage.amount;
					if(this.state.stripe_detail.symbol == '%'){
						let processFee = parseFloat((calculatedamount*this.state.stripe_detail.amount)/100).toFixed(2);
						this.setState({
							ccProcess: processFee
						});
					}else{
						let processFee = parseFloat(calculatedamount+this.state.stripe_detail.amount).toFixed(2);
						this.setState({
							ccProcess: processFee
						});
					}
					this.setState({
						isrcqty: currentPackage.qty,
						expiry_date: currentPackage.expiry_date,
						totalamountisrc: currentPackage.amount,
						reason: currentPackage.reason
					})
					
					const obj = {
						userid:ls.get('userid'),
						email:this.state.email,
						purchaseopt:this.state.purchaseopt,
						isrcqty:currentPackage.qty,
					}
					Axios.post(base_api_url + 'isrccodeavailability.php', obj)
					.then(
						(result) => {
							if (result.data.res_status == 400) {
								this.setState({
									isrcqtyerror: result.data.res_message,
									enablesubmitbutton:false,
									disablebutton:false,
								});
							} else {
								this.setState({
									isrcqtyerror: result.data.res_message,
									enablesubmitbutton:true,
									disablebutton:true,
								});
							}
						}
					);
					
					
				} else {
					this.setState({
						isrcqty: '0',
						expiry_date: '',
						totalamountisrc: '0',
						reason: '',
						disablebutton:true,
						enablesubmitbutton:true,
						isrcqtyerror:'',
					})
				}
			}
		);
		
	}
	onBlurisrcqty(e) {
		let qty = e.target.value;
		const obj = {
			userid:ls.get('userid'),
			email:this.state.email,
			isrcqty:qty,
		}
		Axios.post(base_api_url + 'isrccodeavailability.php', obj)
		.then(
			(res) => {
				if (res.data.res_status == 400) {
					this.setState({
						isrcqtyerror: res.data.res_message,
						enablesubmitbutton:false,
						disablebutton:false,
					});
				} else {
					this.setState({
						isrcqtyerror: res.data.res_message
					});
				}
			}
		);
		if(qty){
			if(this.state.amountisrc > 1){
				this.setState({isrcamterror:''});
			}else{
				this.setState({isrcamterror:'You can not purchase code.Please contact to admin!'});
			}
		}
	}
	
	handleValidation = () => {
		let formIsValid = true;
		if(!this.state.purchaseopt){
			formIsValid = false;
			errors["option_error"] = "Please choose purchase option.";
		}
		if(this.state.purchaseopt === 'package'){
			if(!this.state.package){
				formIsValid = false;
				errors["package_error"] = "Please choose package.";
			}
		}
		this.setState({errors: errors});
		return formIsValid;

	}
	
	handleSubmit = async event => {
		event.preventDefault();
		if(this.handleValidation()){
			this.setState({
				disablebtn:false
			})
			const { stripe, elements } = this.props;
			if (!stripe || !elements) {return;}
			this.setState({enablesubmitbutton:false,disablebutton:false})
			const card = elements.getElement(CardElement);
			const result = await stripe.createToken(card);
			if (!result.error) {
				this.setState({submitbuttontext:<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '3vh',float:'right' }}><Spinner style={{ width: '15px', height: '15px', display: 'block'}} variant="light" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div>});
				this.stripeTokenHandler(card,result.token, this.state.email, this.state.isrcqty, this.state.totalamountisrc);
			}else{
				this.setState({
					loader:false,
					redirect: false,
					successclass: 'errormsg',
					submitbuttontext : 'Submit',
					msg: result.error.message
				})
			}
		}
	};
	
	stripeElementChange = (element, name) => {
		this.setState({
			cardError:'',
			formValidation: true
		});
		if(this.state.isrcqtyerror){
			this.setState({
				disablebtn:false,
				enablesubmitbutton:false
			});
		}else{
			if (element.complete) {
				this.setState({
					enablesubmitbutton:true,
					disablebtn:true,
					submitbuttontext : 'Submit',
				});
			} else {
				if(element.error){
					if(element.error.code){
						this.setState({
							disablebtn:false,
							enablesubmitbutton:false,
							formValidation: false,
							submitbuttontext : 'Submit',
							cardError: element.error.message
						});
					}
				}
			}
		}
	}
	stripeTokenHandler = (card,token,email,isrc_qty,amount) => {
		this.setState({
			loader:true
		});
		const formData 	= new FormData();
		let user_id 	= ls.get('userid'); 
		formData.append("user_id", user_id);
		formData.append("email", this.state.email);
		formData.append("isrcqty", isrc_qty);
		formData.append("totalamountisrc", amount);
		
		const paymentData = { token: token.id, email: email, amount:amount, qty:isrc_qty, rate:this.state.amountisrc,puroption:this.state.purchaseopt,user_id:user_id};
		Axios.post(base_api_url + 'isrccodepay.php', paymentData, { axiosConfig })
		.then(
			(result) => {
				if (result.data.res_status == 200) {
					Axios.post(base_api_url + 'createisrccode.php', formData, { axiosConfig })
					.then(
						(res) => {
							window.scrollTo(0, 0);
							if (res.data.res_status == 200) {
								card.clear();
								this.setState({
									loader:false,
									redirect: false,
									isrcqty: '',
									purchaseopt: '',
									package: '',
									successclass: 'successmsg',
									submitbuttontext : 'Submit',
									msg: res.data.res_message
								});
							} else {
								window.scrollTo(0, 0);
								this.setState({
									loader:false,
									redirect: false,
									isrcqty: '',
									purchaseopt: '',
									package: '',
									successclass: 'errormsg',
									submitbuttontext : 'Submit',
									msg: res.data.res_message
								})
							}
							setTimeout(() => {
								this.setState({
									loader:false,
									submitbuttontext : 'Submit',
									successclass: 'hideclass',
									msg: null,
									redirect: true
								});
								$('#myModal').modal('hide');
							}, 5000);
						}
					);
				} else {
					this.setState({
						loader:false,
						submitbuttontext : 'Submit',
						payment: false,
						disablebtn: true,
						successclass: 'errormsg',
						msg: result.data.res_message,
					})
				}
			}
		);
	}
	closeIsrcform=()=>{
		if(this.state.errors['package_error']){delete this.state.errors['package_error'];}
		if(this.state.errors['option_error']){delete this.state.errors['option_error'];}
		this.setState({
			isrcqtyerror:'',
			isrcamterror:'',
			isrcqty:'',
			totalamountisrc:'',
		});
		const { stripe, elements } = this.props;
		if (!stripe || !elements) {return;}
		const card = elements.getElement(CardElement);
		card.clear();
	}
	
	render() {
		if (this.state.redirect == true) {
			if(this.state.fromcodelist === '1'){
				const url = "/myisrccode";
				window.location.href = url;
			}
		}
		console.log('isrc_amount',this.state.amountisrc);
		console.log('isrcqty',this.state.isrcqty);
		
		return (
			<React.Fragment>
				<div className="modal-dialog">
					<div className="modal-content">
						<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true" onClick={this.closeIsrcform.bind(this)}>&times;</span></button>
						<div className="modal-body">
							<section className="become-artist-form">
								<div className="container">
									<div className="col-md-12"><div className={this.state.successclass}>{this.state.msg}</div></div>
									
										<div className="become-artist-part">
											<div className="row">
												<div className="col-md-12">
													<form ref={(el) => this.myFormRef = el} id="registration" name="registration" onSubmit={this.handleSubmit}>
														<div className="row">
															<div className="col-md-12">
																<p><b>Note*</b> - Choose package for discounts!</p>
															</div>
														</div>
														<div className="row">
															<div className="col-md-12">  
															  <div className="form-group">
																<label>Choose Purchase Option<span className="requiredastrik">*</span></label>
																<select className="custom-select"  onChange={this.onChangeOption.bind(this)} value={this.state.purchaseopt}>
																  <option value="individual">Individual</option>
																  <option value="package">Package</option>
																</select>
																<span className="errorMsg">{this.state.errors["option_error"]}</span>
															  </div>
															</div>
															{(this.state.purchaseopt === 'package')?
															<div className="col-md-12">  
															  <div className="form-group">
																<label>Select ISRC Package<span className="requiredastrik">*</span></label>
																<select className="custom-select"  onChange={this.onChangePackage.bind(this)} ref="ISRCpackType" value={this.state.package}>
																  <option value="">-- ISRC Package --</option>
																	{this.state.isrcpackage.map((result,index) => {
																		return (<option key={index} value={result.key}>{result.value}</option>)
																	})}
																</select>
																<span className="errorMsg">{this.state.errors["package_error"]}</span>
																<font className="errorMsg">{this.state.isrcqtyerror}</font>
															  </div>
															</div>
															:
															<div className="col-md-12">
																<div className="form-group">
																	<label>How many ISRC Code do you want?</label>
																	<div className="subsc">
																		<input type="number" tabIndex="1" className="form-control" required="required" min="1" value={this.state.isrcqty} onChange={this.onChangeisrcqty} onBlur={this.onBlurisrcqty} />
																	</div>
																	<font className="errorMsg">{this.state.isrcqtyerror}</font>
																</div>
															</div>
															}
															<div className="col-md-12">
																{(this.state.purchaseopt === 'individual')?(this.state.isrcqty > 0)?
																<>
																	<div className="">
																		<label><span className="subsdetail"><strong>Total Amount </strong></span> ${this.state.totalamountisrc} </label>
																	</div>
																	<div className="">
																		<label><span className="subsdetail"><strong>CC Processing Fee </strong></span> ${this.state.ccProcess} </label>
																	</div>
																	</>
																:"":(this.state.isrcqty > 0)?
																	<>
																		<div className="">
																			<label><span className="subsdetail"><strong>No. of codes </strong></span> {this.state.isrcqty} </label>
																		</div>
																		<div className="">
																			<label><span className="subsdetail"><strong>Total Amount </strong></span> ${this.state.totalamountisrc} </label>
																		</div>
																		<div className="">
																			<label><span className="subsdetail"><strong>CC Processing Fee </strong></span> ${this.state.ccProcess} </label>
																		</div>
																		<div className="">
																			<label><span className="subsdetail"><strong>Offer Expiry on purchase </strong></span>  {this.state.expiry_date} </label>
																		</div>
																		{(this.state.reason)?<div className="">
																			<label><span className="subsdetail"><strong>Reason </strong></span> {this.state.reason} </label>
																		</div>:''}
																	</>
																	:""
																}
																<font className="errorMsg">{this.state.isrcamterror}</font>
															</div>
															<div className="col-md-12">
																<div className="form-group">
																	<label>Card Details</label>
																	<div className="creditcardsection"><CardElement options={{ hidePostalCode: true }} onChange={(element) => this.stripeElementChange(element, 'card_number')} /></div>
																	<div className="errorMsg">{this.state.cardError}</div>
																</div>
																<div className="btn-toolbar-inner form-group">
																<font style={{'position':'absolute','left':'15px','fontSize':'11px','paddingTop':'20px'}}>Note:- {this.state.stripe_detail.stripe_detail}</font>
																	<div className="btn-group">
																	<button className="btn" disabled={(!this.state.disablebtn)?'disabled':'' }><i className="fa fa-check" aria-hidden="true"></i>{this.state.submitbuttontext} </button>
																	</div>
																</div>
															</div>
														</div>										
													</form>
												</div>
											</div>
										</div>
									</div>
							</section>
						</div>            
					</div>
				</div>
				
			</React.Fragment>
		);
	}
}

export default function InjectedCheckoutForm({stateValue}) {
	let fromCodelist = stateValue.codelist;
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<IsrcCodeForm fromCodelist={fromCodelist} stripe={stripe} elements={elements} />
			)}
		</ElementsConsumer>
	);
}