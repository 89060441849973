import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink } from 'react-router-dom';
import ls from 'local-storage';
import { API_URL } from '../config/constants.js';
import defaultimg from '../components/default.png';
import DashboardSongList from './dashboardsonglist.component.js';
import MusicPlayer from './musicPlayer.component';
import Spinner from '../includes/Spinner.component';
import { playAudio } from '../includes/playAudio.component';
import shortid from 'shortid';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
const base_api_url = API_URL;
const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 4 },
};
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};

//let _token = shortid.generate();
export default class ListenerDashboard extends Component {
	
	constructor(props){
		super(props);
		this.audioRef = React.createRef();
		this.state = {
		  total_song:'',
		  approve_song:'',
		  followers:'',
		  prayer_request:'',
		  income:'',
		  index:0,
		  songlist:[],
		  musiclist:'',
		  toppickslist:[],
		  featuredartistlist:[],
		  recentplayedlist:[],
		  recentlyplayedsglist:[],
		  favoritesonglist:[],
		  featuredsonglist:[],
		  madefyousonglist:[],
		  upcomingevent:'',
		  upcomingeventcount:'',
		  myevent:'',
		  myeventcount:'',
		  error: null,
		  msg: null,
		  successclass:null,
		  redirect:false,
		  songInfo: {
			currentTime: 0,
			duration: 0,
			animationPercentage: 0,
			volume: 0,
		  },
		  isPlaying: false,
		  mute: false,
		  currentSong: {},
		  loading:true,
		  membership_status: ls.get('membership_status'),
		}
	}
	
	componentDidMount(){
		document.title = "CONQER Music - My Dashboard";
		this.gettoppicksdata();
		this.getdashboarddata();
	}
	getdashboarddata = (type) =>{
		const obj = {
			listenerid: ls.get('userid')
		}
				
		Axios.post(base_api_url+'listener-dashboard.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						loading:false
					})
					let upcoming_ev_count = res.data.res_data.upcoming_ev_count;
					let upcomingevdata = '';
					if(upcoming_ev_count > 0){
						upcomingevdata = res.data.res_data.upcoming_ev[0];
					}
					let my_ev_count = res.data.res_data.mycoming_ev_count;
					let myevdata = '';
					if(my_ev_count > 0){
						myevdata = res.data.res_data.mycoming_ev[0];
					}
					let recentplaysong = (type == 'like')?this.state.musiclist:res.data.res_data.featured_song;//res.data.res_data.recentplay_song;
					this.setState({
						recentplayedlist:res.data.res_data.recentplay_song,						
						recentlyplayedsglist:res.data.res_data.recently_played_song,						
						featuredartistlist:res.data.res_data.featured_artist,						
						favoritesonglist:res.data.res_data.favorite_song,						
						featuredsonglist:res.data.res_data.featured_song,						
						madefyousonglist:res.data.res_data.madefyou_song,						
						upcomingevent:upcomingevdata,						
						upcomingeventcount:upcoming_ev_count,
						myevent:myevdata,						
						myeventcount:my_ev_count,						
						/*musiclist:recentplaysong,*/
						musiclist:ls.get('localMusicList')?ls.get('localMusicList'):recentplaysong,
					},
					()=>{
						this.setState({
							currentSong:this.state.musiclist[(ls.get('localIndex'))?ls.get('localIndex'):this.state.index]
						})
					}
					);
				}else{
					this.setState({
						loading:false
					})
				}
			}
		);
	}
	gettoppicksdata = (type) =>{
		const objs = {}	
		let toppicks = [];
		Axios.post(base_api_url+'toppicks.php',objs,{axiosConfig})
		.then(
			(res) => {
				let resdata = res.data.res_data;
				if(res.data.res_status==200){
					resdata.map((result,index) => {
						let toppickslink = "/albumsongs/"+result.album_id;
						toppicks.push(<div className="item" data-value={index}><Link to={toppickslink}><div className="featureartprifles"><div className="featrueimages"><img onError={this.addDefaultSrc} src={result.album_banner} /></div></div></Link></div>);
					})
					this.setState({
						//loading:false,
						toppickslist:toppicks
					})
				}else{
					this.setState({
						loading:false,
						toppickslist:''
					})
				}
			}
		);
	}
	setMute = () => {
		this.setState(
		  {
			mute: !this.state.mute,
		  },
		  () => (this.audioRef.current.muted = this.state.mute)
		);
	};
	setCurrentSOng = (song,index,songlist)=>{		
		song.token = shortid.generate();
		ls.remove('localCurrentSong');
	    ls.remove('localMusicList');
	    ls.remove('localIndex');
	    ls.set('localCurrentSong', song);
	    ls.set('localMusicList', songlist);
	    ls.set('localIndex', index);
		this.setState({
			currentSong:ls.get('localCurrentSong'),
			index:ls.get('localIndex'),
			isPlaying:true,
			musiclist:ls.get('localMusicList'),
			likeStatus:'',
			is_favorite:''
		},
		()=>{
			/**/
			this.audioPlayHandler(song);
			
		});
	}
	insertSongInfo(song){
		const objs = {
		  	user_id: ls.get('userid'),
		  	song_id: song.song_id,
		  	played_time: '0',
		  	token: song.token
	  	};
		Axios.post(base_api_url + 'songplayed.php', objs, { axiosConfig }).then((result) => {
		  if (result.data.res_status == 200) {
		  	this.setState({
		  		isCurrent: true
		  	});
			  console.log('song data saved successfully');
		  } 
		});
	}
	/*updateSongInfo(){
		const objs = {
		  	user_id: ls.get('userid'),
		  	song_id: song.song_id,
		  	played_time: '0',
	  	};
		Axios.post(base_api_url + 'updatesongplayed.php', objs, { axiosConfig }).then((result) => {
		  if (result.data.res_status == 200) {
			  console.log('song data saved successfully');
		  } 
		});
	}*/
	timeUpdateHandler = (e) => {
		const current = e.target.currentTime;
	    const duration = e.target.duration;

	    if (current === duration) {
	      this.setState(
	        {
	          isPlaying: !this.state.isPlaying
	        },
	        () => {
	        	if(this.state.index < this.state.musiclist.length - 1 ){
	        		if (this.state.index === this.state.musiclist.length - 1) {
			            this.audioPlay(0);
		          	} else {
			            this.audioPlay(this.state.index + 1);
		          	}
	        	}	          
	        }
	      );
	    }
	    const roundedCurrent = Math.round(current);
	    const roundedDuration = Math.round(duration);
	    const percentage = Math.round((roundedCurrent / roundedDuration) * 100);
	    this.setState({
	      songInfo: {
	        currentTime: current,
	        duration: duration,
	        animationPercentage: percentage,
	        volume: e.target.volume
	      }
	    });
	  };
	  audioPlay(key) {
	    let songList = this.state.musiclist[key];
	    if(songList.token == undefined){
	      songList.token = shortid.generate();
	    }
	    let newObj = {
	      currentTime: 0,
	      duration: this.state.musiclist[key].duration,
	      animationPercentage: 0,
	      volume: 0
	    };

	    this.setState(
	      {
	        index: key,
	        currentSong: this.state.musiclist[key],
	        isPlaying: true,
	        songInfo: newObj
	      },
	      () => {
	        this.playAudioSong(this.state.isPlaying, this.audioRef, songList);
	      }
	    ); 
	  }
	  audioPlayHandler(song) {
		//playAudio(this.state.isPlaying, this.audioRef, isCurrent);
		this.playAudioSong(this.state.isPlaying, this.audioRef, song);
		//playSongHandler(this.state.isPlaying, this.audioRef);
	  }
	  playAudioSong = (isPlaying, audioRef, song) => {
		  if (isPlaying) {
		  	this.insertSongInfo(song);
		    audioRef.current.play();
		  } else {
		    audioRef.current.pause();
		  }
		};
	  setIsPlaying = () => {
		this.setState({
		  isPlaying: !this.state.isPlaying,
		});
	  };

	  

	  setCurrentSong = (ind) => {
		this.setState({
		  currentSong: this.state.musiclist[ind],
		  index: ind,
		});
	  };

	  setSongInfo = (parameter) => {
		this.setState({
		  songInfo: parameter,
		});
	  };

	  getTime = (timer) => {
		if (this.state.isPlaying) {
		  return Math.floor(timer / 60) + ':' + ('0' + Math.floor(timer % 60)).slice(-2);
		} else {
		  return Math.floor(timer);
		}
	  };
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	likeSongFu = (song_id,like_status, musiclist, index) => {
		const objs = {
		  song_id: song_id,
		  like: like_status,
		  user_id: ls.get('userid')
		};
		
		Axios.post(base_api_url + 'songlike.php', objs, { axiosConfig }).then((result) => {
		  let returndatas = result.data.res_message;
		  if (Number(result.data.res_status) === 200) {
			if (returndatas !== 'null') {
			  this.setState({
					successclass:'successmsg',						
					msg:returndatas
			  },()=>{
				this.setState({musiclist:musiclist, index:index, likeStatus: like_status});
				let updateMusicList = musiclist[ls.get('localIndex')?ls.get('localIndex'):index];
		        updateMusicList.likestatus = like_status;
		        musiclist[ls.get('localIndex')] = updateMusicList;
		        ls.remove('localMusicList');
		        ls.set('localMusicList', musiclist);
				this.getdashboarddata('like');
			});
			}
		  } else {
			this.setState({
				successclass:'errormsg',						
				msg:returndatas
			});
		  }
		  setTimeout(() => {
				this.setState({
					successclass:null,
					msg:null
				});
			}, 10000)
		});
	}
	_favoriteSong = (song_id,is_favorite, musiclist, index) => {
		let favorite = (is_favorite == '0')?'1':'0';
		const objs = {
		  song_id: song_id,
		  is_favorite: favorite,
		  user_id: ls.get('userid')
		};
		
		Axios.post(base_api_url + 'add-to-favorite.php', objs, { axiosConfig }).then((result) => {
		  let returndatas = result.data.res_message;
		  if (Number(result.data.res_status) === 200) {
			if (returndatas !== 'null') {
			  this.setState({
					successclass:'successmsg',						
					msg:returndatas
			  },()=>{
				this.setState({musiclist:musiclist, index:index, is_favorite: favorite})
				let updateMusicList = musiclist[ls.get('localIndex')?ls.get('localIndex'):index];
		        updateMusicList.is_favorite = favorite;
		        musiclist[ls.get('localIndex')] = updateMusicList;
		        ls.remove('localMusicList');
		        ls.set('localMusicList', musiclist);
				this.getdashboarddata('like');
			});
			}
		  } else {
			this.setState({
				successclass:'errormsg',						
				msg:returndatas
			});
		  }
		  setTimeout(() => {
				this.setState({
					successclass:null,
					msg:null
				});
			}, 10000)
		});
	}
	
	render() {
		let featuredalist 		= this.state.featuredartistlist;
		let recentsonglist 		= this.state.recentplayedlist;
		let recentlyplaysglist 	= this.state.recentlyplayedsglist;
		let favoritesongs 		= this.state.favoritesonglist;
		let featuredsongs 		= this.state.featuredsonglist;
		let madefysongs 		= this.state.madefyousonglist;
		let upcomeevent 		= this.state.upcomingevent;
		let upeventcount 		= this.state.upcomingeventcount;
		let myevcount 			= this.state.myeventcount;
		let myevents 			= this.state.myevent;
		let usertocken			= ls.get('token');
		const { musiclist, index, currentTime, pause } = this.state;
		const currentSong 		= this.state.currentSong;
		let visibleclass 		= 'playerimplement d-none';
		if(currentSong){
			visibleclass 		= 'playerimplement d-block';
		}
		let maxlimit 	= '20';
		let almaxlimit 	= '15';
		if(this.state.loading){
			return <Spinner />;
		}else{
			return (
				<>
					<div className="main-dashsection">
						{(this.state.membership_status != 'Active')?
						<div className="col-md-12 membershipmsg">				
							<p>{(this.state.membership_status == 'Inactive') ? 'Your account subscription has ended, Please renew your subscription plan.':(this.state.membership_status == null)?"You don't have any membership, Please purchase a subscription plan.":''}<br /><NavLink to={'/listener-change-plan/'} >Click Here </NavLink> </p>					
						</div>
						:
					   <section className="contentpards">
						  <div className="row toartihead">
							 <div className="col-md-12">
								<div className="todpfive">Top Picks</div>
							 </div>
						  </div>
						  <div className="toppickss">
							  <AliceCarousel
									mouseTracking
									autoPlay
									autoPlayStrategy="all"
									autoPlayInterval={1000}
									animationDuration={1000}
									animationType="fadeout"
									infinite
									touchTracking={false}
									disableDotsControls
									disableButtonsControls
									items={this.state.toppickslist}
									responsive={responsive}
							  />
						  </div>
						  <div className="row toartihead">
							 <div className="col-md-12">
								<div className="todpfive">Featured Artist <span className="viewcomplete">{(featuredalist.length !== 0 )?<Link to="/featured-artist"> View All</Link>:''}</span></div>
							 </div>
						  </div>
						  
							 {(featuredalist.length !== 0 )?
							 	<div className="featuredrow">
							 <div className="row featuredartist">
								 {featuredalist.map((result,index) => {
									 return (
										 <div className="col-md-3">
											<Link to={'/listener-assong/'+result.user_id}>
											<div className="featureartprifles">
											   <div className="featrueimages">
												  <img onError={this.addDefaultSrc} src={result.profile_pic} />
											   </div>
											   <div className="featurestext">
												  <h3>{result.name}</h3>
												  <span className="nooffollowers">{result.followercount} Followers</span>
											   </div>
											</div>
											</Link>
										 </div>	 
									)}
								 )}
							 </div> </div>
							 :<div className="row eventsectionstr"><div className="col-md-12 mb-5"><div className="notfound">No Artist found!</div></div></div>
							 }
						  {(featuredsongs.length !== 0  && featuredsongs[0].song_id)?
							  <>
							  <div className="row toartihead">
								 <div className="col-md-12">
									<div className="boxthreeheading">
									   <div className="todpfive">Featured Songs <span className="viewcomplete">{(featuredsongs.length !== 0 )?<Link to="/listener-featuredsongs/"> View All</Link>:''}</span></div>
									</div>
								 </div>
							  </div>
							  <div className="row featuredartist">
								{(featuredsongs.length)?featuredsongs.map((results) => {
									let topsongurl = (results.song_id)?'/topssongs/'+results.song_id:'#';
									return (
										(results.song_id)?
										<div className="col-md-3">
											<Link to={topsongurl}>
											<div className="featureartprifles">
											   <div className="featrueimages">
												  <img onError={this.addDefaultSrc} src={results.img} />
											   </div>
											   <div className="featurestext">
												  <h3 title={results.name}>{ ((results.name).length > maxlimit) ? (((results.name).substring(0,maxlimit-3)) + '...') :results.name }</h3>
												  <span className="nooffollowers songlikes"><i className="fa fa-heart"></i> {results.slike}</span>
												  <span className="nooffollowers playicons"><i className="fa fa-play-circle"></i> {results.slistened}</span>
											   </div>
											</div>
											</Link>
										</div>:""
									)
									}):<div className="not-found"><span>No song found!</span></div>
								} 
							  </div>
							  </ >
							  :''}
						  <div className="row relatedfavsection">
							 <div className="col-md-4 recentpl">
								<div className="boxthreeheading">
								   <div className="todpfive">Top Songs <span className="viewcomplete">{(recentsonglist.length !== 0 )?<Link to="/listener-topsongs/"> View All</Link>:''}</span></div>
								</div>
								<div className="recentsongs">
									{(recentsonglist.length !== 0 )?<div className="rsongs">
										{recentsonglist.map((result,index) => {
											if (index < 5) {
												return (<DashboardSongList recentsongdata={result}  setcurrentsOng={this.setCurrentSOng} sgindex={index} songslist={recentsonglist} />)
											}
										}
										)
									}</div>:<div className="notfound">No song found!</div>}
									
								</div>
							 </div>
							 <div className="col-md-4 favoritesongs">
								<div className="boxthreeheading">
								   <div className="todpfive">Favorite Songs <span className="viewcomplete">{(favoritesongs.length !== 0 )?<Link to="/listener-favoritesongs/"> View All</Link>:''}</span></div>
								</div>
								<div className="favoritesongarea">
									{(favoritesongs.length !== 0 )?<div className="rsongs">{favoritesongs.map((result,index) => {
										if (index < 5) {
											return (<DashboardSongList recentsongdata={result}  setcurrentsOng={this.setCurrentSOng} sgindex={index} songslist={favoritesongs} />)}})}</div>:<div className="notfound">No song found!</div>}
									
								</div>
							 </div>
							 <div className="col-md-4 featuredsongs">
								<div className="boxthreeheading">
								   <div className="todpfive">Recently Played Songs <span className="viewcomplete">{(recentlyplaysglist.length !== 0 )?<Link to="/listener-recentsongs/"> View All</Link>:''}</span></div>
								</div>
								<div className="featuredsongsarea">
								   {(recentlyplaysglist.length !== 0 && recentlyplaysglist[0].user_id != '0')?<div className="rsongs">{recentlyplaysglist.map((result,index) => { if (index < 5) { return (<DashboardSongList recentsongdata={result}  setcurrentsOng={this.setCurrentSOng} sgindex={index} songslist={recentlyplaysglist} />)}})}</div>:<div className="notfound">No song found!</div>}
								</div>
							 </div>
						  </div>
						  {(madefysongs.length !== 0 )?
						  <>
						  <div className="row toartihead">
							 <div className="col-md-12">
								<div className="boxthreeheading">
								   <div className="todpfive">Made For You <span className="viewcomplete"><Link to={'/album-list'}>View All</Link></span></div>
								</div>
							 </div>
						  </div>
						  <div className="row featuredartist">
							 {	madefysongs.map((madefyresult,index) => {
									return (<div className="col-md-3"><Link to={'/albumsongs/'+madefyresult.album_id} ><div className="featureartprifles"><div className="featrueimages"><img onError={this.addDefaultSrc} src={madefyresult.album_banner} /></div><div className="featurestext"><h3 title={madefyresult.album_name}>{ ((madefyresult.album_name).length > almaxlimit) ? (((madefyresult.album_name).substring(0,almaxlimit-3)) + '...') :madefyresult.album_name }</h3><span className="nooffollowers">{madefyresult.songcount} Songs</span></div></div></Link></div>)
								})
							} 
						  </div>
						  </ >
						  :''}
						  
						 
						  <div className="row eventsectionstr">
							 <div className="col-md-6">
								<div className="boxthreeheading">
								   <div className="todpfive">Upcoming Events <span className="viewcomplete"><Link to={'/listener-myevents/'+usertocken}>View All</Link></span></div>
								</div>
								{(upeventcount > 0)?
								<div className="event-area">
								   <div className="event-date">
									  <span className="event-cirlce">
									  {upcomeevent.startday}<br />{upcomeevent.startmonth}
									  </span>
								   </div>
								   <div className="event-detailstime">
									  <span className="badge">Upcoming</span>
									  <h3><Link to={'listener-viewevent/'+upcomeevent.id}>{upcomeevent.title}</Link></h3>
									  <span className="timeplace"><i className="fa fa-clock-o" aria-hidden="true"></i> <span className="upcomevspan">{upcomeevent.startdayname}, {upcomeevent.startday} {upcomeevent.startmonth}, {upcomeevent.event_start_time} </span> – <span className="upcomevspanaft">{upcomeevent.enddayname}, {upcomeevent.endday} {upcomeevent.endmonth}, {upcomeevent.event_end_time}</span></span>
										{(upcomeevent.address !==null)?
										  <span className="timeplace"><i className="fa fa-location-arrow" aria-hidden="true"></i> {(upcomeevent.address !==null)? upcomeevent.address:''} {(upcomeevent.city !==null)? upcomeevent.city:''} <br/> {(upcomeevent.state !==null)? upcomeevent.state:'' } {(upcomeevent.country !==null)? upcomeevent.country:'' } {(upcomeevent.zipcode !==null)? upcomeevent.zipcode:''}</span>:''}
										  <span className="timeplace"><i className="fa fa-user-circle-o" aria-hidden="true"></i> {upcomeevent.artist_name}</span>
								   </div>
								   <div className="event-pic">
									  <img onError={this.addDefaultSrc} src={upcomeevent.event_banner} />
								   </div>
								</div>
								:<div className="notfound">No upcoming event found!</div>}
							 </div>
							 <div className="col-md-6">
								<div className="boxthreeheading">
								   <div className="todpfive">My Upcoming Booking <span className="viewcomplete"><Link to={'/listener-eventbooking/'}>View All</Link></span></div>
								</div>
								{(myevcount >0)?
								<div className="event-area">
								   <div className="event-date">
									  <span className="event-cirlce">
									  {myevents.startday}<br />{myevents.startmonth}
									  </span>
								   </div>
								   <div className="event-detailstime">
									  <span className="badge">Upcoming</span>
									  <h3><Link to={'listener-viewevent/'+myevents.id}>{myevents.title}</Link></h3>
									  <span className="timeplace"><i className="fa fa-clock-o" aria-hidden="true"></i> <span className="upcomevspan">{myevents.startdayname}, {myevents.startday} {myevents.startmonth}, {myevents.event_start_time} </span> – <span className="upcomevspanaft">{myevents.enddayname}, {myevents.endday} {myevents.endmonth}, {myevents.event_end_time}</span></span>
										{(myevents.address !==null)?
										  <span className="timeplace"><i className="fa fa-location-arrow" aria-hidden="true"></i> {(myevents.address !==null)? myevents.address:''} {(myevents.city !==null)? myevents.city:''} <br/> {(myevents.state !==null)? myevents.state:'' } {(myevents.country !==null)? myevents.country:'' } {(myevents.zipcode !==null)? myevents.zipcode:''}</span>:''}
										  <span className="timeplace"><i className="fa fa-user-circle-o" aria-hidden="true"></i> {myevents.artist_name}</span>
								   </div>
								   <div className="event-pic">
									  <img onError={this.addDefaultSrc} src={myevents.event_banner} />
								   </div>
								</div>
								:<div className="notfound">No upcoming booking found!</div>}
								
							 </div>
						  </div>
					   </section>
					   }
						{(currentSong !== undefined)?
						<section className={visibleclass} >
						   <MusicPlayer
							  setIsPlaying={this.setIsPlaying}
							  audioRef={this.audioRef}
							  currentSong={currentSong}
							  songInfo={this.state.songInfo}
							  isPlaying={this.state.isPlaying}
							  setSongInfo={this.setSongInfo}
							  musiclist={this.state.musiclist}
							  setCurrentSong={this.setCurrentSong}
							  setMute={this.setMute}
							  index={this.state.index}
							  mute={this.state.mute}
							  likeSongFu={this.likeSongFu}
							  _favoriteSong={this._favoriteSong}
							  likeSongStatus = {(this.state.likeStatus)?this.state.likeStatus:currentSong.likestatus}
							  is_favorite = {(this.state.is_favorite)?this.state.is_favorite:currentSong.is_favorite}
							/>
							<audio onLoadedMetadata={this.timeUpdateHandler} onTimeUpdate={this.timeUpdateHandler} ref={this.audioRef} src={currentSong.audio}></audio>
						</section>
						:""}

					</div>
				</>
			)
		}
	}
}