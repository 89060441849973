import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom';
import ls from 'local-storage';

export default class Logout extends Component {
	constructor(props){
		super(props);
		ls.remove('storeData');
		
	}
	render() {		
		return <Redirect exact to="/admin" />
	}
}