import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { addDays, subDays, setHours, setMinutes } from 'date-fns';
import Loader from "react-loader-spinner";
import DOMPurify from "dompurify";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Spinner from '../../../includes/Spinner.component';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const errors = {};
export default class EventTickets extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	ticketlist:[],
      	errors:[],
      	tickettitle: '',
		ticketdescription: '',
		eventstart:'',
		eventend:'',
		btndisable:'',
		ticketprice: '',
		ticketenddate: '',
		ticketstatus: '1',
		ticketaccess: 'public',
		eventseat: '',
      	btnSubmitText: 'Submit',
      	disabled:false
      }
    }
    eventTicketListData(){
    	const eventid = this.props.match.params.id;
    	const obj = {
    		event_id: eventid
    	}
	    Axios.post(API_URL + 'eventticketlist.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		ticketlist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						loader: false
					});
		        }
		      	$(document).ready(function () {
		    		$('#eventTicketList').DataTable().destroy();
			        setTimeout(function(){
			        $('#eventTicketList').DataTable({
			        	"destroy": true,
						"stateSave": true,
						"lengthMenu": [[5, 10, 15 -1], [5, 10, 15,  "All"]],
						language: {
							search: "Search"
						},
						columnDefs: [
							{ "width": "20%", "targets": 0 },
							{ "width": "15%", "targets": 5 },
							{ orderable: false, targets: -1 }
						]
			        });
			        } ,50);
			    });	        	   
	    	}

	    );
	    const obj1 = {
			id: eventid
		}
		Axios.post(API_URL + 'getevent.php', obj1, { axiosConfig })
		.then(
			(res) => {					
				if (res.data.res_status == 200) {
					this.setState({
						eventstart: res.data.res_data.event_start_date,
						eventend: res.data.res_data.event_end_date
					})
				} else {
					this.setState({
						eventstart: '',
						eventend: ''
					});
				}
			}
		);
    }
    componentDidMount(){
		document.title = "Manage Tickets";
    	this.eventTicketListData();
    	$(document).ready(function () {
    		$('#eventTicketList').DataTable().destroy();
	        setTimeout(function(){
	        $('#eventTicketList').DataTable({
					"destroy": true,
					"stateSave": true,
					"lengthMenu": [[5, 10, 15 -1], [5, 10, 15,  "All"]],
					language: {
						search: "Search",
						emptyTable: "No event ticket!"
					},
					columnDefs: [
						{ "width": "20%", "targets": 0 },
				    { "width": "15%", "targets": 5 },
	   				{ orderable: false, targets: -1 }
				]
	        });
	        } ,50);
	    }); 
    }
    deleteEventTickets(id){
    	if(window.confirm("Are you sure you want to delete?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deleteticket.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.eventTicketListData();
						this.setState({
							successclass:'successmsg',
							msg:'Event Ticket deleted successfully!',
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:'Event Ticket not deleted, please try again!'
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
    changeStatus(id,status){
    	this.setState({
    		loader:true
    	});
    	const axiosConfig = {
      		'Content-Type': 'multipart/form-data',
      		"Access-Control-Allow-Origin": "*",
    	};
    	const obj = {
			id:id,
			status:status
		}			
		Axios.post(API_URL+'updateevticketstatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.eventTicketListData();
					this.setState({
						successclass:'successmsg',
						msg:(status === '1')?'Event ticket unpublished successfully.':'Event ticket published successfully.',
					});
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:'Event status not updated, please try again!'
					})
				}
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 10000)
			}
		);
    }
    editTicket = (eventid) => {
    	this.setState({
			loader:true
		})
		const obj = {
			id: eventid
		}
		Axios.post(API_URL + 'getticketdata.php', obj, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						tickettitle: res.data.res_data.ticket_title,
						ticketdescription: res.data.res_data.description,
						ticketprice: res.data.res_data.price,
						ticketEndDate: new Date(res.data.res_data.end_date),
						ticketstatus: res.data.res_data.status,
						ticketaccess: res.data.res_data.access,
						eventseat: res.data.res_data.seats,
						ticket_id: res.data.res_data.id,
						btnSubmitText: 'Update',
						loader:false,
						method:'update'
					});
					
				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Internal Server Error, please try again!',
						loader:false
					})
				}
				this.eventTicketListData();
			}
		);
	}
    onChangeTicketTitle(e) {
		if(this.state.errors['titleerror']){delete this.state.errors['titleerror'];}
		this.setState({
			tickettitle: e.target.value
		})
	}
	onChangeTicketDescription(e) {
		this.setState({
			ticketdescription: e.target.value
		})
	}

	onChangeTicketPrice(e) {
		if(this.state.errors['priceerror']){delete this.state.errors['priceerror'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({
				ticketprice: e.target.value
			})
		}
	}
	
	onChangeTicketStatus(e) {
		this.setState({
			ticketstatus: e.target.value
		})
	}

	onChangeTicketAccess(e) {
		this.setState({
			ticketaccess: e.target.value
		})
	}

	onChangeTicketSeats(e) {
		if(this.state.errors['seaterror']){delete this.state.errors['seaterror'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({
				eventseat: e.target.value
			})
		}
	}
	handleTicketChange(date) {
		if(this.state.errors['dateerror']){delete this.state.errors['dateerror'];}
	    this.setState({
	      ticketEndDate: date
	    })
	}
	onTicketSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
				loader:true,
				disabled:true,
				btnSubmitText:'Please Wait...'
			});
			if(this.state.method === 'update'){
				this.updateTickets();
			}else{
				this.saveTickets();
			}
		}
	}
	saveTickets(){
		const formData = new FormData();
		const ticketEndDate = moment(this.state.ticketEndDate);
		formData.append("event_id", this.props.match.params.id);
		formData.append("ticket_id", this.state.ticket_id);
		formData.append("ticket_title", this.state.tickettitle);
		formData.append("description", this.state.ticketdescription);
		formData.append("price", this.state.ticketprice);
		formData.append("end_date", ticketEndDate.format('yyyy-MM-DD'));
		formData.append("access", this.state.ticketaccess);
		formData.append("seats", this.state.eventseat);
		formData.append("status", this.state.ticketstatus);
		Axios.post(API_URL + 'add-event-ticket.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: 'Event ticket created successfully!',
						tickettitle: '',
						ticketdescription: '',
						btnSubmitText: 'Submit',
						disabled:false,
						ticketprice: '',
						ticketEndDate: '',
						ticketstatus: '',
						ticketaccess: '',
						eventseat: '',
						ticket_id: '',
						loader:false,
					});
					this.eventTicketListData();
				} else {
					this.setState({
						successclass: 'errormsg',
						btnSubmitText:'Submit',
						disabled:false,
						loader:false,
						msg: 'Event ticket not created , please try again!'								
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	updateTickets(){
		const formData = new FormData();
		const ticketEndDate = moment(this.state.ticketEndDate);
		formData.append("event_id", this.props.match.params.id);
		formData.append("ticket_id", this.state.ticket_id);
		formData.append("ticket_title", this.state.tickettitle);
		formData.append("description", this.state.ticketdescription);
		formData.append("price", this.state.ticketprice);
		formData.append("end_date", ticketEndDate.format('yyyy-MM-DD'));
		formData.append("access", this.state.ticketaccess);
		formData.append("seats", this.state.eventseat);
		formData.append("status", this.state.ticketstatus);
		Axios.post(API_URL + 'update-event-ticket.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: 'Ticket updated successfully!',
						tickettitle: '',
						ticketdescription: '',
						btnSubmitText: 'Submit',
						disabled:false,
						ticketprice: '',
						ticketEndDate: '',
						ticketstatus: '',
						ticketaccess: '',
						eventseat: '',
						method:'',
						ticket_id: '',
						loader:false
					});
					this.eventTicketListData();
				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Ticket not updated , please try again!',
						btnSubmitText:'Update',
						disabled:false,
						loader:false,
						method:'update'
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	handleValidation = () => {
		let formIsValid = true;
		
		if(!this.state.tickettitle){
			formIsValid = false;
			errors["titleerror"] = "Please enter ticket title.";
		}

		if(!this.state.ticketprice){
			formIsValid = false;
			errors["priceerror"] = "Please enter ticket price.";
		}else if(this.state.ticketprice < 1){
			formIsValid = false;
			errors["priceerror"] = "Please enter minimum 1$.";
		}
		
		if(!this.state.ticketEndDate){
			formIsValid = false;
			errors["dateerror"] = "Please enter end date.";
		}
		if(!this.state.eventseat){
			formIsValid = false;
			errors["seaterror"] = "Please enter number of seat.";
		}else if(this.state.eventseat < 1){
			formIsValid = false;
			errors["seaterror"] = "Please enter minimum 1 seat.";
		}

		this.setState({errors: errors});		
        return formIsValid;
	
	}
    render() {
    	const eventid = this.props.match.params.id;
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
									<h3 className="box-title">Manage Tickets</h3>
									<div className="box-tools">
										<Link to={'/admin/events/edit/'+eventid} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</Link>
									</div>            
								</div>
								<div className="box-body no-padding"> 
									<div className="nav-tabs-custom">
										<div className="">
											<div className={this.state.tabdetailclass} id="tab_1">
												<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onTicketSubmit.bind(this)}>
													<div className="row">
														<div className="col-md-12">
															<div className="sectionhead">
																<div className="headsec">
																	<h3>Ticket Information</h3>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Ticket Title<span className="requiredastrik">*</span></label>
																<input type="text" ref={this.titleInput} placeholder="Ticket Title" className="form-control" id="tickettitle" name="tickettitle" onChange={this.onChangeTicketTitle.bind(this)} value={this.state.tickettitle} />
																<font className="errorMsg">{this.state.errors["titleerror"]}</font>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Description</label>
																<input type="text" className="form-control"placeholder="Description" id="ticket_desc" name="ticket_desc" value={this.state.ticketdescription} onChange={this.onChangeTicketDescription.bind(this)} />
															</div>
														</div>
													</div>
													<div className="row">

														<div className="col-md-6">
															<div className="form-group">
																<label>Price<span className="requiredastrik">*</span></label>
																<input type="text" placeholder="Price" className="form-control" name="ticketprice" value={this.state.ticketprice} onChange={this.onChangeTicketPrice.bind(this)} />
																<font className="errorMsg">{this.state.errors["priceerror"]}</font>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Ticket End Date<span className="requiredastrik">*</span></label>
																<DatePicker
																  selected={ this.state.ticketEndDate }
																  onChange={ this.handleTicketChange.bind(this) }
																  name="ticketEndDate"
																  dateFormat="MM/dd/yyyy"
																  dropdownMode="select"
																  showMonthDropdown
																  showYearDropdown
																  adjustDateOnChange
																  className="form-control"
																  placeholderText="Ticket End Date"
																  minDate={new Date(this.state.eventstart)}
																  maxDate={new Date(this.state.eventend)}
															  />
																<font className="errorMsg">{this.state.errors["dateerror"]}</font>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Status</label>
																<select className="custom-select" name="ticketstatus"  onChange={this.onChangeTicketStatus.bind(this)} value={this.state.ticketstatus}>
																	<option value="1" selected={(this.state.ticketstatus=='1')?'selected':''}>Publish</option>
																	<option value="0" selected={(this.state.ticketstatus=='0')?'selected':''}>Unpublish</option>
																</select>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Number of Seats<span className="requiredastrik">*</span></label>
																<input type="text" placeholder="Number of Seats" className="form-control" onChange={this.onChangeTicketSeats.bind(this)} value={this.state.eventseat} maxlength="9"/>
																<font className="errorMsg">{this.state.errors["seaterror"]}</font>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<small><strong>Note</strong>* that, 2.5 % fees are applicable on all ticket sales for paid event. This fee is applicable on amount after getting discount. </small>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12 notmainbtn">
															<button type="submit" disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>           
								</div>
								<hr />
								<div className="box-body no-padding">
									<div className="table-responsive">
										<table id="eventTicketList" className="table no-margin table-striped table-hover">
											<thead>
												<tr>
													<th>Title</th>
													<th>Price</th>
													<th>End Date</th>
													<th className="text-center">Seats</th>
													<th className="text-center">Available Seats</th>
													<th>Status</th>
													<th className="actionbtnsets" style={{textAlign:'center'}}>Actions</th>
												</tr>
											</thead>
											<tbody>
												{this.state.ticketlist.map((result) => {
													return (
														<tr>
															<td>{result.ticket_title}</td>
															<td>{(result.price)?'$'+result.price:''}</td>
															<td>{result.end_date}</td>
															<td className="text-center">{result.seats}</td>
															<td className="text-center">{result.available_seats}</td>
															<td>{(result.status=='1') ? <span className="label label-success">Published</span> : <span className="label label-danger">Unpublished</span>}</td>
															<td className="actionbtns" style={{textAlign:'center'}}><Link to="#" title="Edit Ticket" onClick={() => this.editTicket(result.id)} className="btn btn-info custombtn"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;<Link to="#" title={(result.status=='1')?'Unpublish':'Publish'} onClick={() => this.changeStatus(result.id,result.status)} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
															<Link to="#" title="Delete Ticket" onClick={() => this.deleteEventTickets(result.id)} className="btn btn-info custombtn"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>        
					</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}