import React, { Component } from 'react';
import Axios from 'axios';
import ArtistTopMenu from '../includes/artisttopmenu.component';
import  { Link,NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import ls from 'local-storage';
import defaultimg from '../components/default.png';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
export default class PrayerRequestList extends Component {
	
	constructor(props){
		super(props);
		this.state = {
		  error: null,
		  role: null,
		  msg: null,
		  userid:null,
		  token:ls.get('token'),
		  successclass:null,
		  redirect:false		  
		}
	}
	
	componentDidMount(){
		document.title = "CONQER Music - View Prayer";
		const userid = ls.get('userid');
		this.setState({
			role: ls.get('role'),
			userid:userid
		});
		
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	render() {
		let blockalign, chatnamealign, chatandnimage, timestampalign, haveconversation, viewreply, showviewreply, usersfontimages;
		let prayerrequestdata = this.props.prayerrequestdata;
		const role = ls.get('role');
		let maxlimit = '8';
		if(prayerrequestdata.sender_id == this.state.userid){
			/*if(prayerrequestdata.anonymus == '1'){
				blockalign 		= 'direct-chat-msg';
				chatnamealign 	= 'direct-chat-name pull-left';
				chatandnimage 	= 'chatandnimage';
				timestampalign 	= 'direct-chat-timestamp pull-right';
				viewreply 		= 'btn btn-info custombtn pull-right';
				showviewreply 	= true;
			}else{
				blockalign 		= 'direct-chat-msg right';
				chatnamealign 	= 'direct-chat-name pull-right';
				chatandnimage 	= 'chatandnimage pull-right';
				timestampalign 	= 'direct-chat-timestamp pull-left';
				viewreply 		= 'btn btn-info custombtn pull-right';
				showviewreply 	= false;
			} */
			blockalign 		= 'direct-chat-msg right';
			chatnamealign 	= 'direct-chat-name pull-right';
			chatandnimage 	= 'chatandnimage pull-right';
			timestampalign 	= 'direct-chat-timestamp pull-left';
			viewreply 		= 'btn btn-info custombtn pull-right';
			usersfontimages = 'usersfontimages-right';
			showviewreply 	= false;
		}else{
			blockalign 		= 'direct-chat-msg';
			chatnamealign 	= 'direct-chat-name pull-left';
			chatandnimage 	= 'chatandnimage';
			timestampalign 	= 'direct-chat-timestamp pull-right';
			viewreply 		= 'btn btn-info custombtn pull-right';
			usersfontimages = 'usersfontimages';
			showviewreply 	= true; 
		}
		
		if(prayerrequestdata){
			var profileimage = '';
			var image = new Image();
			var profile_pic = prayerrequestdata.profile_pic;
			/*image.src = profile_pic;
			if (image.width !== 0) {
				profileimage = profile_pic;								
			} else {
				profileimage = defaultimg;
			}*/
			return(
				<div className={blockalign}>
					{
						(prayerrequestdata.anonymus == '1')?
							<div className={usersfontimages}><img className="direct-chat-img" src={'/assets/user/anonymous.png'}  alt="message user image" /></div>
							:<div className="">
								<div className="direct-chat-info clearfix">
									<span className={timestampalign}>{prayerrequestdata.date.substring(11)}</span>
								</div>
								{(prayerrequestdata.profile_pic != '')?
									<div className={chatandnimage}><img className="direct-chat-img" src={profile_pic} onError={this.addDefaultSrc} alt="message user image" /><span className={chatnamealign} title={prayerrequestdata.name}>{ ((prayerrequestdata.name).length > maxlimit) ? (((prayerrequestdata.name).substring(0,maxlimit-3)) + '...') :prayerrequestdata.name }</span></div>
									:<div className={chatandnimage}><img className="direct-chat-img" src={process.env.PUBLIC_URL + 'assets/user/user_icon.png'} onError={this.addDefaultSrc} alt="message user image" /><span className={chatnamealign} title={prayerrequestdata.name}>{ ((prayerrequestdata.name).length > maxlimit) ? (((prayerrequestdata.name).substring(0,maxlimit-3)) + '...') :prayerrequestdata.name }</span></div>
								}
							</div>
					}
					<div className="direct-chat-text">
						<div className="replymessage">{prayerrequestdata.prayer}</div>
					</div>
				</div>
			)
		}else{
			return(
				<div className={blockalign}>
					<div className="direct-chat-text">
						No Request found!
					</div>
				</div>
			)
		}
	}
}