import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from '../includes/header.component';
import Footer from '../includes/footer.component';
import Index from './index.component';
import About from './about.component';
import Register from './register.component';
import Artist from './artist.component';
import User from './user.component';
import Philanthropy from './philanthropy.component';
import Merchendise from './merchendise.component';
import News from './news.component';
import Qa from './qa.component';
import Privacy from './privacy.component';
import Login from './login.component';
import ForgotPassword from './forgotpassword.component';
import ForgotUsername from './forgotusername.component';
import BibleReading from './biblereading.component';
import ReadingBible from './readingbible.component';
import Download from './download.component';
import UserRegister from './userregister.component';
import Studio from './studio.component';
import Terms from './terms.component';
import Logout from './logout.component';
import ConqerEverythingComingSoon from './conqercomingsoon.component';
import DMDAgency from './dmdagency.component';
import Conqer from './conqer.component';
import Verification from './verification.component';
import verifyEmailID from './verifyEmailID';
import ResetPassword from './resetpassword.component';
import ResetUsername from './resetusername.component';
import EventLists from './eventLists.component';
import EventDetail from './webEventDetail.component';
import EventBooking from './webEventBooking.component';
import collaboratingPartners from './collaboratingPartners';
import helpComponent from './helpComponent';

export default class WebLayout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
				<Header />
				<div className="webcontent">
					<Switch>                
						<Route exact path='/about-us' component={ About } />
						<Route exact path='/events' component={ EventLists } />
						<Route exact path='/event-detail/:id' component={ EventDetail } />
						<Route exact path='/event-booking/:id' component={ EventBooking } />
						<Route exact path='/register/:type' component={ UserRegister } />
						<Route exact path='/register/:type/:code' component={ UserRegister } />
						<Route exact path='/artist' component={ Artist } />
						<Route exact path='/user' component={ User } />
						<Route exact path='/philanthropy' component={ Philanthropy } />
						<Route exact path='/merchandise' component={ Merchendise } />
						<Route exact path='/news-media' component={ News } />
						<Route exact path='/q-a' component={ Qa } />
						<Route exact path='/privacy-policy' component={ Privacy } />
						<Route exact path='/login' component={ Login } />
						<Route exact path='/forgot-password' component={ ForgotPassword } />
						<Route exact path='/forgot-username' component={ ForgotUsername } />
						<Route exact path='/bible-reading' component={ BibleReading } />
						<Route exact path='/reading-bible/page/:id' component={ ReadingBible } />
						<Route exact path='/reset-password/:id' component={ ResetPassword } />
						<Route exact path='/reset-username/:id' component={ ResetUsername } />
						<Route exact path='/' component={ Index } />
						<Route exact path='/32-conquer-everything-coming-soon' component={ ConqerEverythingComingSoon } />
						<Route exact path='/studio' component={ Studio } />
						<Route exact path='/terms-and-conditions' component={ Terms } />
						<Route exact path='/152-dmdagency' component={ DMDAgency } />
						<Route exact path='/5-conqer' component={ Conqer } />
						<Route exact path='/user-verification/:code' component={ Verification } />
						<Route exact path='/download-app' component={ Download } />
						<Route exact path='/email-verification/:code' component={ verifyEmailID } />
						<Route exact path='/collaborating-partners' component={ collaboratingPartners } />
						<Route exact path='/help' component={ helpComponent } />
					</Switch> 
				</div> 
				<Footer />
			</div>
        );
    }
}