import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import { API_URL,TEST_SITE_KEY, } from '../config/constants.js';
import Axios from 'axios';
import Select from 'react-select';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		}
const DELAY = 1500;
const errors = {};
export default class BibleReading extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors:[],
			isVerified: false,
			selectPlan: true,
			radioReading: true,
			radioPlan: true,
			email:'',		  
			bibledata:'',			
			biblelist:[],
			callback: "not fired",
			value: "[empty]",
			load: false,
			expired: "false",
			btndisable:false
		};
		this._reCaptchaRef = React.createRef();
		this.onChangeEmail 			= this.onChangeEmail.bind(this);
		this.onChangeBible 			= this.onChangeBible.bind(this);		
		this.onReadingBibleSubmit 	= this.onReadingBibleSubmit.bind(this);			
		this.handleChange 			= this.handleChange.bind(this);			
		this.asyncScriptOnLoad		= this.asyncScriptOnLoad.bind(this);			
		this.onChangePlan			= this.onChangePlan.bind(this);			
	}
	componentDidMount(){
		document.title = "CONQER Music - Bible reading plan";	
		this.getbiblelist();
		let loggedinemail = this.props.match.params.email;//ls.get('email');
		this.setState({
			email:loggedinemail
		});
		setTimeout(() => {
			this.setState({ load: true });
		}, DELAY);

	}
	getbiblelist = () => {		
		const obj = {}
		
		Axios.post(base_api_url+'getbiblelist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						biblelist:res.data.res_data,
					});
				}
			}
		);
	}
	onChangePlan(e) {
		let planValue = e.target.value;
		if(planValue === '1'){
			this.setState({
				selectPlan: true,
				radioPlan:true,
				radioReading:false
			});
		}else{
			this.setState({
				selectPlan: false,
				radioPlan:false,
				radioReading:true
			});
		}
		
	}
	onChangeBible(e) {
		if(this.state.errors['bible']){delete this.state.errors['bible'];}
		this.setState({
			bibledata: e.target.value
		});
	}
	onChangeEmail(e) {
		if(this.state.errors['email']){delete this.state.errors['email'];}
		this.setState({
			email: e.target.value
		});
	}
	
	handleChange = value => {
		if(this.state.errors['captcha']){delete this.state.errors['captcha'];}
		if(value){
			this.setState({
				btndisable:true
			});
		}
		this.setState({ value });
		// if value is null recaptcha expired
		if (value === null) this.setState({ expired: "true" });
	};

	asyncScriptOnLoad = () => {
		this.setState({ callback: "called!" });
		// console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
	};
	handleValidation = () => {
		let formIsValid = true;
		if(!this.state.email){
			formIsValid = false;
			errors["email"] = "Please enter email.";
		}
		if(!this.state.btndisable){
			formIsValid = false;
			errors["captcha"] = "Please validate CAPTCHA.";
		}
		if(this.state.selectPlan){
			if(!this.state.bibledata){
				formIsValid = false;
				errors["bible"] = "Please select bible.";
			}
		}
		
		this.setState({errors: errors});
		return formIsValid;

	}
	onReadingBibleSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			const formData = new FormData();
			let userenteremail = this.state.email;
			formData.append("bibleid", this.state.bibledata);
			formData.append("email", this.state.email);
			
			Axios.post(base_api_url+'readingbible.php', formData, {axiosConfig})
			.then(
				(res) => {
					window.scrollTo(0, 0);
					if(res.data.res_status==402){
						this.setState({
							successclass:'errormsg',
							msg:res.data.res_message,
							selectPlan: true,
							radioPlan: true,
							radioReading: false,
							email:userenteremail
						});
					}else if(res.data.res_status==200){
						ls.set('useremail', res.data.res_data.useremail);
						ls.set('bibletype', res.data.res_data.bibletype);
						ls.set('bibleid', res.data.res_data.bibleid);
						ls.set('fileshortname', res.data.res_data.fileshortname);
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
							errors:{},
							email:'',
							bibledata:''
						});
						
					}else{
						
						this.setState({
							successclass:'errormsg',						
							msg:'Something went wrong, please try again!',
							buttontext : 'Save Item'
						})
					}
					let redirectUrl ='';
					if(res.data.res_data){
						let page = (parseInt(res.data.res_data.stop_page))?res.data.res_data.stop_page:'1';
						redirectUrl =  '/mobile-reading-bible/page/'+page;
						//alert(redirectUrl);
						this.props.history.push(redirectUrl);
					}
				}
			);
		}	
	}
	
    render() {		
		const { value, callback, load, expired } = this.state || {};
		let radioReadingChecked = (this.state.radioReading)?'checked':'';
		let radioPlanChecked 	= (this.state.radioPlan)?'checked':'';
        return (
			<React.Fragment>
				<section className="version-section">
				  <div className="container">
					<div className="row">
					  <div className="col-md-12">
						<div className="message-container">
						  <form>						
							<a href="javascript:;" rel="nofollow" title="Click to view RSS Feed" data-toggle="modal" data-target="#myModal">
								<img src={process.env.PUBLIC_URL + 'assets/american-king-james-version/e_mail.png'} width="24" height="24" alt="Click to view RSS Feed" />
							</a>

							<a rel="nofollow" title="Click to view RSS Feed" target="blank" href="https://conqermusic.com/bible-reading-plan/readingrss/otnt365/americanking-james-version/125?format=raw">
								<img src={process.env.PUBLIC_URL + 'assets/american-king-james-version/feeds.png'} width="24" height="24" alt="Click to view RSS Feed" />
							</a>

							<a title="Click to add this Bible reading plan to your calendar." target="blank" href="https://conqermusic.com/bible-reading-plan/planrss/otnt365/americanking-james-version?variant=ical" rel="nofollow">
								<img src={process.env.PUBLIC_URL + 'assets/american-king-james-version/ical.png'} width="24" height="24" alt="Click to add this Bible reading plan to your calendar." />
							</a>
						  </form>
						</div>
					  </div>
					</div>
				  </div>
				</section>
				
				<div className="bile-reading modal fade" id="myModal" data-easein="expandIn">
				<div className="modal-dialog">
				  <div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">&times;</button>							
					</div>
					<div className="modal-body">
						<form className="md-form">
							<div className="form-group row">
								<label htmlFor="staticEmail" className="col-sm-3 col-form-label">Name</label>
								<div className="col-sm-9">
								  <input type="text" className="form-control" id="staticname" required="required" value=""/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="readsplan" className="col-sm-3 col-form-label">Reading Plan</label>
								<div className="col-sm-9">
								  <select className="form-control" id="exampleFormControlSelect1" required="required">					     
										<option value="bio121" selected="">Biographical Reading Plan (121 days)</option>
										<option value="c61">Chronological Reading Plan (61 days)</option>
										<option value="nt365">Comprehensive Reading Plan (365 days)</option>
										<option value="nt83">New Testament Reading Plan (83 days)</option>
										<option value="ot282">Old Testament Plan (282 days)</option>
										<option value="otnt365">Old/New Testament Plan (365 days)</option>
										<option value="prov31">Proverbs (31 days)</option>
										<option value="SYC">Slavic Youth Congress Reading Plan</option>
										<option value="s61">Survey Reading Plan (61 days)</option>
										<option value="ttb">Through-the-Bible Reading Plan (365 days)</option>							
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="readsplan" className="col-sm-3 col-form-label">Bible Version</label>
								<div className="col-sm-9">
								  <select name="bible" id="zef_subs_bibles" className="form-control">
									<option value="american-king-james-version" selected="">American King James Version</option>
									<option value="american-standard-version">American Standard Version</option>
									<option value="king-james-version">King James Version</option>
									<option value="new-simplified-bible">New Simplified Bible</option>
									<option value="reina-valera">Reina Valera</option>
								</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="staticEmail" className="col-sm-3 col-form-label">Email</label>
								<div className="col-sm-9">
								  <input type="text" className="form-control" id="" value="" required="required" />
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="staticEmail" className="col-sm-3 col-form-label">Send Reading Plan Email?</label>
								<div className="col-sm-9">
									<select name="bible" id="zef_subs_bibles" className="form-control">
										<option value="no" selected="">NO</option>
										<option value="yes">Yes</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="staticEmail" className="col-sm-3 col-form-label">Send Verse of the Day Email?</label>
								<div className="col-sm-9">
									<select name="bible" id="zef_subs_bibles" className="form-control">
										<option value="no" selected="">NO</option>
										<option value="yes">Yes</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="staticEmail" className="col-sm-3 col-form-label">Reading Plan Start Date</label>
								<div className="col-sm-9">
									<input type="text" className="form-control" id="" value="" />
								</div>
							</div>
							<div className="form-group row">
							<div className="col-sm-9">
							  <button type="submit" className="btn btn-primary">Submit</button>
							</div>
						  </div>
						</form>
					</div>				
					</div>
				</div>
			</div>
			<section className="become-artist-form">
					<div>
						<form className="userloginform" onSubmit={this.onReadingBibleSubmit}>
							<div className="userlogin">
								<div className="formpart">
									<div className={this.state.successclass}>{this.state.msg}</div>
									<div className="biblereading-field">
										<span><input type="radio" name="actype" id="streading" value="0" checked={radioReadingChecked} onChange={this.onChangePlan} /> <label htmlFor="streading">Start Reading</label></span> 
										<span className="pull-right"><input type="radio" name="actype" id="slplan" checked={radioPlanChecked} value="1" onChange={this.onChangePlan} /> <label htmlFor="slplan">Select Plan</label></span>
									</div>
									<div className="form-group" style={{'display':'none'}}>
										<label id="user-icon" htmlFor="user">Email<span className="requiredastrik">*</span></label>
										<input type="email" className="form-control" id="email" placeholder="Enter email for reading bible" value={this.state.email} onChange={this.onChangeEmail} />
										<font className="errorMsg">{this.state.errors["email"]}</font>
									</div>
									{(this.state.selectPlan)?
										<div className="form-group">
											<label>Bible Type<span className="requiredastrik">*</span></label>
												<select className="custom-select" name="bibleselection" id="bibleselection" onChange={this.onChangeBible}>
												<option value="">--Select Bible--</option>
													{this.state.biblelist.map((item,index)=> { 
														  return (<option key={index} value={item.value}>{item.name} ({item.label})</option>)
													})}
												</select>
												<font className="errorMsg">{this.state.errors["bible"]}</font>
										</div>
									:''}
									
									<div className="mb-3">
										{load && (
										  <ReCAPTCHA
											style={{ display: "inline-block" }}
											theme="light"
											ref={this._reCaptchaRef}
											sitekey={TEST_SITE_KEY}
											onChange={this.handleChange}
											asyncScriptOnLoad={this.asyncScriptOnLoad}
										  />
										)}
										<font className="errorMsg">{this.state.errors["captcha"]}</font>
									</div>
									
									<div className="sub-formpart">
										<button type="submit" className="btn">Go </button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</section>
			</React.Fragment>
		)
	}
}