import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
export default class MyMusics extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
		  email:'',
		  role:null,
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  topartists:[],
		  topsongs:[],
		  loading:true,
		  successclass:null,
		  redirect:false
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - My Music";
		
		const email = ls.get('email');
		const name = ls.get('name');
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({
			email:email,
			name:name,
			token:token,
			userid:userid
		});
		
		//this.getTopArtists();
		this.getMyAlbum();
		this.getTopSongs();
		
	}
	getMyAlbum = () => {
		const obj = {
			limit:5,
			user_id: ls.get('userid')
		}
		Axios.post(base_api_url+'listener-album.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data;
					this.setState({
						topartists:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	/*getTopArtists = () => {
		const obj = {
			limit:5
		}
		Axios.post(base_api_url+'gettopartists.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.user_data
					this.setState({
						topartists:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}*/
	getTopSongs = () => {
		/*const obj = {
			limit:5
		}
		Axios.post(base_api_url+'gettopsongs.php',obj)
		.then(
			(res) => {
				console.log('user_data',res.data.user_data);
				if(res.data.res_status==200){
					const returndata = res.data.user_data
					this.setState({
						topsongs:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);*/
		const obj = {
			limit:'5',
			listenerid:ls.get('userid')
		}
		Axios.post(base_api_url+'getrecentsongs.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.user_data
					this.setState({
						topsongs:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	
	render() {
		let artists = this.state.topartists;
		let songs 	= this.state.topsongs;
		
        if(this.state.loading==true){
			return <Spinner />;
		}
		let maxlimit = '15';
		return (
			<React.Fragment>	
				<div className={this.state.successclass}>{this.state.msg}</div>
				{/*(artists.length > 0)?<React.Fragment>
				<div className="row topfivesongsd">
					<div className="col-md-12">
						<div className="todpfive">Top Artist <span className="rightsidetext"><Link to="/listener-topartists/">View All</Link></span></div>
					</div>
				</div>
				<div className="row artisitblock allartist">
					{this.state.topartists.map((result) => {
						let fullname 		= result.first_name + ' ' + result.last_name;
						let artistsingleurl =  '/listener-assong/'+ result.usr_id;
						return (
							<div className="col-md-3">
								<Link to={artistsingleurl}>
								<div className="featureartprifles">
								   <div className="featrueimages">
									  <img onError={this.addDefaultSrc} src={result.thumb_profile_pic} />
								   </div>
								   <div className="featurestext">
									  <h3>{fullname}</h3>
									  <span className="nooffollowers"><i className="fa fa-users"></i> {result.myfolowers}</span>
								   </div>
								</div>
								</Link>
							</div>
						)
					})
					}
				</div>
				</React.Fragment>
				:''*/}
				{(!artists.length && !songs.length)?
				<>
					<div className="not-found"><span>No data found!</span></div>
				</>
				:""}
				{(artists.length > 0)?<React.Fragment>
				<div className="row topfivesongsd">
					<div className="col-md-12">
						<div className="todpfive">Made For You <span className="rightsidetext"><Link to="/album-list/">View All</Link></span></div>
					</div>
				</div>
				<div className="row artisitblock allartist">
					{this.state.topartists.map((result) => {
						let fullname 		= result.first_name + ' ' + result.last_name;
						let artistsingleurl =  '/listener-assong/'+ result.usr_id;
						return (
							<div className="col-md-3">
								<Link to={'/albumsongs/'+result.album_id}>
									<div className="featureartprifles">
										<div className="featrueimages">
											<img onError={this.addDefaultSrc} src={result.album_banner} />
										</div>
										<div className="featurestext">
											<h3>{result.album_name}</h3>
											<span className="nooffollowers">{result.songcount} Songs</span>
										</div>
									</div>
								</Link>
							</div>
						)
					})
					}
				</div>
				</React.Fragment>
				:''}
				{/*(songs.length > 0)?
					<React.Fragment>
						<div className="row topfivesongsd">
							<div className="col-md-12">
								<div className="todpfive">Top Songs <span className="rightsidetext"><Link to="/listener-topsongs/">View All</Link></span></div>
							</div>
						</div>
						<div className="row artisitblock allartistsongs">
							{this.state.topsongs.map((results) => {
								let stitile = results.title;
								let topsongurl = '/topssongs/'+results.song_id;
								return (
									<div className="col-md-3">
										<Link to={topsongurl}>
										<div className="featureartprifles">
										   <div className="featrueimages">
											  <img onError={this.addDefaultSrc} src={results.thumb_banner_pic} />
										   </div>
										   <div className="featurestext">
											  <h3 title={stitile}>{ (stitile.length > maxlimit) ? (((stitile).substring(0,maxlimit-3)) + '...') :stitile }</h3>
											  <span className="nooffollowers songlikes"><i className="fa fa-heart"></i> {results.slike}</span>
											  <span className="nooffollowers playicons"><i className="fa fa-play-circle"></i> {results.slistened}</span>
										   </div>
										</div>
										</Link>
									</div>
								)
								})
							}
						</div>
					</React.Fragment>
				:''*/}
				{(songs.length > 0)?
					<React.Fragment>
						<div className="row topfivesongsd">
							<div className="col-md-12">
								<div className="todpfive">Recently Played <span className="rightsidetext"><Link to="/listener-recentsongs/">View All</Link></span></div>
							</div>
						</div>
						<div className="row artisitblock allartistsongs">
							{this.state.topsongs.map((results) => {
								let stitile = results.title;
								let topsongurl = '/topssongs/'+results.song_id;
								return (
									<div className="col-md-3">
										<Link to={topsongurl}>
										<div className="featureartprifles">
										   <div className="featrueimages">
											  <img onError={this.addDefaultSrc} src={results.banner_pic} />
										   </div>
										   <div className="featurestext">
											  <h3 title={results.title}>{ ((results.title).length > maxlimit) ? (((results.title).substring(0,maxlimit-3)) + '...') :results.title }</h3>
											  <span className="nooffollowers songlikes"><i className="fa fa-heart"></i> {results.slike}</span>
											  <span className="nooffollowers playicons"><i className="fa fa-play-circle"></i> {results.slistened}</span>
										   </div>
										</div>
										</Link>
									</div>
								)
								})
							}
						</div>
					</React.Fragment>
				:''}
			</React.Fragment>
		)
	}
}