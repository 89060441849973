import React, { Component } from 'react';
import Axios from 'axios';
import ArtistTopMenu from '../includes/artisttopmenu.component';
import  { NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import ls from 'local-storage';
import defaultimg from '../components/default.png';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
export default class DashboardSongList extends Component {
	
	constructor(props){
		super(props);
	}
	componentDidMount(){
		document.title = "CONQER Music - My Dashboard";
		
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	
	render() {
		let recentsongdata = this.props.recentsongdata;
		let maxlimit = '20';
		let stitile = recentsongdata.name;
		let _artist_list = recentsongdata.artist_name;
		let sgindex = this.props.sgindex;
		let songlist = this.props.songslist;
		return(
			<>
				<div className="recentlyplayed">
					<div className="playsequence">
					  <div className="playimg">
						 <img onError={this.addDefaultSrc} src={recentsongdata.img} className="img-responsive" />                
					  </div>
					  <div className="artinformation">
						 <h3 title={stitile}>{ (stitile.length > maxlimit) ? (((stitile).substring(0,maxlimit-3)) + '...') :stitile }</h3>
						 <span className="artisinames" title={_artist_list}>{ (_artist_list.length > 30) ? (((_artist_list).substring(0,30-3)) + '...') :_artist_list }</span>
					  </div>
					  <div className="clicplay">
						 <span className="playsongicon" onClick={() => this.props.setcurrentsOng(recentsongdata,sgindex,songlist)}><i className="fa fa-play" aria-hidden="true"></i></span>
					  </div>
					</div>
				</div>
				
			</ >
			);
	}
}