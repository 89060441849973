import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import  { NavLink } from 'react-router-dom';
import Spinner from '../includes/Spinner.component'
import defaultimg from '../components/default.png';
import DOMPurify from "dompurify";
import $ from 'jquery';
import ls from 'local-storage';
const regex = /^[a-z\d\,-_\s]+$/i;
const base_api_url = API_URL;
const errors = {};
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
export default class EditAlbum extends Component {
	
	constructor(props){
		super(props);
		this.onChangeAlbumTitle = this.onChangeAlbumTitle.bind(this);	
		this.onChangeUploadCover = this.onChangeUploadCover.bind(this);
		this.onChangeAbout = this.onChangeAbout.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
				
		this.state = {			
			error: null,
			token:ls.get('token'),
			msg: null,
			errors: [],
			banner_image:'',
			successclass:'hideclass',
			redirect:false,
			albumdata: false,
			selectedcover:'Choose File'			
		}		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Edit album";
		this.fetchAlbumRecord();	
	}


	fetchAlbumRecord = () => {
		const albumid = this.props.match.params.id;
		
		const obj = {
			id:albumid
		}
		
		Axios.post(base_api_url+'getalbum.php',obj,{axiosConfig})
		.then(
			(res) => {//console.log(res.data);
				if(res.data.res_status==200){
					this.setState({
						id: res.data.res_data.id,
						album_title: res.data.res_data.name,
						about: res.data.res_data.about,
						user: res.data.res_data.user_id,
						banner: res.data.res_data.banner_id,
						banner_image: res.data.res_data.banner_image,
						albumdata: true
					})
				}else{
					this.setState({
						redirect:false,
						albumdata: false,
						msg:null,
						successclass:null
					});
				}
			}
		);
		
	}
	
	handleValidation = () => {
		let formIsValid = true;
		
		if(!this.state.album_title){
			formIsValid = false;
			errors["album_title_error"] = "Please enter album title.";
		}

		this.setState({errors: errors});
		return formIsValid;

	}
	
	onChangeUploadCover(e){
		if(this.state.errors['uploadcover_error']){delete this.state.errors['uploadcover_error'];}
		if(e.target.files.length > 0 ){
			var validExt = ".png, .gif, .jpeg, .jpg";
			var filePath = e.target.files[0].name;
			var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			var pos = validExt.indexOf(getFileExt);
			var maxSize = '10240';
			var fsize = e.target.files[0].size/1024;
			if(pos < 0){
				this.setState({
					filecovererror : 'Please upload JPG or JPEG or PNG or GIF image only.',
					selectedcover:'', 
					uploadcover: ''
				});
			}else if(fsize > maxSize){
				this.setState({
					filecovererror : 'Please upload maximum 10MB.',
					selectedcover:'', 
					uploadcover: ''
				});
			}else{
				this.setState({
					filecovererror : '',
					selectedcover:'', 
					uploadcover: ''
				});
				this.setState({selectedcover:e.target.files[0].name, uploadcover: e.target.files[0] });
			}
		}else{
			this.setState({
				filecovererror : '',
				selectedcover:'', 
				uploadcover: ''
			});
		}
	}
	
	onChangeAlbumTitle(e) {
		let value 	= e.target.value;
		if(this.state.errors['album_title_error']){delete this.state.errors['album_title_error'];}
		this.setState({album_title:e.target.value, error : null});
	}
		
	onChangeAbout(e){
		this.setState({
		  about: e.target.value
		});
	}
	
	resetForm = (e) => {
		this.fetchAlbumRecord();
	}
	
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			const formData = new FormData();
			const usertoken = this.props.match.params.id;	
			if(this.state.uploadcover!=null && this.state.uploadcover !==''){		
				formData.append("cover", this.state.uploadcover, this.state.uploadcover.name);
			}else{
				formData.append("cover", this.state.banner);
			}
					
			formData.append("album_title", this.state.album_title);
			formData.append("about", this.state.about);
			formData.append("oldid", this.state.id);
			formData.append("userid", this.state.user);
			formData.append("user_token", usertoken);
			
			Axios.post(base_api_url+'edit-album.php', formData, {axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						window.scrollTo(0, 0);
						this.setState({
							successclass:'successmsg',
							msg:'Album updated successfully!',
						});						
						
					}else{
						window.scrollTo(0, 0);
						this.setState({
							successclass:'errormsg',						
							msg:'Album not updated, please try again!'
						})
					}
					setTimeout(() => {
					this.setState({
						successclass:'hideclass',
						msg:null
					});
					}, 5000)
				}
			);
		}
				
	}
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	render() {
		/*var bannerimage = '';
		var image = new Image();
		var url_image = this.state.banner_image;
		image.src = url_image;
		if (image.width !== 0) {
			bannerimage = this.state.banner_image;								
		} else {
			bannerimage = defaultimg;
		}*/
		if(this.state.albumdata==false){
			return <Spinner />;
		}else{
        return (
			<React.Fragment>
				
					<div className={this.state.successclass}>{this.state.msg}</div>
					  <div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  	<h3 className="box-title">Edit Album</h3>
								  	<div className="box-tools">
							  			<NavLink to={'/my-albums/'+this.state.token} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>
							  		</div>
								</div>
								
								<div className="box-body no-padding">
								  <form id="editalbum" className="artist-form" ref={(el) => this.myFormRef = el} onSubmit={this.onSubmit}>
								  <div className="row">
									<div className="col-md-12">
									  <div className="sectionhead">
										<div className="headsec">
										  <h3>Album Information</h3>
										</div>
									  </div>
									</div>
								  </div>
								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label htmlFor="albumtitle">Title<span className="requiredastrik">*</span></label>
										<input type="text" value={this.state.album_title} onChange={this.onChangeAlbumTitle} className="form-control" />
										<span className="errorMsg">{this.state.error}</span>
										<font className="errorMsg">{this.state.errors["album_title_error"]}</font>
									  </div>
									</div>
									
								  </div>

								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label htmlFor="coverpic">Upload Cover<span className="requiredastrik">*</span></label>
										<input type="file" className="form-control" id="coverpic" onChange={this.onChangeUploadCover} accept="image/*" />
										<font className="errorMsg">{this.state.errors["uploadcover_error"]}</font>
										<font className="errorMsg">{this.state.filecovererror}</font>
										<div ><img src={this.state.banner_image} className="img-responsive" width="50" height="50"  onClick={() => this.onclickseturl(this.state.banner_image)} /></div>
									  </div>
									</div>
									
								  </div>

								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label htmlFor="about2">About</label>                          
										<textarea className="form-control rounded-0" id="about2" onChange={this.onChangeAbout} rows="3" value={this.state.about}></textarea>
									  </div>
									</div>
								  </div>
								  <div className="row">
									<div className="col-md-12 notmainbtn">
									<button type="submit" className="btn btn-primary">Update</button>
									&nbsp;&nbsp;	
									<button type="button" className="btn btn-default" onClick={() => this.resetForm()}>Cancel </button>
												
									</div>
								  </div>
								  </form>
								</div>								
							</div>
						</div>        
					</div>      
					<div id="myModal" className="modal fade imgmodal" role="dialog">
						<div className="modal-dialog">
							<div className="modal-content">
								<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
								<div className="modal-body">
									<img className="showimage img-responsive" src={this.state.urlvalinitial} />
								</div>            
							</div>
						</div>
					</div>
			</React.Fragment>
		)
	}
	}
}