import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import ChangePlanStripeCard from "./changeplanstripecard.component";
import ls from 'local-storage';
export default class UpdatePlan extends Component {	
	
	componentDidMount(){
		document.title = "CONQER Music - Change Membership Plan";
		ls.set('registration_type', '');
		ls.set('registration_type', this.props.match.params.type);
	}
	
    render() { return (<ChangePlanStripeCard />)}
}