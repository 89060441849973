import React, { Component } from 'react';
import Axios from 'axios';
import { STRIPE_KEY } from '../config/constants.js';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import IsrcCodeForm from "./isrccodeform.component";
const stripePromise = loadStripe( STRIPE_KEY );

export default class ISRCCode extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			albumid : this.props.currentalid,
			codelist : this.props.codelist,
		}
		
	}
	
    render() {
        return (		
			<Elements stripe={stripePromise}>
				<IsrcCodeForm stateValue={this.state}/>
			</Elements>	
		)
	}
}