import React from "react";
import { withRouter, Redirect, Link, NavLink } from 'react-router-dom';
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import InputMask from 'react-input-mask';
import DOMPurify from "dompurify";
import ls from 'local-storage';
import Spinner from '../includes/Spinner.component';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}

class UpdateMembershipForm extends React.Component {

	constructor(props) {
		super(props);
		this.onChangeSubscription = this.onChangeSubscription.bind(this);
		this.state = {
            email: ls.get('email'),
            userid :  ls.get('userid'),
			usertoken: ls.get('token'),
			error: null,
			msg: null,
			emailmsg: null,
			loader: false,
			payment: false,
			planid: '',
			planlist:[],
			usertype: null,
			btndisable:'',
			successclass: 'hideclass',
			redirect: false,
			submitbuttontext: 'Submit'
		}

		
	}
	componentDidMount(){
		const userrole = ls.get('role');
		if(userrole=='2'){	
			var utype = 'artist';
		}else{
			var utype = 'listener';
		}
		this.getplandetails(utype);
		this.getSelectedPlan();

	}
	
	getSelectedPlan = () => {
		const obj = {
			userid: this.state.userid
		}
		
		Axios.post(base_api_url + 'getselectedplandetail.php', obj)
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						this.setState({
							planid: res.data.res_data							
						});
					} else {
						this.setState({
							planid: ''
						});
					}
				}
			);
	}
	
	getplandetails = (utype) => {
		
		const usertype = utype;
		const obj = {
			usertype: usertype
		}

		Axios.post(base_api_url + 'getplandetails.php', obj)
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						this.setState({
							planlist: res.data.res_data,
							usertype: usertype
						});
					} else {
						this.setState({
							planlist: [],
							usertype: usertype
						});
					}
				}
			);
	}

	onChangeSubscription(e) {
		this.setState({
			planid: e.target.value
		})
	}

	handleSubmit = async event => {
		event.preventDefault();
			const { stripe, elements } = this.props;
			if (!stripe || !elements) {
				return;
			}

			const card = elements.getElement(CardElement);
			const result = await stripe.createToken(card);
			if (!result.error) {
				if(ls.get('membership_status')){
					if (window.confirm('On upgrading plan your active plan will nullify. Please confirm to proceed!')) {
						this.setState({submitbuttontext:'Submit',btndisable:'disabled'});
						this.stripeTokenHandler(result.token,this.state.email,this.state.planid);
					}
				}else{
					this.setState({submitbuttontext:'Submit',btndisable:'disabled'});
					this.stripeTokenHandler(result.token,this.state.email,this.state.planid);
				}
				
			}
	};
	stripeElementChange = (element, name) => {
		this.setState({
			cardError:'',
			formValidation: true
		});
		if (element.complete) {
			this.setState({
				enablesubmitbutton:true
			});
		} else {
			if(element.error){
				if(element.error.code){
					this.setState({
						enablesubmitbutton:false,
						formValidation: false,
						cardError: element.error.message
					});
				}
			}
			
		}
	}
	stripeTokenHandler = (token,email,planid) => {
		this.setState({
			loader:true
		});
		const paymentData = { token: token.id, email: email, membership: planid, user_id:ls.get('userid') };
		Axios.post(base_api_url + 'change_subscription.php', paymentData, { axiosConfig })
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						const formData = new FormData();
						formData.append("planid", this.state.planid);
						formData.append("userid", this.state.userid);
						formData.append("email", email);
						Axios.post(base_api_url + 'renew-plan.php', formData, { axiosConfig })
							.then(
								(res) => {
                                    if (res.data.res_status == 200) {
										ls.set('membership_status', 'Active');
										this.setState({
											loader:false,
											redirect: false,
											successclass: 'successmsg',
											submitbuttontext : 'Submit',
											btndisable:'',
											msg: res.data.res_message
										});
										//console.log(res.data);
									} else {
										window.scrollTo(0, 0);
										this.setState({
											loader:false,
											redirect: false,
											successclass: 'errormsg',
											submitbuttontext : 'Submit',
											btndisable:'',
											msg: res.data.res_message
										})
									}
								}
							);
						setTimeout(() => {										

							this.setState({
								loader:false,
								submitbuttontext : 'Submit',
								btndisable:'',
								successclass: 'hideclass',
								msg: null,
								redirect: true
							});
						}, 10000);
					
					} else {
						this.setState({
							loader:false,
							submitbuttontext : 'Submit',
							btndisable:'',
							payment: false,
							successclass: 'errormsg',
							msg: res.data.res_message
						})
					}
				}
			);
	}

	render() {
		if(this.state.loader==true){
            return <Spinner />;
        }
		const userrole 	= ls.get('role');
		const token 	= ls.get('token');
		let redirect_url = '';
		if(this.state.redirect==true){
			if (userrole == 2) {
				redirect_url = '/edit-artist-profile/'+token;
			} else if (userrole == 1) {
				redirect_url = '/edit-listener-profile/'+token;
			}
			return <Redirect to={redirect_url} />
		}
		let backurl = '';
		if (userrole == 2) {
			backurl = '/edit-artist-profile/'+token;
		} else if (userrole == 1) {
			backurl = '/edit-listener-profile/'+token;
		}
		return (
			<React.Fragment>
				<div className={this.state.successclass}>{this.state.msg}</div>
					  <div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title">{(ls.get('membership_status'))?'Change Membership Plan':'Purchase Membership Plan'}</h3>
								  <div className="box-tools">
									<NavLink to={backurl} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>
								</div>
								</div>
								<div className="box-body no-padding">
								  <form className="artist-form" onSubmit={this.handleSubmit}>
								  <div className="row">
									<div className="col-md-12">
									  <div className="sectionhead">
										<div className="headsec">
										  <h3>Purchase membership plan</h3>
										</div>
									  </div>
									</div>
								  </div>
								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label htmlFor="albumtitle">Subscription Plan<span className="requiredastrik">*</span></label>
										<select id="subscription" required className="form-control" value={this.state.planid} name="" onChange={this.onChangeSubscription} >
                                            <option value="0">--Select Membership Plan--</option>
                                            {this.state.planlist.map((result) => {
                                            return (<option value={result.id}>{result.plan} ${result.amount}</option>)
                                            })}
                                        </select>
									  </div>
									</div>
									
								  </div>

								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label>Card Details<span className="requiredastrik">*</span></label>
										<div className="creditcardsection"><CardElement options={{ hidePostalCode: true }} onChange={(element) => this.stripeElementChange(element, 'card_number')} /></div>
										<div className="errorMsg">{this.state.cardError}</div>
									  </div>
									</div>									
								  </div>								  
								  <div className="row">
								  
									<div className="col-md-12 notmainbtn">
										<button type="submit" disabled={this.state.btndisable} className="btn btn-primary">
											<i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;
											{this.state.submitbuttontext}</button>										
									</div>
								  </div>
								  </form>
								</div>								
							</div>
						</div>        
					  </div> 
			</React.Fragment>
		);
	}
}

export default function InjectedUpdateMembershipForm() {
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<UpdateMembershipForm stripe={stripe} elements={elements} />
			)}
		</ElementsConsumer>
	);
}