import React, { Component } from 'react';
import Axios from 'axios';
import { Form } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import $ from 'jquery';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};

export default class ArtistPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userid: null,
      token: '',
      error: null,
      msg: null,
      reportlist: [],
      loading: true,
      loader: null,
      redirect: false,
      hideCustom: true,
      dataType: 'all',
      startdate: '',
      endDate: '',
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Payments';
    const token = ls.get('token');
    const userid = ls.get('userid');

    this.setState({
      token: token,
      userid: userid,
    });

    this.getallreportlist();
  }
  changeData = (e) => {
    this.setState({ dataType: e.target.value }, () => {
      if (this.state.dataType !== 'custom') {
        this.getallreportlist();
        this.setState({ hideCustom: true });
      } else {
        this.setState({ hideCustom: false });
      }
    });
  };
  onChangeStartDate = (e) => {
    this.setState({ startdate: e });
  };
  onChangeEndDate = (e) => {
    this.setState({ endDate: e });
  };
  getallreportlist = () => {
    this.setState({loading:true});
    let obj = {};

    if (this.state.dataType !== 'custom') {
      obj = {
        user_id: ls.get('userid'),
        type: this.state.dataType,
      };
    } else {
		if(this.state.startdate && this.state.endDate){
			obj = {
				user_id: ls.get('userid'),
				type: this.state.dataType,

				from: this.state.startdate.getFullYear() + '-' + (this.state.startdate.getMonth() + 1) + '-' + this.state.startdate.getDate(),
				to: this.state.endDate.getFullYear() + '-' + (this.state.endDate.getMonth() + 1) + '-' + this.state.endDate.getDate(),
			};
		}
    }
	setTimeout(() => {
        $('#myevents').DataTable({
			"ajax": { type: 'POST', url: base_api_url + 'artistrevenue.php', data: obj },
			"destroy": true,
			"stateSave": true,
			"pageLength": 10,
			"language": {
				"search": "Search"
			},
			"dom": 'Bfrtip',
			"buttons": [
				{
					"extend": 'csv',
					"exportOptions": {
						"columns": [ 0,1,2,3 ]
					}
				},
				{
					"extend": 'print',
					"exportOptions": {
						"columns": [ 0,1,2,3 ]
					}
				}
			],
			"columnDefs": [
				{ "type": "num-fmt", "targets": 2, "className": "text-center", "appliesTo": "print" },
				{ "type": "num-fmt", "targets": 3, "className": "text-center", "appliesTo": "print" }
			],
			"bSort": false
		});
      }, 500);
	  this.setState({loading:false})
  };

  render() {
    if (this.state.loading == true) {
      return <Spinner />;
    }
	
    return (
      <React.Fragment>
        <div className={this.state.successclassName}>{this.state.msg}</div>
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">Payments</h3>
              </div>
              <div className="box-body no-padding">
                <div className="table table-responsive no-margin table-striped table-hover">
                  <table id="myevents" className="table table-striped table-bordered table-sm row-border hover mb-5">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Date</th>
                        <th className="text-center">Credit ($)</th>
                        <th className="text-center">Debit ($)</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div className="box-footer clearfix"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
