import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import MobileQa from './mobileqa.component';
import MobileNews from './mobilenews.component';
import MobileBibleReading from './mobilebiblereading.component';
import MobileReadingBible from './mobilereadingbible.component';

export default class MobileLayout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
		<div>
			<Switch>
				<Route exact path='/mobile-qa/' component={ MobileQa } />
				<Route exact path='/mobile-news/' component={ MobileNews } />
			  	<Route exact path='/mobile-bible-reading/:email' component={ MobileBibleReading } />
			  	<Route exact path='/mobile-bible-reading/' component={ MobileBibleReading } />
			  	<Route exact path='/mobile-reading-bible/page/:id' component={ MobileReadingBible } />
			  	<Route exact path='/mobile-reading-bible/page/:id/:email/:bibleid' component={ MobileReadingBible } />
			</Switch>
		</div>
		)
    }
}