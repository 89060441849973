import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Card from "./card.component";
import ls from 'local-storage';
export default class UserRegister extends Component {	
	
	componentDidMount(){
		window.scrollTo(0, 0);
		let lt = (this.props.match.params.type == 'artist')?"Artist":"Listener";
		document.title = "CONQER Music - "+lt+" Registration";
		ls.set('registration_type', '');
		ls.set('registration_type', this.props.match.params.type);
	}
	
    render() { 
    	let code = typeof this.props.match.params.code !== 'undefined'?this.props.match.params.code:'';
    	return (<Card referralCode = {code} userType = {this.props.match.params.type}/>)
    }
}