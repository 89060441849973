import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import { playAudio } from '../../../includes/playAudio.component';
import Spinner from '../../../includes/Spinner.component';
import $ from 'jquery'; 
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
export default class Songs extends Component {
	constructor(props) {
      super(props);
	  this.audioRefs = React.createRef();
      this.state = {
			loader:true,
			songData:[],
			status:'-',
			approvestatus:'-',
			featuredStatus:'0',
			toggle: true,
			isPlaying: false,
			currentSong: '',
      }
	  this.handleApprove = this.handleApprove.bind(this);
    }
	changeDataStatus = (e) => {
		this.setState({ status: e.target.value }, () => {
			this.getSongListData();
		});
	};
	changeDataAStatus = (e) => {
		this.setState({ approvestatus: e.target.value }, () => {
			this.getSongListData();
		});
	};
	changeDataFeatured = (e) => {
		this.setState({ featuredStatus: e.target.value }, () => {
			this.getSongListData();
		});
	};
	async handleApprove(songid,astatus,ISRC_code) {
		let approveContent = astatus === '1' ? 'unapprove' : 'approve';
		if (window.confirm(`Do you want to ${approveContent} this Song? `)) {
			this.setState({
				loader:true,
				successclass:null,
				msg:null
			});
			const obj = {
				song_id:songid,
				astatus:astatus,
				isrc_code:ISRC_code,
			}			
			const response = await Axios.post(API_URL+'updatesongastatus.php',obj,{axiosConfig});
			if (response.data.res_status === '200') {
				this.setState({
					loader:false,
					successclass:'successmsg',
					msg:(astatus === '1')?'Song unapproved successfully.':'Song approved successfully.',
				});
			}else{
				this.setState({
					loader:false,
					successclass:'errormsg',						
					msg:response.data.res_message
				})
			}
			this.getSongListData();
			setTimeout(() => {					
				this.setState({
				successclass:null,
				msg:null
				});
			}, 10000)
		}
		
	}
    getSongListData(){
    	let obj = {
		  status: (this.state.status === '-')?'':this.state.status,
		  approvestatus: (this.state.approvestatus === '-')?'':this.state.approvestatus,
		  featured: this.state.featuredStatus
		};
		this.setState({
			loader: true
		});
	    Axios.post(API_URL + 'songs-list.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){
		        	$('#mysongs').DataTable().destroy();
		        	$(document).ready(function () {
				        setTimeout(function(){
				        $('#mysongs').DataTable({
				        	"destroy": true,
							"stateSave": true,
	  						"lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100,  "All"]],
							"language": {
								"search": "Search"
							},
	  						columnDefs: [
				   				{ "orderable": false, "targets": -1, width: 120 }
							],
							dom: 'Bfrtip',
							  buttons: [
					            {
					                extend: 'csv',
					                exportOptions: {
					                    columns: [ 0,1,2,3,4 ]
					                }
					            },
					            {
					                extend: 'print',
					                exportOptions: {
					                    columns: [ 0,1,2,3,4 ]
					                },
					                customize: function ( win ) {
									$(win.document.body).find('table').addClass('display').css('font-size', '15px');
									$(win.document.body).find('table').css('table-layout','auto');
									$(win.document.body).find('th').css('width','15%');
									$(win.document.body).find('td').css('width','15%');
									}
					            }
					        ]
				        });
				         } ,50);
				    });
		        	this.setState({
		        		songData: res.data.res_data,
						loader: false
					});				         
		        } 
		                
	    	}
	    )
    }
    componentDidMount(){
		document.title = "Songs List";
    	this.getSongListData();
    }
	
    changeStatus(id,status){
		let statusContent = status === '1' ? 'inactivate' : 'activate';
		if (window.confirm(`Do you want to ${statusContent} this Song? `)) {
			this.setState({
				loader:true
			});
			const obj = {
				id:id,
				status:status
			}			
			Axios.post(API_URL+'updatestatussong.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.getSongListData();
						this.setState({
							loader:false,
							successclass:'successmsg',
							msg:(status === '1')?'Song inactivated successfully.':'Song activated successfully.',
						});
					}else{
						this.setState({
							loader:false,
							successclass:'errormsg',						
							msg:'Song status not updated, please try again!'
						})
					}
				}
			);
			setTimeout(() => {					
				this.setState({
				successclass:null,
				msg:null
				});
			}, 10000)
		}
		
    }
	async markFeatured(id,featured){
		let featureContent = featured === '1' ? 'unfeature' : 'feature';
		if (window.confirm(`Do you want to ${featureContent} this Song? `)) {
			this.setState({
				loader:true,
				successclass:null,
				msg:null
			});
			const obj = {
				song_id:id,
				featured:featured,
				type:'song'
			}			
			const response = await Axios.post(API_URL+'markfeatured.php',obj,{axiosConfig});
			if (response.data.res_status === '200') {
				this.getSongListData();
				this.setState({
					loader:false,
					successclass:'successmsg',
					msg:(featured === '1')?'Song has marked as unfeatured successfully.':'Song has marked as featured successfully.',
				});
			}else{
				this.setState({
					loader:false,						
					successclass:'errormsg',						
					msg:'Song has not marked as featured, please try again!'
				})
			}
			setTimeout(() => {					
				this.setState({
				successclass:null,
				msg:null
				});
			}, 10000)
		}
    }
	playHandler = (song) => {
		if (this.state.currentSong.id === song.id) {
		  this.setState(
			{
			  isPlaying: !this.state.isPlaying,
			  currentSong: song,
			},
			() => playAudio(this.state.isPlaying, this.audioRefs)
		  );
		} else {
		  this.setState(
			{
			  isPlaying: true,
			  currentSong: song,
			},
			() => playAudio(this.state.isPlaying, this.audioRefs)
		  );
		}
	};
    render() {
    	let snId = this.props.match.params.id;
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
					<div className={this.state.successclass}>{this.state.msg}</div>
					{(this.state.loader === true)?<Spinner />:
						<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title">Songs List</h3>   
								  
								</div>
								<div className="box-body no-padding">
									<div className="table-responsive">
										<table id="mysongs" className="table table-striped table-bordered table-hover table-sm row-border hover">
										  <thead>
											<tr>
											  <th>Album</th>
											  <th>Title</th>
											  <th>Artist</th>
											  <th>Approval</th>
											  <th>Status</th>
											  <th className="actionbtns" style={{textAlign:'center'}}>Actions</th>
											</tr>
										  </thead>
										  <tbody>
											{this.state.songData.map((result, key) => {
												var albumimage = result.cover_image;
												let mysongurl = '/mysongs/' + result.id;
												let playTitle,playClassName;
												if(this.state.currentSong.id === result.id && this.state.isPlaying){
													playClassName = 'fa fa-pause';
													playTitle 	= 'Pause Song';
												}else{
													playClassName = 'fa fa-play';
													playTitle 	= 'Play Song';
												}
											  return (
											  	<>
											  	{(snId == result.id)?
												<tr>
												  <td>{result.album_name}</td>
												  <td>{result.title}</td>
												  <td>{result.artist}</td>
												  <td>
													<div className="approvestatus" onClick={() => this.handleApprove(result.id, result.song_astatus, result.ISRC_code)} style={{"cursor":"pointer"}}>
														{result.song_astatus == '0' ? <span className="label label-danger">Not Approved</span>:<span className="label label-success">Approved</span>  }
													</div>
												  </td>
												  <td>
													{result.song_status == '1' ? (
													  <span className="label label-success">Active</span>
													) : (
													  <span className="label label-danger">Inactive</span>
													)}
												  </td>
												  <td className="actionbtns" style={{textAlign:'center'}}>
													 <Link
														to="#"
														onClick={() => this.changeStatus(result.id, result.song_status)}
														title={result.song_status === '1' ? 'In Active' : 'Active'}
														className="btn btn-info custombtn"
													  >
														{result.song_status === '1' ? (
														  <i className="fa fa-ban" aria-hidden="true"></i>
														) : (
														  <i className="fa fa-check" aria-hidden="true"></i>
														)}
													  </Link>
													  {/*&nbsp;
													   <Link
														to="#"
														onClick={() => this.markFeatured(result.id, result.featured)}
														title={result.featured === '1' ? 'Unfeature song' : 'Feature song'}
														className="btn btn-info custombtn"
													  >
														{result.featured === '1' ? (
														  <i className="fa fa-star" aria-hidden="true"></i>
														) : (
														  <i className="fa fa-star-o" aria-hidden="true"></i>
														)}
													  </Link>*/}
													  &nbsp;
														<Link to="#" onClick={() => this.playHandler(result)} className="btn btn-info custombtn">
															<i className={playClassName} title={playTitle}>																
															</i>
														</Link>
												  </td>
												</tr>:""
											}
											</>
											  );
											})}
										  </tbody>
										</table>
									</div>              
							</div>
							</div>
						</div>
						<audio ref={this.audioRefs} src={this.state.currentSong.songfile}></audio>        
					</div>
					}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}