import React, { Component } from 'react';
import { Row, Table } from 'react-bootstrap';
import ls from 'local-storage';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import 'jquery/dist/jquery.min.js';
import MusicPlayer from './musicPlayer.component';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import { playAudio } from '../includes/playAudio.component';
import defaultimg from '../components/default.png';
import shortid from 'shortid';

const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
export default class playListSongs extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      playListSongsData: [],
      loading: true,
      isPlaying: false,
      currentSong: '',
      playlist_name: '',
	  index:0,
      userid:'',
	  songlist:[],
	  musiclist:'',
	  error: null,
	  msg: null,
	  successclass:null,
	  redirect:false,
	  songInfo: {
		currentTime: 0,
		duration: 0,
		animationPercentage: 0,
		volume: 0,
	  },
	  mute: false,
	  currentSong: {}
    };
  }

  componentDidMount() {
    this.playListSongsData();
    this.getPlaylistData();
  }

  /* playHandler = (song) => {
    if (this.state.currentSong.id === song.id) {
      this.setState(
        {
          isPlaying: !this.state.isPlaying,
          currentSong: song,
        },
        () => playAudio(this.state.isPlaying, this.audioRefs)
      );
    } else {
      this.setState(
        {
          isPlaying: true,
          currentSong: song,
        },
        () => playAudio(this.state.isPlaying, this.audioRefs)
      );
    }
  }; */

  deleteSong = async (delId) => {
    const obj = { song_id: delId, play_list_id: this.props.match.params.id, device_id: '' };

    if (window.confirm('Do you want to delete the song?')) {
      const response = await Axios.post(base_api_url + 'deleteplaylistsong.php', obj, axiosConfig);
      if (response.data.res_status === '200') {
        this.playListSongsData();
      }
    }
  };
	
 getPlaylistData = () => {
    const obj = {
      id: this.props.match.params.id,
      userid: ls.get('userid'),
    };

    Axios.post(base_api_url + 'getplaylist.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        const returndatas = res.data.res_data[0];
        this.setState({
          playlist_name: returndatas.title,
          userid: returndatas.user_id,
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
    });
  };	
  playListSongsData = async () => {
    const obj = {
      play_list_id: this.props.match.params.id,
      user_id: ls.get('userid'),
    };

    const response = await Axios.post(base_api_url + 'getplaylistsongs.php', obj, axiosConfig);
    $('#myevents3').DataTable().destroy();
    setTimeout(() => {
      $('#myevents3').DataTable({
        destroy: true,
		stateSave: true,
        pageLength: 10,
        order: [[0, 'asc']],
		language: {
			search: "Search",
			emptyTable: "No data found!"
		},
        lengthMenu: [
          [5, 10, 20, 50, -1],
          [5, 10, 20, 50, 'All'],
        ],
        columnDefs: [{ orderable: false, targets: -1 }],
      });
    }, 500);
    if (response.data.res_status === '200') {
      this.setState({
        playListSongsData: response.data.res_data,
		musiclist:response.data.res_data,
        loading: false,
      },
		()=>{
			this.setState({
				currentSong:this.state.musiclist[this.state.index]
			})
		});
    } else {
      this.setState({
        playListSongsData: [],
      });
    }
  };
  
	setMute = () => {
		this.setState(
		  {
			mute: !this.state.mute,
		  },
		  () => (this.audioRef.current.muted = this.state.mute)
		);
	};
	setCurrentSOng = (song,index,songlist)=>{
		ls.remove('localCurrentSong');
	    ls.remove('localMusicList');
	    ls.remove('localIndex');
	    ls.set('localCurrentSong', song);
	    ls.set('localMusicList', songlist);
	    ls.set('localIndex', index);
		song.token = shortid.generate();
		this.setState({
			currentSong:ls.get('localCurrentSong'),
			index:ls.get('localIndex'),
			isPlaying:true,
			musiclist:ls.get('localMusicList'),
			likeStatus:'',
			is_favorite:''
		},
		()=>{
			/**/
			this.audioPlayHandler(song);
		});
	}
	insertSongInfo(song){
		const objs = {
		  	user_id: ls.get('userid'),
		  	song_id: song.song_id,
		  	played_time: '0',
		  	token: song.token
	  	};
		Axios.post(base_api_url + 'songplayed.php', objs, { axiosConfig }).then((result) => {
		  if (result.data.res_status == 200) {
		  	this.setState({
		  		isCurrent: true
		  	});
			  console.log('song data saved successfully');
		  } 
		});
	}
	timeUpdateHandler = (e) => {
		const current = e.target.currentTime;
		const duration = e.target.duration;
		let mList = (this.state.musiclist)?this.state.musiclist:this.props.musiclist;
		if (current === duration) {
		  this.setState(
			{
			  isPlaying: !this.state.isPlaying
			},
			() => {
			  if(this.state.index < mList.length - 1 ){
				if (this.state.index === mList.length - 1) {
				  this.audioPlayers(0);
				} else {
				  this.audioPlayers(this.state.index + 1);
				}
			  }
			}
		  );
		}
		const roundedCurrent = Math.round(current);
		const roundedDuration = Math.round(duration);
		const percentage = Math.round((roundedCurrent / roundedDuration) * 100);
		this.setState({
		  songInfo: {
			currentTime: current,
			duration: duration,
			animationPercentage: percentage,
			volume: e.target.volume
		  }
		});
	  };

	  audioPlayHandler(song) {
		//playAudio(this.state.isPlaying, this.audioRef, isCurrent);
		this.playAudioSong(this.state.isPlaying, this.audioRef, song);
		//playSongHandler(this.state.isPlaying, this.audioRef);
	  }
	  audioPlayers(key) {
		let songList = this.state.musiclist[key];
		if(songList.token == undefined){
		  songList.token = shortid.generate();
		}
		let newObj = {
		  currentTime: 0,
		  duration: this.state.musiclist[key].duration,
		  animationPercentage: 0,
		  volume: 0
		};

		this.setState(
		  {
			index: key,
			currentSong: this.state.musiclist[key],
			isPlaying: true,
			songInfo: newObj
		  },
		  () => {
			//playAudio(this.state.isPlaying, this.audioRef);
			this.playAudioSong(this.state.isPlaying, this.audioRef, songList);
		  }
		);
	  }
	  playAudioSong = (isPlaying, audioRef, song) => {
		  if (isPlaying) {
		  	this.insertSongInfo(song);
		    audioRef.current.play();
		  } else {
		    audioRef.current.pause();
		  }
		};
	  setIsPlaying = () => {
		this.setState({
		  isPlaying: !this.state.isPlaying,
		});
	  };

	  

	  setCurrentSong = (ind) => {
		this.setState({
		  currentSong: this.state.musiclist[ind],
		  index: ind,
		});
	  };

	  setSongInfo = (parameter) => {
		this.setState({
		  songInfo: parameter,
		});
	  };

	  getTime = (timer) => {
		if (this.state.isPlaying) {
		  return Math.floor(timer / 60) + ':' + ('0' + Math.floor(timer % 60)).slice(-2);
		} else {
		  return Math.floor(timer);
		}
	  };
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	likeSongFu = (song_id,like_status, musiclist, index) => {
		const objs = {
		  song_id: song_id,
		  like: like_status,
		  user_id: ls.get('userid')
		};
		
		Axios.post(base_api_url + 'songlike.php', objs, { axiosConfig }).then((result) => {
		  let returndatas = result.data.res_message;
		  if (Number(result.data.res_status) === 200) {
			if (returndatas !== 'null') { //console.log(returndatas);
			  this.setState({
					successclass:'successmsg',						
					msg:returndatas
			  },()=>{
			  	let updateMusicList = musiclist[ls.get('localIndex')?ls.get('localIndex'):index];
		          updateMusicList.likestatus = like_status;
		          musiclist[ls.get('localIndex')] = updateMusicList;
		          ls.remove('localMusicList');
		          ls.set('localMusicList', musiclist);
				this.setState({musiclist:musiclist, index:index, likeStatus: like_status})
				this.playListSongsData();
			});
			}
		  } else {
			this.setState({
				successclass:'errormsg',						
				msg:returndatas
			});
		  }
		  setTimeout(() => {
				this.setState({
					successclass:null,
					msg:null
				});
			}, 10000)
		});
	}
  
  	_favoriteSong = (song_id,is_favorite, musiclist, index) => {
		let favorite = (is_favorite == '0')?'1':'0';
		const objs = {
		  song_id: song_id,
		  is_favorite: favorite,
		  user_id: ls.get('userid')
		};
		
		Axios.post(base_api_url + 'add-to-favorite.php', objs, { axiosConfig }).then((result) => {
		  let returndatas = result.data.res_message;
		  if (Number(result.data.res_status) === 200) {
			if (returndatas !== 'null') {
			  this.setState({
					successclass:'successmsg',						
					msg:returndatas
			  },()=>{
			  	let updateMusicList = musiclist[ls.get('localIndex')?ls.get('localIndex'):index];
				updateMusicList.is_favorite = favorite;
				musiclist[ls.get('localIndex')] = updateMusicList;
				ls.remove('localMusicList');
				ls.set('localMusicList', musiclist);
				this.setState({musiclist:musiclist, index:index, is_favorite: favorite})
				this.playListSongsData();
			});
			}
		  } else {
			this.setState({
				successclass:'errormsg',						
				msg:returndatas
			});
		  }
		  setTimeout(() => {
				this.setState({
					successclass:null,
					msg:null
				});
			}, 10000)
		});
	}

  render() {
	let userid = ls.get('userid');
	const { musiclist, index, currentTime, pause } = this.state;
	const currentSong = this.state.currentSong;
    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : (
		<>
		<section>
		 {this.state.msg ? <div className={this.state.successclass}>{this.state.msg}</div> : ''}
          <div className="box box-success distributions-profileform">
            <div className="box-header with-border addprofilenew">
              <h3 className="box-title">{this.state.playlist_name}</h3>
              <div className="box-tools">
                <Link
                  to={ls.get('role') === '2' ? '/playlist-artist/' + ls.get('token') : '/playlist-listener/' + ls.get('token')}
                  className="btn btn-primary pull-right"
                >
                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
                </Link>
              </div>
            </div>
            <span className="rightsidetext"></span>
            <div className="box-tools saearchicons">
              <div className="input-group input-group-sm hidden-xs"></div>
            </div>
            <div className="box-body no-padding">
              <div className="table-responsive">
                <table id="myevents3" className="table no-margin table-striped table-hover" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Album</th>
                      <th>Artist</th>
                      <th>Genre</th>
                      <th className="text-center">Duration</th>
                      <th className="actionbtns" style={{ textAlign: 'center' }}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.playListSongsData.map((item,indexs) => (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                        <td>{item.albumname}</td>
                        <td>{item.list_artist ? item.list_artist : 'Unknown'}</td>
                        <td>{item.genre}</td>
                        <td className="text-center">{item.song_length}</td>
                        <td className="actionbtns d-flex justify-content-center">
                          <Link onClick={() => this.setCurrentSOng(item,indexs,this.state.playListSongsData)} className="btn ml-2 btn-info custombtn" title="Play Song">
                            <i className='fa fa-play' aria-hidden="true"></i>
                          </Link>
                          {(this.state.userid == userid)?(
                            <>
                            <Link onClick={() => this.deleteSong(item.id)} className="btn ml-2 btn-info custombtn" title="Delete Song">
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </Link>
                            </>
                            ):""
                          }
                          

                          {/*<div className="align-items-center playsongicon">
                            <FontAwesomeIcon onClick={() => console.log('vfv')} className="play " color="white" icon={false ? faPause : faPlay} />
                          </div>*/}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
		  </section>
			<section className="" >
				<MusicPlayer
				setIsPlaying={this.setIsPlaying}
				audioRef={this.audioRef}
				currentSong={currentSong}
				songInfo={this.state.songInfo}
				isPlaying={this.state.isPlaying}
				setSongInfo={this.setSongInfo}
				musiclist={this.state.musiclist}
				setCurrentSong={this.setCurrentSong}
				setMute={this.setMute}
				index={this.state.index}
				mute={this.state.mute}
				likeSongFu={this.likeSongFu}
				_favoriteSong={this._favoriteSong}
				likeSongStatus = {(this.state.likeStatus)?this.state.likeStatus:currentSong.likestatus}
				is_favorite = {(this.state.is_favorite)?this.state.is_favorite:currentSong.is_favorite}
				/>
				<audio onLoadedMetadata={this.timeUpdateHandler} onTimeUpdate={this.timeUpdateHandler} ref={this.audioRef} src={currentSong.audio}></audio>
			</section>
			</>
        )}
      </>
    );
  }
}
