import React, { Component } from 'react';
import Axios from 'axios';
import  { Redirect, Link  } from 'react-router-dom';
import { API_URL, STRIPE_KEY } from '../config/constants.js';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutform.component";
const stripePromise = loadStripe( STRIPE_KEY );

const subsplan = [{value:1, label: 'Monthly $7.77'},{value:2, label: 'Annual $84.00'}];
const base_api_url = API_URL;

export default class Card extends Component {
	constructor(props) {
		super(props);
		
	}
    render() {
    	
        return (		
			<Elements stripe={stripePromise}>
				<CheckoutForm code={this.props.referralCode} type={this.props.userType}/>
			</Elements>	
		)
	}
}