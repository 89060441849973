import React, { Component } from 'react';
import Axios from 'axios';
import ReactDOM from 'react-dom'
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';
import createHistory from 'history/createBrowserHistory';
import {toast} from 'react-toastify';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';

const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
toast.configure();
const history = createHistory();
export default class MyAlbums extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      role: null,
      userid: null,
      token: '',
      error: null,
      msg: null,
      songlist: [],
      items: [],
      loading: true,
      successclass: null,
      redirect: false,
      type:'all',
      myAlbum:[],
      page: 0,
      limit: 25,
      prevY: 0,
      hide:'block',
      iteration: 1,
      label:'none'
    };
  this.onChangeMyAlbum  = this.onChangeMyAlbum.bind(this);
  }

  componentDidMount(props) {
    document.title = 'CONQER Music - My Albums';

    const email = ls.get('email');
    const name = ls.get('name');
    const token = ls.get('token');
    const userid = ls.get('userid');
    let message = '';
    if (this.props.location.state !== null && this.props.location.state !== undefined) {
      message = this.props.location.state.message;
    }
    this.setState({
      email: email,
      name: name,
      token: token,
      userid: userid,
      msg: message,
      successclass: 'successmsg'
    });
    if(message){
      let state = { ...history.location.state };
        delete state.message;
        history.replace({ ...history.location, state });
        setTimeout(() => {
        this.setState({
          successclass: null,
          msg: null
        });
      }, 5000);
    }
    this.getAllAlbumlist(this.state.page, this.state.limit);
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );    
    this.observer.observe(this.loadingRef);
  }
  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if(this.state.type === 'all'){
      if (this.state.prevY > y) {
        const lastArtist = this.state.songlist.length;//this.state.last_id;//
        const curPage = lastArtist;
        this.getAllAlbumlist(curPage,25);
        this.setState({ page: curPage, iteration: this.state.iteration+1 });
      }
      this.setState({ prevY: y });
    }
  }
  deletealbum = (albumid, status) => {
    const obj = {
      id: albumid,
      status: status
    };

    Axios.post(base_api_url + 'updatestatusalbum.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        toast.success(res.data.res_message);
      } else {
        toast.error(res.data.res_message);
      }
      this.getAllAlbumlist();
    });
  };
  songName(e){
    if(e.target.value){
      this.setState({song_name:e.target.value});
    }else{
      this.setState({
        song_name:'',
        songlist:[],
        type:'all'
      },()=>this.getAllAlbumlist(0, this.state.limit*this.state.iteration));
    }
    
  }
  handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        this.searchSongs()
      }
  }
  searchSongs = (e) => {
    this.setState({'type':'search', label: 'none', loader:'none',myAlbum:[]});
    let _data = [];
    let n = this.state.song_name;
    if(n){
      /*this.state.songlist.map((results) => {
        if((results.name).match(new RegExp(n,'ig'))){
          _data.push(results);        
        }
      });
      this.setState({
        myAlbum:_data,
        loading:false,
        hide:'none'
      });*/
      let obj = {
        keyword: n
      }
      Axios.post(base_api_url+`artistalbumlist-search-v2.php`,obj)
      .then(
        (res) => {
          if(res.data.res_status==200){
            this.setState({
              myAlbum:res.data.res_data,
              totalAlbum: res.data.totalAlbum, 
              last_id: res.data.last_id,          
              loader:'none',
              hide:'none',
              label:'block'
            })
          }else{
            this.setState({myAlbum: [], hide:'none', loading:false, label: 'block',loader:'none' });
          }
        }
      );
    }else{
      this.setState({'type':'all', hide:'block'});
    }
  }
  getAllAlbumlist = (page, limit, myal=null) => {
    this.setState({'type':'all', loading: false, label:'none'});
  let obj = {
    userid: ls.get('userid'),
    myalbum: '',
  };
  if(myal){
    obj = {
      userid: ls.get('userid'),
      myalbum: myal,
    };
  }
  if(ls.get('myalbumcheck')){
    obj = {
      userid: ls.get('userid'),
      myalbum: 'myalbum',
    };
  }
    Axios.post(base_api_url + `artistalbumlist-v2.php?_page=${page}&_limit=${limit}`, obj).then((res) => {
      if (res.data.res_status == 200) {
        let returndata = res.data.res_data;
        this.setState({ songlist: [...this.state.songlist, ...res.data.res_data], totalAlbum: res.data.totalAlbum, last_id: res.data.last_id  });
        this.setState({ loading: false, hide:'block', label:'block'});
      } else {
        this.setState({
          loading: false,
          hide:'none'
        });
      }
    });
  };
  onclickseturl = (urlval) => {
    this.setState({
      urlvalinitial: urlval
    });
    $('#myModal').modal('show');
  };
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };
  showActions(index){
    $('.alm'+index).slideToggle("fast");
  }
  onChangeMyAlbum(e){
    let isChecked = e.target.checked;
    ls.set('myalbumcheck',isChecked);
    this.setState({songlist:[], hide:'none'});
    if(isChecked){
      this.getAllAlbumlist(0, 100, 'myalbum');
    }else{
      this.setState({
        last_id: '0',
        hide:'none'
      });
      this.getAllAlbumlist(0, 25);
    }
  }
  commonAlert(){
    toast.error('Please inactivate album before edit.');
  }
  render() {
    let songlists   = (this.state.type == 'all')?this.state.songlist:this.state.myAlbum;
    const loadingTextCSS = { display: ((this.state.totalAlbum == songlists.length) || (songlists.length < 20) ) ? "none" : "block" };
    if (this.state.loading == true) {
     // return <Spinner />;
    }
  let currentUserId   = ls.get('userid');
  let myalbumcheck  = (ls.get('myalbumcheck'))?'checked':'';
  let maxlimit    = '15'; 
    return (
      <React.Fragment>
        {this.state.msg ? <div className={this.state.successclass}>{this.state.msg}</div> : ''}
    <div className="row topfivesongsd">
      <div className="col-md-12">
        <div className="todpfive aralbum">Album
          <span className="rightsidetext">
            <div className="box-tools _hash_css">
              <span className="navbar-search col" style={{'float':'left'}}>
                <label htmlFor="anonymus">
                  <input type="checkbox" id="myalbum" className="htlmyalbum" onChange={this.onChangeMyAlbum} value="1" checked={(myalbumcheck)?'checked':''} /> My Album
                </label>
              </span>
              <span className="navbar-search col" style={{'float':'left'}}>
                <label className="sr-only screen-reader-text visually-hidden" for="search">Search for:</label>
                  <div className="input-group">
                    <div className="input-search-field">
                    <input type="text" id="search" className="form-control search-field product-search-field" dir="ltr" onChange={this.songName.bind(this)} placeholder="Search Album" onKeyPress={this.handleKeyPress} autocomplete="off" />
                  </div>
                  <div className="input-group-btn">
                    <button type="submit" onClick={() => this.searchSongs()} className="btn btn-secondary"><i className="fa fa-search"></i></button>
                  </div>
                </div>
              </span>
              <NavLink to={'/add-album/' + this.state.token} className="btn btn-primary">
                <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add New Album
              </NavLink>
            </div>
          </span>
        </div>
      </div>
    </div>
        <div className="row artisitblock allartistsongs">
      {(songlists.length)?songlists.map((result,index) => {
        var albumimage = result.banner;
        return (
          <div className="col-md-3">
            <div className="featureartprifles">
            {(currentUserId == result.user_id)?
              <>
                <div className="featrueimages">
                  <img onError={this.addDefaultSrc} src={albumimage} />
                </div>
                <div className="featurestext">
                  <h3 title={result.name}>{ ((result.name).length > maxlimit) ? (((result.name).substring(0,maxlimit-3)) + '...') :result.name }</h3>
                  <span className="nooffollowers playicons"><i className="fa fa-music"></i> {result.songs}</span>
                </div>
                <div className="album-descriptionnew">
                  <span className="threedotted showactions" onClick={() => this.showActions(index)}><i className="fa fa-ellipsis-v"></i></span>
                  <div className={"multipleactions alm"+index} style={{"display":"none"}}>
                    <ul>
                      <li><NavLink to={'/albums/'} title="List Album" ><i className="fa fa-list" aria-hidden="true"></i></NavLink></li>
                      <li><Link to={'/my-songs/' + result.id} title="View Songs"><i className="fa fa-music"></i></Link></li>
                      
                      <li>{(result.status == 'Active')?<Link to="#" onClick={() => this.commonAlert()} title="Edit Album" ><i className="fa fa-pencil" aria-hidden="true"></i></Link>:<Link to={'/edit-album/' + result.id} title="Edit Album" ><i className="fa fa-pencil" aria-hidden="true"></i></Link>}</li>
                      <li><NavLink to={'/add-song/' + result.id} title="Add new song" ><i className="fa fa-plus" aria-hidden="true"></i></NavLink></li>
                      <li><a
                        href="javascript:;"
                        title={result.status == 'Active' ? 'In Active' : 'Active'}
                        onClick={() => this.deletealbum(result.id, result.status)}
                      >
                        {result.status !== 'In Active' ? (
                        <i className="fa fa-ban" aria-hidden="true"></i>
                        ) : (
                        <i className="fa fa-check" aria-hidden="true"></i>
                        )}
                      </a></li>
                      
                    </ul>
                  </div>
                </div>
              </>:
              <Link to={'/aalbumsongs/' + result.id}>
                <div className="featrueimages">
                  <img onError={this.addDefaultSrc} src={albumimage} />
                </div>
                <div className="featurestext">
                  <h3 title={result.name}>{ ((result.name).length > maxlimit) ? (((result.name).substring(0,maxlimit-3)) + '...') :result.name }</h3>
                  <span className="nooffollowers playicons"><i className="fa fa-music"></i> {result.songs}</span>
                </div>
              </Link>
            }
            </div>
          </div>
        )
        }):<div className="not-found" style={{"display":this.state.label}}><span>No album found!</span></div>
      }
    </div>
    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={{"display":this.state.hide}}>
      <span style={loadingTextCSS}><div className="col-md-12" style={{fontSize:'24px', textAlign:'center'}}><i class="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i> Please Wait...</div></span>
    </div>
        <div id="myModal" className="modal fade imgmodal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <button type="button" className="btn btn-default closeicon" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-body">
                <img className="showimage img-responsive" src={this.state.urlvalinitial} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
