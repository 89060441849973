import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './style.css';

import MobileLayout from './components/mobilelayout.component';
import WebLayout from './components/weblayout.component';
import ArtistLayout from './components/artistlayout.component';
import ListenerLayout from './components/listenerlayout.component';
import AdminComponent from './components/admin/route.component';

import Card from './components/card.component';
import $ from 'jquery'; 
import ls from 'local-storage';
//require('dotenv').config()
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      msg: null,
      redirect: false,
      current: window.location.pathname,
    };
  }
  componentDidMount(){
    $("body").on("contextmenu",function(e){
        return false;
    });
    $(document).keydown(function (event) {
        if (event.keyCode === 123) {
            return false;
        }
        //(event.ctrlKey && event.shiftKey && event.keyCode == 73) || 
        else if ((event.ctrlKey && event.shiftKey && event.keyCode === 74)  || (event.ctrlKey && event.shiftKey && event.keyCode === 73) || (event.ctrlKey && event.keyCode === 85)  || (event.ctrlKey && event.keyCode === 83)) {
            return false;
        }
    });
  }
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/mobile-qa/" component={MobileLayout} />
            <Route exact path="/mobile-news/" component={MobileLayout} />
            <Route exact path="/mobile-bible-reading/:email" component={MobileLayout} />
            <Route exact path="/mobile-bible-reading/" component={MobileLayout} />
            <Route exact path="/mobile-reading-bible/page/:id" component={MobileLayout} />
            <Route exact path="/mobile-reading-bible/page/:id/:email/:bibleid" component={MobileLayout} />
            <Route exact path="/about-us" component={WebLayout} />
            <Route exact path="/events" component={WebLayout} />
            <Route exact path="/event-detail/:id" component={WebLayout} />
            <Route exact path="/event-booking/:id" component={WebLayout} />
            <Route exact path="/register/:type" component={WebLayout} />
            <Route exact path="/register/:type/:code" component={WebLayout} />
            <Route exact path="/artist" component={WebLayout} />
            <Route exact path="/user" component={WebLayout} />
            <Route exact path="/collaborating-partners" component={WebLayout} />
            <Route exact path="/philanthropy" component={WebLayout} />
            <Route exact path="/merchandise" component={WebLayout} />
            <Route exact path="/news-media" component={WebLayout} />
            <Route exact path="/q-a" component={WebLayout} />
            <Route exact path="/privacy-policy" component={WebLayout} />
            <Route exact path="/login" component={WebLayout} />
            <Route exact path="/forgot-password" component={WebLayout} />
            <Route exact path="/reset-password/:id" component={WebLayout} />
            <Route exact path="/reset-username/:id" component={WebLayout} />
            <Route exact path="/forgot-username" component={WebLayout} />
            <Route exact path="/bible-reading" component={WebLayout} />
            <Route exact path="/reading-bible/page/:id" component={WebLayout} />
            <Route exact path="/" component={WebLayout} />
            <Route exact path="/32-conquer-everything-coming-soon" component={WebLayout} />
            <Route exact path="/studio" component={WebLayout} />
            <Route exact path="/terms-and-conditions" component={WebLayout} />
            <Route exact path="/152-dmdagency" component={WebLayout} />
            <Route exact path="/5-conqer" component={WebLayout} />
            <Route exact path="/user-verification/:code" component={WebLayout} />
            <Route exact path='/email-verification/:code' component={ WebLayout } />
            <Route exact path="/download-app" component={WebLayout} />
            <Route exact path="/stripe-pay" component={WebLayout} />
            <Route exact path="/card" component={Card} />
            <Route exact path="/help" component={WebLayout} />
            <Route exact path="/artist-favoritesongs" component={ArtistLayout} />
            <Route exact path="/artist-dashboard" component={ArtistLayout} />
            <Route exact path="/atopssongs/:id" component={ArtistLayout} />
            <Route exact path="/artist-profile/:id" component={ArtistLayout} />
            <Route exact path="/edit-artist-profile/:id" component={ArtistLayout} />

            <Route exact path="/aalbumsongs/:id" component={ArtistLayout} />
            <Route exact path="/albums/" component={ArtistLayout} />
            <Route exact path="/my-songs/:id" component={ArtistLayout} />
            <Route exact path="/my-song/:id" component={ArtistLayout} />
            <Route exact path="/add-song/:id" component={ArtistLayout} />
            <Route exact path="/edit-song/:id" component={ArtistLayout} />

            <Route exact path="/my-albums/:id" component={ArtistLayout} />
            <Route exact path="/add-album/:id" component={ArtistLayout} />
            <Route exact path="/edit-album/:id" component={ArtistLayout} />

            <Route exact path="/my-events/:id" component={ArtistLayout} />
            <Route exact path="/view-event/:id" component={ArtistLayout} />
            <Route exact path="/add-event/:id" component={ArtistLayout} />
            <Route exact path="/edit-event/:id" component={ArtistLayout} />
            <Route exact path="/edit-ticket/:id" component={ArtistLayout} />
            <Route exact path="/edit-gallery/:id" component={ArtistLayout} />
            <Route exact path="/my-participants/:id" component={ArtistLayout} />
            <Route exact path="/my-participant/:id" component={ArtistLayout} />
            <Route exact path="/attendee/:id" component={ArtistLayout} />
            <Route exact path="/my-attendee/:id" component={ArtistLayout} />
            <Route exact path="/prayer-line/:id" component={ArtistLayout} />
            <Route exact path="/view-prayer-rquest/:id" component={ArtistLayout} />

            <Route exact path="/change-card-details/" component={ArtistLayout} />
            <Route exact path="/reports/" component={ArtistLayout} />
            <Route exact path="/payment/" component={ArtistLayout} />

            <Route exact path="/mysongs/:id" component={ArtistLayout} />
            <Route exact path="/song-approval/:id" component={ArtistLayout} />
            <Route exact path="/change-password/:id" component={ArtistLayout} />
            <Route exact path="/renew-plan/:id" component={ArtistLayout} />
            <Route exact path="/change-plan/" component={ArtistLayout} />
            <Route exact path="/followers/:id" component={ArtistLayout} />
            <Route exact path="/follower/:id" component={ArtistLayout} />
            <Route exact path="/logout" component={ArtistLayout} />
            <Route exact path="/logoutfromall" component={ArtistLayout} />
            <Route exact path="/notifications-artist" component={ArtistLayout} />
            <Route exact path="/myisrccode/" component={ArtistLayout} />
            <Route exact path="/lslogout" component={ListenerLayout} />
            <Route exact path="/lslogoutfromall" component={ListenerLayout} />
            <Route exact path="/listener-dashboard" component={ListenerLayout} />
            <Route exact path="/listener-profile/:id" component={ListenerLayout} />
            <Route exact path="/edit-listener-profile/:id" component={ListenerLayout} />
            <Route exact path="/edit-password/:id" component={ListenerLayout} />

            <Route exact path="/listener-change-plan/" component={ListenerLayout} />
            <Route exact path="/listenerchange-card-details/" component={ListenerLayout} />
            <Route exact path="/listner-prayer-line/:id" component={ListenerLayout} />
            <Route exact path="/listner-view-prayer-request/:id" component={ListenerLayout} />

            <Route exact path="/listener-followers/:id" component={ListenerLayout} />
            <Route exact path="/listener-myevents/:id" component={ListenerLayout} />
            <Route exact path="/listener-viewevent/:id" component={ListenerLayout} />
            <Route exact path="/listener-assong/:id" component={ListenerLayout} />
            <Route exact path="/albumsongs/:id" component={ListenerLayout} />
            <Route exact path="/topssongs/:id" component={ListenerLayout} />
            <Route exact path="/my-music/" component={ListenerLayout} />
            <Route exact path="/listener-topsongs/" component={ListenerLayout} />
            <Route exact path="/listener-topartists/" component={ListenerLayout} />
            <Route exact path="/book-ticket/:id" component={ListenerLayout} />
            <Route exact path="/listener-eventbooking/" component={ListenerLayout} />
            <Route exact path="/playlist-listener/:id" component={ListenerLayout} />
            <Route exact path="/myloggedindevices/" component={ListenerLayout} />
            <Route exact path="/playlist-artist/:id" component={ArtistLayout} />
            <Route exact path="/amyloggedindevices/" component={ArtistLayout} />
            <Route exact path="/take-attendance" component={ArtistLayout} />
            <Route exact path="/listener-notification" component={ListenerLayout} />
            <Route exact path="/add-playlist/:id" render={() => (ls.get('role') === '2' ? <ArtistLayout /> : <ListenerLayout />)} />
            <Route exact path="/edit-playlist/:id" render={() => (ls.get('role') === '2' ? <ArtistLayout /> : <ListenerLayout />)} />
			<Route exact path="/playlist-songs/:id" render={() => (ls.get('role') === '2' ? <ArtistLayout /> : <ListenerLayout />)} />
            <Route exact path="/listener-favoritesongs/" component={ListenerLayout} />
            <Route exact path="/listener-recentsongs/" component={ListenerLayout} />
            <Route exact path="/listener-featuredsongs/" component={ListenerLayout} />
            <Route exact path="/album-list" component={ListenerLayout} />
            <Route exact path="/event-report/" component={ArtistLayout} />
            <Route exact path="/listener-report/" component={ArtistLayout} />
            <Route exact path="/song-report/" component={ArtistLayout} />
            <Route exact path="/featured-artist" component={ListenerLayout} />
            <Route exact path="/artist-list" component={ListenerLayout} />
          </Switch>
        </Router>
        <Router>
          <AdminComponent />
        </Router>
        </React.Fragment>
    );
  }
}
export default App;
