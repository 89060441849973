import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect, Switch, Route } from 'react-router-dom';
import ls from 'local-storage';
import playListSongs from './playListSongs';
import ArtistTopMenu from '../includes/artisttopmenu.component';
import SideMenu from '../includes/sidemenu.component';
import FreeSideMenu from '../includes/freesidemenu.component';
import playListDetails from './playListDetails';
import ArtistDashboard from './artistdashboard.component';
import ArtistProfile from './artistprofile.component';
import EditArtistProfile from './editartistprofile.component';
import EditPlaylist from './editPlaylist.component';
import artistIRSCCode from './artistisrccode.component';
import afavoriteSongs from './afavoritesongs.component';
import MySongs from './mysongs.component';
import MySong from './mysong.component';
import AddSong from './addsong.component';
import EditSong from './editsong.component';
import AddPlaylist from './addPlaylist.component';
import MyLoggedinDevices from './myloggedindevices';
import MyAlbums from './myalbums.component';
import AddAlbum from './addalbum.component';
import EditAlbum from './editalbum.component';
import Albums from './albums.component';

import MyEvents from './myevents.component';
import EventDetail from './eventdetail.component';
import AddEvent from './addevent.component';
import EditEvent from './editevent.component';
import EditTickets from './edittickets.component';
import EditGallery from './editgallery.component';
import MyParticipants from './myParticipants.component';
import MyAtteendeeList from './myAttendeeList.component';
import MyParticipant from './myParticipant.component';
import MyAtteendee from './myAttendee.component';
import PrayerLine from './prayerline.component';
import ViewPrayer from './viewprayer.component';

import ChangeCardDetails from './changecarddetails.component';
import UpdatePlan from './updateplan.component';
import Reports from './reports.component';
import mySingleSong from './mysinglesong.component';

import SongApproval from './songapproval.component';
import RenewPlan from './renewplan.component';
import Followers from './followers.component';
import Follower from './follower.component';
import ChangePassword from './changepassword.component';
import Logout from './logout.component';
import LogoutFromAll from './logoutfromall.component';
import ArtistNotification from './artistNotification.component';
import EventReport from './eventReport.component';
import ListenerReport from './listenerReport.component';
import SongReport from './songReport.component';
import ArtistPayment from './artistpayment.component';
import topSingleSong from './topsinglesong.component';
import albumSingleSong from './albumsinglesong.component';
import TakeAttendance from './takeAttendance';
export default class ArtistLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    if(ls.get('role') === '1'){
      return <Redirect to="/" />;
    }
    const userid = ls.get('userid');
    if (userid === '') {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div className="dashboard-wrapper">
          {ls.get('membership_status') == 'Active' ? <SideMenu /> : <FreeSideMenu />}
          <div className="maincontent-area">
            <ArtistTopMenu />
            <div className="main-dashsection">
              <section className="contentpard">
                <Switch>
                  <Route exact path="/artist-dashboard" component={ArtistDashboard} />
                  <Route exact path="/artist-profile/:id" component={ArtistProfile} />
                  <Route exact path="/edit-artist-profile/:id" component={EditArtistProfile} />
                  <Route exact path="/notifications-artist" component={ArtistNotification} />
                  <Route exact path="/my-songs/:id" component={MySongs} />
                  <Route exact path="/my-song/:id" component={MySong} />
                  <Route exact path="/add-song/:id" component={AddSong} />
                  <Route exact path="/edit-song/:id" component={EditSong} />

                  <Route exact path="/my-albums/:id" component={MyAlbums} />
                  <Route exact path="/add-album/:id" component={AddAlbum} />
                  <Route exact path="/edit-album/:id" component={EditAlbum} />

                  <Route exact path="/my-events/:id" component={MyEvents} />
                  <Route exact path="/view-event/:id" component={EventDetail} />
                  <Route exact path="/add-event/:id" component={AddEvent} />
                  <Route exact path="/edit-event/:id" component={EditEvent} />
                  <Route exact path="/edit-ticket/:id" component={EditTickets} />
                  <Route exact path="/edit-gallery/:id" component={EditGallery} />

                  <Route exact path="/my-participants/:id" component={MyParticipants} />
                  <Route exact path="/attendee/:id" component={MyAtteendeeList} />
                  <Route exact path="/my-participant/:id" component={MyParticipant} />
                  <Route exact path="/my-attendee/:id" component={MyAtteendee} />
                  <Route exact path="/reports" component={Reports} />

                  <Route exact path="/prayer-line/:id" component={PrayerLine} />
                  <Route exact path="/view-prayer-rquest/:id" component={ViewPrayer} />

                  <Route exact path="/change-card-details/" component={ChangeCardDetails} />
                  <Route exact path="/change-plan/" component={UpdatePlan} />
                  <Route exact path="/mysongs/:id" component={mySingleSong} />

                  <Route exact path="/song-approval/:id" component={SongApproval} />
                  <Route exact path="/renew-plan/:id" component={RenewPlan} />
                  <Route exact path="/followers/:id" component={Followers} />
                  <Route exact path="/follower/:id" component={Follower} />
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path="/logoutfromall" component={LogoutFromAll} />
                  <Route exact path="/change-password/:id" component={ChangePassword} />
                  <Route exact path="/playlist-artist/:id" component={playListDetails} />
                  <Route exact path="/playlist-songs/:id" component={playListSongs} />
                  <Route exact path="/add-playlist/:id" component={AddPlaylist} />
				
                  <Route exact path="/aalbumsongs/:id" component={albumSingleSong} />
                  <Route exact path="/albums/" component={Albums} />
                  <Route exact path="/edit-playlist/:id" component={EditPlaylist} />
        				  <Route exact path="/artist-favoritesongs/" component={afavoriteSongs} />
        				  <Route exact path="/atopssongs/:id" component={topSingleSong} />
                  <Route exact path="/event-report/" component={EventReport} />
                  <Route exact path="/listener-report/" component={ListenerReport} />
                  <Route exact path="/song-report/" component={SongReport} />
                  <Route exact path="/myisrccode/" component={artistIRSCCode} />
                  <Route exact path="/payment/" component={ArtistPayment} />
				          <Route exact path="/amyloggedindevices/" component={MyLoggedinDevices} />
                  <Route exact path="/take-attendance" component={TakeAttendance} />
                </Switch>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
