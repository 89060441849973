import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import PrayerRequestList from './prayerrequestlist.component.js';
import Axios from 'axios';
import validator from 'validator';
import { Spinner } from 'react-bootstrap';
import ls from 'local-storage';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import moment from 'moment';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const base_api_url = API_URL;
export default class Songs extends Component {
	constructor(props) {
		super(props);
		this.messagesEndRef 	= React.createRef();
		this.onChangeRequest 	= this.onChangeRequest.bind(this);
		this.onChangeAnonymus	= this.onChangeAnonymus.bind(this);
		this.onSubmit 			= this.onSubmit.bind(this);
		this.state = {
		  email:'',
		  anonymus:'',
		  role:null,
		  userid:null,
		  token:'',
		  error: null,
		  request: '',
		  msg: null,
		  prayerlist:[],
		  items:[],
		  loading:true,
		  redirect:false,
		  btndisable:false,
		  buttontext:'Send Request'
		}
    }
	getAllPrayerlist = async() => {
		this.setState({
			role:ls.get('role')
		});
		
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ role: '3' })
		};
		
		
		await fetch(base_api_url+'prayerline.php',requestOptions)
        .then(response => response.json())
        .then(data => {
			if(data.res_status==200){	
				this.setState({
					prayerlist:data.res_data,
					loading:false 
				});
			}else{
				this.setState({
					error:data.res_message,												
					loading:false
				});
			}
		});
		
		this.scrollToBottom();
	}
	scrollToBottom = () => {
		const divToScrollTo = document.getElementById(`mssgbox`);
		if (divToScrollTo) {
			divToScrollTo.scrollIntoView({block: "end", inline: "end", behavior: "smooth" });
		}
	}
    componentDidMount(){
		document.title = "Prayer Request";
		this.getAllPrayerlist();
		console.log(this.messagesEndRef);
    }
	onChangeRequest(e){
		let checkrequest = validator.isEmpty(e.target.value,{ignore_whitespace:true}); 
		if(!checkrequest){
			this.setState({
				request: e.target.value,
				btndisable:true
			});
		}else{
			this.setState({
				request: e.target.value,
				btndisable:false
			});
		}
	}
	onChangeAnonymus(e){
		let isChecked = e.target.checked;
		let anonymusvalue = '';
		if(isChecked){
			anonymusvalue = e.target.value;
		}
		this.setState({
		  anonymus: anonymusvalue
		});
	}
	groupedDays(messages) {
	  return messages.reduce((acc, el, i) => {
	    const messageDay = moment(el.date.split(' ')[0]).format('MM/DD/YYYY');
	    if (acc[messageDay]) {
	      return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
	    }
	    return { ...acc, [messageDay]: [el] };
	  }, {});
	}
	generateItems(messages) {
		  const days = this.groupedDays(messages);
		  const sortedDays = Object.keys(days).sort(
		    (x, y) => moment(x, 'MM/DD/YYYY').unix() - moment(y, 'MM/DD/YYYY').unix()
		  );
		  const items = sortedDays.reduce((acc, date) => {
		    const sortedMessages = days[date].sort(
		      (x, y) => new Date(x.date.split(' ')[0]) - new Date(y.date.split(' ')[0])
		    );
		    return acc.concat([{ type: 'day', date, id: date }, ...sortedMessages]);
		  }, []);
		  return items;
		}
	onSubmit(e) {
		e.preventDefault();
		this.setState({buttontext:<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '3vh',float:'right' }}><Spinner style={{ width: '15px', height: '15px', display: 'block'}} variant="light" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div>});
		this.setState({
			loading:true
		})
		const formData = new FormData();
		const usertoken = this.props.match.params.id;
		const axiosConfig = {
			  'Content-Type': 'multipart/form-data',
			  "Access-Control-Allow-Origin": "*",
		};
		const user_id = '0';
		formData.append("sender_id", user_id);
		formData.append("request", this.state.request);
		formData.append("user_token", '');
		formData.append("anonymus", this.state.anonymus);
		
		Axios.post(base_api_url+'listenerprayerrequest.php', formData, {axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						successclass:'successmsg',
						msg:res.data.res_message,
						loading:false,
						buttontext:'Send Request'
					});					
					this.getAllPrayerlist();
					this.scrollToBottom();
					
				}else{
					window.scrollTo(0, 0);
					this.setState({
						successclass:'errormsg',						
						msg:res.data.res_message,
						loading:false,
						buttontext:'Send Request'
					});					
				}
				setTimeout(() => {
				  this.setState({
					successclass:'hideclass',
					msg:null
				  });
				}, 5000)
			}
		);
		this.refs.usertype.checked = false;
		this.setState({
			request:'',
			btndisable:false,
			anonymus:'',
		});	
	}
	
    render() {
		let sendername 		= '';
		let prayerlistdata 	= this.generateItems(this.state.prayerlist);
    	return(
			<React.Fragment>
				<div className="dashboard-wrapper">
					<AdminHeader />
					<div className="maincontent-area">
						<AdminTopHeader />
						<div className="main-dashsection">
							<section className="contentpard">
								<div className={this.state.successclass}>{this.state.msg}</div>
									<div className="row">
										<div className="col-md-12">
											<div className="box box-success distributions-profileform">
												<div className="box-header with-border addprofilenew">
													<h3 className="box-title">Prayer Line</h3>  
												</div> 
												<div className="box-body no-padding havetabs praeyrline" ref={this.messagesEndRef}>
													{(prayerlistdata.length != 0 )?<div className="direct-chat-messages prayerlinechatbox">{prayerlistdata.map((result,index) => {
														if(typeof(result.type) != "undefined"){
															return (<div className="sdfdstgrdeygty"><span>{result.date}</span></div>);
														}
														if(typeof(result.type) == "undefined"){
															return (<PrayerRequestList prayerrequestdata={result} />)
														}
													})}<div id="mssgbox3"></div><div id="mssgbox"></div></div>:<div className="">No request found!</div>}
												</div>
												<div className="box-body no-padding">
													<form className="artist-form" onSubmit={this.onSubmit}>
														<div className="row">
															<div className="col-md-12 col-xs-12">
																<div className="input-group sendmesagesection">
																	<input type="text" name="message" placeholder="Prayer Request..." className="form-control" value={this.state.request} onChange= {this.onChangeRequest} required="required" />
																	<span className="input-group-append">
																		<button type="submit" className="btn btn-primary" disabled={(!this.state.btndisable)?'disabled':'' }><i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;{this.state.buttontext}</button>
																	</span>
																</div>
																<div className="form-group">
																	<label htmlFor="anonymus"><input type="checkbox" ref="usertype" id="anonymus" className="htlanonymus" name="anonymus" value="1" onChange={this.onChangeAnonymus} /> Do you want to send a request as anonymous?</label>
																</div>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>        
							</section>
						</div>
					</div>
				</div>
			</React.Fragment>
    	)
    }
}