import React, { Component } from 'react';
import AdminHeader from './header.component';
import AdminTopHeader from './header-top.component';
export default class ComingSoon extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div class="dashboard-wrapper">
          <AdminHeader />
          <div class="maincontent-area">
            <AdminTopHeader />
            <div class="main-dashsection">
              <section class="contentpard">
                <div className="row">
                  <div className="col-md-12">
                    <div className="box box-success distributions-profileform">
                      <div className="box-header with-border addprofilenew">
                        <h3 className="box-title">Coming Soon</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}
