import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink, withRouter  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';


const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
export default class favoriteSongs extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
		  email:'',
		  role:null,
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  topartists:[],
		  topsongs:[],
		  loading:true,
		  successclass:null,
		  redirect:false,
		  type:'all'
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Favorite Songs";
		
		const email = ls.get('email');
		const name = ls.get('name');
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({
			email:email,
			name:name,
			token:token,
			userid:userid
		});
		
		this.getTopSongs();
		
	}
	
	getTopSongs = () => {
		this.setState({'type':'all'});
		const obj = {
			limit:'all',
			listenerid:ls.get('userid')
		}
		Axios.post(base_api_url+'getfavoritesongs.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.user_data
					this.setState({
						topsongs:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	songName(e){
		if(e.target.value){
			this.setState({song_name:e.target.value});
		}else{
			this.setState({song_name:''});
			this.getTopSongs();
		}
		
	}
	searchSongs = (e) => {
		this.setState({'type':'search'});
		let _data = [];
		let n = this.state.song_name;
		if(n){
			this.state.topsongs.map((results) => {
				if((results.title).match(new RegExp(n,'ig'))){
					_data.push(results);				
				}
			});
			this.setState({
				topsong:_data,
				loading:false
			});
		}else{
			this.setState({'type':'all'});
		}
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	handleKeyPress = (event) => {
	  	if(event.key === 'Enter'){
	    	this.searchSongs()
	  	}
	}
	render() {
		let songs 	= (this.state.type == 'all')?this.state.topsongs:this.state.topsong;
		
        if(this.state.loading==true){
			return <Spinner />;
		}
		let maxlimit = '20';
		return (
			<React.Fragment>	
				<div className={this.state.successclass}>{this.state.msg}</div>
					<React.Fragment>
						<div className="row topfivesongsd">
							<div className="col-md-12">
								<div className="todpfive">Favorite Songs 
									<span className="rightsidetext">
										<span className="navbar-search col"style={{'float':'left'}}>
								            <label className="sr-only screen-reader-text visually-hidden" for="search">Search for:</label>
								            <div className="input-group">
								            	<div className="input-search-field">
								            		<input type="text" id="search" className="form-control search-field product-search-field" dir="ltr" onChange={this.songName.bind(this)} placeholder="Search Songs" onKeyPress={this.handleKeyPress} autocomplete="off" />
								            	</div>
								            	<div className="input-group-btn">
										            <button type="submit" onClick={() => this.searchSongs()} className="btn btn-secondary"><i className="fa fa-search"></i></button>
								            	</div>
								            </div>
								        </span>
										<Link to="#" onClick={this.props.history.goBack} className="btn btn-primary mb-3">
											<i className="fa fa-angle-double-left" aria-hidden="true"></i>Back
										</Link>
									</span>
								</div>
							</div>
						</div>
						<div className="row artisitblock allartistsongs">
							{(songs.length)?songs.map((results) => {
								let topsongurl = '/topssongs/'+results.song_id;
								return (
									<div className="col-md-3">
										<Link to={topsongurl}>
										<div className="featureartprifles">
										   <div className="featrueimages">
											  <img onError={this.addDefaultSrc} src={results.banner_pic} />
										   </div>
										   <div className="featurestext">
											  <h3 title={results.title}>{ ((results.title).length > maxlimit) ? (((results.title).substring(0,maxlimit-3)) + '...') :results.title }</h3>
											  <span className="nooffollowers songlikes"><i className="fa fa-heart"></i> {results.slike}</span>
											  <span className="nooffollowers playicons"><i className="fa fa-play-circle"></i> {results.slistened}</span>
										   </div>
										</div>
										</Link>
									</div>
								)
								}):<div className="not-found"><span>No song found!</span></div>
							}
						</div>
					</React.Fragment>
			</React.Fragment>
		)
	}
}