import React, { Component } from 'react';
import Axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import DOMPurify from "dompurify";
import Spinner from '../includes/Spinner.component'
import ls from 'local-storage';


const base_api_url = API_URL;
export default class Login extends Component {
	
	constructor(props) {
		super(props);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeArtPassword = this.onChangeArtPassword.bind(this);
		this.onClickPass = this.onClickPass.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			email: '',
			role: null,
			password: '',
			token: '',
			error: null,
			message: null,
			loader: null,
			redirect: false,
			pass: true
		}
	}
	componentDidMount() {
		document.title = "CONQER Music - Account";
	}
	
	onChangeEmail(e) {
		this.setState({
			email: e.target.value
		});
	}

	onChangeArtPassword(e) {
		this.setState({
			password: e.target.value
		});
	}
	onClickPass(e){
		let p=true;
		if(this.state.pass==true){
			p=false;
		}
       this.setState({
           pass:p
       }); 
	}


	onSubmit(e) {
		e.preventDefault();

		const obj = {
			email: this.state.email,
			password: this.state.password
		}

		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		};
		this.setState({
			loader: true
		})
		Axios.post(base_api_url + 'login.php', obj, { axiosConfig })
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						if(res.data.activation=='1'){
							if(res.data.userstatus=='1'){
								ls.set('email', res.data.email);
								ls.set('role', res.data.role);
								ls.set('name', res.data.name);
								ls.set('loginstatus', res.data.loginstatus);
								ls.set('token', res.data.token);
								ls.set('userid', res.data.userid);
								ls.set('profile_pic', res.data.profile_pic);
								ls.set('membership', res.data.membership);
								ls.set('membership_status', res.data.membership_status);
								ls.set('profile', res.data.profile_pic);
								ls.set('membership_date', res.data.membership_date);
								this.setState({
									role: res.data.role,
									redirect: true,
									loader: false
								});

								setTimeout(() => {
									this.setState({
										loader: null,
									});
								}, 900);
							}else{
								this.setState({
									redirect: false,
									message: res.data.res_message,
									loader: false
								});
							}
						}else{
							this.setState({
								redirect: false,
								message: res.data.res_message,
								loader: false
							});
						}
					} else {
						this.setState({
							redirect: false,
							message: res.data.res_message,
							loader: false
						});
					}
					setTimeout(() => {
						this.setState({
							redirect: false,
							message: '',
							loader: false
						});
					}, 10000);
			});
	}


	render() {
		if (this.state.loader == true) { 
			return <Spinner />;
		}
		const userid 	= ls.get('userid');
		const userrole 	= ls.get('role');
		
		if (this.state.role == 2) {
			return <Redirect to="/artist-dashboard" />
		} else if (this.state.role == 1) {
			return <Redirect to="/listener-dashboard" />
		}/* else{
			if (userid) {
				if (userrole == 2) {
					return <Redirect to="/artist-dashboard" />
				} else if (userrole == 1) {
					return <Redirect to="/listener-dashboard" />
				}
			}
		} */
		
		return (

			<React.Fragment>
				<section className="become-artist-form">
					<div>
						<form className="userloginform" onSubmit={this.onSubmit}>
							<div className="userlogin">
								<div className="formpart">
									<span className="errorMsg">{this.state.message}</span>
									<div className="form-group">
										<label id="user-icon" htmlFor="user">Email<span className="requiredastrik">*</span></label>
										<input type="text" className="form-control" id="email" required="required" value={this.state.email} onChange={this.onChangeEmail} />
									</div>
									<div className="form-group">
										<label id="psw-icon" htmlFor="password">Password<span className="requiredastrik">*</span></label>
										<input type={(this.state.pass)?'password':'text'} className="form-control" id="password" required="required" value={this.state.artpassword} onChange={this.onChangeArtPassword} />
									    <i class="fa fa-eye" id="eye"  onClick={this.onClickPass}></i> 
									</div>

									<div className="sub-formpart">
										<button type="submit" className="btn">Log in </button>
									</div>

									<div className="registration-form-user">
										<p>
											<Link to={'/forgot-password'} target="_blank" style={{"text-decoration":"underline"}}>Forgot Your Password?</Link>
										</p>
									</div>
								</div>
							</div>
						</form>
					</div>
				</section>
			</React.Fragment>
		)

	}
}