import React, { Component } from 'react';
import Axios from 'axios';
import ArtistTopMenu from '../includes/artisttopmenu.component';
import  { Redirect, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
const base_api_url = API_URL;
const regex = /^[a-z\d\,-_\s]+$/i;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
export default class ResetPassword extends Component {
	constructor(props){
		super(props);
		this.onChangePassword 			= this.onChangePassword.bind(this);		
		this.onChangeConfirmPassword 	= this.onChangeConfirmPassword.bind(this);		
		this.onBlurPassword 			= this.onBlurPassword.bind(this);		
		this.onBlurConfirmPassword 		= this.onBlurConfirmPassword.bind(this);		
		this.onSubmit 					= this.onSubmit.bind(this);
				
		this.state = {
			user_id:'',
			resetpass_status:'',
			password:'',
			confirmPassword:'',
			error: null,
			msg: null,
			successclass:'hideclass',
			wrongpassmsg:'',
			wrongcpassmsg:'',
			redirect:false			
		}		
	}
	componentDidMount(){
		document.title = "CONQER Music - Reset Password";
		this.checkResetPassLink();
	}
	
	
	checkResetPassLink = () =>{
		const obj = {
			resetpass_code : this.props.match.params.id
		}
		
		Axios.post(base_api_url+'checkresetpasslink.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						resetpass_status:res.data.res_data.resetpass_status,						
						user_id:res.data.res_data.user_id,						
						loading:false
					});
				}
			}
		);
	}
	onChangePassword(e) {
		this.setState({
		  password: e.target.value
		});
	}
	
	onChangeConfirmPassword(e) {
		this.setState({
		  confirmPassword: e.target.value
		});
	}
	onBlurPassword(e){
		let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		let checkpass = strongRegex.test(e.target.value);//'';//isStrongPassword(e.target.value);
		if(checkpass){
			this.setState({
				password: e.target.value,
				wrongpassmsg:''
			})
		}else{
			this.setState({
				password: '',
				wrongpassmsg: 'Password should have minimum 8 character, 1 symbol, 1 uppercase, 1 lowercase.',
			})
		}
	}
	onBlurConfirmPassword(e){
		let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		let checkpass = strongRegex.test(e.target.value);//'';//isStrongPassword(e.target.value);
		if(checkpass){
			this.setState({
				confirmPassword: e.target.value,
				wrongcpassmsg:''
			})
		}else{
			this.setState({
				confirmPassword: '',
				wrongcpassmsg: 'Password should have minimum 8 character, 1 symbol, 1 uppercase, 1 lowercase.',
			})
		}
	}
	
	onSubmit(e) {
		e.preventDefault();
		
		const { password, confirmPassword } = this.state;
		// perform all neccassary validations
		if (password !== confirmPassword) {
			alert("Passwords don't match");
		} else {
			const formData = new FormData();
					
			formData.append("newpassword", this.state.password);
			formData.append("confirmpassword", this.state.confirmPassword);
			formData.append("userid", this.state.user_id);
			
			Axios.post(base_api_url+'reset-password.php', formData, {axiosConfig})
			.then(
				(res) => {console.log('res',res.data);
					if(res.data.res_status==200){
						window.scrollTo(0, 0);
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
							redirect:true
						});							
					}else{
						window.scrollTo(0, 0);
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message,
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:'hideclass',
						msg:null
					  });
						let redirectUrl ='';
						if(this.state.redirect){
							redirectUrl =  '/login';
							this.props.history.push(redirectUrl);
						}
					}, 5000)
				}
			);
		}
		
		this.setState({
			email:'',
			password: '',
			confirmPassword: ''

		});		
	}
    render() {
		const resetpassstatus = this.state.resetpass_status;
		const message = this.state.msg;
		if ( resetpassstatus === 0){
			return <Redirect to='/forgot-password'  />
		}
        return (
			<React.Fragment>
				<section className="forget-pass">
					<div className="container">						
						<div className="fogotpassform">
							<div className="affendforgot">							
								<div className={this.state.successclass}>{this.state.msg}</div>
								
								<form className="artist-form" onSubmit={this.onSubmit}>
									<div className="form-group">
										<label  htmlFor="npass">New Password<span className="requiredastrik">*</span></label>
										<input type="password" className="form-control" id="npass" value={this.state.password} required="required" onChange={this.onChangePassword} onBlur={this.onBlurPassword} />
										<span className="wrongpassmsg-1">{this.state.wrongpassmsg}</span>
									</div>
									<div className="form-group">
										<label  htmlFor="ncpass">Confirm Password<span className="requiredastrik">*</span></label>
										<input type="password" className="form-control" id="ncpass" value={this.state.confirmPassword} required="required" onChange={this.onChangeConfirmPassword}  onBlur={this.onBlurConfirmPassword} />
										<span className="wrongpassmsg-1">{this.state.wrongcpassmsg}</span>
									</div>
									<div className="form-group">
										<button type="submit" className="btn btn-primary validate">Submit</button>
									</div> 
								</form>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}