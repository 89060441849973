import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';


const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  "Access-Control-Allow-Origin": "*",
}
const mapStyles = {
  width: '100%',
  height: '300px',
  position:'relative'
};
export class EventDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventtitle: '',
      alias: '',
      startdate: '',
      starttime: '',
      enddatetime: '',
      eventcategory: '',
      access: '',
      allowpermission: '',
      venue: '',
      eventlocation: '',
      eventimage: '',
      oldimage: '',
      eventkeyword: '',
      eventmetadescription: '',
      description: '',
      bookingstart: '',
      bookingend: '',
      ticket: [],
      gallery: [],
      markers: [],
	  loading:true,
    }
  }
  componentDidMount() {
    document.title = "CONQER Music - Event";
    const token = ls.get('token');
    const userid = ls.get('userid');
    this.geteventdata();
    this.setState({
      token: token,
      userid: userid
    });

  }

  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });

  componentDidUpdate(){
		$('#ticketlist2').DataTable(
			{	
				"destroy": true,
				"stateSave": true,
				"pageLength":10,
				"language": {
					"search": "Search"
				},
				"lengthMenu": [[5, 10, 15, -1], [5, 10, 15, "All"]]
			}
		);
	}
  geteventdata = () => {
    const eventid = this.props.match.params.id;

    const obj = {
      event_id: eventid,
      userid: ls.get('userid'),
      role: ls.get('role'),
    }

    Axios.post(base_api_url + 'vieweventdetails.php', obj, { axiosConfig })
      .then(
        (res) => {
          if (res.data.res_status == 200) {
            this.setState({
              eventtitle: res.data.res_event.name,
              alias: res.data.res_event.alias,
              startdate: res.data.res_event.startdate,
              starttime: res.data.res_event.starttime,
              enddate: res.data.res_event.enddate,
              endtime: res.data.res_event.endtime,
              eventcategory: res.data.res_event.event_category,
              access: res.data.res_event.access_level,
              allowpermission: res.data.res_event.allowpermission,
              venue: res.data.res_event.event_venu,
              address: res.data.res_event.address,
              city: res.data.res_event.city,
              state: res.data.res_event.state,
              country: res.data.res_event.country,
              zipcode: res.data.res_event.zipcode,
              lat: res.data.res_event.lat,
              lng: res.data.res_event.lng,
              eventimage: res.data.res_event.path,
              oldimage: res.data.res_event.photo_id,
              eventkeyword: res.data.res_event.keyword,
              eventmetadescription: res.data.res_event.meta_description,
              description: res.data.res_event.description,
              bookingstart: res.data.res_event.booking_start_date,
              bookingend: res.data.res_event.booking_end_date,
              artist_name: res.data.res_event.artist_name,
              available: res.data.res_event.available,
              booking_start: res.data.res_event.booking_start,
              booking_end: res.data.res_event.booking_end,
              id: res.data.res_event.id,
              gallery: res.data.res_gallery,
              ticket: res.data.res_ticket,

			  loading:false
            })
          } else {
            this.setState({
              redirect: false,
              albumdata: false,
              msg: null,
              successclass: null,
			  loading:false
            });
          }
        }
      );
  }
  alertMessage(type){
    if(type === 'not_start'){
      alert('Ticket booking not started yet.');
    }else{
      alert('Ticket booking are closed.');
    }
  }
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
  render() {
    let role = ls.get('role'); 
	var bannerimage = this.state.eventimage;
	if(this.state.loading==true){
		return <Spinner />;
	}
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">View Event</h3>
                <div className="box-tools">
                {(role == 2)? <NavLink to={'/my-events/'+this.state.token} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>:<NavLink to={'/listener-myevents/'+this.state.token} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>}
                
                </div>
              </div>
              <div className="box-body no-padding havetabs eventadds">
                <div className="nav-tabs-custom">
                  <ul className="nav nav-tabs">
                    <li className="nav-item"><a href="#tab_1" data-toggle="tab" aria-expanded="true" className="nav-link active">Event Details</a></li>
                    <li className="nav-item"><a href="#tab_2" data-toggle="tab" aria-expanded="false" className="nav-link">Ticket Types</a></li>
                    <li className="nav-item"><a href="#tab_3" data-toggle="tab" aria-expanded="false" className="nav-link">Gallery</a></li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="tab_1">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sectionhead">
                            <div className="headsec">
                              <h3>Basic Information</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-md-12">
                          <div className="box box-success eents-viewpage">                
                            <div className="map-loaction">
                              <Map google={this.props.google}
                                style={mapStyles}
                                className={'map'}
                                zoom={17}
                                initialCenter={{lat: this.state.lat, lng: this.state.lng}}
                                >
                              <Marker
                              onClick={this.onMarkerClick}
                                position={{lat: this.state.lat, lng: this.state.lng}} />
                                <InfoWindow
                              marker={this.state.activeMarker}
                              onClose={this.onInfoWindowClose}
                              visible={this.state.showingInfoWindow}
                            >
                              <div>
                                <p>{this.state.eventtitle}</p>
                              </div>
                            </InfoWindow>
                              </Map>
                            </div>
                            <div className="box-body no-padding">
                  <div className="events-detailsection">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="event-name">
                          <h2>{this.state.eventtitle}</h2>                          
                        </div>
                        <div className="evedetails">
                          <div className="desiprtions">
                            <div className="row">
                              <div className="col-md-12">
                                <p><i className="fa fa-exclamation" aria-hidden="true"></i>{this.state.description}</p>
                              </div>                              
                            </div>
                          </div>
                          <div className="desiprtions">
                            <div className="row">
                              <div className="col-md-12">
                                <p><i className="fa fa-calendar" aria-hidden="true"></i>{this.state.startdate} - {this.state.enddate}<br />{this.state.starttime} - {this.state.endtime}</p>
                              </div>                              
                            </div>
                          </div>
                          <div className="desiprtions">
                            <div className="row">
                              <div className="col-md-6">
                                <p><i className="fa fa-location-arrow" aria-hidden="true"></i>{(this.state.address)?this.state.address+' '+this.state.city:""}<br />{(this.state.state)?this.state.state+', '+this.state.country+' '+this.state.zipcode:''}</p>
                              </div>                              
                              <div className="col-md-6">
                                <p><i className="fa fa-eye-slash" aria-hidden="true"></i>{this.state.access}</p>
                              </div>
                            </div>
                          </div>
						  {(role != 2)?
						  <div>
							  <div className="eventseprates">
								<div className="datetime-aftersearch">
									<div className="event-times icons">
									  <i className="fa fa-user" aria-hidden="true"></i>
									</div>
									<p>{this.state.artist_name}</p>
								</div>
							  </div>
							  <div className="eventseprates">
								<div className="datetime-aftersearch">
									<div className="event-times icons">
									  <i className="fa fa-exclamation" aria-hidden="true"></i>
									</div>
									<h4 className="pull-left">{(this.state.available)?'Seats Available':'Not Available'}</h4>
								</div>
							  </div>
							  <div className="registertoeventbtns">
								   {(this.state.available)?(this.state.booking_start)?(this.state.booking_end)?<Link to="#" className="btn btn-primary" onClick={() => this.alertMessage("end")} style={{'color': '#fff'}}>Book Ticket</Link>:
									<Link to={'/book-ticket/'+this.state.id} className="btn btn-primary" style={{'color': '#fff'}}>Book Ticket</Link>:<Link to="#" className="btn btn-primary" onClick={() => this.alertMessage("not_start")} style={{'color': '#fff'}}>Book Ticket</Link>
								  :''}
							   </div>
						  </div>:''}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="rightsideevent">
                          <div className="eventgalry">
                            <img onError={this.addDefaultSrc} src={bannerimage} onClick={() => this.onclickseturl(bannerimage)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                        
                </div>
                          </div>
                        </div>
                      </div>


                    </div>

                    <div className="tab-pane" id="tab_2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sectionhead">
                            <div className="headsec">
                              <h3>Ticket Information</h3>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="table-responsive">
                          <table id="ticketlist2" className="table no-margin table-striped table-hover">
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th className="text-center">Price</th>
                                <th>End Date</th>
                                <th>Access</th>
                                <th className="text-center">Tickets</th>
                                <th className="text-center">Sold</th>
                                <th className="text-center">Available</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.ticket.map((result2) => {
                                return (
                                  <tr>
                                    <td>{result2.ticket_title}</td>
                                    <td className="text-center">${result2.price} </td>
                                    <td>{result2.end_date}</td>
                                    <td>{result2.access}</td>
                                    <td className="text-center">{result2.seats}</td>
                                    <td className="text-center">{result2.sold}</td>
                                    <td className="text-center"><span className="label label-success">{result2.available_seats}</span></td>
                                    <td>{result2.description}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane" id="tab_3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sectionhead">
                            <div className="headsec">
                              <h3>Gallery Images</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="customFile">{(this.state.gallery.length)?'Images':'No Images'}</label>
                            <div className="uploadimgs editeventimage">
								<div className="row">
								  {this.state.gallery.map((gimage) => {
									return (<div className="col-md-2 custombox"><img onError={this.addDefaultSrc} src={gimage.image} style={{'width':'100%'}} className="img-responsive img-thumbnail" onClick={() => this.onclickseturl(gimage.image)} /></div>)
								  }
								  )}
								</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sectionhead">
                            <div className="headsec">
                              <h3>Video</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="customFile">{(this.state.gallery.length)?'Video Link':'No Video Link'}</label>
                            <div className="uploadimgs videolinks">
                              {this.state.gallery.map((gvideo) => {
                                return (<div><a href={(gvideo.video !== 'null')?gvideo.video:'javascript:void(0);'} target="_blank">{(gvideo.video !== 'null')?gvideo.video:''}</a></div>)
                              }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix"></div>
            </div>
          </div>
        </div>
		<div id="myModal" className="modal fade imgmodal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
					<div className="modal-body">
						<img className="showimage img-responsive" src={this.state.urlvalinitial} />
					</div>            
				</div>
			</div>
		</div>
      </React.Fragment>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0'
})(EventDetail);