import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';
import createHistory from 'history/createBrowserHistory';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import {toast} from 'react-toastify';
const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
const history = createHistory();
toast.configure();
export default class Albums extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      role: null,
      userid: null,
      token: '',
      error: null,
      msg: null,
      songlist: [],
      items: [],
      loading: true,
      successclass: null,
      redirect: false
    };
  }

  componentDidMount(props) {
    document.title = 'CONQER Music - Albums';

    const email = ls.get('email');
    const name = ls.get('name');
    const token = ls.get('token');
    const userid = ls.get('userid');
    let message = '';
    if (this.props.location.state !== null && this.props.location.state !== undefined) {
      message = this.props.location.state.message;
    }
    this.setState({
      email: email,
      name: name,
      token: token,
      userid: userid,
      msg: message,
      successclass: 'successmsg'
    });
    if(message){
      let state = { ...history.location.state };
        delete state.message;
        history.replace({ ...history.location, state });
        setTimeout(() => {
        this.setState({
          successclass: null,
          msg: null
        });
      }, 5000);
    }
    this.getAllAlbumlist();
  }
  deletealbum = (albumid, status) => {
    const obj = {
      id: albumid,
      status: status
    };

    Axios.post(base_api_url + 'updatestatusalbum.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        toast.success(res.data.res_message);
      } else {
        toast.error(res.data.res_message);
      }
      this.getAllAlbumlist();
      /*setTimeout(() => {
        
        this.setState({
          successclass: null,
          msg: null
        });
      }, 10000);*/
    });
  };

  getAllAlbumlist = () => {
    const obj = {
		userid: ls.get('userid'),
		myalbum: 'myalbum',
    };
    Axios.post(base_api_url + 'artistalbumlist.php', obj).then((res) => {
      if (res.data.res_status == 200) {
        let returndata = res.data.res_data;
        //console.log('returndata',returndata);return false;
        this.setState({
          songlist: returndata,
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
      setTimeout(() => {
        $('#myalbumlist').DataTable({
          destroy: true,
          pageLength: 10,
		  stateSave: true,
		  language: {
			search: "Search"
		  },
          lengthMenu: [
            [5, 10, 15, -1],
            [5, 10, 15, 'All']
          ]
        });
      }, 500);
    });
  };
  commonAlert(){
    toast.error('Please inactivate album before edit.');
  }
  onclickseturl = (urlval) => {
    this.setState({
      urlvalinitial: urlval
    });
    $('#myModal').modal('show');
  };
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };

  render() {
    let songlists = this.state.songlist;

    if (this.state.loading == true) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
        {this.state.msg ? <div className={this.state.successclass}>{this.state.msg}</div> : ''}
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">Album List</h3>
                <div className="box-tools">
                  <NavLink to={'/add-album/' + this.state.token} className="btn btn-primary">
                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add New Album
                  </NavLink>
                  {songlists.length > 0 ? <React.Fragment></React.Fragment> : <React.Fragment></React.Fragment>}
                </div>
              </div>
              <div className="box-body table-responsive no-padding">
                <table id="myalbumlist" className="table no-margin table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Cover</th>
                      <th className="text-center">Songs</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th className="actionbtnsets text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.songlist.map((result) => {
                      var albumimage = result.banner;
                      return (
                        <tr>
                          <td>{result.name}</td>
                          <td>
                            <img
                              onError={this.addDefaultSrc}
                              src={albumimage}
                              width="50"
                              height="50"
                              onClick={() => this.onclickseturl(albumimage)}
                            />
                          </td>
                          <td className="text-center">{result.songs}</td>
                          <td>{result.date}</td>
                          <td>
                            <label className={result.status == 'Active' ? 'label label-success' : 'label label-danger'}>{result.status}</label>
                          </td>
                          <td className="actionbtns">
                            <Link to={'/my-songs/' + result.id} title="View Songs" className="btn btn-info custombtn">
                              <i className="fa fa-music"></i>
                            </Link>
                            &nbsp;
                            {(result.status == 'Active')?<Link to="#" onClick={() => this.commonAlert()} title="Edit Album" className="btn btn-info custombtn">
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>:<Link to={'/edit-album/' + result.id} title="Edit Album" className="btn btn-info custombtn">
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>}
                            &nbsp;
                            <NavLink to={'/add-song/' + result.id} title="Add new song" className="btn btn-info custombtn">
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </NavLink>
                            &nbsp;
                            <a
                              href="javascript:;"
                              title={result.status == 'Active' ? 'In Active' : 'Active'}
                              onClick={() => this.deletealbum(result.id, result.status)}
                              className="btn btn-info custombtn"
                            >
                              {result.status !== 'In Active' ? (
                                <i className="fa fa-ban" aria-hidden="true"></i>
                              ) : (
                                <i className="fa fa-check" aria-hidden="true"></i>
                              )}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div id="myModal" className="modal fade imgmodal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <button type="button" className="btn btn-default closeicon" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-body">
                <img className="showimage img-responsive" src={this.state.urlvalinitial} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
