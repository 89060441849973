import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import  { NavLink } from 'react-router-dom';
import ls from 'local-storage';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import $ from 'jquery';
import Select from 'react-select';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
const errors = {};
export default class EditSong extends Component {
	
	constructor(props){
		super(props);
		this.onChangeReleaseType = this.onChangeReleaseType.bind(this);
		this.onChangeUploadCover = this.onChangeUploadCover.bind(this);
		this.onChangeSongTitle = this.onChangeSongTitle.bind(this); 
		this.onChangeSongVersion = this.onChangeSongVersion.bind(this);
		this.onChangeNewRelease = this.onChangeNewRelease.bind(this);

		this.onChangeMultiArtist = this.onChangeMultiArtist.bind(this);
		this.onChangeSongArtist = this.onChangeSongArtist.bind(this);
		this.onChangeSongGenre = this.onChangeSongGenre.bind(this);
		this.onChangeSongSubGenre = this.onChangeSongSubGenre.bind(this);
		this.onChangeSongCopyright = this.onChangeSongCopyright.bind(this);
		this.onChangeSongRecordLabel = this.onChangeSongRecordLabel.bind(this);
		this.onChangeLyrics = this.onChangeLyrics.bind(this);
		this.onChangeSongCode = this.onChangeSongCode.bind(this);
		this.onChangeUploadSong = this.onChangeUploadSong.bind(this);
		this.onChangeLengthMinute = this.onChangeLengthMinute.bind(this);
		this.onChangeLengthSecond = this.onChangeLengthSecond.bind(this);
		this.onChangeAlbum = this.onChangeAlbum.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		
		this.lengthSecondInput 	= React.createRef();
			
		this.state = {			
			error: null,
			msg: null,
			successclass:null,
			redirect:false,
			releaselist:[],
			genrelist:[],
			subgenrelist:[],
			albumlist:[],
			songdata:'',
			errors: [],
			filesongerror:'',
			filecovererror:'',
			buttontext : 'Update Item',
			selectedcover:'Choose File',
			selectedsong:'Choose File',
			lengthminute:'',			
			lengthsecond:'',
			loadinng:false,
			formValid: true
		}		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Edit song";
		this.getSongDetails();
		this.getreleasetype();
		this.getgenretype();
		this.getalbumlist();
		/*setTimeout(()=>{                        
			this.getGenreDetails();
			this.getSubGenreDetails();
		}, 3000);*/
	}
	
	getSongDetails = () => {
		const songid = this.props.match.params.id;
		const userid = ls.get('userid');
		const obj = {
			songid:songid,
			userid:userid
		}
		Axios.post(base_api_url+'getsong.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					let songlength 		= res.data.res_data.song_length;
					let songlengtharray = songlength.split(':');
					let lenminute = songlengtharray[0];
					let lensecond = songlengtharray[1];
					this.setState({
						distribution_title : res.data.res_data.distribution_title,
						release : res.data.res_data.release_type,
						song_title : res.data.res_data.title,
						song_version : res.data.res_data.version,
						song_release : res.data.res_data.is_new_release,
						multiartist : res.data.res_data.is_multiartist,
						artist : res.data.res_data.list_artist,
						genre : res.data.res_data.genre_id,
						subgenre : res.data.res_data.sub_genre_id,
						song_copyright : res.data.res_data.copyright,
						song_label : res.data.res_data.record_label,
						song_code : res.data.res_data.ISRC_code,
						song_file : '',
						uploadcover : '',
						oldbannerpath: res.data.res_data.banner_path,
						oldsongpath: res.data.res_data.song_path,
						song_file_old : res.data.res_data.file_id,
						uploadcover_old : res.data.res_data.banner_id,
						lyrics : res.data.res_data.lyrics,
						lengthminute:lenminute,
						lengthsecond:lensecond,
						album : res.data.res_data.album_id,
						song_id : res.data.res_data.id,
						subgenrelist: res.data.sub_genre_data
					});
				}
			}
		);
	}
	
	
	getreleasetype = () => {
		const usertoken = this.props.match.params.id;
		
		const obj = {
			token:usertoken
		}
		Axios.post(base_api_url+'releasetype.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						releaselist:res.data.res_data,
					});
				}
			}
		);
	}
	
	getalbumlist = () => {
		const obj = {
			userid:ls.get('userid'),
			myalbum:'myalbum'
		}
		
		Axios.post(base_api_url+'artistalbum-v2.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						albumlist:res.data.res_data,
					});
				}
			}
		);
	}	
	
	getgenretype = () => {
		const usertoken = this.props.match.params.id;
		
		const obj = {
			token:usertoken
		}
		Axios.post(base_api_url+'genrelist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						genrelist:res.data.res_data,
					});
				}
			}
		);
	}

	handleValidation = () => {
		let formIsValid = true;
		
		if(!this.state.song_version){
			formIsValid = false;
			errors["version"] = "Please enter song version.";
		}

		if(!this.state.song_title){
			formIsValid = false;
			errors["title"] = "Please enter song title.";
		}
		
		if(!this.state.lengthsecond){
			formIsValid = false;
			this.lengthSecondInput.current.focus();
			errors["lengtherror"] = "Please enter song length.";
		}

		if(!this.state.album){
			formIsValid = false;
			errors["albumerror"] = "Please select album name.";
		}

		if(!this.state.release){
			formIsValid = false;
			errors["releaseerror"] = "Please select release type.";
		}

		if(!this.state.song_code){
			formIsValid = false;
			errors["isrcerror"] = "Please add ISRC code for song.";
		}
		if(!this.state.formValid){
			formIsValid = false;
		}
		
		this.setState({errors: errors});
		console.log(this.state.errors['version']);
        return formIsValid;
	}

	onChangeUploadCover(e){
		if(this.state.errors['cover_error']){delete this.state.errors['cover_error'];}
		this.setState({formValid: true });
		if(e.target.files[0]){
			var validExt = ".png, .gif, .jpeg, .jpg";
			var filePath = e.target.files[0].name;
			var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			var pos = validExt.indexOf(getFileExt);
			var maxSize = '10240';
			var fsize = e.target.files[0].size/1024;
			//console.log(pos);
			if(pos < 0){
				errors['cover_error'] = "Sorry, " + filePath + " is invalid, allowed extensions are: " + validExt;
				this.setState({selectedcover:e.target.files[0].name, uploadcover: e.target.files[0], formValid: false });
			}else{
				this.setState({selectedcover:e.target.files[0].name, uploadcover: e.target.files[0] });
			}
			//this.setState({selectedcover:e.target.files[0].name, uploadcover: e.target.files[0] });
		}
	}
	
	onChangeUploadSong(e) {
		if(this.state.errors['file_error']){delete this.state.errors['file_error'];}
		this.setState({formValid: true });
		if(e.target.files[0]){
			var validExt = ".mp3";
			var filePath = e.target.files[0].name;
			var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			var pos = validExt.indexOf(getFileExt);
			var maxSize = '10024';
			var fsize = e.target.files[0].size/1024;

			 if(pos < 0){
				errors['file_error'] = "Sorry, " + filePath + " is invalid, allowed extensions are: " + validExt;
				this.setState({selectedsong:e.target.files[0].name, song_file: e.target.files[0], formValid: false });
			}else{
				this.setState({selectedsong:e.target.files[0].name, song_file: e.target.files[0]});
			}
			//this.setState({selectedsong:e.target.files[0].name, song_file: e.target.files[0],filesongerror:'' });
		}
	}
	
	onChangeReleaseType(e){
		if(this.state.errors['releaseerror']){delete this.state.errors['releaseerror'];}
		this.setState({
		  release: e.target.value
		});
	}
	
	onChangeSongTitle(e){
		if(this.state.errors['title']){delete this.state.errors['title'];}
		this.setState({
		  song_title: e.target.value
		});
	}
	
	onChangeSongVersion(e){
		if(this.state.errors['version']){delete this.state.errors['version'];}
		this.setState({
		  song_version: e.target.value
		});
	}
	
	onChangeNewRelease(e){
		this.setState({
		  song_release: e.target.value
		});
	}
		
	onChangeMultiArtist(e){
		this.setState({
		  multiartist: e.target.value
		});
	}
	
	onChangeSongArtist(e){
		this.setState({
		  artist: e.target.value
		});
	}
	onChangeSongGenre(e){
		let subGList = [];
		let gList = this.state.genrelist;
		 gList.map((result) => {
		 	if(result.parent_id == e.target.value){
		 		subGList.push(result);
		 	}
		 });
		this.setState({
			subgenrelist:subGList,
		  	genre: e.target.value
		});
	}
	
	onChangeSongSubGenre(e){
		this.setState({
		  subgenre: e.target.value
		});
	}
	
	onChangeSongCopyright(e){
		this.setState({
		  song_copyright: e.target.value
		});
	}
	
	onChangeSongRecordLabel(e){
		this.setState({
		  song_label: e.target.value
		});
	}
	
	onChangeSongCode(e){
		if(this.state.errors['isrcerror']){delete this.state.errors['isrcerror'];}
		this.setState({
		  song_code: e.target.value
		});
	}
	
	onChangeLyrics(e){
		this.setState({
		  lyrics: e.target.value
		});
	}
	
	onChangeLengthMinute(e){
		if(this.state.errors['lengtherror']){delete this.state.errors['lengtherror'];}
		this.setState({
		  lengthminute: e.target.value
		});
	}
	onChangeMinute(e){
		let minuteVal 	= e.target.value;
		if(minuteVal){
			let minuteValue = (minuteVal.toString().length < 2) ? "0"+minuteVal : minuteVal;
			this.setState({
			  lengthminute: minuteValue,
			  lengthsecond: '00'
			});
		}
	}
	onChangeLengthSecond(e){
		if(this.state.errors['lengtherror']){delete this.state.errors['lengtherror'];}
		
		this.setState({
		  lengthsecond: e.target.value
		});
	}
	onChangeSecond(e){
		let secondVal 	= e.target.value;
		if(secondVal){
			let secondValue = (secondVal.toString().length < 2) ? "0"+secondVal : secondVal;
			this.setState({
			  lengthsecond: secondValue
			});
		}
	}
	
	onChangeAlbum(e){
		if(this.state.errors['albumerror']){delete this.state.errors['albumerror'];}
		this.setState({
		  album: e.target.value
		});
	}
	
	onSubmit(e) {		
		e.preventDefault();
		this.setState({
			loadinng:true,
			filesongerror:''
		})
		if(this.handleValidation()){
			const formData = new FormData();
			const usertoken = this.props.match.params.id;
			const axiosConfig = {
				'Content-Type': 'multipart/form-data',
				"Access-Control-Allow-Origin": "*",
			};
			
			if(this.state.uploadcover.name!=null){		
				formData.append("cover", this.state.uploadcover, this.state.uploadcover.name);
			}else{
				formData.append("old_banner", this.state.uploadcover_old);
			}
			
			if(this.state.song_file.name!=null){		
				formData.append("songfile", this.state.song_file, this.state.song_file.name);
			}else{
				formData.append("old_song_file", this.state.song_file_old);
			}
					
			formData.append("distribution_title", this.state.distribution_title);
			formData.append("title", this.state.song_title);
			formData.append("lyrics", this.state.lyrics);
			formData.append("release_type", this.state.release);
			formData.append("list_artist", this.state.artist);
			formData.append("version", this.state.song_version);
			formData.append("is_new_release", this.state.song_release);
			formData.append("is_multiartist", this.state.multiartist);
			formData.append("genre_id", this.state.genre);
			formData.append("sub_genre_id", this.state.subgenre);
			formData.append("copyright", this.state.song_copyright);
			formData.append("record_label", this.state.song_label);
			formData.append("ISRC_code", this.state.song_code);		
			formData.append("song_length_minute", this.state.lengthminute);
			formData.append("song_length_second", this.state.lengthsecond);
			formData.append("album_id", this.state.album);
			
			formData.append("userid", ls.get('userid'));
			formData.append("songid", this.state.song_id);
			
			Axios.post(base_api_url+'edit-song.php', formData, {axiosConfig})
			.then(
				(res) => {
					window.scrollTo(0, 0);
					if(res.data.res_status==200){						
						this.setState({
							successclass:'successmsg',
							msg:'Song updated successfully!',
							buttontext : 'Update Item',
							loadinng:false
						});
					}else{						
						this.setState({
							successclass:'errormsg',						
							msg:'Song not updated, please try again!',
							buttontext : 'Update Item',
							loadinng:false
						})
					}
					this.getSongDetails();
					setTimeout(() => {
						this.setState({
							successclass:null,
							msg:null,
							buttontext : 'Update Item'
						});
					}, 10000)
				}
			);
			
			this.setState({
				selectedcover:'Choose File',
				selectedsong:'Choose File'
			});
		}else{
			this.setState({
				loadinng:false
			})
		}		
	}
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	
	render() {
		if(this.state.loadinng === true){
			return <Spinner />;
		}
		var bannerimage = this.state.oldbannerpath;	
		
        return (
			<React.Fragment>				
					<div className={this.state.successclass}>{this.state.msg}</div>
					  <div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  	<h3 className="box-title">Edit Song</h3>
								  	<div className="box-tools">
							  			<NavLink to={'/my-songs/'+this.state.album} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>
							  		</div>
								</div>
								<div className="box-body no-padding">
									<form className="artist-form" onSubmit={this.onSubmit}>
										<div className="row">
											<div className="col-md-12">
											  <div className="sectionhead">
												<div className="headsec">
												  <h3>General Information</h3>
												</div>
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
											  <div className="form-group">
											  	<label htmlFor="album">Album<span className="requiredastrik">*</span></label>                          
												 <select className="custom-select" id="album" value={this.state.album} onChange={this.onChangeAlbum}>
													<option value="">--Select album--</option>
													{this.state.albumlist.map(item => {
														return (<option value={item.id}>{item.name}</option>)
													})}
												</select>
												<font className="errorMsg">{this.state.errors["albumerror"]}</font>
											  </div>
											</div>
											<div className="col-md-6">
											  <div className="form-group">
												<label htmlFor="lastname">Release Type<span className="requiredastrik">*</span></label>
												<select className="custom-select" id="exampleSelectRounded0" reuired="required" onChange={this.onChangeReleaseType} value={this.state.release} >
												  <option value="">--Select release type--</option>
												  {this.state.releaselist.map(item => { return (<option value={item.key}>{item.value}</option>)})}
												</select>
												<font className="errorMsg">{this.state.errors["releaseerror"]}</font>
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6 editeventimage">
											  <div className="form-group">
											   <label htmlFor="coverpic">Upload Cover</label>
													<input type="file" className="form-control" id="coverpic" onChange={this.onChangeUploadCover} accept="image/*" />
													<font className="errorMsg">{this.state.errors["cover_error"]}</font>
													<img src={bannerimage} className="img-responsive" width="50" height="50" onClick={() => this.onclickseturl(bannerimage)} />
											  </div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label htmlFor="songlength">Length<span className="requiredastrik">*</span></label>
													<div className="row">
														<div className="col-md-6">
															<input type="number" value={this.state.lengthminute} id="lengthminute" name="lengthminute" onChange={this.onChangeLengthMinute} onBlur={this.onChangeMinute.bind(this)} min="0" className="form-control" placeholder="minute" />
														</div>
														<div className="col-md-6">
															<input type="number" ref={this.lengthSecondInput} value={this.state.lengthsecond} onChange={this.onChangeLengthSecond} onBlur={this.onChangeSecond.bind(this)} id="lengthsecond" name="lengthsecond" min="0" max="60" className="form-control" placeholder="second" />
														</div>
													</div>
													<font className="errorMsg">{this.state.errors["lengtherror"]}</font>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
											  <div className="sectionhead">
												<div className="headsec">
												  <h3>Song Information</h3>
												</div>
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
											  <div className="form-group">
												 <label htmlFor="title">Title<span className="requiredastrik">*</span></label>                          
												 <input type="text" id="title" className="form-control" required="required" value={this.state.song_title} onChange={this.onChangeSongTitle} />
												 <font className="errorMsg">{this.state.errors["title"]}</font>
											  </div>
											</div>
											<div className="col-md-6">
											  <div className="form-group">
												<label htmlFor="version">Version<span className="requiredastrik">*</span></label>                          
												<input type="text" id="version" className="form-control" value={this.state.song_version} onChange={this.onChangeSongVersion} />
												<font className="errorMsg">{this.state.errors["version"]}</font>
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
											  <div className="form-group">
												<label htmlFor="is_new_release">Is This A New Release?</label><br />
												<div className="custom-control custom-radio custom-control-inline">
												  <input type="radio" id="newrelease" name="customRadioInline1" checked={this.state.song_release == "1"} value="1" onChange={this.onChangeNewRelease} className="custom-control-input" />
												  <label className="custom-control-label" htmlFor="newrelease">Yes</label>
												</div>
												<div className="custom-control custom-radio custom-control-inline">
												  <input type="radio" id="newrelease2" value="0" checked={this.state.song_release == "0"} onChange={this.onChangeNewRelease} name="customRadioInline1" className="custom-control-input" />
												  <label className="custom-control-label" htmlFor="newrelease2">No</label>
												</div>
											  </div>
											</div>
											<div className="col-md-6">
											  <div className="form-group">
												<label htmlFor="is_multiartist">Is This Multi-Artist Complilation?</label><br />
												<div className="custom-control custom-radio custom-control-inline">
												  <input type="radio" id="multiartist1" value="1" checked={this.state.multiartist == "1"} name="multiartist" className="custom-control-input" onChange={this.onChangeMultiArtist} />
												  <label className="custom-control-label" htmlFor="multiartist1">Yes</label>
												</div>
												<div className="custom-control custom-radio custom-control-inline">
												  <input type="radio" id="multiartist2" value="0" checked={this.state.multiartist == "0"} name="multiartist" className="custom-control-input" onChange={this.onChangeMultiArtist} />
												  <label className="custom-control-label" htmlFor="multiartist2">No</label>
												</div>
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
											  <div className="form-group">
												 <label htmlFor="listartist">List Artist (Seperate by Comma)</label>
												 <input type="text" className="form-control" value={this.state.artist} onChange={this.onChangeSongArtist} />
											  </div>
											</div>                    
										</div>
										<div className="row">
											<div className="col-md-6">
											  <div className="form-group">
												<label htmlFor="gener">Genre</label>
												<select className="custom-select" id="genre" value={this.state.genre} onChange={this.onChangeSongGenre}>
													 <option value="">--Select genre--</option>
													{this.state.genrelist.map(item => {
														if(item.parent_id == '0'){
															return(<option value={item.id}>{item.name}</option>)
														}
													})}
													
												</select>
											  </div>
											</div>
											<div className="col-md-6">
											  <div className="form-group">
												<label htmlFor="subgenre">Subgenre</label>
												<select className="custom-select" id="subgenre" value={this.state.subgenre} onChange={this.onChangeSongSubGenre}>
													<option value="">--Select subgenre--</option>
													{this.state.subgenrelist.map(item => {
														return (<option value={item.id}>{item.name}</option>)
													})}
												</select>
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
											  <div className="form-group">
												 <label htmlFor="copyright">Copyright</label>                          
												 <input type="text" value={this.state.song_copyright} id="copyright" onChange={this.onChangeSongCopyright} className="form-control" />
											  </div>
											</div>
											<div className="col-md-6">
											  <div className="form-group">
												 <label htmlFor="recordlabel">Record Label</label>                          
												 <input type="text" className="form-control" id="recordlabel" value={this.state.song_label} onChange={this.onChangeSongRecordLabel} />
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
											  <div className="form-group">
												 <label htmlFor="isrc">ISRC CODE (A fee will apply if you don't have one and CONQER provides it)<span className="requiredastrik">*</span> </label>                          
												 <input type="text" className="form-control" id="isrc" value={this.state.song_code} onChange={this.onChangeSongCode} />
												 <font className="errorMsg">{this.state.errors["isrcerror"]}</font>
											  </div>
											</div>                    
										</div>

										<div className="row">
											<div className="col-md-12 mb-3 ">
											  <div className="form-group">
												<label htmlFor="songfile">Upload Song</label>                          
												<input type="file" name="songfile" id="songfile" className="form-control" onChange={this.onChangeUploadSong} accept=".mp3,audio/*"/>
												<font className="errorMsg">{this.state.errors["file_error"]}</font>
												<a href={this.state.oldsongpath} download>Download : {this.state.song_title}</a>
											  </div>
											</div>
										</div>

										<div className="row">
											<div className="col-md-12">
											  <div className="form-group">
												<label htmlFor="lyrics">Lyrics</label>
												<textarea className="form-control rounded-0" id="lyrics" onChange={this.onChangeLyrics} rows="3" value={this.state.lyrics}></textarea>
											  </div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 notmainbtn">
												<button type="submit" className="btn btn-primary">{this.state.buttontext}</button>
											</div>
										</div>
									</form>
								</div>								
							</div>
						</div>        
					</div>      
					<div id="myModal" className="modal fade imgmodal" role="dialog">
						<div className="modal-dialog">
							<div className="modal-content">
								<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
								<div className="modal-body">
									<img className="showimage img-responsive" src={this.state.urlvalinitial} />
								</div>            
							</div>
						</div>
					</div>
			</React.Fragment>
		)
	}
}