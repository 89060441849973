import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import ISRCCode from '../components/isrccode.component';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import defaultimg from '../components/default.png';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';

const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};

export default class artistIRSCCode extends Component {
  constructor(props) {
    super(props);
	this.onCheckISRC = this.onCheckISRC.bind(this);
	this.refreshCompnent = this.refreshCompnent.bind(this);
    this.state = {
      email: '',
      role: null,
      userid: null,
      token: '',
      error: null,
      msg: null,
      isrclist: [],
      items: [],
      loading: true,
      loader: true,
      successclass: null,
      redirect: false,
      usedFilter: 'All',
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - ISRC Code';
    this.getallisrclist();
	
	if(this.props.refreshComp === '1'){
		this.refreshCompnent();
	}
  }

  getallisrclist = () => {
    const obj = {
      userid: ls.get('userid'),
	  usedFilter:this.state.usedFilter
    };
	
    Axios.post(base_api_url + 'myisrccode.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
			let pathnames ='';
			if(this.props.location === undefined){
				pathnames = '';
			}else{
				pathnames = this.props.location.pathname;
			}
			
			$('#myisrc').DataTable().destroy();
			$(document).ready(function () {
				if(pathnames){
					if(pathnames === '/myisrccode'){
						setTimeout(function(){
							$('#myisrc').DataTable({
								"destroy": true,
								"stateSave": true,
								"language": {
									"search": "Search"
								},
								"lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100,  "All"]],
							});
						 } ,50);
					}
				}else{
					setTimeout(function(){
						$('#myisrc').DataTable({
							"destroy": true,
							"stateSave": true,
							"language": {
								"search": "Search"
							},
							"lengthMenu": [[5, 15, 25, 35, -1], [5, 15, 25, 35,  "All"]],
						});
					 } ,50);
				}
			});
			const returndata = res.data.res_data;
			this.setState({
			  isrclist: returndata,
			  loading: false
			});
		  } else {
			this.setState({
			  loading: false
			});
		  }
    });
  };
  changeUsedStatus = (e) => {
	this.setState({ usedFilter: e.target.value }, () => {
	  this.getallisrclist();
	});
  };
  
  refreshCompnent = () => {
	this.setState({ usedFilter: 'All' }, () => {
	  this.getallisrclist();
	});
  };
	onCheckISRC(){
		$('#myModal').modal('show');
	}
  render() {
    return (
      <React.Fragment>
        {this.state.msg ? <div className={this.state.successclass}>{this.state.msg}</div> : ''}
		<section>
			{(this.state.loading == true)? <Spinner />:
			<div className="row">
			  <div className="col-md-12">
				<div className="box box-success distributions-profileform">
				  <div className="box-header with-border addprofilenew">
					<h3 className="box-title">ISRC Code</h3>
					<div className="box-tools d-flex justify-content-between">
					{(!this.props.showpurchaselink)?<a href="javascript:void(0);" className="btn btn-primary aadersongs" onClick={this.onCheckISRC}>
							<i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Purchase New Code
					</a>:''}&nbsp;
						<select
						className="filteroptionselect"
						as="select"
						value={this.state.usedFilter}
						onChange={(e) => this.changeUsedStatus(e)}
						style={{ width: '10vw' }}
						>
							<option value="All">All</option>
							<option value="Yes">Used</option>
							<option value="No">Not Used</option>
						</select>
					</div>
				  </div>
				  <div className="box-body no-padding">
					  <div className="table-responsive">
						<table id="myisrc" className="table table-striped table-bordered table-hover table-sm row-border hover">
						  <thead>
							<tr>
							  <th>Title</th>
							  <th className="text-center">ISRC Code</th>
							  <th>Used</th>
							</tr>
						  </thead>
						  <tbody>
							{this.state.isrclist.map((result, key) => {
							  return (
								<tr>
								  <td>{result.title}</td>
								  <td className="text-center">{result.isrc_code}</td>
								  <td>{result.used_isrc}</td>
								</tr>
							  );
							})}
						  </tbody>
						</table>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		}
		</section>
		<div id="myModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
			<ISRCCode currentalid='1' codelist="1" />
		</div>
      </React.Fragment>
    );
  }
}

const SomeComponent = withRouter(props => <artistIRSCCode {...props}/>);