import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL, STRIPE_KEY } from '../../../config/constants.js';
import { Redirect, NavLink, Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import { playAudio } from '../../../includes/playAudio.component';
import Spinner from '../../../includes/Spinner.component';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import {toast} from 'react-toastify';
import $ from 'jquery'; 
const base_api_url = API_URL;
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const stripePromise = loadStripe(STRIPE_KEY);
toast.configure();
const errors = {};
export class Songs extends Component {
	constructor(props) {
      super(props);
	  this.audioRefs = React.createRef();
      this.state = {
			loader:true,
			songData:[],
			status:'-',
			approvestatus:'-',
			featuredStatus:'0',
			toggle: true,
			isPlaying: false,
			currentSong: '',
			errors:[],
		  planlist:[],
		  btnText:'Submit',
		  btnDisable: false ,
		  songId: '',
		  featuredData:[],
		  is_featured: '0',
		  stripe_text:'',
		  countPerPage: 10,
		  page: 0,
		  total:0,
		  searchData: ''
      }
	  this.handleApprove = this.handleApprove.bind(this);
    }
	changeDataStatus = (e) => {
		this.setState({ status: e.target.value }, () => {
			this.getSongListData();
		});
	};
	changeDataAStatus = (e) => {
		this.setState({ approvestatus: e.target.value }, () => {
			this.getSongListData();
		});
	};
	changeDataFeatured = (e) => {
		this.setState({ featuredStatus: e.target.value }, () => {
			this.getSongListData();
		});
	};
	async handleApprove(songid,astatus,ISRC_code) {
		let approveContent = astatus === '1' ? 'unapprove' : 'approve';
		if (window.confirm(`Do you want to ${approveContent} this Song? `)) {
			this.setState({
				loader:true,
				successclass:null,
				msg:null
			});
			const obj = {
				song_id:songid,
				astatus:astatus,
				isrc_code:ISRC_code,
			}			
			const response = await Axios.post(API_URL+'updatesongastatus.php',obj,{axiosConfig});
			if (response.data.res_status === '200') {
				this.setState({
					loader:false,
					successclass:'successmsg',
					msg:(astatus === '1')?'Song unapproved successfully.':'Song approved successfully.',
				});
			}else{
				this.setState({
					loader:false,
					successclass:'errormsg',						
					msg:response.data.res_message
				})
			}
			this.getSongListData();
			setTimeout(() => {					
				this.setState({
				successclass:null,
				msg:null
				});
			}, 10000)
		}
		
	}
    getSongListData(){
    	let obj = {
		  status: (this.state.status === '-')?'':this.state.status,
		  approvestatus: (this.state.approvestatus === '-')?'':this.state.approvestatus,
		  featured: this.state.featuredStatus,
		  search_text: this.state.searchData
		};
		/*this.setState({
			loader: true
		});*/
	    Axios.post(API_URL + `songs-list-v1.php?page=${this.state.page}&per_page=${this.state.countPerPage}&delay=1`, obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){
		        	this.setState({
		        		songData: res.data.res_data,
		        		total: res.data.total,
						loader: false
					});				         
		        } 
		                
	    	}
	    )
    }
    setNoPage = (e) =>{
    	let p = (e*10)-10;
    	this.setState({
    		page: p//(p)?p:1
    	},() => this.getSongListData());
    }
    componentDidMount(){
		document.title = "Songs List";
    	this.getSongListData();
    	this.getplandetails('song');
    }
	
    changeStatus(id,status){
		let statusContent = status === '1' ? 'inactivate' : 'activate';
		if (window.confirm(`Do you want to ${statusContent} this Song? `)) {
			this.setState({
				loader:true
			});
			const obj = {
				id:id,
				status:status
			}			
			Axios.post(API_URL+'updatestatussong.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.getSongListData();
						this.setState({
							loader:false,
							successclass:'successmsg',
							msg:(status === '1')?'Song inactivated successfully.':'Song activated successfully.',
						});
					}else{
						this.setState({
							loader:false,
							successclass:'errormsg',						
							msg:'Song status not updated, please try again!'
						})
					}
				}
			);
			setTimeout(() => {					
				this.setState({
				successclass:null,
				msg:null
				});
			}, 10000)
		}
		
    }
    markFeatured = (id, is_featured) => {
    	this.setState({
			featuredData: []
		});
    	this.getUserPlanDetail(id);
		this.setState({
			songId: id,
			is_featured: is_featured
		}, () => $('#upGradModal').modal('show'));	
		
	}
	getUserPlanDetail = (id) => {		
		const obj = {
			songId: id,
			type: 'song'
		}				
		Axios.post(base_api_url+'user-featuerd-plan.php',obj,{axiosConfig})
		.then(
			(res) => {				
				if(res.data.res_status==200){
					this.setState({
						featuredData:res.data.res_data
					});
				}else{
					this.setState({
						featuredData: []
					});
				}
			}
		);
	}
	getplandetails = (utype) => {   
	    const obj = {
	      type: utype
	    }
	    Axios.post(base_api_url + 'get-featured-plan.php', obj).then(
	        (res) => {
	          if (res.data.res_status == 200) {
	            this.setState({
	              planlist: res.data.res_data,
	              stripe_text: res.data.stripe_detail
	            });
	          } else {
	            this.setState({
	              planlist: [],
	              stripe_text: ''
	            });
	          }
	        }
	    );
	}
	async onSubmit(e) {
		e.preventDefault();
		if (this.handleValidationUpgrad()) {
			this.setState({
				btnText:'Please Wait..',
				btnDisable:true
			});
			await this.onToken();      
		}
	}
	handleValidationUpgrad = () => {
		let formIsValid = true;
		if (!this.state.sub_plan) {
		  formIsValid = false;
		  errors['plan_error'] = 'Please select plan.';
		}
		if(!this.state.formValidation){
		  formIsValid = false;
		}
		if(!this.state.fillStatus){
		  this.setState({
		    cardError:'Please enter card detail.'
		  });
		  formIsValid = false;
		}
		this.setState({ errors: errors });
		return formIsValid;
	};
	stripeElementChange = (element, name) => {
	    this.setState({
	      fillStatus: true,
	      cardError:'',
	      formValidation: true
	    });
	    if(element.error){
	      if(element.error.code){
	        this.setState({
	          formValidation: false,
	          cardError: element.error.message
	        });
	      }
	    }else if(!element.complete){
	    	this.setState({
		      fillStatus: false
		    });
	    }
	  }
	async onToken() {
    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if(result.error){
      this.setState({
        btnText:'Submit',
        btnDisable:false
      });
    }else{
      let formData = {
        "token":result.token.id,
        "planId": this.state.sub_plan,
        "songId": this.state.songId
      };
      Axios.post(base_api_url + 'upgrade-featured-songs.php', formData, { axiosConfig }).then((res) => {
      	if(res.data.res_status == '200'){
      		const { elements, stripe } = this.props;
		    const cardElement = elements.getElement(CardElement);
		    if(cardElement){
		      cardElement.clear();
		    }
		    this.setState({
		    	sub_plan:'',
		    	is_featured:'1'
		    });
		    toast.success(res.data.res_message);
		    this.getSongListData();
		    this.getUserPlanDetail(this.state.songId);
      	}else{
      		toast.error(res.data.res_message);
      	}
      	this.setState({
	        btnText:'Submit',
	        btnDisable:false
	    });
      });
      
    }
  }
  onChangePlan(e){
		if (this.state.errors['plan_error']) { delete this.state.errors['plan_error']; }
		this.setState({
			sub_plan: e.target.value
		})
	}
	playHandler = (song) => {
		if (this.state.currentSong.id === song.id) {
		  this.setState(
			{
			  isPlaying: !this.state.isPlaying,
			  currentSong: song,
			},
			() => playAudio(this.state.isPlaying, this.audioRefs)
		  );
		} else {
		  this.setState(
			{
			  isPlaying: true,
			  currentSong: song,
			},
			() => playAudio(this.state.isPlaying, this.audioRefs)
		  );
		}
	};
	cancelAllPlan = () => {
    if (window.confirm('Are you sure you want to cancel all plans?')) {
      const obj = {
        id: this.state.songId,
        type: 'song'
      }       
      Axios.post(base_api_url+'cancel-plan.php',obj,{axiosConfig})
      .then(
        (res) => {        
          if(res.data.res_status==200){
            toast.success(res.data.res_message);
            this.getSongListData();
		    this.getUserPlanDetail(this.state.songId);
          }else{
            toast.error(res.data.res_message);
          }
        }
      );
    }
  }
  filterData = (e) => {
  	this.setState({
  		searchData: e.target.value
  	}, () => this.getSongListData());
  }
    render() {
    	const columns = [			  
			  {
			    name: 'Album Name',
			    selector: 'album_name',
			    sortable: true,
			  },
			  {
			    name: 'Title',
			    selector: 'title',
			    sortable: true,
			  },
			  {
			    name: 'Artist',
			    selector: 'artist',
			    sortable: true,
			  },
			  {
			  	name: 'Approval',
			  	cell: row => <div className="approvestatus" onClick={() => this.handleApprove(row.id, row.song_astatus, row.ISRC_code)} style={{"cursor":"pointer"}}>
						{row.song_astatus == '0' ? <span className="label label-danger">Not Approved</span>:<span className="label label-success">Approved</span>  }
					</div>
			  },
			  {
			  	name: 'Status',
			  	cell: row => <span className={(row.song_status == 1)?"label label-success":"label label-danger"}>{(row.song_status == 1)?"Active":"In Active"}</span>
			  },
			  {
			  	name: 'Actions',
			  	cell: row => <><Link to="#" onClick={() => this.changeStatus(row.id, row.song_status)} title={row.song_status === '1' ? 'In Active' : 'Active'} className="btn btn-info new-custom" >
			  	{row.song_status === '1' ? <i className="fa fa-ban" aria-hidden="true"></i> : <i className="fa fa-check" aria-hidden="true"></i>} </Link> &nbsp; <Link to="#" onClick={() => this.markFeatured(row.id, row.featured)}
					title={row.featured === '1' ? 'Unfeature song' : 'Feature song'} className="btn btn-info new-custom">
						{row.featured === '1' ? (
					  <i className="fa fa-star" aria-hidden="true"></i>
					) : (
					  <i className="fa fa-star-o" aria-hidden="true"></i>
					)}
				  </Link>
				  &nbsp;
					<Link to="#" onClick={() => this.playHandler(row)} className="btn btn-info new-custom">
						<i className={(this.state.currentSong.id === row.id && this.state.isPlaying)?"fa fa-pause":"fa fa-play"} title={(this.state.currentSong.id === row.id && this.state.isPlaying)?"Pause Song":"Play Song"}>																
						</i>
					</Link></>
			  }
			];
			let dataD = this.state.songData;
			const tableData = {
			    columns,
			    dataD
			  };
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
					<div className={this.state.successclass}>{this.state.msg}</div>
					{(this.state.loader === true)?<Spinner />:
						<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title">Songs List</h3>   
								  <div className="box-tools d-flex justify-content-between">
								  	  <input type="text" className="filteroptionselect" onChange={this.filterData.bind(this)} placeholder="Search"/> &nbsp;
								  	  <select
										className="filteroptionselect"
										as="select"
										value={this.state.featuredStatus}
										onChange={(e) => this.changeDataFeatured(e)}
										style={{ width: '10vw' }}
									  >
									  	<option value="0">All Songs</option>
										<option value="1">Feature Songs</option>
									  </select> &nbsp;
									  <select
										className="filteroptionselect"
										as="select"
										value={this.state.approvestatus}
										onChange={(e) => this.changeDataAStatus(e)}
										style={{ width: '10vw' }}
									  >
										<option value="-">Approved Status</option>
										<option value="">All</option>
										<option value="0">Not Approved</option>
										<option value="1">Approved</option>
									  </select> &nbsp;
									  <select
										className="filteroptionselect"
										as="select"
										value={this.state.status}
										onChange={(e) => this.changeDataStatus(e)}
										style={{ width: '10vw' }}
									  >
										<option value="-">Status</option>
										<option value="">All</option>
										<option value="0">Inactive</option>
										<option value="1">Active</option>
									  </select>
									</div>
									<div className="box-tools saearchicons">
									  <div className="input-group input-group-sm hidden-xs"></div>
									</div>
								</div>
								<div className="box-body no-padding">
									<div className="table-responsive">
										<DataTable
									        columns={columns}
									        data={this.state.songData}
									        highlightOnHover
									        pagination
									        paginationServer
									        paginationTotalRows={this.state.total}
									        paginationPerPage={this.state.countPerPage}
									        paginationComponentOptions={{
									          noRowsPerPage: true
									        }}
									        onChangePage={(e) => this.setNoPage(e)}
									    />
									</div>              
							</div>
							</div>
						</div> 
						<audio ref={this.audioRefs} src={this.state.currentSong.songfile}></audio>       
					</div>
					}
    			</section>
    		</div>
    		</div>
    		</div>
    		<div id="upGradModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header" style={{'padding':'5px 20px'}}>
						<h5 class="modal-title">Featured Song</h5>
						{(this.state.featuredData.length)?
						<Link to="#" className="btn btn-primary aadersongs cancel-plan" onClick = {this.cancelAllPlan.bind(this)}><i className="fa fa-times" aria-hidden="true"></i>&nbsp;Cancel all plan</Link>:""}
						<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
					</div>
					<form className="artist-form" onSubmit={this.onSubmit.bind(this)}>
						<div className="container">
							<div class="modal-body">
							{/*(this.state.is_featured == '1')?*/}
							<>
								<div className="row">
									<div className="col-md-12">
										<h5 class="modal-title" style={{'fontSize':'17px'}}>Purchased Plan</h5>
										<div className="table-responsive no-padding">
										<table className="table table-striped table-bordered table-sm row-border hover tbl-padding-tr">
											<thead>
											<tr>
												<th>Plan</th>
												<th>Start Date & Time</th>
												<th>End Date & Time</th>
												<th>Status</th>    											
											</tr>
											</thead>
											<tbody>
											{(this.state.featuredData.length)?this.state.featuredData.map((result) => {
												return (<tr>
													<td>{result.paln}</td>
													<td>{result.start_from}</td>
													<td>{result.end_to}</td>
													<td>{result.status}</td>
												</tr>)
											}):<tr><td colspan="4" style={{'textAlign':'center'}}>No plan available!</td></tr>}
											</tbody>										
										</table>
										</div>
									</div>
								</div><hr style={{'width':'50%'}}/></>{/*:""*/}									
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label>Plan<span className="requiredastrik">*</span></label>
											<select className="form-control" value={this.state.sub_plan} onChange={this.onChangePlan.bind(this)}>
												<option value="">--Select Plan--</option>
												{this.state.planlist.map((result) => {
												return (<option value={result.id}>{result.plan} [ ${result.amount} ]</option>)
												})}
											</select>
											<font className="errorMsg">{this.state.errors['plan_error']}</font>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label>Credit Card<span className="requiredastrik">*</span></label>
											<div className="creditcardsection">
												<CardElement options={{ hidePostalCode: true }}  onChange={(element) => this.stripeElementChange(element, 'card_number')} />
											</div>
											<font className="errorMsg">{this.state.cardError}</font>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<font style={{'position':'absolute','left':'15px','fontSize':'11px'}}>Note:- {this.state.stripe_text}</font>
							<button type="submit" class="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
						</div>
					</form>							
				</div>
			</div>
		</div>

    		</React.Fragment>
    	)
    }
}
export default function InjectedEventBooking({ match }) {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <Songs match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}