import React, { Component } from 'react';
import Axios from 'axios';
import  { Redirect, Link  } from 'react-router-dom';
import { API_URL, STRIPE_KEY } from '../config/constants.js';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ChangeCheckoutForm from "./changecheckoutform.component";
const stripePromise = loadStripe(STRIPE_KEY);

const base_api_url = API_URL;

export default class ChangeCard extends Component {
	
	constructor(props) {
		super(props);
		
	}
	
    render() {
        return (		
			<Elements stripe={stripePromise}>
				<ChangeCheckoutForm />
			</Elements>	
		)
	}
}