import React, { Component } from 'react';
import  { NavLink  } from 'react-router-dom';
export default class DMDAgency extends Component {
    componentDidMount(){
		document.title = "CONQER Music - DMD Agency";
	}
	render() {
        return (
			<React.Fragment>
				<section className="152-dmdagency">
				  <div className="container" >
					<div className="row">
					  <div className="col-md-12">
						<div className="dmdagency">
						  <div className="headingandtxt">
							<h2><a href="#">Conquer Everything Coming Soon</a></h2>
							<div className="dtetime">09:14 AM, 22 Oct 2019</div>
						  </div>
						  <div className="conqerevrtimg">
							<img src={process.env.PUBLIC_URL + 'assets/news/867519228d1d5325856fc61d710ded0e_XL.jpg'} alt="Conquer Everything Coming Soon" />
						  </div>
						  <p className="managelinksd"><a href="https://open.spotify.com/artist/1xQ6GA0A4RnrE6ZrIRyqi4?si=_hoI75tfS5GDdetmWCzF0Q" target="_blank" rel="noopener noreferrer">Spotify</a> - <a href="https://www.youtube.com/channel/UCvuJKYqvTFPU_jxNGU8iHQQ" target="_blank" rel="noopener noreferrer">YouTube</a> - <a href="https://www.facebook.com/WhoIsMichaelAndersonJr" target="_blank" rel="noopener noreferrer">Facebook Whois Michael</a> - <a href="https://www.instagram.com/michaelandersonjr" target="_blank" rel="noopener noreferrer">Instagram Michael</a> - <a href="https://www.instagram.com/RevivalDaAlbum" target="_blank" rel="noopener noreferrer">Instragram Revival Album</a> - <a href="https://twitter.com/prolificmikejr" target="_blank" rel="noopener noreferrer">Twitter</a></p>
						  <p>&nbsp;</p>
						  <p>&nbsp;</p>
						  <p>&nbsp;</p>         
						  <h3>Tagged under</h3>
						  <p><NavLink to={'/32-conquer-everything-coming-soon'}>Read more...</NavLink></p>
						</div>
					  </div>
					</div>
					
				  </div>
				</section>
			</React.Fragment>
		)
	}
}