import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import ls from 'local-storage';

const base_api_url = API_URL;
const errors = {};
export default class EditPassword extends Component {
	
	constructor(props){
		super(props);
		this.onChangeNewPassword 		= this.onChangeNewPassword.bind(this);		
		this.onChangeConfirmPassword 	= this.onChangeConfirmPassword.bind(this);
		this.onChangeOldPassword 		= this.onChangeOldPassword.bind(this);
		this.onSubmit 					= this.onSubmit.bind(this);
				
		this.state = {
			newpassword:'',
			confirmpassword:'',
			oldpassword:'',
			error: null,
			errors: [],
			msg: null,
			passmsg: null,
			samemsg: null,
			successclass:null,
			redirect:false,		
			wrongpassmsg:'',		
			wrongcpassmsg:'',		
		}		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Change password";				
	}
	
	handleValidation = () => {
		let formIsValid = true;
		if(!this.state.newpassword){
			formIsValid = false;
			errors["newpassword_error"] = "Please enter new password.";
		}else{
			let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
			let checkpass = strongRegex.test(this.state.newpassword);
			if(!checkpass){
				formIsValid = false;
				errors["newpassword_error"] = "Password should have minimum 8 character, 1 symbol, 1 uppercase & 1 lowercase.";
			}
		}

		if(!this.state.confirmpassword){
			formIsValid = false;
			errors["conpassword_error"] = "Please confirm your password.";
		}else{
			if(this.state.newpassword !== this.state.confirmpassword){
				formIsValid = false;
				errors["conpassword_error"] = "Confirm password must be same as new password.";
			}
		}
		if(!this.state.oldpassword){
			formIsValid = false;
			errors["oldpassword_error"] = "Please enter your old password.";
		}
		this.setState({errors: errors});
		return formIsValid;

	}


	onChangeNewPassword(e) {
		if(this.state.errors['newpassword_error']){delete this.state.errors['newpassword_error'];}		
		this.setState({
		  newpassword: e.target.value
		});
	}
	
	onChangeConfirmPassword(e){
		if(this.state.errors['conpassword_error']){delete this.state.errors['conpassword_error'];}
		this.setState({
		  confirmpassword: e.target.value
		});
	}
	
	onChangeOldPassword(e){
		if(this.state.errors['oldpassword_error']){delete this.state.errors['oldpassword_error'];}
		this.setState({
		  oldpassword: e.target.value
		});		
	}
	
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			const formData = new FormData();
			const usertoken = this.props.match.params.id;
			const axiosConfig = {
				'Content-Type': 'multipart/form-data',
				"Access-Control-Allow-Origin": "*",
			};
			formData.append("newpassword", this.state.newpassword);
			formData.append("confirmpassword", this.state.confirmpassword);
			formData.append("oldpassword", this.state.oldpassword);
			formData.append("userid", ls.get('userid'));
			formData.append("user_token", usertoken);
			formData.append("type", 'web');
			Axios.post(base_api_url+'change-password.php', formData, {axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:'Password updated successfully. Please re-login to continue.',
							newpassword:'',
							confirmpassword:'',
							oldpassword:''
						});	
						setTimeout(() => {
						this.setState({
							successclass:null,
							msg:null
						});
						this.props.history.push("/lslogout");
						}, 
					5000)		
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message
						})
						setTimeout(() => {
						this.setState({
							successclass:null,
							msg:null
						});
						}, 
					5000)
					}
					
				}
			);
		}	
	}
	
	render() {
		const userrole = ls.get('role')
        return (
			<React.Fragment>
				<div className={this.state.successclass}>{this.state.msg}</div>
					  <div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title">Change Password</h3>
								</div>
								<div className="box-body no-padding">
								  <form className="artist-form" onSubmit={this.onSubmit} autoComplete="new-password">
								  <div className="row">
									<div className="col-md-12">
									  <div className="sectionhead">
										<div className="headsec">
										  <h3>Password Information</h3>
										</div>
									  </div>
									</div>
								  </div>
								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label htmlFor="oldpassword">Current Password<span className="requiredastrik">*</span></label>
										<input type="password" reuired="required" id="oldpassword" value={this.state.oldpassword} onChange={this.onChangeOldPassword} className="form-control" placeholder="Enter Current Password"/>
										<span className="errorMsg">{this.state.passmsg}</span>
										<font className="errorMsg">{this.state.errors["oldpassword_error"]}</font>
									  </div>
									</div>									
								  </div>
								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label htmlFor="newpassword">New Password<span className="requiredastrik">*</span></label>
										<input type="password" reuired="required" id="newpassword" value={this.state.newpassword} onChange={this.onChangeNewPassword} className="form-control" placeholder="Enter New Password"/>
										<font className="errorMsg">{this.state.errors["newpassword_error"]}</font>
									  </div>
									</div>									
								  </div>
								  
								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label htmlFor="confirmpassword">Confirm Password<span className="requiredastrik">*</span></label>
										<input type="password" reuired="required" value={this.state.confirmpassword} id="confirmpassword" onChange={this.onChangeConfirmPassword} className="form-control" placeholder="Enter Confirm Password"/>
										<font className="errorMsg">{this.state.errors["conpassword_error"]}</font>
									  </div>
									</div>									
								  </div>
								  
								  
								  
								  <div className="row">
									<div className="col-md-12 notmainbtn">
										<button type="submit" className="btn btn-primary">Change Password</button>
									</div>
								  </div>
								  </form>
								</div>								
							</div>
						</div>        
					  </div>      
					
			</React.Fragment>
		)
	}
}