import React, { Component } from 'react';
export default class Download extends Component {
	componentDidMount(){
		document.title = "CONQER Music - Download App";
	}
    render() {
        return (
			<React.Fragment>
				<section className="download-app">
					<div className="download-app-section">
						<h2>Download App</h2>
						<p>Download Conqer Music Mobile Application from your Platform's App Store!</p>
						<div className="container">
						  <div className="row">
							<div className="col-md-6 text-right">
								<a target="_blank" href="https://apps.apple.com/us/app/conqer-music/id1491808005">
									<img className="img-responsive" src={process.env.PUBLIC_URL + 'assets/merchandise/app-store-download.png'} alt="App store" />
								</a>
							</div>
							<div className="col-md-6 text-left">
								<a target="_blank" href="https://play.google.com/store/apps/details?id=com.dmdagency.conqer">
								<img className="img-responsive" src={process.env.PUBLIC_URL + 'assets/merchandise/google_play_logo1.png'} alt="Google Play" />
								</a>
							</div>
						 </div>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}