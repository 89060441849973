import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';

const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
let _data = [];
export default class topArtists extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
		  email:'',
		  role:null,
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  topartists:[],
		  topartist:[],
		  loading:true,
		  artist_name:'',
		  successclass:null,
		  redirect:false,
		  type:'all'
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - My Music";
		
		const email = ls.get('email');
		const name = ls.get('name');
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({
			email:email,
			name:name,
			token:token,
			userid:userid
		});
		
		this.getTopArtists();
		
	}
	getTopArtists = () => {
		this.setState({'type':'all'});
		const obj = {
			limit:'all'
		}
		Axios.post(base_api_url+'gettopartists.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.user_data
					this.setState({
						topartists:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	artitName(e){
		if(e.target.value){
			this.setState({artist_name:e.target.value});
		}else{
			this.setState({artist_name:''});
			this.getTopArtists();
		}
		
	}
	searchArtist = (e) => {
		this.setState({'type':'search'});
		let _data = [];
		let n = this.state.artist_name;
		if(n){
			this.state.topartists.map((results) => {
				if((results.first_name+' '+results.last_name).match(new RegExp(n,'ig'))){
					_data.push(results);				
				}
			});
			this.setState({
				topartist:_data,
				loading:false
			});
		}else{
			this.setState({'type':'all'});
		}
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	
	render() {
		let artists = (this.state.type == 'all')?this.state.topartists:this.state.topartist;
		
        if(this.state.loading==true){
			return <Spinner />;
		}
		let maxlimit = '20';
		return (
			<React.Fragment>	
				<div className={this.state.successclass}>{this.state.msg}</div>
				<React.Fragment>
				<div className="row topfivesongsd">
					<div className="col-md-12">
						<div className="todpfive">Top Artist 
							<span className="rightsidetext">
								<span class="navbar-search col"style={{'float':'left'}}>
						            <label class="sr-only screen-reader-text visually-hidden" for="search">Search for:</label>
						            <div class="input-group">
						            	<div class="input-search-field">
						            		<input type="text" id="search" class="form-control search-field product-search-field" dir="ltr" onChange={this.artitName.bind(this)} placeholder="Search Artist" autocomplete="off" />
						            	</div>
						            	<div class="input-group-btn">
								            <button type="submit" onClick={() => this.searchArtist()} class="btn btn-secondary"><i class="fa fa-search"></i></button>
						            	</div>
						            </div>
						        </span>						        
								<Link to="/my-music/" className="btn btn-primary mb-3">
									<i className="fa fa-angle-double-left" aria-hidden="true"></i>Back
								</Link>
							</span>
						</div>
					</div>
				</div>
				<div className="row artisitblock allartist">					
					{(artists.length)?artists.map((result) => {
						let fullname = result.first_name + ' ' + result.last_name;
						let artistsingleurl =  '/listener-assong/'+ result.usr_id;
						return (
						<div className="col-md-3">
							<Link to={artistsingleurl}>
							<div className="featureartprifles">
							   <div className="featrueimages">
								  <img onError={this.addDefaultSrc} src={result.thumb_profile_pic} />
							   </div>
							   <div className="featurestext">
								  <h3>{fullname}</h3>
								  <span className="nooffollowers"><i className="fa fa-users"></i> {result.myfolowers}</span>
							   </div>
							</div>
							</Link>
						</div>
						)
					}):<div className="not-found"><span>No artist found!</span></div>
					}
				</div>
				</React.Fragment>
			</React.Fragment>
		)
	}
}