import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import BulkISRC from './bulkisrc.component';
import Axios from 'axios';

//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import 'jquery/dist/jquery.min.js';
import Spinner from '../../../includes/Spinner.component';
import $ from 'jquery'; 
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const errors = {};
export default class ISRC extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	isrclist:[],
      	errors:[],
		btndisable:'',
		code: '',
		isrcid:'',
		oldcode:'',
		oldtitle:'',
      	btnSubmitText: 'Submit',
      	disabled:false
      }
    }
    isrcListData(){
		const obj= {isrccodeid:'0'}
	    Axios.post(API_URL + 'masterisrclist.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		isrclist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						isrclist: [],
						loader: false
					});
		        }
		      	$(document).ready(function () {
		    		$('#isrclist').DataTable().destroy();
			        setTimeout(function(){
			        $('#isrclist').DataTable({
						"destroy": true,
						"aaSorting": [],
						"stateSave": true,
						"lengthMenu": [[10, 15, 20 -1], [10, 15, 20,  "All"]],
						"language": {
							"search": "Search"
						},
						columnDefs: [
							{ type: "num-fmt", "targets": 1, className: "text-center", appliesTo: "print" }
						],
						dom: 'Bfrtip',
						buttons: [
							{
								extend: 'csv',
								exportOptions: {
									columns: [ 0,1,2]
								}
							},
							{
								extend: 'print',
								exportOptions: {
									columns: [ 0,1,2 ]
								},
								customize: function ( win ) {
									$(win.document.body).find('table').addClass('display').css('font-size', '15px');
									$(win.document.body).find('table').css('table-layout','auto');
									$(win.document.body).find('th').css('width','15%');
									$(win.document.body).find('td').css('width','15%');
								}
							}
						]
			        });
			        } ,50);
			    });	        	   
	    	}

	    );
    }
    componentDidMount(){
		document.title = "Master - ISRC Code";
    	this.isrcListData();
    	$(document).ready(function () {
    		$('#isrclist').DataTable().destroy();
	        setTimeout(function(){
	        $('#isrclist').DataTable({
					"destroy": true,
					"aaSorting": [],
					"stateSave": true,
					"lengthMenu": [[ 10, 15, 20 -1], [10, 15, 20,  "All"]],
					"language": {
						"search": "Search"
					},
					columnDefs: [
						{ type: "num-fmt", "targets": 1, className: "text-center", appliesTo: "print" }
					],
					dom: 'Bfrtip',
					buttons: [
						{
							extend: 'csv',
							exportOptions: {
								columns: [ 0,1,2]
							}
						},
						{
							extend: 'print',
							exportOptions: {
								columns: [ 0,1,2 ]
							},
							customize: function ( win ) {
								$(win.document.body).find('table').addClass('display').css('font-size', '15px');
								$(win.document.body).find('table').css('table-layout','auto');
								$(win.document.body).find('th').css('width','15%');
								$(win.document.body).find('td').css('width','15%');
							}
						}
					]
	        });
	        } ,50);
	    }); 
    }
	
    editISRC = (isrc_id) => {
		if(this.state.errors['titleerror']){delete this.state.errors['titleerror'];}
		if(this.state.errors['codeerror']){delete this.state.errors['codeerror'];}
    	this.setState({
			loader:true,
			isrcid:isrc_id,
			codeerror:''
		})
		const obj = {
			isrccodeid: isrc_id
		}
		Axios.post(API_URL + 'masterisrclist.php', obj, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.isrcListData();
					let currentc = res.data.res_data[0];
					this.setState({
						code: currentc.code,
						oldcode: currentc.code,
						btnSubmitText: 'Update',
						loader:false,
						method:'update'
					});

				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Internal Server Error, please try again!',
						loader:false
					})
				}
			}
		);
	}
	deleteISRC(id){
    	if(window.confirm("Are you sure you want to delete the code?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deleteisrc.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message
						})
					}
					this.isrcListData();
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
	
	onBlurCode(e) {
		if(this.state.errors['codeerror']){delete this.state.errors['codeerror'];}
		let codeVal = e.target.value;
		if(codeVal !== this.state.oldcode){
			const obj = {
				code:codeVal
			}			
			Axios.post(API_URL+'mastercode_exist.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						errors["codeerror"] = res.data.res_message;
						this.setState({errors: errors,code: ''});
					}else{
						errors["codeerror"] = res.data.res_message;
						this.setState({errors: errors,code: codeVal});
					}
				}
			);
		}
	}

	onChangeCode(e) {
		if(this.state.errors['codeerror']){delete this.state.errors['codeerror'];}
		let codeVal = e.target.value;
		let newcode = '';
		if(codeVal){
			newcode = codeVal.toUpperCase();
		}
		this.setState({
			code: newcode
		})
	}
	
	onCommissionSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
				loader:true,
				disabled:true,
				btnSubmitText:'Please Wait...'
			});
			if(this.state.method === 'update'){
				this.updateTickets();
			}else{
				this.saveTickets();
			}
		}
	}
	saveTickets(){
		const formData = new FormData();
		formData.append("code", this.state.code);
		Axios.post(API_URL + 'save_isrc.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						code: '',
						btnSubmitText: 'Submit',
						disabled:false,
						loader:false,
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						btnSubmitText:'Submit',
						disabled:false,
						loader:false,
						msg: res.data.res_message								
					})
				}
				this.isrcListData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	updateTickets(){
		const formData = new FormData();
		formData.append("isrc_id", this.state.isrcid);
		formData.append("code", this.state.code);
		Axios.post(API_URL + 'update-isrc.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						code: '',
						btnSubmitText: 'Submit',
						disabled:false,
						method:'',
						loader:false
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						code: '',
						msg: res.data.res_message,
						btnSubmitText:'Update',
						disabled:false,
						loader:false,
						method:'update'
					})
				}
				this.isrcListData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	handleValidation = () => {
		let formIsValid = true;		
		if(!this.state.code){
			formIsValid = false;
			errors["codeerror"] = "Please enter code.";
		}
		
		this.setState({errors: errors});		
        return formIsValid;
	
	}
	changeStatus(id,status){
    	this.setState({
    		loader:true
    	});
    	const obj = {
			id:id,
			status:status
		}			
		Axios.post(API_URL+'updateisrcstatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.isrcListData();
					this.setState({
						successclass:'successmsg',
						msg:res.data.res_message,
					});
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:res.data.res_message
					})
				}
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 10000)
			}
		);
    }
	onCheckISRC(){
		$('#myModal').modal('show');
	}
	closeIsrcform(){
		$('#myModal').modal('hide');
		$('body').removeClass('modal-open');
		$('.modal-backdrop').remove();
		let redurl = '/admin/isrc';
		window.location.href = redurl;
	}
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
    			<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">ISRC Code</h3>
								<div className="box-tools d-flex justify-content-between">
									<a href="javascript:void(0);" className="btn btn-primary" onClick={this.onCheckISRC}> Import ISRC Code</a>
								</div>
							</div>
							<div className="box-body no-padding"> 
								<div className="nav-tabs-custom">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onCommissionSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>ISRC Code Information</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>Code<span className="requiredastrik">*</span></label>
															<input type="text" placeholder={this.state.code} maxLength="12" minLength="12" className="form-control" name="c_amount" value={this.state.code} onChange={this.onChangeCode.bind(this)} onBlur={this.onBlurCode.bind(this)} />
															<font className="errorMsg">{this.state.errors["codeerror"]}</font>
														</div>
													</div>
													<div className="col-md-6 mt-4">
														<button type="submit" style={{'height':'34px'}} disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>           
							</div>
							<hr />
							<div className="box-body no-padding">
								<div className="table-responsive">
									<table id="isrclist" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Title</th>
												<th className="text-center">ISRC Code</th>
												<th>Status</th>
												<th className="actionbtnsets" style={{textAlign:'center'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.isrclist.map((result,index) => {
												return (
													<tr key={index}>
														<td>{result.title}</td>
														<td className="text-center">{result.code}</td>
														<td><span className={(result.status==='1')?"label label-success":"label label-danger"}>{(result.status==='1')?"Active":"In Active"}</span></td>
														<td className="actionbtns" style={{textAlign:'center'}}><Link to="#" title="Edit ISRC" onClick={() => this.editISRC(result.id)} className="btn btn-info custombtn"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;<Link to="#"  onClick={() => this.changeStatus(result.id,result.status)} title={(result.status === '1' )?'In Active':'Active'} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
														<Link to="#" title="Delete ISRC" onClick={() => this.deleteISRC(result.id)} className="btn btn-info custombtn"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>        
				</div>
				}
    			</section>
				<div id="myModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog">
						<div className="modal-content">
							<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true" onClick={this.closeIsrcform}>&times;</span></button>
							<div className="modal-body">
								<BulkISRC />
							</div>            
						</div>
					</div>
				</div>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}