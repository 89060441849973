import "datatables.net-dt/css/jquery.dataTables.min.css"
import React, { Component } from 'react';
const $ = require('jquery')
$.DataTable = require('datatables.net')

export class AlbumTbl extends Component {
	
	componentDidMount(){
		this.$el = $(this.el)
		this.$el.DataTable({
			data:this.props.data,
			columns:[
				{ title: "" },
				{ title: "Id" },
				{ title: "Name" },
				{ title: "Cover" },
				{ title: "Songs" },
				{ title: "Date" },
				{ title: "Actions" }
			]
		})
	}
	
	componentWillUnmount(){
		
	}
	
	render(){
		return (
			<div>
				<table clsssName="display" width="100%" ref={el=>this.el=el}></table>
			</div>
		)
	}
}