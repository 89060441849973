import React, { Component } from 'react';
import  { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import { API_URL, STRIPE_KEY } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import InputMask from 'react-input-mask';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const stripePromise = loadStripe(STRIPE_KEY);

const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  "Access-Control-Allow-Origin": "*",
}
const errors = {};
toast.configure();
export class WebEventBooking extends Component {
	constructor(props){
		super(props);

		this.state = {      
		loading:false,
		redirect:false,
        ticket_type: '',
        price: '',
        f_process_fee: '',
        primary_price: '',
        no_of_ticket: '',
        available_seats: '',
        end_ticket: true,
		  	ticketlist:[],
        errors: [],
        name:'',
        email:'',
        phonenumber:'',
        dob: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        disabled: false,
        bttext: 'Submit',
        eventData:'',
        process_msg:'',
        process_amount:'',
        process_type:''
		}
	}

  
	componentDidMount(){
    window.scrollTo(0, 0)
		document.title = "CONQER Music - Event Booking";
    this.getEventTicketList();
    this.getEventDetail();
  }
  getEventTicketList() {
    const obj = {
      event_id: this.props.match.params.id,
    };
    this.setState({
      loading: true,
    });
    Axios.post(base_api_url + 'ticket-list.php', obj, { axiosConfig }).then((res) => {
      this.setState({
        loading: false,
        ticketlist: res.data.res_data,
        process_msg: res.data.stripe_detail,
        process_amount: res.data.stripe_fee,
        process_type: res.data.stripe_fee_type
      });
    });
  }
  getEventDetail(){
    const eventid = this.props.match.params.id;
    const obj = {
        event_id: eventid
      }
      Axios.post(base_api_url + 'get-events-detail.php', obj, { axiosConfig })
        .then(
        (res) => {
          if (res.data.res_status == 200) {
            this.setState({
              eventData: res.data.res_data
            });
           }else{
			  this.setState({
				  redirect:true
			  })
		  }
          this.setState({
          	loading:false,
          	redirect:false
          });
        }
      )
  }
  tickets(e) {
    if (this.state.errors['titleerror']) {
      delete this.state.errors['titleerror'];
    }
    this.state.ticketlist.map((result) => {
      if (result.id === e.target.value) {
    		if(result.ticket_end){
    			this.setState({
    				errors: {titleerror: "Ticket booking closed at "+result.end_date+"."}
    			})
    		}
        if (this.state.no_of_ticket) {
          if(this.state.process_type == '%'){
            let final = parseFloat(((result.price * this.state.no_of_ticket)*this.state.process_amount)/100).toFixed(2)
            this.setState({
              f_process_fee: final
            });
          }else{
            let final = parseFloat(((result.price * this.state.no_of_ticket)+this.state.process_amount)).toFixed(2)
            this.setState({
              f_process_fee: final
            });
          }
          this.setState({
            primary_price: result.price,
            price: parseFloat(result.price * this.state.no_of_ticket).toFixed(2),
            available_seats: parseInt(result.seats),
            end_ticket: result.ticket_end,
            end_date: result.end_date
          });
        } else {
          if(this.state.process_type == '%'){
            let final = parseFloat((result.price*this.state.process_amount)/100).toFixed(2)
            this.setState({
              f_process_fee: final
            });
          }else{
            let final = parseFloat(result.price+this.state.process_amount).toFixed(2)
            this.setState({
              f_process_fee: final
            });
          }
          this.setState({
            primary_price: result.price,
            price: parseFloat(result.price).toFixed(2),
            available_seats: parseInt(result.seats),
            end_ticket: result.ticket_end,
            end_date: result.end_date
          });
        }
      }
    });
    if(e.target.value){
      this.setState({
        ticket_type: e.target.value,
      });
    }else{
      this.setState({
        ticket_type: e.target.value,
        price: '',
        f_process_fee:''
      });
    }
    
  }
  onChangeTicketNumber(e) {
    if (this.state.errors['numbererror']) {
      delete this.state.errors['numbererror'];
    }
    const re = /^[0-9\b]+$/;
    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value > 0) {
      let price = this.state.primary_price * e.target.value;
      if(this.state.process_type == '%'){
        let final = parseFloat((price*this.state.process_amount)/100).toFixed(2)
        this.setState({
          f_process_fee: final
        });
      }else{
        let final = parseFloat(price+this.state.process_amount).toFixed(2)
        this.setState({
          f_process_fee: final
        });
      }
      this.setState({
        no_of_ticket: e.target.value,
        price: parseFloat(this.state.primary_price * e.target.value).toFixed(2),
      });
    }else{
      this.setState({
        no_of_ticket: '',
        price: parseFloat(this.state.primary_price).toFixed(2)
      });
    }
  }
  userName(e){
    if (this.state.errors['nameerror']) {
      delete this.state.errors['nameerror'];
    }
    this.setState({
      name: e.target.value
    });
  }
  userEmail(e){
    if (this.state.errors['emailerror']) {
      delete this.state.errors['emailerror'];
    }
    this.setState({
      email: e.target.value
    });
  }
  userNumber(e){
    if (this.state.errors['phoneerror']) {
      delete this.state.errors['phoneerror'];
    }
    const re = /^[0-9\b]+$/;
    //if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        phonenumber: e.target.value
      });
    //}
  }
  
  async onSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({
        bttext:'Please Wait..',
        disabled:true
      });
      if(this.state.price > 0){
        await this.onToken();
      }else{
        this.freeTicketPayments();
      }
      
    }
  }
  stripeElementChange = (element, name) => {
    this.setState({
      cardError:'',
      formValidation: true
    });
    if(this.state.isrcqtyerror){
      this.setState({
        disablebtn:false,
        enablesubmitbutton:false
      });
    }else{
      if (element.complete) {
        this.setState({
          enablesubmitbutton:true,
          disablebtn:true,
          submitbuttontext : 'Submit',
        });
      } else {
        if(element.error){
          if(element.error.code){
            this.setState({
              disablebtn:false,
              enablesubmitbutton:false,
              formValidation: false,
              submitbuttontext : 'Submit',
              cardError: element.error.message
            });
          }
        }
      }
    }
  }
  async onToken() {
    const { elements, stripe } = this.props;

    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if(result.error){
      this.setState({
        bttext:'Submit',
        disabled:false,
        cardError: result.error.message
      });
    }else{
      this.setState({
        loading: true,
      });
      let formData = {
        "payment_token":result.token.id,
        "event_id": this.props.match.params.id,
        "ticket_type": this.state.ticket_type,
        "number_of_tickets": this.state.no_of_ticket,
        "total_price": this.state.price,
        "initial_price": this.state.primary_price,
        "name": this.state.name,
        "email": this.state.email,
        "phone": this.state.phonenumber.replace(/[^0-9]/g, ''),
      };
      Axios.post(base_api_url + 'event_ticket_booking.php', formData, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          this.onCancle();
          toast.success('Ticket booked successfully! Event details are sent to Email ID.');
        }else {
          toast.error('Ticket not booked, please try again!');
        }
        this.getEventTicketList();
        window.scrollTo(0, 0);
        this.setState({
          loading: false,
          bttext:'Submit',
          disabled:false
        });
      }
      )
    }
  }
  freeTicketPayments(){
    let formData = {
      "type":"free",
      "event_id": this.props.match.params.id,
      "ticket_type": this.state.ticket_type,
      "number_of_tickets": this.state.no_of_ticket,
      "total_price": this.state.price,
      "initial_price": this.state.primary_price,
      "name": this.state.name,
      "email": this.state.email,
      "phone": this.state.phonenumber.replace(/[^0-9]/g, ''),
    };
    Axios.post(base_api_url + 'event_ticket_booking.php', formData, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        this.onCancle();
        toast.success('Ticket booked successfully! Event details are sent to Email ID.');
      }else {
        toast.error('Ticket not booked, please try again!');
      }
      this.getEventTicketList();
      window.scrollTo(0, 0);
      this.setState({
        loading: false,
        bttext:'Submit',
        disabled:false
      });
    }
    )
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  handleValidation = () => {
    let formIsValid = true;
    if (!this.state.ticket_type) {
      formIsValid = false;
      errors['titleerror'] = 'Please select ticket.';
    }else if(this.state.end_ticket){
      formIsValid = false;
      errors['titleerror'] = 'Ticket booking closed at '+this.state.end_date+'.';
    }
    if (!this.state.no_of_ticket) {
      formIsValid = false;
      errors['numbererror'] = 'Please enter number of tickets.';
    } else if (this.state.no_of_ticket == 0) {
      formIsValid = false;
      errors['numbererror'] = 'Please enter minimum 1 ticket.';
    } else if (this.state.no_of_ticket > this.state.available_seats) {
      formIsValid = false;
      if (this.state.available_seats) {
        if(this.state.available_seats == 1){
          errors['numbererror'] = 'Only ' + this.state.available_seats + ' seat is available.';
        }else{
          errors['numbererror'] = 'Only ' + this.state.available_seats + ' seats are available.';
        }        
      } else {
        errors['numbererror'] = 'No seats are available.';
      }
    }
    if (!this.state.name) {
      formIsValid = false;
      errors['nameerror'] = 'Please enter name.';
    }
    if (!this.state.email) {
      formIsValid = false;
      errors['emailerror'] = 'Please enter email id.';
    }else if(this.state.email){
      if(!this.validateEmail(this.state.email)){
        formIsValid = false;
        errors['emailerror'] = 'Please enter valid email id.';
      }      
    }
    if (!this.state.phonenumber) {
      formIsValid = false;
      errors['phoneerror'] = 'Please enter phone number.';
    }else if(this.state.phonenumber){
      let number = this.state.phonenumber.replace(/[^0-9]/g, '');
      if(number.length < 9){
        formIsValid = false;
        errors['phoneerror'] = 'Please enter valid phone number.';
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  onCancle(){
    this.setState({
      ticket_type: '',
      price: '',
      primary_price: '',
      no_of_ticket: '',
      available_seats: '',
      end_ticket: true,
      name:'',
      email:'',
      phonenumber:'',
      f_process_fee:''
    });
    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);
    if(cardElement){
      cardElement.clear();
    }
  }
  addDefaultSrc = (ev) => {
      ev.target.src = defaultimg
  }
	render() {
    var input = navigator.onLine ? 'online' : 'offline';
    console.log(input);
		if(this.state.redirect==true){
			return <Redirect to='/events/' />;
		}
		if(this.state.loading==true){
			return <Spinner />;
		}
		return (
			<React.Fragment>
				<section id="registeforevent">
<div class="eventformare">
  <div class="container">
  <div class="row">
  <div class="col-md-4">
    <div class="viewimageevent">
        <img src={this.state.eventData.event_image} onError={this.addDefaultSrc} />
      </div>
  </div>
  <div class="col-md-8">
    <form class="registerevents">
    	<div class="row">
	        <div class="col-md-6">
	            <div class="form-group">
	              <label for="exampleInputFile">Select Ticket<span class="red-txt"> *</span></label>
	              <select className="form-control" name="access" onChange={this.tickets.bind(this)} value={this.state.ticket_type}>
                  <option value="">Select Ticket</option>
                  {this.state.ticketlist.map((result) => {
                    return <option value={result.id}>{result.ticket_title} ( {result.seats} )</option>;
                  })}
                </select>
                <font className="errorMsg">{this.state.errors['titleerror']}</font>
	            </div>
	        </div>
	        <div class="col-md-2">
	            <div class="form-group">
	              <label for="exampleInputFile">Price[$]</label>
	              <input type="text" class="form-control required" placeholder="Ticket Price" value={this.state.price} readOnly/>
	            </div>
	        </div>
          <div class="col-md-4">
              <div class="form-group">
                <label for="exampleInputFile">CC Processing Fee[$]</label>
                <input type="text" class="form-control required" placeholder="CC Processing Fee" value={this.state.f_process_fee} readOnly/>
              </div>
          </div>
	    </div>
      <div class="row">
        <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputFile">Number of Tickets<span class="red-txt"> *</span></label>
              <input type="text" class="form-control required" placeholder="Enter number of tickets" onChange={(e) => this.onChangeTicketNumber(e)} value={this.state.no_of_ticket}/>
              <font className="errorMsg">{this.state.errors['numbererror']}</font>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputFile">Name<span class="red-txt"> *</span></label>
              <input type="text" class="form-control required" placeholder="Enter your name" onChange={this.userName.bind(this)} value={this.state.name}/>
              <font className="errorMsg">{this.state.errors['nameerror']}</font>
            </div>
        </div>
        
      </div>
      <div class="row">
      <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputFile">Email ID<span class="red-txt"> *</span></label>
              <input type="email" class="form-control required" placeholder="Enter your email id" onChange={this.userEmail.bind(this)} value={this.state.email}/>
              <font className="errorMsg">{this.state.errors['emailerror']}</font>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputFile">Phone Number<span class="red-txt"> *</span></label>
              <InputMask {...this.props} mask="(999) 999-9999" maskChar="" className="form-control" onChange={this.userNumber.bind(this)} value={this.state.phonenumber} placeholder="Enter your phone number"/>
              <font className="errorMsg">{this.state.errors['phoneerror']}</font>
            </div>
        </div>
        
      </div>

      <div class="row">
      <div className="col-md-12">
        <div className="form-group">
         {(this.state.price > 0)?
        <>
        <label>
            Payment<span className="requiredastrik">*</span>
          </label>
          <div className="creditcardsection">
            <CardElement options={{ hidePostalCode: true }} onChange={(element) => this.stripeElementChange(element, 'card_number')}/>            
          </div>
          <div className="errorMsg">{this.state.cardError}</div>
        </>
      :""}
          
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="registertoeventbtns">
            <button type="button" class="btn btn-primary" onClick={this.onSubmit.bind(this)} disabled={this.state.disabled}>{this.state.bttext}</button>
            <button type="reset" class="btn btn-default closeB" onClick={this.onCancle.bind(this)}>Cancel</button>
        
           </div>
        </div>
        <div className="col-md-6" style={{'textAlign':'right'}}>
          <small>Note* {this.state.process_msg}</small>
        </div>
      </div>
    </form>
    
    
  </div>
</div>
</div>
</div>
</section>
			</React.Fragment>
		)
	}
}

export default function InjectedEventBooking({ match }) {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <WebEventBooking match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}