import React from 'react';
import { Spinner } from 'react-bootstrap';
const SpinnerComponent = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
      <Spinner
        style={{
          width: '80px',
          height: '80px',
          display: 'block',
        }}
        variant="warning"
        animation="border"
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default SpinnerComponent;
