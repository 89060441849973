import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
const base_api_url = API_URL;
const errors = {};
export default class Qa extends Component {
	
	constructor(props){
		super(props);
		this.onChangeName = this.onChangeName.bind(this);		
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeMessage = this.onChangeMessage.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
				
		this.state = {
			name:'',
			email:'',
			description:'',
			error: '',
			msg: '',
			successclass:'hideclass',
			submitbuttontext: 'Submit',
			redirect:false,
			loadinng:false,
			errors: [],
		}		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Q + A";
	}
	
	onChangeName(e){
		if(this.state.errors['name']){delete this.state.errors['name'];}
		this.setState({
		  name: e.target.value
		});
	}
	
	onChangeEmail(e){
		if(this.state.errors['email']){delete this.state.errors['email'];}
		this.setState({
		  email: e.target.value
		});
	}
	
	onChangeMessage(e){
		if(this.state.errors['comment']){delete this.state.errors['comment'];}
		this.setState({
		  description: e.target.value
		});
	}
	
	handleValidation = () => {
		let formIsValid = true;
		if(!this.state.name){
			formIsValid = false;
			errors["name"] = "Please enter name.";
		}else if(!this.state.name.replace(/ /g, "").length){
			formIsValid = false;
			errors["name"] = "Please enter name.";
		}
		if(!this.state.email){
			formIsValid = false;
			errors["email"] = "Please enter email.";
		}
		
		if(!this.state.description){
			formIsValid = false;
			errors["comment"] = "Please enter question.";
		}else if(!this.state.description.replace(/ /g, "").length){
			formIsValid = false;
			errors["comment"] = "Please enter question.";
		}

		this.setState({errors: errors});
		return formIsValid;

	}
	
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
				loadinng:true,
			});
			const formData = new FormData();
			const axiosConfig = {
				  'Content-Type': 'multipart/form-data',
				  "Access-Control-Allow-Origin": "*",
			};
			
			formData.append("name", this.state.name);
			formData.append("email", this.state.email);
			formData.append("description", this.state.description);
			Axios.post(base_api_url+'send-qa-message.php', formData, {axiosConfig})
			.then(
				(res) => {	
					if(res.data.res_status==200){
						window.scrollTo(0, 0);
						this.setState({
							successclass:'successmsg',
							submitbuttontext: 'Submit',
							msg:'Your message is sent successfully!',
							loadinng:false,
							name:null,
							email:null,
							description:null,
						});						
						
					}else{
						window.scrollTo(0, 0);
						this.setState({
							successclass:'errormsg',
							submitbuttontext: 'Submit',						
							msg:'Message is not send, please try again!',
							loadinng:false,
							name:null,
							email:null,
							description:null,
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:'hideclass',
						submitbuttontext: 'Submit',
						msg:null
					  });
					}, 5000)
				}
			);
		}
	}
	
    render() {
		if(this.state.loadinng === true){
			return <Spinner />;
		}
        return (
			<React.Fragment>
				
				<section className="ques-ans-section">
				  <div className="container">
					<div className={this.state.successclass}>{this.state.msg}</div>
					<div className="ques-ans">
					  <div>
						<p><u><strong>Why Conqer?</strong></u></p>

						<p>We are more than just a music platform. Conqer is a Christ-Centered music platform that seeks to develop artists and the community. You can post tickets for event, request prayer, order merchandise and more. <br /><br />And this is only the beginning!</p>

						<p><u><strong>Why isn't the music I upload to Conqer Music not on the streaming site? </strong></u></p>

						<p>All music is scanned and approved by the Conqer Music team to ensure the content is clean and appropriate. Your music will appear once content approval is granted. You can check your profile for the status but if you have questions or concerns, please submit your inquiry below. <br /></p>

						<p><u><strong>Does Conqer own my music? </strong></u></p>

						<p>No! You do. Your assets are yours alone.</p>
					  </div>
					</div>
				  </div>
				</section>
				<section className="q-a-form">
				  <div className="container">
					<div className="ques-ans">
					  <div className="text-center">If you have a specific question please feel free to complete the form below<br /> and we will get back to you with an answer.</div>
					</div>
					<div className="row">
					  <div className="col-md-12">
						<form className="form-q-a" onSubmit={this.onSubmit}>
							<div className="form-group">
								<label htmlFor="name">Name<span className="requiredastrik">*</span></label>
								<input type="text" className="form-control" value={this.state.name} onChange={this.onChangeName} id="name" />
								<font className="errorMsg">{this.state.errors["name"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="email">Email<span className="requiredastrik">*</span></label>
								<input type="email" className="form-control" value={this.state.email} onChange={this.onChangeEmail} id="email" />
								<font className="errorMsg">{this.state.errors["email"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="comment">Question<span className="requiredastrik">*</span></label>
								<textarea className="form-control" rows="5" onChange={this.onChangeMessage} id="comment" name="comment" ></textarea>
								<font className="errorMsg">{this.state.errors["comment"]}</font>
							</div>
							<div className="form-group q-a-submit">
								<input type="submit" name="Submit" value="Submit" />
							</div>
						</form>
					  </div>
					</div>
				  </div>
				</section>
			</React.Fragment>
		)
	}
}