import React, { Component } from 'react';
import Axios from 'axios';
import ArtistTopMenu from '../includes/artisttopmenu.component';
import  { Redirect, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
const base_api_url = API_URL;
const regex = /^[a-z\d\,-_\s]+$/i;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
const errors = {};
export default class ResetPassword extends Component {
	constructor(props){
		super(props);
		this.onChangeUsername 			= this.onChangeUsername.bind(this);		
		this.onChangeConfirmUsername 	= this.onChangeConfirmUsername.bind(this);		
		this.onSubmit 					= this.onSubmit.bind(this);
				
		this.state = {
			user_id:'',
			resetusername_status:'',
			username:'',
			confirmUsername:'',
			error: null,
			msg: null,
			successclass:'hideclass',
			redirect:false	,
			errors: [],
			btnStaus: true,	
			btnText: 'Submit'
		}		
	}
	componentDidMount(){
		document.title 			= "CONQER Music - Reset Username";
		this.checkResetPassLink();
	}
	
	handleValidation = () => {
		let formIsValid = true;
		
		if(!this.state.username){
			formIsValid = false;
			errors["username_error"] = "Please enter username.";
		}else{
			const res = /^[a-zA-Z0-9_\.]+$/;
			if(!res.test(this.state.username)){
				formIsValid = false;
				errors["username_error"] = "Only A-Z, a-z, 0-9, '.' and '_' are  acceptable.";
			}

		}

		if(!this.state.confirmUsername){
			formIsValid = false;
			errors["cusername_error"] = "Please enter confirm username.";
		}else{
			if(this.state.username !== this.state.confirmUsername){
				formIsValid = false;
				errors["cusername_error"] = "Confirm username must be same.";
			}
		}

		

		this.setState({errors: errors});		
        return formIsValid;
	
	}
	
	checkResetPassLink = () =>{
		const obj = {
			resetusername_code : this.props.match.params.id
		}
		
		Axios.post(base_api_url+'checkresetusernamelink.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					console.log('res',res.data);
					
					this.setState({
						resetusername_status:res.data.res_data.resetusername_status,						
						user_id:res.data.res_data.user_id,						
						loading:false
					});
				}
			}
		);
	}
	onChangeUsername(e) {
		if(this.state.errors['username_error']){delete this.state.errors['username_error'];}
		this.setState({
		  username: e.target.value
		});
	}
	
	onChangeConfirmUsername(e) {
		if(this.state.errors['cusername_error']){delete this.state.errors['cusername_error'];}
		this.setState({
		  confirmUsername: e.target.value
		});
	}
	
	
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({					
				btnStaus:false,
				btnText: "Please Wait..."
			});
			const { username, confirmUsername } = this.state;
			// perform all neccassary validations
			if (username !== confirmUsername) {
				alert("Username don't match");
			} else {
				const formData = new FormData();
						
				formData.append("newusername", this.state.username);
				formData.append("confirmusername", this.state.confirmUsername);
				formData.append("userid", this.state.user_id);
				
				Axios.post(base_api_url+'reset-username.php', formData, {axiosConfig})
				.then(
					(res) => {
						if(res.data.res_status==200){
							window.scrollTo(0, 0);
							this.setState({
								successclass:'successmsg',
								msg:'Username changed successfully!',
								username:'',
								confirmUsername:''
							});						
							
						}else{
							window.scrollTo(0, 0);
							this.setState({
								successclass:'errormsg',						
								msg:res.data.res_message
							})
						}
						this.setState({					
							btnStaus:true,
							btnText: 'Submit'
						});
						setTimeout(() => {
						  this.setState({
							successclass:'hideclass',
							msg:null
						  });
						}, 10000)
					}
				);
			}
			
			this.setState({
				email:'',			
			});
		}	
	}
    render() {
		const resetusernamestatus = this.state.resetusername_status;
		const message = this.state.msg;
		if ( resetusernamestatus === 0){
			return <Redirect to='/forgot-username'  />
		}
        return (
			<React.Fragment>
				<section className="forget-pass">
					<div className="container">
						<div className="row">
							<div className="fogotpassform">
							  <div className="affendforgot">
								<div className={this.state.successclass}>{this.state.msg}</div>
								
								<form className="artist-form" onSubmit={this.onSubmit}>
									<div className="form-group">
										<label  htmlFor="nusername">New Username<span className="requiredastrik">*</span></label>
										<input type="text" className="form-control" id="nusername" value={this.state.username} onChange={this.onChangeUsername} />
										<font className="errorMsg">{this.state.errors["username_error"]}</font>
									</div>
									<div className="form-group">
										<label  htmlFor="ncusername">Confirm Username<span className="requiredastrik">*</span></label>
										<input type="text" className="form-control" id="ncusername" value={this.state.confirmUsername} onChange={this.onChangeConfirmUsername} />
										<font className="errorMsg">{this.state.errors["cusername_error"]}</font>
									</div>
									<div className="form-group">
										<button type="submit" disabled={!this.state.btnStaus} className="btn btn-primary validate">{this.state.btnText}</button>
									</div> 
								</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}