import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './css/style.css';
import AdminLoginComponent from './adminLogin.component';
import AdminDashboardComponent from './dashboard.component';
import EventComponent from './events/events-list.component';
import EventAddComponent from './events/add.component';
import EventTicketsComponent from './events/tickets.component';
import EventGalleryComponent from './events/gallery.component';
import EventViewComponent from './events/view.component';
import EventReportComponent from './events/report';
import SongComponent from './songs/songs-list.component';
import SongComponents from './songs/song-list';
import PrayerLine from './prayerline/prayerline.component';
import ViewPrayer from './prayerline/viewprayer.component';
import AdminLogoutComponent from './logout.component';
import ComingSoonComponent from './coming-soon.component';
import UserListingComponent from './usersListing.component';
import UserDetailComponent from './userDetails.component';
import UserEditComponent from './editUser.component';
import UserAddComponent from './addUser';
import Commission from './masters/commission.component';
import Notification from './masters/notifications.component';
import ISRC from './masters/isrc.component';
import Genre from './masters/genre.component';
import subGenre from './masters/subGenre';
import eventCategory from './masters/eventCategory';
import ISRCPackage from './masters/isrcpackage.component';
import ViewRevenue from './payments/viewrevenue';
import Settings from './settings.component';

import userPayment from './payments/userpayment';
import songRevenue from './payments/songrevenue';
import songReports from './payments/songReports';
import adminPayment from './payments/adminpayment';
import EventDetailPayment from './payments/eventDetailPayment';
import NotificationList from './notificationsList';
import Celebrity from './celebrity/index';
import AddCelebrity from './celebrity/add';
import ViewCelebrity from './celebrity/view';
import CelebrityUserList from './celebrity/user-list';
import suggestArtist from './suggest/index';
import Church from './church/index';
import AddChurch from './church/add';
import ViewChurch from './church/view';
import ChurchUserList from './church/user-list';
import EmailNotification from './email/index';
export default class AdminRoute extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        <Route exact path="/admin" component={AdminLoginComponent} />
        <Route exact path="/admin/dashboard" component={AdminDashboardComponent} />
        <Route exact path="/admin/my-song" component={AdminDashboardComponent} />
        <Route exact path="/admin/events" component={EventComponent} />
        <Route exact path="/admin/events/add" component={EventAddComponent} />
        <Route exact path="/admin/events/edit/:id" component={EventAddComponent} />
        <Route exact path="/admin/events/tickets/:id" component={EventTicketsComponent} />
        <Route exact path="/admin/events/gallery/:id" component={EventGalleryComponent} />
        <Route exact path="/admin/events/view/:id" component={EventViewComponent} />
        <Route exact path="/admin/events/reports/:id" component={EventReportComponent} />
        <Route exact path="/admin/songs" component={SongComponent} />
        <Route exact path="/admin/song/:id" component={SongComponents} />
        <Route exact path="/admin/prayerline" component={PrayerLine} />
        <Route exact path="/admin/view-prayer-request/:id" component={ViewPrayer} />
        <Route exact path="/admin/logout" component={AdminLogoutComponent} />
        <Route exact path="/coming-soon" component={ComingSoonComponent} />
        <Route exact path="/admin/users" component={UserListingComponent} />
        <Route exact path="/admin/usersDetail/:id" component={UserDetailComponent} />
        <Route exact path="/admin/edit-user/:id" component={UserEditComponent} />
        <Route exact path="/admin/add-user" component={UserAddComponent} />
        <Route exact path="/admin/commission" component={Commission} />
        <Route exact path="/admin/notification" component={Notification} />
        <Route exact path="/admin/isrc" component={ISRC} />
        <Route exact path="/admin/genre" component={Genre} />
        <Route exact path="/admin/subgenre" component={subGenre} />
        <Route exact path="/admin/isrcpackage" component={ISRCPackage} />
        <Route exact path="/admin/event-category" component={eventCategory} />
        <Route exact path="/admin/userpayment" component={userPayment} />
        <Route exact path="/admin/songrevenue" component={songRevenue} />
        <Route exact path="/admin/song-played-reports" component={songReports} />
        <Route exact path="/admin/adminpayment" component={adminPayment} />
        <Route exact path="/admin/eventdetailpayment/:id" component={EventDetailPayment} />
        <Route exact path="/admin/viewrevenue/:id" component={ViewRevenue} />
        <Route exact path="/admin/settings" component={Settings} />
        <Route exact path="/admin/notification-list" component={NotificationList} />
        <Route exact path="/admin/celebrity" component={Celebrity} />
        <Route exact path="/admin/celebrity/add" component={AddCelebrity} />
        <Route exact path="/admin/celebrity/edit/:id" component={AddCelebrity} />
        <Route exact path="/admin/celebrity/view/:id" component={ViewCelebrity} />
        <Route exact path="/admin/celebrity/user-list/:type/:id" component={CelebrityUserList} />
        <Route exact path="/admin/suggest-artist" component={suggestArtist} />
        <Route exact path="/admin/church" component={Church} />
        <Route exact path="/admin/church/add" component={AddChurch} />
        <Route exact path="/admin/church/edit/:id" component={AddChurch} />
        <Route exact path="/admin/church/view/:id" component={ViewChurch} />
        <Route exact path="/admin/church/user-list/:type/:id" component={ChurchUserList} />
        <Route exact path="/admin/email-notification" component={EmailNotification} />
      </Switch>
    );
  }
}
