import React, { Component } from 'react';
import { Link, NavLink, withRouter} from 'react-router-dom';
import Axios from 'axios';
import ls from 'local-storage';
import { API_URL } from '../config/constants.js';
import $ from 'jquery';
const base_api_url = API_URL;
const axiosConfig = {
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*'
		};
class Header extends Component {
	constructor(props){
		super(props);
		this.state = {
		  email:'',
		  role:null,
		  name:'',
		  userid:null,
		  error: null,
		  msg: null,
		  loginstatus: null,
		  redirect:false,
		  showhide:'mobilemenu',
		  bibledata:'',
		}
	}
	componentDidMount() {
		const email = (ls.get('email'))?ls.get('email'):'';
		const role = (ls.get('role'))?ls.get('role'):'';
		const name = (ls.get('name'))?ls.get('name'):'';
		// const loginstatus = (ls.get('loginstatus'))?ls.get('loginstatus'):'';
		const userid = (ls.get('userid'))?ls.get('userid'):'';
		this.setState({ email:email, role:role, name:name, userid:userid });
		$(document).ready(function () {
		    $(document).click(function (event) {
		        var clickover = $(event.target);
		        if (clickover.hasClass("fa fa-bars")) {
		            $("#mymobilemenu").addClass('show');
		            $("#mymobilemenu").removeClass('hide');
		        }else{
		        	$("#mymobilemenu").addClass('hide');
		            $("#mymobilemenu").removeClass('show');
		        }
		    });
		});
	}	
	componentDidUpdate() {	
		ls.set('email', this.state.email);
		ls.set('role', this.state.role);
		ls.set('name', this.state.name);
		ls.set('userid', this.state.userid);
		ls.set('loginstatus', this.state.loginstatus);		
	}
	
	openNav = () => {		
		this.setState({
			showhide:'mobilemenu show'
		})		
	}
	
	closeNav = () => {
		this.setState({
			showhide:'mobilemenu hide'
		})
	}
	onclicklogut = (logouttype) => {
		let currenttoken = ls.get('token');
		const obj = {
				type: logouttype,
				token: currenttoken,
			};
		
		Axios.post(base_api_url + 'deleteloginattempt.php', obj, { axiosConfig }).then((res) => {
			if (res.data.res_status == 200) {
				/*ls.set('userid','');
				ls.set('email','');
				ls.set('name','');
				ls.set('role','');
				ls.set('token','');
				ls.set('membership','');
				ls.set('membership_status','');
				ls.set('profile','');*/
				ls.clear();
				this.setState({
					email:'',
					loginstatus:'',
				})
				this.props.history.push('/login');
			}
		});
	};
	bibleRead = () => {
		const formData = new FormData();
		let userenteremail = this.state.email;
		formData.append("bibleid", this.state.bibledata);
		formData.append("email", this.state.email);
		
		Axios.post(base_api_url+'readingbible.php', formData, {axiosConfig})
		.then(
			(res) => {
				window.scrollTo(0, 0);
				if(res.data.res_status==402){
					/*this.setState({
						successclass:'errormsg',
						msg:res.data.res_message,
						selectPlan: true,
						radioPlan: true,
						radioReading: false,
						email:userenteremail
					}, () => );*/
					this.props.history.push({
					  pathname: '/bible-reading',
					  search: '?plan=no'
					})

				}else if(res.data.res_status==200){
					ls.set('useremail', res.data.res_data.useremail);
					ls.set('bibletype', res.data.res_data.bibletype);
					ls.set('bibleid', res.data.res_data.bibleid);
					ls.set('fileshortname', res.data.res_data.fileshortname);
					this.setState({
						successclass:'successmsg',
						msg:res.data.res_message,
						errors:{},
						bibledata:''
					});
					
				}else{
					
					this.setState({
						successclass:'errormsg',						
						msg:'Something went wrong, please try again!',
						buttontext : 'Save Item'
					})
				}
				let redirectUrl ='';
				if(res.data.res_data){
					redirectUrl =  res.data.res_data.redirect_url;
					this.props.history.push(redirectUrl);
				}
			}
		);
	}
	render() {
        const email 	= this.state.email;
		const lstats 	= this.state.loginstatus;
        const role 		= ls.get('role');
		return (
         <div className="conqer-header"> 
			<div className="conqer-topbar">
				<div className="container top-menu">				   
				<ul>
					<li>
					{ (email != '')?<a href="javascript:void(0)" onClick={()=>this.bibleRead()} activeClassName="active">Bible Reading Plan</a>:<NavLink to={'/bible-reading'} activeClassName="active">Bible Reading Plan</NavLink>}
					</li>
					<li><NavLink to={'/studio'} activeClassName="active">Conqer Studio</NavLink></li>
					{(email!='' && lstats!='')?<li><a href="javascript:void(0)" onClick={() => this.onclicklogut('1')} activeClassName="active">Logout</a></li>:<li><NavLink to={'/login'} activeClassName="active">Account</NavLink></li>}
					<li><NavLink to={'/help'} activeClassName="active">Help</NavLink></li>
				</ul>
				<div id="mymobilemenu" className="mobilemenu hide">
					<a href="javascript:;"  className="closebtn" onClick={this.closeNav}>&times;</a>
					
					<NavLink exact to={'/'} className="nav-item nav-link" activeClassName="active">Home</NavLink>
					<NavLink exact to={'/about-us'} className="nav-item nav-link" activeClassName="active">About Us</NavLink>
					
					{(email!='' && lstats!='')?'':<NavLink exact to={'/artist'} className="nav-item nav-link" activeClassName="active">Artist</NavLink>}
					{(email!='' && lstats!='')?'':<NavLink exact to={'/user'} className="nav-item nav-link" activeClassName="active">Listener</NavLink>}
					{(email!='' && lstats!='')?<NavLink exact to={(role === '2')?'/artist-dashboard':'/listener-dashboard'} className="nav-item nav-link" activeClassName="active">Dashboard</NavLink>:''}
					<NavLink exact to={'/events'} className="nav-item nav-link">Events</NavLink>
					<NavLink exact to={'/philanthropy'} className="nav-item nav-link" activeClassName="active">Philanthropy</NavLink>
					<NavLink exact to={'/merchandise'} className="nav-item nav-link" activeClassName="active">Merchandise</NavLink>
					<NavLink exact to={'/news-media'} className="nav-item nav-link" activeClassName="active">News + Media</NavLink>
					<NavLink exact to={'/collaborating-partners'} className="nav-item nav-link" activeClassName="active">Partners</NavLink>
					<NavLink exact to={'/q-a'} className="nav-item nav-link" activeClassName="active">Q+A</NavLink>
				</div>
				</div>
			</div>   
			<div className="bs-example container">
				<nav className="navbar navbar-expand-md">
					<Link to={'/'} className="navbar-brand logo-conqer">
						<img src={process.env.PUBLIC_URL + '/assets/logo/logo.png'} alt="Conqer" />
					</Link>	
					<span className="mobilebars" onClick={this.openNav}><i className="fa fa-bars" aria-hidden="true"></i></span>				
					<div className="collapse navbar-collapse" id="navbarCollapse">
						<div className="navbar-nav navbar-head">
							<NavLink exact to={'/'} className="nav-item nav-link" activeClassName="active">Home</NavLink>
							<NavLink exact to={'/about-us'} className="nav-item nav-link" activeClassName="active">About Us</NavLink>
							{(email!='' && lstats!='')?'':<NavLink exact to={'/artist'} className="nav-item nav-link" activeClassName="active">Artist</NavLink>}
							{(email!='' && lstats!='')?'':<NavLink exact to={'/user'} className="nav-item nav-link" activeClassName="active">Listener</NavLink>}
							{(email!='' && lstats!='')? (role === '2')?<NavLink exact to={'/artist-dashboard'} className="nav-item nav-link" activeClassName="active">Dashboard</NavLink>:<NavLink exact to={'/listener-dashboard'} className="nav-item nav-link" activeClassName="active">Dashboard</NavLink> : ''}
							<NavLink exact to={'/events'} className="nav-item nav-link">Events</NavLink>
							<NavLink exact to={'/philanthropy'} className="nav-item nav-link" activeClassName="active">Philanthropy</NavLink>
							<NavLink exact to={'/merchandise'} className="nav-item nav-link" activeClassName="active">Merchandise</NavLink>
							<NavLink exact to={'/news-media'} className="nav-item nav-link" activeClassName="active">News + Media</NavLink>
							<NavLink exact to={'/collaborating-partners'} className="nav-item nav-link" activeClassName="active">Partners</NavLink>
							<NavLink exact to={'/q-a'} className="nav-item nav-link" activeClassName="active">Q+A</NavLink>
						</div>
					</div>
				</nav>
			</div>
		</div>
        )
    }
}
export default withRouter(Header);