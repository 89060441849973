import React, { Component } from 'react';
import Axios from 'axios';
import ArtistTopMenu from '../includes/artisttopmenu.component';
import  { NavLink  } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { API_URL } from '../config/constants.js';
const base_api_url = API_URL;
const errors = {};
export default class ForgotPassword extends Component {
	constructor(props){
		super(props);
		this.onChangeEmail 	= this.onChangeEmail.bind(this);		
		this.onSubmit 		= this.onSubmit.bind(this);
				
		this.state = {
			email:'',
			errors:[],
			error: null,
			msg: null,
			successclass:'hideclass',
			redirect:false,
			submitbuttontext:'Submit',
		}		
	}
	componentDidMount(){
		document.title = "CONQER Music - Forgot Password";				
	}
	
	
	onChangeEmail(e) {
		if(this.state.errors['email']){delete this.state.errors['email'];}
		this.setState({email:e.target.value, error : null});
	}
	
	
	handleValidation = () => {
		let formIsValid = true;
		
		if(!this.state.email){
			formIsValid = false;
			errors["email"] = "Please enter registered email id.";
		}
		
		this.setState({errors: errors});
		return formIsValid;

	}
	
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({submitbuttontext:<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '3vh',float:'right' }}><Spinner style={{ width: '15px', height: '15px', display: 'block'}} variant="light" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div>});
			
			const axiosConfig = {
				  'Content-Type': 'multipart/form-data',
				  "Access-Control-Allow-Origin": "*",
			};
			const obj = {
				"email": this.state.email
			}	
			Axios.post(base_api_url+'forgotpasswordlink.php', obj, {axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						window.scrollTo(0, 0);
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
							submitbuttontext:'Submit'
						});						
						
					}else{
						window.scrollTo(0, 0);
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message,
							submitbuttontext:'Submit'
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:'hideclass',
						msg:null
					  });
					}, 5000)
				}
			);
			
			this.setState({
				email:'',			
			});		
		}
	}
    render() {
        return (
			<React.Fragment>
				<section className="forget-pass">
					<div className="container">
						<div className="row">
							<div className="fogotpassform">
							  <div className="affendforgot">
								<div className={this.state.successclass}>{this.state.msg}</div>
								<p>Please enter your registered email id to receive reset password link.</p>
								<form className="artist-form" onSubmit={this.onSubmit}>
									<div className="form-group">
										<label  htmlFor="emailA">Email Address<span className="requiredastrik">*</span></label>
										<input type="email" className="form-control" id="emailA" value={this.state.email} onChange={this.onChangeEmail} onBlur={this.onBlurEmail} />
										<span className="errorMsg">{this.state.error}</span>
										<font className="errorMsg">{this.state.errors["email"]}</font>
									</div>
									
										<div className="sub-formpart forgotpage">
										<button type="submit" className="btn btn-primary validate">{this.state.submitbuttontext}</button>
										</div>
									
								</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}