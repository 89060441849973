import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../config/constants.js';
import ls from 'local-storage';
import  { Link, NavLink } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import $ from 'jquery'; 
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
export default class UserList extends Component {
	constructor(props) {
    	super(props);
    	this.state = {
    		listData:[]
    	}
    }
    componentDidMount() {
    	const type = this.props.match.params.type;
    	const id = this.props.match.params.id;
	  	if(type == 'listener'){
	  		this.getUserList('1',id);
			document.title = "CONQER Music - Listener List";
		}else{
			this.getUserList('2',id);
			document.title = "CONQER Music - Artist List";
		}
	}
	getUserList(type,id){
		let formData = {
			"type":type,
			"id":id
		}
		$('#celebrity').DataTable().destroy();
		Axios.post(base_api_url + 'church-user-list.php',formData, {axiosConfig}).then(
	        (res) => {
	          	if (res.data.res_status == 200) {
	          		const returndata = res.data.res_data;	
	            	this.setState({
	              		listData: returndata
	            	});
	          	} else {
	            	this.setState({
	              		listData: []
	            	});
	          	}	          	
	         	$('#celebrity').DataTable({
	          		"destroy": true,	
					"stateSave": true,
					language: {
						search: "Search"
					},
					dom: 'Bfrtip',
					buttons: [
		            	{
		                	extend: 'csv'
		            	},
		            	{
		                extend: 'print'
		            	}
		        	]
	          	});
	        }
	    );
	}
	render() {
		const type = this.props.match.params.type;
		return(
		<React.Fragment>
    		<div className="dashboard-wrapper">
    			<AdminHeader />
    			<div className="maincontent-area">
    				<AdminTopHeader />
    				<div className="main-dashsection">
    					<section className="contentpard">
    						<div className="row">
								<div className="col-md-12">
									<div className="box box-success distributions-profileform">
										<div className="box-header with-border addprofilenew">
									  		<h3 className="box-title">{(type == 'listener')?'Listener List':'Artist List'}</h3>
									  		<div className="box-tools">
												<NavLink to={'/admin/church'} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i> Back</NavLink>
											</div>          
										</div>
										<div className="box-body no-padding">
									  		<div className="table-responsive">
									  			<table id="celebrity" className="table no-margin table-striped table-hover">
													<thead>
													  	<tr>
															<th>Name</th>
															<th>Mobile</th>
															<th>Email</th>
													  	</tr>
													</thead>
													<tbody>
													{this.state.listData.map(result => {
														return (
															<tr>
																<td>{result.name}</td>
																<td>{(result.phone)?`(${result.phone.substring(0, 3)}) ${result.phone.substring(3, 6)}-${result.phone.substring(6,result.phone.length)}`:'-'}</td>
																<td>{result.email}</td>
															</tr>
														)}
														)
													}
													</tbody>
												</table>
									  		</div>
									  	</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</React.Fragment>
		);
	}
}