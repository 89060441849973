import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
export default class Terms extends Component {
	componentDidMount(){
		document.title = "CONQER Music - Terms of Services";
	}
    render() {
        return (
			<React.Fragment>
				<section >
				  <div className="privacy-section">
					<div className="container">
					  <div className="col-md-12">
						<div className="sppb-addon-content">
							<p><span className="terms_heading"><strong>Terms and Conditions</strong><strong> of Use</strong></span></p>
							<p>Welcome and thank you for choosing Conquer Music Companies LLC (“Conqer Music,” “we,” “us,” “our”). The conqermusic.com website (the "Site") is comprised of various web pages operated by Conquer Music Companies LLC and is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of conqermusic.com constitutes your agreement to all such Terms. Please read these terms carefully and keep a copy of them for your reference.</p>
							<p>Conqer Music is a music streaming site that provides personalized services with social and interactive features for streaming music and other content as well as other products and services that may be developed from time to time. Subscribe to listen to Christian music or as an artist to post your music and talk to everyone in the network. You can also buy Conqer merchandise, post and buy tickets for Christian events and request prayer. </p>
							<p>By signing up or otherwise using any of our services, including all associated features and functionalities, websites and user interfaces, as well as all content and software applications associated with our services (collectively, the “Service”), or accessing any music, videos or other content or material that is made available through the Service (the “Content”) you are entering into a binding contract with Conquer Music Companies, LLC.</p>
							<p className="goldtext"><strong>1.0 Service Options</strong></p>
							<p>The Conqer Music Service requires payment before you can access it (the “Subscription”). We may also offer special promotional plans, memberships, or services, including offerings of third-party products and services in conjunction with or through the Conqer Music Service. We are not responsible for the products and services provided by such third parties. We reserve the right to modify, terminate or otherwise amend our offered subscription plans and promotional offerings at any time in accordance with these Terms.</p>
							<p>If you have purchased or received a code, gift card, pre-paid offer or other offer provided or sold by or on behalf of Conqer for access to a Subscription (“Code”), separate terms and conditions presented to you along with the Code may also apply to your access to the Service and you agree to comply with any such terms and conditions. You may also purchase access to a Subscription through a third party. In such cases, separate terms and conditions with such third party in addition to the Agreements may apply to your access to the Service.</p>
							<p className="goldtext"><strong>1.1 Trials</strong></p>
							<p>From time to time, we or others on our behalf may offer trials of a Subscription for a specified period without payment or at a reduced rate (a “Trial”). Conqer Music may determine your eligibility for a Trial and withdraw or modify a Trial at any time without prior notice and with no liability, to the extent permitted under applicable law.</p>
							<p>Trials will require you to provide your payment details to start the Trial. By providing such details you agree that we may automatically begin charging you for the Subscription cost on the first day following the end of the Trial on a recurring monthly basis or another interval that we disclose to you in advance. IF YOU DO NOT WANT THIS CHARGE, YOU MUST CANCEL THE APPLICABLE SUBSCRIPTION BEFORE THE END OF THE TRIAL BY CLICKING HERE IF YOU SIGNED UP FOR THE TRIAL THROUGH CONQER MUSIC, OR IF YOU RECEIVED YOUR TRIAL THROUGH A THIRD PARTY, YOU MUST CANCEL THE APPLICABLE PAID SUBSCRIPTION THROUGH THE THIRD PARTY</p>
							<p className="goldtext"><strong>2 Payments and Cancellations</strong></p>
							<p className="goldtext"><strong>2.1 Billing</strong></p>
							<p>You may purchase a Subscription directly from Conqer Music or through a third party either by (1) paying a subscription fee in advance on a monthly basis or some other recurring interval disclosed to you prior to your purchase; or (2) pre-payment giving you access to the Conqer Music Service for a specific time period (“Pre-Paid Period”). Payments made to and from Conqer music will be facilitated through <a href="https://stripe.com/get-started?utm_campaign=paid_brand-US%20%7C%20Search%20%7C%20Brand%20%7C%20Stripe-351718373&utm_medium=cpc&utm_source=bing&utm_content=&utm_term=EXA_Stripe%20Only-stripe-e&utm_adposition=&utm_device=c&msclkid=51c2db43f1eb1ee2d52186a044dd86d5">Stripe</a> and applicable terms of service will apply. All music loaded to Conqer Music service requires an ISRC code. If a user requires an ISRC code and has not already obtain one, we reserve the right to charge a service fee in addition to authority fees to obtain the ISRC code on your behalf. </p>
							<p>Conqer Music may change the price for our Subscription, including recurring subscription fees, the Pre-Paid Period (for periods not yet paid), or Codes, from time to time and will communicate any price changes to you in advance and, if applicable, how to accept those changes. Price changes will take effect at the start of the next subscription period following the date of the price change. Subject to applicable law, you accept the new price by continuing to use the Conqer Music Service after the price change takes effect. If you do not agree with a price change, you have the right to reject the change by unsubscribing from the Subscription prior to the price change going into effect.</p>
							<p className="goldtext"><strong>2.2 Renewal; Cancellation</strong></p>
							<p>Unless your Subscription has been purchased for a Pre-Paid Period, your payment to Conqer Music or the third party through which you purchased the Subscription will automatically renew at the end of the applicable subscription period, unless you cancel your Subscription before the end of the then-current subscription period by clicking here if you purchased the Subscription through Conqer Music, or if you purchased the Subscription through a third party, by canceling the Subscription through such third party. The cancellation will take effect the day after the last day of the current subscription period. We do not provide refunds or credits for any partial subscription periods.</p>
							<p>If you have purchased a Subscription using a Code, your subscription will automatically terminate at the end of the period stated in the Code and will auto renew at standard rates, or cancel if there is an insufficient balance to pay for the Service. If you have purchased your Subscription through a third party, you must cancel directly with that third party.</p>
							<p className="goldtext"><strong>3.0 Third Party Applications and Devices</strong></p>
							<p>The Conqer Music Service is integrated with or may otherwise interact with third party applications, websites, and services (“Third Party Applications”) and third party Devices to make the Conqer Music Service available to you. These Third Party Applications and Devices may have their own terms and conditions of use and privacy policies and your use of these Third Party Applications and Devices will be governed by and subject to such terms and conditions and privacy policies. You understand and agree that Conqer Music does not endorse and is not responsible or liable for the behavior, features, or content of any Third Party Application or Device or for any transaction you may enter into with the provider of any such Third Party Applications and Devices, nor does Conqer Music warrant the compatibility or continuing compatibility of the Third Party Applications and Devices with the Service.</p>
							<p className="goldtext"><strong>4.0 Electronic Communications</strong></p>
							<p>Visiting conqermusic.com or sending emails to Conqer Music constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>
							<p className="goldtext"><strong>5.0 Your Account</strong></p>
							<p>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Conqer Music is not responsible for third party access to your account that results from theft or misappropriation of your account. Conqer Music and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>
							<p className="goldtext"><strong>6.0 Children Under Thirteen</strong></p>
							<p>Conqer Music does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use conqermusic.com only with permission of a parent or guardian.</p>
							<p className="goldtext"><strong>7.0 Links to Third Party Sites/Third Party Services</strong></p>
							<p>conqermusic.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Conqer Music and Conqer Music is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Conqer Music is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Conqer Music of the site or any association with its operators.</p>
							<p>Certain services made available via www.conqermusic.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the www.conqermusic.com domain, you hereby acknowledge and consent that Conqer Music may share such information and data with any third party with whom Conqer Music has a contractual relationship to provide the requested product, service or functionality on behalf of www.conqermusic.com users and customers.</p>
							<p className="goldtext"><strong>8.0 No Unlawful or Prohibited Use/Intellectual Property </strong></p>
							<p>You are granted a non-exclusive, non-transferable, revocable license to access and use www.conqermusic.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Conqer Music that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>
							<p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Conqer Music or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>
							<p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Conqer Music content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use and will make no other use of the content without the express written permission of Conqer Music and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Conqer Music or our licensors except as expressly authorized by these Terms.</p>
							<p className="goldtext"><strong>9.0 Use of Communication Services</strong></p>
							<p>The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"). You agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service.</p>
							<p>By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded; restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.</p>
							<p>Conqer Music has no obligation to monitor Communication Services. However, Conqer Music reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. Conqer Music reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.</p>
							<p>Conqer Music reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in Conqer Music's sole discretion.</p>
							<p>Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. Conqer Music does not control or endorse the content, messages or information found in any Communication Service and, therefore, Conqer Music specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorized Conqer Music spokespersons, and their views do not necessarily reflect those of Conqer Music.</p>
							<p>Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials.</p>
							<p className="goldtext"><strong>10.0 Materials Provided to conqermusic.com or Posted on Any Conqer Music Web Page</strong></p>
							<p>Conqer Music does not claim ownership of the materials you provide to www.conqermusic.com (including feedback and suggestions) or post, upload, input or submit to any Conqer Music site or our associated services (collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting Conqer Music, our affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.</p>
							<p>No compensation will be paid with respect to the use of your Submission, as provided herein. Conqer Music is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in Conqer Music's sole discretion.</p>
							<p>By posting, uploading, inputting, providing or submitting your Submission, you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</p>
							<p className="goldtext"><strong>11.0 International Users</strong></p>
							<p>The Service is controlled, operated and administered by Conqer Music from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Conqer Music Content accessed through www.conqermusic.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>
							<p className="goldtext"><strong>12.0 Indemnification</strong></p>
							<p>You agree to indemnify, defend and hold harmless Conqer Music, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Conqer Music reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Conqer Music in asserting any available defenses.</p>
							<p className="goldtext"><strong>13.0 Arbitration</strong></p>
							<p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regard to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.</p>
							<p className="goldtext"><strong>14.0 Class Action Waiver</strong></p>
							<p>Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and Conqer Music agree otherwise, the arbitrator may not consolidate more than one person's claims and may not otherwise preside over any form of a representative or class proceeding.</p>
							<p className="goldtext"><strong>15.0 Liability Disclaimer</strong></p>
							<p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. CONQUER MUSIC COMPANIES LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>
							<p>CONQUER MUSIC COMPANIES LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. CONQUER MUSIC COMPANIES LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
							<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL CONQUER MUSIC COMPANIES LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF CONQUER MUSIC COMPANIES LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>
							<p className="goldtext"><strong>16.0 Termination/Access Restriction</strong></p>
							<p>Conqer Music reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of California and you hereby consent to the exclusive jurisdiction and venue of courts in California in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
							<p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and Conqer Music as a result of this agreement or use of the Site. Conqer Music's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Conqer Music's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by Conqer Music with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
							<p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Conqer Music with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Conqer Music with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>
							<p className="goldtext"><strong>17.0 Changes to Terms</strong></p>
							<p>Conqer Music reserves the right, in its sole discretion, to change the Terms under which conqermusic.com is offered. The most current version of the Terms will supersede all previous versions. Conqer Music encourages you to periodically review the Terms to stay informed of our updates.</p>
							<p className="goldtext"><strong>Contact Us</strong></p>
							<p>Conqer Music welcomes your questions or comments regarding the Terms:</p>
							<p>Conquer Music Companies LLC<br />P.O. Box 407<br />Blandon, Pennsylvania 19510</p>
							<p><span className="terms_text_color">Email Address:</span><br />info@conqermusic.com</p>
							<p><span className="terms_text_color">Telephone number:</span></p>
							<p>Effective as of December 04, 2019</p>
						</div>
						</div>
					</div>
				</div>
				</section>
			</React.Fragment>
		)
	}
}