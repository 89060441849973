import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Axios from 'axios';
import ls from 'local-storage';
import { API_URL } from '../config/constants.js';
import $ from 'jquery';
const base_api_url = API_URL;
class ListenerSideMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      role: null,
      token: '',
      error: null,
      msg: null,
      redirect: false,
    };
  }

  componentDidMount() {
    const email = ls.get('email');
    const name = ls.get('name');
    const token = ls.get('token');
    const userid = ls.get('userid');
    this.setState({
      email: email,
      name: name,
      token: token,
      userid: userid,
    });
    $(document).ready(function() {
      $("#sidebarCollapse").on('click',function() {
        $("#sidebar").toggleClass('active');
      });
    });
  }
  onclicklogut = (logouttype) => {
		let currenttoken = ls.get('token');
		let currentemail = ls.get('email');
		let obj = [];
		if(logouttype === 'all'){
			obj = {
			  type: logouttype,
			  email: currentemail,
			};
		}else{
			obj = {
				type: logouttype,
				token: currenttoken,
			};

		}
		const axiosConfig = {
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*'
		};
		Axios.post(base_api_url + 'deleteloginattempt.php', obj, { axiosConfig }).then((res) => {
		  if (res.data.res_status == 200) {
			/*ls.remove('userid');
			ls.remove('email');
			ls.remove('name');
			ls.remove('role');
			ls.remove('token');
			ls.remove('membership');
			ls.remove('membership_status');
			ls.remove('profile');
      ls.remove('selectedtab');
      ls.remove('localCurrentSong');
      ls.remove('localMusicList');
      ls.remove('localIndex');*/
      ls.clear();
			this.props.history.push('/login');
		  }
		});
  };
  render() {
    return (
      <React.Fragment>
        <div className="main-sidemenu" id="sidebar">
          <div className="logo-menu">
            <NavLink to="/" className="">
              <img src={process.env.PUBLIC_URL + '/assets/logo/logo.png'} alt="Conqer" />
            </NavLink>
          </div>
          <div className="menuitems">
            <ul className="mainmenutims">
              <li>
                <NavLink to={'/listener-dashboard'}>
                  <i className="fa fa-dashboard"></i> Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to={'/listener-profile/' + this.state.token}>
                  <i className="fa fa-user"></i> Profile
                </NavLink>
              </li>
              <li>
                <NavLink to={'/artist-list'}>
                  <i className="fa fa-user-circle-o" aria-hidden="true"></i> Artist
                </NavLink>
              </li>
              <li>
                <NavLink to={'/playlist-listener/' + this.state.token}>
                  <img className="h-75 w-55" src="../assets/music/addplaylist2.png" style={{"width": "17px","marginLeft": "6px","marginRight":"2px"}}/> Playlist
                </NavLink>
              </li>
              <li>
                <NavLink to={'/listener-favoritesongs/'}>
                  <i className="fa fa-star"></i> Favorite Songs
                </NavLink>
              </li>
              <li>
                <NavLink to={'/listener-followers/' + this.state.token}>
                  <i className="fa fa-users"></i> Following
                </NavLink>
              </li>
              <li className="dropdown open">
                <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="fa fa-calendar"></i> Events <i className="fa fa-angle-right right rightanle"></i>
                </a>
                <ul className="dropdown-menu forAnimate" role="menu">
                  <li className="mainT">
                    <NavLink to={'/listener-myevents/' + this.state.token}>Event List</NavLink>
                  </li>
                  <li className="mainT">
                    <NavLink to={'/listener-eventbooking/'}>My Bookings</NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to={'/listner-prayer-line/' + this.state.token}>
                  <i className="fa fa-comment"></i> Prayer Line
                </NavLink>
              </li>
              <li>
                <NavLink to={'/edit-password/' + this.state.token}>
                  <i className="fa fa-lock"></i> Change Password
                </NavLink>
              </li>
              <li>
                <NavLink to={'/myloggedindevices/'}>
                  <i className="fa fa-laptop"></i> Logged In Devices
                </NavLink>
              </li>
			  <li>
                <a href="javascript:void(0)" onClick={() => this.onclicklogut('1')}>
                  <i className="fa fa-sign-out"></i> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(ListenerSideMenu);