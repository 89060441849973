import React, { Component } from 'react';
import Axios from 'axios';
import  { NavLink, Redirect } from 'react-router-dom';
import DOMPurify from "dompurify";
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import ISRCCode from '../components/isrccode.component';
import ArtistIRSCCode from '../components/artistisrccode.component';
import Select from 'react-select';
import ls from 'local-storage';
import $ from 'jquery';
const base_api_url = API_URL;

const axiosConfig = {
	  'Content-Type': 'multipart/form-data',
	  "Access-Control-Allow-Origin": "*",
};
const dtarray = [];
const errors = {};
export default class AddSong extends Component {
	
	constructor(props){
		super(props);
		this.onChangeReleaseType = this.onChangeReleaseType.bind(this);
		this.onChangeUploadCover = this.onChangeUploadCover.bind(this);
		this.onChangeSongTitle = this.onChangeSongTitle.bind(this); 
		this.onChangeSongVersion = this.onChangeSongVersion.bind(this);
		this.onChangeNewRelease = this.onChangeNewRelease.bind(this);

		this.onChangeMultiArtist = this.onChangeMultiArtist.bind(this);
		this.onChangeSongArtist = this.onChangeSongArtist.bind(this);
		this.onChangeSongGenre = this.onChangeSongGenre.bind(this);
		this.onChangeSongSubGenre = this.onChangeSongSubGenre.bind(this);
		this.onChangeSongCopyright = this.onChangeSongCopyright.bind(this);
		this.onChangeSongRecordLabel = this.onChangeSongRecordLabel.bind(this);
		this.onChangeLyrics = this.onChangeLyrics.bind(this);
		this.onChangeSongCode = this.onChangeSongCode.bind(this);
		this.onChangeUploadSong = this.onChangeUploadSong.bind(this);
		this.onChangeLengthMinute = this.onChangeLengthMinute.bind(this);
		this.onChangeLengthSecond = this.onChangeLengthSecond.bind(this);
		this.onChangeAlbum = this.onChangeAlbum.bind(this);
		this.onCheckISRC = this.onCheckISRC.bind(this);
		this.onViewISRC = this.onViewISRC.bind(this);
		this.onBlurISRCCode = this.onBlurISRCCode.bind(this);
		this.closeIsrcform = this.closeIsrcform.bind(this);
		
		this.onSubmit = this.onSubmit.bind(this);
		this.titleInput = React.createRef();
		this.versionInput = React.createRef();
		this.albumInput = React.createRef();
		this.releaseInput = React.createRef();
		this.lengthSecondInput 	= React.createRef();
		this.coverInput = React.createRef();
		this.codeInput = React.createRef();
		this.songInput = React.createRef();
		
		this.state = {
			distribution_title:'',
			loadISRC:'',
			release:'',
			uploadcover:'',
			song_title:'',
			song_version:'',
			song_release:1,
			multiartist:1,
			artist:'',
			genre:0,
			subgenre:0,
			song_copyright:'',
			song_label:'',
			song_code:'',
			song_file:'',
			lyrics:'',
			album:this.props.match.params.id,
			currentalbumid:this.props.match.params.id,
			reviewarray:[],
			error: null,
			errors: [],
			msg: null,
			successclass:null,
			redirect:false,
			releaselist:[],
			genrelist:[],
			subgenrelist:[],
			pendingsonglist:[],
			albumlist:[],
			sendreview:false,
			filesongerror:'',
			filecovererror:'',
			token:ls.get('token'),
			buttontext: 'Save Item',
			btndisable:'',
			selectedcover:'Choose File',
			selectedsong:'Choose File',		  
			isrcmsg:'',		  
			showISRCCheck:false,
			redirecttosong:false,
			loading:false,
			lengthminute:'',			
			lengthsecond:'',
			radioReading: true,
			radioPlan: false,
			radioMultiY: true,
			radioMultiN: false,
			usedFilter: 'All',
			isrclist: [],
		}		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Add song";		
		this.getreleasetype();
		this.getgenretype();
		this.getalbumlist();
		this.setState({album:this.props.match.params.id})
	}
	getallisrclist = () => {
    const obj = {
      userid: ls.get('userid'),
	  usedFilter:this.state.usedFilter
    };	
    Axios.post(base_api_url + 'myisrccode.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
			let pathnames ='';
			if(this.props.location === undefined){
				pathnames = '';
			}else{
				pathnames = this.props.location.pathname;
			}
			
			$('#myisrc').DataTable().destroy();
			$(document).ready(function () {
				setTimeout(function(){
					$('#myisrc').DataTable({
						"destroy": true,
						stateSave: true,
						"language": {
							"search": "Search"
						},
						"lengthMenu": [[5, 15, 25, 35, -1], [5, 15, 25, 35,  "All"]],
					});
				 } ,50);
			});
			const returndata = res.data.res_data;
			this.setState({
			  isrclist: returndata,
			  loading: false
			});
		  } else {
			this.setState({
			  loading: false
			});
		  }
    });
  };
	handleValidation = () => {
		let formIsValid = true;
		
		if(!this.state.song_version){
			formIsValid = false;
			errors["version"] = "Please enter song version.";
		}

		if(!this.state.song_title){
			formIsValid = false;
			errors["title"] = "Please enter song title.";
		}
		
		
		if(!this.state.lengthsecond){
			formIsValid = false;
			errors["lengtherror"] = "Please enter song length in minute & second.";
		}

		if(!this.state.album){
			formIsValid = false;
			errors["albumerror"] = "Please select album name.";
		}

		if(!this.state.release){
			formIsValid = false;
			errors["releaseerror"] = "Please select release type.";
		}

		if(!this.state.uploadcover){
			formIsValid = false;
			errors["covererror"] = "Please upload cover image.";
		}else if(!this.state.formValid){
			formIsValid = false;
		}

		if(!this.state.song_code){
			formIsValid = false;
			errors["isrcerror"] = "Please add ISRC code for song.";
		}

		if(!this.state.song_file){
			formIsValid = false;
			errors["songfileerror"] = "Please upload song file.";
		}else if(!this.state.formValid){
			formIsValid = false;
		}

		this.setState({errors: errors});
		
        return formIsValid;

	}

	getreleasetype = () => {
		const usertoken = this.state.token;
		
		const obj = {
			token:usertoken
		}
		
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		}
		
		Axios.post(base_api_url+'releasetype.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status == 200){
					this.setState({
						releaselist:res.data.res_data,
					});
				}
			}
		);
	}
	
	getalbumlist = () => {
		const obj = {
			userid:ls.get('userid'),
			myalbum:'myalbum'
		}
		
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		}
		
		Axios.post(base_api_url+'artistalbum-v2.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						albumlist:res.data.res_data,
					});
				}
			}
		);
	}
		
	getgenretype = () => {
		const usertoken = this.state.token;
		
		const obj = {
			token:usertoken,
			type:'web'
		}
		
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		}
		
		Axios.post(base_api_url+'genrelist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						genrelist:res.data.res_data,
					});
				}
			}
		);
	}
		
	onChangeUploadCover(e){
		this.setState({formValid: true });
		if(e.target.files[0]){
			if(this.state.errors['covererror']){delete this.state.errors['covererror'];}
			var validExt = ".png, .gif, .jpeg, .jpg";
			var filePath = e.target.files[0].name;
			var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			var pos = validExt.indexOf(getFileExt);
			var maxSize = '5024';
			var fsize = e.target.files[0].size/1024;
			//console.log(pos);
			/* if(pos < 0){
				this.setState({
					filecovererror : 'Please upload JPG or JPEG or PNG or GIF image only.',
					selectedcover:'', 
					uploadcover: ''
				});
			}else if(fsize > maxSize){
				this.setState({
					filecovererror : 'Please upload maximum 5MB.',
					selectedcover:'', 
					uploadcover: ''
				});
			}else{
				this.setState({selectedcover:e.target.files[0].name, uploadcover: e.target.files[0],filecovererror : '' });
			} */
			if(pos < 0){
				errors['covererror'] = "Sorry, " + filePath + " is invalid, allowed extensions are: " + validExt;
				this.setState({selectedcover:e.target.files[0].name, uploadcover: e.target.files[0], formValid: false });
			}else{
				this.setState({selectedcover:e.target.files[0].name, uploadcover: e.target.files[0]});
			}
			
		}else{
			this.setState({
				uploadcover:''
			})
		}
	}
	
	onBlurISRCCode(e) {
		let iscode = e.target.value;
		let isrcErr = [];
		if(iscode.length >0){
			const obj = {
				code: e.target.value,
				userid:ls.get('userid')
			}

			Axios.post(base_api_url + 'isrccode_exist.php', obj)
				.then(
					(res) => {
						if (res.data.res_status == 200) {
							isrcErr['isrcerror'] = res.data.res_message;
							this.setState({
								errors:isrcErr
							});
						} else {
							isrcErr['isrcerror'] = res.data.res_message;
							this.setState({
								song_code: '',
								errors:isrcErr
							});
						}
					}
				);
		}else{
			isrcErr['isrcerror'] = 'Please enter ISRC code!';
			this.setState({
				song_code: '',
				errors:isrcErr
			});
		}
	}
	
	closeIsrcform() {
		this.setState({
			showISRCCheck:false,
			refresh:0
		});
	}
	
	onChangeUploadSong(e){
		if(this.state.errors['songfileerror']){delete this.state.errors['songfileerror'];}
		this.setState({formValid: true });
		if(e.target.files.length >0){
			var validExt = ".mp3";
			var filePath = e.target.files[0].name;
			var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			var pos = validExt.indexOf(getFileExt);
			var maxSize = '10024';
			var fsize = e.target.files[0].size/1024;

			if(pos < 0){
				errors['songfileerror'] = "Sorry, " + filePath + " is invalid, allowed extensions are: " + validExt;
				this.setState({selectedsong:e.target.files[0].name, song_file: e.target.files[0], formValid: false });
			}else{
				this.setState({selectedsong:e.target.files[0].name, song_file: e.target.files[0],filesongerror:'' });
			}
			//this.setState({selectedsong:e.target.files[0].name, song_file: e.target.files[0],filesongerror:'' });
		}
		
	}
	
	onChangeReleaseType(e){
		if(this.state.errors['releaseerror']){delete this.state.errors['releaseerror'];}
		this.setState({
		  release: e.target.value
		});		
	}
	
	onChangeSongTitle(e){
		if(this.state.errors['title']){delete this.state.errors['title'];}
		this.setState({
		  song_title: e.target.value,		  	  
		});	
			
	}
	
	onChangeSongVersion(e){
		if(this.state.errors['version']){delete this.state.errors['version'];}
		this.setState({
		  song_version: e.target.value
		});
	}
	
	onChangeNewRelease(e){
		let releaseValue = e.target.value;
		if(releaseValue === '1'){
			this.setState({
				radioReading:true,
				radioPlan:false
			});
		}else{
			this.setState({
				radioReading:false,
				radioPlan:true
			});
		}
		this.setState({
		  song_release: e.target.value
		});
	}
	
	onChangeMultiArtist(e){
		let multiAValue = e.target.value;
		if(multiAValue === '1'){
			this.setState({
				radioMultiY:true,
				radioMultiN:false
			});
		}else{
			this.setState({
				radioMultiY:false,
				radioMultiN:true
			});
		}
		this.setState({
		  multiartist: e.target.value
		});
	}
	
	onChangeSongArtist(e){
		this.setState({
		  artist: e.target.value
		});
	}
	
	onChangeSongGenre(e){
		let subGList = [];
		let gList = this.state.genrelist;
		 gList.map((result) => {
		 	if(result.parent_id == e.target.value){
		 		subGList.push(result);
		 	}
		 });
		this.setState({
			subgenrelist:subGList,
		  	genre: e.target.value
		});
	}
	
	onChangeSongSubGenre(e){
		this.setState({
		  subgenre: e.target.value
		});
	}
	
	onChangeSongCopyright(e){
		this.setState({
		  song_copyright: e.target.value
		});
	}
	
	onChangeSongRecordLabel(e){
		this.setState({
		  song_label: e.target.value
		});
	}
	
	onChangeSongCode(e){
		if(this.state.errors['isrcerror']){delete this.state.errors['isrcerror'];}
		this.setState({
		  song_code: e.target.value
		});
	}
	
	onChangeLyrics(e){
		this.setState({
		  lyrics: e.target.value
		});
	}
	
	onChangeLengthMinute(e){
		if(this.state.errors['lengtherror']){delete this.state.errors['lengtherror'];}
		this.setState({
		  lengthminute: e.target.value
		});
	}
	onChangeMinute(e){
		let minuteVal 	= e.target.value;
		if(minuteVal){
			let minuteValue = (minuteVal.toString().length < 2) ? "0"+minuteVal : minuteVal;
			this.setState({
			  lengthminute: minuteValue,
			  lengthsecond: '00'
			});
		}
	}
	onChangeLengthSecond(e){
		if(this.state.errors['lengtherror']){delete this.state.errors['lengtherror'];}
		this.setState({
		  lengthsecond: e.target.value
		});
	}
	onChangeSecond(e){
		let secondVal 	= e.target.value;
		if(secondVal){
			let secondValue = (secondVal.toString().length < 2) ? "0"+secondVal : secondVal;
			this.setState({
			  lengthsecond: secondValue
			});
		}
	}
	onChangeAlbum(e){
		if(this.state.errors['albumerror']){delete this.state.errors['albumerror'];}
		this.setState({
		  album: e.target.value
		});
	}
	
	
	onCheckISRC(e){
		let isChecked = e.target.checked;
		if(isChecked){
			$('#myModal').modal('show');
		}
	}
	
	onViewISRC(load){
		if(load === '1'){
			this.setState({
				loadISRC:'1',
				loading:true
			})
			$('#viewISRC').modal('show');
			this.getallisrclist();
		}
		
	}
	changeUsedStatus = (e) => {
		this.setState({ usedFilter: e.target.value, loading:true }, () => {
		  this.getallisrclist();
		});
	};
	onSubmit(e) {
		e.preventDefault();
		this.setState({
			loadinng:true,
			filesongerror:''
		});
		if(this.handleValidation()){
			const formData = new FormData();
			const usertoken = this.state.token;
			const user_id = ls.get('userid');
			if(this.state.uploadcover!=null){		
				formData.append("cover", this.state.uploadcover, this.state.uploadcover.name);
			}
			
			if(this.state.song_file!=null){		
				formData.append("songfile", this.state.song_file, this.state.song_file.name);
			}
					
			formData.append("distribution_title", this.state.distribution_title);
			formData.append("title", this.state.song_title);
			formData.append("lyrics", this.state.lyrics);
			formData.append("release_type", this.state.release);
			formData.append("list_artist", this.state.artist);
			formData.append("version", this.state.song_version);
			formData.append("is_new_release", this.state.song_release);
			formData.append("is_multiartist", this.state.multiartist);
			formData.append("genre_id", this.state.genre);
			formData.append("sub_genre_id", this.state.subgenre);
			formData.append("copyright", this.state.song_copyright);
			formData.append("record_label", this.state.song_label);
			formData.append("ISRC_code", this.state.song_code);		
			formData.append("song_length_minute", this.state.lengthminute);
			formData.append("song_length_second", this.state.lengthsecond);
			formData.append("album_id", this.state.album);
			formData.append("user_id", user_id);
			
			Axios.post(base_api_url+'add-song.php', formData, {axiosConfig})
			.then(
				(res) => {window.scrollTo(0, 0);
					if(res.data.res_status==200){
						
						this.setState({
							successclass:'successmsg',
							msg:'Song uploaded successfully!',
							buttontext : 'Save Item',
							btndisable:'',
							redirecttosong:true,
							loadinng:false
						});
						
					}else{
						
						this.setState({
							successclass:'errormsg',						
							msg:'Song not uploaded, please try again!',
							buttontext : 'Save Item',
							btndisable:'',
							loadinng:false
						})
						setTimeout(() => {
							this.setState({
								successclass:null,
								msg:null,
								btndisable:'',
								loadinng:false
							});
						}, 10000)
					}
				}
			);
			this.myFormRef.reset();
			this.setState({
				distribution_title:'',
				release:'',
				uploadcover:'',
				song_title:'',
				song_version:'',
				song_release:'',
				multiartist:'',
				artist:'',
				genre:'',
				subgenre:'',
				song_copyright:'',
				song_label:'',
				song_code:'',
				song_file:'',
				lyrics:'',
				length:'',
				album:'',
				lengthminute:'',
				lengthsecond:'',
				errors:{},
				selectedcover:'Choose File',
				selectedsong:'Choose File'
			});
		}else{
			this.setState({
				loadinng:false
			})
		}	
	}
	
	render() {
		
		let selectedalid = this.props.match.params.id;
		if(this.state.loadinng === true){
			return <Spinner />;
		}
		if (this.state.redirecttosong) {
			let redirecturl = '/my-songs/'+selectedalid;
			return <Redirect to={{
				pathname: redirecturl,
				state: { message: this.state.msg }
			}} />
			// return <Redirect to={redirecturl}  />
		}
		let checkedisrc = null;
		if(!this.state.showISRCCheck){
			checkedisrc = false;
		}
		
		let radioReadingChecked = (this.state.radioReading)?'checked':'';
		let radioPlanChecked 	= (this.state.radioPlan)?'checked':'';
		let radioMultiYChecked 	= (this.state.radioMultiY)?'checked':'';
		let radioMultiNChecked 	= (this.state.radioMultiN)?'checked':'';
		
        return (
			<React.Fragment>
			  <div className={this.state.successclass}>{this.state.msg}</div>
			  <div className="row">
				<div className="col-md-12">
					<div className="box box-success distributions-profileform">
						<div className="box-header with-border addprofilenew">
						  <h3 className="box-title">Add Song</h3>
						  <div className="box-tools">
							  	<NavLink to={'/my-songs/'+selectedalid} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>
							</div>
						</div>
						<div className="box-body no-padding">
						  <form className="artist-form" ref={(el) => this.myFormRef = el} name="addsong" id="addsong" onSubmit={this.onSubmit}>
						  <div className="row">
							<div className="col-md-12">
							  <div className="sectionhead">
								<div className="headsec">
								  <h3>General Information</h3>
								</div>
							  </div>
							</div>
						  </div>
						  <div className="row">
							<div className="col-md-6">
							  <div className="form-group">
								<label>Album<span className="requiredastrik">*</span></label>
								<select className="custom-select" ref={this.albumInput} name="album" id="album" onChange={this.onChangeAlbum}>
									<option value="">--Select album--</option>
									{this.state.albumlist.map(item => {
										return (<option value={item.id} selected={(selectedalid===item.id)?'selected':''} >{item.name}</option>)
									})}
								</select>
								<font className="errorMsg">{this.state.errors["albumerror"]}</font>
							  </div>
							</div>
							<div className="col-md-6">
							  <div className="form-group">
								<label>Release Type<span className="requiredastrik">*</span></label>
								<select className="custom-select" ref={this.releaseInput} name="release_type" id="exampleSelectRounded0" onChange={this.onChangeReleaseType}>
								  <option value="">--Select release type--</option>
								  {this.state.releaselist.map(item => { return (<option value={item.key}>{item.value}</option>)})}
								</select>
								<font className="errorMsg">{this.state.errors["releaseerror"]}</font>
							  </div>
							</div>
						  </div>

						  <div className="row">
							<div className="col-md-6">
							  <div className="form-group">
									<label htmlFor="coverpic">Upload Cover<span className="requiredastrik">*</span></label>
									<input type="file" ref={this.coverInput} className="form-control" id="coverpic" onChange={this.onChangeUploadCover} accept="image/*" />
									<font className="errorMsg">{this.state.errors["covererror"]}</font>
									<font className="errorMsg">{this.state.filecovererror}</font>
							  </div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Length<span className="requiredastrik">*</span></label>
									<div className="row">
										<div className="col-md-6">
											<input type="number" value={this.state.lengthminute} onBlur={this.onChangeMinute.bind(this)} id="lengthminute" name="lengthminute" onChange={this.onChangeLengthMinute} min="0" className="form-control" placeholder="minute" />
										</div>
										<div className="col-md-6">
											<input type="number" ref={this.lengthSecondInput} onBlur={this.onChangeSecond.bind(this)} value={this.state.lengthsecond} onChange={this.onChangeLengthSecond} id="lengthsecond" name="lengthsecond" min="0" max="60" className="form-control" placeholder="second" />
										</div>
									</div>
									<font className="errorMsg">{this.state.errors["lengtherror"]}</font>
								</div>
							</div>
						  </div>

						  <div className="row">
							<div className="col-md-12">
							  <div className="sectionhead">
								<div className="headsec">
								  <h3>Song Information</h3>
								</div>
							  </div>
							</div>
						  </div>
						  <div className="row">
							<div className="col-md-6">
							  <div className="form-group">
								 <label>Title<span className="requiredastrik">*</span></label>                          
								 <input type="text" ref={this.titleInput} id="title" className="form-control" value={this.state.song_title} onChange={this.onChangeSongTitle} />
								 <font className="errorMsg">{this.state.errors["title"]}</font>
							  </div>
							</div>
							<div className="col-md-6">
							  <div className="form-group">
								<label>Version<span className="requiredastrik">*</span></label>                          
								<input type="text" ref={this.versionInput} name="version" id="version" className="form-control" value={this.state.song_version} onChange={this.onChangeSongVersion} />
								<font className="errorMsg">{this.state.errors["version"]}</font>
							  </div>
							</div>
						  </div>
						  
						  <div className="row">
							<div className="col-md-6">
							  <div className="form-group">
								<label htmlFor="is_new_release">Is This A New Release?</label><br />
								<div className="custom-control custom-radio custom-control-inline">
								  <input type="radio" id="newrelease" name="customRadioInline1" checked={radioReadingChecked} value="1" onChange={this.onChangeNewRelease} className="custom-control-input" />
								  <label className="custom-control-label" htmlFor="newrelease">Yes</label>
								</div>
								<div className="custom-control custom-radio custom-control-inline">
								  <input type="radio" id="newrelease2" value="0" onChange={this.onChangeNewRelease} name="customRadioInline1" checked={radioPlanChecked} className="custom-control-input" />
								  <label className="custom-control-label" htmlFor="newrelease2">No</label>
								</div>
							  </div>
							</div>
							<div className="col-md-6">
							  <div className="form-group">
								<label htmlFor="is_multiartist">Is This Multi-Artist Complilation?</label><br />
								<div className="custom-control custom-radio custom-control-inline">
								  <input type="radio" id="multiartist1" value="1" checked="checked" name="multiartist" className="custom-control-input" onChange={this.onChangeMultiArtist} checked={radioMultiYChecked} />
								  <label className="custom-control-label" htmlFor="multiartist1">Yes</label>
								</div>
								<div className="custom-control custom-radio custom-control-inline">
								  <input type="radio" id="multiartist2" value="0" name="multiartist" className="custom-control-input" onChange={this.onChangeMultiArtist} checked={radioMultiNChecked} />
								  <label className="custom-control-label" htmlFor="multiartist2">No</label>
								</div>
							  </div>
							</div>
						  </div>
						  <div className="row">
							<div className="col-md-12">
							  <div className="form-group">
								 <label>List Artist (Seperate by Comma)</label>
								 <input type="text" className="form-control" name="list_artist" id="list_artist" value={this.state.song_artist} onChange={this.onChangeSongArtist} />
							  </div>
							</div>                    
						  </div>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label>Genre</label>
										<select className="custom-select" name="genre" id="genre" onChange={this.onChangeSongGenre}>
											<option value="">--Select genre--</option>
											{this.state.genrelist.map(item => {
												if(item.parent_id == '0'){
													return(<option value={item.id}>{item.name}</option>)
												}
											})}											
										</select>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label>Subgenre</label>
										<select className="custom-select" name="subgenre" id="subgenre" onChange={this.onChangeSongSubGenre}>
											<option value="">--Select subgenre--</option>
											{this.state.subgenrelist.map(item => {
												return (<option value={item.id}>{item.name}</option>)
											})}
										</select>
									</div>
								</div>
						  	</div>
						  	<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label>Copyright</label>                          
										<input type="text" value={this.state.song_copyright} id="copyright" onChange={this.onChangeSongCopyright} className="form-control" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label>Record Label</label>                          
										<input type="text" className="form-control" id="recordlabel" value={this.state.song_label} onChange={this.onChangeSongRecordLabel} />
									</div>
								</div>								
						  </div>

						  <div className="row">
							<div className="col-md-12">
							  <div className="form-group">
								 <label>ISRC CODE (A fee will apply if you don't have one and CONQER provides it)<span className="requiredastrik">*</span> </label> 
								 <label className="pull-right"><a href="javascript:void(0);" onClick={() => this.onViewISRC('1')}>View ISRC Code</a></label>                        
								 <input type="text" ref={this.codeInput} className="form-control" id="isrc" value={this.state.song_code} onChange={this.onChangeSongCode}  onBlur={this.onBlurISRCCode} maxLength="12"/>
								 <label htmlFor="isrccheck" className="mt-2"><input type="checkbox" ref="usertype" id="isrccheck" className="htlanonymus" name="isrccheck" value="1" onChange={this.onCheckISRC} checked={(!checkedisrc)?'':'checked'} /> Click here to get ISRC Code!</label>
								 <p className="errorMsg">{this.state.errors["isrcerror"]}</p>
								 <p className="errorMsg">{this.state.isrcmsg}</p>
							  </div>
							</div>                    
						  </div>

						  <div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="songfile">Upload Song<span className="requiredastrik">*</span></label>                          
									<input type="file" ref={this.songInput} name="songfile" id="songfile" className="form-control" onChange={this.onChangeUploadSong} accept=".mp3,audio/*"/>
									<font className="errorMsg">{this.state.errors["songfileerror"]}</font>
									<font className="errorMsg">{this.state.filesongerror}</font>
								</div>
							</div>
						  </div>

						  <div className="row">
							<div className="col-md-12">
							  <div className="form-group">
								<label>Lyrics</label>                          
								<textarea className="form-control rounded-0" id="lyrics" onChange={this.onChangeLyrics} rows="3"></textarea>
							  </div>
							</div>
						  </div>

						  <div className="row">
							<div className="col-md-12 notmainbtn">
							<button type="submit" disabled={this.state.btndisable} className="btn btn-primary">{this.state.buttontext}</button>
							</div>
						  </div>
						  </form>						  
						</div>
					</div>
				</div>        
			  </div>
			  <div id="myModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
					<ISRCCode currentalid={selectedalid} codelist="0"/>
				</div>
				<div id="viewISRC" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog">
						<div className="modal-content" style={{"padding-top":"30px"}}>
							<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true" onClick={this.closeIsrcform}>&times;</span></button>
							<div className="modal-body">
								<div className="row">								
			  <div className="col-md-12">
				<div className="box box-success distributions-profileform">
				  <div className="box-header with-border addprofilenew">
					<h3 className="box-title">ISRC Code</h3>
					<div className="box-tools d-flex justify-content-between">
						<select
						className="filteroptionselect"
						as="select"
						value={this.state.usedFilter}
						onChange={(e) => this.changeUsedStatus(e)}
						style={{ width: '10vw' }}
						>
							<option value="All">All</option>
							<option value="Yes">Used</option>
							<option value="No">Not Used</option>
						</select>
					</div>
				  </div>
				  {(this.state.loading == true)? <Spinner />:
				  <div className="box-body no-padding">
					  <div className="table-responsive">
						<table id="myisrc" className="table table-striped table-bordered table-hover table-sm row-border hover">
						  <thead>
							<tr>
							  <th>Title</th>
							  <th>ISRC Code</th>
							  <th>Used</th>
							</tr>
						  </thead>
						  <tbody>
							{this.state.isrclist.map((result, key) => {
							  return (
								<tr>
								  <td>{result.title}</td>
								  <td>{result.isrc_code}</td>
								  <td>{result.used_isrc}</td>
								</tr>
							  );
							})}
						  </tbody>
						</table>
					</div>
				  </div>
					}
				</div>
			  </div>
			</div>
							</div>            
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
