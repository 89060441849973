import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';

const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
const _data = [];
export default class topSongs extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
		  email:'',
		  role:null,
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  topartists:[],
		  topsongs:[],
		  topsong:[],
		  loading:true,
		  successclass:null,
		  redirect:false,
		  song_name: '',
		  type:'all',
		  page: 0,
	      limit: 25,
	      prevY: 0,
	      hide:'block',
	      iteration: 1,
	      label:'none',
	      loader: 'none'
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - My Music";
		
		const email = ls.get('email');
		const name = ls.get('name');
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({
			email:email,
			name:name,
			token:token,
			userid:userid
		});		
		this.getTopSongs(this.state.page, this.state.limit);	
		var options = {
	      root: null,
	      rootMargin: "0px",
	      threshold: 1.0
	    };
	    this.observer = new IntersectionObserver(
	      this.handleObserver.bind(this),
	      options
	    );    
	    this.observer.observe(this.loadingRef);	
	}
	handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if(this.state.type === 'all'){
      if (this.state.prevY > y) {
        const lastArtist = this.state.topsongs.length;
        const curPage = lastArtist;
        this.getTopSongs(curPage,25);
        this.setState({ page: curPage, iteration: this.state.iteration+1 });
      }
      this.setState({ prevY: y });
    }
  }
	getTopSongs = (page, limit) => {
		this.setState({'type':'all', loading: false, label:'none'});
		Axios.post(base_api_url+`gettopsongs-v2.php?_page=${page}&_limit=${limit}`)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.user_data;
					//, totalSongs: res.data.totalAlbum
					this.setState({ topsongs: [...this.state.topsongs, ...res.data.user_data], totalSongs: res.data.totalSongs, loading: false, hide:'block', label:'block', loader:'none'});
				}else{
					this.setState({
						loading:false,
						loader:'none'
					});
				}
			}
		);
	}
	songName(e){
		if(e.target.value){
			this.setState({song_name:e.target.value});
		}else{
			this.setState({
		        song_name:'',
		        topsongs:[],
		        type:'all',
		        loader:'block'
		    },()=>this.getTopSongs(0, this.state.limit*this.state.iteration));
			
		}		
	}
	searchSongs = (e) => {
		this.setState({topsong: [], 'type':'search', label: 'none', loader:'none'});
		//let topsong = [];
		let n = this.state.song_name;
		if(n){
			/*this.state.topsongs.map((results) => {
				if((results.title).match(new RegExp(n,'ig'))){
					_data.push(results);				
				}
			});
			this.setState({
				topsong:_data,
				loading:false,
        		hide:'none'
			});*/
			let obj = {
				key: n
			}
			Axios.post(base_api_url+`gettopsongs-v3.php`,obj)
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							topsong:res.data.user_data,							
							loader:'none',
        					hide:'none',
        					label:'block'
						})
					}else{
						this.setState({topsong: [], hide:'none', loading:false, label: 'block',loader:'none' });
					}
				}
			);
		}else{
			this.setState({'type':'all', hide:'block',loader:'none'});
		}
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	handleKeyPress = (event) => {
	  	if(event.key === 'Enter'){
	    	this.searchSongs()
	  	}
	}
	render() {
		let songs 	= (this.state.type == 'all')?this.state.topsongs:this.state.topsong;
		const loadingTextCSS = { display: (this.state.totalSongs == songs.length) ? "none" : "block" };
        if(this.state.loading==true){
			//return <Spinner />;
		}
		let maxlimit = '15';
		return (
			<React.Fragment>	
				<div className={this.state.successclass}>{this.state.msg}</div>
					<React.Fragment>
						<div className="row topfivesongsd">
							<div className="col-md-12">
								<div className="todpfive">Top Songs 
									<span className="rightsidetext">
										<span className="navbar-search col"style={{'float':'left'}}>
								            <label className="sr-only screen-reader-text visually-hidden" for="search">Search for:</label>
								            <div className="input-group">
								            	<div className="input-search-field">
								            		<input type="text" id="search" className="form-control search-field product-search-field" dir="ltr" onChange={this.songName.bind(this)} placeholder="Search Songs" onKeyPress={this.handleKeyPress} autocomplete="off" />
								            	</div>
								            	<div className="input-group-btn">
										            <button type="submit" onClick={() => this.searchSongs()} className="btn btn-secondary"><i className="fa fa-search"></i></button>
								            	</div>
								            </div>
								        </span>	
										<Link to="/listener-dashboard/" className="btn btn-primary mb-3">
											<i className="fa fa-angle-double-left" aria-hidden="true"></i>Back
										</Link>
									</span>
								</div>
							</div>
						</div>
						<div className="row artisitblock allartistsongs">
							{(songs.length)?songs.map((results) => {
								// console.log('results',results);
								let topsongurl = '/topssongs/'+results.song_id;
								let stitile = results.title;
								return (
									<div className="col-md-3">
										<Link to={topsongurl}>
										<div className="featureartprifles">
											{(results.is_featured_song == "1")?<span className="__featured_artist">Featured</span>:""}
										   <div className="featrueimages">
											  <img onError={this.addDefaultSrc} src={results.thumb_banner_pic} />
										   </div>
										   <div className="featurestext">
											  <h3 title={stitile}>{ (stitile.length > maxlimit) ? (((stitile).substring(0,maxlimit-3)) + '...') :stitile }</h3>
											  <span className="nooffollowers songlikes"><i className="fa fa-heart"></i> {results.slike}</span>
											  <span className="nooffollowers playicons"><i className="fa fa-play-circle"></i> {results.slistened}</span>
										   </div>
										</div>
										</Link>
									</div>
								)
								}):<div className="not-found" style={{"display":this.state.label}}><span>No song found!</span></div>
							}
						</div>
						<span style={{"display":this.state.loader}}><div className="col-md-12" style={{fontSize:'24px', textAlign:'center'}}><i class="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i> Please Wait...</div></span>
						<div ref={loadingRef => (this.loadingRef = loadingRef)} style={{"display":this.state.hide}}>
					    	<span style={loadingTextCSS}><div className="col-md-12" style={{fontSize:'24px', textAlign:'center'}}><i class="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i> Please Wait...</div></span>
					    </div>
					</React.Fragment>
			</React.Fragment>
		)
	}
}