import React, { Component } from 'react';
import  { Link, Redirect  } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  "Access-Control-Allow-Origin": "*",
}
const mapStyles = {
  width: '100%',
  height: '350px'
};
toast.configure();
export class webEventDetail extends Component {
  constructor(props){
    super(props);
    
    this.state = {      
      loading:true,
      eventData:'',
      redirect:false,
  	}
  }
  	onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });
  	componentDidMount(){
		document.title = "CONQER Music - Event Detail";    
		this.getEventDetail();
	}
	addDefaultSrc = (ev) => {
	    ev.target.src = defaultimg
	}
  alertMessage(type){
    if(type === 'not_start'){
      toast.error('Ticket booking not started yet.');
    }else{
      toast.error('Ticket booking are closed.');
    }
  }
	getEventDetail(){
		const eventid = this.props.match.params.id;
		const obj = {
	      event_id: eventid
	    }
	    Axios.post(base_api_url + 'get-events-detail.php', obj, { axiosConfig })
      	.then(
        (res) => {
          if (res.data.res_status == 200) {
          	this.setState({
          		eventData: res.data.res_data
          	});
          }else{
			  this.setState({
				  redirect:true
			  })
		  }
          this.setState({
          	loading:false,
          	redirect:false
          });
      	}
      )
	}
	render() {
		if(this.state.redirect==true){
			return <Redirect to='/events' />;
		}
		if(this.state.loading == true){
			return <Spinner />;
		}
		return (
			<React.Fragment>
				<section id="eventmap" style={{position:' relative',top: '125px'}}>
					<div class="event-locations">
					  	<Map google={this.props.google}
					  		style={mapStyles}
                            className={'map'}
                            zoom={17}
                            initialCenter={{lat: this.state.eventData.lat, lng: this.state.eventData.lng}}
                        >
                        <Marker
                        	onClick={this.onMarkerClick}
                            position={{lat: this.state.eventData.lat, lng: this.state.eventData.lng}} />
                        <InfoWindow
                        	marker={this.state.activeMarker}
                          	onClose={this.onInfoWindowClose}
                          	visible={this.state.showingInfoWindow}
                        >
                            <div>
                                <p>{this.state.eventData.event_name}</p>
                            </div>
                        </InfoWindow>
                     	</Map>
					</div>
				</section>
				<section id="eventweb"  style={{top:'420px',position:'relative'}}>
<div class="eventfront">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="sectionbox viewevnts">
<div class="row">  
    <div class="col-md-8">
      <div class="headeventname">
        <h2>{this.state.eventData.event_name}</h2>
        <p>{this.state.eventData.description}</p>       
      </div>
      <div class="eventseprates">
            <div class="datetime-aftersearch">
              <div class="event-times icons">
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </div>
              <p>{this.state.eventData.event_start_date+' - '+this.state.eventData.event_end_date}<br />{this.state.eventData.event_start_time+' - '+this.state.eventData.event_end_time}</p>
            </div>
      </div>
      <div class="eventseprates">
      <div class="datetime-aftersearch">
              <div class="event-times icons">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <p>{(this.state.eventData.address)?this.state.eventData.address+' '+this.state.eventData.city:''}<br />{(this.state.eventData.state)?this.state.eventData.state+', '+this.state.eventData.country+' '+this.state.eventData.zipcode:''}</p>
            </div>
          </div>
          <div class="eventseprates">
            <div class="datetime-aftersearch">
            <div class="event-times icons">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            <p>{this.state.eventData.artist_name}</p>
          </div>
          </div>
           <div class="eventseprates">
            <div class="datetime-aftersearch">
            <div class="event-times icons">
              <i class="fa fa-exclamation" aria-hidden="true"></i>
            </div>
            <h4 class="pull-left">{(this.state.eventData.available)?'Seats Available':'Not Available'}</h4>
          </div>
           </div>
           <div class="registertoeventbtns">
           {(this.state.eventData.available)?(this.state.eventData.booking_start)?(this.state.eventData.booking_end)?<Link to="#" class="btn btn-primary" onClick={() => this.alertMessage("end")}>Book Ticket</Link>:
            <Link to={'/event-booking/'+this.state.eventData.id} class="btn btn-primary">Book Ticket</Link>:<Link to="#" class="btn btn-primary" onClick={() => this.alertMessage("not_start")}>Book Ticket</Link>
        	:''}
           </div>
    </div>
    <div class="col-md-4"> 
      <div class="viewimageevent">
        <img src={this.state.eventData.thumb_event_image} onError={this.addDefaultSrc}/>
      </div>
    </div>
  
  
</div>
      </div>
      </div>
    </div>
  </div>
</div>
</section>
			</React.Fragment>
		)
	}
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0'
})(webEventDetail);