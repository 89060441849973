import React, { Component } from 'react';
import { Row, Table } from 'react-bootstrap';
import ls from 'local-storage';
import { Link, NavLink } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';

const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
export default class playListDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playListData: [],
      loading: true,
      filterType:'all'
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Playlist';
    this.playListData();
  }

  deletePlayList = async (delId) => {
    const obj = { userid: ls.get('userid'), play_list_id: delId };

    if (window.confirm('Do you want to delete the playlist?')) {
      const response = await Axios.post(base_api_url + 'deleteplaylist.php', obj, axiosConfig);

      if (response.data.res_status === '200') {
        this.setState({ successclass: 'successmsg', msg: 'Playlist deleted successfully' });
        this.playListData();
        setTimeout(() => {
          this.setState({ successclass: 'hideclass' });
        }, 3000);
      }
    }
  };

  playListData = async () => {
    const obj = {
      userid: ls.get('userid'),
      type: this.state.filterType
    };
    const response = await Axios.post(base_api_url + 'getplaylist.php', obj, axiosConfig);
    $('#myevents2').DataTable().destroy();
    setTimeout(() => {
      $('#myevents2').DataTable({
        destroy: true,
        pageLength: 10,
		language: {
			search: "Search",
			emptyTable: "No data found!"
		},
		stateSave: true,
        order: [[1, 'desc']],
        lengthMenu: [
          [5, 10, 20, 50, -1],
          [5, 10, 20, 50, 'All'],
        ],
        columnDefs: [{ orderable: false, targets: -1 }],
      });
    }, 500);

    if (response.data.res_status === '200') {
      this.setState({ playListData: response.data.res_data, loading: false });
    } else {
      this.setState({ playListData: [] });
    }
  };
  filterPlaylist(e){
    this.setState({ filterType: e.target.value }, () => {
      this.playListData();
    });
  }
  render() {
    const playListDataArray = this.state.playListData;
    let userid 		= ls.get('userid');
    let userrole 	= ls.get('role');
    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : (
          
          <div className="row">          
            <div className="col-md-12">
            <div className={this.state.successclass}>{this.state.msg}</div>
              <div className="box box-success distributions-profileform">
                <div className="box-header with-border addprofilenew playlsitsongf">
                  <h3 className="box-title">Playlists</h3>
                  <div className="box-tools saearchicons">
					<select className="filteroptionselect" style={{'width':'30vh'}} onChange={this.filterPlaylist.bind(this)}>
						<option value="all">All Playlist</option>
						<option value="my">My Playlist</option>
						<option value="public">Public Playlist</option>
					</select>
                    <Link to={{ pathname: '/add-playlist/' + ls.get('token'), state: { prevPath: this.props.location.pathname } }} className="btn btn-primary" >
                      <img className="asd" style={{"width":"20px"}} src="../assets/music/whiteplaylistin.png" /> Add New Playlist
                    </Link>
                  </div>
                </div>
                <div className="box-body no-padding">
                  <div className="table-responsive">
                    <table id="myevents2" className="table no-margin table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Playlist Title</th>
                          <th>Created By</th>
                          <th>Description</th>
                          <th style={{ textAlign: 'center' }}>Total Songs</th>
                          <th className="actionbtns text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {playListDataArray.map((item, index) => (
                          <tr key={index}>
                            <td>{item.title}</td>
                            <td>{item.created_by}</td>
                            <td>{item.description}</td>
                            <td style={{ textAlign: 'center' }}>{item.total_songs}</td>
                            <td className="actionbtns d-flex justify-content-left">
                            {item.total_songs > 0 ? (
                              <>
                                <Link to={`/playlist-songs/` + item.id} className="btn btn-info custombtn" title="See Songs">
                                  <i className="fa fa-music" aria-hidden="true"></i>
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link disabled className="btn btn-info custombtn" title="Songs Not available">
                                  <i className="fa fa-music" aria-hidden="true"></i>
                                </Link>
                              </>
                            )}
                            {(userid == item.user_id) ? (
                              <>
                              <Link
                                to={{ pathname: '/edit-playlist/' + item.id, state: { prevPath: this.props.location.pathname } }}
                                className="btn btn-info custombtn"
                                title="Edit"
                              >
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                              </Link>
                              <Link onClick={() => this.deletePlayList(item.id)} className="btn  btn-info custombtn" title="Delete Playlist">
                                <i className="fa fa-trash" aria-hidden="true"></i>
                              </Link>
                              </>
                              ):""

                            }
                              
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
