import React, { Component } from 'react';
import  { Link  } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  "Access-Control-Allow-Origin": "*",
}
export default class eventList extends Component {
  constructor(props){
    super(props);
    
    this.state = {      
      loading:true,
      eventList:[],
      checked: true,
      text: 'Upcoming Events',
      event_name: '',
	  event_cname: '',
      event_zipcode: '',
      event_aname: '',
      event_state: '',
      eventType: 'upcoming',
      loadMoreText: 'Load More',
      total_events: '0',
      disabled: false,
      preloader:true
    }
  }
	componentDidMount(){
		document.title = "CONQER Music - Events";
    this.getAllEventLists('upcoming');
	}
  getAllEventLists = (type) => {
    this.setState({preloader:true});
    const obj = {
      "event_type": type,
      "event_title": this.state.event_name,
	    "event_city": this.state.event_cname,
      "event_zipcode": this.state.event_zipcode,
      "event_aname": this.state.event_aname,
      "event_state": this.state.event_state,
    }
    
    Axios.post(base_api_url+'get-all-events.php?limit=0', obj, {axiosConfig})
    .then(
      (res) => {
        this.setState({preloader:false});
        if(res.data.res_status==200){
          const returndata = res.data.res_data;
          
          this.setState({
            eventList:returndata,
            loading:false,
            total_events: res.data.total_events
          });
        }else{
          this.setState({
            loading:false,
            total_events: 0
          });
        }
      }
    );
  }
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg
  }
  changeEventName(e) {
    this.setState({
      event_name: e.target.value
    });
  }
  changeEventCityName(e) {
    this.setState({
      event_cname: e.target.value
    });
  }
  changeEventZipcode(e) {
    const re = /^[0-9\b]+$/;
    if(re.test(e.target.value) || e.target.value == ''){
      this.setState({
        event_zipcode: e.target.value
      });
    }
    
  }
  changeEventAName(e) {
    this.setState({
      event_aname: e.target.value
    });
  }
  changeEventState(e) {
    this.setState({
      event_state: e.target.value
    });
  }
  onSubmit(e){
    e.preventDefault();
    this.getAllEventLists(this.state.eventType);
  }
  resetForm(){
    this.setState({preloader:true});
    const obj = {
      "event_type": this.state.eventType,
      "event_title": "",
	    "event_city": "",
      "event_zipcode": "",
      "event_aname": "",
      "event_state": "",
    }
    this.setState({
      event_name:'',
	  event_cname: '',
      event_zipcode: '',
      event_aname: '',
      event_state: '',
    });
    Axios.post(base_api_url+'get-all-events.php?limit=0', obj, {axiosConfig})
    .then(
      (res) => {
        this.setState({preloader:false});
        if(res.data.res_status==200){
          const returndata = res.data.res_data;
          
          this.setState({
            eventList:returndata,
            loading:false,
            total_events: res.data.total_events
          });
        }else{
          this.setState({
            loading:false,
            total_events: '0'
          });
        }
      }
    );
  }
  loadMore(page){
    this.setState({disabled:true});
    const obj = {
      "event_type": this.state.eventType,
      "event_title": this.state.event_name,
	    "event_city": this.state.event_cname,
      "event_zipcode": this.state.event_zipcode,
      "event_aname": this.state.event_aname,
      "event_state": this.state.event_state,
    }
    this.setState({ loadMoreText: 'Please wait...'});
    Axios.post(base_api_url+'get-all-events.php?limit='+page, obj, {axiosConfig})
    .then(
      (res) => {
        if(res.data.res_status==200){
          const returndata = res.data.res_data;
          
          this.setState({
            eventList:[...this.state.eventList, ...returndata],
            loading:false,
            loadMoreText: 'Load More',
            total_events: res.data.total_events
          });
        }else{
          this.setState({
            loading:false,
            total_events: '0'
          });
        }
        this.setState({disabled:false});
      }
    );
  }
  changeStatus(e) {
    if(e.target.checked){
      this.getAllEventLists('past');
      this.setState({
        text: 'Past Events',
        eventType: 'past'
      });
    }else{
      this.getAllEventLists('upcoming');
      this.setState({
        text: 'Upcoming Events',
        eventType: 'upcoming'
      });
    }
  }
	render() {
    if(this.state.loading==true){
      return <Spinner />;
    }
        return (
		<React.Fragment>
		<div id="slider-home">
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item">
          <img src="assets/slider/slide6.jpg" alt="..." />
          <div className="title">
            <h1 className="animated slideInDown">EVENTS <span></span></h1>
            <h2 className="animated slideInLeft">Founded on biblical principles.</h2>
          </div>
        </div>
        <div className="carousel-item active">
          <img src="assets/slider/slide6.jpg" alt="..." />
          <div className="title">
            <h1 className="animated slideInDown">EVENTS <span></span></h1>
          </div>
        </div>
      </div>
    </div>
</div>
<section id="eventweb">
<div className="eventfront">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sectionbox">          
        <div className="row">
          <form className="form-inline">
          <div className="col-md-4">
              <div className="form-group">
                  <input type="text" className="form-control" placeholder="Event Title" onChange={ this.changeEventName.bind(this)} value={this.state.event_name}/>
              </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
                  <input type="text" className="form-control" placeholder="City Name" onChange={ this.changeEventCityName.bind(this)} />
              </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
                  <input type="text" className="form-control" maxlength='6' placeholder="Zip Code" onChange={this.changeEventZipcode.bind(this)} value={this.state.event_zipcode} />
              </div>
          </div>        
          <div className="col-md-4">
            <div className="form-group">
                  <input type="text" className="form-control" placeholder="Artist Name" onChange={this.changeEventAName.bind(this)} />
              </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
                  <input type="text" className="form-control" placeholder="State" onChange={this.changeEventState.bind(this)} />
              </div>
          </div>          
          <div className="col-md-4">
            <div className="actionbtns eventfrontp">
              <button type="button" onClick={this.onSubmit.bind(this)} id="search_button" className="btn btn-primary" >Search</button>
              <button type="reset" onClick={this.resetForm.bind(this)} className="btn btn-secondary">Clear</button>
            </div>
          </div>
          
        </form>
        </div>


<div className="row">
  <div className="eventarea" style={{width:'100%'}}>
    <div className="col-md-12">
      <div className="headingevnt">
        <h3>{this.state.text}</h3>
        <div className="pull-right">
        <div className="onoffswitch4">
          <input type="checkbox" name="onoffswitch4" className="onoffswitch4-checkbox" id="myonoffswitch4" onChange={ this.changeStatus.bind(this) }/>
          <label className="onoffswitch4-label" for="myonoffswitch4">
              <span className="onoffswitch4-inner"></span>
              <span className="onoffswitch4-switch"></span>
          </label>
          </div>
      </div>
      </div>
    </div>
    {(this.state.preloader)?<div className="col-md-12" style={{fontSize:'24px', textAlign:'center'}}><i class="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i> Please Wait...</div>:
    <div className="col-md-12">
    	{(this.state.eventList.length)?this.state.eventList.map((result) => {
    return (<div className="eventlistings">
      	<div className="eventthumbnail">
        		<div className="pic-aftersearch"><img src={result.thumb_event_image} className="img-responsive " onError={this.addDefaultSrc} />
        		</div>
      	</div>
      	<div className="event-content">
      		<div className="row">
      			<div className="col-md-12">              
        			<h3 className="pull-left"><Link target="_blank" to={'event-detail/'+result.id}>
        				{result.event_name} </Link>
        			</h3>
        		</div>
      			<div className="col-md-6">
        			<div className="datetime-aftersearch">
        				<div className="event-times icons">
          				<i className="fa fa-calendar" aria-hidden="true"></i>
        				</div>
        				<p>{result.event_start_date} -<br />{result.event_end_date}<br />{result.event_start_time+' - '+result.event_end_time}</p>
        			</div>
      			</div>
      			<div className="col-md-6">
      				<div className="datetime-aftersearch">
        				<div className="event-times icons">
        					<i className="fa fa-map-marker" aria-hidden="true"></i>
        				</div>
        				<p>{(result.address) ? result.address+' '+result.city:'N/A'}<br />{(result.state)?result.state+', '+result.country+' '+result.zipcode:''}</p>
      				</div>
    			  </div>
      			<div className="col-md-6">
        			<div className="datetime-aftersearch">
        				<div className="event-times icons">
          				<i className="fa fa-user" aria-hidden="true"></i>
        				</div>
        			  <p>{result.artist_name}</p>
        			</div>
      		  </div>
        		<div className="col-md-6">
        			<div className="datetime-aftersearch">
          			<div className="event-times icons">
            			<i className="fa fa-exclamation" aria-hidden="true"></i>
          			</div><Link target="_blank" to={'/event-detail/'+result.id}>
          		  <h4 className="pull-left">{(result.available)?'Seats Available':'Not Available'}</h4></Link>
        		  </div>
        	  </div>
          </div>
        </div>
      </div>)
    }):
    <div className="eventlistings" style={{textAlign:'center'}}>No events found!</div>
  }
  {(this.state.eventList.length - parseInt(this.state.total_events))?<div className="additionloads"><button type="button" onClick={() => this.loadMore(this.state.eventList.length)} className="btn btn-primary" disabled={this.state.disabled} >{this.state.loadMoreText}</button></div>:''}
  
    </div>
}
    
    </div>
    </div>
      </div>
      </div>
    </div>
  </div>
</div>
</section>
		</React.Fragment>
		)
    }
}