import React, { Component } from 'react';
import Axios from 'axios';
import InputMask from 'react-input-mask';
import { API_URL } from '../../config/constants.js';
import { NavLink, Link } from 'react-router-dom';
import ls from 'local-storage';
import defaultimg from '../../components/default.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import $ from 'jquery';
import Spinner from '../../includes/Spinner.component';
import AdminHeader from './header.component';
import AdminTopHeader from './header-top.component';
const errors = {};
const base_api_url = API_URL;
const axiosConfig = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      };
export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.onSubmit 				= this.onSubmit.bind(this);

    this.state = {
      user: [],
      error: null,
      errors: [],
      msg: null,
      login_device: '',
      isrc_amount: '',
      song_paused_on: '',
      stripe_proceess_fee: '0'
    };
  }
	onChangeLogindevice(e) {
		if (this.state.errors['login_device_error']) {delete this.state.errors['login_device_error'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value) || (e.target.value > 0)) {
			this.setState({
				login_device: e.target.value
			})
		}else{
		  this.setState({
			login_device: ''
		  })
		}
	}
	onChangeSongPausedOn(e) {
		if (this.state.errors['song_paused_on_error']) {delete this.state.errors['song_paused_on_error'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value) || (e.target.value > 0)) {
			this.setState({
				song_paused_on: e.target.value
			})
		}else{
		  this.setState({
			song_paused_on: ''
		  })
		}
	}
	onChangeCodeAmount(e) {
		if (this.state.errors['isrc_amount_error']) {delete this.state.errors['isrc_amount_error'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value) || (e.target.value > 0)) {
			this.setState({
				isrc_amount: e.target.value
			})
		}else{
		  this.setState({
			isrc_amount: ''
		  })
		}
	}
	componentDidMount() {
		document.title = 'CONQER Music - Manage Setting';
		this.fetchUserRecord();
	}

	handleValidation = () => {
		let formIsValid = true;
		if(!this.state.login_device){
			formIsValid = false;
			errors["login_device_error"] = "Please enter number of login devices.";
		}else if(this.state.login_device < 1){
			formIsValid = false;
			errors["login_device_error"] = "Please enter minimum 1 login device.";
		}
		if(!this.state.isrc_amount){
			formIsValid = false;
			errors["isrc_amount_error"] = "Please enter amount of individual isrc code.";
		}else if(this.state.isrc_amount < 1){
			formIsValid = false;
			errors["isrc_amount_error"] = "Please enter minimum $1 amount.";
		}
		if(!this.state.song_paused_on){
			formIsValid = false;
			errors["song_paused_on_error"] = "Please enter amount of individual isrc code.";
		}else if(this.state.song_paused_on < 1){
			formIsValid = false;
			errors["song_paused_on_error"] = "Please enter minimum $1 amount.";
		}
		this.setState({ errors: errors });
		return formIsValid;
	};

  fetchUserRecord = () => {
    const obj = {
      user_id: this.props.match.params.id
    };

    Axios.post(base_api_url + 'settings.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        this.setState(
          {
            login_device: res.data.res_data.login_device,
            isrc_amount: res.data.res_data.isrc_amount,
            song_paused_on: res.data.res_data.song_paused_on,
            stripe_proceess_fee: res.data.res_data.stripe_proceess_fee,
          }
        );
      } else {
        this.setState({
          redirect: false,
          msg: null,
          successclass: null
        });
      }
      this.setState({
        loader: false
      });
    });
  };

  async onSubmit(e) {
    e.preventDefault();
    //if (this.handleValidation()) {
      this.setState({
        loader: true
      });
      const formData = new FormData();
      formData.append('login_device', this.state.login_device);	  
      formData.append('isrc_amount', this.state.isrc_amount);	  
      formData.append('song_paused_on', this.state.song_paused_on);  
      Axios.post(base_api_url + 'save-settings.php', formData, { axiosConfig }).then((res) => {
		window.scrollTo(0, 0);
        if (res.data.res_status == 200) {
          this.setState({
            successclass: 'successmsg',
            msg: res.data.res_message,
          });
          this.fetchUserRecord();
        } else {
          this.setState({
            successclass: 'errormsg',
            msg: res.data.res_message,
          });
        }
        setTimeout(() => {
          this.setState({
            successclass: 'hideclass',
            msg: null
          });
        }, 10000);
      });
    //}
  }

  render() {
    return (
      <React.Fragment>
        <div className="dashboard-wrapper">
          <AdminHeader />
          <div className="maincontent-area">
            <AdminTopHeader />
            <div className="main-dashsection">
              <section className="contentpard">
                <div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader == true)? <Spinner />:
                <form className="artist-form" onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box box-success artisit-profileform">
                        <div className="box-header with-border addprofilenew">
                          <h3 className="box-title ">Settings</h3>
                        </div>
                        <div className="box-body no-padding">
							<div className="row">
								<div className="col-md-12">
								  <div className="sectionhead">
									<div className="headsec">
									  <h3>General Settings</h3>
									</div>
								  </div>
								</div>
							  </div>
							  <div className="row">
								<div className="col-md-6">
								  <div className="form-group">
									<label htmlFor="lattempts">Login Attempts</label>
									<input
									  type="text"
									  placeholder="Login Attempts"
									  id="lattempts"
									  className="form-control"
									  value={this.state.login_device !== 'null' ? this.state.login_device : ''}
									  onChange={this.onChangeLogindevice.bind(this)}
									/>
									<font className="errorMsg">{this.state.errors['login_device_error']}</font>
								  </div>
								</div>
								<div className="col-md-6">
								  <div className="form-group">
									<label htmlFor="isrccodeamount">Individual ISRC Code Amount ($)</label>
									<input
									  type="text"
									  placeholder="ISRC Code Amount"
									  id="isrccodeamount"
									  className="form-control"
									  value={this.state.isrc_amount !== 'null' ? this.state.isrc_amount : ''}
									  onChange={this.onChangeCodeAmount.bind(this)}
									/>
									<font className="errorMsg">{this.state.errors['isrc_amount_error']}</font>
								  </div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
								  <div className="form-group">
									<label htmlFor="song_paused_on">Song Paused On (Sec.)</label>
									<input
									  type="text"
									  placeholder="Song Paused On (Sec.)"
									  id="song_paused_on"
									  className="form-control"
									  value={this.state.song_paused_on !== 'null' ? this.state.song_paused_on : ''}
									  onChange={this.onChangeSongPausedOn.bind(this)}
									/>
									<font className="errorMsg">{this.state.errors['song_paused_on_error']}</font>
								  </div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</div>
							</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
				}
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
