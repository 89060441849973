import React, { Component } from 'react';
import { API_URL } from '../config/constants.js';
import $ from 'jquery';
import Axios from 'axios';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
export default class Footer extends Component {
	
	scrolltopfunction = () => {
		window.scrollTo(0, 0);
	}
	constructor(props){
		super(props);
		this.state = {
			appleURL :'#',
			playStore: '#'
		}
	}
	componentDidMount(){
		this.getAppURL();
	}
	getAppURL = () => { 
	    Axios.post(base_api_url + 'get-app-url.php',{axiosConfig}).then(
	        (res) => {
	          if (res.data.res_status == 200) {
	            this.setState({
	              appleURL: res.data.itunes_path,
	              playStore: res.data.playstore_path 
	            });
	          } else {
	            this.setState({
	              appleURL: '#',
	              playStore: '#'
	            });
	          }
	        }
	    );
	}
    render() {
        return (<React.Fragment>			
			
			<footer className="can-footer">
				<div className="container">
					<div className="cn-f">
						<p>&copy; 2021-2022 CONQER Music. All Rights Reserved.</p>
						<p><a target="_blank" href="/privacy-policy">Privacy Policy</a></p>
						<div className="bottom-menusocial">                
							<ul className=" playstore-logo">
								<li>
									<a href={this.state.appleURL} target="_blank">
										<img alt="App Store" src={process.env.PUBLIC_URL + '/assets/merchandise/apple.png'} />
									</a>
								</li>
								<li>
									<a href={this.state.playStore} target="_blank">
										<img alt="Play Store" src={process.env.PUBLIC_URL + '/assets/merchandise/googleplaystore.png'} />
									</a>
								</li>                          
							</ul>                
						</div>
					</div>
				</div>
			</footer>
			<button onClick={() => this.scrolltopfunction()} id="myBtn" title="Go to top">Top</button>
			
			
			
			<div className="app-store-fixed-icon">
				<p className="play-store-icon">
					<a href={this.state.playStore} target="_blank">
						<img src={process.env.PUBLIC_URL + '/assets/merchandise/play-store-icon.png'} className="marginright" alt="Play Store" />
					</a>
				</p>
				<p className="app-store-icon">
					<a href={this.state.appleURL} target="_blank">
						<img src={process.env.PUBLIC_URL + '/assets/merchandise/app-store-icon.png'} alt="App Store" />
					</a>
				</p>
			</div>
		</React.Fragment>
		)
	}
}
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    $("#myBtn").show();
  } else {
    $("#myBtn").hide();
  }
}

$(document).ready(function() {
  $("#sidebarCollapse").on('click',function() {
	$("#sidebar").toggleClass('active');
  });
});