import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../config/constants.js';
import  { NavLink, Redirect, withRouter } from 'react-router-dom';
import DOMPurify from "dompurify";
import Spinner from '../../../includes/Spinner.component';
import ls from 'local-storage';
import $ from 'jquery';
const base_api_url = API_URL;
const sample_isrc_url = API_URL + 'sample/sample.xlsx';
const regex = /^[a-z\d\,-_\s]+$/i;
const errors = {};
class BulkISRC extends Component {
	
	constructor(props){
		super(props);	
		this.onChangeUploadCover = this.onChangeUploadCover.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
				
		this.state = {
			uploadisrc:null,
			error: null,
			errors: [],
			msg: null,
			successclass:'hideclass',
			redirect:false,
			fileisrcerror: '',
			selectedisrc:'Choose File',
			loadinng:false,
			errfileurl:'',
		}		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Import ISRC Code";		
	}
	
	handleValidation = () => {
		let formIsValid = true;
		if(!this.state.uploadisrc){
			formIsValid = false;
			errors["uploadisrc_error"] = "Please select the file you want to import.";
		}
		this.setState({errors: errors});
		return formIsValid;

	}
	
	onChangeUploadCover(e){
		if(this.state.errors['uploadisrc_error']){delete this.state.errors['uploadisrc_error'];}
		this.setState({errfileurl:''});
		if(e.target.files.length > 0 ){
			var validExt 	= ".xls,.xlsx";
			var filePath 	= e.target.files[0].name;
			var getFileExt 	= filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			var pos 		= validExt.indexOf(getFileExt);
			if(pos < 0){
				this.setState({
					fileisrcerror : 'Please upload .xls or .xlsx file only.',
					selectedisrc:'', 
					uploadisrc: ''
				});
			}else{
				this.setState({
					fileisrcerror : '',
					selectedisrc:'', 
					uploadisrc: ''
				});
				this.setState({selectedisrc:e.target.files[0].name, uploadisrc: e.target.files[0] });
			}
		}else{
			this.setState({
				fileisrcerror : '',
				selectedisrc:'', 
				uploadisrc: ''
			});
		}
	}	
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			const formData = new FormData();
			this.setState({
				loadinng:true
			});
			const axiosConfig = {
				'Content-Type': 'multipart/form-data',
				"Access-Control-Allow-Origin": "*",
			};
			
			if(this.state.uploadisrc!=null){		
				formData.append("importisrcfile", this.state.uploadisrc, this.state.uploadisrc.name);
			}
			
			Axios.post(base_api_url+'importisrc.php', formData, {axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status == 200){
						window.scrollTo(0, 0);
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
							loadinng:false,
						});						
					}else{
						window.scrollTo(0, 0);
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message,
							errfileurl:res.data.res_errfileurl,
							loadinng:false
						})
					}
					if(this.state.errfileurl){
						setTimeout(() => {
							this.setState({
								successclass:'hideclass',
								msg:null,
							});
						}, 10000)
					}else{
						setTimeout(() => {
							this.setState({
								successclass:'hideclass',
								msg:null,
							});
							$('#myModal').modal('hide');
							let redurl = '/admin/isrc';
							window.location.href = redurl;
						}, 10000)
					}
				}
			);
			this.setState({
				album_title:'',
				uploadisrc:'',
				about:'',
				selectedisrc:'Choose File',			
			});	
		}	
	}
	
	render() {
		if(this.state.loadinng === true){
			return <Spinner />;
		}
		return (
			<React.Fragment>				
					<div className={this.state.successclass}>{this.state.msg}</div>
					  <div className="row">
						<div className="col-md-12">
							<div className="distributions-profileform">
								<div className="addprofilenew">
								  <div className="row">
									<div className="col-md-12">
									  <div className="sectionhead">
										<div className="headsec">
										  <h3>Import ISRC Code</h3>
										</div>
									  </div>
									</div>
								  </div>
								</div>
								<div className="no-padding mb-3 px-3">
								  <form className="artist-form" onSubmit={this.onSubmit}>
									  <div className="row">
										<div className="col-md-12">
										  <div className="form-group">
											  <label htmlFor="isrcfile">Select File<span className="requiredastrik">*</span></label>
												<input type="file" className="form-control" id="isrcfile" onChange={this.onChangeUploadCover} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{'height':'auto'}} />  
												<div><span className="wrongpassmsg">Note: Supported type is Only xls | xlsx.</span></div>
												<font className="errorMsg">{this.state.errors["uploadisrc_error"]}</font>
												<font className="errorMsg">{this.state.fileisrcerror}</font>
										  </div>
										</div>
									  </div>
									  {(this.state.errfileurl)?
									  <div className="row">
										<div className="col-md-12" style={{'color':'#f00'}}>
											<b>Errors while importing: </b>
											<span className="notes-imasize"><a href={this.state.errfileurl} >Download</a></span>
										</div>
									  </div>
									  :''}
									  <div className="row mb-3">
										<div className="col-md-12">
											<b>Sample Import File: </b>
											<span className="notes-imasize"><a href={sample_isrc_url}>Download</a></span>
										</div>
									  </div>
									  <div className="row">
										<div className="col-md-12 notmainbtn">
											<button type="submit" className="btn btn-primary">Submit</button>
										</div>
									  </div>
								  </form>
								</div>								
							</div>
						</div>        
					  </div>      
			</React.Fragment>
		)
	}
}
export default withRouter(BulkISRC);