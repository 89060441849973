import React, { Component } from 'react';
import { Link, NavLink, Redirect, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { Col } from 'react-bootstrap';
import ls from 'local-storage';
import { API_URL } from '../config/constants.js';
import ModalShowCase from '../includes/modal.component';
import defaultimg from '../components/default.png';
import $ from 'jquery';
const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};

class ArtistTopMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      userdata: '',
      role: null,
      token: '',
      error: null,
      msg: null,
      redirect: false,
      showName: false,
      notifications: [],
      selectedNotificationData: '',
      unseenCounter: 0,
      unreadCounter: 0,
      show: false,
    };
  }

  componentDidMount() {
    const email = ls.get('email');
    const name = ls.get('name');
    const token = ls.get('token');
    const userid = ls.get('userid');
    this.setState({
      email: email,
      name: name,
      token: token,
      userid: userid,
    });
    this.getNotificationData();
    setInterval(this.getNotificationData, 10000);
    this.getLoggedinUserData();
    setInterval(this.getLoggedinUserData, 10000);
    $(document).ready(function () {
        $(document).click(function (event) {
            var clickover = $(event.target);
            if (clickover.hasClass("sidebar-toggle")) {
                $("#sidebar").addClass('active');
            }else{
                $("#sidebar").removeClass('active');
            }
        });
    });
  }

  getNotificationData = async () => {
    const obj = {
      user_id: ls.get('userid'),
    };

    const response = await Axios.post(base_api_url + 'get-notification.php', obj, axiosConfig);

    if (response.data.res_status === '200') {
      this.setState({
        notifications: response.data.res_data,
        unseenCounter: response.data.total_unseen,
        unreadCounter: response.data.total_unread,
      });
    }
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  getLoggedinUserData = () => {
    const obj = {
      userid: ls.get('userid'),
      token: ls.get('token'),
    };
    //console.log(obj);
    Axios.post(base_api_url + 'userbytoken.php', obj).then((res) => {
      if (res.data.res_status == 200) {
        const returndata = res.data.listenerdata;
		let setting_data = returndata.setting_data;
		ls.set('isrc_amount',setting_data);
        this.setState({
          userdata: returndata,
          loading: false,
          showName: true,
        });
      } else {
        this.setState({
          loading: false,
          showName: false,
        });
      }
    });
  };

  readNotification = async (uid) => {
    const obj = {
      id: uid,
    };
    await Axios.post(base_api_url + 'read-notification.php', obj, axiosConfig);
    this.getNotificationData();
  };

  seenNotification = async () => {
    const obj = {
      user_id: ls.get('userid'),
    };
    await Axios.post(base_api_url + 'seen-notification.php', obj, axiosConfig);
  };

  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };
  handleShowModal = (con, index) => {
    const selectedItemData = this.state.notifications[index] ? this.state.notifications[index] : {};
    this.readNotification(selectedItemData.id);
    if(selectedItemData.notification_type == "lis_booking"){
      let path = '/my-participant/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "atn_booking"){
      let path = '/my-attendee/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "follow"){
      let path = '/follower/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "song_approved"){
      let path = '/my-song/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "song_added"){
      let path = '/topssongs/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "event_created"){
      let path = '/listener-viewevent/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "isrc_exist"){
      let path = '/my-song/'+selectedItemData.data_id;
	  this.props.history.push(path);
    }else if(selectedItemData.notification_type == "prayer_reply"){
      if (Number(ls.get('role')) === 2) {
        let path = '/view-prayer-rquest/'+selectedItemData.data_id;
        this.props.history.push(path);
      }else{
        let path = '/listner-view-prayer-request/'+selectedItemData.data_id;
        this.props.history.push(path);
      }
    }else if(selectedItemData.notification_type == "user_welcome" || selectedItemData.notification_type == "become_a_featured_artist" || selectedItemData.notification_type == "become_a_featured_song" || selectedItemData.notification_type == "free_to_paid"){
      this.setState({ show: true, selectedNotificationData: selectedItemData }, () => this.readNotification(selectedItemData.id));
    }
    
  };
  onclicklogut = (logouttype) => {
		let currenttoken = ls.get('token');
		let currentemail = ls.get('email');
		let obj = [];
		if(logouttype === 'all'){
			obj = {
			  type: logouttype,
			  email: currentemail,
			};
		}else{
			obj = {
				type: logouttype,
				token: currenttoken,
			};

		}
		const axiosConfig = {
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*'
		};
		Axios.post(base_api_url + 'deleteloginattempt.php', obj, { axiosConfig }).then((res) => {
		  if (res.data.res_status == 200) {
			/*ls.remove('userid');
			ls.remove('email');
			ls.remove('name');
			ls.remove('role');
			ls.remove('token');
			ls.remove('membership');
			ls.remove('membership_status');
			ls.remove('profile');
			ls.remove('selectedtab');
      ls.remove('localCurrentSong');
      ls.remove('localMusicList');
      ls.remove('localIndex');*/
      ls.clear();
			this.props.history.push('/login');
		  }
		});
  };
  render() {
    let loggedinuserdata = this.state.userdata;
	if(loggedinuserdata){
		if(loggedinuserdata.total_ml < 1){
			ls.remove('userid');
			ls.remove('email');
			ls.remove('name');
			ls.remove('role');
			ls.remove('token');
			ls.remove('membership');
			ls.remove('membership_status');
			ls.remove('profile');
			ls.remove('selectedtab');
      ls.remove('localCurrentSong');
      ls.remove('localMusicList');
      ls.remove('localIndex');
			return <Redirect to="/login" />;
		}
	}else{
		let userid = ls.get('userid');
		if(!userid){
			return <Redirect to="/login" />;
		}
	}
    let profileimage = defaultimg;
    if (loggedinuserdata.profile_pic_path) {
      profileimage = loggedinuserdata.profile_pic_path;
    }
    let userName = loggedinuserdata.first_name + ' ' + loggedinuserdata.last_name;
    let profileUrl = '';
    if (Number(ls.get('role')) === 2) {
      profileUrl = '/artist-profile/' + this.state.token;
    } else {
      profileUrl = '/listener-profile/' + this.state.token;
    }

    return (
      <div className="topbararea">
        <div className="profile-infor">
          <nav className="navbar navbar-static-top">
            <button type="button" id="sidebarCollapse" className="sidebar-toggle">
              <span className="sr-only">Toggle navigation</span>
            </button>
            <div className="articwelcome">{this.state.showName ? <h4>{userName}</h4> : ''}</div>

            <div className="navbar-custom-menu">
              <ModalShowCase handleShow={this.hideModal} data={this.state.selectedNotificationData} show={this.state.show} />
              <ul className="nav navbar-nav">
                <li className="dropdown notifications-menu">
                  <Link onClick={() => this.seenNotification()} className="dropdown-toggle" data-toggle="dropdown">
                    <i className="fa fa-bell-o"></i>

                    {this.state.unreadCounter > 0 ? <span className="label label-warning">{this.state.unreadCounter}</span> : ''}
                  </Link>
                  <ul style={{ overflowY: 'auto',maxHeight:'400px' }} className="dropdown-menu">
                    <li className="header">You have {this.state.unreadCounter} notifications</li>
                    <li>
                      <ul className="menu">
                        {this.state.notifications.map((item, index) => (
                          (item.read == 0)?
                          <Link onClick={() => this.handleShowModal(false, index)}>
                          <li style={{ cursor: 'pointer', borderBottom:'1px solid #dcd9d9' }}>
                            <Col className="py-2">
                            <span style={{ fontSize: '0.9rem' }} className={item.read === '0' ? 'font-weight-bold text-dark' : 'text-secondary'}>
                                {item.title}
                              </span>
                            </Col>
                          </li>
                          </Link>:""
                        ))}
                      </ul>
                    </li>
                    <li className="footer">
                    {(Number(ls.get('role')) === 2)?
                      <Link to="/notifications-artist">View all</Link>:<Link to="/listener-notification">View all</Link>
                    }
                    </li>
                  </ul>
                </li>

                <li className="dropdown user user-menu">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <img onError={this.addDefaultSrc} src={profileimage} className="user-image" alt="User Image" />
                  </a>
                  <ul className="dropdown-menu">
                    <li className="user-header">
                      <img onError={this.addDefaultSrc} src={profileimage} className="img-circle" alt="User Image" />
                      <p>
                        {this.state.name} - {ls.get('role') == 2 ? 'Artist' : 'Listener'}
                        <br />
                        {(loggedinuserdata.is_free == 1)?<span>Free</span>:<span>Member since {loggedinuserdata.membership_date}</span>}
                      </p>
                    </li>
                    <li className="user-footer">
                      <div className="pull-left">
                        <NavLink to={profileUrl} className="btn btn-default btn-flat">
                          Profile
                        </NavLink>
                      </div>
                      <div className="pull-right">
                        <a href="javascript:void(0)" onClick={() => this.onclicklogut('1')} activeClassName="active" className="btn btn-default btn-flat">
                          Logout
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}
export default withRouter(ArtistTopMenu);
