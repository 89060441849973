import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import $ from 'jquery';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}

export default class Reports extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {		  
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  reportlist:[],		  
		  loading:true,
		  loader:null,		  
		  redirect:false
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Reports";
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({	
			token:token,		
			userid:userid
		});
		
		this.getallreportlist();
		
	}
	componentDidUpdate(){
		//this.getallreportlist();
		$('#myevents').DataTable(
			{	
				"destroy": true,
				"processing": true,
				"pageLength":10,
				"stateSave": true,
				"lengthMenu": [[5, 10, 15, -1], [5, 10, 15, "All"]],
				"dom": 'Bfrtip',
				"buttons": ['csv']
			}
		);
	}
	getallreportlist = () => {
		const obj = {
			artist_id:ls.get('userid')
		}
		
		Axios.post(base_api_url+'reports.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data
					this.setState({
						reportlist:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	
	render() {
		if(this.state.loading==true){
			return <Spinner />;
		}
		let maxlimit = '30';
		return (
			<React.Fragment>
				
				<div className={this.state.successclassName}>{this.state.msg}</div>
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
									<h3 className="box-title">Reports</h3>  
									<div className="box-tools saearchicons">
										<div className="input-group input-group-sm hidden-xs">
										</div>
									</div>            
								</div>
								<div className="box-body no-padding">
									<div className="table table-responsive no-margin table-striped table-hover">
										<table id="myevents" className="table table-striped table-bordered table-sm row-border hover mb-5">
										<thead>
											<tr>
												<th>Name</th>                    
												<th>Gender</th>                    
												<th>City</th>
												<th>State</th>
												<th>Country</th>
												<th>No. of listened song</th>
											</tr>
										</thead>
										<tbody>
											{this.state.reportlist.map((result) => {
												// console.log('result',result);
												return (
												  <tr>                   
													<td><a href="#">{result.name}</a></td>
													<td>{result.gender}</td>
													<td>{result.city}</td>
													<td>{result.state}</td>
													<td>{result.country}</td>
													<td>{result.slcount}</td>
												  </tr>	
												)
											})}
										</tbody>
									</table>
								</div>              
							</div>
							<div className="box-footer clearfix">
							</div>
						</div>
					</div>        
				</div>      			
			</React.Fragment>
		)
	}
}