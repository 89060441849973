import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import StripeCard from "./stripecard.component";
import ls from 'local-storage';
export default class RenewPlan extends Component {	
	
	componentDidMount(){
		document.title = "CONQER Music - Membership Renewal";
		ls.set('registration_type', '');
		ls.set('registration_type', this.props.match.params.type);
	}
	
    render() { return (<StripeCard />)}
}