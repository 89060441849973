import React, { Component } from 'react';
import Axios from 'axios';
import ArtistTopMenu from '../../../includes/artisttopmenu.component';
import  { NavLink  } from 'react-router-dom';
import { API_URL } from '../../../config/constants.js';
import ls from 'local-storage';
import defaultimg from '../../../components/default.png';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
export default class ConversationList extends Component {
	
	constructor(props){
		super(props);
		this.state = {
		  error: null,
		  role: null,
		  msg: null,
		  userid:null,
		  token:ls.get('token'),
		  successclass:null,
		  redirect:false		  
		}
	}
	
	componentDidMount(){
		document.title = "CONQER Music - View Prayer";
		const userid = ls.get('userid');
		this.setState({
			role: ls.get('role'),
			userid:userid
		});
		
	}
	
	render() {
		let blockalign,chatnamealign,timestampalign,haveconversation;
		let coversationdata = this.props.coversationdata;
		if(coversationdata.sender_id === '0'){
			if(coversationdata.anonymus == '1'){
				blockalign 		= 'direct-chat-msg';
				chatnamealign 	= 'direct-chat-name pull-left';
				timestampalign 	= 'direct-chat-timestamp pull-right';
			}else{
				blockalign 		= 'direct-chat-msg right';
				chatnamealign 	= 'direct-chat-name pull-right';
				timestampalign 	= 'direct-chat-timestamp pull-left';
			}
		}else{
			blockalign 		= 'direct-chat-msg';
			chatnamealign 	= 'direct-chat-name pull-left';
			timestampalign 	= 'direct-chat-timestamp pull-right';
		}
		
		if(coversationdata){
			var profileimage = '';
			var image = new Image();
			var profile_pic = coversationdata.profile_pic;
			image.src = profile_pic;
			if (image.width !== 0) {
				profileimage = profile_pic;								
			} else {
				profileimage = defaultimg;
			}
			return(
				<div className={blockalign}>
					{
						(coversationdata.anonymus == '1')?
							<div className="usersfontimages"><i class="fa fa-user-circle-o" aria-hidden="true"></i>
</div>
						:	<div className="">
								<div className="direct-chat-info clearfix">
									<span className={chatnamealign}>{coversationdata.name}</span>
									<span className={timestampalign}>{coversationdata.date}</span>
								</div>
								{(coversationdata.profile_pic != '')?
									<img className="direct-chat-img" src={profileimage} alt="message user image" />
									:<img className="direct-chat-img" src={process.env.PUBLIC_URL + 'assets/user/user_icon.png'} alt="message user image" />
								}
							</div>
					}
					<div className="direct-chat-text">
						{coversationdata.message}
					</div>
				</div>
			)
		}else{
			return(
				<div className={blockalign}>
					<div className="direct-chat-text">
						No comments found!
					</div>
				</div>
			)
		}
	}
}