import React, { Component } from 'react';
import Axios from 'axios';
import InputMask from 'react-input-mask';
import { API_URL, STRIPE_KEY } from '../../config/constants.js';
import { NavLink, Link } from 'react-router-dom';
import ls from 'local-storage';
import DOMPurify from 'dompurify';
import defaultimg from '../../components/default.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import $ from 'jquery';
import Spinner from '../../includes/Spinner.component';
import AdminHeader from './header.component';
import AdminTopHeader from './header-top.component';
import CropAndPreview from '../../includes/CropAndPreviewModal.component';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
const stripePromise = loadStripe(STRIPE_KEY);
const errors = {};
const base_api_url = API_URL;
toast.configure();
export class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onFileChangeRoutingFile = this.onFileChangeRoutingFile.bind(this);
    this.onFileChangeIdentityFile = this.onFileChangeIdentityFile.bind(this);
    this.onChangeFirstNameEdit = this.onChangeFirstNameEdit.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeDob = this.onChangeDob.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeZip = this.onChangeZip.bind(this);
    this.onChangeSSN = this.onChangeSSN.bind(this);
    this.onChangeFaceBook = this.onChangeFaceBook.bind(this);
    this.onChangeTwitter = this.onChangeTwitter.bind(this);
    this.onChangeStage = this.onChangeStage.bind(this);
    this.onChangeBankName = this.onChangeBankName.bind(this);
    this.onChangeAccountNumber = this.onChangeAccountNumber.bind(this);
    this.onChangeRoutingNumber = this.onChangeRoutingNumber.bind(this);
    this.onChangeBio = this.onChangeBio.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      user: [],
      error: null,
      errors: [],
      msg: null,
      usersubscription: '',
      token: ls.get('token'),
      membership_status: ls.get('membership_status'),
      successclass: 'hideclass',
      redirect: false,
      isrctitle: '',
      isrccode: '',
      formStep: '1',
      submitbuttontext: 'Update',
      btnDisable: false,
      selectedprofile: 'Choose File',
      selectedidentity: 'Choose File',
      selectedrouting: 'Choose File',
      selectedFile: '',
      loader: false,
      role: '',
      profile_pic_path: '',
      urlvalinitial: '',
      allCountries: [],
      selectedCountry: '',
      showCropAndPreview: false,
      formValid: true,
      bank_name:'',
      is_free:'0',
      sub_plan:'',
      fillStatus: false,
      planlist: [],
      btnText: 'Update',
    };
  }
  handleShow = () => {
    this.setState({ showCropAndPreview: !this.state.showCropAndPreview });
  };
  handleChange(date) {
    if (this.state.errors['dob_error']) {
      delete this.state.errors['dob_error'];
    }
    this.setState({
      startDate: date
    });
  }
  componentDidMount() {
    document.title = 'CONQER Music - Edit User Profile';
    this.fetchUserRecord();    
  }
  getplandetails = (utype) => {    
    const usertype = utype;
    const obj = {
      usertype: usertype
    }
    Axios.post(base_api_url + 'getplandetails.php', obj)
      .then(
        (res) => {
          if (res.data.res_status == 200) {
            this.setState({
              planlist: res.data.res_data
            });
          } else {
            this.setState({
              planlist: []
            });
          }
        }
      );
  }
  handleValidation = () => {
    let formIsValid = true;

    if (!this.state.firstname) {
      formIsValid = false;
      errors['firstname_error'] = 'Please enter first name.';
    }

    /*if (!this.state.startDate) {
      formIsValid = false;
      errors['dob_error'] = 'Please select date of birth.';
    }*/
    if (this.state.selectedFile != null) {
      const fsize = this.state.selectedFile.size;
      const file = Math.round(fsize / 1024);
      if (file >= 5120) {
        formIsValid = false;
        errors['image_error'] = 'File too Big, please select a file less than 5mb.';
      }else if(!this.state.formValid){
        formIsValid = false;
      }
    }
	
	if (this.state.routingFile != null) {
      const fsize = this.state.routingFile.size;
      const file = Math.round(fsize / 1024);
      if (file >= 5120) {
        formIsValid = false;
        errors['routing_error'] = 'File size should be less than 5mb.';
      }else if(!this.state.formValid){
        formIsValid = false;
      }
    }
    if (this.state.identityFile != null) {
      const fsize = this.state.identityFile.size;
      const file = Math.round(fsize / 1024);
      if (file >= 5120) {
        formIsValid = false;
        errors['identity_error'] = 'File size should be less than 5mb.';
      }else if(!this.state.formValid){
        formIsValid = false;
      }
    }
    if(!this.state.email){
      formIsValid = false;
      errors['email_error'] = 'Please enter email.';
    }else if(!this.validateEmail(this.state.email)){
      formIsValid = false;      
      errors["email_error"] = "Please enter valid email.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  croppedImage = async (img) => {
    this.setState({ profile_pic_path: img });
  };

  fetchUserRecord = () => {
    const obj = {
      user_id: this.props.match.params.id
    };

    const axiosConfig = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    };

    Axios.post(base_api_url + 'user-detail.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        this.setState(
          {
            firstname: res.data.res_data.first_name,
            lastname: res.data.res_data.last_name,
            account_number: res.data.res_data.account_number,
            address: res.data.res_data.address,
            bank_file: res.data.res_data.bank_file,
            bank_file_path: res.data.res_data.bank_file_path,
            bank_name: res.data.res_data.bank_name,
            city: res.data.res_data.city,
            dob: res.data.res_data.dob,
            startDate: (res.data.res_data.dob)?new Date(res.data.res_data.dob):"",
            email: res.data.res_data.email,
            facebook: res.data.res_data.facebook,
            gender: res.data.res_data.gender,
            linkedin: res.data.res_data.linkedin,
            phone: res.data.res_data.phone,
            ssn: res.data.res_data.ssn,
            profile_pic: res.data.res_data.profile_pic,
            profile_pic_path: res.data.res_data.path,
            routing_file: res.data.res_data.routing_file,
            routing_file_path: res.data.res_data.routing_file_path,
            routing_number: res.data.res_data.routing_number,
            state: res.data.res_data.state,
            zipcode: res.data.res_data.zipcode,
            about: res.data.res_data.about,
            user_id: res.data.res_data.user_id,
            stage: res.data.res_data.stage,
            twitter: res.data.res_data.twitter,
            isrctitle: res.data.res_data.isrc_title,
            isrccode: res.data.res_data.isrc_code,
            role: res.data.res_data.role,
            selectedCountry: res.data.res_data.country,
            is_free: res.data.res_data.is_free
          },
          () => {
            Axios.get(base_api_url + 'country.php', axiosConfig).then((res) => {
              if (res.data.res_status === '200') {
                this.setState({
                  allCountries: res.data.res_data
                });
              }
            });
          }
        );
        this.getplandetails(this.state.role && this.state.role === '2' ? 'artist' : 'listener');
      } else {
        this.setState({
          redirect: false,
          msg: null,
          successclass: null
        });
      }
      this.setState({
        loader: false
      });
    });
  };

  state = {
    selectedFile: null,
    routingFile: null,
    identityFile: null
  };

  onFileChange(e) {
    if (this.state.errors['image_error']) {
      delete this.state.errors['image_error'];
    }
	if(e.target.files.length>0){
		let url = URL.createObjectURL(e.target.files[0]);
		this.setState({ selectedprofile: e.target.files[0].name, selectedFile: url, showCropAndPreview: true });
	}else{
		this.setState({ selectedprofile: null, selectedFile: null, showCropAndPreview: false });
	}
  }
  onFileChangeRoutingFile(e) {
	this.setState({ formValid: true});
    if (this.state.errors['routing_error']) {
      delete this.state.errors['routing_error'];
    }
	if( e.target.files.length > 0 ){
      var validExt = ".png, .pdf, .jpeg, .jpg";
      var filePath = e.target.files[0].name;
      var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
      var pos = validExt.indexOf(getFileExt);      
      let url = URL.createObjectURL(e.target.files[0]);
      if(url && pos < 0){
        errors['routing_error'] = "Sorry, " + filePath + " is invalid, allowed extensions are: " + validExt;
        this.setState({ selectedrouting: e.target.files[0].name, routingFile: e.target.files[0], formValid: false });
      }else{
        this.setState({ selectedrouting: e.target.files[0].name, routingFile: e.target.files[0], btnDisable:false });
      }	
	}
  }

  onFileChangeIdentityFile(e) {
	this.setState({ formValid: true});
    if (this.state.errors['identity_error']) {
      delete this.state.errors['identity_error'];
    }
    var validExt = ".png, .pdf, .jpeg, .jpg";
    var filePath = e.target.files[0].name;
    var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
    var pos = validExt.indexOf(getFileExt);      
    let url = URL.createObjectURL(e.target.files[0]);
    if(url && pos < 0){
      errors['identity_error'] = "Sorry, " + filePath + " is invalid, allowed extensions are: " + validExt;
      this.setState({ selectedidentity: e.target.files[0].name, identityFile: e.target.files[0], formValid: false });
    }else{
      this.setState({ selectedidentity: e.target.files[0].name, identityFile: e.target.files[0], btnDisable:false });
    }
	
  }

  onChangeFirstNameEdit(e) {
    if (this.state.errors['firstname_error']) {
      delete this.state.errors['firstname_error'];
    }
    this.setState({
      firstname: e.target.value
    });
  }

  onChangeLastName(e) {
    this.setState({
      lastname: e.target.value
    });
  }

  onChangeDob(e) {
    if (this.state.errors['dob_error']) {
      delete this.state.errors['dob_error'];
    }
    this.setState({
      dob: e.target.value
    });
  }

  onChangeGender(e) {
    this.setState({
      gender: e.target.value
    });
  }

  onChangeAddress(e) {
    this.setState({
      address: e.target.value
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value
    });
  }
  validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }
  onChangeEmail(e){
    if (this.state.errors['email_error']) {
      delete this.state.errors['email_error'];
    }
    this.setState({
      email: e.target.value
    });
  }
  onChangeCountry(e) {
    this.setState({
      selectedCountry: e.target.value
    });
  }

  onChangeCity(e) {
    this.setState({
      city: e.target.value
    });
  }

  onChangeState(e) {
    this.setState({
      state: e.target.value
    });
  }

  onChangeZip(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        zipcode: e.target.value
      });
    }
  }

  onChangeFaceBook(e) {
    this.setState({
      facebook: e.target.value
    });
  }

  onChangeTwitter(e) {
    this.setState({
      twitter: e.target.value
    });
  }

  onChangeStage(e) {
    this.setState({
      stage: e.target.value
    });
  }
  onChangeSSN(e) {
    this.setState({
      ssn: e.target.value
    });
  }
  onChangeBankName(e) {
    this.setState({
      bank_name: e.target.value
    });
  }

  onChangeAccountNumber(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        account_number: e.target.value
      });
    }
  }

  onChangeRoutingNumber(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        routing_number: e.target.value
      });
    }
  }

  onChangeBio(e) {
    this.setState({
      about: e.target.value
    });
  }
  
  onClicktab(tab) {
    ls.set('selectedadmintab',tab);
    this.setState({
      formStep:tab
    });
  }
  onChangePlan(e){
    if (this.state.errors['plan_error']) {
      delete this.state.errors['plan_error'];
    }
    this.setState({
      sub_plan: e.target.value
    })
  }
  handleValidationUpgrad = () => {
    let formIsValid = true;
    if (!this.state.sub_plan) {
      formIsValid = false;
      errors['plan_error'] = 'Please select subscription plan.';
    }
    if(!this.state.formValidation){
      formIsValid = false;
    }
    if(!this.state.fillStatus){
      this.setState({
        cardError:'Please enter card detail.'
      });
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  stripeElementChange = (element, name) => {
    this.setState({
      fillStatus: true,
      cardError:'',
      formValidation: true
    });
    if(element.error){
      if(element.error.code){
        this.setState({
          formValidation: false,
          cardError: element.error.message
        });
      }
    }
  }

  async onUpgradeProfile(e) {
    e.preventDefault();
    if (this.handleValidationUpgrad()) {
      this.setState({
        btnText:'Please Wait..',
        btnDisable:true
      });
      await this.onToken();      
    }
  }
  async onToken() {
    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if(result.error){
      this.setState({
        btnText:'Update',
        btnDisable:false
      });
    }else{
      let formData = {
        "token":result.token.id,
        "membership": this.state.sub_plan,
        "email": this.state.email
      };
      const axiosConfig = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      };
      Axios.post(base_api_url + 'stripepay.php', formData, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          let formUpdateData = {
            "subscription_id": res.data.res_data,
            "plan_id": this.state.sub_plan,            
            "user_id": this.state.user_id
          };
          Axios.post(base_api_url + 'upgrade-plan.php', formUpdateData, { axiosConfig }).then((response) => {
            if (response.data.res_status == 200) {
              toast.success(response.data.res_message);
              this.setState({
                is_free: '0',
                formStep:'1'
              });
            }else{
              toast.error(response.data.res_message);
            }
          });
          toast.success(res.data.res_message);
        }else {
          toast.error(res.data.res_message);
        }
        this.setState({
          btnText:'Update',
          btnDisable:false
        });
      }
      )
    }
  }
  async onSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {      
    this.setState({btnDisable: true});
      this.setState({
        loader: false,
        btnDisable: false
      });
      const formData = new FormData();
      const axiosConfig = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      };
      if (this.state.selectedFile) {
        let blob = await fetch(this.state.profile_pic_path).then((r) => r.blob());
        formData.append('image', blob, this.state.selectedprofile);
      }
      if (this.state.routingFile != null) {
        formData.append('routingfile', this.state.routingFile, this.state.routingFile.name);
      }
      if (this.state.identityFile != null) {
        formData.append('identityfile', this.state.identityFile, this.state.identityFile.name);
      }
      if(this.state.startDate){
        const dob = moment(this.state.startDate);
        formData.append('dob', dob.format('YYYY-MM-DD'));
      }else{
        formData.append('dob', "");
      }
      
      formData.append('first_name', this.state.firstname);
      formData.append('last_name', this.state.lastname);
      
      formData.append('gender', this.state.gender);
      formData.append('address', this.state.address);
      formData.append('phone', this.state.phone);
      formData.append('email', this.state.email);
      formData.append('ssn', this.state.ssn);
      formData.append('city', this.state.city);
      formData.append('state', this.state.state);
      formData.append('zipcode', this.state.zipcode);
      formData.append('facebook', this.state.facebook);
      formData.append('twitter', this.state.twitter);
      formData.append('stage', this.state.stage);
      formData.append('bank_name', this.state.bank_name);
      formData.append('account_number', this.state.account_number);
      formData.append('routing_number', this.state.routing_number);
      formData.append('userid', this.state.user_id);
      formData.append('oldprofilepic', this.state.profile_pic);
      formData.append('oldroutingpic', this.state.routing_file);
      formData.append('oldidentitypic', this.state.bank_file);
      formData.append('country', this.state.selectedCountry);
      formData.append('about', this.state.about);
      formData.append('update_by_admin', true);
      Axios.post(base_api_url + 'update-user.php', formData, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          window.scrollTo(0, 0);
          this.setState({
            successclass: 'successmsg',
            msg: 'User updated successfully!',
            submitbuttontext: 'Update',
            btnDisable: false,
            selectedprofile: 'Choose File',
            selectedidentity: 'Choose File',
            selectedrouting: 'Choose File',
			      selectedFile: null
          });
          this.refs.uploadImageProfile.value = "";
		  //document.getElementById('customFile').value = '';
        } else {
          window.scrollTo(0, 0);
          this.setState({
            successclass: 'errormsg',
            submitbuttontext: 'Update',
            btnDisable: false,
            msg: 'User not updated, please try again!',
            selectedprofile: 'Choose File',
            selectedidentity: 'Choose File',
            selectedrouting: 'Choose File'
          });
        }
		this.fetchUserRecord();
		this.forceUpdate();
		let getseltab = ls.get('selectedadmintab');
		if(getseltab === '2'){
		  this.setState({formStep:'2'});
		}else if(getseltab === '3'){
		  this.setState({formStep:'3'});
		}else if(getseltab === '4'){
		  this.setState({formStep:'4'});
		}else{
		  this.setState({formStep:'1'});
		}
        setTimeout(() => {
          this.setState({
            successclass: 'hideclass',
            msg: null
          });
        }, 10000);
      });
    }
  }

  onclickseturl = (urlval) => {
    this.setState({
      urlvalinitial: urlval
    });
    $('#myModal').modal('show');
  };
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };

  render() {
    let isrc_codedata = this.state.isrctitle + ' ( ' + this.state.isrccode + ' )';
    let countrySelected = this.state.allCountries.find((item) => item.key === this.state.selectedCountry);

    return (
      <React.Fragment>
        <div className="dashboard-wrapper">
          <AdminHeader />
          <div className="maincontent-area">
            <AdminTopHeader />
            <div className="main-dashsection">
              <section className="contentpard">
                <div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader == true)? <Spinner />:
                <form className="artist-form" onSubmit={this.onSubmit}>
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success artisit-profileform adminusers">
								<div className="box-header with-border addprofilenew">
									<h3 className="box-title ">{this.state.role && this.state.role === '2' ? 'Artist Profile' : 'Listener Profile'}</h3>
									<div className="box-tools ">
										<Link to={'/admin/users/'} className="btn btn-primary pull-right">
											<i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
										</Link>
									</div>
								</div>
								<div className="box-body no-padding havetabs eventadds">
									<div className="nav-tabs-custom">
										<ul className="nav nav-tabs">
											<li className="nav-item"><a href="#tab_pe" data-toggle="tab" aria-expanded="true" className={(this.state.formStep==='1')?'nav-link active':'nav-link'} onClick={()=>this.onClicktab('1')}>Personal Information</a></li>
											<li className="nav-item"><a href="#tab_so" data-toggle="tab" aria-expanded="false"  className={(this.state.formStep==='2')?'nav-link active':'nav-link'} onClick={()=>this.onClicktab('2')}>Social Media Information</a></li>
											{this.state.role && this.state.role === '2' ? (
											<li className="nav-item"><a href="#bankinfor" data-toggle="tab" aria-expanded="false" className={(this.state.formStep==='3')?'nav-link active':'nav-link'} onClick={()=>this.onClicktab('3')}>Financial Information</a></li>):('')}
											<li className="nav-item"><a href="#tab_up" data-toggle="tab" aria-expanded="false" className={(this.state.formStep==='4')?'nav-link active':'nav-link'} onClick={()=>this.onClicktab('4')}>Upload</a></li>
                      {(this.state.is_free == '1')?<li className="nav-item"><a href="#tab_upgrad" onClick={()=>this.onClicktab('5')} data-toggle="tab" aria-expanded="false" className={(this.state.formStep==='5')?'nav-link active':'nav-link'}>Upgrade Profile</a></li>:""}              
										</ul>
										<div className="tab-content">
											<div className={(this.state.formStep==='1')?'tab-pane active':'tab-pane'} id="tab_pe">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="firstname">
															First Name<span className="requiredastrik">*</span>
															</label>
															<input
															type="text"
															className="form-control"
															maxLength="50"
															value={this.state.firstname}
															onChange={this.onChangeFirstNameEdit}
															/>
															<font className="errorMsg">{this.state.errors['firstname_error']}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="lastname">Last Name</label>
															<input
															type="text"
															className="form-control"
															maxLength="50"
															value={this.state.lastname}
															onChange={this.onChangeLastName}
															/>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="dateofbirth">
															Date of Birth{/*<span className="requiredastrik">*</span>*/}
															</label>
															<DatePicker
															selected={this.state.startDate}
															onChange={this.handleChange}
															name="startDate"
															dateFormat="MM/dd/yyyy"
															dropdownMode="select"
															showMonthDropdown
															showYearDropdown
															adjustDateOnChange
															maxDate={new Date()}
															className="form-control"
															/>
															<font className="errorMsg">{this.state.errors['dob_error']}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="gender">Gender</label>
															<br />
															<div className="custom-control custom-radio custom-control-inline">
																<input
																type="radio"
																id="malegender"
																value="male"
																name="gender"
																onChange={this.onChangeGender}
																checked={this.state.gender === 'male'}
																className="custom-control-input"
																/>
																<label className="custom-control-label" htmlFor="malegender">
																	Male
																</label>
															</div>
															<div className="custom-control custom-radio custom-control-inline">
																<input
																type="radio"
																id="femalegender"
																value="female"
																name="gender"
																checked={this.state.gender === 'female'}
																onChange={this.onChangeGender}
																className="custom-control-input"
																/>
																<label className="custom-control-label" htmlFor="femalegender">
																Female
																</label>
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="phone">Phone No.</label>
															<InputMask
															{...this.props}
															mask="(999) 999-9999"
															maskChar=""
															className="form-control"
															value={this.state.phone}
															onChange={this.onChangePhone}
															/>
														</div>
													</div>
                          <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="email">Email<span className="requiredastrik">*</span></label>
                            <input type="text" className="form-control" value={this.state.email} onChange={this.onChangeEmail.bind(this)} />
                            <font className="errorMsg">{this.state.errors['email_error']}</font>
                            </div>
                          </div>
													
												</div>
												<div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="address">Address</label>
                              <input
                              type="text"
                              className="form-control"
                              maxLength="150"
                              value={this.state.address}
                              onChange={this.onChangeAddress}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
														<div className="form-group">
															<label htmlFor="city">City</label>
															<input type="text" className="form-control" maxLength="50" value={this.state.city} onChange={this.onChangeCity} />
														</div>
													</div>
                          
												</div>

												<div className="row">
													<div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="state">State</label>
                              <input type="text" className="form-control" maxLength="50" value={this.state.state} onChange={this.onChangeState} />
                            </div>
                          </div>
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="country">Country</label>
															<select
															maxLength="6"
															className="form-control"
															value={countrySelected ? countrySelected.key : ''}
															onChange={this.onChangeCountry}
															>
																{this.state.allCountries.map((item, index) => (
																	<option value={item.key} key={item.key}>
																	{item.value}
																	</option>
																))}
															</select>
														</div>
													</div>
												</div>
                        <div className="row">                        
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="zip">Zip Code</label>
                              <input type="text" maxLength="6" className="form-control" value={this.state.zipcode} onChange={this.onChangeZip} />
                            </div>
                          </div>
                        </div>
												<div className="row">
													<div className="col-md-12">
														<button type="submit" className="btn btn-primary" disabled={this.state.btnDisable}>
                              {this.state.submitbuttontext}
                            </button>
													</div>
												</div>
											</div>
											<div className={(this.state.formStep==='2')?'tab-pane active':'tab-pane'} id="tab_so">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="twitter">Twitter</label>
															<input
															type="text"
															className="form-control"
															maxLength="100"
															value={(this.state.twitter ==='null')?'':this.state.twitter}
															onChange={this.onChangeTwitter}
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="facebook">Facebook</label>
															<input
															type="text"
															className="form-control"
															maxLength="100"
															value={(this.state.facebook ==='null')?'':this.state.facebook}
															onChange={this.onChangeFaceBook}
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="stagename">Stage Name</label>
															<input type="text" className="form-control" maxLength="100" value={(this.state.stage ==='null')?'':this.state.stage} onChange={this.onChangeStage} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<button type="submit" className="btn btn-primary" disabled={this.state.btnDisable}>
                              {this.state.submitbuttontext}
                            </button>
													</div>
												</div>
											</div>
											{this.state.role && this.state.role === '2' ? (
											<div className={(this.state.formStep==='3')?'tab-pane active':'tab-pane'} id="bankinfor">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="bankname">Bank Name</label>
															<input
															type="text"
															className="form-control"
															maxLength="50"
															value={this.state.bank_name}
															onChange={this.onChangeBankName}
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="accountnumber">Account Number</label>
															<input
															type="text"
															className="form-control"
															maxLength="30"
															value={(this.state.account_number === 'null')?'':this.state.account_number}
															onChange={this.onChangeAccountNumber}
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="routingnumber">Routing Number</label>
															<input
															type="text"
															className="form-control"
															maxLength="9"
															value={(this.state.routing_number === 'null')?'':this.state.routing_number}
															onChange={this.onChangeRoutingNumber}
															/>
														</div>
													</div>
													  <div className="col-md-6">
														<div className="form-group">
														  <label htmlFor="ssn">SSN <span className="helpicon"><i className="fa fa-question-circle" aria-hidden="true"></i></span>
															<span className="helpiconactive helpiconssn" style={{'width':'330px'}}>
															<p>This enables you to receive payments and proper tax documents at year end.</p>
															</span>
														  </label>
														  <InputMask
														  {...this.props}
														  className="form-control"
														  placeholder="SSN"
														  mask="999-99-9999"
														  maskChar=" "
														  value={this.state.ssn}
														  onChange={this.onChangeSSN}
														  id="ssn"
														  />
														</div>
													  </div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<button type="submit" className="btn btn-primary">
															Update
														</button>
													</div>
												</div>
											</div>
											):('')}
											<div className={(this.state.formStep==='4')?'tab-pane active':'tab-pane'} id="tab_up">
												<CropAndPreview
												show={this.state.showCropAndPreview}
												data={{ src: this.state.selectedFile }}
												croppedImages={this.croppedImage}
												handleShow={this.handleShow}
												/>
												<div className="row">
													<div className="col-md-6 editeventimage">
														<div className="form-group">
															<label htmlFor="customFile">Profile Picture</label>
															<input type="file" ref="uploadImageProfile" className="form-control" id="customFile" onChange={this.onFileChange.bind(this)} accept="image/*" />
															<font className="errorMsg">{this.state.errors['image_error']}</font>
															<img
															onError={this.addDefaultSrc}
															src={this.state.profile_pic_path}
															className="img-responsive img-circle"
															style={{ width: '100px', height: '100px', marginTop:'5px' }}
															onClick={() => this.onclickseturl(this.state.profile_pic_path)}
															/>
														</div>
													</div>
													{this.state.role && this.state.role === '2' ? (
													<div className="col-md-6 editeventimage">
														<div className="form-group">
															<label htmlFor="customFile1">Routing Document</label> {(this.state.routing_file_path)?<font style={{fontSize:'13px'}}>[<a href={this.state.routing_file_path} target="_blank">Download</a>]</font>:''}
															<input type="file" className="form-control" id="customFile1" onChange={this.onFileChangeRoutingFile} />
															<div><span className="wrongpassmsg">Note: Supported type is JPG, JPEG, PNG, PDF and maximum file size is 5MB.</span></div>
															<font className="errorMsg">{this.state.errors['routing_error']}</font>
														</div>
													</div>
													) : (
													''
													)}
												</div>
												{this.state.role && this.state.role === '2' ? (
													<div className="row">
														<div className="col-md-6 editeventimage">
															<div className="form-group">
																<label htmlFor="customFile2">Identity Document</label> {(this.state.bank_file_path)?<font style={{fontSize:'13px'}}>[<a href={this.state.bank_file_path} target="_blank">Download</a>]</font>:''}
																<input type="file" className="form-control" id="customFile2" onChange={this.onFileChangeIdentityFile} />
																<div><span className="wrongpassmsg">Note: Supported type is JPG, JPEG, PNG, PDF and maximum file size is 5MB.</span></div>
																<font className="errorMsg">{this.state.errors['identity_error']}</font>
															</div>
														</div>
													</div>
												) : (
												''
												)}
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label htmlFor="routingdocuments">User Bio</label>
															<textarea className="form-control rounded-0" onChange={this.onChangeBio} rows="3" value={(this.state.about ==='null')?'':this.state.about}></textarea>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-md-12">
														<button type="submit" className="btn btn-primary" disabled={this.state.btnDisable}>
															{this.state.submitbuttontext}
														</button>
													</div>
												</div>                        
											</div>
											{(this.state.is_free == '1')?
                        <div className={(this.state.formStep==='5')?'tab-pane active':'tab-pane'} id="tab_upgrad">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Subscription Plan<span className="requiredastrik">*</span></label>
                                <select className="form-control" onChange={this.onChangePlan.bind(this)}>
                                  <option value="">--Select Subscription Plan--</option>
                                    {this.state.planlist.map((result) => {
                                      return (<option value={result.id}>{result.plan} ${result.amount}</option>)
                                    })}
                                </select>
                                <font className="errorMsg">{this.state.errors['plan_error']}</font>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Credit Card<span className="requiredastrik">*</span></label>
                                <div className="creditcardsection">
                                  <CardElement options={{ hidePostalCode: true }}  onChange={(element) => this.stripeElementChange(element, 'card_number')} />
                                </div>
                                <font className="errorMsg">{this.state.cardError}</font>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 notmainbtn customtabsbtns">
                              <button type="button" onClick={ this.onUpgradeProfile.bind(this)} className="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
                            </div>
                          </div>
                        </div>:""}
										</div>
									</div>
								</div>
								<div className="box-footer clearfix"></div>
							</div>
						</div>
					</div>
				</form>
				}
				<div id="myModal" className="modal fade imgmodal" role="dialog">
					<div className="modal-dialog">
						<div className="modal-content">
							<button type="button" className="btn btn-default closeicon" data-dismiss="modal">
								<span aria-hidden="true">&times;</span>
							</button>
							<div className="modal-body">
								<img className="showimage img-responsive" style={{ width: '100px', height: '100px' }} src={this.state.urlvalinitial} />
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</div>
</React.Fragment>
);
}
}
export default function InjectedEventBooking({ match }) {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <EditUser match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}

