import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../config/constants.js';
import ls from 'local-storage';
import  { Link, NavLink } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import $ from 'jquery'; 
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../../includes/Spinner.component';
import { CKEditor } from 'ckeditor4-react';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
toast.configure();
const errors = {};
export default class EmailIndex extends Component {
	constructor(props) {
    	super(props);
    	this.state = {
    		userList:[],
    		loader: false,
    		disabled: false,
    		btnSubmitText: 'Send Email',
    		editorState:'',
    		errors:[],
    		utype:'',
    		etype:'',
    		user:'',
    		subject:'',
    		body:'',
    		userListData:[],
    		disabledSelect:'',
    	}
    }
    componentDidMount() {
	  	document.title = "CONQER Music - Email Notification";
	}
	changeUserType = (e) => {
		if (this.state.errors['utype_error']) {delete this.state.errors['utype_error'];}
		this.setState({
			utype: e.target.value
		})
		this.getUserList(e.target.value);
	}
	getUserList = (utype) => {
		let formData = {
			"utype":utype
		}
		Axios.post(base_api_url + 'user-email.php',formData, {axiosConfig}).then(
	        (res) => {
	          	if (res.data.res_status == 200) {
	          		this.setState({
	              		userListData: res.data.res_data
	            	});
	          	}else{
	          		this.setState({
	              		userListData: [],
	            	});
	          	}
	        }
	    );
	}
	changeEType = (e) => {
		if (this.state.errors['etype_error']) {delete this.state.errors['etype_error'];}
		if (this.state.errors['user_error']) {delete this.state.errors['user_error'];}
		this.setState({
			etype: e.target.value
		})
		if(e.target.value === 'all'){
			this.setState({
				disabledSelect: 'disabled',
				user:''
			})
		}else{
			this.setState({
				disabledSelect: ''
			})
		}
	}
	changeUser = (e) => {
		if (this.state.errors['user_error']) {delete this.state.errors['user_error'];}
		this.setState({
			user: e.target.value
		})
	}
	changeSubject = (e) => {
		if (this.state.errors['subject_error']) {delete this.state.errors['subject_error'];}
		this.setState({
			subject: e.target.value
		})
	}
	onChangeBody = (e) => {
		if (this.state.errors['body_error']) {delete this.state.errors['body_error'];}
		this.setState({
			body: e.editor.getData()
		})
	}
	onFormSubmit(e){
		e.preventDefault();
		if (this.handleValidation()) {
			this.setState({
		        disabled: true,
		        btnSubmitText: 'Please Wait...'
		    });
		    let emailData = {
		    	"emailType": this.state.etype,
		    	"email": this.state.user,
		    	"subject": this.state.subject,
		    	"body": this.state.body,
		    	"user_type": this.state.utype
		    };
		    Axios.post(base_api_url + 'send-email.php',emailData, {axiosConfig}).then(
	        (res) => {
	          	if (res.data.res_status == 200) {
	          		toast.success(res.data.res_message, {
				      onClose: () => window.location.reload()
				    });
	          	}else{
	          		toast.error(res.data.res_message);
	          	}
	          	this.setState({
			        disabled: false,
			        btnSubmitText: 'Send Email'
			    });
	        });
		}
	}
	handleValidation = () => {
		let formIsValid = true;
		if (!this.state.utype) {
		  	formIsValid = false;
		  	errors['utype_error'] = 'Please select user type.';
		}
		if (!this.state.etype) {
		  	formIsValid = false;
		  	errors['etype_error'] = 'Please choose email type.';
		}
		if (!this.state.user && this.state.etype === 'indivisual') {
		  	formIsValid = false;
		  	errors['user_error'] = 'Please select user.';
		}
		if (!this.state.subject) {
		  	formIsValid = false;
		  	errors['subject_error'] = 'Please enter email subject.';
		}
		if (!this.state.body) {
		  	formIsValid = false;
		  	errors['body_error'] = 'Please enter email body.';
		}
		this.setState({ errors: errors });
    	return formIsValid;
	}
	clearForm(){
		this.setState({
			utype:'',
    		etype:'',
    		user:'',
    		subject:'',
    		body:'',
		});
	}
	render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
				{(this.state.loader === true)?<Spinner />:
    			<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">Email</h3>            
							</div>
							<div className="box-body no-padding"> 
								<div className="nav-tabs-custom">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" onSubmit={this.onFormSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>Email</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>User Type<span className="requiredastrik">*</span></label>
															<select className="form-control" onChange={this.changeUserType.bind(this)} value={this.state.utype}>
																<option value="">--Select User Type--</option>
																<option value="artist">Artist</option>
																<option value="listener">Listener</option>
																<option value="old_listener">Old Listener</option>
															</select>
															<font className="errorMsg">{this.state.errors['utype_error']}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="eType">Email Type<span className="requiredastrik">*</span></label><br />
															<div className="custom-control custom-radio custom-control-inline">
																<input type="radio" id="radioEtypeT" value="indivisual" name="eType" onChange={this.changeEType.bind(this)} className="custom-control-input" />
																<label className="custom-control-label" htmlFor="radioEtypeT">individual</label>
															</div>
															<div className="custom-control custom-radio custom-control-inline">
																<input type="radio" id="radioEtypeO" value="all" name="eType" onChange={this.changeEType.bind(this)} className="custom-control-input" />
																<label className="custom-control-label" htmlFor="radioEtypeO">Send to all selected user type</label>
															</div>
															<br />
															<font className="errorMsg">{this.state.errors['etype_error']}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>User<span className="requiredastrik">*</span></label>
															<select className="form-control" disabled={this.state.disabledSelect} onChange={this.changeUser.bind(this)} value={this.state.user} >
																<option value="">--Select User--</option>
																{this.state.userListData.map(result => {
																	return(
																		<option value={result.email}>{result.name+' ['+result.email+']'}</option>
																	);
																})}
															</select>
															<font className="errorMsg">{this.state.errors['user_error']}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label>Subject<span className="requiredastrik">*</span></label>
															<input type="text" className="form-control" onChange={this.changeSubject.bind(this)} value={this.state.subject} placeholder="Subject"/>
															<font className="errorMsg">{this.state.errors['subject_error']}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
														<label>Body<span className="requiredastrik">*</span></label>
														<div ref={ this.myRef } />
														<CKEditor
											              	onChange={this.onChangeBody.bind(this)}/>
											              <font className="errorMsg">{this.state.errors['body_error']}</font>
											            </div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6 mt-4">
														<button type="submit" style={{'padding':'5px 16px'}} disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>           
							</div>							
						</div>
					</div>        
				</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}