import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import { playAudio } from '../includes/playAudio.component';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import defaultimg from '../components/default.png';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
import createHistory from 'history/createBrowserHistory';

const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
const history = createHistory();
export default class MySongs extends Component {
  constructor(props) {
    super(props);
    this.audioRefs = React.createRef();
    this.state = {
      email: '',
      role: null,
      userid: null,
      token: '',
      error: null,
      msg: null,
      songlist: [],
      items: [],
      loading: true,
      loader: true,
      successclass: null,
      redirect: false,
      isPlaying: false,
      currentSong: '',
      album_name: '',
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Songs';

    const email = ls.get('email');
    const name = ls.get('name');
    const token = ls.get('token');
    const userid = ls.get('userid');
    let message = '';
    if (this.props.location.state !== null && this.props.location.state !== undefined) {
      message = this.props.location.state.message;
    }
    this.setState({
      email: email,
      name: name,
      token: token,
      userid: userid,
      msg: message,
      successclass: 'successmsg'
    });
    if(message){
      let state = { ...history.location.state };
        delete state.message;
        history.replace({ ...history.location, state });
        setTimeout(() => {
        this.setState({
          successclass: null,
          msg: null
        });
      }, 5000);
    }
    this.getallsonglist();
  }
  getallsonglist = () => {
    const obj = {
      albumid: this.props.match.params.id
    };

    Axios.post(base_api_url + 'artistalbumsong.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        const returndata = res.data.res_data;
        this.setState({
          songlist: returndata,
          album_name: res.data.album_data,
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
      setTimeout(() => {
        $('#mysongs').DataTable({
        pageLength: 10,
        destroy: true,
		stateSave: true,
		language: {
			search: "Search"
		},
        lengthMenu: [
          [5, 10, 15, -1],
          [5, 10, 15, 'All']
        ]
      });
      }, 500);
      
    });
  };


  deletealbumsong = (songid, status) => {
    const obj = {
      id: songid,
      status: status
    };
    console.log('obj', obj);
    Axios.post(base_api_url + 'updatestatussong.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status == 200) {
        this.setState({
          successclass: 'successmsg',
          msg: 'Song status updated successfully!'
        });
      } else {
        this.setState({
          successclass: 'errormsg',
          msg: 'Song status not updated, please try again!'
        });
      }
      setTimeout(() => {
        this.getallsonglist();
        this.setState({
          successclass: null,
          msg: null
        });
      }, 5000);
    });
  };

  deletesong = (songid) => {
    const confirmBox = window.confirm('Do you really want to delete this song?');

    if (confirmBox === true) {
      const obj = {
        userid: ls.get('userid'),
        songid: songid
      };

      Axios.post(base_api_url + 'deletesong.php', obj, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          const returndata = res.data.res_web_data;
          this.setState({
            successclass: 'successmsg',
            msg: 'Song deleted successfully!'
          });
        } else {
          this.setState({
            successclass: 'errormsg',
            msg: 'Song not deleted, please try again!'
          });
        }
        setTimeout(() => {
          this.setState({
            successclass: null,
            msg: null
          });
        }, 5000);
      });
      window.location.reload();
    }
  };

  onclickseturl = (urlval) => {
    this.setState({
      urlvalinitial: urlval
    });
    $('#myModal').modal('show');
  };
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };

  playHandler = (song) => {
    if (this.state.currentSong.id === song.id) {
      this.setState(
        {
          isPlaying: !this.state.isPlaying,
          currentSong: song
        },
        () => playAudio(this.state.isPlaying, this.audioRefs)
      );
    } else {
      this.setState(
        {
          isPlaying: true,
          currentSong: song
        },
        () => playAudio(this.state.isPlaying, this.audioRefs)
      );
    }
  };

  render() {
    let albumid = this.props.match.params.id;
    if (this.state.loading == true) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
        {this.state.msg ? <div className={this.state.successclass}>{this.state.msg}</div> : ''}
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">{this.state.album_name}</h3>

                <div className="box-tools">
                  <NavLink to={'/add-song/' + albumid} className="btn btn-primary aadersongs">
                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add New Song
                  </NavLink>
                  <NavLink to={'/my-albums/' + this.state.token} className="btn btn-primary">
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
                  </NavLink>
                </div>
              </div>
              <div className="box-body table-responsive  no-padding">
                <table id="mysongs" className="table table-striped table-bordered table-hover table-sm row-border hover mb-5">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Cover</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Approval</th>
                      <th>Status</th>
                      <th className="actionbtns text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.songlist.map((result, key) => {
                      var albumimage = result.cover_image;
                      let mysongurl = '/mysongs/' + result.id;
                      let playTitle, playClassName;
                      if (this.state.currentSong.id === result.id && this.state.isPlaying) {
                        playClassName = 'fa fa-pause';
                        playTitle = 'Pause Song';
                      } else {
                        playClassName = 'fa fa-play';
                        playTitle = 'Play Song';
                      }

                      return (
                        <tr>
                          <td>{result.title}</td>
                          <td>
                            <img
                              onError={this.addDefaultSrc}
                              src={albumimage}
                              width="50"
                              height="50"
                              onClick={() => this.onclickseturl(albumimage)}
                            />
                          </td>
                          <td>{result.releases}</td>
                          <td>{result.date}</td>
                          <td>
                            {result.song_astatus == '0' ? (
                              <span className="label label-danger">Not approved</span>
                            ) : (
                              <span className="label label-success">Approved</span>
                            )}
                          </td>
                          <td>
                            {result.song_status == '1' ? (
                              <span className="label label-success">Active</span>
                            ) : (
                              <span className="label label-danger">Inactive</span>
                            )}
                          </td>
                          <td className="actionbtns" style={{'textAlign':'center'}}>
                            <Link to={'/edit-song/' + result.id} title="Edit Song" className="btn btn-info custombtn">
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                            </Link>{' '}
                            {result.status === '1' ? (
                              <a href="javascript:;" onClick={() => this.deletesong(result.id)} className="btn btn-danger custombtn">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </a>
                            ) : (
                              ''
                            )}
                            &nbsp;
                            <a
                              href="javascript:;"
                              title={result.song_status == '1' ? 'In Active' : 'Active'}
                              onClick={() => this.deletealbumsong(result.id, result.song_status)}
                              className="btn btn-info custombtn"
                            >
                              {result.song_status === '1' ? (
                                <i className="fa fa-ban" aria-hidden="true"></i>
                              ) : (
                                <i className="fa fa-check" aria-hidden="true"></i>
                              )}
                            </a>
                            &nbsp;
                            <Link onClick={() => this.playHandler(result)} className="btn btn-info custombtn">
                              <i className={playClassName} title={playTitle}>
                                <audio ref={this.audioRefs} src={this.state.currentSong.songfile}></audio>
                              </i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div id="myModal" className="modal fade imgmodal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <button type="button" className="btn btn-default closeicon" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-body">
                <img className="showimage img-responsive" src={this.state.urlvalinitial} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
