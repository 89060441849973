import React, { Component } from 'react';
import Axios from 'axios';
import  { Link  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
const base_api_url = API_URL;
const axiosConfig = {
	  'Content-Type': 'multipart/form-data',
	  "Access-Control-Allow-Origin": "*",
}
export default class CollaboratingPartners extends Component {
	constructor(props) {
		super(props);
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Collaborating Partners";
	}
	
	
	render() {
		return (
			<React.Fragment>
				<div id="slider-home" className="collbrationpge">
				<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
				  <div className="carousel-inner">
					<div className="carousel-item active">
					  <img src={process.env.PUBLIC_URL + 'assets/slider/collbration.jpg'} alt="banner" />
					  <div className="title">
						<h1 className="animated slideInDown">COLLABORATING <span>PARTNERS</span></h1>
					  </div>
					  
					</div>
					<div className="carousel-item">
					  <img src={process.env.PUBLIC_URL + 'assets/slider/collbration.jpg'} alt="banner"  />
					  <div className="title">
						<h1 className="animated slideInDown">COLLABORATING <span>PARTNERS</span></h1>
					  </div>
					  
					</div>
				  </div>
				</div>
			</div>
			<section id="collbratingicons">
				<div class="collbratingiconsss">
				  <div class="container-fluid">
				    <ul>
				      <li>
				        <div class="logoinside c-construction">
				        <a target="_blank" href="https://conquerstrong.com" title="Conquer Construction">
				          <img src={process.env.PUBLIC_URL + '/assets/partners/conqer-construction.png'}  />
				          <div class="nameofthecompany">{/*Conquer Construction*/}</div>
				          </a>
				        </div>
				      </li>
				      <li>
				        <div class="logoinside htl">
				        <a target="_blank" href="https://hashtaglabs.biz" title="Hashtag Labs">
				          <img src={process.env.PUBLIC_URL + '/assets/partners/wFinal-Logo.png'} />
				          <div class="nameofthecompany">{/*Hashtag Labs*/}</div>
				          </a>
				        </div>
				        
				      </li>
				      <li>
				        <div class="logoinside cea">
				          <img src={process.env.PUBLIC_URL + '/assets/partners/newlogo.png'} />
				          <div class="nameofthecompany">{/*Conquer Entrepreneur Academy*/}</div>
				        </div>
				      </li>
				      <li>
				        <div class="logoinside c-cleaning">
				        <a target="_blank" href="https://conquercleaningservices.com" title="Conquer Cleaning">
				          <img src={process.env.PUBLIC_URL + '/assets/partners/conqer-cleaning.png'} />
				          <div class="nameofthecompany">{/*Conquer Cleaning*/}</div>
				          </a>
				        </div>
				      </li>
				      <li>
				        <div class="logoinside c-cleaning">
				        <a target="_blank" href="https://davisspecializedservices.com/" title="Davis Specialized Services">
				          <img src={process.env.PUBLIC_URL + '/assets/partners/davis.png'} style={{"height":"125px"}}/>
				          <div class="nameofthecompany">{/*Conquer Cleaning*/}</div>
				          </a>
				        </div>
				      </li>
				    </ul>
				    
				  </div>
				</div>
			</section>
			</React.Fragment>
		)
	}
}