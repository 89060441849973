import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

function Example({ handleShow, show, data }) {
  return (
    <>
      <Modal show={show} onHide={() => handleShow(false, null)}>
        <Modal.Header closeButton>
          <Modal.Title>{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{data.message}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleShow(false, null)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;
