import React, { Component } from 'react';
import Axios from 'axios';
import { Form } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import DatePicker from 'react-datepicker';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import $ from 'jquery';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};

export default class SongReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userid: null,
      token: '',
      error: null,
      msg: null,
      reportlist: [],
      loading: true,
      loader: null,
      redirect: false,
      hideCustom: true,
      dataType: 'all',
      startdate: '',
      endDate: '',
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Songs Information';
    const token = ls.get('token');
    const userid = ls.get('userid');

    this.setState({
      token: token,
      userid: userid,
    });

    this.getallreportlist();
  }
  componentDidUpdate() {
    //this.getallreportlist();
  }
  changeData = (e) => {
    this.setState({ dataType: e.target.value }, () => {
      if (this.state.dataType !== 'custom') {
        this.getallreportlist();
        this.setState({ hideCustom: true });
      } else {
        this.setState({ hideCustom: false });
      }
    });
  };
  onChangeStartDate = (e) => {
    this.setState({ startdate: e });
  };
  onChangeEndDate = (e) => {
    this.setState({ endDate: e });
  };
  getallreportlist = () => {
    let obj = {};
    this.setState({loading:true});
    if (this.state.dataType !== 'custom') {
      obj = {
        artist_id: ls.get('userid'),
        type: this.state.dataType,
      };
    } else {
      obj = {
        artist_id: ls.get('userid'),
        type: this.state.dataType,

        from: this.state.startdate.getFullYear() + '-' + (this.state.startdate.getMonth() + 1) + '-' + this.state.startdate.getDate(),
        to: this.state.endDate.getFullYear() + '-' + (this.state.endDate.getMonth() + 1) + '-' + this.state.endDate.getDate(),
      };
    }
    Axios.post(base_api_url + 'songreports.php', obj, { axiosConfig }).then((res) => {
      $('#songreports').DataTable().destroy();
      setTimeout(() => {
        $('#songreports').DataTable({
          destroy: true,
		  stateSave: true,
          processing: true,
          pageLength: 10,
		  language: {
			search: "Search"
		  },
          lengthMenu: [
            [5, 10, 15, -1],
            [5, 10, 15, 'All'],
          ],
          dom: 'Bfrtip',
          buttons: ['csv', 'print'],
		  "columnDefs": [
				{ "type": "num-fmt", "targets": 1, "className": "text-center", "appliesTo": "print" },
				{ "type": "num-fmt", "targets": 2, "className": "text-center", "appliesTo": "print" },
				{ "type": "num-fmt", "targets": 3, "className": "text-center", "appliesTo": "print" },
				{ "type": "num-fmt", "targets": 4, "className": "text-center", "appliesTo": "print" },
			],
        });
      }, 100);
      if (res.data.res_status == 200) {
        const returndata = res.data.res_data;
        this.setState({
          reportlist: returndata,
          loading: false,
        });
      } else {
        this.setState({
          reportlist: [],
          loading: false,
        });
      }
    });
  };

  render() {
    let maxlimit = '30';
    return (
      <React.Fragment>
        <div className={this.state.successclassName}>{this.state.msg}</div>
		{(this.state.loading == true)? <Spinner />:
			<div className="row">
			  <div className="col-md-12">
				<div className="box box-success distributions-profileform eventsreports">
				  <div className="box-header with-border addprofilenew">
					<h3 className="box-title">Songs Information</h3>
					<div className="box-tools d-flex justify-content-between songreportpage">                  
					  <select class="filteroptionselect"
						as="select"
						value={this.state.dataType}
						onChange={(e) => this.changeData(e)}
					  >
						<option value="all">All</option>
						<option value="monthly">Current Month</option>
						<option value="yearly">Current Year</option>                    
						<option value="custom">Custom</option>
					  </select>
					  <span className="firstrow">
						<DatePicker
						  selected={this.state.startdate}
						  onChange={(e) => this.onChangeStartDate(e)}
						  name="startdate"
						  dateFormat="MM/dd/yyyy"
						  dropdownMode="select"
						  showMonthDropdown
						  showYearDropdown
						  adjustDateOnChange
						  required={true}
						  placeholderText="From"
						  className={this.state.hideCustom ? 'd-none ' : 'form-control'}
						  id="startdate"
						  onKeyDown={e => e.preventDefault()}
						/>
					  </span>
					  <span className="firstrow">
						<DatePicker
						  selected={this.state.endDate}
						  onChange={(e) => this.onChangeEndDate(e)}
						  name="End Date"
						  dateFormat="MM/dd/yyyy"
						  dropdownMode="select"
						  showMonthDropdown
						  showYearDropdown
						  adjustDateOnChange
						  required={true}
						  minDate={new Date(this.state.startdate)}
						  placeholderText="To"
						  className={this.state.hideCustom ? 'd-none ' : 'form-control'}
						  id="startdate"
						  onKeyDown={e => e.preventDefault()}
						/>
					  </span>
					  <button className={this.state.hideCustom ? 'd-none ' : 'btn pull-right btn-primary'} onClick={() => this.getallreportlist()}>
						Go
					  </button>
					</div>
					<div className="box-tools saearchicons">
					  <div className="input-group input-group-sm hidden-xs"></div>
					</div>
				  </div>
				  <div className="box-body no-padding">
					<div className="table table-responsive no-margin table-striped table-hover">
					  <table id="songreports" className="table table-striped table-bordered table-sm row-border hover">
						<thead>
						  <tr>
							<th>Title</th>
							<th className="text-center">ISRC</th>
							<th className="text-center">Plays</th>
							<th className="text-center">Rate</th>
							<th className="text-center">Total</th>
						  </tr>
						</thead>
						<tbody>
						  {this.state.reportlist.map((result) => {
							return (
							  <tr>
								<td>{result.title}</td>
								<td className="text-center">{result.ISRC_code}</td>
								<td className="text-center">{result.listened_count}</td>
								<td className="text-center">{result.rate}</td>
								<td className="text-center">{result.song_rev}</td>
							  </tr>
							);
						  })}
						</tbody>
					  </table>
					</div>
				  </div>
				  <div className="box-footer clearfix"></div>
				</div>
			  </div>
			</div>
		}
      </React.Fragment>
    );
  }
}
