import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link, withRouter } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Spinner from '../../../includes/Spinner.component';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
export default class EventDetailPayment extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	eventData:[]
      }
    }
    getEventListData(){
    	const obj = {
			user_id:this.props.match.params.id
		}
    	const axiosConfig = {
      		'Content-Type': 'multipart/form-data',
      		"Access-Control-Allow-Origin": "*",
    	};
	    Axios.post(API_URL + 'eventdetailrevenue.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
				
		        if(res.data.res_status === "200"){
		        	$('#myevents').DataTable().destroy();
		        	$(document).ready(function () {
				        setTimeout(function(){
				        $('#myevents').DataTable({
				        	"destroy": true,
							"stateSave": true,
							"processing": true,
							"pageLength": 10,
	  						"lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100,  "All"]],
							"dom": 'Bfrtip',
							"language": {
								"search": "Search"
							},
							"buttons": [
								{
									"extend": 'csv',
									"exportOptions": {
										"columns": [ 0,1,2 ]
									}
								},
								{
									"extend": 'print',
									"exportOptions": {
										"columns": [ 0,1,2 ]
									}
								}
							],
							"columnDefs": [
								{ "type": "num-fmt", "targets": 1, "className": "text-center", "appliesTo": "print" },
								{ "type": "num-fmt", "targets": 2, "className": "text-center", "appliesTo": "print" },
							],							
				        });
				         } ,50);
				    });
		        	this.setState({
		        		eventData: res.data.res_data,
						loader: false
					});				         
		        }else{
					this.setState({
		        		eventData: [],
						loader: false
					});
				} 
		                
	    	}
	    )
    }
    componentDidMount(){
		document.title = "Artist Event Revenue";
    	this.getEventListData();

    }
    
    render() {
		console.log('eventData',this.state.eventData);
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title">Artist Event Revenue List</h3>
									<div className="box-tools">
									  <Link to='#' className="btn btn-primary" onClick={this.props.history.goBack}><i className="fa fa-angle-double-left"></i>&nbsp;Back</Link>
									</div>
								</div>
								<div className="box-body no-padding">
								  <div className="table-responsive">
								<table id="myevents" className="table no-margin table-striped table-hover">
									<thead>
									  <tr>
										<th>Event Title</th>                    
										<th className="text-center">From Attendee</th>
										<th className="text-center">From Listener</th>
									  </tr>
									</thead>
									<tbody>
									{this.state.eventData.map((result, i) => {
										if(result.atn_rev !== '$0' || result.listener_rev !== '$0'){
											return (
												<tr>
													<td>{result.event_name}</td>
													<td className="text-center">{result.atn_rev}</td>
													<td className="text-center">{result.listener_rev}</td>
												  </tr>
											)
										}
									})}
									  
									</tbody>
								</table>
							  </div>              
							</div>
							</div>
						</div>        
					</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}