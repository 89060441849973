import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect, Switch, Route } from 'react-router-dom';
import ls from 'local-storage';

import ArtistTopMenu from '../includes/artisttopmenu.component';
import ListenerSideMenu from '../includes/listenersidemenu.component';
import FreeSideMenu from '../includes/freesidemenu.component';
import playListDetails from './playListDetails';
import MyLoggedinDevices from './myloggedindevices';
import playListSongs from './playListSongs';
import ListenerDashboard from './listenerdashboard.component';
import listenerNotify from './listenerNotification';
import ListenerProfile from './listenerprofile.component';
import EditListenerProfile from './editlistenerprofile.component';
import EventDetail from './eventdetail.component';
import bookTicket from './bookTickets.component';
import EditPassword from './editpassword.component';

import ListenerChangeCardDetails from './listenerchangecarddetails.component';
import UpdatePlan from './updateplan.component';

import ListenerPrayerline from './listenerprayerline.component';
import ListenerViewPrayer from './listenerviewprayer.component';
import topSongs from './topsongs.component';
import topArtists from './topartists.component';
import allArtist from './allArtists';
import featureArtists from './featureArtists';
import artistSingleSong from './artistsinglesong.component';
import albumSingleSong from './albumsinglesong.component';
import topSingleSong from './topsinglesong.component';
import favoriteSongs from './favoritesongs.component';
import recentSongs from './recentsongs.component';
import featuredSongs from './featuredsongs.component';
import AddPlaylist from './addPlaylist.component';
import EditPlaylist from './editPlaylist.component';
import ListenerFollowers from './listenerfollowers.component';
import ListenerMyEvents from './listenermyevents.component';
import ListenerMyBooking from './listenermybooking.component';
import MyMusics from './mymusics.component';
import ListenerAlbumList from './listenerAlbumList';
import Logout from './logout.component';
import LogoutFromAll from './logoutfromall.component';
export default class ListenerLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const userid = ls.get('userid');
    if(ls.get('role') === '2'){
      return <Redirect to="/" />;
    }
    if (userid === '') {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div className="dashboard-wrapper">
          {ls.get('membership_status') == 'Active' ? <ListenerSideMenu /> : <FreeSideMenu />}
          <div className="maincontent-area">
            <ArtistTopMenu />
            <div className="main-dashsection">
              <section className="contentpard">
                <Switch>
                  <Route exact path="/listener-dashboard" component={ListenerDashboard} />
                  <Route exact path="/listener-profile/:id" component={ListenerProfile} />
                  <Route exact path="/edit-listener-profile/:id" component={EditListenerProfile} />
                  <Route exact path="/lslogout" component={Logout} />
                  <Route exact path="/lslogoutfromall" component={LogoutFromAll} />
                  <Route exact path="/edit-password/:id" component={EditPassword} />
                  <Route exact path="/listner-prayer-line/:id" component={ListenerPrayerline} />
                  <Route exact path="/listenerchange-card-details/" component={ListenerChangeCardDetails} />
                  <Route exact path="/listener-change-plan/" component={UpdatePlan} />
                  <Route exact path="/listner-view-prayer-request/:id" component={ListenerViewPrayer} />
                  <Route exact path="/listener-followers/:id" component={ListenerFollowers} />
                  <Route exact path="/listener-myevents/:id" component={ListenerMyEvents} />
                  <Route exact path="/listener-eventbooking/" component={ListenerMyBooking} />
                  <Route exact path="/listener-viewevent/:id" component={EventDetail} />
                  <Route exact path="/listener-assong/:id" component={artistSingleSong} />
                  <Route exact path="/albumsongs/:id" component={albumSingleSong} />
                  <Route exact path="/topssongs/:id" component={topSingleSong} />
                  <Route exact path="/my-music/" component={MyMusics} />
                  <Route exact path="/listener-topsongs/" component={topSongs} />
                  <Route exact path="/listener-topartists/" component={topArtists} />
                  <Route exact path="/book-ticket/:id" component={bookTicket} />
                  <Route exact path="/playlist-listener/:id" component={playListDetails} />
                  <Route exact path="/myloggedindevices/" component={MyLoggedinDevices} />
                  <Route exact path="/playlist-songs/:id" component={playListSongs} />
                  <Route exact path="/listener-favoritesongs/" component={favoriteSongs} />
                  <Route exact path="/listener-recentsongs/" component={recentSongs} />
                  <Route exact path="/listener-featuredsongs/" component={featuredSongs} />
                  <Route exact path="/listener-notification" component={listenerNotify} />
                  <Route exact path="/add-playlist/:id" component={AddPlaylist} />
                  <Route exact path="/edit-playlist/:id" component={EditPlaylist} />
                  <Route exact path="/album-list" component={ListenerAlbumList} />
                  <Route exact path="/featured-artist" component={featureArtists} />
                  <Route exact path="/artist-list" component={allArtist} />
                </Switch>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
