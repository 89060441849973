import React, { Component } from 'react';
import { withRouter, Redirect, Link, NavLink } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import ls from 'local-storage';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { addDays, subDays, setHours, setMinutes } from 'date-fns';
import { Spinner } from 'react-bootstrap';
import $ from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
const errors = {};
const mapStyles = {
  width: '97%',
  height: '365px',
  position: 'relative'
};
export class AddEvent extends Component {
	constructor(props) {
		super(props);
		this.state = {
	      startDateTime: new Date(),
	      endDateTime: new Date(),
	      startBookingDate: new Date(),
	      endBookingDate:new Date()
	    };
	    this.handleStartChange = this.handleStartChange.bind(this);
	    this.handleEndChange = this.handleEndChange.bind(this);
	    this.handleBookingChange = this.handleBookingChange.bind(this);
	    this.handleEndBookingChange = this.handleEndBookingChange.bind(this);

		this.onChangeEventTitle = this.onChangeEventTitle.bind(this);
		this.onChangeAlias = this.onChangeAlias.bind(this);
		this.onChangeEventCategory = this.onChangeEventCategory.bind(this);
		this.onChangeAccess = this.onChangeAccess.bind(this);
		this.onChangeAllowPermissoin = this.onChangeAllowPermissoin.bind(this);
		this.onChangeEventVenue = this.onChangeEventVenue.bind(this);
		this.onChangeEventImage = this.onChangeEventImage.bind(this);
		this.onChangeEventKeyword = this.onChangeEventKeyword.bind(this);
		this.onChangeEventMetaDescription = this.onChangeEventMetaDescription.bind(this);
		this.onChangeEventDescription = this.onChangeEventDescription.bind(this);
		this.onSubmit = this.onSubmit.bind(this);		

		var today = new Date();
		var date = '';
		var date2 = '';
		var year = today.getFullYear();
		var month = today.getMonth() + 1;
		var todaydate = today.getDate();
		if (month < 10) {
			var month = '0' + month;
		}

		if (todaydate < 10) {
			var todaydate = '0' + todaydate;
		}

		date = year + '-' + month + '-' + todaydate + 'T00:00';
		date2 = year + '-' + month + '-' + todaydate;
		this.state = {
			eventtitle: '',
			alias: '',
			startDateTime: '',
			endDateTime: '',
			eventcategory: '',
			access: '',
			allowpermission: '',
			venue: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			country: '',
			eventimage: '',
			eventkeyword: '',
			eventmetadescription: '',
			description: '',
			bookingstart: date2,
			bookingend: '',
			error: '',
			msg: '',
			successclass: '',
			redirect: false,
			eventdetailform: true,
			eventticketform: false,
			eventgalleryform: false,
			detailclass: 'active',
			selectedcover: 'Choose File',
			selectedgallery: 'Choose File',
			submitbuttontext: 'Save & Next',
			submitbuttontextw: 'Save',
			submitbuttontextt: 'Save & Next',
			btndisable:false,
			currentDate: date,
			endDate: '',
			bookingStartDate: '',
			bookingstmaxdate: date2,
			bookingEndDate: '',
			ticketenddate: '',
			errors:[],
			countrylist:[],
			eventcatlist:[],
			add_lat:'0',
			add_lng:'0',
			event_id:'',
			stepSecond: false,
			emptySSN: false
		}
	}
	handleStartChange(date) {
		if(this.state.errors['s_date_error']){delete this.state.errors['s_date_error'];}
	    this.setState({
	      startDateTime: date,
	      endDateTime:'',
	      startBookingDate: '',
	      endBookingDate: ''
	    })
	}
	handleEndChange(date) {
		if(this.state.errors['e_date_error']){delete this.state.errors['e_date_error'];}
	    this.setState({
	      endDateTime: date,
	      startBookingDate: '',
	      endBookingDate: ''
	    })
	}
	handleBookingChange(date){
		if(this.state.errors['b_start_error']){delete this.state.errors['b_start_error'];}
		this.setState({
	      startBookingDate: date,
	      endBookingDate: ''
	    })
	}
	handleEndBookingChange(date){
		if(this.state.errors['b_end_error']){delete this.state.errors['b_end_error'];}
		this.setState({
	      endBookingDate: date
	    })
	}
	componentDidMount() {
		document.title = "CONQER Music - Add event";
		Geocode.setApiKey("AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0");
		Geocode.setLanguage("en");
		this.getcountrylist();
		this.geteventcatlist();
		this.checkSSN(ls.get('userid'));
	}
	checkSSN = (_userID) => {
		Axios.post(base_api_url + 'check-ssn.php', {userid: _userID}).then((res) => {
		    if (res.data.res_status == 200) {
	    		this.setState({
	    			emptySSN: !res.data.ssn
	    		});
		    }
	    });
	}
	getcountrylist = () => {
		Axios.post(base_api_url + 'country.php')
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						this.setState({
							countrylist: res.data.res_data
						});
					} else {
						this.setState({
							countrylist: []
						});
					}
				}
			);
	}
	geteventcatlist = () => {
		Axios.post(base_api_url + 'eventcat.php')
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						eventcatlist: res.data.res_data
					});
				} else {
					this.setState({
						eventcatlist: []
					});
				}
			}
		);
	}

	getAddress(){
		let address = this.state.address+' '+this.state.city+' '+this.state.state+' '+this.state.country+' '+this.state.zip;
		Geocode.fromAddress(address).then(
		  (response) => {
		    const { lat, lng } = response.results[0].geometry.location;
		    this.setState({
		    	add_lat:lat,
		    	add_lng:lng
		    });
		  },
		  (error) => {
		    console.error(error);
		  }
		);
	}

	onChangeEventTitle(e) {
		if(this.state.errors['title_error']){delete this.state.errors['title_error'];}
		this.setState({
			eventtitle: e.target.value
		});
	}

	onChangeAlias(e) {
		this.setState({
			alias: e.target.value
		})
	}

	onChangeEventCategory(e) {
		if(this.state.errors['event_cat_error']){delete this.state.errors['event_cat_error'];}
		this.setState({
			eventcategory: e.target.value
		})
	}

	onChangeAccess(e) {
		if(this.state.errors['event_access_error']){delete this.state.errors['event_access_error'];}
		this.setState({
			access: e.target.value
		})
	}

	onChangeAllowPermissoin(e) {
		this.setState({
			allowpermission: e.target.value
		})
	}

	onChangeEventVenue(e) {
		if(this.state.errors['event_venue_error']){delete this.state.errors['event_venue_error'];}
		this.setState({
			venue: e.target.value
		})
	}

	onChangeAddress(e) {
		if(this.state.errors['address_error']){delete this.state.errors['address_error'];}
		this.setState({
			address: e.target.value
		})
	}
	onChangeCity(e) {
		if(this.state.errors['city_error']){delete this.state.errors['city_error'];}
		this.setState({
			city: e.target.value
		})
	}
	onChangeState(e) {
		if(this.state.errors['state_error']){delete this.state.errors['state_error'];}
		this.setState({
			state: e.target.value
		})
	}
	onChangeZip(e) {
		if(this.state.errors['zip_error']){delete this.state.errors['zip_error'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({
				zip: e.target.value
			})
		}
	}
	moveMarker = (coord) => {
	    const { latLng } = coord;
	    this.setState({
	    	add_lat:latLng.lat(),
	    	add_lng: latLng.lng()
	    });	    
	};
	onChangeCountry(e) {
		if(this.state.errors['country_error']){delete this.state.errors['country_error'];}
		this.setState({
			country: e.target.value
		})
	}

	onChangeEventImage(e) {
		if(e.target.files.length >0){
			this.setState({ selectedcover: e.target.files[0].name, eventimage: e.target.files[0] });
		}
	}

	onChangeEventKeyword(e) {
		this.setState({
			eventkeyword: e.target.value
		})
	}

	onChangeEventMetaDescription(e) {
		this.setState({
			eventmetadescription: e.target.value
		})
	}

	onChangeEventDescription(e) {
		let value 	= e.target.value;
		value.trim();
		errors['description_error'] = '';
		if(value.trim()){
			if(this.state.errors['description_error']){delete this.state.errors['description_error'];}
			this.setState({ description: e.target.value, errors : errors})
		}else{
			this.setState({description:'', errors : errors});
		}
	}
	handleValidation(){
		let formIsValid = true;
    	
		if(!this.state.eventtitle){
			formIsValid = false;
			errors["title_error"] = "Please enter event title.";
		}

		if(!this.state.startDateTime){
			formIsValid = false;
			errors["s_date_error"] = "Please select event start date & time.";
		}
		if(!this.state.endDateTime){
			formIsValid = false;
			errors["e_date_error"] = "Please select event end date & time.";
		}
		if(!this.state.eventcategory){
			formIsValid = false;
			errors["event_cat_error"] = "Please select event category.";
		}
		if(!this.state.access){
			formIsValid = false;
			errors["event_access_error"] = "Please select event access.";
		}
		/*if(!this.state.venue){
			formIsValid = false;
			errors["event_venue_error"] = "Please select event venue.";
		}*/
		if(!this.state.address){
			formIsValid = false;
			errors["address_error"] = "Please enter address.";
		}
		if(!this.state.city){
			formIsValid = false;
			errors["city_error"] = "Please enter city.";
		}
		if(!this.state.state){
			formIsValid = false;
			errors["state_error"] = "Please enter state.";
		}
		if(!this.state.zip){
			formIsValid = false;
			errors["zip_error"] = "Please enter zip code.";
		}
		if(!this.state.country){
			formIsValid = false;
			errors["country_error"] = "Please select country.";
		}
		/*if(!this.state.description){
			formIsValid = false;
			errors["description_error"] = "Please enter event description.";
		}
		if(!this.state.startBookingDate){
			formIsValid = false;
			errors["b_start_error"] = "Please select booking start date.";
		}
		if(!this.state.endBookingDate){
			formIsValid = false;
			errors["b_end_error"] = "Please select booking end date.";
		}*/

		this.setState({errors: errors});
		return formIsValid;
	}
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({ btndisable:true });
			const formData = new FormData();
			const startDateTime = moment(this.state.startDateTime);
			const endDateTime = moment(this.state.endDateTime);
			const bStartDate = moment(this.state.startBookingDate);
			const bEndDate = moment(this.state.endBookingDate);
			formData.append("name", this.state.eventtitle);
			formData.append("alias", this.state.alias);
			formData.append("eventstart", startDateTime.format("yyyy-MM-DD H:mm:ss"));
			formData.append("eventend", endDateTime.format("yyyy-MM-DD H:mm:ss"));
			formData.append("event_category", this.state.eventcategory);
			formData.append("access_level", this.state.access);
			formData.append("allowpermission", this.state.allowpermission);
			//formData.append("event_venu", this.state.venue);
			formData.append("address", this.state.address);
			formData.append("city", this.state.city);
			formData.append("state", this.state.state);
			formData.append("country", this.state.country);
			formData.append("zip", this.state.zip);
			formData.append("lat", this.state.add_lat);
			formData.append("lng", this.state.add_lng);			
			/*formData.append("booking_start_date", bStartDate.format("yyyy-MM-DD"));
			formData.append("booking_end_date", bEndDate.format("yyyy-MM-DD"));*/
			formData.append("created_by", ls.get('userid'));
			if(this.state.event_id){
				formData.append("event_id", this.state.event_id);
				Axios.post(base_api_url+'edit-event.php', formData, {axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						toast.success('Event basic information updated successfully!');
						this.setState({
							successclass:'successmsg',
							submitbuttontext: 'Save & next',
							msg:'Event basic information updated successfully!',
							loader:false,
							btndisable:false
						});
						$('#tab_peb').removeClass('active');
						$('#tab_peb_link').removeClass('active');
						$('#tab_mad').addClass('active');
						$('#tab_mad_link').addClass('active');
					}else{
						toast.error('Event not updated, please try again!');
						this.setState({
							successclass:'errormsg',
							submitbuttontext: 'Save & next',						
							msg:'Event not updated, please try again!',
							loader:false						
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:'hideclass',
						msg:null
					  });
					}, 5000)
				}
			);
			}else{
				Axios.post(base_api_url + 'add-event.php', formData, { axiosConfig })
				.then(
					(res) => {
						if (res.data.res_status == 200) {
							toast.success('Event basic information created successfully!');
							this.setState({
								successclass: 'successmsg',
								msg: 'Event basic information created successfully!',
								submitbuttontext: 'Save & Next',
								btndisable:false,
								//redirect:true,
								event_id: res.data.event_id							
							});
							$('#tab_peb').removeClass('active');
							$('#tab_peb_link').removeClass('active');
							$('#tab_mad').addClass('active');
							$('#tab_mad_link').addClass('active');
						} else {
							toast.error('Event not created, please try again!');
							this.setState({
								successclass: 'errormsg',
								msg: 'Event not created, please try again!',
								submitbuttontext: 'Save & Next',
								btndisable:false,
								redirect:false
							})
						}
						setTimeout(() => {
							this.setState({
								successclass: null,
								msg: null,
								btndisable:false,
								redirect:false
							});
						}, 5000)
					}
				);
			}
			
		}
	}
	onSubmitSecondStep(e){		
		e.preventDefault();
		if(this.handleValidationSecond()){
			this.setState({btndisable:true});
			const formData = new FormData();
			if (this.state.eventimage !== '' ) {
				formData.append("cover", this.state.eventimage, this.state.eventimage.name);
			}
			formData.append("keyword", this.state.eventkeyword);
			formData.append("meta_description", this.state.eventmetadescription);
			formData.append("description", this.state.description);
			formData.append("event_id", this.state.event_id);
			formData.append("created_by", ls.get('userid'));
			Axios.post(base_api_url + 'update-event-step-2.php', formData, { axiosConfig })
			.then(
			(res) => {
				if (res.data.res_status == 200) {
					toast.success('Event detail information created successfully!');
					this.setState({
						successclass: 'successmsg',
						msg: 'Event detail information created successfully!',
						submitbuttontextt: 'Save & Next',
						stepSecond: true,
						btndisable:false
					});
					$('#tab_mad').removeClass('active');
					$('#tab_mad_link').removeClass('active');
					$('#tab_sob').addClass('active');
					$('#tab_sob_link').addClass('active');
				}else {
					toast.error('Event not created, please try again!');
					this.setState({
						successclass: 'errormsg',
						msg: 'Event not created, please try again!',
						submitbuttontextt: 'Save & Next',
						btndisable:false
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null,
					});
				}, 5000)
			});
		}
	}
	handleValidationSecond(){
		let formIsValid = true;
		if(!this.state.event_id){
			formIsValid = false;
			alert('Please complete event basic information.');
		}else if(!this.state.description){
			formIsValid = false;
			errors["description_error"] = "Please enter event description.";
		}
		this.setState({errors: errors});
		return formIsValid;
	}
	onSubmitThirdStep(e){		
		e.preventDefault();
		if(this.handleValidationThird()){
			this.setState({btndisable:true});
			const bStartDate = moment(this.state.startBookingDate);
			const bEndDate = moment(this.state.endBookingDate);
			const formData = new FormData();
			formData.append("booking_start_date", bStartDate.format("yyyy-MM-DD"));
			formData.append("booking_end_date", bEndDate.format("yyyy-MM-DD"));
			formData.append("event_id", this.state.event_id);
			Axios.post(base_api_url + 'update-event-step-3.php', formData, { axiosConfig })
			.then(
			(res) => {
				if (res.data.res_status == 200) {
					toast.success('Event created successfully.');
					this.setState({
						successclass: null,
						msg: null,
						redirect:true,
						submitbuttontextw: 'Save',
						btndisable:false
					});
				}else {
					toast.error('Event not created, please try again!');
					this.setState({
						successclass: null,
						msg: null,
						redirect:true,
						submitbuttontextw: 'Save',
						btndisable:false
					});
				}

			});
		}
	}
	handleValidationThird(){
		let formIsValid = true;
		if(!this.state.event_id){
			formIsValid = false;
			alert('Please complete event basic information.');
		}else if(!this.state.stepSecond){
			formIsValid = false;
			alert('Please complete event detail information.');
		}else{
			if(!this.state.startBookingDate){
				formIsValid = false;
				errors["b_start_error"] = "Please select booking start date.";
			}
			if(!this.state.endBookingDate){
				formIsValid = false;
				errors["b_end_error"] = "Please select booking end date.";
			}
		}
		this.setState({errors: errors});
		return formIsValid;
	}
	render() {		
		if (this.state.redirect == true) {
			const url = "/edit-event/" + this.state.event_id;
			return <Redirect to={url} />
		}
		return (
			<React.Fragment>
				{(this.state.emptySSN)?<div class="alert alert-warning" role="alert">Please update SSN number in your profile. This enables you to receive payments and tax documents at the year end.</div>:""}
				<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
                <div className="box-header with-border addprofilenew">
								<h3 className="box-title">Create Event</h3>
							</div>
							<div className="box-body no-padding havetabs eventadds">
								<div className="nav-tabs-custom">
									<ul className="nav nav-tabs">
		                <li className="nav-item"><a href="#tab_peb" id="tab_peb_link" data-toggle="tab" aria-expanded="true" className="nav-link active">Basic Information</a></li>
		                <li className="nav-item"><a href="#tab_mad" id="tab_mad_link" data-toggle="tab" aria-expanded="false" className="nav-link">Detail Information</a></li>
		                <li className="nav-item"><a href="#tab_sob" id="tab_sob_link" data-toggle="tab" aria-expanded="false" className="nav-link">Booking Timings</a></li> 
		               </ul>
		               	<div className="tab-content">		               		
	               		 	<div className="tab-pane active" id="tab_peb">
		               		 	<form name="event-details" onSubmit={this.onSubmit}>
		               		 		<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Event Title<span className="requiredastrik">*</span></label>
												<input type="text" className="form-control" placeholder="Event Title"  name="eventtitle" id="eventtitle" value={this.state.eventtitle} onChange={this.onChangeEventTitle} />
												<span className="form-error">{this.state.errors["title_error"]}</span>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Alias</label>
												<input type="text" value={this.state.alias} placeholder="Alias" className="form-control" onChange={this.onChangeAlias} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Start Date & Time<span className="requiredastrik">*</span></label>
												<div className="input-group">
	                      							<div className="input-group-prepend">
	                        							<span className="input-group-text"><i className="fa fa-calendar"></i></span>		                            
	                      							</div>
	                          						<DatePicker
										              selected={ this.state.startDateTime }
										              onChange={ this.handleStartChange }
										              name="startDateTime"
										              dateFormat="MM/dd/yyyy h:mm aa"
										              dropdownMode="select"
													  showMonthDropdown
													  showYearDropdown
													  adjustDateOnChange
													  showTimeSelect
													  minDate={new Date()}
													  placeholderText="Start Date & Time"
													  className="form-control"
													  dataInputmaskAlias="datetime"
										          	/>
		                         				</div>
		                         				<span className="form-error">{this.state.errors["s_date_error"]}</span>
		                         			</div>
		                         		</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>End Date & Time<span className="requiredastrik">*</span></label>
												<div className="input-group">
		                          					<div className="input-group-prepend">
		                            					<span className="input-group-text"><i className="fa fa-calendar"></i></span>		                            
		                          					</div>
	                          						<DatePicker
													selected={ this.state.endDateTime }
													onChange={ this.handleEndChange }
													name="endDateTime"
													dateFormat="MM/dd/yyyy h:mm aa"
													dropdownMode="select"
													showMonthDropdown
													showYearDropdown
													adjustDateOnChange
													showTimeSelect
													minDate={(this.state.startDateTime)?new Date(this.state.startDateTime):new Date()}											  
													placeholderText="End Date & Time"
													className="form-control"
											        />
		                          				</div>
		                          				<span className="form-error">{this.state.errors["e_date_error"]}</span>
		                          			</div>
		                          		</div>
		                          	</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Select Event Category<span className="requiredastrik">*</span></label>
												<select className="custom-select" onChange={this.onChangeEventCategory}>
													<option value="">-- Event Category --</option>
													{this.state.eventcatlist.map((result) => {
														return (<option value={result.key}>{result.value}</option>)
													})}
												</select>
												<span className="form-error">{this.state.errors["event_cat_error"]}</span>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Access<span className="requiredastrik">*</span>
												  <span className="helpicon"><i className="fa fa-question-circle" aria-hidden="true"></i></span>
												  <span className="helpiconactive">
													<p>Public- Everyone can see and participate</p>
													<p>Private - Only Registered Listener</p>
													<p>Only Me - Only you can see</p>
												  </span>
												  
												</label>
												<select className="custom-select" onChange={this.onChangeAccess}>
													<option value="">-- Access Type --</option>
													<option value="public">Public</option>
													<option value="private">Private</option>
													<option value="only-me">Only Me</option>
												</select>
												<span className="form-error">{this.state.errors["event_access_error"]}</span>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Allow Other to See Event Attendee</label><br />
												<div className="custom-control custom-radio custom-control-inline">
													<input type="radio" id="newrelease" name="customRadioInline1" value="1" onChange={this.onChangeAllowPermissoin} className="custom-control-input" />
													<label className="custom-control-label" htmlFor="newrelease">Yes</label>
												</div>
												<div className="custom-control custom-radio custom-control-inline">
													<input type="radio" id="newrelease2" value="0" onChange={this.onChangeAllowPermissoin} name="customRadioInline1" className="custom-control-input" />
													<label className="custom-control-label" htmlFor="newrelease2">No</label>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Address<span className="requiredastrik">*</span></label>
												<input type="text" className="form-control" placeholder="Enter Address" onChange={this.onChangeAddress.bind(this)}/>
												<span className="form-error">{this.state.errors["address_error"]}</span>
											</div>
										</div>
									</div>
									<div className="row">												
										<div className="col-md-6">
											<div className="form-group">
												<label>City<span className="requiredastrik">*</span></label>
												<input type="text" className="form-control" placeholder="Enter City" onChange={this.onChangeCity.bind(this)}/>
												<span className="form-error">{this.state.errors["city_error"]}</span>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>State<span className="requiredastrik">*</span></label>
												<input type="text" className="form-control" placeholder="Enter State" onChange={this.onChangeState.bind(this)}/>
												<span className="form-error">{this.state.errors["state_error"]}</span>
											</div>
										</div>
									</div>
									<div className="row">												
										<div className="col-md-6">
											<div className="form-group">
												<label>Country<span className="requiredastrik">*</span></label>
												<select className="custom-select" onChange={this.onChangeCountry.bind(this)}>
													<option value="">Select Country</option>
													{this.state.countrylist.map((result) => {
														return (<option value={result.code}>{result.value}</option>)
													})}
												</select>
												<span className="form-error">{this.state.errors["country_error"]}</span>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>ZIP Code<span className="requiredastrik">*</span></label>
												<input type="text" className="form-control" maxlength="6" placeholder="Enter ZIP Code" value={this.state.zip} onChange={this.onChangeZip.bind(this)} onBlur={this.getAddress.bind(this)}/>
												<span className="form-error">{this.state.errors["zip_error"]}</span>
											</div>
										</div>
									</div>
									<div className="row showinggooglemap" style={{marginBottom: '375px'}}>
										<div className="col-md-12 ">
											<Map google={this.props.google}
										    style={mapStyles}
										    className={'map'}
										    zoom={14}
										    center={{lat: this.state.add_lat, lng: this.state.add_lng}}
										    >
										  <Marker
										    position={{lat: this.state.add_lat, lng: this.state.add_lng}}
										    draggable={true}
												onDragend={(t, map, coord) => this.moveMarker(coord)}/>
										  </Map>
										</div>
									</div>
									<div className="row">
							            <div className="col-md-12 notmainbtn customtabsbtns">
							                <button type="submit" disabled={this.state.btndisable} className="btn btn-primary">{this.state.submitbuttontext} </button>
							            </div>
							        </div>
						        </form>
	               		 	</div>
		               		<div className="tab-pane" id="tab_mad">
		               			<form name="event-details" onSubmit={this.onSubmitSecondStep.bind(this)}>
			               		 	<div className="row">
										<div className="col-md-6">
											<div className="row">
												<div className="col-md-12">
													<div className="form-group">
														<label>Upload Event Image</label>
														<input type="file" className="form-control" onChange={this.onChangeEventImage} accept="image/*" />
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group metaboxs">
														<label>Meta Keyword</label>
														<textarea className="form-control rounded-0" placeholder="Meta Keyword" onChange={this.onChangeEventKeyword} rows="3"></textarea>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group metaboxs">
														<label>Meta Description</label>
														<textarea className="form-control rounded-0" placeholder="Meta Description" onChange={this.onChangeEventMetaDescription} rows="3"></textarea>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group ckeditro">
												<label>Description<span className="requiredastrik">*</span></label>
												<textarea className="form-control rounded-0" placeholder="Description" onChange={this.onChangeEventDescription} rows="3"></textarea>
												<span className="form-error">{this.state.errors["description_error"]}</span>
											</div>
										</div>
									</div>	
									<div className="row">
						              	<div className="col-md-12 notmainbtn customtabsbtns">
						                	<button type="submit" disabled={this.state.btndisable} className="btn btn-primary">{this.state.submitbuttontextt} </button>
						              	</div>
						            </div>
					            </form>	               		 
		               		</div>
		               		<div className="tab-pane" id="tab_sob">
			               		<form name="event-details" onSubmit={this.onSubmitThirdStep.bind(this)}>
			               			<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Booking Start Date<span className="requiredastrik">*</span></label>
												<div className="input-group">
	                          						<div className="input-group-prepend">
	                            						<span className="input-group-text"><i className="fa fa-calendar"></i></span>
	                          						</div>
	                          						<DatePicker
													selected={ this.state.startBookingDate }
													onChange={ this.handleBookingChange }
													name="startBookingDate"
													dateFormat="MM/dd/yyyy"
													dropdownMode="select"
													showMonthDropdown
													showYearDropdown
													adjustDateOnChange
													className="form-control"
													maxDate={this.state.endDateTime?new Date(this.state.endDateTime):""}
													placeholderText="Booking Start Date"
													/>
	                          					</div>
	                          					<span className="form-error">{this.state.errors["b_start_error"]}</span>
	                          				</div>
	                          			</div>
	                          			<div className="col-md-6">
	                          				<div className="form-group">
	                          					<label>Booking End Date<span className="requiredastrik">*</span></label>
												<div className="input-group">
			                          				<div className="input-group-prepend">
			                            				<span className="input-group-text"><i className="fa fa-calendar"></i></span>
			                          				</div>
			                          				<DatePicker
													selected={ this.state.endBookingDate }
													onChange={ this.handleEndBookingChange }
													name="endBookingDate"
													dateFormat="MM/dd/yyyy"
													dropdownMode="select"
													showMonthDropdown
													showYearDropdown
													adjustDateOnChange
													className="form-control"
													placeholderText="Booking End Date"
													minDate={this.state.startBookingDate?new Date(this.state.startBookingDate):""}
													maxDate={this.state.endDateTime?new Date(this.state.endDateTime):""}
													/>
	                        	  				</div>
	                        	  				<span className="form-error">{this.state.errors["b_end_error"]}</span>
	                        	  			</div>
										</div>
									</div>
									<div className="row">
						              	<div className="col-md-12 notmainbtn customtabsbtns">
						                	<button type="submit" disabled={this.state.btndisable} className="btn btn-primary">{this.state.submitbuttontextw} </button>
						              	</div>
						            </div>
						        </form>
					        </div>
		               	</div>
		            </div>
								

							</div>
							<div className="box-footer clearfix"></div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0'
})(AddEvent);