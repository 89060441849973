import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CropAndPreview = ({ show, handleShow, data, croppedImages }) => {
  const [imageRef, setImageRef] = useState('');
  const [croppedImage, setCroppedImage] = useState('');
  const [crop, setCrop] = useState({x:parseInt('0'), y:parseInt('0'), unit: 'px', height: parseInt('150'), width: parseInt('150'), aspect: 16 / 16 });
  useEffect(() => {
    return () => {
      console.log('cleaned up');
    };
  }, []);

  const onSave = () => {
    croppedImages(croppedImage);
    setCroppedImage('');
    setImageRef('');
    handleShow();
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
    makeClientCrop(crop, image);
  };
  const onCropComplete = () => {
    makeClientCrop(crop, imageRef);
  };
  useEffect(() => {
    //setCroppedImage(data.src);
  }, []);

  const makeClientCrop = async (crop, imageRef) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef, crop, 'newFile.jpeg');

      setCroppedImage(croppedImageUrl);
    } else {
      console.log(imageRef, 'depth', crop);
    }
  };
  const download = () => {
    var element = document.createElement('a');
    var file = new Blob([croppedImage], { type: 'image/*' });
    element.href = URL.createObjectURL(file);
    element.download = 'image.jpg';
    element.click();
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;

        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, 'image/jpg');
    });
  };

  return (
    <>
      <Modal show={show} onHide={() => handleShow(false, null)} backdrop="static"
        keyboard={false}>
        <Modal.Body className="d-flex justify-content-center ">
          <ReactCrop
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              ruleOfThirds={true}
              src={data.src}
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
            />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={() => onSave()}>
              Save
            </button>
          <button className="btn btn-secondary" onClick={() => handleShow()}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CropAndPreview;
