import React, { useState } from 'react';
import { Row, Image } from 'react-bootstrap';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayListModal from '../includes/showPlaylistModal';
import { Spinner } from 'react-bootstrap';
import defaultimg from '../components/default.png';
import ls from 'local-storage';
import shortid from 'shortid';
import {
  faPlay,
  faAngleLeft,
  faAngleRight,
  faStepBackward,
  faStepForward,
  faPause,
  faVolumeDown,
  faVolumeMute
} from '@fortawesome/free-solid-svg-icons';
import { playAudio } from '../includes/playAudio.component';
import './musicPlayer.css';
import { API_URL } from '../config/constants.js';
const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
const MusicPlayer = ({
  audioRef,
  musiclist,
  index,
  currentSong,
  setCurrentSong,
  isPlaying,
  setIsPlaying,
  songInfo,
  setSongInfo,
  setMute,
  mute,
  likeSongFu,
  _favoriteSong,
  likeSongStatus,
  is_favorite
}) => {
  const [showModal, setShowModal] = useState(false);
  const [playCounter, setPlayCounter]   = useState(true);
  const [ isCurrent, setIsCurrent ] = useState('');
  let counter = 1; 
  let ct = 1; 
  const handleModal = (data) => {
    setShowModal(data);
  };
  const playSongHandler = () => {
    setIsCurrent(true);
    setSongInfo({...songInfo, currentTime: (songInfo.currentTime)+1});
    if (isPlaying) {
	  audioRef.current.pause();
      setIsPlaying();
      if(musiclist[index].token == undefined){
        musiclist[index].token = shortid.generate();
        savePlayedTime();
      }else{
        updatePlayedTime();
      }      
    } else {      
      if(songInfo.animationPercentage === 0){
        let myCurrentSong   = musiclist[index];
        myCurrentSong.token = shortid.generate();
        savePlayedTime();
      }
      audioRef.current.play();
      setIsPlaying(!isPlaying);
    }
  };
  const changeVolume = (e) => {
    let value = e.target.value;
    audioRef.current.volume = value;
    setSongInfo({ ...songInfo, volume: value });
  };

  function getTime(time) {
    let currentplayedtime = Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
    if(currentplayedtime === '0:30'){
      for (counter = 1; counter < 2; counter++){
        updatePlayedTime();
      }
    }
    return currentplayedtime;
  }
  const addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };
  const savePlayedTime = () => {
    let myCurrentSong   = musiclist[index];
    let listener_id   = ls.get('userid');
    let playedTime    = Math.floor(songInfo.currentTime);
    let song_id     = myCurrentSong.song_id;
    const objs    = {
      user_id: listener_id,
      song_id: song_id,
      played_time: playedTime,
      token: myCurrentSong.token
    };
    Axios.post(base_api_url + 'songplayed.php', objs, { axiosConfig }).then((result) => {
      if (result.data.res_status == 200) {
        console.log('song data saved successfully');
      } 
    });
  }
  const savePlayedNextTime = (currSong) => {
    //let myCurrentSong   = musiclist[index];
    let listener_id   = ls.get('userid');
    let playedTime    = Math.floor(songInfo.currentTime);
    let song_id     = currSong.song_id;
    const objs    = {
      user_id: listener_id,
      song_id: song_id,
      played_time: playedTime,
      token: currSong.token
    };
    Axios.post(base_api_url + 'songplayed.php', objs, { axiosConfig }).then((result) => {
      if (result.data.res_status == 200) {
        console.log('song data saved successfully');
      } 
    });
  }
  const updatePlayedTime = () => {
    let myCurrentSong   = musiclist[index];
    let listener_id   = ls.get('userid');
    let playedTime    = Math.floor(songInfo.currentTime);
    let song_id     = myCurrentSong.song_id;
    const objs    = {
      played_time: playedTime,
      token: myCurrentSong.token
    };
    Axios.post(base_api_url + 'updatesongplayed.php', objs, { axiosConfig }).then((result) => {
      if (result.data.res_status == 200) {
        console.log('song data updated successfully');
      } 
    });
  }
  function getReverseTime(time) {
    if(time === 0){
      return "0:00";
    }else{
      return Math.floor((Number(songInfo.duration) - time) / 60) + ':' + ('0' + Math.floor((Number(songInfo.duration) - time) % 60)).slice(-2);
    }
    
  }
  const dragHandler = (e) => {
    audioRef.current.currentTime = e.target.value;
    setSongInfo({ ...songInfo, currentTime: e.target.value });
  };

  const skipTrackHandler = async (direction) => {
    let currentIndex = musiclist.findIndex((song) => song.song_id === currentSong.song_id);
    console.log(currentIndex);
    if (direction === 'skip-forward') {
      ls.remove('localIndex');
      ls.set('localIndex',(currentIndex + 1) % musiclist.length);
      await setCurrentSong(ls.get('localIndex'));
    }
    if (direction === 'skip-back') {
      ls.remove('localIndex');
      ls.set('localIndex',(currentIndex - 1) % musiclist.length);
      await setCurrentSong(ls.get('localIndex'));
    }
    if (isPlaying) audioRef.current.play();
    let cSong = musiclist[currentIndex];
    if(typeof cSong.token !== 'undefined' && cSong.token){
       updatePlayedTime();
     }else{
        cSong.token = shortid.generate();
        savePlayedNextTime(cSong);
     }
      let updateMusicList = musiclist[currentIndex];
      delete updateMusicList["token"]
      musiclist[currentIndex] = updateMusicList;
      ls.remove('localMusicList');
      ls.set('localMusicList', musiclist);
   };
  let sendstatus = '';
  if (likeSongStatus === '0') {
    sendstatus = '1';
  } else {
    sendstatus = '0';
  }
  let maxnum = musiclist.length -1;
    let disprevclass = '';
    let disnextclass = '';
    let lcIndex = (ls.get('localIndex')?ls.get('localIndex'):index)
    if(lcIndex<1){
      disprevclass = 'disablediv';
    }
    
    if(index == maxnum){
      disnextclass = 'disablediv';
    }
    if(musiclist.length<0){
      disprevclass = 'disablediv';
      disnextclass = 'disablediv';
    }
  let currentUrl = window.location.href;
  let urlArray = currentUrl.split('/');
  let currentPage = '';
  if (urlArray) {
    currentPage = urlArray[3];
  }
  let maxlimit    = '15';
  let songname    = currentSong.name;
  let artiname    = currentSong.artist_name;
  return (
    <>
      <Row className="fixed-bottom" style={{ backgroundColor: 'white', minHeight: '10vh', zIndex: '1' }} sm={12}>
        <div className="d-flex flex-row" style={{ width: '100%' }}>
          <div className="d-flex justify-content-start align-items-center border-right ml-3 col-sm-2">
            <div className="songdetials">
            <div className="song-imge">
              <Image style={{ width: '2.6rem', height: '2.6rem' }} fluid src={currentSong.img} onError={addDefaultSrc} rounded />
            </div>
            <div className="song-titlens">
              <h4 title={songname}>{ (songname)?(songname.length > maxlimit) ? (((songname).substring(0,maxlimit-3)) + '...') :songname:'' }</h4>
              <sapn className="moviename" title={artiname}>{ (artiname)?(artiname.length > maxlimit) ? (((artiname).substring(0,maxlimit-3)) + '...') :artiname:'' }</sapn>
            </div>
          </div>
          </div>

          <div className="d-flex  justify-content-around align-items-center col-sm-2">
            <div className={(disprevclass)?disprevclass:''}>
            <FontAwesomeIcon onClick={() => skipTrackHandler('skip-back')} title="Previous" className="skip-back next-farward" icon={faStepBackward} style={{'cursor':'pointer'}} />
          </div>
            <div className="d-flex  justify-content-center align-items-center playsongicon" onClick={playSongHandler}  style={{'cursor':'pointer'}} title={isPlaying && songInfo.duration !== songInfo.currentTime ? "Pause" : "Play"}>
            {(currentSong)?
              <FontAwesomeIcon
              className="play "
              size="1x"
              color="white"
              icon={isPlaying && songInfo.duration !== songInfo.currentTime ? faPause : faPlay}
              />:<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '3vh',float:'right' }}><Spinner style={{ width: '15px', height: '15px', display: 'block'}} variant="light" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div>
            }
          </div>
            <div className={(disnextclass)?disnextclass:''}>
            <FontAwesomeIcon onClick={() => skipTrackHandler('skip-forward')} title="Next" className="play next-farward " size="1x" icon={faStepForward}  style={{'cursor':'pointer'}} />
          </div>
          </div>
          <div className=" col-sm-5  d-flex flex-row align-items-center ">
            <div className="songplaerbar w-100 ">
              <span className=" pt-1 sonstime leftside">{songInfo.duration ? getTime(songInfo.currentTime) : '0:00'}</span>
              <div className=" progress ">
                <input
                  className="e-range"
                  value={songInfo.currentTime}
                  type="range"
                  max={songInfo.duration || 0}
                  min={0}
                  style={{ width: '100%' }}
                  onChange={dragHandler}
                />
              </div>

              <span className="sonstime pt-1 leftside">{songInfo.duration ? getReverseTime(songInfo.currentTime) : '0:00'}</span>
            </div>
          </div>

          <div className="col-sm-1 d-flex flex-row align-items-center  ">
            <PlayListModal songId={currentSong.song_id} show={showModal} handleModal={handleModal} />

            <span className="d-flex  flex-row w-100 justify-content-around">
              <span className="likesong" onClick={() => _favoriteSong(currentSong.song_id, is_favorite, musiclist, index)}>
                {is_favorite === '0' ? (
                  <i className="fa fa-star-o" title="Favorite song"></i>
                ) : (
                  <i className="fa fa-star" title="Unfavorite song"></i>
                )}
              </span>
              <span className="likesong" onClick={() => likeSongFu(currentSong.song_id, sendstatus, musiclist, index)}>
                {likeSongStatus === '0' ? (
                  <i className="fa fa-heart-o" title="Like song"></i>
                ) : (
                  <i className="fa fa-heart" title="Dislike song"></i>
                )}
              </span>
              <span style={{ cursor: 'pointer' }} onClick={() => setShowModal(!showModal)} className="likesong" title="Add to playlist">
                <img className="h-75 w-55" src="../assets/music/addplaylist2.png" />
              </span>
            </span>
          </div>

          <div className="d-flex col-sm-3 justify-content-start align-items-center ">
            <div className="d-flex align-items-center justify-content-start  volumecontrols" style={{ width: '100%' }}>
              <span class="volumneicons">
                <FontAwesomeIcon onClick={() => setMute(!mute)} size="2x" icon={mute ? faVolumeMute : faVolumeDown} style={{ paddingRight: '2px' }} />
              </span>
              <input className="e-range" onChange={changeVolume} max="1" min="0" step="0.01" type="range" />
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default MusicPlayer;
