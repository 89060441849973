import React, { Component } from 'react';
import Axios from 'axios';
import { Form } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../../config/constants';
import DatePicker from 'react-datepicker';
import AdminHeader from './header.component';
import InputMask from 'react-input-mask';
import AdminTopHeader from './header-top.component';
import swal from 'sweetalert';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import $ from 'jquery';
import Spinner from '../../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};

export default class EventReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userid: null,
      token: '',
      error: null,
      msg: null,
      reportlist: [],
      loading: true,
      loader: null,
      redirect: false,
      hideCustom: true,
      dataType: 'all',
      startdate: '',
      endDate: '',
      status: '',
      type: '',
      featuredStatus:'0',
      successclass: 'hideclass',
      msg: ''
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Users';
    const token = ls.get('token');
    const userid = ls.get('userid');

    this.setState({
      token: token,
      userid: userid
    });

    this.getallreportlist();
  }
  async changeStatus(userid, status) {
    const statusCheck = status === '1' ? 'deactivate' : 'activate';
	
    if (window.confirm(`Do you want to ${statusCheck} the user? `)) {
      let obj = {
        user_id: userid,
        status: status === '1' ? '0' : '1'
      };

      const response = await Axios.post(base_api_url + 'change-user-status.php', obj, axiosConfig);
	  
      if (response.data.res_status === '200') {
        this.getallreportlist();
		this.setState({
			successclass:'successmsg',
			msg:`User status ${statusCheck}d successfully.`,
		});
      } else {
        this.setState({
			successclass:'errormsg',
			msg:'Something went wrong!',
		});
      }
    }
  }

  componentDidUpdate() {
  }
  changeData = (e) => {
    this.setState({ type: e.target.value }, () => {
      this.getallreportlist();
    });
  };

  changeDataStatus = (e) => {
    this.setState({ status: e.target.value }, () => {
      this.getallreportlist();
    });
  };
  changeDataFeatured = (e) => {
		this.setState({ featuredStatus: e.target.value }, () => {
			this.getallreportlist();
		});
	};
  getallreportlist = () => {
    this.setState({ loading: true });
    let obj = {
      type: this.state.type,
      status: this.state.status,
      featured: this.state.featuredStatus
    };

    Axios.post(base_api_url + 'all-user-list.php', obj, { axiosConfig }).then((res) => {
      $('#myusers').DataTable().destroy();
      setTimeout(() => {
        $('#myusers').DataTable({
          destroy: true,
          processing: true,
          pageLength: 10,
          lengthMenu: [
            [5, 10, 15, -1],
            [5, 10, 15, 'All']
          ],
		  language: {
			 search: "Search"
		  },
		  stateSave: true,
		  columnDefs: [
				{ "width": "10%", "targets": 0 },
				{ "width": "15%", "targets": 1 },
				{ "width": "10%", "targets": 2 },
				{ "width": "20%", "targets": 3 },
				{ "width": "25%", "targets": 4 },
				{ "width": "5%", "targets": 5 },
				{ "width": "15%", "targets": 6 },
				{ orderable: false, targets: -1 }
		  ],
          dom: 'Bfrtip',
		  buttons: [
            {
                extend: 'csv',
                exportOptions: {
                    columns: [ 0,1,2,3,4,5 ]
                }
            },
            {
                extend: 'print',
                exportOptions: {
                    columns: [ 0,1,2,3,4,5 ]
                },
                customize: function ( win ) {
				$(win.document.body).find('table').addClass('display').css('font-size', '15px');
				$(win.document.body).find('table').css('table-layout','auto');
				$(win.document.body).find('th').css('width','15%');
				$(win.document.body).find('td').css('width','15%');
				}
            }
        ]
        });
      }, 100);

      if (res.data.res_status == 200) {
        const returndata = res.data.res_data;
        this.setState(
          {
            reportlist: returndata,
            loading: false
          },
          () => {
            setTimeout(() => {
              this.setState({ successclass: '', msg: '' });
            }, 10000);
          }
        );
      } else {
        this.setState({
          reportlist: [],
          loading: false
        });
      }
    });
  };
  async makeFeatured(userid, featured) {
    let obj = {
      user_id: userid,
      featured: featured,
      type: 'user'
    };
    let featureContent = featured === '1' ? 'unfeature' : 'feature';
    if (window.confirm(`Do you want to ${featureContent} the artist? `)) {
      const response = await Axios.post(base_api_url + 'markfeatured.php', obj, axiosConfig);
      if (response.data.res_status === '200') {
        this.getallreportlist();
		this.setState({
			successclass:'successmsg',
			msg:`Artist ${featureContent}d successfully`,
		});
      }else{
		 this.setState({
			successclass:'errormsg',
			msg:'Something went wrong!',
		}); 
	  }
    }
  }
  
  async logoutfromall(useremail) {
    let obj = {
      type: 'all',
      email: useremail
    };
    if (window.confirm(`Do you want to logout from all devices? `)) {
      const response = await Axios.post(base_api_url + 'deleteloginattempt.php', obj, axiosConfig);
      if (response.data.res_status === '200') {
        this.getallreportlist();
		this.setState({
			successclass:'successmsg',
			msg:response.data.res_message,
		});
      }else{
		 this.setState({
			successclass:'errormsg',
			msg:'Something went wrong!',
		}); 
	  }
    }
  }

  render() {
    let maxlimit = '30';
    return (
      <React.Fragment>
        <div className="dashboard-wrapper">
          <AdminHeader />
          <div className="maincontent-area">
            <AdminTopHeader />
            <div className="main-dashsection">
              <section className="contentpard">
                <div className={this.state.successclass}>{this.state.msg}</div>
				{ (this.state.loading == true)? <Spinner />:
					<div className="row">
					  <div className="col-md-12">
						<div className="box box-success distributions-profileform">
						  <div className="box-header with-border addprofilenew">
							<h3 className="box-title">Users</h3>
							<div className="box-tools d-flex justify-content-between">
							<select
								className="filteroptionselect"
								as="select"
								value={this.state.type}
								onChange={(e) => this.changeData(e)}
								style={{ width: '10vw', marginRight:'5px' }}
							  >
								<option value="">All Users</option>
								<option value="1">Listener</option>
								<option value="2">Artist</option>
							  </select> &nbsp;
							<select
								className="filteroptionselect"
								as="select"
								value={this.state.featuredStatus}
								onChange={(e) => this.changeDataFeatured(e)}
								style={{ width: '10vw' }}
							  >
							  	<option value="0">All Users</option>
								<option value="1">Feature Artist</option>
							  </select> &nbsp;
							  
							  <select
								className="filteroptionselect"
								as="select"
								value={this.state.status}
								onChange={(e) => this.changeDataStatus(e)}
								style={{ width: '10vw' }}
							  >
								<option value="">All</option>
								<option value="0">Inactive</option>
								<option value="1">Active</option>
								<option value="90">Deleted</option>
							  </select>
							  <Link to={'/admin/add-user'} className="btn btn-primary aadersongs"><i className="fa fa-plus"></i> Add User</Link>
							</div>
						  </div>
						  <div className="box-body no-padding">
							<div className="table table-responsive no-margin table-striped table-hover">
							  <table id="myusers" className="table table-striped table-bordered table-sm row-border hover ">
								<thead>
								  <tr>
									<th>Type</th>
									<th>Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Address</th>
									<th>Status</th>
									<th data-orderable="false" className="text-center">Actions</th>
								  </tr>
								</thead>
								<tbody>
								  {this.state.reportlist.map((result) => {
									// console.log('result',result);
									return (
									  <tr>
										<td>{result.role}</td>
										<td>
										  {result.name}
										</td>
										<td>{result.email}</td>
										<td>
										  {result.phone
											? `(${result.phone.substring(0, 3)}) ${result.phone.substring(3, 6)}-${result.phone.substring(
												6,
												result.phone.length
											  )}`
											: ''}
										</td>
										<td>
										  {(result.address !=='')?result.address + ', ' + result.city:''} <br /> {(result.state !=='')?result.state + ', ' + result.country + ' ' + result.zipcode:''}
										</td>
										<td>
										  <span className={result.status === '1' ? 'label label-success' : 'label label-danger'}>
											{result.status === '1' ? 'Active' : (result.status === '0')? 'Inactive':'Deleted'}
										  </span>
										</td>
										<td className="actionbtns d-flex justify-content-left">
										  <Link to={'/admin/usersDetail/' + result.id} className="btn  btn-info custombtn" title="View User details">
											<i className="fa fa-address-book-o" aria-hidden="true"></i>
										  </Link>
										  <Link
											className="btn  btn-info custombtn"
											title={result.status === '0' ? 'Active' : 'Inactive'}
											onClick={() => this.changeStatus(result.id, result.status)}
										  >
											{result.status === '0' ? (
											  <i className="fa fa-check" aria-hidden="true"></i>
											) : (
											  <i className="fa fa-ban" aria-hidden="true"></i>
											)}
										  </Link>
										  {/*result.role === 'Artist' ? (
											Number(result.featured) > 0 ? (
											  <Link
												onClick={() => this.makeFeatured(result.id, result.featured)}
												className="btn  btn-info custombtn"
												title="Featured Artist"
											  >
												<i className="fa fa-star" aria-hidden="true"></i>
											  </Link>
											) : (
											  <Link
												onClick={() => this.makeFeatured(result.id, result.featured)}
												className="btn  btn-info custombtn"
												title="Feature Artist"
											  >
												<i className="fa fa-star-o" aria-hidden="true"></i>
											  </Link>
											)
										  ) : (
											''
										  )*/}
										  <Link
											to={{
											  pathname: '/admin/edit-user/' + result.id,
											  state: { user: result }
											}}
											className="btn  btn-info custombtn"
											title="Edit User"
										  >
											<i className="fa fa-pencil" aria-hidden="true"></i>
										  </Link>
										  <Link
											onClick={() => this.logoutfromall(result.email)}
											className="btn  btn-info custombtn"
											title="Logout from all devices"
										  >
											<i className="fa fa-sign-out" aria-hidden="true"></i>
										  </Link>
										</td>
									  </tr>
									);
								  })}
								</tbody>
							  </table>
							</div>
						  </div>
						  <div className="box-footer clearfix"></div>
						</div>
					  </div>
					</div>
				}
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
