import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class User extends Component {
	componentDidMount(){
		document.title = "CONQER Music - User";
	}
    render() {
        return (
			<React.Fragment>
				<div id="slider-home" className="usersectionslide">
					<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
					  <div className="carousel-inner ">
						<div className="carousel-item active">
						  <img src={process.env.PUBLIC_URL + 'assets/slider/slide3.jpg'} alt="..." />
						  <div className="title">
							<h1 className="animated slideInDown artist-sl">Listen to<span> the difference</span></h1>
							<h2 className="animated slideInLeft">A clear sound that is changing the World</h2>
						  </div>
						  <div className="slide-m-img">
							  <div className="artist-m-img1"><img src={process.env.PUBLIC_URL + 'assets/slider/mp1.png'} /></div>
						  </div> 
						</div>
						<div className="carousel-item">
						  <img src={process.env.PUBLIC_URL + 'assets/slider/slide3.jpg'} alt="..." />
						  <div className="title">
							<h1 className="animated slideInDown artist-sl">Listen to<span> the difference</span></h1>
							<h2 className="animated slideInLeft">A clear sound that is changing the World</h2>
						  </div>
						   <div className="slide-m-img">
							  <div className="artist-m-img1"><img src={process.env.PUBLIC_URL + 'assets/slider/mp1.png'} /></div>
						  </div>
						</div>
					  </div>
					</div>
				</div>
				<section className="artist-section">
				  <div className="container">
					<div className="promote_artist">
					  <div className="row">
						<div className="col-md-3">
						  <img src={process.env.PUBLIC_URL + 'assets/user/img41.jpg'} />
						  <h3>THE BEST CHRISTIAN MUSIC</h3>
						  <p>CONQER has you covered with all genres of Christ-centered music. Whatever you are in the mood to listen to.</p>
						</div>
						<div className="col-md-3">
						  <img src={process.env.PUBLIC_URL + 'assets/user/img51.jpg'} />
						  <h3>EVENTS AND TICKET PURCHASE</h3>
						  <p>Find out where your favorite artist will perform and purchase your tickets directly from CONQER.</p>
						</div>
						<div className="col-md-3">
						  <img src={process.env.PUBLIC_URL + 'assets/user/img6.jpg'} />
						  <h3>REQUEST OR BE A PRAYER WARRIOR</h3>
						  <p>Request prayer for your need(s) or join the millions of Prayer Warriors ready to stand together.</p>
						</div>
						 <div className="col-md-3">
						  <img src={process.env.PUBLIC_URL + 'assets/user/img7.jpg'} />
						  <h3>GET INSPIRED TO SERVE GOD</h3>
						  <p>CONQER aims to inspire everyone to enjoy their day and walk in the Spirit, while glorifying God.</p>
						</div>
					  </div>
					  <div className="row">
				    <div className="col-md-12">
				    	<div className="other-benefits">Other Benefits</div>
				        <div className="supertext">
					        <ul className="text-info-reg"><li>5% discount on all partner/subsidiary services</li>
							<li>Special Promotions as announce</li>
							</ul>
						</div>
				    </div>
				</div>
					</div>
					
				  </div>
				</section>

				<section>
					<div className="why-conqer">
						<div className="container why-content">
							<h3>FIND OUT WHY CONQER</h3>
							<span>is taking the Christian world by storm!</span>
							<p>Experience the best Christian music in the entertainment industry. <br/>Join the millions of users who are getting inspired by the Word of God.</p>
						</div>
						<div className="empty-space"></div>
						<div><Link to={'/register/listener'} className="why-btn" role="button">CREATE A PROFILE TODAY</Link></div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}