import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import Spinner from '../../../includes/Spinner.component';
import $ from 'jquery'; 
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const errors = {};
export default class Commission extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	commissionlist:[],
      	errors:[],
		btndisable:'',
		c_amount: '',
		typeerror:'',
		amountlabel:'Amount',
		cid:'',
      	btnSubmitText: 'Submit',
      	disabled:false
      }
    }
    commissionListData(){
    	const eventid = this.props.match.params.id;
		const obj= {}
	    Axios.post(API_URL + 'getcommissions.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		commissionlist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						loader: false
					});
		        }
		      	$(document).ready(function () {
		    		$('#eventTicketList').DataTable().destroy();
			        setTimeout(function(){
			        $('#eventTicketList').DataTable({
							"destroy": true,
							"stateSave": true,
							"lengthMenu": [[5, 10, 15 -1], [5, 10, 15,  "All"]],
							"language": {
								"search": "Search"
							},
							columnDefs: [
			   				{ orderable: false, targets: -1 }
						],
						dom: 'Bfrtip',
						buttons: [
				            {
				                extend: 'csv',
				                exportOptions: {
				                    columns: [ 0,1,2]
				                }
				            },
				            {
				                extend: 'print',
				                exportOptions: {
				                    columns: [ 0,1,2]
				                },
				                customize: function ( win ) {
								$(win.document.body).find('table').addClass('display').css('font-size', '15px');
								$(win.document.body).find('table').css('table-layout','auto');
								$(win.document.body).find('th').css('width','15%');
								$(win.document.body).find('td').css('width','15%');
								}
				            }
				        ]
			        });
			        } ,50);
			    });	        	   
	    	}

	    );
    }
    componentDidMount(){
		document.title = "Master - Commission";
    	this.commissionListData();
    }
	
    editTicket = (c_id) => {
		if(this.state.errors['typeerror']){delete this.state.errors['typeerror'];}
		if(this.state.errors['nameerror']){delete this.state.errors['nameerror'];}
		if(this.state.errors['amounterror']){delete this.state.errors['amounterror'];}
    	this.setState({
			loader:true,
			cid:c_id,
			nameerror:''
		})
		const obj = {
			id: c_id
		}
		Axios.post(API_URL + 'getcommissions.php', obj, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.commissionListData();
					let currentc = res.data.res_data[0];
					this.setState({
						cname: currentc.c_name,
						ctype: currentc.c_type,
						c_amount: currentc.c_amount,
						btnSubmitText: 'Update',
						loader:false,
						method:'update'
					});

				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Internal Server Error, please try again!',
						loader:false
					})
				}
			}
		);
	}
	deleteCommissions(id){
    	if(window.confirm("Are you sure you want to delete?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deletecommission.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.commissionListData();
						this.setState({
							successclass:'successmsg',
							msg:'Commission deleted successfully!',
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:'Commission not deleted, please try again!'
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
    onChangeTicketTitle(e) {
		if(this.state.errors['nameerror']){delete this.state.errors['nameerror'];}
		let namedata;
		if(e.target.value == this.state.cname){
			namedata = false;
		}else{
			namedata = true;
		}
		if(namedata){
			this.setState({
				cname: e.target.value,
				nameerror:''
			})
			const obj = {
				c_name: e.target.value
			}
			Axios.post(API_URL + 'commission_exist.php', obj, { axiosConfig })
			.then(
				(res) => {
					if (res.data.res_status == 200) {
					} else {
						this.setState({
							nameerror:res.data.res_message
						})
					}
				}
			);
		}
	}
	onChangeCType(e) {
		if(this.state.errors['typeerror']){delete this.state.errors['typeerror'];}
		let typevalue = e.target.value; 
		let type;
		if(typevalue === 'fixed'){
			type = 'Amount';
		}else{
			type = 'Percentage';			
		}
		this.setState({
			ctype: e.target.value,
			amountlabel:type
		})
	}

	onChangeCAmount(e) {
		if(this.state.errors['amounterror']){delete this.state.errors['amounterror'];}
		const re = /^[0-9.\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({
				c_amount: e.target.value
			})
		}
	}

	
	onCommissionSubmit(e) {
		e.preventDefault();
		if(this.handleValidation() && this.state.nameerror === ''){
			this.setState({
				loader:true,
				disabled:true,
				btnSubmitText:'Please Wait...'
			});
			if(this.state.method === 'update'){
				this.updateTickets();
			}else{
				this.saveTickets();
			}
		}
	}
	saveTickets(){
		const formData = new FormData();
		formData.append("c_name", this.state.cname);
		formData.append("c_type", this.state.ctype);
		formData.append("c_amount", this.state.c_amount);
		Axios.post(API_URL + 'commission.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: 'Commission created successfully!',
						cname: '',
						ctype: '',
						btnSubmitText: 'Submit',
						disabled:false,
						c_amount: '',
						loader:false,
					});
					this.commissionListData();
				} else {
					this.setState({
						successclass: 'errormsg',
						btnSubmitText:'Submit',
						disabled:false,
						loader:false,
						msg: 'Commission not created , please try again!'								
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	updateTickets(){
		const formData = new FormData();
		formData.append("c_id", this.state.cid);
		formData.append("c_type", this.state.ctype);
		formData.append("c_name", this.state.cname);
		formData.append("c_amount", this.state.c_amount);
		Axios.post(API_URL + 'update-commission.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						cname: '',
						ctype: '',
						c_amount: '',
						btnSubmitText: 'Submit',
						disabled:false,
						method:'',
						loader:false
					});
					this.commissionListData();
				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Commission not updated , please try again!',
						btnSubmitText:'Update',
						disabled:false,
						loader:false,
						method:'update'
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	handleValidation = () => {
		let formIsValid = true;		
		if(!this.state.ctype){
			formIsValid = false;
			errors["typeerror"] = "Please select type.";
		}
		if(!this.state.cname){
			formIsValid = false;
			errors["nameerror"] = "Please select name.";
		}
		if(!this.state.c_amount){
			formIsValid = false;
			errors["amounterror"] = "Please enter commission amount.";
		}		
		this.setState({errors: errors});		
        return formIsValid;
	
	}
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
    			<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">Commission</h3>            
							</div>
							<div className="box-body no-padding"> 
								<div className="nav-tabs-custom">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onCommissionSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>Commission Information</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4">
														<div className="form-group">
															<label>Commission Type<span className="requiredastrik">*</span></label>
															<select className="custom-select" name="ctype"  onChange={this.onChangeCType.bind(this)} value={this.state.ctype}>
																<option value="0">Select Type</option>
																<option value="fixed" selected={(this.state.ctype=='fixed')?'selected':''}>Fixed</option>
																<option value="percentage" selected={(this.state.ctype=='percentage')?'selected':''}>Percentage</option>
															</select>
															<font className="errorMsg">{this.state.errors["typeerror"]}</font>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-group">
															<label>Commission Name<span className="requiredastrik">*</span></label>
															<select className="custom-select" name="cname"  onChange={this.onChangeTicketTitle.bind(this)} value={this.state.cname}>
																<option value="0">Select Name</option>
																<option value="song" selected={(this.state.cname=='song')?'selected':''}>Song</option>
																<option value="ticket" selected={(this.state.cname=='ticket')?'selected':''}>Ticket</option>
																<option value="stripe_fee" selected={(this.state.cname=='stripe_fee')?'selected':''}>STRIPE Processing Fee</option>
																<option value="celebrity_commission" selected={(this.state.cname=='celebrity_commission')?'selected':''}>Celebrity Commission</option>
																<option value="church_commission" selected={(this.state.cname=='church_commission')?'selected':''}>Church Commission</option>
															</select>
															<font className="errorMsg">{this.state.errors["nameerror"]}</font>
															<font className="errorMsg">{this.state.nameerror}</font>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-group">
															<label>{this.state.amountlabel}<span className="requiredastrik">*</span></label>
															<input type="text" placeholder={this.state.amountlabel} className="form-control" name="c_amount" value={this.state.c_amount} onChange={this.onChangeCAmount.bind(this)} />
															<font className="errorMsg">{this.state.errors["amounterror"]}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 notmainbtn">
														<button type="submit" disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>           
							</div>
							<hr />
							<div className="box-body no-padding">
								<div className="table-responsive">
									<table id="eventTicketList" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Commission Type</th>
												<th>Commission Name</th>
												<th className="text-center">Value</th>
												<th className="actionbtnsets" style={{textAlign:'center'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.commissionlist.map((result) => {
												return (
													<tr>
														<td>{result.c_type}</td>
														<td>{result.c_name}</td>
														{(result.c_type === 'Fixed')?<td className="text-center">{(result.c_amount)?'$'+result.c_amount:''}</td>:<td className="text-center">{(result.c_amount)?result.c_amount+'%':''}</td>}
														<td className="actionbtns" style={{textAlign:'center'}}><Link to="#" title="Edit Commission" onClick={() => this.editTicket(result.id)} className="btn btn-info custombtn"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;
														<Link to="#" title="Delete Commission" onClick={() => this.deleteCommissions(result.id)} className="btn btn-info custombtn"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>        
				</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}