import React, { Component } from 'react';
import Axios from 'axios';
import  { Link } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Loader from '../components/loader.component';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';

const base_api_url = API_URL;
const axiosConfig = {
	  'Content-Type': 'multipart/form-data',
	  "Access-Control-Allow-Origin": "*",
};
const dtarray = [];
export default class SongApproval extends Component {
    constructor(props){
		super(props);
        this.onChangeCheckReview = this.onChangeCheckReview.bind(this);
        this.onReviewSubmit = this.onReviewSubmit.bind(this);

        this.state = {
			reviewarray:[],
			msg: null,
			successclass:null,
			pendingsonglist:[],
			selectedcover:'Choose File',
			selectedsong:'Choose File'		  
		}
    }

    componentDidMount(){
		document.title = "CONQER Music - Song approval";		
		this.getpendingsonglist();
	}

	componentDidUpdate(){
		$('#approvallist').DataTable(
			  {		
				"destroy": true,
				"iDisplayLength" : 50,		
				"lengthMenu": [[5, 10, 15, -1], [5, 10, 15, "All"]]
			  }
			);
		
	}


    getpendingsonglist = () => {
		const usertoken = this.props.match.params.id;
		
		const obj = {
			token:usertoken
		}
		
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		}
		
		Axios.post(base_api_url+'pendingsonglist.php',obj,{axiosConfig})
		.then(
			(res) => {
				console.log(res.data);
				if(res.data.res_status==200){
					this.setState({
						pendingsonglist:res.data.res_data,
					});
				}
			}
		);
	}

    onChangeCheckReview(e){
		
		if(e.target.checked==true){
			dtarray.push(e.target.value)
		}else{
			const id = dtarray.indexOf(e.target.value); // 2
			dtarray.splice(id,  1);					
		}
		
		this.setState({
		  reviewarray: dtarray
		});
	}

    onReviewSubmit = (e) =>{
		e.preventDefault();
		const formData = new FormData();
		formData.append("reviewsong", this.state.reviewarray);
		formData.append("user_id", ls.get('userid'));
		Axios.post(base_api_url+'sendreviewsong.php', formData, {axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					window.scrollTo(0, 0);
					this.setState({
						successclass:'successmsg',
						msg:'Song sends for approval!'
					});	
					this.getpendingsonglist();					
				}else{
					window.scrollTo(0, 0);
					this.setState({
						successclass:'errormsg',
						msg:'Song not send for approval, please try again!'
					})
				}
				setTimeout(() => {
				  this.setState({
					successclass:null,
					msg:null					
				  });
				}, 5000)
			}
		);
		
	}

    render() {
		
		//if (this.state.sendreview==true) return <Loader />;
        return (
			<React.Fragment>
			  <div className={this.state.successclass}>{this.state.msg}</div>
              <div className="row">
				<div className="col-md-12">
					<div className="box box-success distributions-profileform">
						<div className="box-header with-border addprofilenew">
						  <h3 className="box-title">Send song for approval</h3>
						</div>
                        <div className="box-body no-padding">
                        <div className="row">
							<div className="col-md-12">
							  <div className="sectionhead">
								<div className="headsec">
								  <h3>Unapprove Song List</h3>
								</div>
							  </div>
							</div>
						  </div>
						  <form name="sendreview" className="artist-form" onSubmit={this.onReviewSubmit}>
						  <div>										
								<table id="approvallist" className="table no-margin table-striped table-hover" >
									<thead>
										<tr>
											<th><input type="checkbox" name="checkparent" value="1" /></th>
											<th>#</th>
											<th>Title</th>
											<th>Length</th>
											<th>Version</th>
											<th>IRSC</th>
											<th>Status</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{(this.state.pendingsonglist===[])?<tr><td colspan="8">No song for review.</td></tr>:this.state.pendingsonglist.map(items => {
										  return (
											<tr>
												<th><input type="checkbox" name="check[]" value={items.id} onChange={this.onChangeCheckReview} /></th>
												<td>{items.id}</td>
												<td>{items.title}</td>
												<td>{items.song_length}</td>
												<td>{items.version}</td>
												<td>{items.ISRC_code}</td>
												<td><span className="label label-danger">Not approved</span></td>
												<td><Link to={'/edit-song/'+items.id} className="btn btn-info custombtn">Edit</Link>  <a href="" onClick={() => this.deletesong(items.id)} className="btn btn-info custombtn">Delete</a> </td>
											</tr>
										  )
										  })
										}
									</tbody>
								</table>
						  </div>
						   <div className="col-md-12">&nbsp;</div>
							  <div className="row">
								<div className="col-md-12">
								  <div className="checkbox-term">
									<label>
										<input id="terms" className="required" required="required" type="checkbox" name="terms" aria-required="true" /> I agree to the <Link to={'/terms-and-conditions'} activeClassName="active">Terms &amp; Conditions</Link><span className="requiredastrik">*</span>
									</label>
								</div>
								</div>
							  </div>
							  <div className="box-footer clearfix">
								<button type="submit" className="btn btn-primary">Submit For Review</button>
								<a className="btn btn-danger">Cancel</a>
							  </div>
						  </form>
						</div>
					</div>
				</div>        
			  </div>      
			
			</React.Fragment>
        );
    }

}