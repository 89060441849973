import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { API_URL, STRIPE_KEY } from '../config/constants.js';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(STRIPE_KEY);
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
const errors = {};
class BookTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      bttext: 'Submit',
      errors: [],
      ticketlist: [],
      ticket_type: '',
      price: '',
      primary_price: '',
      no_of_ticket: '',
      available_seats: '',
      paymentResult: false,
      end_ticket: true,
      redirect: false,
      comission: '$0',
      stripe_detail: '',
      stripe_fee: '0',
      f_process_fee:''
    };
  }
  getEventTicketList() {
    const obj = {
      event_id: this.props.match.params.id,
    };
    this.setState({
      loading: true,
    });
    Axios.post(base_api_url + 'ticket-list.php', obj, { axiosConfig }).then((res) => {
      this.setState({
        loading: false,
        ticketlist: res.data.res_data,
        comission: res.data.com_data,
        stripe_detail: res.data.stripe_detail,
        process_amount: res.data.stripe_fee,
        process_type: res.data.stripe_fee_type
      });
    });
  }
  componentDidMount() {
    document.title = 'CONQER Music - Book Ticket';
    this.getEventTicketList();
    const token = ls.get('token');
    const userid = ls.get('userid');
    this.setState({
      token: token,
      userid: userid,
    });
  }
  tickets(e) {
    if (this.state.errors['titleerror']) {
      delete this.state.errors['titleerror'];
    }
    this.state.ticketlist.map((result) => {
      if (result.id === e.target.value) {
    		if(result.ticket_end){
    			this.setState({
    				errors: {titleerror: "Ticket booking closed at "+result.end_date+"."}
    			})
    		}
        if (this.state.no_of_ticket) {
          if(this.state.process_type == '%'){
            let final = parseFloat(((result.price * this.state.no_of_ticket)*this.state.process_amount)/100).toFixed(2)
            this.setState({
              f_process_fee: final
            });
          }else{
            let final = parseFloat(((result.price * this.state.no_of_ticket)+this.state.process_amount)).toFixed(2)
            this.setState({
              f_process_fee: final
            });
          }
          this.setState({
            primary_price: result.price,
            price: parseFloat(result.price * this.state.no_of_ticket).toFixed(2),
            available_seats: parseInt(result.seats),
            end_ticket: result.ticket_end,
            end_date: result.end_date
          });
        } else {
          if(this.state.process_type == '%'){
            let final = parseFloat((result.price*this.state.process_amount)/100).toFixed(2)
            this.setState({
              f_process_fee: final
            });
          }else{
            let final = parseFloat(result.price+this.state.process_amount).toFixed(2)
            this.setState({
              f_process_fee: final
            });
          }
          this.setState({
            primary_price: result.price,
            price: parseFloat(result.price).toFixed(2),
            available_seats: parseInt(result.seats),
            end_ticket: result.ticket_end,
            end_date: result.end_date
          });
        }
      }
    });
    if(e.target.value){
      this.setState({
        ticket_type: e.target.value,
      });
    }else{
      this.setState({
        ticket_type: e.target.value,
        price:'',
        f_process_fee:''
      });
    }
    
  }
  onChangeTicketNumber(e) {
    if (this.state.errors['nubmererror']) {
      delete this.state.errors['nubmererror'];
    }
    const re = /^[0-9\b]+$/;
    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value > 0) {
      let price = this.state.primary_price * e.target.value;
      if(this.state.process_type == '%'){
        let final = parseFloat((price*this.state.process_amount)/100).toFixed(2)
        this.setState({
          f_process_fee: final
        });
      }else{
        let final = parseFloat(price+this.state.process_amount).toFixed(2)
        this.setState({
          f_process_fee: final
        });
      }
      this.setState({
        no_of_ticket: e.target.value,
        price: parseFloat(this.state.primary_price * e.target.value).toFixed(2),
      });
    }else{
      this.setState({
        no_of_ticket: '',
        price: parseFloat(this.state.primary_price).toFixed(2)
      });
    }
  }
  handleValidation = () => {
    let formIsValid = true;
    if (!this.state.ticket_type) {
      formIsValid = false;
      errors['titleerror'] = 'Please select ticket.';
    }else if(this.state.end_ticket){
      formIsValid = false;
      errors['titleerror'] = 'Ticket booking closed at '+this.state.end_date+'.';
    }
    if (!this.state.no_of_ticket) {
      formIsValid = false;
      errors['nubmererror'] = 'Please enter number of tickets.';
    } else if (this.state.no_of_ticket == 0) {
      formIsValid = false;
      errors['nubmererror'] = 'Please enter minimum 1 ticket.';
    } else if (this.state.no_of_ticket > this.state.available_seats) {
      formIsValid = false;
      if (this.state.available_seats) {
        if(this.state.available_seats == 1){
          errors['nubmererror'] = 'Only ' + this.state.available_seats + ' seat is available.';
        }else{
          errors['nubmererror'] = 'Only ' + this.state.available_seats + ' seats are available.';
        }        
      } else {
        errors['nubmererror'] = 'No seats are available.';
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  async onToken() {
    const { elements, stripe } = this.props;

    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if(result.error){
      console.log(result);
    }else{
      this.setState({
        loading: true,
      });
      let formData = {
        "payment_token":result.token.id,
        "event_id": this.props.match.params.id,
        "ticket_type": this.state.ticket_type,
        "number_of_tickets": this.state.no_of_ticket,
        "total_price": this.state.price,
        "initial_price": this.state.primary_price,
        "listener_id": this.state.userid
      };
      Axios.post(base_api_url + 'event_ticket_payment.php', formData, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          this.setState({
            successclass: 'successmsg',
            msg: 'Ticket booked successfully!',
            ticket_type: '',
            no_of_ticket: '',
            price: '',
            f_process_fee:''
          });
        }else {
          this.setState({
            successclass: 'errormsg',
            msg: 'Ticket not booked, please try again!',
          });
        }
        this.getEventTicketList();
        this.setState({
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            successclass: null,
            msg: null,
            redirect: true
          });
          
        }, 5000);
      }
      )
    }
  }
  freeTicketPayments(){
    this.setState({ loading: true});
    let formData = {
      "type":"free",
      "event_id": this.props.match.params.id,
      "ticket_type": this.state.ticket_type,
      "number_of_tickets": this.state.no_of_ticket,
      "total_price": this.state.price,
      "initial_price": this.state.primary_price,
      "listener_id": this.state.userid
    };
    Axios.post(base_api_url + 'event_ticket_payment.php', formData, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          this.setState({
            successclass: 'successmsg',
            msg: 'Ticket booked successfully!',
            ticket_type: '',
            no_of_ticket: '',
            price: '',
          });
        }else {
          this.setState({
            successclass: 'errormsg',
            msg: 'Ticket not booked, please try again!',
          });
        }
        this.getEventTicketList();
        this.setState({
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            successclass: null,
            msg: null,
            redirect: true
          });
          
        }, 5000);
      }
      )
  }
  async onSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      if(this.state.price > 0){
        await this.onToken();
      }else{
        this.freeTicketPayments();
      }
    }
  }
  render() {
    if (this.state.redirect == true) {
      return <Redirect to={'/listener-myevents/' + this.state.token} />
    }
    if (this.state.loading == true) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
        <div className={this.state.successclass}>{this.state.msg}</div>
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">Book Tickets</h3>
                <div className="box-tools">
                  <NavLink to={'/listener-myevents/' + this.state.token} className="btn btn-primary">
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
                  </NavLink>
                </div>
              </div>
              <div className="box-body no-padding havetabs">
                <div className="">
                  <div className="">
                    <div className={this.state.tabdetailclass} id="tab_1">
                      <form name="ticket-types">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="sectionhead">
                              <div className="headsec">
                                <h3>Ticket Information</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Ticket<span className="requiredastrik">*</span>
                              </label>
                              <select className="custom-select" name="access" onChange={this.tickets.bind(this)} value={this.state.ticket_type}>
                                <option value="">Select Ticket</option>
                                {this.state.ticketlist.map((result) => {
                                  return <option value={result.id}>{result.ticket_title} ( {result.seats} )</option>;
                                })}
                              </select>
                              <font className="errorMsg">{this.state.errors['titleerror']}</font>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Price[$]</label>
                              <input type="text" placeholder="Price" className="form-control" name="ticketprice" value={this.state.price} readOnly />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputFile">CC Processing Fee[$]</label>
                              <input type="text" class="form-control required" placeholder="CC Processing Fee" value={this.state.f_process_fee} readOnly/>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Number of tickets<span className="requiredastrik">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Number of tickets"
                                className="form-control"
                                onChange={(e) => this.onChangeTicketNumber(e)}
                                value={this.state.no_of_ticket}
                              />
                              <font className="errorMsg">{this.state.errors['nubmererror']}</font>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                            {(this.state.price > 0)?
                              <>
                              <label>
                                Payment<span className="requiredastrik">*</span>
                              </label>
                              <div className="creditcardsection">
                                <CardElement options={{ hidePostalCode: true }} />
                              </div>
                              </>
                              :""}
                              
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <small>
                              <strong>Note</strong>* {this.state.stripe_detail}
                            </small>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 notmainbtn">
                            <button onClick={this.onSubmit.bind(this)} disabled={this.state.disabled} className="btn btn-primary">
                              {this.state.bttext}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default function InjectedbookTicketForm({ match }) {
  console.log('stripe', stripePromise);
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <BookTickets match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}
