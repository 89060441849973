import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL, GOOGLE_API_KEY } from '../../config/constants.js';
import { Redirect, NavLink, Link } from 'react-router-dom';
import AdminHeader from './header.component';
import AdminTopHeader from './header-top.component';
import Axios from 'axios';
import Spinner from '../../includes/Spinner.component';
import { PieChart } from 'react-minimal-pie-chart';
import DOMPurify from 'dompurify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import GoogleMapReact from 'google-map-react'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Geocode from "react-geocode";
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import defaultimg from '../../components/default.png';
import Chart from 'react-apexcharts';

const errors = {};
export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndsDate = this.onChangeEndsDate.bind(this);
    this.state = {
      eventData: [],
      artistCard: [],
      recentMembers: [],
      markers: [],
      totalSongs: '0',
      totalUsers: '0',
      totalEvents: '0',
      totalUpcomingEvent: '0',
      totalPastEvent: '0',
      listener: '0',
      artist: '0',
      totalRevenue: '$0',
      totalDisapprovedSongs: '0',
      totalApprovedSongs: '0',
      graphText: 'Songs',
      loader: true,
      defaultClassAll: true,
      defaultClassMonth: true,
      defaultClassYear: true,
      defaultClassCustom: true,
      isActive: false,
      activeSongGraph: true,
      activeEventGraph: false,
      activeUsersGraph: false,
      activeRevenueGraph: false,
      startdate: new Date(),
      enddate: new Date(),
      filterType: 'all',
      graphFor: 'Songs',
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        //colors: ['#b8a25e'],
        dataLabels: {
          enabled: true,
          style: {
          //  colors: ['#b8a25e']
          }
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
         plotOptions: {
          bar: {
            dataLabels: {
              orientation: 'vertical',
              position: 'center' // bottom/center/top
            }
          }
        },
        dataLabels: {
          style: {
            colors: ['#878585']
          },
          offsetY: 15, // play with this value
        },
        yaxis: [
          {
            labels: {
              formatter: (value) => { return value },
            }
          }
        ]
      },
      series: [
        {
          name: 'Songs',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
		seriesDonut: [0],
		optionsDonut: {
		  chart: {
			type: 'donut',
			width: 90
		  },
		  labels: [''],
		  legend: {
			show: false
		  },

		  responsive: [{
			breakpoint: 480,
			options: {
			  chart: {
				width: 200
			  },
			  legend: {
				position: 'bottom'
			  }
			}
		  }]
		},
    };
  }

  dashboardData(type) {
    this.setState({
      filterType: type
    });
    if (typeof type === 'undefined') {
      this.setState({
        defaultClassAll: false,
        filterType: 'all'
      });
    }

    const obj = {
      type: type,
      from: type === 'custom' ? moment(this.state.startdate).format('yyyy-MM-DD') : '',
      to: type === 'custom' ? moment(this.state.enddate).format('yyyy-MM-DD') : ''
    };
    const axiosConfig = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    };
    this.dashboardLineChart(this.state.graphFor, type);
    Axios.post(API_URL + 'admin-dashboard.php', obj, { axiosConfig }).then((res) => {
      if (res.data.res_status === '200') {
        const response = res.data.res_data.res_chart_data;
        this.setState({
          totalSongs: res.data.res_data.total_song,
          totalEvents: res.data.res_data.total_event,
          totalUpcomingEvent: res.data.res_data.total_upcoming_events,
          totalApprovedSongs: res.data.res_data.total_approved_song,
          totalDisapprovedSongs: res.data.res_data.total_disapproved_song,
          totalPastEvent: res.data.res_data.total_past_event,
          totalUsers: res.data.res_data.total_users,
          totalRevenue: res.data.res_data.total_revenue,
          ticketRevenue: res.data.res_data.ticket_revenue,
          songRevenue: res.data.res_data.song_revenue,
          artistCard: res.data.res_data.artist_data,
          recentMembers: res.data.res_data.recent_users,
          eventData: res.data.res_data.event_data,
          markers: res.data.res_data.list_address,
          listener: res.data.res_data.total_grp_users.listener,
          artist: res.data.res_data.total_grp_users.artist,
		      seriesDonut: res.data.res_data.demography_state_data,
		  optionsDonut: {
			  labels: res.data.res_data.demography_state_label,
			  legend: {
				show: false
			  }
		  },
          loader: false
        });
      }
    });
  }
  getDasbhoardData(type) {
    if (type === 'all') {
      this.setState({
        defaultClassAll: false,
        defaultClassMonth: true,
        defaultClassYear: true,
        defaultClassCustom: true,
        isActive: false
      });
    }
    if (type === 'monthly') {
      this.setState({
        defaultClassAll: true,
        defaultClassMonth: false,
        defaultClassYear: true,
        defaultClassCustom: true,
        isActive: false
      });
    }
    if (type === 'yearly') {
      this.setState({
        defaultClassAll: true,
        defaultClassMonth: true,
        defaultClassYear: false,
        defaultClassCustom: true,
        isActive: false
      });
    }
    if (type === 'custom') {
      this.setState({
        defaultClassAll: true,
        defaultClassMonth: true,
        defaultClassYear: true,
        defaultClassCustom: false,
        isActive: true
      });
    }
    this.setState({
      totalSongs: '0',
      totalEvents: '0',
      totalUsers: '0',
      totalRevenue: '$0',
      totalUpcomingEvent: '0',
      totalPastEvent: '0',
      listener: '0',
      artist: '0',
      loader: true,
      isActive: false,
      totalApprovedSongs: '0',
      totalDisapprovedSongs: '0'
    });

    this.dashboardData(type);
  }
  customShowForm() {
    this.setState({
      defaultClassAll: true,
      defaultClassMonth: true,
      defaultClassYear: true,
      defaultClassCustom: false,
      isActive: true
    });
  }
  onChangeStartDate(stdate) {
    this.setState({
      startdate: stdate,
      enddate: ''
    });
  }

  onChangeEndsDate(endate) {
    this.setState({
      enddate: endate
    });
  }
  componentDidMount() {
    document.title = 'Dashboard';
    this.dashboardData();  
    Geocode.setApiKey("AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0");
    Geocode.setLanguage("en");
    const script = document.createElement('script')
      script.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js'
      script.async = true
      document.body.appendChild(script)  
  }
  getDasbhoardGraphData(graphType) {
    if (graphType === 'Songs') {
      this.setState({
        activeSongGraph: true,
        activeEventGraph: false,
        activeUsersGraph: false,
        activeRevenueGraph: false,
        graphText: 'Songs'
      });
    }
    if (graphType === 'Events') {
      this.setState({
        activeSongGraph: false,
        activeEventGraph: true,
        activeUsersGraph: false,
        activeRevenueGraph: false,
        graphText: 'Events'
      });
    }
    if (graphType === 'Users') {
      this.setState({
        activeSongGraph: false,
        activeEventGraph: false,
        activeUsersGraph: true,
        activeRevenueGraph: false,
        graphText: 'Users'
      });
    }
    if (graphType === 'Revenue') {
      this.setState({
        activeSongGraph: false,
        activeEventGraph: false,
        activeUsersGraph: false,
        activeRevenueGraph: true,
        graphText: 'Revenue'
      });
    }
    this.setState({
      loading: true,
      graphFor: graphType
    });
    this.dashboardLineChart(graphType, this.state.filterType);
  }
  dashboardLineChart(dataFor, filterType) {
    const obj = {
      type: filterType,
      data_for: dataFor,
      from: filterType === 'custom' ? moment(this.state.startdate).format('yyyy-MM-DD') : '',
      to: filterType === 'custom' ? moment(this.state.enddate).format('yyyy-MM-DD') : ''
    };
    const axiosConfig = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    };
    if(dataFor === 'Events'){
      Axios.post(API_URL + 'admin-event-chart.php', obj, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          const response = res.data.res_data.res_chart_data;
          this.setState({
            series: [
              {
                name: 'Upcomming Event',
                data: response[0]
              },
              {
                name: 'Past Event',
                data: response[1]
              }
            ],
            loading: false
          });
        }
      });
    }else if(dataFor === 'Songs'){
      Axios.post(API_URL + 'admin-song-chart.php', obj, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          const response = res.data.res_data.res_chart_data;
          this.setState({
            series: [
              {
                name: 'Unapproved Song',
                data: response[0]
              },
              {
                name: 'Approved Song',
                data: response[1]
              }
            ],
            loading: false
          });
        }
      });
    }else if(dataFor === 'Users'){
      Axios.post(API_URL + 'admin-user-chart.php', obj, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          const response = res.data.res_data.res_chart_data;
          this.setState({
            series: [
              {
                name: 'Listener',
                data: response[0]
              },
              {
                name: 'Artist',
                data: response[1]
              }
            ],
            loading: false
          });
        }
      });
    }else{
      Axios.post(API_URL + 'admin-dashboard-linechart.php', obj, { axiosConfig }).then((res) => {
        if (res.data.res_status == 200) {
          const response = res.data.res_data.res_chart_data;
          this.setState({
            series: [
              {
                name: 'Ticket Revenue',
                data: response[0]
              },
              {
                name: 'Song Revenue',
                data: response[1]
              }
            ],
            loading: false
          });
        }
      });
    }
    
  }
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
  onclickseturl = () => {
    $('#myModal').modal('show');
  }
  setGoogleMapRef (map, maps, loc) {
      this.googleMapRef = map
      this.googleRef = maps
      let locations = loc;
      let markers = locations && locations.map((location) => {
        return new this.googleRef.Marker({position: location})
      })
      const markerCluster = new MarkerClusterer({ map, markers });
  }
  render() {
    let marks = this.state.markers;
    console.log(marks);
    const defaultLabelStyle = {
      fontSize: '5px',
      fontFamily: 'sans-serif'
    };
    const List = [];
    for (let i = 0; i <= 3-this.state.eventData.length; i++) {
      List.push(<li className="item" style={{"height":"53px","borderBottom": "none"}}><div className="song-info"></div></li>);
    }    
    return (
      <div>
        <div className="dashboard-wrapper">
          <AdminHeader />
          <div className="maincontent-area">
            <AdminTopHeader />
            <div className="main-dashsection">
              <section className="contentpard">
                <div className="row smartbuttons">
                  <div className="col-md-12">
                    <div className="multifilter">
                      <button
                        type="button"
                        className={this.state.defaultClassAll ? 'btn btn-secondary btn-sm' : 'btn btn-primary btn-sm'}
                        onClick={() => this.getDasbhoardData('all')}
                      >
                        All
                      </button>
                      <button
                        type="button"
                        className={this.state.defaultClassYear ? 'btn btn-secondary btn-sm' : 'btn btn-primary btn-sm'}
                        onClick={() => this.getDasbhoardData('yearly')}
                      >
                        YTD
                      </button>
                      <button
                        type="button"
                        className={this.state.defaultClassMonth ? 'btn btn-secondary btn-sm' : 'btn btn-primary btn-sm'}
                        onClick={() => this.getDasbhoardData('monthly')}
                      >
                        MTD
                      </button>
                      <button
                        type="button"
                        className={this.state.defaultClassCustom ? 'btn btn-secondary btn-sm' : 'btn btn-primary btn-sm'}
                        onClick={() => this.customShowForm()}
                      >
                        Custom Date
                      </button>
                      <span className={this.state.isActive ? 'popuptext show' : 'popuptext'} id="myPopup">
                        <table width="100%">
                          <tr>
                            <td className="fisrtow">
                              <DatePicker
                                selected={this.state.startdate}
                                onChange={this.onChangeStartDate}
                                name="startdate"
                                dateFormat="MM/dd/yyyy"
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                required={true}
                                placeholderText="MM/DD/YYYY"
                                className="form-control"
                                id="startdate"
                              />
                            </td>
                            <td className="fisrtow">
                              <DatePicker
                                selected={this.state.enddate}
                                onChange={this.onChangeEndsDate}
                                dateFormat="MM/dd/yyyy"
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                required={true}
                                placeholderText="MM/DD/YYYY"
                                minDate={this.state.startdate ? new Date(this.state.startdate) : new Date()}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input type="button" name="button" id="button" onClick={() => this.getDasbhoardData('custom')} value="GO"></input>
                            </td>
                          </tr>
                        </table>
                      </span>
                    </div>
                  </div>
                </div>
				{(this.state.loader == true) ? <Spinner />:
                <><div className="row kpisarea">
                  <div className="col-md-3 col-sm-6 col-xs-12" onClick={() => this.getDasbhoardGraphData('Songs')}>
                    <div className={this.state.activeSongGraph ? 'info-box active' : 'info-box'}>
                      <div className="infoheaderarea">
                        <span className="info-box-icon bg-songs">
                          <i className="fa fa-music"></i>
                        </span>
                        <span className="info-box-text">Songs</span>
                      </div>

                      <div className="info-box-content">
                        <span className="info-box-number" title="Total Songs">{this.state.totalSongs}</span>
                      </div>
                      <div className="infor-box-footerarea">
                        <span className="footerprogress" title="Total Unapproved">
                          <i className="fa fa-upload"></i> {this.state.totalDisapprovedSongs}
                        </span>
                        <span className="footerprogress right" title="Total Approved">
                          <i className="fa fa-check-circle"></i> {this.state.totalApprovedSongs}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-xs-12" onClick={() => this.getDasbhoardGraphData('Events')}>
                    <div className={this.state.activeEventGraph ? 'info-box active' : 'info-box'}>
                      <div className="infoheaderarea">
                        <span className="info-box-icon bg-events">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <span className="info-box-text">Events</span>
                      </div>

                      <div className="info-box-content">
                        <span className="info-box-number" title="Total Events">{this.state.totalEvents}</span>
                      </div>
                      <div className="infor-box-footerarea">
                        <span className="footerprogress" title="Upcoming Event">
                          <i className="fa fa-calendar-minus-o"></i> {this.state.totalUpcomingEvent}
                        </span>
                        <span className="footerprogress right" title="Past Event">
                          <i className="fa fa-calendar-check-o"></i> {this.state.totalPastEvent}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="clearfix visible-sm-block"></div>

                  <div className="col-md-3 col-sm-6 col-xs-12" onClick={() => this.getDasbhoardGraphData('Users')}>
                    <div className={this.state.activeUsersGraph ? 'info-box active' : 'info-box'}>
                      <div className="infoheaderarea">
                        <span className="info-box-icon bg-listener">
                          <i className="fa fa-users"></i>
                        </span>
                        <span className="info-box-text">Users</span>
                      </div>

                      <div className="info-box-content">
                        <span className="info-box-number"  title="Total Users">{this.state.totalUsers}</span>
                      </div>
                      <div className="infor-box-footerarea">
                        <span className="footerprogress" title="Listener">
                          <i className="fa fa-headphones"></i> {this.state.listener}
                        </span>
                        <span className="footerprogress right" title="Artist">
                          <i className="fa fa-users"></i> {this.state.artist}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-xs-12" onClick={() => this.getDasbhoardGraphData('Revenue')}>
                    <div className={this.state.activeRevenueGraph ? 'info-box active' : 'info-box'}>
                      <div className="infoheaderarea">
                        <span className="info-box-icon bg-rev">
                          <i className="fa fa-usd"></i>
                        </span>
                        <span className="info-box-text">Revenue</span>
                      </div>

                      <div className="info-box-content">
                        <span className="info-box-number" title="Total Revenue">{this.state.totalRevenue}</span>
                      </div>
                      <div className="infor-box-footerarea">
                        <span className="footerprogress" title="Song Revenue">
                          <i className="fa fa-music"></i> {this.state.songRevenue}
                        </span>
                        <span className="footerprogress right" title="Ticket Revenue">
                          <i className="fa fa-ticket"></i> {this.state.ticketRevenue}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row songschart">
                  <div className="col-md-9">
                    <div className="box box-success">
                      <div className="box-header with-border">
                        <h3 className="box-title">{this.state.graphText}</h3>
                      </div>
                      <div className="box-body no-padding">
                        <div className="row">
                          <div className="col-md-12 line-label">
                          {(this.state.loading)?<div style={{fontSize:'24px', textAlign:'center', display: 'flex',minHeight: '225px',alignItems: 'center',justifyContent: 'center'}}><i class="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i> Please Wait...</div>:
                            <Chart options={this.state.options} series={this.state.series} type="bar" height="210" />
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="box box-success">
                      <div className="box-header with-border">
                        <h3 className="box-title">Listener Stats <span style={{fontSize:'12px',marginLeft:'40px'}}>{(marks.length)?<Link to="#" onClick={() => this.onclickseturl()}>View Map</Link>:""}</span></h3>
                      </div>
                      <div className="box-body" style={{height:'255px'}}>
                        <div className="row">
                          <div className="col-md-12">
							           <Chart options={this.state.optionsDonut} series={this.state.seriesDonut} type="donut" height="223"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row recentusersevents">
                  <div className="col-md-8">
                    <div className="box box-success">
                      <div className="box-header with-border">
                        <h3 className="box-title">Recent Events</h3>
                      </div>
                      <div className="box-body recent-evnts">
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="topsongs-list songss-list-in-box">
                              {this.state.eventData.length > 0
                                ? this.state.eventData.map((result, i) => {
                                      return (
                                        <li className="item">
                                          <div className="song-img">
                                            <i className="fa fa-calendar"></i>
                                          </div>
                                          <div className="song-info">
                                            <NavLink to={'/admin/events/view/' + result.id} className="sonmgs-title">
                                              {result.name}
                                              <span
                                                className={
                                                  result.event_status == 'Past' ? 'label label-success pull-right' : 'label label-warning pull-right'
                                                }
                                              >
                                                {result.event_status}
                                              </span>
                                            </NavLink>
                                            <span className="songs-description">{(result.description !=='null')?result.description:'-'}</span>
                                          </div>
                                        </li>
                                      );
                                  })
                                : ""}  
                                {List}                           
                            </ul>
                            <div className="box-footer text-center">
                              <NavLink to="/admin/events" className="uppercase">
                                View All Events
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box box-success admindash">
                      <div className="box-header with-border">
                        <h3 className="box-title">Recently Joined</h3>
                      </div>
                      <div className="box-body no-padding rcusers">
                        <ul className="users-list clearfix">
                          {this.state.recentMembers.map((result) => {
							  let userUrl = '/admin/usersDetail/'+result.id;
                            return (
                              <li>
								<img onError={this.addDefaultSrc} src={result.path} src={result.path} alt="User Image" />
                                <NavLink className="users-list-name" to={userUrl} title={result.name}>
                                  {result.name}
                                </NavLink>
                                <span className="users-list-date" title={result.created_at}>
                                  {result.created_at}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="box-footer text-center">
                        <NavLink to="/admin/users" className="uppercase">
                          View All Users
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row topfivesongsd">
                  <div className="col-md-12">
                    <div className="todpfive">Top Five Artists</div>
                  </div>
                </div>
                <div className="row artisitblock">
                  {this.state.artistCard.map((result) => {
					  let artistUrl = '/admin/usersDetail/'+result.id;
                    return (
                      <div className="col-md-3">
                        <div className="box box-info">
                          <div className="box-body">
							<Link to={artistUrl}>
								<div className="profile-art">
								  <img onError={this.addDefaultSrc} src={result.path} alt="Artist Image" />
								</div>
								<div className="profileartinfo">
								  <span className="artname" title={result.artist_name}>{((result.artist_name).length > 20) ?(result.artist_name).substring(0,20-3)+'...':result.artist_name }</span>
								  <span className="folwershave">
									<i className="fa fa-users"></i> {result.followers}
								  </span>
								</div>
							</ Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="box box-info">
                      <div className="box-header with-border">
                        <h3 className="box-title">Latest Events</h3>
                      </div>

                      <div className="box-body">
                        <div className="table-responsive">
                          <table className="table no-margin table-striped table-hover" id="_event_dataTable">
                            <thead>
                              <tr>
                                <th>Event Title</th>
                                <th>Category</th>
                                <th>Artist</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th style={{ textAlign: 'center' }}>Available</th>                                
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.eventData.length > 0
                                ? this.state.eventData.map((result, i) => {
                                    if (i < 5) {
                                      return (
                                        <tr>
                                          <td>
                                            <Link to={'/admin/events/view/' + result.id}>{result.name}</Link>
                                          </td>
                                          <td>{result.event_category ? result.event_category : '-'}</td>
                                          <td>{result.artist_name}</td>
                                          <td>{result.event_start_date}</td>
                                          <td>{result.event_end_date}</td>
                                          <td style={{ textAlign: 'center' }}>
                                            <span className="label label-success">{result.seats ? result.seats : '0'}</span>
                                          </td>
                                          
                                        </tr>
                                      );
                                    }
                                  })
                                : ''}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="box-footer clearfix">
                        <Link to="/admin/events" className="btn btn-sm btn-info btn-flat pull-right">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
				</ >
				}
              </section>
            </div>
          </div>
        </div>
        <div id="myModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
        {(marks.length)?
          <div className="modal-dialog modal-lg" style={{maxWidth:'1080px'}}>
            <div className="modal-content">
              <button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
              <div className="modal-body" style={{height:'500px'}}>
              <GoogleMapReact
                    bootstrapURLKeys={{key: GOOGLE_API_KEY}}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => this.setGoogleMapRef(map, maps, marks)}
                    defaultCenter={{lat:37.090240, lng:-95.712891}}
                    defaultZoom={1}
                    maxZoom={10}
                    options={{streetViewControl: true}}
                  ></GoogleMapReact>
              </div>            
            </div>
          </div>:""
        }
        </div>
      </div>
      
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0'
})(AdminDashboard);