import React, { Component } from 'react';
import Axios from 'axios';
import  { Link  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}

export default class ListenerMyBooking extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {		  
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  eventbooklist:[],		  
		  loading:true,
		  loader:null,		  
		  redirect:false
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - My Bookings";
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({	
			token:token,		
			userid:userid
		});
		
		this.getevbooklist();
		
	}
	componentDidUpdate(){
		$('#myevents').DataTable(
			{	
				"destroy": true,
				"stateSave": true,
				"pageLength":10,
				"order": [[ 3, "desc" ]],
				"language": {
					"search": "Search",
					"sEmptyTable": "No Event Found"
				},				
				"lengthMenu": [[5, 10, 15, -1], [5, 10, 15, "All"]],
				columnDefs: [
	   				{ orderable: false, targets: -1 }
				]
			}
		);
	}
	getevbooklist = () => {
		const obj = {
			userid:ls.get('userid')
		}
		
		Axios.post(base_api_url+'listener-eventbooklist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data
					this.setState({
						eventbooklist:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	
	render() {
		if(this.state.loading==true){
			return <Spinner />;
		}
		let maxlimit = '30';
		return (
			<React.Fragment>
				
				<div className={this.state.successclassName}>{this.state.msg}</div>
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
									<h3 className="box-title">My Bookings</h3>  
									<div className="box-tools saearchicons">
										<div className="input-group input-group-sm hidden-xs">
										</div>
									</div>            
								</div>
								<div className="box-body no-padding">
									<div className="table-responsive">
										<table id="myevents" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Event Name</th>                    
												<th>Ticket Title</th>                    
												<th>Event Start Date</th>
												<th>Booking Date</th>
												<th className="text-center">Booked Seats</th>
												<th className="text-center">Paid Amount</th>
												<th>Location</th>
												<th>Reference ID</th>
											</tr>
										</thead>
										<tbody>
											{this.state.eventbooklist.map((result) => {
												console.log('result',result);
												return (
												  <tr>                  
													<td><Link to={'/listener-viewevent/'+result.id}>{result.event_name}</Link></td>
													<td>{result.ticket_title}</td>
													<td>{result.event_start_date}</td>
													<td>{result.event_book_date}</td>
													<td className="text-center">{result.book_seats}</td>
													<td className="text-right">${parseFloat(result.total_amount).toFixed(2)}</td>
													<td>{(result.address)?result.address+' '+result.city:""}<br />{(result.state)?result.state+', '+result.country+' '+result.zipcode:""}</td>
													<td>{result.reference_id}</td>
												  </tr>	
												)
											})}
										</tbody>
									</table>
								</div>              
							</div>
							<div className="box-footer clearfix">
							</div>
						</div>
					</div>        
				</div>      			
			</React.Fragment>
		)
	}
}