import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery'; 
import Spinner from '../../../includes/Spinner.component';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
export default class Events extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	filterStatus:'',
      	filterType:'',
      	eventData:[]
      }
    }
    getEventListData(){
    	this.setState({loader:true});
    	const obj = {status:this.state.filterStatus,type:this.state.filterType}
    	const axiosConfig = {
      		'Content-Type': 'multipart/form-data',
      		"Access-Control-Allow-Origin": "*",
    	};
	    Axios.post(API_URL + 'event-list.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){
		        	
		        	this.setState({
		        		eventData: res.data.res_data,
						loader: false
					});				         
		        }else{
		        	this.setState({
		        		eventData: [],
						loader: false
					});
		        }
		        $('#myevents').DataTable().destroy();
		        	$(document).ready(function () {
				        setTimeout(function(){
				        $('#myevents').DataTable({
				        	"destroy": true,	
							"stateSave": true,
							language: {
								search: "Search"
							},
							columnDefs: [
				   				{ "orderable": false, "targets": -1, width: 200 }
							],
	  						dom: 'Bfrtip',
							  buttons: [
					            {
					                extend: 'csv',
					                exportOptions: {
					                    columns: [ 0,1,2,3,4,5 ]
					                }
					            },
					            {
					                extend: 'print',
					                exportOptions: {
					                    columns: [ 0,1,2,3,4,5 ]
					                },
					                customize: function ( win ) {
									$(win.document.body).find('table').addClass('display').css('font-size', '15px');
									$(win.document.body).find('table').css('table-layout','auto');
									$(win.document.body).find('th').css('width','15%');
									$(win.document.body).find('td').css('width','25%');
									}
					            }
					        ]
				        });
				         } ,50);
				    });     
	    	}
	    )
    }
    componentDidMount(){
		document.title = "Event List";
    	this.getEventListData();

    }
	changeDataStatus = (e) => {
		this.setState({ filterStatus: e.target.value }, () => {
			this.getEventListData();
		});
	};
	changeDataType = (e) => {
		this.setState({ filterType: e.target.value }, () => {
			this.getEventListData();
		});
	};
    deleteEvent(id){
    	if(window.confirm("Are you sure you want to delete?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deleteevent.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.getEventListData();
						this.setState({
							successclass:'successmsg',
							msg:'Event deleted successfully!',
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:'Event not deleted, please try again!'
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
    changeStatus(id,status){
    	this.setState({
    		loader:true
    	});
    	const axiosConfig = {
      		'Content-Type': 'multipart/form-data',
      		"Access-Control-Allow-Origin": "*",
    	};
    	const obj = {
			id:id,
			status:status
		}			
		Axios.post(API_URL+'updateeventstatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.getEventListData();
					this.setState({
						successclass:'successmsg',
						msg:(status === 1)?'Event unpublished successfully.':'Event published successfully.',
					});
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:'Event status not updated, please try again!'
					})
				}
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 10000)
			}
		);
    }
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
									<h3 className="box-title">Events List</h3>
									<div className="box-tools d-flex justify-content-between">
									  <select
										className="filteroptionselect"
										as="select"
										value={this.state.filterStatus}
										onChange={(e) => this.changeDataStatus(e)}
										style={{ width: '10vw' }}
									  >
										<option value="">All Status</option>
										<option value="1">Publish</option>
										<option value="0">Unpublish</option>
									  </select> &nbsp;
									  <select
										className="filteroptionselect"
										as="select"
										value={this.state.filterType}
										onChange={(e) => this.changeDataType(e)}
										style={{ width: '10vw' }}
									  >
										<option value="">All Type</option>
										<option value="0">Past</option>
										<option value="1">Upcoming</option>
									  </select>
									</div>
									<div className="box-tools saearchicons">
									  <div className="input-group input-group-sm hidden-xs"></div>
									</div>
								</div>
								<div className="box-body no-padding">
								  <div className="table-responsive">
								<table id="myevents" className="table no-margin table-striped table-hover">
									<thead>
									  <tr>
										<th>Event Name</th>
										<th>Category</th>
										<th>Artist Name</th>                
										<th>Start Date & Time</th>
										<th>End Date & Time</th>
										<th>Status</th>
										<th className="actionbtns" style={{textAlign:'center'}}>Actions</th>
									  </tr>
									</thead>
									<tbody>
									{this.state.eventData.map((result, i) => {
										return (
											<tr>
												<td><Link to={'/admin/events/view/'+result.id} title="Event Detail">{result.name}</Link><br /> <font title={result.description}>{((result.description).length > 30) ?(result.description).substring(0,30-3)+'...':result.description }</font></td>
												<td>{result.event_category}</td>
												<td>{result.artist_name}</td>
												<td>{result.event_start_date} <br /> {result.event_start_time}</td>
												<td>{result.event_end_date} <br /> {result.event_end_time}</td>
												<td><span className={(result.status===1)?"label label-success":"label label-danger"}>{result.status_text}</span></td>
												<td className="actionbtns" style={{textAlign:'center'}}>
													<Link to={"/admin/events/edit/"+result.id} className="btn btn-info custombtn" title="Edit Event"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;<Link to="#"  onClick={() => this.changeStatus(result.id,result.status)} title={(result.status === 1)?'Unpublish':'Publish'} className="btn btn-info custombtn">{(result.status === 1)?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
													<Link to="#" onClick={() => this.deleteEvent(result.id)} className="btn btn-info custombtn"  title="Delete Event"><i className="fa fa-trash" aria-hidden="true"></i></Link>&nbsp;
													<Link to={'/admin/events/reports/'+result.id} className="btn btn-info custombtn"  title="Attendee/Booking Report"><i className="fa fa-eye" aria-hidden="true"></i></Link>
												</td>
											  </tr>
										)
									})}
									  
									</tbody>
								</table>
							  </div>              
							</div>
							</div>
						</div>        
					</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}