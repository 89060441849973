import React, { Component } from 'react';
import { Row, Table } from 'react-bootstrap';
import ls from 'local-storage';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';

const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
class MyLoggedinDevices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedinListData: [],
      loading: true,
    };
  }

  componentDidMount() {
    document.title = "CONQER Music - Logged In Devices";
    this.loggedinListData();
  }

  deleteLoggedinDevice = async (devicetoken) => {
	let browsertoken = ls.get('token');
    const obj = { useremail: ls.get('email'), token: devicetoken };
    if (window.confirm('Do you want to logout from this device?')) {
		const response = await Axios.post(base_api_url + 'deleteloggedindevice.php', obj, axiosConfig);
		if (response.data.res_status === '200') {
			if(devicetoken == browsertoken){
				ls.remove('userid');
				ls.remove('email');
				ls.remove('name');
				ls.remove('role');
				ls.remove('token');
				ls.remove('membership');
				ls.remove('membership_status');
				ls.remove('profile');
				this.props.history.push('/login');
			}else{
				this.setState({ successclass: 'successmsg', msg: response.data.res_message });				
			}
		}else{
			this.setState({ successclass: 'errormsg', msg: response.data.res_message });
		}	
		this.loggedinListData();
		setTimeout(() => {
			this.setState({ successclass: 'hideclass' });
		}, 5000);
    }
  };

  loggedinListData = async () => {
    const obj = {
      email: ls.get('email'),
    };
    const response = await Axios.post(base_api_url + 'getmyloggedindevices.php', obj, axiosConfig);
    $('#myevents2').DataTable().destroy();
    setTimeout(() => {
      $('#myevents2').DataTable({
        destroy: true,
        pageLength: 10,
		language: {
			search: "Search"
		},
		stateSave: true,
        lengthMenu: [
          [5, 10, 20, 50, -1],
          [5, 10, 20, 50, 'All'],
        ],
        columnDefs: [{ orderable: false, targets: -1 }],
      });
    }, 500);

    if (response.data.res_status === '200') {
      this.setState({ loggedinListData: response.data.res_data, loading: false });
    } else {
      this.setState({ loggedinListData: [] });
    }
  };

  render() {
    const loggedinListDataArray = this.state.loggedinListData;
    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : (
          
          <div className="row">          
            <div className="col-md-12">
            <div className={this.state.successclass}>{this.state.msg}</div>
              <div className="box box-success distributions-profileform">
                <div className="box-header with-border addprofilenew">
                  <h3 className="box-title">Logged In Devices</h3>
                </div>
                <div className="box-body no-padding">
                  <div className="table-responsive">
                    <table id="myevents2" className="table no-margin table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Device Type</th>
                          <th>Login Time</th>
                          <th className="actionbtns" style={{ textAlign: 'center' }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loggedinListDataArray.map((item, index) => (
                          <tr key={index}>
                            <td>{item.device_type}</td>
                            <td>{item.login_time}</td>
                            <td className="actionbtns d-flex justify-content-center">
                              <Link onClick={() => this.deleteLoggedinDevice(item.token)} className="btn  btn-info custombtn" title="Logout from this device">
                                <i className="fa fa-sign-out"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default withRouter(MyLoggedinDevices);