import React, { Component } from 'react';
import { withRouter, Redirect, Link, NavLink } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import ls from 'local-storage';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Geocode from 'react-geocode';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { addDays, subDays, setHours, setMinutes } from 'date-fns';
import { Spinner } from 'react-bootstrap';

const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
const errors = {};
const mapStyles = {
  width: '97%',
  height: '365px',
  position: 'relative',
};
export default class EditPlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newTitle: '',
      newDescription: '',
      successclass: 'hideclass',
      msg: '',
      errors:[]
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Add event';
    this.playListData();
    this.setState({
      token: ls.get('token'),
    });
  }

  playListData = async () => {
    const obj = {
      userid: ls.get('userid'),
    };
    const response = await Axios.post(base_api_url + 'getplaylist.php', obj, axiosConfig);

    if (response.data.res_status === '200') {
      const getData = response.data.res_data.find((item) => item.id === this.props.match.params.id);
      this.setState({ newTitle: getData.title, newDescription: getData.description, is_public: getData.is_public });
    }
  };
  playListIsPublic(e){
    this.setState({
      is_public: e.target.value
    });
  }
  handleValidation = () => {
    let formIsValid = true;
    if(!this.state.newTitle){
      formIsValid = false;
      errors["title_error"] = "Please enter title.";
    }
    this.setState({errors: errors});
    return formIsValid;
  }
  async onSubmit() {
    if(this.handleValidation()){
    const obj = {
      title: this.state.newTitle,
      description: this.state.newDescription,
      is_public: this.state.is_public,
      id: this.props.match.params.id,
    };
      const response = await Axios.post(base_api_url + 'updateplaylist.php', obj, axiosConfig);
      if (response.data.res_status === '200') {
        this.setState({ successclass: 'successmsg', msg: 'Playlist updated successfully!' }, () => {
          setTimeout(() => {
            this.props.history.push(this.props.location.state.prevPath);
          }, 3000);
        });
      } else {
        this.setState({ successclass: 'errormsg', msg: 'Playlist Not updated, Please try again.' }, () => {
          setTimeout(() => {
            this.setState({ successclass: 'hideclass' });
          }, 3000);
        });
      }
  }
  }
  render() {
    let role    = ls.get('role');
    let backurl   = (role === '2')?"/playlist-artist/"+ this.state.token:"/playlist-listener/"+ this.state.token;
    return (
      <React.Fragment>
        <div className={this.state.successclass}>{this.state.msg}</div>
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">Update Playlist</h3>
                <div class="box-tools">
                <Link class="btn btn-primary" to={backurl}>
                <i class="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</Link>
                </div>
              </div>
              <div className="box-body no-padding havetabs">
                <div className="tab-content">
                  <div className={this.state.tabdetailclass} id="tab_1">
                    <form name="event-details" onSubmit={this.onSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sectionhead">
                            <div className="headsec">
                              <h3>Basic Information</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Title<span className="requiredastrik">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              name="eventtitle"
                              id="eventtitle"
                              value={this.state.newTitle}
                              onChange={(e) => this.setState({ newTitle: e.target.value })}
                            />
                            <font className="errorMsg">{this.state.errors["title_error"]}</font>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Description</label>
                            <input
                              type="text"
                              value={this.state.newDescription}
                              placeholder="Description"
                              className="form-control"
                              onChange={(e) => this.setState({ newDescription: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Is Public?</label>
                            <select className="custom-select" value={this.state.is_public} onChange={this.playListIsPublic.bind(this)}>
                              <option value="no">No</option>
                              <option value="yes">Yes</option>                   
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="row">
                      <div className="col-md-12 notmainbtn">
                        <button onClick={() => this.onSubmit()} className="btn btn-primary">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
