import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component'
import defaultimg from '../components/default.png';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import DOMPurify from "dompurify";

const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
export default class artistSingleSong extends Component {
	
	constructor(props){
		super(props);
		this.state = {
		  email:'',
		  role:null,
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  totalsong:'',
		  followstatus:false,
		  artistsdata:'',
		  allalbumlist:[],
		  loading:true,
		  successclass:null,
		  redirect:false
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Artist Page";
		
		const email = ls.get('email');
		const name = ls.get('name');
		const token = ls.get('token');
		const userid = ls.get('userid');
		this.setState({
			email:email,
			name:name,
			token:token,
			userid:userid
		});
		
		this.getArtistsdata();
		this.getAllAlbumlist();
		this.getFollowData();
		
	}
	getArtistsdata = () => {
		const obj = {
			userid:this.props.match.params.id
		}
		Axios.post(base_api_url+'userbytoken.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.listenerdata
					this.setState({
						artistsdata:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	getAllAlbumlist = () => {
		const obj = {
			userid:this.props.match.params.id
		}
		Axios.post(base_api_url+'album-list.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data
					this.setState({
						allalbumlist:returndata,
						loading:false
					});
					let songsss = 0;
					{
						this.state.allalbumlist.map((results,index) => {
							
							songsss += parseInt(results.songs);
						});
					}					
					this.setState({
						totalsong:songsss
					});
					
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	getFollowData = () =>{
		const listenerobj = {
			artistid:this.props.match.params.id,
			listenerid:ls.get('userid')
		}
		//console.log(obj);
		Axios.post(base_api_url+'getfollowstatus.php',listenerobj,{axiosConfig})
		.then(
			(followresult) => {
				if(followresult.data.res_status==200){
					const followdata = followresult.data.res_data;
					if(followdata.status === '1'){
						this.setState({
							followstatus:true
						});
					}else{
						this.setState({
							followstatus:false
						});
					}
				}else{
					this.setState({
						followstatus:false
					});
				}
			});
	}
	followArtist = (artist_id,followstatusdata) => {
		const listenerobj = {
			artistid:artist_id,
			followstatus:followstatusdata,
			listenerid:ls.get('userid')
		}
		//console.log(obj);
		Axios.post(base_api_url+'changefollowstatus.php',listenerobj,{axiosConfig})
		.then(
			(followresults) => {
				
				let currentstatus;
				if(followresults.data.res_status==200){
					window.scrollTo(0, 0);
					if(followresults.data.follow_status === '0'){
						currentstatus = false;
					}else{
						currentstatus = true;						
					}
					this.setState({
						followstatus:currentstatus,
						successclass:'successmsg',
						msg:followresults.data.res_message						
					});					
					
				}else{
					window.scrollTo(0, 0);
					if(followresults.data.follow_status === '0'){
						currentstatus = true;
					}else{
						currentstatus = false;						
					}
					this.setState({
						followstatus:currentstatus,
						successclass:'errormsg',						
						msg:followresults.data.res_message				
					})
				}
				setTimeout(() => {
				  this.setState({
					successclass:'hideclass',
					msg:null
				  });
				}, 10000)
			});
	}
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	
	render() {
		let artists = this.state.artistsdata;
        if(this.state.loading==true){
			return <Spinner />;
		}
		let maxlimit = '20';
		let artistname = artists.first_name +' '+artists.last_name;
		let artistabout = artists.about;
		let albumlist = this.state.allalbumlist;
		return (
			<React.Fragment>	
				<div className={this.state.successclass}>{this.state.msg}</div>
				<div className="row ">
					<div className="col-md-12">
						<span className="rightsidetext"><span className="addfollower" style={{'cursor':'pointer'}} onClick={() => this.followArtist(artists.id,this.state.followstatus)} >{(this.state.followstatus)?<i className="fa fa-user-plus" aria-hidden="true" title="Unfollow Artist"></i>:<i className="fa fa-user-plus" aria-hidden="true" title="Follow Artist"></i>}</span>
						<Link to='#' className="btn btn-primary" onClick={this.props.history.goBack}><i className="fa fa-angle-double-left"></i>&nbsp;Back</Link></span>
					</div>
				</div>
				<div className="row artisitblock allartist">
					<div className="col-md-3">
						<div className="featureartprifles">
						   <div className="featrueimages">
							  <img onError={this.addDefaultSrc} src={artists.profile_pic_path} />
						   </div>
						   <div className="featurestext">
							  <h3>{artistname}</h3>
						   </div>
						</div>
					</div> 
					<div className="col-md-9">
						<div className="artist-profileinfo">
							<div className="bestartistname">         
								<h3>Best of <span className="artnames">{artistname}</span></h3>
								<span>Created by Conqer | Tracks {this.state.totalsong}</span>
								<p>{artistabout}</p>
							</div>
						</div>
					</div>              
				</div>  
				<div className="row">
					<div className="col-md-12">
						<div className="box box-info">
							<div className="box-header with-border">
								<h3 className="box-title">Top Picks of {artistname}</h3>
							</div>
							<div className="box-body">
								<div className="table-responsive">
									<table className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Cover Photo</th>
												<th>Album Title</th>
												<th className="text-center">Songs</th>                                   
											</tr>
										</thead>
										<tbody>
											{((this.state.allalbumlist).length)?this.state.allalbumlist.map((results,index) => {
												let indexvalue 	= parseInt(index)+1;
												let albumurl;
												if(results.songs > 0){
													albumurl 	= '/albumsongs/'+results.id;
												}else{
													albumurl 	= '#';
												}
												return(<tr>
													<td className="artist-wlecome editeventimage"><img onError={this.addDefaultSrc} src={results.banner} onClick={() => this.onclickseturl(results.banner)} /></td>
													<td><Link to={albumurl}>{results.name}</Link></td>
													<td className="text-center">{results.songs}</td>
												</tr>)
												}
											):<tr><td colspan="3" style={{'textAlign':'center'}}>No album available!</td></tr>}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="myModal" className="modal fade imgmodal" role="dialog">
					<div className="modal-dialog">
						<div className="modal-content">
							<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
							<div className="modal-body">
								<img className="showimage img-responsive" onError={this.addDefaultSrc} src={this.state.urlvalinitial} />
							</div>            
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}