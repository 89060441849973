import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import $ from 'jquery'; 
import Spinner from '../../../includes/Spinner.component';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
export default class userPayment extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	eventData:[]
      }
    }
    getEventListData(){
    	const obj = {}
    	const axiosConfig = {
      		'Content-Type': 'multipart/form-data',
      		"Access-Control-Allow-Origin": "*",
    	};
	    Axios.post(API_URL + 'userrevenue.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){
		        	$('#myevents').DataTable().destroy();
		        	$(document).ready(function () {
				        setTimeout(function(){
				        $('#myevents').DataTable({
				        	"destroy": true,
							"stateSave": true,
							"processing": false,
							"pageLength": 10,
	  						"lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100,  "All"]],
							"language": {
								"search": "Search"
							},
							"dom": 'Bfrtip',
							"buttons": [
								{
									"extend": 'csv',
									"exportOptions": {
										"columns": [ 0,1,2,3 ]
									}
								},
								{
									"extend": 'print',
									"exportOptions": {
										"columns": [ 0,1,2,3 ]
									}
								}
							],
							"columnDefs": [
								{ "type": "num-fmt", "targets": 3, "className": "text-center", "appliesTo": "print" },
							],
				        });
				         } ,50);
				    });
		        	this.setState({
		        		eventData: res.data.res_data,
						loader: false
					});				         
		        } 
		                
	    	}
	    )
    }
    componentDidMount(){
		document.title = "Artist Revenue";
    	this.getEventListData();

    }
    
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title">Artist's Revenue List</h3>             
								</div>
								<div className="box-body no-padding">
								  <div className="table-responsive">
								<table id="myevents" className="table no-margin table-striped table-hover">
									<thead>
									  <tr>
										<th>Artist</th>                    
										<th>Email</th>                    
										<th>Phone</th>                    
										<th className="text-center">Amount($)</th>
										<th data-orderable="false" style={{textAlign:'center'}}>Action</th>
									  </tr>
									</thead>
									<tbody>
									{this.state.eventData.map((result, i) => {
										return (
											<tr>
												<td>{result.artist_name}</td>
												<td>{result.email}</td>
												<td>
												{result.phone
												? `(${result.phone.substring(0, 3)}) ${result.phone.substring(3, 6)}-${result.phone.substring(
													6,
													result.phone.length
												  )}`
												: ''}
												</td>
												<td className="text-center">{result.totalrev}</td>
												<td className="actionbtns" style={{textAlign:'center'}}>
												  <Link to={'/admin/viewrevenue/' + result.user_id} className="btn  btn-info custombtn" title="View Balance Sheet">
													<i className="fa fa-eye" aria-hidden="true"></i>
												  </Link>
												  
												</td>
											  </tr>
										)
									})}
									  
									</tbody>
								</table>
							  </div>              
							</div>
							</div>
						</div>        
					</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}