export const playAudio = (isPlaying, audioRef) => {
  console.log('play audio', isPlaying);
  if (isPlaying) {
	audioRef.current.play();
    const playPromise = audioRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then((audio) => {
          audioRef.current.play();
        })
        .catch((error) => console.log(error));
    }
  } else {
    audioRef.current.pause();
  }
};
