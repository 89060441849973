import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';
const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
export default class ListenerAlbum extends Component {
	constructor(props){
		super(props);
		this.state = {
		  myAlbumList:[],
		  myAlbum:[],
		  loading:true,
		  type:'all'
		}
	}
	componentDidMount(){
		document.title = "CONQER Music - My Album";
		this.getListenerAlbum();
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	handleKeyPress = (event) => {
	  	if(event.key === 'Enter'){
	    	this.searchSongs()
	  	}
	}
	getListenerAlbum = () => {
		this.setState({'type':'all'});
		const obj = {
			user_id : ls.get('userid')
		}
		Axios.post(base_api_url+'listener-album.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data
					this.setState({
						myAlbumList:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	songName(e){
		if(e.target.value){
			this.setState({song_name:e.target.value});
		}else{
			this.setState({song_name:''});
			this.getListenerAlbum();
		}
		
	}
	searchSongs = (e) => {
		this.setState({'type':'search'});
		let _data = [];
		let n = this.state.song_name;
		if(n){
			this.state.myAlbumList.map((results) => {
				if((results.album_name).match(new RegExp(n,'ig'))){
					_data.push(results);				
				}
			});
			this.setState({
				myAlbum:_data,
				loading:false
			});
		}else{
			this.setState({'type':'all'});
		}
	}
	render() {
		let album 	= (this.state.type == 'all')?this.state.myAlbumList:this.state.myAlbum;
		if(this.state.loading==true){
			return <Spinner />;
		}
		let almaxlimit 	= '15';
		return (
			<React.Fragment>
				<div className="row topfivesongsd">
					<div className="col-md-12">
						<div className="todpfive">My Album
							<span className="rightsidetext">
								<span class="navbar-search col"style={{'float':'left'}}>
						            <label class="sr-only screen-reader-text visually-hidden" for="search">Search for:</label>
						            <div class="input-group">
						            	<div class="input-search-field">
						            		<input type="text" id="search" class="form-control search-field product-search-field" dir="ltr" onChange={this.songName.bind(this)} placeholder="Search Album" onKeyPress={this.handleKeyPress} autocomplete="off" />
						            	</div>
						            	<div class="input-group-btn">
								            <button type="submit" onClick={() => this.searchSongs()} class="btn btn-secondary"><i class="fa fa-search"></i></button>
						            	</div>
						            </div>
						        </span>
								<Link to="/listener-dashboard/" className="btn btn-primary mb-3">
								<i className="fa fa-angle-double-left" aria-hidden="true"></i>Back
								</Link>
							</span>
						</div>
					</div>
				</div>
				<div className="row artisitblock allartist">
					{(album.length)?album.map((result) => {
						return (
						<div className="col-md-3">
							<Link to={'/albumsongs/'+result.album_id}>
								<div className="featureartprifles">
									<div className="featrueimages">
										<img onError={this.addDefaultSrc} src={result.album_banner} />
									</div>
									<div className="featurestext">
										<h3 title={result.album_name}>{ (result.album_name)?((result.album_name).length > almaxlimit) ? (((result.album_name).substring(0,almaxlimit-3)) + '...') :result.album_name:''}</h3>
										<span className="nooffollowers">{result.songcount} Songs</span>
									</div>
								</div>
							</Link>
						</div>
						)
					}):<div className="not-found"><span>No album found!</span></div>
					}
				</div>
			</React.Fragment>
		);
	}
}