import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class Artist extends Component {
	componentDidMount(){
		document.title = "CONQER Music - Artist";		
	}
	
    render() {		
		return (
		<React.Fragment>
			<div id="slider-home" className="artisitsliders">
				<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
				  <div className="carousel-inner">
					<div className="carousel-item active">
					  <img src={process.env.PUBLIC_URL + 'assets/slider/slide2.jpg'} alt="..." />
					  <div className="title">
						<h1 className="animated slideInDown artist-sl">PROMOTE YOUR<span> MUSIC</span></h1>
						<h2 className="animated slideInLeft"><b>AND GIVE GOD</b> ALL THE GLORY</h2>					
						<h2 className="animated slideInLeft margitosp">"Church choirs, individual artists and groups"</h2>
					  </div>
					  <div className="slide-m-img">
						  <div className="artist-m-img"><img src={process.env.PUBLIC_URL + 'assets/slider/mp2.png'} /></div>
					  </div> 
					</div>
					<div className="carousel-item">
					  <img src={process.env.PUBLIC_URL + 'assets/slider/slide2.jpg'} alt="..." />
					  <div className="title">
						<h1 className="animated slideInDown artist-sl">PROMOTE YOUR<span> MUSIC</span></h1>
						<h2 className="animated slideInLeft"><b>AND GIVE GOD</b> ALL THE GLORY</h2>
						<br />
						<h2 className="animated slideInLeft">"Church choirs, individual artist and groups"</h2>
					  </div>
					   <div className="slide-m-img">
						  <div className="artist-m-img"><img src={process.env.PUBLIC_URL + 'assets/slider/mp2.png'} /></div>
					  </div>
					</div>
				  </div>
				</div>
			</div>
			<section className="artist-section">
			  <div className="container">
				<div className="promote_artist">
				  <div className="row">
					<div className="col-md-4">
					  <img src={process.env.PUBLIC_URL + 'assets/artist/img3.jpg'} />
					  <h3>PROMOTE YOUR MUSIC</h3>
					  <p>CONQER helps you get your music in the hands of millions of Christians who are hungry for amazing music that speaks to their spirit.</p>
					</div>
					<div className="col-md-4">
					  <img src={process.env.PUBLIC_URL + 'assets/artist/img4.jpg'} />
					  <h3>GENERATE REVENUE</h3>
					  <p>At CONQER we believe that the worker is worthy of his wages, thats why we are always working hard to help you generate revenue through your music.</p>
					</div>
					<div className="col-md-4">
					  <img src={process.env.PUBLIC_URL + 'assets/artist/img5.jpg'} />
					  <h3>PROMOTE THE GOSPEL</h3>
					  <p>Be a part of encouraging people and feeding their soul by glorifying our Lord and Savior Jesus Christ through your music.</p>
					</div>
				  </div>
				  <div className="row">
				    <div className="col-md-12">
				    <div className="other-benefits">Other Benefits</div>
				        <div className="supertext">
					        <ul className="text-info-reg"><li>5% discount on all partner/subsidiary services</li>
							<li>Special Promotions as announced</li>
							</ul>
						</div>
				    </div>
				</div>
				</div>
				
			  </div>
			</section>

			<section>
				<div className="why-conqer artist-why">
					<div className="container why-content">
						<h3>WE PROMOTE AND ENCOURAGE OUR ARTISTS</h3>
						<span>Try us today & Experience the difference</span>
						<p>Every month, seven artists are selected to be highlighted on CONQER’s website and app. <br />They also enter into a monthly drawing for a free gift and a chance to perform at our annual festival.</p>
					</div>
					<div className="empty-space"></div>
					<div>
						<Link to={'/register/artist'} className="why-btn" role="button">CREATE A PROFILE TODAY</Link>
					</div>
				</div>
			</section>
		</React.Fragment>
		)
	}
}