import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import DOMPurify from "dompurify";
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import $ from 'jquery';
import Spinner from '../includes/Spinner.component'
const errors = {};
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};

export default class EditTickets extends Component {

	constructor(props) {
		super(props);
		this.state = {
	      ticketEndDate: new Date()
	    };
	    this.handleTicketChange = this.handleTicketChange.bind(this);
		this.onChangeTicketTitle = this.onChangeTicketTitle.bind(this);
		this.onChangeTicketDescription = this.onChangeTicketDescription.bind(this);
		this.onChangeTicketPrice = this.onChangeTicketPrice.bind(this);
		this.onChangeTicketStatus = this.onChangeTicketStatus.bind(this);
		this.onChangeTicketAccess = this.onChangeTicketAccess.bind(this);
		this.onChangeTicketSeats = this.onChangeTicketSeats.bind(this);
		this.onChangeTicketEndDate = this.onChangeTicketEndDate.bind(this);

		this.onTicketSubmit = this.onTicketSubmit.bind(this);

		this.titleInput = React.createRef();
		this.priceInput = React.createRef();
		this.dateInput = React.createRef();

		this.state = {
			ticketlist: [],
			tickettitle: null,
			ticketdescription: null,
			eventstart:'',
			eventend:'',
			bttext: 'Save',
			btndisable:'',
			ticketprice: '',
			ticketenddate: null,
			ticketstatus: '1',
			ticketaccess: 'public',
			eventseat: '',
			ticket_id: null,
			error: null,
			errors: [],
			msg: null,
			successclass: null,
			redirect: false,
			commission: '$0.00'
		}
	}
	handleTicketChange(date) {
		if(this.state.errors['dateerror']){delete this.state.errors['dateerror'];}
	    this.setState({
	      ticketEndDate: date
	    })
	}
	handleValidation = () => {
		let formIsValid = true;
		
		if(!this.state.tickettitle){
			formIsValid = false;
			errors["titleerror"] = "Please enter ticket title.";
		}
		if(!this.state.ticketdescription){
			formIsValid = false;
			errors["descerror"] = "Please enter ticket description.";
		}
		if(!this.state.ticketprice){
			formIsValid = false;
			errors["priceerror"] = "Please enter ticket price.";
		}/*else if(this.state.ticketprice < 1){
			formIsValid = false;
			errors["priceerror"] = "Please enter minimum 1$.";
		}*/
		
		if(!this.state.ticketEndDate){
			formIsValid = false;
			errors["dateerror"] = "Please enter end date.";
		}
		if(!this.state.eventseat){
			formIsValid = false;
			errors["seaterror"] = "Please enter number of seat.";
		}else if(this.state.eventseat < 1){
			formIsValid = false;
			errors["seaterror"] = "Please enter minimum 1 seat.";
		}

		this.setState({errors: errors});		
        return formIsValid;
	
	}

	componentDidMount() {
		document.title = "CONQER Music - Manage Tickets";
		this.geteventticketdata();
		this.geteventdata();
	}

	geteventdata = () => {
		const eventid = this.props.match.params.id;

		const obj = {
			id: eventid
		}

		Axios.post(base_api_url + 'getevent.php', obj, { axiosConfig })
			.then(
				(res) => {
					
					if (res.data.res_status == 200) {
						this.setState({
							eventstart: res.data.res_data.event_start_date,
							eventend: res.data.res_data.event_end_date,
							commission: res.data.com_data
						})
					} else {
						this.setState({
							eventstart: '',
							eventend: ''
						});
					}
				}
			);

	}

	geteventticketdata = () => {
		const eventid = this.props.match.params.id;

		const obj = {
			event_id: eventid
		}
		this.setState({
			loader:true
		});
		Axios.post(base_api_url + 'eventticketlist.php', obj, { axiosConfig })
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						$('#myeventTickets').DataTable().destroy();
			        	$(document).ready(function () {
					        setTimeout(function(){
					        $('#myeventTickets').DataTable({
					        	"destroy": true,		
		  						"lengthMenu": [[5, 10, 15, -1], [5, 10, 15,  "All"]],
								language: {
									emptyTable: "No event ticket!"
								},
		  						columnDefs: [
		  							{ "width": "20%", "targets": 0 },
								    { "width": "15%", "targets": 5 },
					   				{ orderable: false, targets: -1 }
								]
					        });
					         } ,50);
					    });
						this.setState({
							ticketlist: res.data.res_data
						})
						
					} else {
						this.setState({
							redirect: false,
							albumdata: false,
							msg: null,
							successclass: null
						});
					}
					this.setState({
						loader:false
					});
				}
			);
	}

	onChangeTicketTitle(e) {
		if(this.state.errors['titleerror']){delete this.state.errors['titleerror'];}
		this.setState({
			tickettitle: e.target.value
		})
	}

	onChangeTicketDescription(e) {
		if(this.state.errors['descerror']){delete this.state.errors['descerror'];}
		this.setState({
			ticketdescription: e.target.value
		})
	}

	onChangeTicketPrice(e) {
		if(this.state.errors['priceerror']){delete this.state.errors['priceerror'];}
		const re = /^[0-9.\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({
				ticketprice: e.target.value
			})
		}
	}

	onChangeTicketEndDate(e) {
		if(this.state.errors['dateerror']){delete this.state.errors['dateerror'];}
		this.setState({
			ticketenddate: e.target.value
		})
	}

	onChangeTicketStatus(e) {
		this.setState({
			ticketstatus: e.target.value
		})
	}

	onChangeTicketAccess(e) {
		this.setState({
			ticketaccess: e.target.value
		})
	}

	onChangeTicketSeats(e) {
		if(this.state.errors['seaterror']){delete this.state.errors['seaterror'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({
				eventseat: e.target.value
			})
		}
	}

	
	editTicket = (recordid) => {
		const obj = {
			id: recordid
		}
		this.setState({
			loader:true
		})
		Axios.post(base_api_url + 'getticketdata.php', obj, { axiosConfig })
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						this.setState({
							tickettitle: res.data.res_data.ticket_title,
							ticketdescription: res.data.res_data.description,
							ticketprice: res.data.res_data.price,
							ticketEndDate: new Date(res.data.res_data.end_date),
							ticketstatus: res.data.res_data.status,
							ticketaccess: res.data.res_data.access,
							eventseat: res.data.res_data.seats,
							ticket_id: res.data.res_data.id,
							bttext: 'Update',
							loader:false
						});

					} else {
						this.setState({
							successclass: 'errormsg',
							msg: 'Event not deleted, please try again!',
							loader:false
						})
					}
				}
			);

	}

	onTicketSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
                loader:true,
                bttext:'Please Wait...',
                disabled: true
            });
			const formData = new FormData();
			const ticketEndDate = moment(this.state.ticketEndDate);
			formData.append("event_id", this.props.match.params.id);
			formData.append("ticket_id", this.state.ticket_id);
			formData.append("ticket_title", this.state.tickettitle);
			formData.append("description", this.state.ticketdescription);
			formData.append("price", this.state.ticketprice);
			formData.append("end_date", ticketEndDate.format('yyyy-MM-DD'));
			formData.append("access", this.state.ticketaccess);
			formData.append("seats", this.state.eventseat);
			formData.append("status", this.state.ticketstatus);
			(this.state.bttext == 'Save') ?
			
				Axios.post(base_api_url + 'add-event-ticket.php', formData, { axiosConfig })
					.then(
						(res) => {
							if (res.data.res_status == 200) {
								this.geteventticketdata();
								this.setState({
									successclass: 'successmsg',
									msg: 'Event ticket created successfully!',
									tickettitle: '',
									ticketdescription: '',
									bttext: 'Save',
									btndisable:'',
									ticketprice: '',
									ticketEndDate: '',
									ticketstatus: '1',
									ticketaccess: 'public',
									eventseat: '',
									ticket_id: '',
									loader:false,
									disabled: false
								});								
							} else {
								this.setState({
									successclass: 'errormsg',
									bttext:'Save',
									btndisable:'',
									msg: 'Event ticket not created , please try again!',
									loader:false,
									disabled: false						
								})
							}
							setTimeout(() => {
								this.setState({
									successclass: null,
									msg: null,
								});
							}, 5000)
						}
					)
				: Axios.post(base_api_url + 'update-event-ticket.php', formData, { axiosConfig })
					.then(
						(res) => {
							if (res.data.res_status == 200) {								
								this.geteventticketdata();
								this.setState({
									successclass: 'successmsg',
									msg: 'Ticket updated successfully!',
									tickettitle: '',
									ticketdescription: '',
									bttext: 'Save',
									btndisable:'',
									ticketprice: '',
									ticketEndDate: '',
									ticketstatus: '1',
									ticketaccess: 'public',
									eventseat: '',
									ticket_id: '',
									loader:false,
									disabled: false
								});
							} else {
								this.setState({
									successclass: 'errormsg',
									msg: 'Ticket not updated , please try again!',
									bttext:'Save',
									btndisable:''
								})
							}
							setTimeout(() => {
								this.setState({
									successclass: null,
									btndisable:'',
									msg: null
								});
							}, 5000)
						}
					)
		}
	}
	updateticketstatus = (evticketid, status) => {
		const obj = {
			id:evticketid,
			status:status
		}
		this.setState({
			loader:true
		})
		Axios.post(base_api_url+'updateevticketstatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({

						successclass:'successmsg',
						msg:'Event ticket status '+((status=='1')?'Unpublished':'Published')+' successfully!',
						loader:false
					});
					this.geteventticketdata();
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:'Event ticket status not updated, please try again!',
						loader:false
					})
				}
				setTimeout(() => {
					this.setState({
					successclass:null,
					msg:null
					});
				}, 5000)
			}
		);
	}
	
	deleteTicket = (id) => {
		const confirmBox = window.confirm(
			"Do you really want to delete this ticket?"
		);

		if (confirmBox === true) {
			const obj = {
				id: id
			}
			this.setState({
				loader:true
			})
			Axios.post(base_api_url + 'deleteticket.php', obj, { axiosConfig })
				.then(
					(res) => {
						if (res.data.res_status == 200) {
							this.setState({
								successclass: 'successmsg',
								msg: 'Ticket deleted successfully!',
								loader:false
							});
							this.geteventticketdata();
						} else {
							this.setState({
								successclass: 'errormsg',
								msg: 'Ticket not deleted, please try again!',
								loader:false
							})
						}
						setTimeout(() => {
							this.setState({
								successclass: null,
								msg: null
							});
						}, 5000)
					}
				);

		}
	}


	render() {
		if(this.state.loader==true){
			return <Spinner />;
		}
		return (
			<React.Fragment>
				<div className={this.state.successclass}>{this.state.msg}</div>
				<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">Manage Tickets</h3>
								<div className="box-tools">
                					<NavLink to={'/edit-event/'+this.props.match.params.id} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>
                				</div>
							</div>
							<div className="box-body no-padding havetabs">
								<div className="">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onTicketSubmit}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>Ticket Information</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>Ticket Title<span className="requiredastrik">*</span></label>
															<input type="text" ref={this.titleInput} placeholder="Ticket Title" className="form-control" id="tickettitle" name="tickettitle" onChange={this.onChangeTicketTitle} value={this.state.tickettitle} />
															<font className="errorMsg">{this.state.errors["titleerror"]}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label>Description<span className="requiredastrik">*</span></label>
															<input type="text" className="form-control"placeholder="Description" id="ticket_desc" name="ticket_desc" value={this.state.ticketdescription} onChange={this.onChangeTicketDescription} />
															<font className="errorMsg">{this.state.errors["descerror"]}</font>
														</div>
													</div>
												</div>
												<div className="row">

													<div className="col-md-6">
														<div className="form-group">
															<label>Price[$]<span className="requiredastrik">*</span></label>
															<input type="text" placeholder="Price" className="form-control" name="ticketprice" value={this.state.ticketprice} onChange={this.onChangeTicketPrice} />
															<font className="errorMsg">{this.state.errors["priceerror"]}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label>Ticket End Date<span className="requiredastrik">*</span></label>
															<DatePicker
												              selected={ this.state.ticketEndDate }
												              onChange={ this.handleTicketChange }
												              name="ticketEndDate"
												              dateFormat="MM/dd/yyyy"
												              dropdownMode="select"
															  showMonthDropdown
															  showYearDropdown
															  adjustDateOnChange
															  className="form-control"
															  placeholderText="Ticket End Date"
															  minDate={new Date(this.state.eventstart)}
															  maxDate={new Date(this.state.eventend)}
												          />
															<font className="errorMsg">{this.state.errors["dateerror"]}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>Status</label>
															<select className="custom-select" name="ticketstatus" id="exampleSelectRounded0" onChange={this.onChangeTicketStatus} value={this.state.ticketstatus}>
																<option value="1" selected={(this.state.ticketstatus=='1')?'selected':''}>Publish</option>
																<option value="0" selected={(this.state.ticketstatus=='0')?'selected':''}>Unpublish</option>
															</select>
														</div>
												
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label>Number of Seats<span className="requiredastrik">*</span></label>
															<input type="text" placeholder="Number of Seats" className="form-control" onChange={this.onChangeTicketSeats} value={this.state.eventseat} maxlength="9"/>
															<font className="errorMsg">{this.state.errors["seaterror"]}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<small><strong>Note</strong>* that, {this.state.commission} fees are applicable on all ticket sales for paid event. This fee is applicable on amount after getting discount. </small>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 notmainbtn">
														<button type="submit" disabled={this.state.disabled} className="btn btn-primary">{this.state.bttext} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div className="box-body no-padding">
								<div className="">
									<table id="myeventTickets" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Title</th>
												<th className="text-center">Price</th>
												<th>End Date</th>
												<th className="text-center">Seats</th>
												<th className="text-center">Available Seats</th>
												<th>Status</th>
												<th className="actionbtnsets text-center">Actions</th>
											</tr>
										</thead>
											<tbody>
												{this.state.ticketlist.map((result) => {
													return (
														<tr>
															<td>{result.ticket_title}</td>
															<td className="text-center">{(result.price)?'$'+result.price:''}</td>
															<td>{result.end_date}</td>
															<td className="text-center">{result.seats}</td>
															<td className="text-center">{result.available_seats}</td>
															<td>{(result.status=='1') ? <span className="label label-success">Publish</span> : <span className="label label-danger">Unpublished</span>}</td>
															<td className="actionbtns"><a href="javascript:;" title="Edit Ticket" onClick={() => this.editTicket(result.id)} className="btn btn-info custombtn"><i class="fa fa-pencil" aria-hidden="true"></i></a>&nbsp;<a href="javascript:;" title={(result.status=='1')?'Unpublished':'Publish'} onClick={() => this.updateticketstatus(result.id,result.status)} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</a>&nbsp;
															<a href="javascript:;" title="Delete Ticket" onClick={() => this.deleteTicket(result.id)} className="btn btn-info custombtn"><i class="fa fa-trash" aria-hidden="true"></i></a></td>
														</tr>
													)
												})}
											</tbody>
									</table>
								</div>
							</div>

							<div className="box-footer clearfix"></div>
						</div>
					</div>
				</div>

			</React.Fragment>
		)
	}

}