import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import ls from 'local-storage';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import defaultimg from '../components/default.png';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
toast.configure();
export default class MyEvents extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {		  
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  albumlist:[],		  
		  loading:true,
		  loader:null,		  
		  redirect:false
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Events List";
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({	
			token:token,		
			userid:userid
		});
		
		this.getalleventlist();
	}	
	getalleventlist = () => {
		const obj = {
			userid:ls.get('userid')
		}		
		Axios.post(base_api_url+'eventlist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data;					
					this.setState({
						albumlist:returndata,
						loading:false
					});
				}else{
					this.setState({
						albumlist:[],
						loading:false
					});
				}
				$('#myevents').DataTable().destroy();
			    $(document).ready(function () {
			        setTimeout(function(){
				        $('#myevents').DataTable({
				        	"destroy": true,
							"stateSave": true,
							"language": {
								"search": "Search",
								"sEmptyTable": "No Event Found"
							},
							"lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100,  "All"]],
							columnDefs: [
								{ "width": "20%", "targets": 0 },
								{ "width": "15%", "targets": 1 },
								{ "width": "15%", "targets": 6 },
								{ orderable: false, targets: -1 }
							]
				        });
			        } ,50);
			    });
				
			}
		);
	}
	updateeventstatus = (eventid, status) => {
		const obj = {
			id:eventid,
			status:status
		}
		this.setState({
			loading:true
		});
		Axios.post(base_api_url+'updateeventstatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						successclass:'successmsg',
						msg:'Event status updated successfully!',
						loading:false
					});
					this.getalleventlist();
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:'Event status not updated, please try again!',
						loading:false
					})
				}
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 5000)
			}
		);
	}
	
	deleteEvent = (id) => {
		const confirmBox = window.confirm(
			"Are you sure you want to delete?"
		);
		
		if (confirmBox === true) {
			const obj = {
				id:id
			}
			this.setState({
				loading:true
			});
			Axios.post(base_api_url+'deleteevent.php',obj,{axiosConfig})
			.then(
				(res) => {console.log(res.data);
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:'Event deleted successfully!',
							loading:false
						});
						this.getalleventlist();
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:'Event not deleted, please try again!',
							loading:false
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });					  
					}, 5000)
				}
			);
			
		}
	}
	alertUnpublish(type){
		if(type == 1){
			toast.error('Unpublish to edit this event.');
		}else if(type == 2){
			toast.error('Unpublish to delete this event.');
		}
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	render() {
		if(this.state.loading==true){
			return <Spinner />;
		}
		return (
			<React.Fragment>
				<div className={this.state.successclass}>{this.state.msg}</div>
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
									<h3 className="box-title">Events List</h3>  
									<div className="box-tools saearchicons">
										<NavLink to={'/add-event/'+this.state.token} className="btn btn-primary"><i className="fa fa-plus" aria-hidden="true"></i> Add New</NavLink>
									</div>            
								</div>
								<div className="box-body no-padding">
									<div className="table-responsive">
										<table id="myevents" className="table no-margin table-striped table-hover">
											<thead>
												<tr>
													<th>Event Name</th>                    
													<th>Event Image</th>                    
													<th>Date Start</th>
													<th>Date End</th>
													<th>Location</th>
													<th>Status</th>
													<th className="actionbtns" style={{textAlign:'center'}}>Actions</th>
												</tr>
											</thead>
											<tbody>
												{this.state.albumlist.map((result) => {
												let eventlocation = result.location;
												return (
												<tr>                  
												<td><NavLink to={'/view-event/'+result.id}>{result.event_name}</NavLink> </td>
												<td><img onError={this.addDefaultSrc} src={result.thumb_photo} style={{'width':'100px','height':'100px'}} className="img-responsive img-thumbnail" /></td>
												<td>{result.event_start_date} <br/> {result.start_time}</td>
												<td>{result.event_end_date} <br/> {result.end_time}</td>
												<td>{(result.address)?result.address+' '+result.city:""}<br />{(result.state)?result.state+', '+result.country+' '+result.zipcode:""}</td>
												<td>{(result.status=='1') ? <span className="label label-success">Publish</span> : <span className="label label-danger">Unpublish</span>}</td>
												<td className="actionbtns" style={{textAlign:'center'}}>
												{(result.status === '1')?<Link onClick={() => this.alertUnpublish('1')} className="btn btn-info custombtn" title="Unpublish to edit this event"><i className="fa fa-pencil" aria-hidden="true"></i></Link>:<Link to={'/edit-event/'+result.id} className="btn btn-info custombtn" title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></Link>}&nbsp;
												<Link href="javascript:;" title={(result.status=='1')?'Unpublish':'Publish'} onClick={() => this.updateeventstatus(result.id,result.status)} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
												{(result.status === '1')?<Link onClick={() => this.alertUnpublish('2')} className="btn btn-info custombtn" title="Unpublish to delete this event"><i className="fa fa-trash" aria-hidden="true"></i></Link>:<Link href="javascript:;" onClick={() => this.deleteEvent(result.id)} className="btn btn-info custombtn" title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></Link>}
												</td>
												</tr>	
												)
												})}
											</tbody>
										</table>
									</div>              
								</div>
							</div>
						</div>        
					</div>      	
				</React.Fragment>
			)
		}
	}