import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import { Link, NavLink } from 'react-router-dom';
import DOMPurify from "dompurify";
//Bootstrap and jQuery libraries
//Datatable Modules
import defaultimg from '../components/default.png';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import Spinner from '../includes/Spinner.component'
import { ProgressBar } from "react-bootstrap"
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
const errors = {};
let allfiles = [];
export default class ArtistEditGallery extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:false,
        bttext:'Submit',
        selectedgallery: 'Choose Files',
        selectedgalleries: [],
        eventvideolink:'',
        disableBtn: false,
        errors:[],
        gallerylist:[],
      }
    }    
    componentDidMount(){
		document.title = "CONQER Music - Manage Gallery";
        this.eventGalleryData();
    }
    onChangeGalleryImage(e){
        if(this.state.errors['image_error']){delete this.state.errors['image_error'];}
		allfiles = e.target.files;
		let filesarr = [];
		for (const image of allfiles) {
			filesarr.push(image.name)
		}
        this.setState({
            selectedgalleries: filesarr, 
            galleryimage: e.target.files,
        })
    }
    onChangeVideoLink(e) {
        if(this.state.errors['video_error']){delete this.state.errors['video_error'];}
        this.setState({
            eventvideolink: e.target.value
        })
    }
    submitGallery(e){
        e.preventDefault();
        if(this.handleValidation()){
            this.setState({
                loader:false,
                bttext:'Please Wait...',
                disableBtn: true
            });
            const formData = new FormData();
			for (const imagefiles of this.state.galleryimage) {
				formData.append("gallery[]", imagefiles, imagefiles.name);
			}
            // formData.append("gallery[]", this.state.galleryimage, this.state.galleryimage.name);
            formData.append("event_id", this.props.match.params.id);
            formData.append("videolink", this.state.eventvideolink);
            Axios.post(API_URL + 'add-event-gallery.php', formData, { axiosConfig,
                onUploadProgress: data => {
                    this.setState({progress: Math.round((100 * data.loaded) / data.total)})
                }
            }).then(
                (res) => {
                	
                    if(res.data.res_status == '200') {
                        this.setState({
                            successclass: 'successmsg',
                            msg: 'Image and video link added successfully.',
                            selectedgallery:'Choose File',
                            loader:false,
                            bttext:'Submit',
                            disableBtn: false,
                            eventvideolink:'',
							selectedgalleries:[]
                        });
                        this.eventGalleryData();
                    } else {
                        this.setState({
                            successclass: 'errormsg',
                            msg: 'Image and video link not added, Please try again!',
                            loader:false,
                            bttext:'Submit',
                            disableBtn: false,
                        })
                    }
                    setTimeout(() => {
                        this.setState({
                            successclass: null,
                            msg: null,
							progress:'',
                        });
                    }, 5000)
                }
            )
        }
    }
    handleValidation = () => {
        let formIsValid = true;     
        if(!this.state.galleryimage){
            formIsValid = false;
            errors["image_error"] = "Please select image.";
        }else{
            let validExt 	= ".png, .gif, .jpeg, .jpg";
            let filePath 	= this.state.galleryimage;
			for (const imagefile of filePath) {
				let imagefileName = imagefile.name;
				let getFileExt 	= imagefileName.substring(imagefileName.lastIndexOf('.') + 1).toLowerCase();
				let pos 		= validExt.indexOf(getFileExt);
				if(pos < 0){
					formIsValid = false;
					errors["image_error"] = "Please upload JPG/JPEG/PNG or GIF image only.";
				}
			}
        }
        if(this.state.eventvideolink){
            var res = (this.state.eventvideolink).match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if(!res){
                formIsValid = false;
                errors["video_error"] = "Please enter valid video link.";
            }
        }
        this.setState({errors: errors});        
        return formIsValid;
    
    }
    eventGalleryData = () => {
        this.setState({
            loader:false
        });
        $('#eventGalleryList').DataTable().destroy();
        const eventid = this.props.match.params.id;

        const obj = {
            event_id: eventid
        }
        Axios.post(API_URL + 'eventgallerylist.php', obj, { axiosConfig })
            .then(
                (res) => {
                	$(document).ready(function () {
                        setTimeout(function(){
                        $('#eventGalleryList').DataTable({
                            "destroy": true,        
                                "lengthMenu": [[5, 10, 15, -1], [5, 10, 15,  "All"]],
								language: {
                                    search: "Search",
									emptyTable: "No event gallery!"
                                },
                                columnDefs: [
                                    { "width": "20%", "targets": 0 },
                                { "width": "15%", "targets": 3 },
                                { orderable: false, targets: -1 }
                            ]
                        });
                        } ,50);
                    });
                	if (res.data.res_status == 200) {
                        this.setState({
                            gallerylist: res.data.res_data,
                            loader:false
                        })
                    }else{
                        this.setState({
                            loader:false
                        })
                    }
                   	
                }
            );
            
    }
    addDefaultSrc = (ev) => {
        ev.target.src = defaultimg
    }
    onclickseturl = (urlval) => {
        this.setState({
            urlvalinitial: urlval
        });
        $('#myModals').modal('show');
    }
    updategallerystatus = (galleryid, status) => {
        const obj = {
            id:galleryid,
            status:status
        }    
        this.setState({
            loader:true
        })        
        Axios.post(API_URL+'updategallerystatus.php',obj,{axiosConfig})
        .then(
            (res) => {
                if(res.data.res_status==200){
                    this.setState({
                        successclass:'successmsg',
                        msg:'Image and Video Link Updated Successfully.',
                        loader:false
                    });
                    this.eventGalleryData();
                }else{
                    this.setState({
                        successclass:'errormsg',                        
                        msg:'Image and Video Link Not Updated, Please Try Again!',
                        loader:false
                    })
                }
                setTimeout(() => {
                    this.setState({
                    successclass:null,
                    msg:null
                    });
                }, 5000)
            }
        );
    }
    deleteImage = (id) => {
        if (window.confirm("Are you sure want to delete record?")) {
            this.setState({
                loader:true
            });
            const obj = {
                id: id
            }

            Axios.post(API_URL + 'deleteimage.php', obj, { axiosConfig })
            .then(
                (res) => {
                    if (res.data.res_status == 200) {
                                                
                        this.setState({
                            successclass: 'successmsg',
                            msg: 'Record is deleted successfully!',
                            loader:false
                        });
                        this.eventGalleryData();
                    } else {
                        this.setState({
                            successclass: 'errormsg',
                            msg: 'Record not deleted, please try again!',
                            loader:false
                        })
                    }
                    setTimeout(() => {
                        this.setState({
                            successclass: null,
                            msg: null
                        });                   
                    }, 5000)
                }
            );
        }
    }
    render() {
        if(this.state.loader==true){
            return <Spinner />;
        }
    	return(
    		<React.Fragment>
			<div className={this.state.successclass}>{this.state.msg}</div>
			<div className="row">
				<div className="col-md-12">
					<div className="box box-success distributions-profileform">
						<div className="box-header with-border addprofilenew">
							<h3 className="box-title">Manage Gallery</h3>
							<div className="box-tools">
				  				<Link to={'/edit-event/'+this.props.match.params.id} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</Link>
				  			</div>            
						</div>
						<div className="box-body no-padding"> 
							<div className="nav-tabs-custom">
                                <div className="">
                                    <div id="tab_1">
                                        <form name="imagvideoupload" onSubmit={this.submitGallery.bind(this)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="sectionhead">
                                                        <div className="headsec">
                                                            <h3>Gallery Upload</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Image Upload <span className="wrongpassmsg">(Supported type is JPG, JPEG, PNG, GIF.)</span> </label>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" id="customFile" name="galleryimage" onChange={this.onChangeGalleryImage.bind(this)}  accept="image/*" multiple />
															{(this.state.selectedgalleries.length > 0)?
                                                            <label className="custom-file-label" >{this.state.selectedgalleries.join()}</label>:<label className="custom-file-label" >{this.state.selectedgallery}</label>}
                                                            <font className="errorMsg">{this.state.errors["image_error"]}</font>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Video Link <span className="small">(ex. https://www.youtube.com/embed/88uwWAVyvPY)</span></label>
                                                        <input type="text" className="form-control" id="eventvideolink" name="eventvideolink" value={this.state.eventvideolink} placeholder="Video Link" onChange={this.onChangeVideoLink.bind(this)} />
                                                        <font className="errorMsg">{this.state.errors["video_error"]}</font>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                {this.state.progress && <ProgressBar now={this.state.progress} label={`${this.state.progress}%`} animated variant="success" />}
                                            </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-12 notmainbtn">
                                                    <button type="submit" disabled={this.state.disableBtn} className="btn btn-primary">{this.state.bttext} </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>         
						</div>
                        <hr />
                        <div className="box-body no-padding">
                            <div className="table-responsive">
                                <table id="eventGalleryList" className="table no-margin table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Video</th>
                                            <th>Status</th>
                                            <th className="actionbtns text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.gallerylist.map((result,index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><img onError={this.addDefaultSrc}  src={result.path} width="50" height="50" onClick={() => this.onclickseturl(result.path)} /></td>
                                                    <td><a href={result.video_link} target="_blank">{result.video_link}</a></td>
                                                    <td>{(result.status=='1') ? <span className="label label-success">Active</span> : <span className="label label-danger">Inactive</span>}</td>
                                                    <td className="actionbtns">
                                                        <a href="javascript:;" title={(result.status=='1')?'Inactive':'Active'} onClick={() => this.updategallerystatus(result.id,result.status)} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</a>&nbsp;<a href="javascript:;" onClick={() => this.deleteImage(result.id)} className="btn btn-info custombtn"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
					</div>
				</div>        
			</div>
            <div id="myModals" className="modal fade imgmodal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                            <div className="modal-body">
                                <img className="showimage img-responsive" onError={this.addDefaultSrc} src={this.state.urlvalinitial} />
                            </div>            
                        </div>
                    </div>
                </div>
    		</React.Fragment>
    	)
    }
}