import React, { Component } from 'react';
import Axios from 'axios';
import InputMask from 'react-input-mask';
import { API_URL, STRIPE_KEY } from '../../config/constants.js';
import { NavLink, Link, Redirect } from 'react-router-dom';
import ls from 'local-storage';
import defaultimg from '../../components/default.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Spinner from '../../includes/Spinner.component';
import AdminHeader from './header.component';
import AdminTopHeader from './header-top.component';
import CropAndPreview from '../../includes/CropAndPreviewModal.component';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
const stripePromise = loadStripe(STRIPE_KEY);
const errors = {};
const base_api_url = API_URL;
toast.configure();
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
toast.configure();
export class AddUser extends Component {
	constructor(props) {
    	super(props);
    	this.state = {
    		errors: [],
    		allCountries: [],
    		planlist: [],
    		btnDisable: false,
    		btnText: 'Submit',
    		type: 'paid',
    		fname: '',
			lname: '',
			dob: '',
			gender: '',
			mobile: '',
			email: '',
			password: '',
			address:'',
			city: '',
			state: '',
			country: '',
			zip: '',
			selectedFile: '',
			ssn:'',
			plan:'',
			user_type:'',
    		payment_option_hide: 'block',
    		ssn_hide: 'block',
    		fillStatus: false,
    		redirect: false
    	}
    }
    componentDidMount() {
		document.title = 'CONQER Music - Add User';
		this.getCountryList();
	}
	getplandetails = (utype) => {
		const obj = {
		  usertype: utype
		}
		Axios.post(base_api_url + 'getplandetails.php', obj)
		  .then(
		    (res) => {
		      if (res.data.res_status == 200) {
		        this.setState({
		          planlist: res.data.res_data
		        });
		      } else {
		        this.setState({
		          planlist: []
		        });
		      }
		    }
		  );
		}
	getCountryList = () => {
		Axios.get(base_api_url + 'country.php', axiosConfig).then((res) => {
          if (res.data.res_status === '200') {
            this.setState({
              allCountries: res.data.res_data
            });
          }
        });
	}
	onChangeUserType = (e) => {
		if (this.state.errors['utype_error']) {
			delete this.state.errors['utype_error'];
		}
		if(e.target.value == '1'){
			this.setState({
				ssn_hide: 'none'
			});
		}else{
			this.setState({
				ssn_hide: 'block'
			});
		}
		this.setState({
			user_type: e.target.value
	    }, () => this.getplandetails((e.target.value == '2')?'artist':'listener'));
	}
	onChangeType = (e) => {
		this.setState({
			type: e.target.value
		});
		if(e.target.value === 'paid'){
			this.setState({
				payment_option_hide: 'block' 
			});
		}else{
			this.setState({
				payment_option_hide: 'none' 
			});
		}
	}
	onChangeFirstName = (e) => {
		if (this.state.errors['firstname_error']) {
			delete this.state.errors['firstname_error'];
		}
		this.setState({
			fname: e.target.value 
		});
	}
	onChangeLastName = (e) => {
		this.setState({
			lname: e.target.value 
		});
	}
	onChangeDOB = (e) => {
		this.setState({
			dob: e
		});
	}
	onChangeGender = (e) => {
		this.setState({
			gender: e.target.value 
		});
	}
	onChangePhone = (e) => {
		this.setState({
			mobile: e.target.value 
		});
	}
	onChangeEmail = (e) => {
		if (this.state.errors['email_error']) {
			delete this.state.errors['email_error'];
		}
		this.setState({
			email: e.target.value 
		});
	}
	onChangePassword = (e) => {
		if (this.state.errors['password_error']) {
			delete this.state.errors['password_error'];
		}
		this.setState({
			password: e.target.value 
		});
	}
	onChangeAddress = (e) => {
		this.setState({
			address: e.target.value 
		});
	}
	onChangeCity = (e) => {
		this.setState({
			city: e.target.value 
		});
	}
	onChangeState = (e) => {
		this.setState({
			state: e.target.value 
		});
	}
	onChangeCountry = (e) => {
		this.setState({
			country: e.target.value 
		});
	}
	onChangeZip = (e) => {
		this.setState({
			zip: e.target.value 
		});
	}
	onFileChange(e) {
		if (this.state.errors['image_error']) {
			delete this.state.errors['image_error'];
		}
		if(e.target.files.length>0){
			this.setState({ selectedprofile: e.target.files[0].name, selectedFile: e.target.files[0]});
		}else{
			this.setState({ selectedprofile: null, selectedFile: null});
		}
	}
	onChangeSSN = (e) => {
		this.setState({
			ssn: e.target.value 
		});
	}
	onChangePlan = (e) => {
		this.setState({
			plan: e.target.value 
		});
	}
	stripeElementChange = (element, name) => {
		this.setState({
			fillStatus: true,
			cardError:'',
			formValidation: true
		});
		if(element.error){
			if(element.error.code){
				this.setState({
					formValidation: false,
					cardError: element.error.message
				});
			}
		}
	}
	validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  	}
	async onSubmitForm(e){
		e.preventDefault();
		if (this.handleValidation()) {
			this.setState({
				btnText:'Please Wait..',
				btnDisable:true
			});
			if(this.state.type === 'paid'){
				await this.onToken();
			}else{
				this.onSubmit();
			}
			      
		}
	}
	handleValidation = () => {
		let formIsValid = true;
		let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		if (!this.state.user_type) {
			formIsValid = false;
			errors['utype_error'] = 'Please select user type.';
		}
		if(!this.state.fname){
			formIsValid = false;
			errors['firstname_error'] = 'Please enter first name.';
		}
		if(!this.state.email){
			formIsValid = false;
			errors['email_error'] = 'Please enter email id.';
		}else if(!this.validateEmail(this.state.email)){
			formIsValid = false;      
			errors["email_error"] = "Please enter valid email.";
		}
		if(!this.state.password){
			formIsValid = false;
			errors['password_error'] = 'Please enter password.';
		}else if(!strongRegex.test(this.state.password)){
			formIsValid = false;
			errors["password_error"] = "Password should have minimum 8 character, 1 symbol, 1 uppercase, 1 lowercase.";
		}
		if(this.state.type === 'paid'){
			if(!this.state.plan){
				formIsValid = false;
				errors['plan_error'] = 'Please select subscription plan.';
			}
			if(!this.state.fillStatus){
				this.setState({
					cardError:'Please enter card detail.'
				});
				formIsValid = false;
			}
			if(!this.state.formValidation){
				formIsValid = false;
			}
		}
		
		this.setState({ errors: errors });
    	return formIsValid;
	}
	async onToken() {
		const { elements, stripe } = this.props;
		const cardElement = elements.getElement(CardElement);
		const result = await stripe.createToken(cardElement);
		if(result.error){
		  	this.setState({
		    	btnText:'Submit',
		    	btnDisable:false
		  	});
		  	toast.error(result.error);
		}else{
			this.onSubmitWithToken(result.token,this.state.email,this.state.plan);		
		}
	}
	onSubmitWithToken(token,email,planid){
		const paymentData = { token: token.id, email: email, membership: planid};
		Axios.post(base_api_url + 'stripepay.php', paymentData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.onSubmit(res.data.res_data);
				}else{
					toast.error(res.data.res_message);
				}
			}
		);
	}
	onSubmit = (sub_id = 0) => {
		const formData = new FormData();
		formData.append("subscription_id", sub_id);
		formData.append('type', this.state.type);
		formData.append('first_name', this.state.fname);
		formData.append('last_name', this.state.lname);
		if(this.state.dob){
			const dob = moment(this.state.dob);
			formData.append('dob', dob.format('YYYY-MM-DD'));
		}else{
			formData.append('dob', "");
		}		
		formData.append('gender', this.state.gender);			
		formData.append('cellphone', this.state.mobile);			
		formData.append('email', this.state.email);			
		formData.append('artpassword', this.state.password);			
		formData.append('address', this.state.address);			
		formData.append('city', this.state.city);			
		formData.append('states', this.state.state);			
		formData.append('country', this.state.country);			
		formData.append('zipcode', this.state.zip);
		if (this.state.selectedFile) {
		    formData.append("image", this.state.selectedFile, this.state.selectedFile.name);
		}		
		formData.append('ssn', this.state.ssn);			
		formData.append('planid', this.state.plan);
		formData.append("roleid", this.state.user_type);
		Axios.post(base_api_url + 'add-user-admin.php', formData, { axiosConfig }).then((res) => {
			window.scrollTo(0, 0);
			if (res.data.res_status == 200) {
				this.setState({
					redirect: true
				});
				this.clearForm();
				toast.success(res.data.res_message);				
			}else{
				toast.error(res.data.res_message);
			}			
			this.setState({
		    	btnText:'Submit',
		    	btnDisable:false
		  	});
		});
	}
	clearForm = (e) => {
		this.setState({
			type : 'paid',
			fname: '',
			lname: '',
			dob: '',
			mobile: '',
			email: '',
			password: '',
			city: '',
			state: '',
			country: '',
			zip: '',
			selectedFile: '',
			ssn:'',
			plan:'',
			user_type:''
		});
	}
	render() {
		if(this.state.redirect){
			return <Redirect to="/admin/users" />
		}
		return (
      	<React.Fragment>
        	<div className="dashboard-wrapper">
          		<AdminHeader />
          		<div className="maincontent-area">
            		<AdminTopHeader />
            		<div className="main-dashsection">
              			<section className="contentpard">
              				<form className="artist-form" onSubmit={this.onSubmit}>
              					<div className="row">
									<div className="col-md-12">
										<div className="box box-success artisit-profileform adminusers">
											<div className="box-header with-border addprofilenew">
												<h3 className="box-title ">Add User</h3>
												<div className="box-tools ">
													<Link to={'/admin/users'} className="btn btn-primary pull-right">
														<i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
													</Link>
												</div>
											</div>
											<div className="box-body no-padding havetabs eventadds">
												<div className="nav-tabs-custom">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="firstname">User Type<span className="requiredastrik">*</span></label>
																<select className="form-control" onChange={this.onChangeUserType.bind(this)}>
																	<option value="">Select User Type</option>
																	<option value="2">Artist</option>
																	<option value="1">Listener</option>																	
																</select>
																<font className="errorMsg">{this.state.errors['utype_error']}</font>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="type">Account Type</label><br />
																<div className="custom-control custom-radio custom-control-inline">
																	<input type="radio" id="accountTypePaid" value="paid" name="type" onChange={this.onChangeType.bind(this)} className="custom-control-input" checked={this.state.type === 'paid'}/>
																	<label className="custom-control-label" htmlFor="accountTypePaid">Paid</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline">
																	<input type="radio" id="accountTypeFree" value="free" name="type" onChange={this.onChangeType.bind(this)} className="custom-control-input" checked={this.state.type === 'free'}/>
																	<label className="custom-control-label" htmlFor="accountTypeFree">Free</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline">
																	<input type="radio" id="accountTypePayment" value="payment" name="type" onChange={this.onChangeType.bind(this)} className="custom-control-input" checked={this.state.type === 'payment'}/>
																	<label className="custom-control-label" htmlFor="accountTypePayment">For Payment By User</label>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="firstname">First Name<span className="requiredastrik">*</span></label>
																<input type="text" className="form-control"	maxLength="50" onChange={this.onChangeFirstName.bind(this)} />
																<font className="errorMsg">{this.state.errors['firstname_error']}</font>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="firstname">Last Name</label>
																<input type="text" className="form-control"	maxLength="50" onChange={this.onChangeLastName.bind(this)} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="dateofbirth">Date of Birth</label>
																<DatePicker selected={this.state.dob} onChange={this.onChangeDOB.bind(this)} name="startDate" dateFormat="MM/dd/yyyy" dropdownMode="select"
																	showMonthDropdown
																	showYearDropdown
																	adjustDateOnChange
																	maxDate={new Date()}
																	className="form-control"
																/>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="gender">Gender</label><br />
																<div className="custom-control custom-radio custom-control-inline">
																	<input type="radio" id="malegender"	value="male" name="gender" onChange={this.onChangeGender.bind(this)} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="malegender">Male</label>
																</div>
																<div className="custom-control custom-radio custom-control-inline">
																	<input type="radio" id="femalegender" value="female" name="gender" onChange={this.onChangeGender.bind(this)} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="femalegender">Female</label>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="phone">Phone No.</label>
																<InputMask {...this.props} mask="(999) 999-9999" maskChar="" className="form-control" onChange={this.onChangePhone.bind(this)}	/>
															</div>
														</div>
							                            <div className="col-md-6">
							                            	<div className="form-group">
							                            		<label htmlFor="email">Email<span className="requiredastrik">*</span></label>
							                            		<input type="text" className="form-control" onChange={this.onChangeEmail.bind(this)} />
							                            		<font className="errorMsg">{this.state.errors['email_error']}</font>
							                            	</div>
							                          	</div>													
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="address">Password<span className="requiredastrik">*</span></label>
																<input type="password" className="form-control" onChange={this.onChangePassword.bind(this)} />
																<font className="errorMsg">{this.state.errors['password_error']}</font>
								                            </div>
								                        </div>
								                        <div className="col-md-6">
															<div className="form-group">
																<label htmlFor="address">Address</label>
																<input type="text" className="form-control" onChange={this.onChangeAddress.bind(this)}	/>
								                            </div>
								                        </div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="city">City</label>
																<input type="text" className="form-control" onChange={this.onChangeCity.bind(this)} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="state">State</label>
																<input type="text" className="form-control" onChange={this.onChangeState.bind(this)} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="country">Country</label>
																<select className="form-control" onChange={this.onChangeCountry.bind(this)}>
																	<option value="">--Select Country--</option>
																	{this.state.allCountries.map((item, index) => (
																	<option value={item.key} key={item.key}>
																	{item.value}
																	</option>
																	))}
																</select>
															</div>
														</div>
														<div className="col-md-6">
								                            <div className="form-group">
								                              	<label htmlFor="zip">Zip Code</label>
								                              	<input type="text" maxLength="6" className="form-control" onChange={this.onChangeZip.bind(this)} />
								                            </div>
								                        </div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="customFile">Profile Picture</label>
																<input type="file" ref="uploadImageProfile" className="form-control" id="customFile" onChange={this.onFileChange.bind(this)} accept="image/*" />
																<font className="errorMsg">{this.state.errors['image_error']}</font>
															</div>
														</div>
														<div className="col-md-6" style={{'display':this.state.ssn_hide}}>
															<div className="form-group">
														  		<label htmlFor="ssn">SSN <span className="helpicon"><i className="fa fa-question-circle" aria-hidden="true"></i></span>
																	<span className="helpiconactive helpiconssn" style={{'width':'330px'}}>
																	<p>This enables you to receive payments and proper tax documents at year end.</p>
																	</span>
														  		</label>
														  		<InputMask
														  		{...this.props}
														  		className="form-control"
														  		mask="999-99-9999"
														  		maskChar=""														  		
														  		onChange={this.onChangeSSN.bind(this)}
														  		/>
															</div>
													  	</div>
													</div>
													<div style={{'display':this.state.payment_option_hide}}>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Subscription Plan<span className="requiredastrik">*</span></label>
																<select className="form-control" onChange={this.onChangePlan.bind(this)}>
																	<option value="">--Select Subscription Plan--</option>
																	{this.state.planlist.map((result) => {
																	return (<option value={result.id}>{result.plan} ${result.amount}</option>)
																	})}
																</select>
																<font className="errorMsg">{this.state.errors['plan_error']}</font>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Credit Card<span className="requiredastrik">*</span></label>
																<div className="creditcardsection">
																	<CardElement options={{ hidePostalCode: true }}  onChange={(element) => this.stripeElementChange(element, 'card_number')} />
																</div>
																<font className="errorMsg">{this.state.cardError}</font>
															</div>
														</div>
													</div>
													</div>
													<div className="row">
														<div className="col-md-12 notmainbtn customtabsbtns">
															<button type="button" onClick={ this.onSubmitForm.bind(this)} className="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
              				</form>
              			</section>
              		</div>
              	</div>
            </div>
        </React.Fragment>
        )
	}
}
export default function InjectedEventBooking({ match }) {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <AddUser match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}