import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}

export default class MyParticipants extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {		  
		  userid:null,
		  token:'',
		  participantsList:[],		  
		  loading:true,
		  eventList: []
		}
		
	}	
	componentDidMount(){
		document.title = "CONQER Music - Participants List";
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({	
			token:token,		
			userid:userid
		});
		
		this.getAllParticipants();
		this.getAllEvents();
	}
	getAllEvents = () => {
		const obj = {
			userid:ls.get('userid')
		}		
		Axios.post(base_api_url+'eventlist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data;					
					this.setState({
						eventList:returndata
					});
				}else{
					this.setState({
						eventList:[]
					});
				}
				
			}
		);
	}
	getAllParticipants = () => {
		const obj = {
			user_id:ls.get('userid'),
			event_id: this.state.event_id
		}
		
		Axios.post(base_api_url+'get-participants.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data;
					
					this.setState({
						participantsList:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}

				$('#myevents').DataTable().destroy();
	        	$(document).ready(function () {
			        setTimeout(function(){
			        $('#myevents').DataTable({
			        	"destroy": true,
						"stateSave": true,
  						"lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100,  "All"]],
						"language": {
							"search": "Search"
						},
						dom: 'Bfrtip',
          				buttons: ['csv', 'print'],
  						columnDefs: [
  							{ "width": "20%", "targets": 0 },
			   				{ orderable: false, targets: -1 }
						]
			        });
			         } ,50);
			    });
			}
		);
	}
	OnChangeRecord(e){
		this.setState({
			event_id: e.target.value
		},()=> this.getAllParticipants());
	}
	render() {
		if(this.state.loading==true){
			return <Spinner />;
		}
		let id = this.props.match.params.id;
		return (
			<React.Fragment>
				<div className={this.state.successclass}>{this.state.msg}</div>
				<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
							  <h3 className="box-title">Participants List</h3>
							  <div className="box-tools d-flex justify-content-between">
								  <select className="filteroptionselect" onChange={this.OnChangeRecord.bind(this)}>
								  	<option value="">All</option>
								  	{
								  		this.state.eventList.map((result) => {
								  			return(
								  				<option value={result.id}>{result.event_name}</option>
								  			)
								  		})
								  	}
								  </select>
								</div>         
							</div>
									<div className="box-body no-padding">
									  <div className="table-responsive">									  
									<table id="myevents" className="table no-margin table-striped table-hover">
										<thead>
										  <tr>
											<th>Event Name</th>                    
											<th>Ticket</th>
											<th>Participant</th>
											<th style={{textAlign:'center'}}>Booked Tickets</th>
											<th style={{textAlign:'center'}}>Artist Amount</th>
											<th style={{textAlign:'center'}}>Admin Commission</th>
											<th style={{textAlign:'center'}}>Amount Received</th>	
											<th>Reference ID</th>										
											<th>Status</th>
											<th>Present</th>
										  </tr>
										</thead>
										<tbody>
										  {this.state.participantsList.map((result) => {
											  let eventlocation = result.location;
											return (
											<>
											{!isNaN(id)?
											((id == result.id)?
											  <tr>                  
												<td><NavLink to={'/view-event/'+result.event_id}>{result.event_name}</NavLink> </td>
												<td>{result.ticket_name}</td>
												<td>{result.participant_name}</td>
												<td style={{textAlign:'center'}}>{result.no_of_tickets}</td>												
												<td style={{textAlign:'center'}}>{result.total_price}</td>
												<td style={{textAlign:'center'}}>{result.admin_commission}</td>
												<td style={{textAlign:'center'}}>{result.ticket_price}</td>
												<td>{result.reference_id}</td>
												<td>{(result.status=='1') ? <span className="label label-success">Booked</span> : <span className="label label-danger">Failed</span>}</td>
											  	<td style={{textAlign:'center'}}>{result.attendee_qr_ids}</td>
											  </tr>	
											  :""):<tr>                  
												<td><NavLink to={'/view-event/'+result.event_id}>{result.event_name}</NavLink> </td>
												<td>{result.ticket_name}</td>
												<td>{result.participant_name}</td>
												<td style={{textAlign:'center'}}>{result.no_of_tickets}</td>												
												<td style={{textAlign:'center'}}>{result.total_price}</td>
												<td style={{textAlign:'center'}}>{result.admin_commission}</td>
												<td style={{textAlign:'center'}}>{result.ticket_price}</td>
												<td >{result.reference_id}</td>
												<td>{(result.status=='1') ? <span className="label label-success">Booked</span> : <span className="label label-danger">Failed</span>}</td>
											  	<td style={{textAlign:'center'}}>{result.attendee_qr_ids}</td>
											  </tr>}
											  </>
											)
											})}
										</tbody>
									</table>
								  </div>              
								</div>
								</div>
							</div>        
						  </div>      
						
			</React.Fragment>
		)
	}
}