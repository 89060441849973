import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image, NavLink, Table } from 'react-bootstrap';
import Axios from 'axios';
import MusicPlayer from './musicPlayer.component';
import { playAudio } from '../includes/playAudio.component';
import { API_URL } from '../config/constants.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import shortid from 'shortid';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
export default class ListenerSongPlay extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      musicList: this.props.musiclist,
      index: 0,
      songInfo: {
        currentTime: 0,
        duration: 0,
        animationPercentage: 0,
        volume: 0
      },
      isPlaying: true,
      pause: '',
      loading: '',
      mute: false,
      currentSong: this.props.musiclist.filter((music) => music.song_id == this.props.alid)[0]
    };
  }

  setMute = () => {
    this.setState(
      {
        mute: !this.state.mute
      },
      () => (this.audioRef.current.muted = this.state.mute)
    );
  };

  timeUpdateHandler = (e) => {
    const current = e.target.currentTime;
    const duration = e.target.duration;
    let mList = (this.state.musiclist)?this.state.musiclist:this.props.musiclist;
    if (current === duration) {
      this.setState(
        {
          isPlaying: !this.state.isPlaying
        },
        () => {
          if(this.state.index < mList.length - 1 ){
            if (this.state.index === mList.length - 1) {
              this.audioPlayHandler(0);
            } else {
              this.audioPlayHandler(this.state.index + 1);
            }
          }
        }
      );
    }
    const roundedCurrent = Math.round(current);
    const roundedDuration = Math.round(duration);
    const percentage = Math.round((roundedCurrent / roundedDuration) * 100);
    this.setState({
      songInfo: {
        currentTime: current,
        duration: duration,
        animationPercentage: percentage,
        volume: e.target.volume
      }
    });
  };

  audioPlayHandler(key) {
    ls.remove('localIndex');
    ls.set('localIndex', key);
    let songList = this.props.musiclist[key];
    if(songList.token == undefined){
      songList.token = shortid.generate();
    }
    let newObj = {
      currentTime: 0,
      duration: this.props.musiclist[key].duration,
      animationPercentage: 0,
      volume: 0
    };

    this.setState(
      {
        index: key,
        currentSong: this.props.musiclist[key],
        isPlaying: true,
        songInfo: newObj
      },
      () => {
        //playAudio(this.state.isPlaying, this.audioRef);
        this.playAudioSong(this.state.isPlaying, this.audioRef);
      }
    );
  }
  playAudioSong = (isPlaying, audioRef) => {
      if (isPlaying) {        
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    };
    insertSongInfo(song){
    const objs = {
        user_id: ls.get('userid'),
        song_id: song.song_id,
        played_time: '0',
        token: song.token
      };
    Axios.post(base_api_url + 'songplayed.php', objs, { axiosConfig }).then((result) => {
      if (result.data.res_status == 200) {
        this.setState({
          isCurrent: true
        });
        console.log('song data saved successfully');
      } 
    });
  }
  setIsPlaying = () => {
    this.setState({
      isPlaying: !this.state.isPlaying
    });
  };

  setCurrentSong = (ind) => {
    console.log('inde', ind);
    this.setState({
      currentSong: this.state.musicList[ind],
      index: ind
    });
  };

  setSongInfo = (parameter) => {
    this.setState({
      songInfo: parameter
    });
  };

  getTime = (timer) => {
    if (this.state.isPlaying) {
      return Math.floor(timer / 60) + ':' + ('0' + Math.floor(timer % 60)).slice(-2);
    } else {
      return Math.floor(timer);
    }
  };
  componentDidMount() {
    this.audioRef.current.play();
    const csong = this.props.musiclist.filter((music) => music.song_id === this.props.alid);
    this.setState({
      currentSong: csong[0]
    });
    let songList = csong[0];
    if(songList.token == undefined){
      songList.token = shortid.generate();
    }
    this.insertSongInfo(songList);
    //console.log(songList);
  }
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };
  static getDerivedStateFromProps(props, state) {
    return { musicList: props.musiclist };
  }
  render() {
    const { musicList, index, currentTime, pause } = this.state;
    // console.log('musicList',index);
    let currentSong = musicList[index];
    return currentSong ? (
      <>
		<section className="contentpards">
        <Row sm={12} className="d-flex">
          <Col className="my-3 d-flex ">
            <Image onError={this.addDefaultSrc} style={{ width: '200px', height: '200px' }} fluid src={currentSong.img} fluid />

            <Col>
              <h2 className="text-white">{currentSong.name}</h2>
            </Col>
          </Col>

          <Col className="d-flex justify-content-end">
            <Link to={(ls.get('role') == '2')?'/artist-favoritesongs/':'/listener-recentsongs/'} className="btn btn-primary" style={{ height: '35px' }}>
              {' '}
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
            </Link>
          </Col>
        </Row>
        <div className="box box-success distributions-profileform">
          <div className="box-header with-border addprofilenew">
            <h3 className="box-title">Album Songs</h3>
          </div>
          <div className="box-body table-responsive no-padding">
            <div style={{ overflow: 'auto', cursor: 'pointer', backgroundColor: 'white', maxHeight: '50vh' }}>
              <Table responsive className="managewithmobile">
                <thead>
                  <tr className="">
                    <th> </th>
                    <th>Title</th>
                    <th>Artist</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {musicList.map((music, key) => {
                    if (key === 0) {
                      return (
                        <React.Fragment key={key}>
                          <tr
                            style={this.state.isPlaying ? (this.state.index == key ? { backgroundColor: '#FDEAB0' } : {}) : {}}
                            onClick={() => this.audioPlayHandler(Number(key))}
                          >
                            <td className="" style={{width:'10%'}}>
                              <i
                                style={{ fontSize: '1.5rem', color: '#b8a25e', width: '100%' }}
                                className=" d-flex  justify-content-center fa fa-play-circle-o"
                                aria-hidden="true"
                              ></i>
                            </td>

                            <td className="" style={{width:'50%'}}>
                              <Image
                                onError={this.addDefaultSrc}
                                style={{ width: '2.1rem', height: '2.1rem', marginRight: '1rem' }}
                                src={music.img}
                                rounded
                              />
                              {'     ' + music.name}
                            </td>
                            <td className="" style={{width:'30%'}}>{music.author ? music.author : ''}</td>
                            <td className="" style={{width:'10%'}}>{music.duration}</td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  })}
                </tbody>
              </Table>

              <div className="my-3" style={{ borderTop: '1px solid #b8a25e' }}>
                <h4 style={{ 'color': '#b8a25e','cursor':'default' }} className="mt-3">
                  Similar Songs
                </h4>
              </div>

              <Table style={{ cursor: 'pointer', width: '100%' }} responsive hover className="managewithmobile">
                <thead>
                  <tr className="">
                    <th> </th>
                    <th>Title</th>
                    <th>Artist</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {musicList.map((music, key) => {
                    if (key > 0) {
                      return (
                        <React.Fragment key={key}>
                          <tr
                            style={this.state.isPlaying ? (this.state.index == key ? { backgroundColor: '#FDEAB0' } : {}) : {}}
                            onClick={() => this.audioPlayHandler(Number(key))}
                          >
                            <td className="" style={{width:'10%'}}>
                              <i
                                style={{ fontSize: '1.5rem', color: '#b8a25e', width: '100%' }}
                                className=" d-flex  justify-content-center fa fa-play-circle-o"
                                aria-hidden="true"
                              ></i>
                            </td>

                            <td className="" style={{width:'50%'}}>
                              <Image
                                onError={this.addDefaultSrc}
                                style={{ width: '2.1rem', height: '2.1rem', marginRight: '1rem' }}
                                src={music.img}
                                rounded
                              />
                              <span class="songtitletable">{music.name}</span>
                            </td>
                            <td className="" style={{width:'30%'}}>{music.author ? music.author : ''}</td>
                            <td className="" style={{width:'10%'}}>{music.duration}</td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
		</section>
		<section className="playerimplement d-block">
			<MusicPlayer
			  setIsPlaying={this.setIsPlaying}
			  audioRef={this.audioRef}
			  currentSong={currentSong}
			  songInfo={this.state.songInfo}
			  isPlaying={this.state.isPlaying}
			  setSongInfo={this.setSongInfo}
			  musiclist={this.state.musicList}
			  setCurrentSong={this.setCurrentSong}
			  index={this.state.index}
			  setMute={this.setMute}
			  mute={this.state.mute}
			  likeSongFu={this.props.likeSong}
        _favoriteSong={this.props._favoriteSong}
        likeSongStatus = {(this.state.likeStatus)?this.state.likeStatus:currentSong.likestatus}
        is_favorite = {(this.state.is_favorite)?this.state.is_favorite:currentSong.is_favorite}
			/>
			<audio onLoadedMetadata={this.timeUpdateHandler} onTimeUpdate={this.timeUpdateHandler} ref={this.audioRef} src={currentSong.audio}></audio>
		</section>
      </>
    ) : (
      <Spinner />
    );
  }
}
