import React, { Component } from 'react';
export default class Loader extends Component {
	render() {
		return (
			<React.Fragment>
				<section className="become-artist-form">
					<div className="loader center custom-loader">
						<i className="fa fa-refresh fa-spin fa-3x fa-fw custom-spin" aria-hidden="true"></i>						
						<h2>Loading...</h2>
					</div>
				</section>
			</React.Fragment>
		)
	}
}


