import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import { API_URL, STRIPE_KEY } from '../config/constants.js';
import { playAudio } from '../includes/playAudio.component';
import PlayListModal from '../includes/showPlaylistModal';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import defaultimg from '../components/default.png';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
import createHistory from 'history/createBrowserHistory';
import MusicPlayer from './musicPlayer.component';
import shortid from 'shortid';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
const history = createHistory();
const stripePromise = loadStripe(STRIPE_KEY);
toast.configure();
const errors = {};
export class MySongs extends Component {
	constructor(props) {
		super(props);
		this.audioRefs = React.createRef();
		this.audioRef = React.createRef();
		this.state = {
		  email: '',
		  role: null,
		  userid: null,
		  token: '',
		  error: null,
		  msg: null,
		  index:0,
		  songlist: [],
		  items: [],
		  loading: true,
		  loader: true,
		  successclass: null,
		  redirect: false,
		  isPlaying: false,
		  // currentSong: '',
		  album_name: '',
		  showModal: '',
		  csongid:'',
		  musiclist:'',
		  error: null,
		  msg: null,
		  successclass:null,
		  redirect:false,
		  songInfo: {
			currentTime: 0,
			duration: 0,
			animationPercentage: 0,
			volume: 0,
		  },
		  mute: false,
		  currentSong: {},
		  likeStatus:'',
		  errors:[],
		  planlist:[],
		  btnText:'Submit',
		  btnDisable: false ,
		  songId: '',
		  featuredData:[],
		  is_featured: '0',
		  stripe_text: '' 
		};
	}

	componentDidMount() {
		document.title = 'CONQER Music - Songs';

		const email = ls.get('email');
		const name = ls.get('name');
		const token = ls.get('token');
		const userid = ls.get('userid');
		let message = '';
		/*if (this.props.location.state !== undefined && this.props.location.state !== null) {
		  message = this.props.location.state.message;
		}*/
		this.setState({
		  email: email,
		  name: name,
		  token: token,
		  userid: userid,
		  msg: message,
		  successclass: 'successmsg'
		});
		if(message){
		  let state = { ...history.location.state };
			delete state.message;
			history.replace({ ...history.location, state });
			setTimeout(() => {
			this.setState({
			  successclass: null,
			  msg: null
			});
		  }, 5000);
		}
		this.getallsonglist();
		this.getAlbumData();
		this.getplandetails('song');
	}
	getallsonglist = () => {
		const obj = {
		  albumid: this.props.match.params.id
		};

		Axios.post(base_api_url + 'artistalbumsongall.php', obj, { axiosConfig }).then((res) => {
			if (res.data.res_status === '200') {
			  this.setState({
				songlist: res.data.res_data,
				musiclist:res.data.res_data,
				loading: false,
			  },
				()=>{
					this.setState({
						currentSong:this.state.musiclist[this.state.index]
					})
				});
			} else {
			  this.setState({
				songlist: [],
				loading: false
			  });
			}
			setTimeout(() => {
				$('#mysongs').DataTable({
					pageLength: 10,
					destroy: true,
					stateSave: true,
					language: {
						search: "Search"
					},
					ordering: false,
					lengthMenu: [
						[5, 10, 15, -1],
						[5, 10, 15, 'All']
					],
					columnDefs: [
						{ "width": "30%", "targets": 0 }
					]
				});
			}, 500);
		  
		});
	};
	handleModal = (data) => {
		this.setState({showModal:data});
	};
	setShowModal = (show,sngid) => {
		this.setState({showModal:show,csongid:sngid});
	};
	getAlbumData = () => {
		const obj = {
		  id: this.props.match.params.id
		};

		Axios.post(base_api_url + 'getalbum.php', obj, { axiosConfig }).then((res) => {
		  if (res.data.res_status == 200) {
			const returndatas = res.data.res_data;
			this.setState({
			  album_name: returndatas.name,
			  loading: false
			});
		  } else {
			this.setState({
			  loading: false
			});
		  }
		});
	};

	deletealbumsong = (songid, status) => {
		const obj = {
		  id: songid,
		  status: status
		};
	//	console.log('obj', obj);
		Axios.post(base_api_url + 'updatestatussong.php', obj, { axiosConfig }).then((res) => {
		  if (res.data.res_status == 200) {
			this.setState({
			  successclass: 'successmsg',
			     msg: 'Song status ' + ((status=='0')?'activated':'in-activated') + ' successfully!'
			});
		  } else {
			this.setState({
			  successclass: 'errormsg',
			  msg: 'Song status not updated, please try again!'
			});
		  }
		  setTimeout(() => {
			this.getallsonglist();
			this.setState({
			  successclass: null,
			  msg: null
			});
		  }, 5000);
		});
	};

	deletesong = (songid) => {
		const confirmBox = window.confirm('Do you really want to delete this song?');

		if (confirmBox === true) {
		  const obj = {
			userid: ls.get('userid'),
			songid: songid
		  };

		  Axios.post(base_api_url + 'deletesong.php', obj, { axiosConfig }).then((res) => {
			if (res.data.res_status == 200) {
			  const returndata = res.data.res_web_data;
			  this.setState({
				successclass: 'successmsg',
				msg: 'Song deleted successfully!'
			  });
			} else {
			  this.setState({
				successclass: 'errormsg',
				msg: 'Song not deleted, please try again!'
			  });
			}
			setTimeout(() => {
			  this.setState({
				successclass: null,
				msg: null
			  });
			}, 5000);
		  });
		  window.location.reload();
		}
	};

	onclickseturl = (urlval) => {
		this.setState({
		  urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	};
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg;
	};

	playHandler = (song) => {
		if (this.state.currentSong.id === song.id) {
		  this.setState(
			{
			  isPlaying: !this.state.isPlaying,
			  currentSong: song
			},
			() => playAudio(this.state.isPlaying, this.audioRefs)
		  );
		} else {
		  this.setState(
			{
			  isPlaying: true,
			  currentSong: song
			},
			() => playAudio(this.state.isPlaying, this.audioRefs)
		  );
		}
	};
	setMute = () => {
		this.setState(
		  {
			mute: !this.state.mute,
		  },
		  () => (this.audioRef.current.muted = this.state.mute)
		);
	};
	setCurrentSOng = (song,index,songlist)=>{
		song.token = shortid.generate();
		this.setState({
			currentSong:song,
			index:index,
			isPlaying:true,
			musiclist:songlist,
			likeStatus:''
		},
		()=>{
			/**/
			this.audioPlayHandler(song);
		});
	}
	insertSongInfo(song){
		const objs = {
		  	user_id: ls.get('userid'),
		  	song_id: song.song_id,
		  	played_time: '0',
		  	token: song.token
	  	};
		Axios.post(base_api_url + 'songplayed.php', objs, { axiosConfig }).then((result) => {
		  if (result.data.res_status == 200) {
		  	this.setState({
		  		isCurrent: true
		  	});
			  console.log('song data saved successfully');
		  } 
		});
	}
	timeUpdateHandler = (e) => {
		const current = e.target.currentTime;
		const duration = e.target.duration;
		let mList = (this.state.musiclist)?this.state.musiclist:this.props.musiclist;
		if (current === duration) {
		  this.setState(
			{
			  isPlaying: !this.state.isPlaying
			},
			() => {
			  if(this.state.index < mList.length - 1 ){
				if (this.state.index === mList.length - 1) {
				  this.audioPlayers(0);
				} else {
				  this.audioPlayers(this.state.index + 1);
				}
			  }
			}
		  );
		}
		const roundedCurrent = Math.round(current);
		const roundedDuration = Math.round(duration);
		const percentage = Math.round((roundedCurrent / roundedDuration) * 100);
		this.setState({
		  songInfo: {
			currentTime: current,
			duration: duration,
			animationPercentage: percentage,
			volume: e.target.volume
		  }
		});
	  };

	  audioPlayHandler(song) {
		//playAudio(this.state.isPlaying, this.audioRef, isCurrent);
		this.playAudioSong(this.state.isPlaying, this.audioRef, song);
		//playSongHandler(this.state.isPlaying, this.audioRef);
	  }
	  audioPlayers(key) {
		let songList = this.state.musiclist[key];
		if(songList.token == undefined){
		  songList.token = shortid.generate();
		}
		let newObj = {
		  currentTime: 0,
		  duration: this.state.musiclist[key].duration,
		  animationPercentage: 0,
		  volume: 0
		};

		this.setState(
		  {
			index: key,
			currentSong: this.state.musiclist[key],
			isPlaying: true,
			songInfo: newObj
		  },
		  () => {
			//playAudio(this.state.isPlaying, this.audioRef);
			this.playAudioSong(this.state.isPlaying, this.audioRef, songList);
		  }
		);
	  }
	  playAudioSong = (isPlaying, audioRef, song) => {
		  if (isPlaying) {
		  	this.insertSongInfo(song);
		    audioRef.current.play();
		  } else {
		    audioRef.current.pause();
		  }
		};
	  setIsPlaying = () => {
		this.setState({
		  isPlaying: !this.state.isPlaying,
		});
	  };

	  

	  setCurrentSong = (ind) => {
		this.setState({
		  currentSong: this.state.musiclist[ind],
		  index: ind,
		});
	  };

	  setSongInfo = (parameter) => {
		this.setState({
		  songInfo: parameter,
		});
	  };

	  getTime = (timer) => {
		if (this.state.isPlaying) {
		  return Math.floor(timer / 60) + ':' + ('0' + Math.floor(timer % 60)).slice(-2);
		} else {
		  return Math.floor(timer);
		}
	  };
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	likeSongFu = (song_id,like_status, musiclist, index) => {
		const objs = {
		  song_id: song_id,
		  like: like_status,
		  user_id: ls.get('userid')
		};
		
		Axios.post(base_api_url + 'songlike.php', objs, { axiosConfig }).then((result) => {
		  let returndatas = result.data.res_message;
		  if (Number(result.data.res_status) === 200) {
			if (returndatas !== 'null') {
			  this.setState({
					successclass:'successmsg',						
					msg:returndatas
			  },()=>{
				this.setState({musiclist:musiclist, index:index, likeStatus: like_status})
				this.getallsonglist();
			});
			}
		  } else {
			this.setState({
				successclass:'errormsg',						
				msg:returndatas
			});
		  }
		  setTimeout(() => {
				this.setState({
					successclass:null,
					msg:null
				});
			}, 10000)
		});
	}
	_favoriteSong = (song_id,is_favorite, musiclist, index) => {
		let favorite = (is_favorite == '0')?'1':'0';
		const objs = {
		  song_id: song_id,
		  is_favorite: favorite,
		  user_id: ls.get('userid')
		};
		
		Axios.post(base_api_url + 'add-to-favorite.php', objs, { axiosConfig }).then((result) => {
		  let returndatas = result.data.res_message;
		  if (Number(result.data.res_status) === 200) {
			if (returndatas !== 'null') {
			  this.setState({
					successclass:'successmsg',						
					msg:returndatas
			  },()=>{
				this.setState({musiclist:musiclist, index:index, is_favorite: favorite})
				this.getallsonglist();
			});
			}
		  } else {
			this.setState({
				successclass:'errormsg',						
				msg:returndatas
			});
		  }
		  setTimeout(() => {
				this.setState({
					successclass:null,
					msg:null
				});
			}, 10000)
		});
	}
	markFeatured = (id, is_featured) => {
		this.setState({
			songId: id,
			is_featured: is_featured
		}, () => $('#upGradModal').modal('show'));
		this.getUserPlanDetail(id);
		
	}
	getUserPlanDetail = (id) => {
		const obj = {
			songId: id,
			type: 'song'
		}				
		Axios.post(base_api_url+'user-featuerd-plan.php',obj,{axiosConfig})
		.then(
			(res) => {				
				if(res.data.res_status==200){
					this.setState({
						featuredData:res.data.res_data
					});
				}
			}
		);
	}
	onChangePlan(e){
		if (this.state.errors['plan_error']) { delete this.state.errors['plan_error']; }
		this.setState({
			sub_plan: e.target.value
		})
	}
	getplandetails = (utype) => {   
	    const obj = {
	      type: utype
	    }
	    Axios.post(base_api_url + 'get-featured-plan.php', obj).then(
	        (res) => {
	          if (res.data.res_status == 200) {
	            this.setState({
	              planlist: res.data.res_data,
	              stripe_text: res.data.stripe_detail 
	            });
	          } else {
	            this.setState({
	              planlist: []
	            });
	          }
	        }
	    );
	}
	async onSubmit(e) {
		e.preventDefault();
		if (this.handleValidationUpgrad()) {
			this.setState({
				btnText:'Please Wait..',
				btnDisable:true
			});
			await this.onToken();      
		}
	}
	handleValidationUpgrad = () => {
		let formIsValid = true;
		if (!this.state.sub_plan) {
		  formIsValid = false;
		  errors['plan_error'] = 'Please select plan.';
		}
		if(!this.state.formValidation){
		  formIsValid = false;
		}
		if(!this.state.fillStatus){
		  this.setState({
		    cardError:'Please enter card detail.'
		  });
		  formIsValid = false;
		}
		this.setState({ errors: errors });
		return formIsValid;
	};
	stripeElementChange = (element, name) => {
	    this.setState({
	      fillStatus: true,
	      cardError:'',
	      formValidation: true
	    });
	    if(element.error){
	      if(element.error.code){
	        this.setState({
	          formValidation: false,
	          cardError: element.error.message
	        });
	      }
	    }else if(!element.complete){
	    	this.setState({
		      fillStatus: false
		    });
	    }
	  }
	async onToken() {
    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if(result.error){
      this.setState({
        btnText:'Submit',
        btnDisable:false
      });
    }else{
      let formData = {
        "token":result.token.id,
        "planId": this.state.sub_plan,
        "songId": this.state.songId
      };
      Axios.post(base_api_url + 'upgrade-featured-songs.php', formData, { axiosConfig }).then((res) => {
      	if(res.data.res_status == '200'){
      		const { elements, stripe } = this.props;
		    const cardElement = elements.getElement(CardElement);
		    if(cardElement){
		      cardElement.clear();
		    }
		    this.setState({
		    	sub_plan:'',
		    	is_featured:'1'
		    });
		    toast.success(res.data.res_message);
		    this.getallsonglist();
		    this.getUserPlanDetail(this.state.songId);
      	}else{
      		toast.error(res.data.res_message);
      	}
      	this.setState({
	        btnText:'Submit',
	        btnDisable:false
	    });
      });
      
    }
  }
  render() {
    let albumid = this.props.match.params.id;
	const { musiclist, index, currentTime, pause } = this.state;
	const currentSong = this.state.currentSong;
    if (this.state.loading == true) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
        {this.state.msg ? <div className={this.state.successclass}>{this.state.msg}</div> : ''}
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">{this.state.album_name}</h3>

                <div className="box-tools">
                  <NavLink to={'/add-song/' + albumid} className="btn btn-primary aadersongs">
                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add New Song
                  </NavLink>
                  <NavLink to={'/my-albums/' + this.state.token} className="btn btn-primary">
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back
                  </NavLink>
                </div>
              </div>
              <div className="box-body table-responsive  no-padding">
                <table id="mysongs" className="table table-striped table-bordered table-hover table-sm row-border hover mb-5">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Cover</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Approval</th>
                      <th>Status</th>
                      <th className="actionbtns text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.songlist.map((result, key) => {
                      var albumimage = result.cover_image;
                      let mysongurl = '/mysongs/' + result.id;
                      let playTitle, playClassName;
                      if (this.state.currentSong.id === result.id && this.state.isPlaying) {
                        playClassName = 'fa fa-pause';
                        playTitle = 'Pause Song';
                      } else {
                        playClassName = 'fa fa-play';
                        playTitle = 'Play Song';
                      }
                      return (
                        <tr>
                          <td>{result.title}</td>
                          <td>
                            <img
                              onError={this.addDefaultSrc}
                              src={albumimage}
                              width="50"
                              height="50"
                              onClick={() => this.onclickseturl(albumimage)}
                            />
                          </td>
                          <td>{result.releases}</td>
                          <td>{result.date}</td>
                          <td>
                            {result.song_astatus == '0' ? (
                              <span className="label label-danger">Not approved</span>
                            ) : (
                              <span className="label label-success">Approved</span>
                            )}
                          </td>
                          <td>
                            {result.song_status == '1' ? (
                              <span className="label label-success">Active</span>
                            ) : (
                              <span className="label label-danger">Inactive</span>
                            )}
                          </td>
                          <td className="actionbtns" style={{'textAlign':'center'}}>
                            <Link to={'/edit-song/' + result.id} title="Edit Song" className="btn btn-info custombtn">
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>
                            {result.status === '1' ? (
                              <a href="javascript:;" onClick={() => this.deletesong(result.id)} className="btn btn-danger custombtn">
                                <i className="fa fa-trash" aria-hidden="true"></i>
                              </a>
                            ) : (
                              ''
                            )}
                            <a
                              href="javascript:;"
                              title={result.song_status == '1' ? 'In Active' : 'Active'}
                              onClick={() => this.deletealbumsong(result.id, result.song_status)}
                              className="btn btn-info custombtn"
                            >
                              {result.song_status === '1' ? (
                                <i className="fa fa-ban" aria-hidden="true"></i>
                              ) : (
                                <i className="fa fa-check" aria-hidden="true"></i>
                              )}
                            </a>
                            {/*<Link onClick={() => this.playHandler(result)} className="btn btn-info custombtn">
                              <i className={playClassName} title={playTitle}></i>
                            </Link>*/}
							<Link onClick={() => this.setCurrentSOng(result,key,this.state.songlist)} className="btn btn-info custombtn" title="Play Song">
								<i className='fa fa-play'></i>
							</Link>
							<a href="javascript:;" style={{ cursor: 'pointer' }} onClick={() => this.setShowModal(!this.state.showModal,result.id)} className="likesong btn btn-info custombtn" title="Add to playlist">
								<img className="asd" style={{"width":"18px"}} src="../assets/music/whiteplaylistin.png" />
							</a>
							<Link onClick={() => this.markFeatured(result.id, result.featured)} className="btn btn-info custombtn" title="Featured Song">
								{result.featured === '1' ? (
								  <i className="fa fa-star" aria-hidden="true"></i>
								) : (
								  <i className="fa fa-star-o" aria-hidden="true"></i>
								)}
							</Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
			   <audio ref={this.audioRefs} src={this.state.currentSong.songfile}></audio>
            </div>
          </div>
        </div>
		<PlayListModal songId={this.state.csongid} show={this.state.showModal} handleModal={this.handleModal} />
        <div id="myModal" className="modal fade imgmodal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <button type="button" className="btn btn-default closeicon" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-body">
                <img className="showimage img-responsive" src={this.state.urlvalinitial} />
              </div>
            </div>
          </div>
        </div>
        <div id="upGradModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header" style={{'padding':'5px 20px'}}>
						<h5 class="modal-title">Featured Song</h5>
						<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
					</div>
					<form className="artist-form" onSubmit={this.onSubmit.bind(this)}>
						<div className="container">
							<div class="modal-body">
							{/*(this.state.is_featured == '1')?*/}
							<>
								<div className="row">
									<div className="col-md-12">
										<h5 class="modal-title" style={{'fontSize':'17px'}}>Purchased Plan</h5>
										<div className="table-responsive no-padding">
										<table className="table table-striped table-bordered table-sm row-border hover tbl-padding-tr">
											<thead>
											<tr>
												<th>Plan</th>
												<th>Start Date & Time</th>
												<th>End Date & Time</th>
												<th>Status</th>												
											</tr>
											</thead>
											<tbody>
											{(this.state.featuredData.length)?this.state.featuredData.map((result) => {
												return (<tr>
													<td>{result.paln}</td>
													<td>{result.start_from}</td>
													<td>{result.end_to}</td>
													<td>{result.status}</td>
												</tr>)
											}):<tr><td colspan="4" style={{'textAlign':'center'}}>No plan available!</td></tr>}
											</tbody>										
										</table>
										</div>
									</div>
								</div><hr style={{'width':'50%'}}/></>{/*:""*/}									
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label>Plan<span className="requiredastrik">*</span></label>
											<select className="form-control" value={this.state.sub_plan} onChange={this.onChangePlan.bind(this)}>
												<option value="">--Select Plan--</option>
												{this.state.planlist.map((result) => {
												return (<option value={result.id}>{result.plan} [ ${result.amount} ]</option>)
												})}
											</select>
											<font className="errorMsg">{this.state.errors['plan_error']}</font>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label>Credit Card<span className="requiredastrik">*</span></label>
											<div className="creditcardsection">
												<CardElement options={{ hidePostalCode: true }}  onChange={(element) => this.stripeElementChange(element, 'card_number')} />
											</div>
											<font className="errorMsg">{this.state.cardError}</font>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<font style={{'position':'absolute','left':'15px','fontSize':'11px'}}>Note:- {this.state.stripe_text}</font>
							<button type="submit" class="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
						</div>
					</form>							
				</div>
			</div>
		</div>
        {(this.state.musiclist).length ? 
		<section className="" >
		
			<MusicPlayer
				setIsPlaying={this.setIsPlaying}
				audioRef={this.audioRef}
				currentSong={currentSong}
				songInfo={this.state.songInfo}
				isPlaying={this.state.isPlaying}
				setSongInfo={this.setSongInfo}
				musiclist={this.state.musiclist}
				setCurrentSong={this.setCurrentSong}
				setMute={this.setMute}
				index={this.state.index}
				mute={this.state.mute}
				likeSongFu={this.likeSongFu}
				_favoriteSong={this._favoriteSong}
				likeSongStatus = {(this.state.likeStatus)?this.state.likeStatus:currentSong.likestatus}
				is_favorite = {(this.state.is_favorite)?this.state.is_favorite:currentSong.is_favorite}
			/>
			<audio onLoadedMetadata={this.timeUpdateHandler} onTimeUpdate={this.timeUpdateHandler} ref={this.audioRef} src={currentSong.audio}></audio>
		
		</section>:""}
      </React.Fragment>
    );
  }
}
export default function InjectedEventBooking({ match }) {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <MySongs match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}