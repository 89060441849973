import React from "react";
import { withRouter, Redirect, Link, NavLink } from 'react-router-dom';
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import InputMask from 'react-input-mask';
import DOMPurify from "dompurify";
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}

class ChangeCheckoutForm extends React.Component {

	constructor(props) {
		super(props);
		
		
		this.state = {
			email: '',
			planid: '',
			error: null,
			msg: null,
			emailmsg: null,
			loader: false,
			payment: false,
			usertype: null,
			usertoken:ls.get('token'),
			successclass: 'hideclass',
			redirect: false,
			submitbuttontext: 'Submit',
			cardData:'',
			enablesubmitbutton: false
		}
	}
	componentDidMount(){
		let currentLocation = window.location.pathname;
		if(currentLocation == '/register/listener'){
			var utype = 'listener';
			var role = '1';
		}

		if(currentLocation == '/register/artist'){
			var utype = 'artist';
			var role = '2';
		}
		this.setState({role : role});
		this.getusersdata();
		this.getuserssubsdata();
		this.getSavedCard();
		
	}
	
	
	getusersdata = () => {
		
		const obj = {
			userid:ls.get('userid')
		}
		this.setState({
			role:ls.get('role')
		});
		//console.log(obj);
		Axios.post(base_api_url+'userbytoken.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.listenerdata	
					this.setState({
						email:returndata.email,
						loading:false
					});
				}
			}
		);
	}

	getSavedCard = () => {
		this.setState({
			loader:true
		});
		const obj = {
			user_id:ls.get('userid')
		}
		Axios.post(base_api_url+'get-card.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						cardData: res.data.res_data,
						loader: false
					});
					
				}
			}
		);
	}
	
	getuserssubsdata = () => {
		const obj = {
			userid:ls.get('userid')
		}
		this.setState({
			role:ls.get('role')
		});
		//console.log(obj);
		Axios.post(base_api_url+'getsubscription.php',obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					const subsdata = res.data.user_data	
					this.setState({
						planid:subsdata.subscription_id,
						loading:false
					});
				}
			}
		);
	}
	
	handleSubmit = async event => {
		event.preventDefault();
		const { stripe, elements } = this.props;
		if (!stripe || !elements) {
			return;
		}
		const card 		= elements.getElement(CardElement);
		const result 	= await stripe.createToken(card);
		if (!result.error) {
			this.stripeTokenHandler(result.token,this.state.email,this.state.planid);
		}
	};
	
	stripeElementChange = (element, name) => {
		this.setState({
			cardError:'',
			formValidation: true
		});
		if (element.complete) {
			this.setState({
				enablesubmitbutton:true
			});
		} else {
			if(element.error){
				if(element.error.code){
					this.setState({
						enablesubmitbutton:false,
						formValidation: false,
						cardError: element.error.message
					});
				}
			}
			
		}
	}
	
	stripeTokenHandler = (token,email,planid) => {
		this.setState({
			loader:true
		});
		const paymentData = { token: token.id, email: email, membership: planid,uname:ls.get('name')};
		Axios.post(base_api_url + 'changestripepay.php', paymentData, { axiosConfig })
			.then(
				(res) => {console.log(res.data);
					if (res.data.res_status == 200) {
						this.setState({
							loader:false,
							redirect: false,
							successclass: 'successmsg',
							submitbuttontext : 'Submit',
							msg: res.data.res_message
						});
					} else {
						window.scrollTo(0, 0);
						this.setState({
							loader:false,
							redirect: false,
							successclass: 'errormsg',
							submitbuttontext : 'Submit',
							msg: res.data.res_message
						})
					}
					this.getSavedCard();
					setTimeout(() => {
						this.setState({
						  successclass:'hideclass',
						  submitbuttontext : 'Submit',
						  msg:res.data.res_message
						});
						if(res.data.res_status == 200){
							this.setState({
								redirect: true,
							});
						}else{
							this.setState({
								redirect: false,
							});
							
						}
					  }, 10000)
				}
			);
			// this.myFormRef.reset();
	}

	render() {
		if(this.state.loader==true){
            return <Spinner />;
        }
		if (this.state.redirect==true) {
			let redurl = '/edit-artist-profile/'+this.state.usertoken;
			return <Redirect to={redurl} />
		}else{
		return (

			<React.Fragment>
				<div className={this.state.successclass}>{this.state.msg}</div>		
				<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform kysccards">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">Existing Card Details</h3>
								<div className="box-tools">
									<NavLink to={'/edit-artist-profile/'+this.state.usertoken} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>
								</div>
							</div>
							<div className="box-body">
				                <div className="row">
				                  <div className="col-md-6 subscriptionbtnactive">
				                    <p>
				                    {(this.state.cardData)? <>
				                      <span className="subsdetail"><strong>Card Number </strong></span>: XXXX XXXX XXXX {this.state.cardData.last4}
				                      <br />
				                      <span className="subsdetail"><strong>Valid Through </strong></span>: {(this.state.cardData.exp_month < 9)?'0'+this.state.cardData.exp_month:this.state.cardData.exp_month}/{this.state.cardData.exp_year}
				                      <br />
				                      <span className="subsdetail"><strong>Card Type </strong></span>: {this.state.cardData.brand}</>
				                      :<span className="subsdetail"><strong>Sorry! Your CC information deleted by Admin. </strong></span>}
				                    </p>
				                  </div>
				                </div>
				              </div>
				              </div>
					          </div>
					        </div>
					        <div className="row">
							<div className="col-md-12">
							  <div className="box box-success artisit-profileform">
							<div className="box-body no-padding">
								<form ref={(el) => this.myFormRef = el} onSubmit={this.handleSubmit}>
									<div className="row">
										<div className="col-md-12">
										  <div className="sectionhead">
											<div className="headsec">
											  <h3>Change your card information</h3>
											</div>
										  </div>
										</div>
									  </div>
									<div className="row">
										<div className="col-md-12">													
											<div className="form-group">
												<label>Card Details</label>
												<div className="creditcardsection"><CardElement options={{ hidePostalCode: true }} onChange={(element) => this.stripeElementChange(element, 'card_number')}/></div>
												<div className="errorMsg">{this.state.cardError}</div>
											</div>
											<div className="btn-toolbar-inner form-group">
												<div className="btn-group">
													<button className="btn"><i className="fa fa-check" aria-hidden="true"></i>{this.state.submitbuttontext} </button>
												</div>
											</div>													
										</div>
									</div>										
								</form>
							</div>
						</div>
					</div>
				</div>	
			</React.Fragment>
		);
		}
	}
}

export default function InjectedChangeCheckoutForm() {
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<ChangeCheckoutForm stripe={stripe} elements={elements} />
			)}
		</ElementsConsumer>
	);
}