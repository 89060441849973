import React, { Component } from 'react';
import Axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import DOMPurify from 'dompurify';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';

const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
export default class Followers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      follower: [],
      token: '',
      error: null,
      msg: null,
      loading: true,
      redirect: false
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - My Followers';

    const email = ls.get('email');
    const name = ls.get('name');
    const token = ls.get('token');
    const userid = ls.get('userid');

    this.setState({
      email: email,
      name: name,
      token: token,
      userid: userid
    });

    this.getAllFollowers();
  }

  componentDidUpdate() {
    $('#myfollowers').DataTable({
      destroy: true,
	  stateSave: true,
	  language: {
		search: "Search"
	  },
      lengthMenu: [
        [5, 10, 15, -1],
        [5, 10, 15, 'All']
      ],
      "columnDefs": [
        { "targets": 0, "width": "90"},
        { orderable: false, targets: 0 }
      ],
    });
  }

  getAllFollowers = () => {
    const obj = {
      artistid: ls.get('userid')
    };

    Axios.post(base_api_url + 'followers.php', obj).then((res) => {
      console.log(res.data);
      if (res.data.res_status == 200) {
        this.setState({
          follower: res.data.res_data,
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
    });
  };

  render() {
    if (this.state.loading == true) {
      return <Spinner />;
    }
    let id = this.props.match.params.id;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">My Followers</h3>
              </div>
              <div className="box-body table-responsive  no-padding">
                <table id="myfollowers" className="table table-striped table-bordered table-sm row-border hover mb-5">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Follower Name</th>
                      <th>Email</th>
                      <th>Following Since</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.follower.map((result) => {
                      return (
                      <>
                      {!isNaN(id)?
                      ((id == result.id)?
                        <tr>
                          <td><img src={result.thumb_profile_pic} className="img-responsive " onError={this.addDefaultSrc} style={{'width':'80px','height':'80px'}}/></td>
                          <td>{result.name}</td>
                          <td>{result.email}</td>
                          <td>{result.date}</td>
                        </tr>:""):""}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
