import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import MySongPlays from '../components/mysongplay.component';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';

const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
export default class mySingleSong extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
		  error: null,
		  msg: null,
		  currentalbum:'',
		  songlist:[],
		  loading:true,
		  successclass:null,
		  redirect:false,
		  albumsid:this.props.match.params.id
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Song Page";		
		this.getallsonglist();
		
	}
	 
	getallsonglist = () => {
		const obj = {
			song_id: this.props.match.params.id
		}
		
		Axios.post(base_api_url+'topsimilarsongs.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data;
					this.setState({
						songlist:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
		
	};
	
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	
	render() {		
        if(this.state.loading==true){
			return <Spinner />;
		}
		return (
			<React.Fragment>	
				<MySongPlays musiclist={this.state.songlist} alid={this.state.albumsid}/>
			</React.Fragment>
		)
	}
}