import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import Spinner from '../../../includes/Spinner.component';
import $ from 'jquery'; 
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const regex = /^[a-z\d\,-_\s]+$/i;
const errors = {};
export default class EventCategory extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	catlist:[],
      	errors:[],
		c_name: '',
      	btnSubmitText: 'Submit',
      	disabled:false
      }
    }
    catListData(){
		const obj= {catid:'0'}
	    Axios.post(API_URL + 'mastercatlist.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		catlist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						catlist: res.data.res_data,
						loader: false
					});
		        }
		      	$(document).ready(function () {
		    		$('#catlist').DataTable().destroy();
			        setTimeout(function(){
			        $('#catlist').DataTable({
							"destroy": true,
							"stateSave": true,
							"lengthMenu": [[10, 15, 20 -1], [10, 15, 20,  "All"]],
							"language": {
								"search": "Search"
							},
							columnDefs: [
			   				{ orderable: false, targets: -1 }
						],
						dom: 'Bfrtip',
						buttons: [
				            {
				                extend: 'csv',
				                exportOptions: {
				                    columns: [ 0,1]
				                }
				            },
				            {
				                extend: 'print',
				                exportOptions: {
				                    columns: [ 0,1]
				                },
				                customize: function ( win ) {
								$(win.document.body).find('table').addClass('display').css('font-size', '15px');
								$(win.document.body).find('table').css('table-layout','auto');
								$(win.document.body).find('th').css('width','15%');
								$(win.document.body).find('td').css('width','15%');
								}
				            }
				        ]
			        });
			        } ,50);
			    });	        	   
	    	}

	    );
    }
    componentDidMount(){
		document.title = "Master - Event Category";
    	this.catListData();
    }
	
    editISRC = (cat_id) => {
		if(this.state.errors['nameerror']){delete this.state.errors['nameerror'];}
    	this.setState({
			loader:true,
			isrcid:cat_id,
			codeerror:''
		})
		const obj = {
			catid: cat_id
		}
		Axios.post(API_URL + 'mastercatlist.php', obj, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.catListData();
					let currentc = res.data.res_data[0];
					this.setState({
						c_name: currentc.cat_name,
						oldc_name: currentc.cat_name,
						btnSubmitText: 'Update',
						loader:false,
						method:'update'
					});

				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Internal Server Error, please try again!',
						loader:false
					})
				}
			}
		);
	}
	deleteISRC(id){
    	if(window.confirm("Are you sure you want to delete the category?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deletecat.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message
						})
					}
					this.catListData();
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
	
	onChangeCategory(e) {
		let value 	= e.target.value;
		value.trim();
		errors["nameerror"]='';
		if(value.trim()){
			if(this.state.errors['nameerror']){delete this.state.errors['nameerror'];}
			let resdata = regex.test(value);
			if(resdata){
				this.setState({c_name:value, errors : errors});
			}else{
				errors["nameerror"] = "Special characters not allowed";
				this.setState({c_name: '', errors : errors})
			}
		}else{
			this.setState({c_name:'', errors : errors});
		}
	}	
	onFormSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
				loader:true,
				disabled:true,
				btnSubmitText:'Please Wait...'
			});
			if(this.state.method === 'update'){
				this.updateTickets();
			}else{
				this.saveTickets();
			}
		}
	}
	saveTickets(){
		let objs = {
			'category_name' : this.state.c_name
		}
		Axios.post(API_URL + 'save_event_category.php', objs, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						c_name: '',
						btnSubmitText: 'Submit',
						disabled:false,
						loader:false,
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						btnSubmitText:'Submit',
						disabled:false,
						loader:false,
						msg: res.data.res_message								
					})
				}
				this.catListData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	updateTickets(){
		const formData = new FormData();
		formData.append("cat_id", this.state.isrcid);
		formData.append("c_name", this.state.c_name);
		Axios.post(API_URL + 'update-event-cat.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						c_name: '',
						btnSubmitText: 'Submit',
						disabled:false,
						method:'',
						loader:false
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						msg: res.data.res_message,
						btnSubmitText:'Update',
						disabled:false,
						loader:false,
						method:'update'
					})
				}
				this.catListData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	handleValidation = () => {
		let formIsValid = true;		
		if(!this.state.c_name){
			formIsValid = false;
			errors["nameerror"] = "Please enter category name.";
		}
		this.setState({errors: errors});		
        return formIsValid;
	
	}
	changeStatus(id,status){
    	this.setState({
    		loader:true
    	});
    	const obj = {
			id:id,
			status:status
		}			
		Axios.post(API_URL+'updatecatstatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.catListData();
					this.setState({
						successclass:'successmsg',
						msg:res.data.res_message,
					});
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:res.data.res_message
					})
				}
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 10000)
			}
		);
    }
	
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
    			<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">Event Category</h3>            
							</div>
							<div className="box-body no-padding"> 
								<div className="nav-tabs-custom">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onFormSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>Event Category Information</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>Category Name<span className="requiredastrik">*</span></label>
															<input type="text" className="form-control" name="c_name" value={this.state.c_name} onChange={this.onChangeCategory.bind(this)} maxLength="30" />
															<font className="errorMsg">{this.state.errors["nameerror"]}</font>
														</div>
													</div>
													<div className="col-md-6 mt-4">
														<button type="submit" style={{'padding':'5px 16px'}} disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>           
							</div>
							<hr />
							<div className="box-body no-padding">
								<div className="table-responsive">
									<table id="catlist" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Title</th>
												<th>Status</th>
												<th className="actionbtnsets" style={{textAlign:'center'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.catlist.map((result) => {
												return (
													<tr>
														<td>{result.cat_name}</td>
														<td><span className={(result.status==='1')?"label label-success":"label label-danger"}>{(result.status==='1')?"Active":"In Active"}</span></td>
														<td className="actionbtns" style={{textAlign:'center'}}><Link to="#" title="Edit Category" onClick={() => this.editISRC(result.id)} className="btn btn-info custombtn"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;<Link to="#"  onClick={() => this.changeStatus(result.id,result.status)} title={(result.status === '1' )?'In Active':'Active'} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
														<Link to="#" title="Delete Category" onClick={() => this.deleteISRC(result.id)} className="btn btn-info custombtn"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>        
				</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}