import React, { Component } from 'react';
import { Link, NavLink, Redirect, withRouter } from 'react-router-dom';
import Axios from 'axios';
import ls from 'local-storage';
import { API_URL } from '../config/constants.js';
const base_api_url = API_URL;
class FreeSideMenu extends Component {
    constructor(props){
		super(props);
		
		this.state = {
		  email:'',
		  role:null,
		  token:'',
		  error: null,
		  msg: null,
		  redirect:false
		}
	}
	
	componentDidMount(){
		const email = ls.get('email');
		const name = ls.get('name');
		const token = ls.get('token');
		const userid = ls.get('userid');
		this.setState({
			email:email,
			name:name,
			token:token,
			userid:userid,
            membership_status: ls.get('membership_status')
		});
	}	
	onclicklogut = (logouttype) => {
		let currenttoken = ls.get('token');
		let currentemail = ls.get('email');
		let obj = [];
		if(logouttype === 'all'){
			obj = {
			  type: logouttype,
			  email: currentemail,
			};
		}else{
			obj = {
				type: logouttype,
				token: currenttoken,
			};

		}
		const axiosConfig = {
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*'
		};
		Axios.post(base_api_url + 'deleteloginattempt.php', obj, { axiosConfig }).then((res) => {
		  if (res.data.res_status == 200) {
			ls.remove('userid');
			ls.remove('email');
			ls.remove('name');
			ls.remove('role');
			ls.remove('token');
			ls.remove('membership');
			ls.remove('membership_status');
			ls.remove('profile');
      ls.remove('selectedtab');
      ls.remove('localCurrentSong');
      ls.remove('localMusicList');
      ls.remove('localIndex');
			this.props.history.push('/login');
		  }
		});
  };
    render() {
    	let profileUrl = '';
    	let dashboardURL = ''
    	if (Number(ls.get('role')) === 2) {
    		  dashboardURL = '/artist-dashboard';
		      profileUrl = '/artist-profile/' + this.state.token;
		    } else {
		      dashboardURL = '/listener-dashboard';
		      profileUrl = '/listener-profile/' + this.state.token;
		    }
        return (<React.Fragment>
            <div className="main-sidemenu" id="sidebar">
                <div className="logo-menu">
                    <a href="#" class="">
                        <img src={process.env.PUBLIC_URL + '/assets/logo/logo.png'} alt="Conqer" />
                    </a>
                </div>
                <div className="menuitems">
                    <ul className="mainmenutims">
                        <li><NavLink to={dashboardURL}><i className="fa fa-dashboard"></i> Dashboard</NavLink></li>
						<li><NavLink to={profileUrl}><i className="fa fa-user"></i> Profile</NavLink></li>
                        <li><a href="javascript:void(0)" onClick={() => this.onclicklogut(ls.get('role'))}><i className="fa fa-sign-out"></i> Logout</a></li>
                    </ul>
                </div>
            </div>
        </React.Fragment>)
    }
}
export default withRouter(FreeSideMenu);