import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import Spinner from '../../../includes/Spinner.component';
import $ from 'jquery'; 
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const errors = {};
export default class Notifications extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	notificationlist:[],
      	errors:[],
		btndisable:'',
		c_amount: '',
		typeerror:'',
		amountlabel:'Amount',
		nid:'',
      	btnSubmitText: 'Submit',
      	disabled:false
      }
    }
    notificationListData(){
    	const eventid = this.props.match.params.id;
		const obj= {}
	    Axios.post(API_URL + 'getnotifications.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		notificationlist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						loader: false
					});
		        }
		      	$(document).ready(function () {
		    		$('#notificationList').DataTable().destroy();
			        setTimeout(function(){
			        $('#notificationList').DataTable({
							"destroy": true,
							"stateSave": true,
							"lengthMenu": [[5, 10, 15 -1], [5, 10, 15,  "All"]],
							"language": {
								"search": "Search"
							},
							columnDefs: [
			   				{ orderable: false, targets: -1 }
						],
						dom: 'Bfrtip',
						buttons: [
				            {
				                extend: 'csv',
				                exportOptions: {
				                    columns: [ 0,1,2,3 ]
				                }
				            },
				            {
				                extend: 'print',
				                exportOptions: {
				                    columns: [ 0,1,2,3 ]
				                },
				                customize: function ( win ) {
								$(win.document.body).find('table').addClass('display').css('font-size', '15px');
								$(win.document.body).find('table').css('table-layout','auto');
								$(win.document.body).find('th').css('width','15%');
								$(win.document.body).find('td').css('width','15%');
								}
				            }
				        ]
			        });
			        } ,50);
			    });	        	   
	    	}

	    );
    }
    componentDidMount(){
		document.title = "Master - Notification";
    	this.notificationListData();
    	/*$(document).ready(function () {
    		$('#notificationList').DataTable().destroy();
	        setTimeout(function(){
	        $('#notificationList').DataTable({
					"destroy": true,
					"stateSave": true,
					"lengthMenu": [[5, 10, 15 -1], [5, 10, 15,  "All"]],
					"language": {
						"search": "Search"
					},
					columnDefs: [
	   				{ orderable: false, targets: -1 }
				]
	        });
	        } ,50);
	    });*/ 
    }
	
    editNotification = (n_id) => {
		if(this.state.errors['typeerror']){delete this.state.errors['typeerror'];}
		if(this.state.errors['titleerror']){delete this.state.errors['titleerror'];}
		if(this.state.errors['descerror']){delete this.state.errors['descerror'];}
    	this.setState({
			loader:true,
			nid:n_id,
			titleerror:''
		})
		const obj = {
			id: n_id
		}
		Axios.post(API_URL + 'getnotifications.php', obj, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.notificationListData();
					let currentc = res.data.res_data[0];
					this.setState({
						title: currentc.title,
						type: currentc.type,
						description: currentc.description,
						btnSubmitText: 'Update',
						loader:false,
						method:'update'
					});

				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Internal Server Error, please try again!',
						loader:false
					})
				}
			}
		);
	}
	deleteNotifications(id){
    	if(window.confirm("Are you sure you want to delete?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deletenotification.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.notificationListData();
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
    onChangeType(e) {
		if(this.state.errors['typeerror']){delete this.state.errors['typeerror'];}
		let typedata;
		if(e.target.value == this.state.type){
			typedata = false;
		}else{
			typedata = true;
		}
		if(typedata){
			this.setState({
				type: e.target.value,
				typeerror:''
			})
			const obj = {
				type: e.target.value
			}
			Axios.post(API_URL + 'notification_exist.php', obj, { axiosConfig })
			.then(
				(res) => {
					if (res.data.res_status == 200) {
					} else {
						this.setState({
							typeerror:res.data.res_message
						})
					}
				}
			);
		}else{
			this.setState({
				type: e.target.value,
				typeerror:''
			})
		}
	}
	onChangeTitle(e) {
		if(this.state.errors['titleerror']){delete this.state.errors['titleerror'];}
		this.setState({
			title: e.target.value
		})
	}

	onChangeDesc(e) {
		if(this.state.errors['descerror']){delete this.state.errors['descerror'];}
		this.setState({
			description: e.target.value
		})
	}

	
	onCommissionSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
				loader:true,
				disabled:true,
				btnSubmitText:'Please Wait...'
			});
			if(this.state.method === 'update'){
				this.updateTickets();
			}else{
				this.saveTickets();
			}
		}
	}
	saveTickets(){
		const formData = new FormData();
		formData.append("type", this.state.type);
		formData.append("title", this.state.title);
		formData.append("description", this.state.description);
		Axios.post(API_URL + 'master_notification.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						title: '',
						type: '',
						btnSubmitText: 'Submit',
						disabled:false,
						description: '',
						loader:false,
					});
					this.notificationListData();
				} else {
					this.setState({
						successclass: 'errormsg',
						btnSubmitText:'Submit',
						disabled:false,
						loader:false,
						msg: res.data.res_message								
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	updateTickets(){
		const formData = new FormData();
		formData.append("n_id", this.state.nid);
		formData.append("type", this.state.type);
		formData.append("title", this.state.title);
		formData.append("description", this.state.description);
		Axios.post(API_URL + 'update-notification.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						title: '',
						type: '',
						description: '',
						btnSubmitText: 'Submit',
						disabled:false,
						method:'',
						loader:false
					});
					this.notificationListData();
				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Notification not updated , please try again!',
						btnSubmitText:'Update',
						disabled:false,
						loader:false,
						method:'update'
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	handleValidation = () => {
		let formIsValid = true;		
		if(!this.state.type){
			formIsValid = false;
			errors["typeerror"] = "Please select type.";
		}
		if(!this.state.title){
			formIsValid = false;
			errors["titleerror"] = "Please enter title.";
		}
		if(!this.state.description){
			formIsValid = false;
			errors["descerror"] = "Please enter description.";
		}		
		this.setState({errors: errors});		
        return formIsValid;
	
	}
	changeStatus(id,status){
    	this.setState({
    		loader:true
    	});
    	const obj = {
			id:id,
			status:status
		}			
		Axios.post(API_URL+'updatenotificationstatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.notificationListData();
					this.setState({
						successclass:'successmsg',
						msg:(status === '1')?'Notification inactivated successfully.':'Notification activated successfully.',
					});
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:'Notification status not updated, please try again!'
					})
				}
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 10000)
			}
		);
    }
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
    			<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">Notifications</h3>            
							</div>
							<div className="box-body no-padding"> 
								<div className="nav-tabs-custom">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onCommissionSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>Notification Information</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>Type<span className="requiredastrik">*</span></label>
															<select className="custom-select" name="type"  onChange={this.onChangeType.bind(this)} value={this.state.type}>
																<option value="0">Select Type</option>
																<option value="album" selected={(this.state.type=='album')?'selected':''}>Album</option>
																<option value="event" selected={(this.state.type=='event')?'selected':''}>Event</option>
																<option value="following" selected={(this.state.type=='following')?'selected':''}>Following</option>
																<option value="isrc_exist" selected={(this.state.type=='isrc_exist')?'selected':''}>ISRC Exist</option>
																<option value="song" selected={(this.state.type=='song')?'selected':''}>Song</option>
																<option value="song_approve_status" selected={(this.state.type=='song_approve_status')?'selected':''}>Song Approve Status</option>
																<option value="ticket_booking" selected={(this.state.type=='ticket_booking')?'selected':''}>Ticket Booking</option>
																<option value="user_welcome" selected={(this.state.type=='user_welcome')?'selected':''}>User Welcome</option>
																<option value="plan_renewal" selected={(this.state.type=='plan_renewal')?'selected':''}>Plan Renewal</option>
															</select>
															<font className="errorMsg">{this.state.errors["typeerror"]}</font>
															<font className="errorMsg">{this.state.typeerror}</font>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label>Title<span className="requiredastrik">*</span></label>
															<input type="text" placeholder={this.state.title} className="form-control" name="c_amount" value={this.state.title} onChange={this.onChangeTitle.bind(this)} />
															<font className="errorMsg">{this.state.errors["titleerror"]}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label>Description<span className="requiredastrik">*</span></label>
															<textarea className="form-control rounded-0" placeholder="Description" onChange={this.onChangeDesc.bind(this)} rows="3" value={this.state.description}></textarea>
															<font className="errorMsg">{this.state.errors["descerror"]}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="constantDef">
															<p>Available Variables: {'{USER_NAME}'}, {'{EVENT_NAME}'}, {'{TICKET_NAME}'}, {'{TICKET_TYPE}'}, {'{NO_OF_TICKETS}'}, {'{ALBUM_TITLE}'}, {'{SONG_TITLE}'}, {'{FOLLOW_STATUS}'}, {'{ISRC_USED_SONG_TITLE}'}, {'{PLAN_NAME}'}, {'{EXPIRY_DATE}'}, {'{APPROVE_STUATUS_TEXT}'}</p>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 notmainbtn">
														<button type="submit" disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>           
							</div>
							<hr />
							<div className="box-body no-padding">
								<div className="table-responsive">
									<table id="notificationList" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Type</th>
												<th>Title</th>
												<th>Description</th>
												<th>Status</th>
												<th className="actionbtnsets" style={{textAlign:'center'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.notificationlist.map((result) => {
												return (
													<tr>
														<td>{result.type}</td>
														<td>{result.title}</td>
														<td>{result.description}</td>
														<td><span className={(result.status==='1')?"label label-success":"label label-danger"}>{result.status_text}</span></td>
														<td className="actionbtns" style={{textAlign:'center'}}><Link to="#" title="Edit Notification" onClick={() => this.editNotification(result.id)} className="btn btn-info custombtn"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;<Link to="#"  onClick={() => this.changeStatus(result.id,result.status)} title={(result.status === '1' )?'In Active':'Active'} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
														<Link to="#" title="Delete Notification" onClick={() => this.deleteNotifications(result.id)} className="btn btn-info custombtn"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>        
				</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}