import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../config/constants.js';
import ls from 'local-storage';
import  { Link, NavLink } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import $ from 'jquery'; 
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
toast.configure();
export default class Index extends Component {
	constructor(props) {
    	super(props);
    	this.state = {
    		listData:[]
    	}
    }
    componentDidMount() {
	  	document.title = "CONQER Music - Celebrity";
		this.getCelebrityList();
	}
	getCelebrityList = () => {
		$('#celebrity').DataTable().destroy();
		Axios.post(base_api_url + 'celebrity-list.php',{axiosConfig}).then(
	        (res) => {
	          	if (res.data.res_status == 200) {
	          		const returndata = res.data.res_data;	
	            	this.setState({
	              		listData: returndata
	            	});
	          	} else {
	            	this.setState({
	              		listData: []
	            	});
	          	}	          	
	         	$('#celebrity').DataTable({
	          		"destroy": true,	
					"stateSave": true,
					language: {
						search: "Search"
					},
					columnDefs: [
	   					{ "orderable": false, "targets": -1, width: 300 }
					],
					dom: 'Bfrtip',
					buttons: [
		            	{
		                	extend: 'csv',
		                	exportOptions: {
			                    columns: [ 0,1,2,3,4,5 ]
			                }
		            	},
		            	{
		                extend: 'print',
		                exportOptions: {
		                    columns: [ 0,1,2,3,4,5 ]
		                }
		            	}
		        	]
	          	});
	        }
	    );
	}
	changeStatus = (id,status = '90') => {
		let msg ;
		if(status == '1'){
			msg = 'Are you sure you want to inactivate celebrity?';
		}else if(status == '0'){
			msg = 'Are you sure you want to activate celebrity?';
		}else if(status == '90'){
			msg = 'Are you sure you want to delete celebrity?';
		}
		if(window.confirm(msg)){
			const obj = {
				id:id,
				status: status
			}
			Axios.post(API_URL+'celebrity-status.php',obj,{axiosConfig})
			.then(
			(res) => {
				if(res.data.res_status==200){
					this.getCelebrityList();
					toast.success(res.data.res_message);
				}else{
					toast.error(res.data.res_message);
				}
			});
		}
	}
	render() {
		return(
		<React.Fragment>
    		<div className="dashboard-wrapper">
    			<AdminHeader />
    			<div className="maincontent-area">
    				<AdminTopHeader />
    				<div className="main-dashsection">
    					<section className="contentpard">
    						<div className="row">
								<div className="col-md-12">
									<div className="box box-success distributions-profileform">
										<div className="box-header with-border addprofilenew">
									  		<h3 className="box-title">Celebrity</h3>
									  		<div className="box-tools saearchicons">
												<NavLink to={'/admin/celebrity/add'} className="btn btn-primary"><i className="fa fa-plus" aria-hidden="true"></i> Add New</NavLink>
											</div>          
										</div>
										<div className="box-body no-padding">
									  		<div className="table-responsive">
									  			<table id="celebrity" className="table no-margin table-striped table-hover">
													<thead>
													  	<tr>
															<th>Name</th>
															{/*<th>Mobile</th>
															<th>Email</th>*/}
															<th style={{textAlign:'center'}}>Commission[$]</th>
															<th>Referral Code</th>
															<th>No. of Artist</th>
															<th>No. of Listener</th>
															<th>Status</th>
															<th style={{textAlign:'center'}}>Action</th>
													  	</tr>
													</thead>
													<tbody>
													{this.state.listData.map(result => {
														return (
															<tr>
																<td>{result.name}</td>
																{/*<td>{(result.phone)?`(${result.phone.substring(0, 3)}) ${result.phone.substring(3, 6)}-${result.phone.substring(6,result.phone.length)}`:'-'}</td>
																<td>{result.email}</td>*/}
																<td style={{textAlign:'center'}}>{result.total_commission}</td>
																<td>{result.referral_code}</td>
																<td title="Artist List"><Link to={"/admin/celebrity/user-list/artist/"+result.id}>{result.no_of_artist}</Link></td>
																<td title="Listener List"><Link to={"/admin/celebrity/user-list/listener/"+result.id}>{result.no_of_listener}</Link></td>
																<td><span className={(result.status)?'label label-success':'label label-danger'}>{result.status_txt}</span></td>
																<td className="actionbtns" style={{textAlign:'center'}}>
																<Link to={"/admin/celebrity/edit/"+result.id} className="btn btn-info custombtn" title="Edit Celebrity">
																	<i className="fa fa-pencil" aria-hidden="true"></i>
																</Link>&nbsp;
																<Link to="#"  onClick={() => this.changeStatus(result.id,result.status)} title={(result.status == 1)?'Inactive':'Active'} className="btn btn-info custombtn">
																	{(result.status)?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}
																</Link>&nbsp;
																<Link to="#" onClick={() => this.changeStatus(result.id)} className="btn btn-info custombtn"  title="Delete Celebrity"><i className="fa fa-trash" aria-hidden="true"></i>
																</Link>&nbsp;
																<Link to={'/admin/celebrity/view/'+result.id} className="btn btn-info custombtn"  title="View Detail"><i className="fa fa-eye" aria-hidden="true"></i>
																</Link>
																</td>
															</tr>
														)}
														)
													}
													</tbody>
												</table>
									  		</div>
									  	</div>
									</div>
								</div>
							</div>
    					</section>
    				</div>
    			</div>
    		</div>
		</React.Fragment>);
	}
}