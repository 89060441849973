import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Image, NavLink, Table } from 'react-bootstrap';
import Axios from 'axios';
import MusicPlayer from './musicPlayer.component';
import { playAudio } from '../includes/playAudio.component';
import { API_URL } from '../config/constants.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import defaultimg from '../components/default.png';
import shortid from 'shortid';
import ls from 'local-storage';
const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
class ListenerSongPlay extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      musicList: this.props.musiclist,
      index: 0,
      songInfo: {
        currentTime: 0,
        duration: 0,
        animationPercentage: 0,
        volume: 0
      },
      isPlaying: false,
      mute: false,
      loading: '',
      currentalbum: '',
      currentSong: this.props.musiclist[0],
      likeStatus:'',
    };
  }

  changeAction = async () => {
    if (this.state.songInfo.currentTime === this.state.songInfo.duration) {
      this.setIsPlaying(!this.state.isPlaying);
    }
  };

  setMute = () => {
    this.setState(
      {
        mute: !this.state.mute
      },
      () => (this.audioRef.current.muted = this.state.mute)
    );
  };

  componentDidMount() {
    this.getalbumdata();
  }

  getalbumdata = () => {
    const objs = {
      id: this.props.alid
    };

    Axios.post(base_api_url + 'getalbum.php', objs, { axiosConfig }).then((result) => {
      if (Number(result.data.res_status) === 200) {
        let returndatas = result.data.res_data;
        // console.log('returndatas',returndatas);
        if (returndatas !== 'null') {
          this.setState({
            currentalbum: returndatas,
            loading: false
          });
        }
      } else {
        this.setState({
          loading: false
        });
      }
    });
  };

  timeUpdateHandler = (e) => {
    const current = e.target.currentTime;
    const duration = e.target.duration;
    let mList = (this.state.musiclist)?this.state.musiclist:this.props.musiclist;
    if (current === duration) {
      this.setState(
        {
          isPlaying: !this.state.isPlaying
        },
        () => {
          if(this.state.index < mList.length - 1 ){
            if (this.state.index === mList.length - 1) {
              this.audioPlayHandler(0);
            } else {
              this.audioPlayHandler(this.state.index + 1);
            }
          }
        }
      );
    }
    const roundedCurrent = Math.round(current);
    const roundedDuration = Math.round(duration);
    const percentage = Math.round((roundedCurrent / roundedDuration) * 100);
    this.setState({
      songInfo: {
        currentTime: current,
        duration: duration,
        animationPercentage: percentage,
        volume: e.target.volume
      }
    });
  };

  audioPlayHandler(key) {
    /*ls.remove('localIndex');
    ls.set('localIndex', key);*/

    ls.remove('localCurrentSong');
    ls.remove('localMusicList');
    ls.remove('localIndex');
    ls.set('localMusicList', this.props.musiclist);
    ls.set('localCurrentSong', this.props.musiclist[key]);
    ls.set('localIndex', key);
    let songList = ls.get('localCurrentSong');//this.props.musiclist[key];
    if(songList.token == undefined){
      songList.token = shortid.generate();
    }
    let newObj = {
      currentTime: 0,
      duration: this.props.musiclist[key].duration,
      animationPercentage: 0,
      volume: 0
    };

    this.setState(
      {
        index: key,
        currentSong: this.props.musiclist[key],
        isPlaying: true,
        songInfo: newObj
      },
      () => {
        //playAudio(this.state.isPlaying, this.audioRef);
        this.playAudioSong(this.state.isPlaying, this.audioRef, songList);
      }
    );    
    /* const objs = {
      user_id: ls.get('userid'),
      song_id: this.state.currentSong.song_id
    };
    Axios.post(base_api_url + 'songplayed.php', objs, { axiosConfig }).then((result) => {
      if (result.data.res_status == 200) {
        this.setState({
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
    }); */
  }
  playAudioSong = (isPlaying, audioRef, songList) => {
      if (isPlaying) {
        this.insertSongInfo(songList);
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    };
  insertSongInfo(song){
    const objs = {
        user_id: ls.get('userid'),
        song_id: song.song_id,
        played_time: '0',
        token: song.token
      };
    Axios.post(base_api_url + 'songplayed.php', objs, { axiosConfig }).then((result) => {
      if (result.data.res_status == 200) {
        this.setState({
          isCurrent: true
        });
        console.log('song data saved successfully');
      } 
    });
  }
  setIsPlaying = () => {
    this.setState({
      isPlaying: !this.state.isPlaying
    });
  };

  setCurrentSong = (ind) => {
    this.setState({
      currentSong: this.state.musicList[ind],
      index: ind
    });
  };

  setSongInfo = (parameter) => {
    this.setState({
      songInfo: parameter
    });
  };

  getTime = (timer) => {
    if (this.state.isPlaying) {
      return Math.floor(timer / 60) + ':' + ('0' + Math.floor(timer % 60)).slice(-2);
    } else {
      return Math.floor(timer);
    }
  };
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };
  static getDerivedStateFromProps(props, state) {
    return { musicList: props.musiclist };
  }

  render() {
    const { musicList, index, currentTime, pause } = this.state;
    const currentSong = this.state.musicList[index];//(ls.get('localMusicList'))?ls.get('localMusicList')[ls.get('localIndex')]:this.state.musicList[index];
    const __currentSong = (ls.get('localMusicList'))?ls.get('localMusicList')[(ls.get('localIndex'))?ls.get('localIndex'):index]:this.state.musicList[index];
    const { currentalbum } = this.state;
    let albumname = currentalbum.name;
    let albumsabout = currentalbum.about;
    let albumsuserid = currentalbum.user_id;
    let backurl = '/listener-assong/' + albumsuserid;
    return (
      <>
		<section className="contentpards">
			<div className="row topfivesongsd">
				<div className="col-md-12">
				  <div className="todpfive"> <span className="rightsidetext"><Link to='#' onClick={this.props.history.goBack} className="btn btn-primary" style={{ height: '35px' }}>
				  <i className="fa fa-angle-double-left" aria-hidden="true"></i> Back
				</Link></span></div>
				</div>
			  </div>
			  
			  <div className="row artisitblock allartist">
				<div className="col-md-3">
				  <div className="box box-info">            
					<div className="box-body">
					  <div className="profile-art"><Image onError={this.addDefaultSrc} fluid src={currentalbum.banner_image} fluid /></div>
					  <div className="profileartinfo">
						<span className="artname">{albumname !== 'null' ? albumname : ''}</span>                
					  </div>
					</div>
				  </div>
				</div>  
				<div className="col-md-9">
				  <div className="artist-profileinfo mb-3">
					<div className="bestartistname">         
					  <h3>{albumname !== 'null' ? albumname : ''}</h3>
					  <p>{albumsabout !== 'null' ? albumsabout : ''}</p>
					</div>
				  </div>

				</div>              
			  </div> 
			<div className="box box-success distributions-profileform">
			  <div className="box-header with-border addprofilenew">
				<h3 className="box-title">Album Songs</h3>
			  </div>
			  <div className="box-body table-responsive no-padding">
				<div style={{ overflow: 'auto', cursor: 'pointer', backgroundColor: 'white', maxHeight: '50vh' }}>
				  <Table responsive>
					<thead>
					  <tr className="">
						<th> </th>
						<th>Title</th>
						<th>Artist</th>
						<th style={{textAlign: 'center'}}>Duration</th>
					  </tr>
					</thead>
					<tbody>
					  {musicList.map((music, key) => {
						return (
						  <React.Fragment key={key}>
							<tr
							  style={this.state.isPlaying ? (this.state.index == key ? { backgroundColor: '#FDEAB0' } : {}) : {}}
							  onClick={() => this.audioPlayHandler(Number(key))}
							>
							  <td className="">
								<i
								  style={{ fontSize: '1.5rem', color: '#b8a25e', width: '100%' }}
								  className=" d-flex  justify-content-center fa fa-play-circle-o"
								  aria-hidden="true"
								></i>
							  </td>

							  <td className="artist-wlecome">
								<div className="artistitleimg"><Image
								  onError={this.addDefaultSrc}
								  src={music.img}
								  rounded
								/>
								<span className="songtitletable">{'     ' + music.name}</span>
								</div>
							  </td>
							  <td className="">{music.author ? music.author : ''}</td>
							  <td style={{textAlign: 'center'}}>{music.duration}</td>
							</tr>
						  </React.Fragment>
						);
					  })}
					</tbody>
				  </Table>
				</div>
			  </div>
			</div>
		</section>
		<section className="playerimplement d-block">
			{(this.state.musicList.length > 0)?
				<>
				<MusicPlayer
					setIsPlaying={this.setIsPlaying}
					audioRef={this.audioRef}
					currentSong={(ls.get('localMusicList') && ls.get('localIndex'))?ls.get('localMusicList')[ls.get('localIndex')]:__currentSong}
					songInfo={this.state.songInfo}
					isPlaying={this.state.isPlaying}
					setSongInfo={this.setSongInfo}
					/*musiclist={this.state.musicList}*/
          musiclist={(ls.get('localMusicList'))?ls.get('localMusicList'):this.state.musicList}
					setCurrentSong={this.setCurrentSong}
					setMute={this.setMute}
					/*index={this.state.index}*/
          index={(ls.get('localIndex'))?ls.get('localIndex'):this.state.index}
					mute={this.state.mute}
					likeSongFu={this.props.likeSong}
          _favoriteSong={this.props._favoriteSong}
					likeSongStatus = {(this.state.likeStatus)?this.state.likeStatus:__currentSong.likestatus}
          is_favorite = {(this.state.is_favorite)?this.state.is_favorite:__currentSong.is_favorite}
				/>

				<audio onLoadedMetadata={this.timeUpdateHandler} onTimeUpdate={this.timeUpdateHandler} ref={this.audioRef} src={__currentSong.audio}></audio>
				< />
			:''}
		</section>
        {/*onLoadedMetadata={this.timeUpdateHandler} onTimeUpdate={this.timeUpdateHandler}*/}
      </>
    );
  }
  /* componentDidUpdate(nextProps) {
		this.setState({ musicList: nextProps.musiclist });  
	} */
}
export default withRouter(ListenerSongPlay);