import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom';
import Axios from 'axios';
import ls from 'local-storage';
import { API_URL } from '../config/constants.js';
const base_api_url = API_URL;
export default class LogoutFromAll extends Component {
	constructor(props){
		super(props);
		
		this.state = {
		  error: null,
		  msg: null,
		  redirect:false
		}
		
	}
	componentDidMount() {
		document.title = 'CONQER Music - Logout';
		this.fetchUserRecord();
	}
	fetchUserRecord = () => {
		let currentemail = ls.get('email');
		const obj = {
			type: 'all',
			email: currentemail
		};
		const axiosConfig = {
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*'
		};
		Axios.post(base_api_url + 'deleteloginattempt.php', obj, { axiosConfig }).then((res) => {
		  if (res.data.res_status == 200) {
			ls.remove('userid');
			ls.remove('email');
			ls.remove('name');
			ls.remove('role');
			ls.remove('token');
			ls.remove('membership');
			ls.remove('membership_status');
			ls.remove('profile');
			ls.remove('localCurrentSong');
			ls.remove('localMusicList');
			ls.remove('localIndex');
			this.props.history.replace('/login');
		  }
		});
	  };
	render() {		
		return <Redirect exact to="/login" />
	}
}