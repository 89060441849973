import React, { Component } from 'react';
import Axios from 'axios';
import  { Redirect, Link  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import InputMask from 'react-input-mask';
const base_api_url = API_URL;
export default class Register extends Component {
	//let history = History();
	constructor(props) {
		super(props);
		this.onChangeFirstName = this.onChangeFirstName.bind(this);
		this.onChangeLastName = this.onChangeLastName.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeArtPassword = this.onChangeArtPassword.bind(this); 
		this.onChangeDob = this.onChangeDob.bind(this);
		this.onChangeGender = this.onChangeGender.bind(this);
		this.onChangeAddress = this.onChangeAddress.bind(this);
		this.onChangeCellphone = this.onChangeCellphone.bind(this);
		this.onChangeSsn = this.onChangeSsn.bind(this);
		this.onChangeCity = this.onChangeCity.bind(this);
		this.onChangeStates = this.onChangeStates.bind(this);
		this.onChangeZipcode = this.onChangeZipcode.bind(this);
		this.onChangeSubscription = this.onChangeSubscription.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
		  first_name: '',
		  last_name: '',
		  email:'',
		  artpassword:'',
		  dob:'',
		  gender:'',
		  address:'',
		  cellphone:'',
		  ssn:'',
		  city:'',
		  states:'',
		  zipcode:'',
		  planid:'',
		  error: null,
		  msg: null,
		  emailmsg: null,
		  redirect:false,
		  loading:true,
		  successclass:'hideclass'
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Artist Registration";		
	}
	
	state = { 
		selectedFile: null
    };
	
	onFileChange(e){    
		this.setState({ selectedFile: e.target.files[0] });		
    };
	
	onChangeFirstName(e) {
		this.setState({
		  first_name: e.target.value
		});
	}
	
	onChangeLastName(e) {
		this.setState({
		  last_name: e.target.value
		})  
	}
	
	onChangeEmail(e) {
		this.setState({
		  email: e.target.value,
		  emailmsg:null
		});
		
		const obj = {
			email:e.target.value
		}
		
		Axios.post(base_api_url+'email_exist.php',obj)
		.then(
			(res) => {
				//console.log(res.data)
				if(res.data.res_status==200){
					this.setState({
						email:'',
						emailmsg:'Email already exist.'
					});
				}else{
					this.setState({
						emailmsg:null
					});
				}
			}
		);
		 
	}
	
	onChangeArtPassword(e) {
		this.setState({
		  artpassword: e.target.value
		})
	}
	
	onChangeDob(e){
		this.setState({
		  dob: e.target.value
		})
	}
	
	onChangeGender(e){
		this.setState({
		  gender: e.target.value
		});		
	}
	
	onChangeAddress(e){
		this.setState({
		  address: e.target.value
		})
	}
	
	onChangeCellphone(e){
		this.setState({
		  cellphone: e.target.value
		})
	}
	
	onChangeSsn(e){
		this.setState({
		  ssn: e.target.value
		})
	}
	
	onChangeCity(e){
		this.setState({
		  city: e.target.value
		})
	}
	
	onChangeStates(e){
		this.setState({
		  states: e.target.value
		})
	}
	
	onChangeZipcode(e){
		this.setState({
		  zipcode: e.target.value
		})
	}
	
	onChangeSubscription(e){
		this.setState({
		  planid: e.value
		})
	}
		
	onSubmit(e) {
		e.preventDefault();
		const formData = new FormData();
		
		const axiosConfig = {
			'Content-Type': 'multipart/form-data',
			"Access-Control-Allow-Origin": "*",
		};
		if(this.state.selectedFile!=null){		
			formData.append("image", this.state.selectedFile, this.state.selectedFile.name);
		}
		formData.append("first_name", this.state.first_name);
		formData.append("last_name", this.state.last_name);
		formData.append("email", this.state.email);
		formData.append("artpassword", this.state.artpassword);
		formData.append("dob", this.state.dob);
		formData.append("gender", this.state.gender);
		formData.append("address", this.state.address);
		formData.append("cellphone", this.state.cellphone);
		formData.append("ssn", this.state.ssn);
		formData.append("city", this.state.city);
		formData.append("states", this.state.states);
		formData.append("zipcode", this.state.zipcode);
		formData.append("planid", this.state.planid);
		formData.append("roleid", 2);
			
		Axios.post(base_api_url+'add-user.php', formData, {axiosConfig})
		.then(
			(res) => {window.scrollTo(0, 0);
				if(res.data.res_status==200){					
					this.setState({
						redirect: false,
						successclass:'successmsg2',
						msg:'Registration is successfull!'
					});					
					
				}else{
					window.scrollTo(0, 0);
					this.setState({
						redirect: false,
						successclass:'errormsg2',
						msg:'Registration process is failed, please try again!'
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: 'hideclass',
						msg: null,
						redirect: true
					});
				}, 5000);
			}
		);
		
		this.setState({
		  first_name: '',
		  last_name: '',
		  email:'',
		  artpassword:'',
		  dob:'',
		  gender:'',
		  address:'',
		  cellphone:'',
		  ssn:'',
		  city:'',
		  states:'',
		  zipcode:'',
		  planid:''		  
		});
	}
	
    render() {
		const { redirect } = this.state;
		if(redirect==true){
			return <Redirect to="/login" />
		}else{
        return (
		<React.Fragment>
			<section className="become-artist-form">
				<div className="container">
					<div className="col-md-12"><div className={this.state.successclass}>{this.state.msg}</div></div>
					<div className="col-md-12">&nbsp;</div>
					<div className="become-artist-part">					
						<div className="row">
							<div className="contents_right col-md-12">
								<form className="artist-form" onSubmit={this.onSubmit}>
									<div className="row">
										<div className="col-md-6">
											<div className="artist-form-part">
												<legend>Register <small>(Artist)</small></legend>
												<div className="row">
												  <div className="col-md-6">
												  <div className="form-group">
													<label>First Name <span className="red">*</span></label>
													<input type="text" className="form-control" required="required" id="f-name" value={this.state.first_name} onChange={this.onChangeFirstName} />
												  </div>
												  </div>
												  <div className="col-md-6">
												  <div className="form-group">
													<label>Last Name</label>
													<input type="text" className="form-control" id="l-name" value={this.state.last_name} onChange={this.onChangeLastName} />
												  </div>
												  </div>
												</div>
												<div className="form-group">
													<label>Email <span className="red">*</span></label>
													<input type="email" className="form-control" id="email" required="required" value={this.state.email} onChange={this.onChangeEmail} />
													<span className="errorMsg">{this.state.emailmsg}</span>
												</div>
												<div className="form-group">
													<label>Password <span className="red">*</span></label>
													<input type="password" className="form-control" id="pwd" required="required" value={this.state.artpassword} onChange={this.onChangeArtPassword} />
												</div>
												<div className="form-group">
													<label>Birthday <span className="red">*</span></label>
													<input type="date" className="form-control" required="required" value={this.state.dob} onChange={this.onChangeDob} min="1940-01-01" max="2021-12-31" id="birthday" />
												</div>
												<div className="form-group">													
													<label htmlFor="is_new_release">Gender</label><br />
													<div className="custom-control custom-radio custom-control-inline">
														<input type="radio" id="newrelease" name="customRadioInline1" value="male" onChange={this.onChangeGender} className="custom-control-input" />
														<label className="custom-control-label" htmlFor="newrelease">Male</label>
													</div>
													<div className="custom-control custom-radio custom-control-inline">
														<input type="radio" id="newrelease2" value="female" onChange={this.onChangeGender} name="customRadioInline1" className="custom-control-input" />
														<label className="custom-control-label" htmlFor="newrelease2">Female</label>
													</div>
												</div>
												<div className="form-group">
													<label>Address</label>
													<input type="text" className="form-control" id="address" value={this.state.address} onChange={this.onChangeAddress} placeholder="Address" />
												</div>
												<div className="form-group cell-detail">
													<div className="form-item">
														<label>Phone No.</label>
														<InputMask {...this.props} className="form-control" mask="+1 (999) 999-9999" maskChar=" " value={this.state.cellphone} onChange={this.onChangeCellphone} id="phoneN" />
													</div>
													<div className="form-item">
														<label>SSN</label>
														<InputMask {...this.props} className="form-control" value={this.state.ssn} mask="999999999" maskChar=" " onChange={this.onChangeSsn} id="ssn" />
													</div>
												</div>
												<div className="row">
													<div className="col-md-4">
													<div className="form-group ">
														<label>City</label>
														<input type="text" className="form-control" value={this.state.city} onChange={this.onChangeCity} id="city" placeholder="City" /> 
													</div>
													</div>
													<div className="col-md-4">
													<div className="form-group ">
														<label>State</label>
														<input type="text" className="form-control" value={this.state.states} onChange={this.onChangeStates} id="state" placeholder="State" />
													</div>
													</div>
													<div className="col-md-4">
													<div className="form-group ">
														<label>Zip Code</label>
														<InputMask {...this.props} type="text" className="form-control" value={this.state.zipcode} onChange={this.onChangeZipcode} mask="99999" maskChar=" " id="zip" placeholder="Zip Code" />
													</div>
													</div>
												</div>
											</div>                                
										</div>
											
											
										<div className="col-md-6 secondcolumg">
										<div className="artist-form-part">
											<div className="form-group">
												<label>Avatar</label>
												<div className="avatar-data">
													<input type="file" id="avatar" name="myfile" onChange={this.onFileChange} placeholder="Avatar" aria-invalid="false" />
												</div>
											</div>
											<div className="form-group">
												<label>Subscription</label>
												<div className="subsc">
													<select id="subscription" className="form-control" name="" onChange={this.onChangeSubscription} >
														<option value="1">Monthly $9.77</option>
														<option value="2">Annual $108.00</option>
													</select>
												</div>
											</div>
											<div className="form-group credit-cardform-group credit-card">
												<label>Credit Card</label>
												<div className="row">
													<div className="col">
														<input type="text" placeholder="Card Number" name="" />
													</div>
													<div className="col">
														<input type="text" placeholder="MM / YY" name="" />
													</div>
													<div className="col">
														<input type="text" placeholder="CVC" name="" />
													</div>
												</div>
											</div>
											<div className="form-group">
												<div className="checkbox">
													<label>
														<input className="check-h" required="required"  type="checkbox" name="" aria-required="true" />
														<Link to={'/terms-and-conditions'} activeClassName="active" target="_blank">I Agree To Terms of Service <span className="red">*</span></Link>
													</label>
												</div>
											</div>
											<div className="btn-toolbar-inner form-group">
												<div className="btn-group">
													<button type="submit" className="btn"><i className="fa fa-check" aria-hidden="true"></i>Submit </button>
												</div>
												<div className="btn-group">
													<button type="button" className="btn"><i className="fa fa-times" aria-hidden="true"></i>Cancel </button>
												</div>
											</div>
										</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
		)
		}
	}
}