import React, { Component } from 'react';
import Axios from 'axios';
import  { Link  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}

export default class ListenerMyEvents extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {		  
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  eventlist:[],		  
		  loading:true,
		  loader:null,		  
		  redirect:false
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - My Events";
		const token = ls.get('token');
		const userid = ls.get('userid');
		
		this.setState({	
			token:token,		
			userid:userid
		});
		
		this.getalleventlist();
	}
	componentDidUpdate(){
		$('#myevents').DataTable(
			{	
				"destroy": true,
				"pageLength":10,
				"stateSave": true,
				"language": {
					"search": "Search",
					"sEmptyTable": "No Event Found"
				},
				"aaSorting": [],
				"order": [],
				"lengthMenu": [[5, 10, 15, -1], [5, 10, 15, "All"]],
				columnDefs: [
	   				{ orderable: false, targets: -1 }
				]
			}
		);
	}
	getalleventlist = () => {
		const obj = {
			userid:ls.get('userid')
		}
		
		Axios.post(base_api_url+'listener-eventlist.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					const returndata = res.data.res_data
					this.setState({
						eventlist:returndata,
						loading:false
					});
				}else{
					this.setState({
						loading:false
					});
				}
			}
		);
	}
	
	deleteEvent = (id) => {
		const confirmBox = window.confirm(
			"Do you really want to delete this event?"
		);
		
		if (confirmBox === true) {
			const obj = {
				id:id
			}
			
			Axios.post(base_api_url+'deleteevent.php',obj,{axiosConfig})
			.then(
				(res) => {console.log(res.data);
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:'Event deleted successfully!',
						});
						this.getalleventlist();
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:'Event not deleted, please try again!'
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });
					  window.location.reload();					  
					}, 100)
				}
			);
			
		}
	}
	
	render() {
		if(this.state.loading==true){
			return <Spinner />;
		}
		let maxlimit = '30';
		return (
			<React.Fragment>
				
				<div className={this.state.successclassName}>{this.state.msg}</div>
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
									<h3 className="box-title">Events List</h3>  
									<div className="box-tools saearchicons">
										<div className="input-group input-group-sm hidden-xs">
										</div>
									</div>            
								</div>
								<div className="box-body no-padding">
									<div className="table-responsive">
										<table id="myevents" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Event Name</th>                    
												<th>Event Start Date</th>
												<th>Event End Date</th>
												<th>Booking Start Date</th>
												<th>Booking End Date</th>
												<th>Location</th>
												<th style={{textAlign:'center'}}>Available Seats</th>
												<th data-orderable="false" className="text-center">Action</th>
											</tr>
										</thead>
										<tbody>
											{this.state.eventlist.map((result) => {
												let eventlocation = result.location;
												return (
												  <tr>                  
													<td><Link to={'/listener-viewevent/'+result.id}>{result.event_name}</Link></td>
													<td>{result.event_start_date}</td>
													<td>{result.event_end_date}</td>
													<td>{result.boobking_start_date}</td>
													<td>{result.boobking_end_date}</td>
													<td>{(result.address)?result.address+' '+result.city:""}<br />{(result.state)?result.state+', '+result.country+' '+result.zipcode:""}</td>
													<td style={{textAlign:'center'}}>
													  <span className="label label-success">{result.total_seats}</span>
													</td>
													<td>
													{(result.total_seats == 0)?<span className="btn btn-info custombtndisable" title="No seat available"><i className="fa fa-ticket" aria-hidden="true"></i></span>:((result.booking_start)?(result.booking_end)?<span className="btn btn-info custombtndisable" title="Booking closed"><i className="fa fa-ticket" aria-hidden="true"></i></span>:<Link to={'/book-ticket/'+result.id} className="btn btn-info custombtn" title="Book Now"><i className="fa fa-ticket" aria-hidden="true"></i></Link>:<span className="btn btn-info custombtndisable" title="Booking not started yet"><i className="fa fa-ticket" aria-hidden="true"></i></span>)}
													
													</td>
												  </tr>	
												)
											})}
										</tbody>
									</table>
								</div>              
							</div>
							<div className="box-footer clearfix">
							</div>
						</div>
					</div>        
				</div>      			
			</React.Fragment>
		)
	}
}