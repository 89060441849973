import React, { Component } from 'react';
import Axios from 'axios';
import  { Link, NavLink  } from 'react-router-dom';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import defaultimg from '../components/default.png';
import ls from 'local-storage';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import {toast} from 'react-toastify';
const base_api_url = API_URL;

const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}
const errors = {};
let _data = [];
toast.configure();
export default class allArtist extends Component {
	
	constructor(props){
		super(props);
		this.loadingRef 	= React.createRef();
		this.state = {
		  email:'',
		  role:null,
		  userid:null,
		  token:'',
		  error: null,
		  msg: null,
		  topartists:[],
		  topartist:[],
		  loading:true,
		  artist_name:'',
		  successclass:null,
		  redirect:false,
		  type:'all',
		  followstatus: '0',
		  btnText: 'Submit',
		  btnDisable: false,
		  errors: [],
		  artist_class: null,
		  artist_msg: null,
		  suggest_name: '',
		  page: 0,
		  limit: 25,
		  prevY: 0,
		  hide:'block',
		  iteration: 1,
		  label:'none'
		}
		
	}
	
	componentDidMount(){
		document.title = "CONQER Music - Artist List";
		this.setState({
			email:ls.get('email'),
			name:ls.get('name'),
			token:ls.get('token'),
			userid:ls.get('userid')
		});
		this.getTopArtists(this.state.page, this.state.limit);
		var options = {
			root: null,
			rootMargin: "0px",
			threshold: 1.0
		};
		this.observer = new IntersectionObserver(
			this.handleObserver.bind(this),
			options
		);		
		this.observer.observe(this.loadingRef);
	}
	handleObserver(entities, observer) {
		const y = entities[0].boundingClientRect.y;
		if(this.state.type === 'all'){
			if (this.state.prevY > y) {
				const lastArtist = this.state.topartists.length;
				const curPage = lastArtist;
				this.getTopArtists(curPage,25);
				this.setState({ page: curPage, iteration: this.state.iteration+1 });
			}
			this.setState({ prevY: y });
		}
	}
	followArtist = (artist_id,followstatusdata) => {
		const listenerobj = {
			artistid:artist_id,
			followstatus:followstatusdata,
			listenerid:ls.get('userid')
		}
		Axios.post(base_api_url+'changefollowstatus.php',listenerobj,{axiosConfig})
		.then(
			(followresults) => {
				if(followresults.data.res_status==200){
					toast.success(followresults.data.res_message);
				}else{
					toast.error(followresults.data.res_message);
				}
				if(this.state.type == 'search'){
					this.setState(
					{
						hide:'none'
					},()=>this.searchArtist());
				}else{
					this.setState(
					{
						topartists:[],
						hide:'none'
					},() => this.getTopArtists(0, this.state.limit*this.state.iteration));
				}
				
			});
	}
	getTopArtists = (page,limit) => {
		this.setState({type:'all', loading: true, label:'none'});
		const obj = {
			user_id: ls.get('userid')
		}
		Axios.post(base_api_url+`gettopartists-v2.php?_page=${page}&_limit=${limit}`,obj)
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({ topartists: [...this.state.topartists, ...res.data.user_data], totalArtist: res.data.totalArtist  });
					this.setState({ hide:'block', label:'block'});
				}else{
					//this.setState({ topartists: [] });
					this.setState({ hide:'none', label:'block' });
				}
			}
		);
	}
	artitName(e){
		if(e.target.value){
			this.setState({artist_name:e.target.value});
		}else{
			this.setState(
			{
				artist_name:'',
				topartists:[],
				type:'all',
			},() => this.getTopArtists(0, this.state.limit*this.state.iteration));
		}
		
	}
	searchArtist = (e) => {
		this.setState({type:'search', topartist:[], label: 'none'});
		let _data = [];
		let n = this.state.artist_name;
		if(n){
			let obj = {
				keyword: n,
				user_id: ls.get('userid')
			}
			Axios.post(base_api_url+`gettopartists-search-v2.php`,obj)
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							topartist:res.data.user_data,							
							hide:'none',
							label:'block'
						})
					}else{
						this.setState({topartist: [], hide:'none', label:'block' });
					}
				}
			);
			/*this.state.topartists.map((results) => {
				if((results.first_name+' '+results.last_name).match(new RegExp(n,'ig'))){
					_data.push(results);				
				}
			});
			this.setState({
				topartist:_data,
				
				hide:'none'
			});*/
		}else{
			this.setState({type:'all',hide:'block'});
		}
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	handleKeyPress = (event) => {
	  	if(event.key === 'Enter'){
	    	this.searchArtist()
	  	}
	}
	suggestArtist = () => {
		$('#__suggest_artist_modal').modal('show');
	}
	onChangeName(e){
		if (this.state.errors['name_error']) {
			delete this.state.errors['name_error'];
		}
		this.setState({
			suggest_name : e.target.value
		})
	}
	clearForm = () => {
		this.setState({
			suggest_name: '',
			errors:{
				name_error:''
			}
		});
	}
	handleValidation = () => {
	    let formIsValid = true;
	    if (!this.state.suggest_name) {
	      formIsValid = false;
	      errors['name_error'] = 'Please enter artist name.';
	    }
	    this.setState({ errors: errors });
    	return formIsValid;
	}
	async onSubmit(e) {
		e.preventDefault();
		if (this.handleValidation()) {
			this.setState({
		    	btnText:'Please Wait..',
		    	btnDisable:true
		  	});
		  	let formData = {
		        "sender_by":ls.get('userid'),
		        "suggest_name": this.state.suggest_name
		    };
		    Axios.post(base_api_url + 'artist-request.php', formData, { axiosConfig }).then((res) => {
		    	if (res.data.res_status == 200) {
		    		this.setState({
			            artist_class: 'successmsg',
			            artist_msg: res.data.res_message,
			            btnText:'Submit',
			    		btnDisable:false,
			    		suggest_name: ''
			        });
		    	}else{
		    		this.setState({
			            artist_class: 'errormsg',
			            artist_msg: res.data.res_message,
			            btnText:'Submit',
			    		btnDisable:false
			        });
		    	}
			  	setTimeout(() => {
					this.setState({
						artist_class: 'hideclass',
						artist_msg: null
					});
					}, 
				10000);
		    });
		}
	}
	render() {		
		let artists = (this.state.type == 'all')?this.state.topartists:this.state.topartist;
		const loadingTextCSS = { display: (this.state.totalArtist == artists.length) ? "none" : "block" };
		return (
			<React.Fragment>	
				<div className={this.state.successclass}>{this.state.msg}</div>
				<div className="row topfivesongsd">
					<div className="col-md-12">
						<div className="todpfive">Artist 
							<span className="rightsidetext">
								<span class="navbar-search col"style={{'float':'left'}}>
						            <label class="sr-only screen-reader-text visually-hidden" for="search">Search for:</label>
						            <div class="input-group">
						            	<div class="input-search-field">
						            		<input type="text" id="search" class="form-control search-field product-search-field" dir="ltr" onChange={this.artitName.bind(this)} placeholder="Search Artist" onKeyPress={this.handleKeyPress} autocomplete="off" />
						            	</div>
						            	<div class="input-group-btn">
								            <button type="submit" onClick={() => this.searchArtist()} class="btn btn-secondary"><i class="fa fa-search"></i></button>
						            	</div>
						            </div>
						        </span>
						        <Link to="#" onClick={() => this.suggestArtist()} className="btn btn-suggest mb-3">
									<i className="fa fa-user-plus" aria-hidden="true"></i> Suggest Artist
								</Link>
								<Link to="#" onClick={this.props.history.goBack} className="btn btn-primary mb-3">
									<i className="fa fa-angle-double-left" aria-hidden="true"></i> Back
								</Link>
							</span>
						</div>
					</div>
				</div>
				<div className="row artisitblock allartist">					
					{(artists.length)?artists.map((result) => {
						let fullname = result.first_name + ' ' + result.last_name;
						let artistsingleurl =  '/listener-assong/'+ result.usr_id;
						return (
						<div className="col-md-3">
							<Link to={artistsingleurl}>
							<div className="featureartprifles">
							{(result.is_featured == "1")?
							<span className="__featured_artist">Featured</span>:""}
							   <div className="featrueimages">
								  <img onError={this.addDefaultSrc} src={result.thumb_profile_pic} loading="lazy" />
							   </div>
							   <div className="featurestext">
								  <h3>{fullname}</h3>
								  <span className="nooffollowers float-btn" title="Total Followers"><i className="fa fa-users"></i> {result.myfolowers}</span>
								  <span className="nooffollowers float-btn" title={(result.is_follow)?"Unfollow":"Follow"}><Link onClick={() => this.followArtist(result.usr_id,result.is_follow)} style={{'color':'#353535'}}><i className="fa fa-user-plus"></i></Link></span>
							   </div>
							</div>
							</Link>
						</div>
						)
					}):<><div className="not-found" style={{"display":this.state.label}}><span>No artist found!</span></div></>
					}
				</div>
				<div ref={loadingRef => (this.loadingRef = loadingRef)} style={{"display":this.state.hide}}>
					<span style={loadingTextCSS}><div className="col-md-12" style={{fontSize:'24px', textAlign:'center'}}><i class="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i> Please Wait...</div></span>
				</div>
				<div id="__suggest_artist_modal" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
					<div class="modal-dialog">
						<div class="modal-content">
				      		<div class="modal-header">
				        		<button type="button" className="btn btn-default closeicon" onClick={this.clearForm.bind(this)} data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
				        		<h6 class="modal-title">Suggest Artist</h6>
				      		</div>
				      		<form>
					      		<div class="modal-body">
					      			<div className={this.state.artist_class}>{this.state.artist_msg}</div>
						        	<div className="row">
						        		<div className="col-md-12">
											<div class="form-group">
											    <label>Artist Name</label>
											    <input type="text" class="form-control" onChange={this.onChangeName.bind(this)} value={this.state.suggest_name} placeholder="Artist Name" />
												<font className="errorMsg">{this.state.errors['name_error']}</font>
											</div>
										</div>
									</div>
					      		</div>
						      	<div class="modal-footer">
						      		<button type="submit" className="btn btn-suggest" onClick={this.onSubmit.bind(this)}  disabled={this.state.btnDisable}>{this.state.btnText}</button>
						        	<button type="button" className="btn btn-suggest-close" onClick={this.clearForm.bind(this)} data-dismiss="modal">Close</button>
						      	</div>
						    </form>
					    </div>
				  	</div>
				</div>
			</React.Fragment>
		)
	}
}