import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import ls from 'local-storage';
import moment from 'moment';
import {toast} from 'react-toastify';
import InputMask from 'react-input-mask';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
const errors = {};
toast.configure();
export default class TakeAttendance extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	errors:[],
	    	booking_id:'',
	    	disabled: false
	    };
	}
	componentDidMount(){
		document.title = "CONQER Music - Take Attendance";
	}
	onValidateQRCode(e){
		if(this.state.errors['qr_error']){delete this.state.errors['qr_error'];}
		this.setState({
			booking_id: e.target.value
		});
	}
	handleValidation = () => {
		let formIsValid = true;
		if(!this.state.booking_id){
			formIsValid = false;
		  	errors["qr_error"] = "Please enter QR Code.";
		}
		this.setState({errors: errors});
		return formIsValid;
	}
	onSubmit() {
		if(this.handleValidation()){
			this.setState({
		        disabled:true
		    });
			const obj = {
		        booking_id: this.state.booking_id,
		        user_id: ls.get('userid'),
		        current_date_time: moment().format('yyyy-MM-DD HH:mm:ss')
		    };
		    Axios.post(base_api_url + 'event-ticket-verification.php', obj, { axiosConfig }).then((res) => {
		    	if (res.data.res_status == 200) {
		    		toast.success(res.data.res_message);
		    	}else{
		    		toast.error(res.data.res_message);
		    	}
		    	this.setState({
			        disabled:false,
			        booking_id:''
			    });
		    });
		}
	}
	render() {
        return (
        	<>
        		<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
							  	<h3 className="box-title">Take Attendance</h3>        
							</div>
							<div className="box-body no-padding">
								<div className="tab-content">
									<div className="row">
				                        <div className="col-md-12">
				                          	<div className="sectionhead">
				                            	<div className="headsec">
				                              		<h3>Take Attendance</h3>
				                            	</div>
				                          	</div>
				                        </div>
				                    </div>
				                    <div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>QR Code<span className="requiredastrik">*</span></label>
												{/*<input type="text" className="form-control" placeholder="QR Code" onChange={this.onValidateQRCode.bind(this)} onBlur={() => this.onSubmit()}/>*/}
												<InputMask {...this.props} className="form-control" placeholder="QR Code" mask="********-****-****-****-************::9999" tabIndex="8" maskChar="" value={this.state.booking_id} onChange={this.onValidateQRCode.bind(this)} onBlur={() => this.onSubmit()}/>
												<font className="errorMsg">{this.state.errors["qr_error"]}</font>
											</div>
										</div>
										<div className="col-md-6 notmainbtn">
											<button onClick={() => this.onSubmit()} className="btn btn-primary" style={{"marginTop":"23px"}} disabled={this.state.disabled}>Submit</button>
										</div>
									</div>
								</div>           
							</div>
						</div>
					</div>        
				</div> 
        	</>
        )
    }
}