import React, { Component } from 'react';
import Axios from 'axios';
import { Row, Col, ListGroup } from 'react-bootstrap';
import Spinner from '../includes/Spinner.component';
import { API_URL } from '../config/constants.js';
import ModalShowCase from '../includes/modal.component';
import ls from 'local-storage';
import  { Link, NavLink } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
export default class ListenerNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      show: false,
      selectedItem: {},
      unreadCounter: 0,
      loading:true,
      hideCustom: true,
      dataType: 'all',
    };
  }
  componentDidMount() {
  	document.title = "CONQER Music - Notification";
    this.getNotificationData();
  }
  getNotificationData = async (uid) => {
    if(uid == null){
      this.setState({loading:true});
    }
    let obj = {};
    if(this.state.dataType !== 'custom'){
      obj = {
        user_id: ls.get('userid'),
        type: this.state.dataType
      };
    }else{
      obj = {
        user_id: ls.get('userid'),
        type: this.state.dataType,
        from: moment(this.state.startdate).format("yyyy-MM-DD"),
        to: moment(this.state.endDate).format("yyyy-MM-DD")
      };
    }
    const response = await Axios.post(base_api_url + 'get-notification.php', obj, axiosConfig);

    if (response.data.res_status === '200') {
      this.setState({
        notifications: response.data.res_data,
        loading:false
      });
    }
  };
  readNotification = async (uid) => {
    const obj = {
      id: uid,
    };
    await Axios.post(base_api_url + 'read-notification.php', obj, axiosConfig);
    this.getNotificationData(uid);
  };

  handleShowModal = (con, index) => {
    this.setState({ show: false});
    const selectedItemData = this.state.notifications[index] ? this.state.notifications[index] : {};
    //this.setState({ show: con, selectedItem: selectedItemData }, () => this.readNotification(selectedItemData.id));
    this.readNotification(selectedItemData.id);
    if(selectedItemData.notification_type == "song_added"){
      let path = '/topssongs/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "event_created"){
      let path = '/listener-viewevent/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "prayer_reply"){
      let path = '/listner-view-prayer-request/'+selectedItemData.data_id;
      this.props.history.push(path);
    }else if(selectedItemData.notification_type == "user_welcome"){
		  this.setState({ show: true, selectedItem: selectedItemData }, () => this.readNotification(selectedItemData.id));
    }  
  };
  changeData = (e) => {
    this.setState({ dataType: e.target.value }, () => {
      if (this.state.dataType !== 'custom') {
        this.getNotificationData();
        this.setState({ hideCustom: true, loading:true });
      } else {
        this.setState({ hideCustom: false });
      }
    });
  };
  onChangeStartDate = (e) => {
    this.setState({ startdate: e,endDate:'' });
  };
  onChangeEndDate = (e) => {
    this.setState({ endDate: e });
  };
  render() {
    return (
      <React.Fragment>
      <div className="row">
			<div className="col-md-12">
				<div className="box box-success distributions-profileform">
					<div className="box-header with-border addprofilenew">
					  <h3 className="box-title">All Notifications</h3>
            <div className="box-tools d-flex justify-content-between">
              <select class="filteroptionselect"
                as="select"
                value={this.state.dataType}
                onChange={(e) => this.changeData(e)}
              >
                <option value="all">All</option>
                <option value="monthly">Current Month</option>
                <option value="yearly">Current Year</option>
                <option value="custom">Custom</option>
              </select>
              <span className="firstrow">
                <DatePicker
                  selected={this.state.startdate}
                  onChange={(e) => this.onChangeStartDate(e)}
                  name="startdate"
                  dateFormat="MM/dd/yyyy"
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  required={true}
                  placeholderText="From"
                  className={this.state.hideCustom ? 'd-none ' : 'form-control'}
                  id="startdate"
                  onKeyDown={e => e.preventDefault()}
                />
              </span>
              <span className="firstrow">
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(e) => this.onChangeEndDate(e)}
                  name="End Date"
                  dateFormat="MM/dd/yyyy"
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  required={true}
                  minDate={(this.state.startdate)?new Date(this.state.startdate):''}
                  placeholderText="To"
                  className={this.state.hideCustom ? 'd-none ' : 'form-control'}
                  id="startdate"
                  onKeyDown={e => e.preventDefault()}
                />
              </span>
              <button className={this.state.hideCustom ? 'd-none ' : 'btn pull-right btn-primary'} onClick={() => this.getNotificationData()}>
                Go
              </button>
            </div>
          </div>
					<ModalShowCase data={this.state.selectedItem} show={this.state.show} handleShow={this.handleShowModal} />
			            <div>
                  {this.state.loading?
                  <div className="col-md-12" style={{fontSize:'24px', textAlign:'center'}}><i class="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i> Please Wait...</div>:
			            	<ListGroup>
			                  {(this.state.notifications.length)?this.state.notifications.map((item, index) => (
								  
                          <ListGroup.Item key={index} style={{padding:'15px 15px' }} >
                            <div className="d-flex flex-row justify-content-between">
								<Link onClick={() => this.handleShowModal(false, index)}>
								  <p className={item.read === '0' ? 'font-weight-bold' : 'text-secondary'} style={{ marginBottom:'0px'}}>{item.title}<br /><span className="text-secondary">{item.message}</span></p>
								</Link>
								<p className="text-right text-secondary" style={{ marginBottom:'0px', width:'16%'}}>{item.created_at}</p>
                            </div>
                          </ListGroup.Item>
			                  )):
			                  <ListGroup.Item style={{ padding:'15px 15px' }}>
			                      <div className="d-flex flex-row justify-content-center">
			                        No Notification.
			                        
			                      </div>
			                    </ListGroup.Item>
			              	}
			                </ListGroup>}
			            </div>
				</div>
			</div>        
	  	</div>      
      </React.Fragment>
      )
  }
}