import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { addDays, subDays, setHours, setMinutes } from 'date-fns';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import $ from 'jquery';
import defaultimg from '../../../components/default.png';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Geocode from "react-geocode";
import Spinner from '../../../includes/Spinner.component';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const errors = {};
const mapStyles = {
  width: '97%',
  height: '365px',
  position: 'relative'
};
export class AddEvent extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:false,
      	eventtitle:'',
      	alias:'',
      	startDateTime: '',
      	endDateTime: '',
      	eventcategory:'',
      	allowpermission:'',
      	venue: '',
      	access:'',
		eventlocation: '',
		eventimage: '',
		eventkeyword: '',
		eventmetadescription: '',
		description: '',
		bookingstart: '',
		bookingend: '',
		oldimage:'',
      	submitBtnText:'Submit',
      	btnDisable:false,
      	errors: [],
      	eventcatlist: [],
      	address: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		add_lat:'0',
		add_lng:'0',
		submitbuttontext: 'Update',
		btndisable:false,
      }
    }
    onChangeEventTitle(e){
    	if(this.state.errors['title_error']){delete this.state.errors['title_error'];}
    	this.setState({
			eventtitle: e.target.value
		});
    }
    onChangeAlias(e){
    	this.setState({
			alias: e.target.value
		});
    }
    handleStartChange(date) {
    	if(this.state.errors['start_date_error']){delete this.state.errors['start_date_error'];}
	    this.setState({
	      startDateTime: date,
	      endDateTime:''
	    })
	}
	handleEndChange(date) {
		if(this.state.errors['end_date_error']){delete this.state.errors['end_date_error'];}
	    this.setState({
	      endDateTime: date
	    })
	}
	onChangeEventCategory(e) {
		if(this.state.errors['category_error']){delete this.state.errors['category_error'];}
	    this.setState({
	      eventcategory: e.target.value
	    })
	}
	onChangeAccess(e) {
		if(this.state.errors['access_error']){delete this.state.errors['access_error'];}
	    this.setState({
	      access: e.target.value
	    })
	}
	onChangeAllowPermissoin(e) {
		this.setState({
			allowpermission: e.target.value
		})
	}
	onChangeEventVenue(e) {
		if(this.state.errors['event_venue_error']){delete this.state.errors['event_venue_error'];}
		this.setState({
			venue: e.target.value
		})
	}

	onChangeAddress(e) {
		if(this.state.errors['address_error']){delete this.state.errors['address_error'];}
		this.setState({
			address: e.target.value
		})
	}
	onChangeCity(e) {
		if(this.state.errors['city_error']){delete this.state.errors['city_error'];}
		this.setState({
			city: e.target.value
		})
	}
	onChangeState(e) {
		if(this.state.errors['state_error']){delete this.state.errors['state_error'];}
		this.setState({
			state: e.target.value
		})
	}
	onChangeZip(e) {
		if(this.state.errors['zip_error']){delete this.state.errors['zip_error'];}
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({
				zip: e.target.value
			})
		}
	}
	moveMarker = (coord) => {
	    const { latLng } = coord;
	    this.setState({
	    	add_lat:latLng.lat(),
	    	add_lng: latLng.lng()
	    });	    
	};
	onChangeCountry(e) {
		if(this.state.errors['country_error']){delete this.state.errors['country_error'];}
		this.setState({
			country: e.target.value
		})
	}

	onChangeEventImage(e) {
		if(e.target.files.length >0){
			this.setState({ selectedcover: e.target.files[0].name, eventimage: e.target.files[0] });
		}
	}

	onChangeEventKeyword(e) {
		this.setState({
			eventkeyword: e.target.value
		})
	}

	onChangeEventMetaDescription(e) {
		this.setState({
			eventmetadescription: e.target.value
		})
	}

	onChangeEventDescription(e) {
		let value 	= e.target.value;
		value.trim();
		errors['description_error'] = '';
		console.log(e.target.value)
		if(value.trim()){
			if(this.state.errors['description_error']){delete this.state.errors['description_error'];}
			this.setState({ description: e.target.value, errors : errors})
		}else{
			this.setState({description:'', errors : errors});
		}
	}
	handleBookingChange(date){
		if(this.state.errors['bstart_date_error']){delete this.state.errors['bstart_date_error'];}
		this.setState({
	      startBookingDate: date,
	      endBookingDate: ''
	    })
	}
	handleEndBookingChange(date){
		if(this.state.errors['bend_date_error']){delete this.state.errors['bend_date_error'];}
		this.setState({
	      endBookingDate: date
	    })
	}
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
    componentDidMount(){		
		const eventid = this.props.match.params.id;
		document.title = (eventid)?"Edit Event":"Add Event";
		if(eventid){
			this.setState({
				submitBtnText: "Update",
				loader:true
			});
			this.getEventDetail(eventid);
		}
		Geocode.setApiKey("AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0");
		Geocode.setLanguage("en");	
		this.geteventcatlist();		
	}
	getAddress(){
		let address = this.state.address+' '+this.state.city+' '+this.state.state+' '+this.state.country+' '+this.state.zip;
		Geocode.fromAddress(address).then(
		  (response) => {
		    const { lat, lng } = response.results[0].geometry.location;
		    this.setState({
		    	add_lat:lat,
		    	add_lng:lng
		    });
		  },
		  (error) => {
		    console.error(error);
		  }
		);
	}
	getEventDetail = (eventid) => {
		const obj = {
			event_id:eventid,
			userid:""
		}
		
		Axios.post(API_URL+'geteventdetail.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						eventtitle:	res.data.res_event.name,
						alias: res.data.res_event.alias,
						startDateTime: new Date(res.data.res_event.event_start_date+' '+res.data.res_event.event_start_time),
						endDateTime: new Date(res.data.res_event.event_end_date+' '+res.data.res_event.event_end_time),
						eventcategory: res.data.res_event.event_category,
						access:	res.data.res_event.access_level,
						allowpermission: res.data.res_event.allowpermission,
						venue: res.data.res_event.event_venu,
						eventlocation: res.data.res_event.location,
						eventimageold:	res.data.res_event.path,
						oldimage: res.data.res_event.photo_id,
						eventkeyword: res.data.res_event.keyword,
						eventmetadescription: res.data.res_event.meta_description,
						description: res.data.res_event.description,
						/*bookingstart: res.data.res_event.booking_start_date,
						bookingend: res.data.res_event.booking_end_date,
						startBookingDate: new Date(res.data.res_event.booking_start_date),
						endBookingDate: new Date(res.data.res_event.booking_end_date),*/
						bookingstart: (res.data.res_event.booking_start_date == '0000-00-00')?"":res.data.res_event.booking_start_date,
						bookingend: (res.data.res_event.booking_end_date == '0000-00-00')?"":res.data.res_event.booking_end_date,
						startBookingDate: (res.data.res_event.booking_start_date == '0000-00-00')?"":new Date(res.data.res_event.booking_start_date),
						endBookingDate: (res.data.res_event.booking_end_date == '0000-00-00')?"":new Date(res.data.res_event.booking_end_date),
						eventid: res.data.res_event.id,
						address: res.data.res_event.address,
						city: res.data.res_event.city,
						state: res.data.res_event.state,
						country: res.data.res_event.country,
						zip: res.data.res_event.zipcode,						
						add_lat: res.data.res_event.lat,						
						add_lng: res.data.res_event.lng,
						loader:false
					})
				}else if(res.data.res_status==401){
					this.setState({
						redirect:true,
						albumdata: false,
						msg:null,
						successclass:null,
						loader:false
					});
				}else{
					this.setState({
						redirect:false,
						albumdata: false,
						msg:null,
						successclass:null,
						loader:false
					});
				}
			}
		);
	}
	geteventcatlist = () => {
		Axios.post(API_URL + 'eventcat.php')
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						this.setState({
							eventcatlist: res.data.res_data
						});
					} else {
						this.setState({
							eventcatlist: []
						});
					}
				}
			);
	}
	resetForm(){
		this.setState({
			eventtitle:'',
		  	alias:'',
		  	startDateTime: '',
		  	endDateTime: '',
		  	eventcategory:'',
		  	allowpermission:'',
		  	venue: '',
			eventlocation: '',
			eventimage: '',
			eventkeyword: '',
			eventmetadescription: '',
			description: '',
			bookingstart: '',
			bookingend: '',
			startBookingDate:'',
			endBookingDate:'',
			access:''
		});
	}
	getPreviousData(){
		const eventid = this.props.match.params.id;
		this.getEventDetail(eventid);
	}
	handleValidation(){
		let formIsValid = true;		
		if(!this.state.eventtitle){
			formIsValid = false;
			errors["title_error"] = "Please enter event title.";
		}
		if(!this.state.startDateTime){
			formIsValid = false;
			errors["start_date_error"] = "Please select event state date & time.";
		}
		if(!this.state.endDateTime){
			formIsValid = false;
			errors["end_date_error"] = "Please select event end date & time.";
		}
		if(!this.state.eventcategory){
			formIsValid = false;
			errors["category_error"] = "Please select event category.";
		}else if(!this.refs.categoryType.value){
			formIsValid = false;
			errors["category_error"] = "Please select event category.";
		}
		if(!this.state.access){
			formIsValid = false;
			errors["access_error"] = "Please select event access type.";
		}
		/* if(!this.state.venue){
			formIsValid = false;
			errors["event_venue_error"] = "Please select event venue.";
		} */
		if(!this.state.address){
			formIsValid = false;
			errors["address_error"] = "Please enter address.";
		}
		if(!this.state.city){
			formIsValid = false;
			errors["city_error"] = "Please enter city.";
		}
		if(!this.state.state){
			formIsValid = false;
			errors["state_error"] = "Please enter state.";
		}
		if(!this.state.zip){
			formIsValid = false;
			errors["zip_error"] = "Please enter zip code.";
		}
		if(!this.state.country){
			formIsValid = false;
			errors["country_error"] = "Please select country.";
		}
		if(!this.state.startBookingDate){
			formIsValid = false;
			errors["bstart_date_error"] = "Please select event booking state date.";
		}
		if(!this.state.endBookingDate){
			formIsValid = false;
			errors["bend_date_error"] = "Please select event booking end date.";
		}
		this.setState({errors: errors});
        return formIsValid;
	}
	onSubmit(e) {
		e.preventDefault();		
		const eventid = this.props.match.params.id;
		if(this.handleValidation()){
			this.setState({
				submitBtnText: 'Please Wait...',
				btnDisable:true,
				loader:true,
			});
			this.formUpdate(e);
			/*if(!eventid){
				this.formSubmit(e);
			}else{
				this.formUpdate(e);
			}*/
		}
	}
	formSubmit(e){
		const formData = new FormData();
		if (this.state.eventimage !== '' ) {
			formData.append("cover", this.state.eventimage, this.state.eventimage.name);
		}
		const startDateTime = moment(this.state.startDateTime);
		const endDateTime = moment(this.state.endDateTime);
		const bStartDate = moment(this.state.startBookingDate);
		const bEndDate = moment(this.state.endBookingDate);
		formData.append("name", this.state.eventtitle);
		formData.append("alias", this.state.alias);
		formData.append("eventstart", startDateTime.format("yyyy-MM-DD H:mm:ss"));
		formData.append("eventend", endDateTime.format("yyyy-MM-DD H:mm:ss"));
		formData.append("event_category", this.state.eventcategory);
		formData.append("access_level", this.state.access);
		formData.append("allowpermission", this.state.allowpermission);
		// formData.append("event_venu", this.state.venue);
		formData.append("address", this.state.address);
		formData.append("city", this.state.city);
		formData.append("state", this.state.state);
		formData.append("country", this.state.country);
		formData.append("zip", this.state.zip);
		formData.append("lat", this.state.add_lat);
		formData.append("lng", this.state.add_lng);
		formData.append("keyword", this.state.eventkeyword);
		formData.append("meta_description", this.state.eventmetadescription);
		formData.append("description", this.state.description);
		formData.append("booking_start_date", bStartDate.format("yyyy-MM-DD"));
		formData.append("booking_end_date", bEndDate.format("yyyy-MM-DD"));
		formData.append("created_by", '0');

		Axios.post(API_URL + 'add-event.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					e.target.reset();
					this.setState({
						successclass: 'successmsg',
						msg: 'Event created successfully!',
						submitBtnText: 'Submit',
						eventtitle:'',
					  	alias:'',
					  	startDateTime: '',
					  	endDateTime: '',
					  	eventcategory:'',
					  	allowpermission:'',
					  	venue: '',
						eventlocation: '',
						eventimage: '',
						eventkeyword: '',
						eventmetadescription: '',
						description: '',
						bookingstart: '',
						bookingend: '',
						startBookingDate:'',
						endBookingDate:'',
						access:'',
						btnDisable:false,
						loader:false
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Event not created, please try again!',
						submitBtnText: 'Submit',
						btnDisable:false,
						loader:false
					})
				}
				window.scrollTo(0, 0);
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		);
	}
	formUpdate(e){
		const formData = new FormData();
		if (this.state.eventimage !== '' ) {		
			formData.append("cover", this.state.eventimage, this.state.eventimage.name);
		}
		
		const startDateTime = moment(this.state.startDateTime);
		const endDateTime = moment(this.state.endDateTime);
		const bStartDate = moment(this.state.startBookingDate);
		const bEndDate = moment(this.state.endBookingDate);

		formData.append("name", this.state.eventtitle);
		formData.append("alias", this.state.alias);
		formData.append("eventstart", startDateTime.format("yyyy-MM-DD H:mm:ss"));
		formData.append("eventend", endDateTime.format("yyyy-MM-DD H:mm:ss"));
		formData.append("event_category", this.state.eventcategory);
		formData.append("access_level", this.state.access);
		formData.append("allowpermission", this.state.allowpermission);
		// formData.append("event_venu", this.state.venue);
		formData.append("address", this.state.address);
		formData.append("city", this.state.city);
		formData.append("state", this.state.state);
		formData.append("country", this.state.country);
		formData.append("zip", this.state.zip);
		formData.append("keyword", this.state.eventkeyword);
		formData.append("meta_description", this.state.eventmetadescription);
		formData.append("description", this.state.description);
		formData.append("booking_start_date", bStartDate.format("yyyy-MM-DD"));
		formData.append("booking_end_date", bEndDate.format("yyyy-MM-DD"));
		formData.append("created_by", "0");
		formData.append("event_id", this.state.eventid);
		formData.append("oldimage", this.state.oldimage);
		formData.append("lat", this.state.add_lat);
		formData.append("lng", this.state.add_lng);

		Axios.post(API_URL+'edit-event.php', formData, {axiosConfig})
		.then(
			(res) => {
				window.scrollTo(0, 0);
				if(res.data.res_status==200){
					this.setState({
						successclass: 'successmsg',
						msg: 'Event basic information updated successfully!',
						submitBtnText: 'Update',
						btndisable:false,
						loader:false						
					});
					this.getEventDetail(this.state.eventid);
				}else{
					this.setState({
						successclass:'errormsg',
						submitBtnText: 'Update',						
						msg:'Event not updated, please try again!',
						loader:false						
					})
				}
				setTimeout(() => {
				  this.setState({
					successclass:'hideclass',
					msg:null
				  });
				}, 10000)
			}
		);
	}
	onSubmitSecondStep(e){
		e.preventDefault();
		if(this.handleValidationSecond()){
			const formData = new FormData();
			if (this.state.eventimage !== '' ) {
				formData.append("cover", this.state.eventimage, this.state.eventimage.name);
			}
			formData.append("keyword", this.state.eventkeyword);
			formData.append("meta_description", this.state.eventmetadescription);
			formData.append("description", this.state.description);
			formData.append("event_id", this.state.eventid);
			Axios.post(API_URL + 'update-event-step-2.php', formData, { axiosConfig })
			.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: 'Event detail information updated successfully!',
						submitbuttontext: 'Update',
						btndisable:false
					});
					this.getEventDetail(this.state.eventid);
				}else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Event not updatd, please try again!',
						submitbuttontext: 'Update',
						btndisable:false
					})
				}
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null,
					});
				}, 5000)
			});
		}
	}
	handleValidationSecond(){
		let formIsValid = true;
		if(!this.state.description){
			formIsValid = false;
			errors["description_error"] = "Please enter event description.";
		}
		this.setState({errors: errors});
		return formIsValid;
	}
	onSubmitThirdStep(e){
		e.preventDefault();
		if(this.handleValidationThird()){
			const bStartDate = moment(this.state.startBookingDate);
			const bEndDate = moment(this.state.endBookingDate);
			const formData = new FormData();
			formData.append("booking_start_date", bStartDate.format("yyyy-MM-DD"));
			formData.append("booking_end_date", bEndDate.format("yyyy-MM-DD"));
			formData.append("event_id", this.state.eventid);
			Axios.post(API_URL + 'update-event-step-3.php', formData, { axiosConfig })
			.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: 'Event booking timings updated successfully.',
						submitbuttontextw: 'Save',
						stepSecond: true,
						btndisable:false
					});
					this.getEventDetail(this.state.eventid);
					setTimeout(() => {
						this.setState({
							successclass: null,
							msg: null
						});
					}, 5000)
				}else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Event not created, please try again!',
						submitbuttontextw: 'Save & Next',
						btndisable:false
					})
					setTimeout(() => {
						this.setState({
							successclass: null,
							msg: null,
							redirect:true
						});
					}, 5000)
				}

			});
		}
	}
	handleValidationThird(){
		let formIsValid = true;
		if(!this.state.startBookingDate){
			formIsValid = false;
			errors["b_start_error"] = "Please select booking start date.";
		}
		if(!this.state.endBookingDate){
			formIsValid = false;
			errors["b_end_error"] = "Please select booking end date.";
		}
		this.setState({errors: errors});
		return formIsValid;
	}
    render() {
    	const eventid = this.props.match.params.id;
		if(this.state.redirect == true){
			return <Redirect to='/admin/events/' />
		}
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
					{(this.state.loader === true)?<Spinner />:
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  	<h3 className="box-title">Edit Event</h3>
									  	<div className="box-tools">
							  				<Link to={'/admin/events/'} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</Link>
											  <Link to={'/admin/events/tickets/'+this.props.match.params.id} className="btn btn-primary aadersongs">Manage Tickets</Link>
											<Link to={'/admin/events/gallery/'+this.props.match.params.id} className="btn btn-primary aadersongs">Manage Gallery</Link>
							  			</div>
									</div>
									<div className="box-body no-padding havetabs eventadds">
										<div className="nav-tabs-custom">
											<ul className="nav nav-tabs">
								                <li className="nav-item"><a href="#tab_peb" data-toggle="tab" aria-expanded="true" className="nav-link active">Basic Information</a></li>
								                <li className="nav-item"><a href="#tab_mad" data-toggle="tab" aria-expanded="false" className="nav-link">Detail Information</a></li>
								                <li className="nav-item"><a href="#tab_sob" data-toggle="tab" aria-expanded="false" className="nav-link">Booking Timings</a></li> 
				               				</ul>
				               				<div className="tab-content">
		               		 					<div className="tab-pane active" id="tab_peb">
		               		 						<form name="event-details" onSubmit={this.onSubmit.bind(this)}>
		               		 							<div className="row">
															<div className="col-md-6">
																<div className="form-group">
																	<label>Event Title<span className="requiredastrik">*</span></label>
																	<input type="text" className="form-control" placeholder="Event Title" name="eventtitle" id="eventtitle" value={this.state.eventtitle} onChange={this.onChangeEventTitle.bind(this)} />
																	<font className="errorMsg">{this.state.errors["title_error"]}</font>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label>Alias</label>
																	<input type="text" value={this.state.alias} placeholder="Alias" className="form-control" onChange={this.onChangeAlias.bind(this)} />
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-6">
																<div className="form-group">
																	<label>Start Date & Time<span className="requiredastrik">*</span></label>
																	<div className="input-group">
	                      												<div className="input-group-prepend">
	                            											<span className="input-group-text"><i className="fa fa-calendar"></i></span>
	                          											</div>
																		<DatePicker
																		  selected={ this.state.startDateTime }
																		  onChange={ this.handleStartChange.bind(this) }
																		  name="startDateTime"
																		  dateFormat="MM/dd/yyyy h:mm aa"
																		  dropdownMode="select"
																		  showMonthDropdown
																		  showYearDropdown
																		  adjustDateOnChange
																		  showTimeSelect
																		  minDate={new Date()}
																		  maxDate={(this.state.endDateTime)?new Date(this.state.endDateTime):new Date(2100, 1, 1)}
																		  placeholderText="Start Date & Time"
																		  className="form-control"
																	  	/>
																	</div>
																  	<font className="errorMsg">{this.state.errors["start_date_error"]}</font>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label>End Date & Time<span className="requiredastrik">*</span></label>
																	<div className="input-group">
	                      												<div className="input-group-prepend">
	                            											<span className="input-group-text"><i className="fa fa-calendar"></i></span>
	                          											</div>
																		<DatePicker
																		  selected={ this.state.endDateTime }
																		  onChange={ this.handleEndChange.bind(this) }
																		  name="endDateTime"
																		  dateFormat="MM/dd/yyyy h:mm aa"
																		  dropdownMode="select"
																		  showMonthDropdown
																		  showYearDropdown
																		  adjustDateOnChange
																		  showTimeSelect
																		  minDate={(this.state.startDateTime)?new Date(this.state.startDateTime):new Date()}											  
																		  placeholderText="End Date & Time"
																		  className="form-control"
																	  	/>
																	</div>
																  	<font className="errorMsg">{this.state.errors["end_date_error"]}</font>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-6">
																<div className="form-group">
																	<label>Select Event Category<span className="requiredastrik">*</span></label>
																	<select className="custom-select" onChange={this.onChangeEventCategory.bind(this)} value={this.state.eventcategory} ref="categoryType" >
																		<option value="">-- Event Category --</option>
																		{this.state.eventcatlist.map((result) => {
																			return (<option value={result.key}>{result.value}</option>)
																		})}
																	</select>
																	<font className="errorMsg">{this.state.errors["category_error"]}</font>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label>Access<span className="requiredastrik">*</span>
																	  <span className="helpicon"><i className="fa fa-question-circle" aria-hidden="true"></i></span>
																	  <span className="helpiconactive">
																		<p>Public- Everyone can see and participate</p>
																		<p>Private - Only Registered Listener</p>
																		<p>Only Me - Only you can see</p>
																	  </span>
																	</label>
																	<select className="custom-select" onChange={this.onChangeAccess.bind(this)} value={this.state.access}>
																		<option value="">-- Access Type --</option>
																		<option value="public">Public</option>
																		<option value="private">Private</option>
																		<option value="only me">Only Me</option>
																	</select>
																	<font className="errorMsg">{this.state.errors["access_error"]}</font>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-6">
																<div className="form-group">
																	<label>Allow Other to See Event Attendee</label><br />
																	<div className="custom-control custom-radio custom-control-inline">
																		<input type="radio" id="newrelease" name="customRadioInline1" checked={this.state.allowpermission == "1"} value="1" onChange={this.onChangeAllowPermissoin.bind(this)} className="custom-control-input" />
																		<label className="custom-control-label" htmlFor="newrelease">Yes</label>
																	</div>
																	<div className="custom-control custom-radio custom-control-inline">
																		<input type="radio" id="newrelease2" checked={this.state.allowpermission == "0"} value="0" onChange={this.onChangeAllowPermissoin.bind(this)} name="customRadioInline1" className="custom-control-input" />
																		<label className="custom-control-label" htmlFor="newrelease2">No</label>
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label>Address<span className="requiredastrik">*</span></label>
																	<input type="text" className="form-control" placeholder="Enter Address" value={this.state.address} onChange={this.onChangeAddress.bind(this)}/>
																	<span className="form-error">{this.state.errors["address_error"]}</span>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-6">
																<div className="form-group">
																	<label>City<span className="requiredastrik">*</span></label>
																	<input type="text" className="form-control" value={this.state.city} placeholder="Enter City" onChange={this.onChangeCity.bind(this)}/>
																	<span className="form-error">{this.state.errors["city_error"]}</span>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label>State<span className="requiredastrik">*</span></label>
																	<input type="text" value={this.state.state} className="form-control" placeholder="Enter State" onChange={this.onChangeState.bind(this)}/>
																	<span className="form-error">{this.state.errors["state_error"]}</span>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-6">
																<div className="form-group">
																	<label>Country<span className="requiredastrik">*</span></label>
																	<select className="custom-select" value={this.state.country} onChange={this.onChangeCountry.bind(this)}>
																		<option value="">Select Country</option>
																		<option value="IND">India</option>
																		<option value="USA">United State of America</option>
																	</select>
																	<span className="form-error">{this.state.errors["country_error"]}</span>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label>ZIP Code<span className="requiredastrik">*</span></label>
																	<input type="text" className="form-control" maxlength="6" placeholder="Enter ZIP Code" onChange={this.onChangeZip.bind(this)} value={this.state.zip} onBlur={this.getAddress.bind(this)} />
																	<span className="form-error">{this.state.errors["zip_error"]}</span>
																</div>
															</div>
														</div>
														<div className="row" style={{marginBottom: '375px'}}>
															<div className="col-md-12">
																<Map google={this.props.google}
																style={mapStyles}
																className={'map'}
																zoom={14}
																initialCenter={{lat: this.state.add_lat, lng: this.state.add_lng}}
																center={{lat: this.state.add_lat, lng: this.state.add_lng}}
																>
															  <Marker
																position={{lat: this.state.add_lat, lng: this.state.add_lng}}
																draggable={true}
																onDragend={(t, map, coord) => this.moveMarker(coord)}/>
															  </Map>
															</div>
														</div>
														<div className="row">
											              	<div className="col-md-12 notmainbtn customtabsbtns">
											                	<button type="submit" disabled={this.state.btndisable} className="btn btn-primary">{this.state.submitbuttontext} </button>
											              	</div>
											            </div>
		               		 						</form>
		               		 					</div>
		               		 					<div className="tab-pane" id="tab_mad">
					               		 			<form name="event-details" onSubmit={this.onSubmitSecondStep.bind(this)}>
					               		 				<div className="row">
															<div className="col-md-6">
																<div className="row">
																	<div className="col-md-12">
																		<div className="form-group">
																			<label>Upload Event Image</label>
																			<input type="file" className="form-control" onChange={this.onChangeEventImage.bind(this)}    accept="image/*" />
																			{(this.state.oldimage!='')?<img onError={this.addDefaultSrc} src={this.state.eventimageold} width="50" height="50" style={{marginTop:'5px'}} onClick={() => this.onclickseturl(this.state.eventimageold)} />:''}
																		</div>
																	</div>
																	<div className="col-md-12">
																		<div className="form-group metaboxs">
																			<label>Meta Keyword</label>
																			<textarea className="form-control rounded-0" placeholder="Meta Keyword" onChange={this.onChangeEventKeyword.bind(this)} rows="3" value={this.state.eventkeyword}></textarea>
																		</div>
																	</div>
																	<div className="col-md-12">
																		<div className="form-group metaboxs">
																			<label>Meta Description</label>
																			<textarea className="form-control rounded-0" placeholder="Meta Description" onChange={this.onChangeEventMetaDescription.bind(this)} rows="3" value={this.state.eventmetadescription}></textarea>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group ckeditro">
																	<label>Description<span className="requiredastrik">*</span></label>
																	<textarea className="form-control rounded-0" placeholder="Description" onChange={this.onChangeEventDescription.bind(this)} rows="3" value={this.state.description}></textarea>
																	<span className="form-error">{this.state.errors["description_error"]}</span>
																</div>
															</div>
														</div>
														<div className="row">
											              	<div className="col-md-12 notmainbtn customtabsbtns">
											                	<button type="submit" disabled={this.state.btndisable} className="btn btn-primary">{this.state.submitbuttontext} </button>
											              	</div>
											            </div>
					               		 			</form>
					               		 		</div>
					               		 		<div className="tab-pane" id="tab_sob">
		               		 						<form name="event-details" onSubmit={this.onSubmitThirdStep.bind(this)}>
		               		 							<div className="row">
															<div className="col-md-6">  
											  					<div className="form-group">
																	<label>Booking Start Date<span className="requiredastrik">*</span></label>
																	<div className="input-group">
		                          										<div className="input-group-prepend">
		                            										<span className="input-group-text"><i className="fa fa-calendar"></i></span>
		                         										</div>
																		<DatePicker
																		  selected={ this.state.startBookingDate }
																		  onChange={ this.handleBookingChange.bind(this) }
																		  name="startBookingDate"
																		  dateFormat="MM/dd/yyyy"
																		  dropdownMode="select"
																		  showMonthDropdown
																		  showYearDropdown
																		  adjustDateOnChange
																		  className="form-control"
																		  maxDate={new Date(this.state.endDateTime)}
																		  placeholderText="Booking Start Date"
																	  />
		                      										</div>
																	<font className="errorMsg">{this.state.errors["bstart_date_error"]}</font>
												  				</div>
												  			</div>
															<div className="col-md-6">
												  				<div className="form-group">
																	<label>Booking End Date<span className="requiredastrik">*</span></label>
																	<div className="input-group">
		                          										<div className="input-group-prepend">
		                            										<span className="input-group-text"><i className="fa fa-calendar"></i></span>
		                          										</div>
																		<DatePicker
																		  selected={ this.state.endBookingDate }
																		  onChange={ this.handleEndBookingChange.bind(this) }
																		  name="endBookingDate"
																		  dateFormat="MM/dd/yyyy"
																		  dropdownMode="select"
																		  showMonthDropdown
																		  showYearDropdown
																		  adjustDateOnChange
																		  className="form-control"
																		  placeholderText="Booking End Date"
																		  minDate={new Date(this.state.startBookingDate)}
																		  maxDate={new Date(this.state.endDateTime)}
																	  />	
		                       										</div>
																	<font className="errorMsg">{this.state.errors["bend_date_error"]}</font>
												  				</div>
															</div>
										  				</div>
										  				<div className="row">
						              						<div className="col-md-12 notmainbtn customtabsbtns">
						               							<button type="submit" className="btn btn-primary">{this.state.submitbuttontext}</button>
						              						</div>
						            					</div>
		               		 						</form>
		               		 					</div>
		               		 				</div>
		               		 			</div>
		               		 		</div>
								<div className="box-footer clearfix"></div>
							</div>
						</div>
					</div>
					}
    			</section>
    		</div>
    		</div>
    		</div>
    		<div id="myModal" className="modal fade imgmodal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
					<div className="modal-body">
						<img className="showimage img-responsive" onError={this.addDefaultSrc} src={this.state.urlvalinitial} />
					</div>            
				</div>
			</div>
		</div>
    		</React.Fragment>
    	)
    }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0'
})(AddEvent);