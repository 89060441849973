import React, { Component } from 'react';
import Axios from 'axios';
import  { NavLink, Link  } from 'react-router-dom';
import { API_URL, STRIPE_KEY } from '../config/constants.js';
import DOMPurify from "dompurify";
import defaultimg from '../components/default.png';
import Spinner from '../includes/Spinner.component'
import $ from 'jquery';
import ls from 'local-storage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
const stripePromise = loadStripe(STRIPE_KEY);
toast.configure();
const errors = {};
export class ArtistProfile extends Component {
	
	constructor(props){
		super(props);
		this.state = {
		  userdata:[],
		  errors: [],
		  error: null,
		  msg: null,
		  loading:true,
		  token:ls.get('token'),
		  successclass:null,
		  redirect:false,		  
		  isrccode:'',		  
		  isrctitle:'',	
		  planlist:[],
		  btnText:'Submit',
		  btnDisable: false ,
		  fillStatus: false,
		  sub_plan:'',
		  featuredData:[],
		  stripe_text:''
		}
	}
	
	componentDidMount(){
		document.title = "CONQER Music - My Profile";
		this.getUserData();
		this.getplandetails('artist');
		this.getUserPlanDetail();
	}
	
	getUserData = () =>{
		const obj = {
			userid: ls.get('userid')
		}
				
		Axios.post(base_api_url+'userbytoken.php',obj,{axiosConfig})
		.then(
			(res) => {
				
				if(res.data.res_status==200){
					this.setState({
						userdata:res.data.listenerdata,						
						loading:false
					});
				}
			}
		);
	}
	getUserPlanDetail = () => {
		const obj = {
			userid: ls.get('userid'),
			type: 'user'
		}				
		Axios.post(base_api_url+'user-featuerd-plan.php',obj,{axiosConfig})
		.then(
			(res) => {				
				if(res.data.res_status==200){
					this.setState({
						featuredData:res.data.res_data
					});
				}
			}
		);
	}
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	upgradeFeaturedProfile(){
		$('#upGradModal').modal('show');
	}
	onChangePlan(e){
		if (this.state.errors['plan_error']) { delete this.state.errors['plan_error']; }
		this.setState({
			sub_plan: e.target.value
		})
	}
	getplandetails = (utype) => {   
	    const obj = {
	      type: utype
	    }
	    Axios.post(base_api_url + 'get-featured-plan.php', obj).then(
	        (res) => {
	          if (res.data.res_status == 200) {
	            this.setState({
	              planlist: res.data.res_data,
	              stripe_text: res.data.stripe_detail 
	            });
	          } else {
	            this.setState({
	              planlist: []
	            });
	          }
	        }
	    );
	}
	async onSubmit(e) {
		e.preventDefault();
		if (this.handleValidationUpgrad()) {
			this.setState({
				btnText:'Please Wait..',
				btnDisable:true
			});
			await this.onToken();      
		}
	}
	handleValidationUpgrad = () => {
		let formIsValid = true;
		if (!this.state.sub_plan) {
		  formIsValid = false;
		  errors['plan_error'] = 'Please select plan.';
		}
		if(!this.state.formValidation){
		  formIsValid = false;
		}
		if(!this.state.fillStatus){
		  this.setState({
		    cardError:'Please enter card detail.'
		  });
		  formIsValid = false;
		}
		this.setState({ errors: errors });
		return formIsValid;
	};
	stripeElementChange = (element, name) => {
	    this.setState({
	      fillStatus: true,
	      cardError:'',
	      formValidation: true
	    });
	    if(element.error){
	      if(element.error.code){
	        this.setState({
	          formValidation: false,
	          cardError: element.error.message
	        });
	      }
	    }else if(!element.complete){
	    	this.setState({
		      fillStatus: false
		    });
	    }
	  }
	async onToken() {
    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if(result.error){
      this.setState({
        btnText:'Submit',
        btnDisable:false
      });
    }else{
      let formData = {
        "token":result.token.id,
        "planId": this.state.sub_plan,
        "userId": ls.get('userid'),
        "emailId": ls.get('email')
      };
      Axios.post(base_api_url + 'upgrade-featured.php', formData, { axiosConfig }).then((res) => {
      	if(res.data.res_status == '200'){
      		const { elements, stripe } = this.props;
		    const cardElement = elements.getElement(CardElement);
		    if(cardElement){
		      cardElement.clear();
		    }
		    this.setState({
		    	sub_plan:''
		    });
		    toast.success(res.data.res_message);
		    this.getUserData();
		    this.getUserPlanDetail();
      	}else{
      		toast.error(res.data.res_message);
      	}
      	this.setState({
	        btnText:'Submit',
	        btnDisable:false
	    });
      });
      
    }
  }
	render() {
		if (this.state.loading == true) {
			return <Spinner />;
		}
		const userinfo = this.state.userdata;
		let isrccodesdata = userinfo.isrc_title + ' ( '+ userinfo.isrc_code +' )';
        return (
			<React.Fragment>
			<div className={this.state.successclass}>{this.state.msg}</div>
			<div className="row">
				<div className="col-md-12">
					<div className="box box-success distributions-profileform artprofile">
						<div className="box-header with-border addprofilenew">
						  <h3 className="box-title">Profile</h3>
						  <div className="box-tools">
						  	<Link to="#" className="btn btn-primary aadersongs" onClick={this.upgradeFeaturedProfile.bind(this)}>
								<i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Become a Featured Artist
							</Link>
			               </div>
						</div>
						<div className="box-body no-padding">
						  <div className="row">
							<div className="col-md-12">
							  <div className="sectionhead">
								<div className="headsec">
								  <h3>Personal Information</h3>
								</div>
							  </div>
							</div>
						  </div>
						  <div className="row">
								<div className="col-md-4">  
								  <div className="form-group">
									<label for="firstname">Name</label>
									<div className="decentvalue">{this.state.userdata.first_name + ' ' +this.state.userdata.last_name}</div>
								  </div>
								</div>
								<div className="col-md-4">  
								  <div className="form-group">
									<label for="firstname">Email</label>
									<div className="decentvalue">{userinfo.email}</div>
								  </div>
								</div>
								<div className="col-md-4">  
								  <div className="form-group">
									<label for="firstname">Phone No.</label>
									<div className="decentvalue">{(userinfo.phone)?`(${(userinfo.phone).substring(0, 3)}) ${(userinfo.phone).substring(3, 6)}-${(userinfo.phone).substring(6, (userinfo.phone).length)}`:''}</div>
								  </div>
								</div>
								<div className="col-md-4">
								  <div className="form-group">
									<label for="lastname">Zipcode</label>
									<div className="decentvalue">{userinfo.zipcode}</div>
								  </div>
								</div>
								<div className="col-md-4">
								  <div className="form-group">
									<label for="lastname">SSN</label>
									<div className="decentvalue">{userinfo.ssn}</div>
								  </div>
								</div>
								<div className="col-md-4">  
								  <div className="form-group">
									<label for="firstname">DOB</label>
									<div className="decentvalue">{userinfo.dob}</div>
								  </div>
								</div>
								<div className="col-md-4">
								  <div className="form-group">
									<label for="lastname">Address</label>
									<div className="decentvalue">{userinfo.address}</div>
								  </div>
								</div>
								<div className="col-md-4">
								  <div className="form-group">
								   <label for="customFile">Registered Date</label>
								   <div className="decentvalue">{userinfo.created}</div>
								  </div>
								</div>						
								<div className="col-md-4">
								  <div className="form-group">
								   <label for="customFile">Last Visited Date</label>
								   <div className="decentvalue">{userinfo.last_login}</div> 
								  </div>
								</div>
							</div>                   
							<div className="row">
								<div className="col-md-4 profilepic">
									<img onError={this.addDefaultSrc} src={userinfo.profile_pic_path} width="100" height="100" className="img-responsive img-circle" onClick={() => this.onclickseturl(userinfo.profile_pic_path)}/>
								</div>								
							</div>
						</div>
						
						<div className="box-footer clearfix">
							<div className="row">
							<div className="col-md-12">
								<NavLink className="btn btn-primary" to={'/edit-artist-profile/'+this.state.token}>Edit Profile</NavLink>
							</div>
							</div>
						</div>
					</div>
				</div>        
			  </div>
				<div id="myModal" className="modal fade imgmodal" role="dialog">
					<div className="modal-dialog">
						<div className="modal-content">
							<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
							<div className="modal-body">
								<img className="showimage img-responsive" src={this.state.urlvalinitial} />
							</div>            
						</div>
					</div>
				</div>	
				<div id="upGradModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
					<div class="modal-dialog" role="document">
						<div class="modal-content">
							<div class="modal-header" style={{'padding':'5px 20px'}}>
								<h5 class="modal-title">Become a Featured Artist</h5>
								<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
							</div>
							<form className="artist-form" onSubmit={this.onSubmit.bind(this)}>
								<div className="container">
									<div class="modal-body">
									{/*(userinfo.is_featured == '1')?*/}
									<>
										<div className="row">
											<div className="col-md-12">
												<h5 class="modal-title" style={{'fontSize':'17px'}}>Purchased Plan</h5>
												<div className="table-responsive no-padding">
												<table className="table table-striped table-bordered table-sm row-border hover tbl-padding-tr">
													<thead>
													<tr>
														<th>Plan</th>
														<th>Start Date & Time</th>
														<th>End Date & Time</th>
														<th>Status</th>											
													</tr>
													</thead>
													<tbody>
													{(this.state.featuredData.length)?this.state.featuredData.map((result) => {
														return (<tr>
															<td>{result.paln}</td>
															<td>{result.start_from}</td>
															<td>{result.end_to}</td>
															<td>{result.status}</td>
														</tr>)
													}):<tr><td colspan="4" style={{'textAlign':'center'}}>No plan available!</td></tr>}	
													</tbody>									
												</table>
												</div>
											</div>
										</div><hr style={{'width':'50%'}}/></>{/*:""*/}
										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<label>Plan<span className="requiredastrik">*</span></label>
													<select className="form-control" value={this.state.sub_plan} onChange={this.onChangePlan.bind(this)}>
														<option value="">--Select Plan--</option>
														{this.state.planlist.map((result) => {
														return (<option value={result.id}>{result.plan} [ ${result.amount} ]</option>)
														})}
													</select>
													<font className="errorMsg">{this.state.errors['plan_error']}</font>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<label>Credit Card<span className="requiredastrik">*</span></label>
													<div className="creditcardsection">
														<CardElement options={{ hidePostalCode: true }}  onChange={(element) => this.stripeElementChange(element, 'card_number')} />
													</div>
													<font className="errorMsg">{this.state.cardError}</font>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="modal-footer">
									<font style={{'position':'absolute','left':'15px','fontSize':'11px'}}>Note:- {this.state.stripe_text}</font>
									<button type="submit" class="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
								</div>
							</form>							
						</div>
					</div>
				</div>	
			</React.Fragment>
		)
	}
}
export default function InjectedEventBooking({ match }) {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <ArtistProfile match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}